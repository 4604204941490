import img from '../../../assets/images/pyramid.png';
const Image = (props: any)=>{
    return(
        <div className="image-container" style={{width: "12rem"}}>
            <img src={img} alt="Pirâmide de Prioridade nas regras." />
        </div>
    )
}

export const CaptionsPrecificationAuto = {
    title: 'Legendas Cadastro Precificador',
    data: {
        colors: {
            "Atenção: As regras criadas podem ser sobrescrevidas de acordo com a prioridade do item do grupo, devido a isso deve atentar-se para que o cadastro seja realizado levando como base que os itens do topo da pirâmide sobrescreverão os itens da base.": "#fff",
        },
        tags:{
            "": ["#fff", (<Image />)],
        }
    }
}