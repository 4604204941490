import { BaseSyntheticEvent } from "react";
import { BasicModal } from "../../../components/modal/BasicModal";
import {FiEye, FiEyeOff} from 'react-icons/fi';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { CheckBoxes } from './Checkboxes';
import { toast } from 'react-toastify';
import { ModalConfirm } from '../../../components/modal/ModalConfirm';
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import { useModal } from '../../../hooks/useModal';

import { ObjetoVazio, UsersPermissions, UpdateUserData, IUsers, UsersPermissionsRoles } from "../../../types/Types"
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';

const defaultPermissions: UsersPermissions = {'read': false, 'write': false, 'delete': false, 'update': false}

const rolesLabels: any ={
    checkMLQuestions: "Perguntas ML",
    checkMLMessages: "Mensagens ML",
    checkCadastroOrigem: "Cadastro Origem",
    checkCategComponentes: "Categoria Componentes",
    checkAgrupMLB: "Agrupamento MLBs",
    checkAgrupSKU: "Agrupamento SKUs",
    checkMLBConc: "MLBs Concorrentes",
    checkAcompCompras: "Acompanhamento Compras",
    checkAnaliseCompras: "Análise Compras",
    checkShoppingList: "Lista Compras",
    checkConfig: "Configurações Gerais",
    checkConfigFiscais: "Configurações Fiscais",
    checkConfigIntegracoes: "Configurações de Integrações",
    checkConfigOutras: "Outras Configurações",
    checkConfigUsers: "Configurações de Usuários",
    checkDashboard: "Dashboard",
    checkLCRE: "LCRE",
    checkCreditoFornec: "Crédito Fornecedores",
    checkGestPC: "Gestão PCs Alteração",
    checkEnvios: "Envios",
    checkRelacaoPC: "Relação PCs/MLBs",
    checkProducao: "Produção",
    checkHistoricoProd: "Histórico Produção",
    checkProdOrders: "Ordens de Produção",
    checkPrecificacao: "Precificação",
    checkCadastroPrecificacao: "Cadastro Precificação",
    checkKPI: "Análise de Vendas (KPI)",
    checkCatalog: "Catálogo",
    checkManagementMLBs: "Gestão Anúncios",
    checkMakeSellPrice: "Formulação de Preço de Venda",
}
const rolesTabs: any = {
    "Atendimento": {
        checkMLQuestions: defaultPermissions,
        checkMLMessages: defaultPermissions,
    },
    "Cadastro": {
        checkCadastroOrigem: defaultPermissions,
        checkCategComponentes: defaultPermissions,
        checkAgrupMLB: defaultPermissions,
        checkAgrupSKU: defaultPermissions,
        checkMLBConc: defaultPermissions,
    },
    "Compras": {
        checkAcompCompras: defaultPermissions,
        checkAnaliseCompras: defaultPermissions,
        checkShoppingList: defaultPermissions,

    },
    "Configurações": {
        checkConfig: defaultPermissions,
        checkConfigFiscais: defaultPermissions,
        checkConfigIntegracoes: defaultPermissions,
        checkConfigOutras: defaultPermissions,
        checkConfigUsers: defaultPermissions,
    },
    "Dashboard": {
        checkDashboard: defaultPermissions,

    },
    "Financeiro": {
        checkLCRE: defaultPermissions,
        checkCreditoFornec: defaultPermissions,
    },
    "Fulfillment": {
        checkGestPC: defaultPermissions,
        checkEnvios: defaultPermissions,
        checkRelacaoPC: defaultPermissions,
    },
    "Produção": {
        checkProducao: defaultPermissions,
        checkHistoricoProd: defaultPermissions,
        checkProdOrders: defaultPermissions,

    },
    "Vendas": {
        checkPrecificacao: defaultPermissions,
        checkCadastroPrecificacao: defaultPermissions,
        checkKPI: defaultPermissions,
        checkCatalog: defaultPermissions,
        checkManagementMLBs: defaultPermissions,
        checkMakeSellPrice: defaultPermissions,
    },
}

const columns = [
    {
      headerName: "Usuário",
      field: "name",
      type: "string",
      headerAlign: 'center',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (rowData: any) =>{
        return(
          <span>{rowData.row.name}</span>
        )
      }
    },
    {
        headerName: "Tipo",
        field: "type",
        type: "string",
        headerAlign: 'center',
        disableColumnMenu: true,
        flex: 1,
        renderCell: (rowData: any) =>{
            const type = rowData.row.type === 'user' ? 'usuário' : 'administrador';
            return(
              <span>{type}</span>
            )
          }
    },
    {
        headerName: "E-mail",
        field: "email",
        type: "string",
        headerAlign: 'center',
        disableColumnMenu: true,
        flex: 1,
        renderCell: (rowData: any) =>{
            return(
              <span>{rowData.row.email}</span>
            )
          }
    }
]

const options = {
    palette:{
        tableHeader: {
            main: '#F7CE3E',
            dark: '#ac902b',
            light: '#f8d764',
            contrastText: '#000'
        },
    },
    alternate: true,
}

export const Users2 = ()=>{
    const handleValidateStatus = useHandleErrors();
    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const fetchResource = (query:any=undefined) => axs.get<IUsers[]>('/auth/operators', {params: query}).then((r: any) => {
        return r.data;
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    })

    const data: State<IUsers[]> = useState<IUsers[]>([]);

    const addUserModalVisible: State<boolean> = useState<boolean>(false);

    const userData: State<ObjetoVazio> = useState<ObjetoVazio>({
        userName: '',
        userEmail: '',
        password: '',
        repeatedPassword: ''
    });

    const passwordVisible: State<boolean> = useState<boolean>(false);
    const confirmPasswordVisible: State<boolean> = useState<boolean>(false);

    const roles: State<UsersPermissionsRoles> = useState<UsersPermissionsRoles>({
        checkMLQuestions: defaultPermissions,
        checkMLMessages: defaultPermissions,
        checkCadastroOrigem: defaultPermissions,
        checkCategComponentes: defaultPermissions,
        checkAgrupMLB: defaultPermissions,
        checkAgrupSKU: defaultPermissions,
        checkMLBConc: defaultPermissions,
        checkAcompCompras: defaultPermissions,
        checkAnaliseCompras: defaultPermissions,
        checkShoppingList: defaultPermissions,
        checkConfig: defaultPermissions,
        checkConfigFiscais: defaultPermissions,
        checkConfigIntegracoes: defaultPermissions,
        checkConfigOutras: defaultPermissions,
        checkConfigUsers: defaultPermissions,
        checkDashboard: defaultPermissions,
        checkLCRE: defaultPermissions,
        checkCreditoFornec: defaultPermissions,
        checkGestPC: defaultPermissions,
        checkEnvios: defaultPermissions,
        checkRelacaoPC: defaultPermissions,
        checkProducao: defaultPermissions,
        checkHistoricoProd: defaultPermissions,
        checkPrecificacao: defaultPermissions,
        checkCadastroPrecificacao: defaultPermissions,
        checkKPI: defaultPermissions,
        checkCatalog: defaultPermissions,
        checkManagementMLBs: defaultPermissions,
        checkMakeSellPrice: defaultPermissions,
        checkProdOrders: defaultPermissions,
    })

    const updateModal: State<boolean> = useState<boolean>(false);
    const updateUserData: State<UpdateUserData> = useState<UpdateUserData>({name: '', email: '', id: ''});
    const {showHideModal} = useModal();

    useEffect(() => {
        data.set(fetchResource())
    }, [])

    const handleSubmit = async (evt: BaseSyntheticEvent)=>{
        evt.preventDefault();
        
        try{
            if(!updateModal.get()){
                if(userData.password.get() !== userData.repeatedPassword.get()){
                    toast.error("As senhas inseridas não são iguais!");
                    return
                }
                if(userData.password.get().length < 8){
                    toast.error("A senha é muito pequena, por favor forneça uma maior!");
                    return
                }
                const processedData:IUsers = {
                    name: userData.userName.get(),
                    email: userData.userEmail.get(),
                    password: userData.password.get(),
                    type: 'user',
                    roles: {
                        dashboard: roles.checkDashboard.attach(Downgraded).get(),
                        lcre: roles.checkLCRE.attach(Downgraded).get(),
                        kpi: roles.checkKPI.attach(Downgraded).get(),
                        settings_accounts: roles.checkConfigUsers.attach(Downgraded).get(),
                        settings: roles.checkConfig.attach(Downgraded).get(),
                        settings_tax: roles.checkConfigFiscais.attach(Downgraded).get(),
                        settings_integrations: roles.checkConfigIntegracoes.attach(Downgraded).get(),
                        settings_others: roles.checkConfigOutras.attach(Downgraded).get(),
                        relationPCsMlb: roles.checkRelacaoPC.attach(Downgraded).get(),
                        fulfillmentShipments: roles.checkEnvios.attach(Downgraded).get(),
                        managementPCs: roles.checkGestPC.attach(Downgraded).get(),
                        precificationAuto: roles.checkCadastroPrecificacao.attach(Downgraded).get(),
                        precification: roles.checkPrecificacao.attach(Downgraded).get(),
                        groupSkus: roles.checkAgrupSKU.attach(Downgraded).get(),
                        groupComponentsProduction: roles.checkCategComponentes.attach(Downgraded).get(),
                        groupPCsMlbs: roles.checkAgrupMLB.attach(Downgraded).get(),
                        anunciosConcorrentes: roles.checkMLBConc.attach(Downgraded).get(),
                        origin: roles.checkCadastroOrigem.attach(Downgraded).get(),
                        productionHistory: roles.checkHistoricoProd.attach(Downgraded).get(),
                        production: roles.checkProducao.attach(Downgraded).get(),
                        supplierCredit: roles.checkCreditoFornec.attach(Downgraded).get(),
                        questions: roles.checkMLQuestions.attach(Downgraded).get(),
                        customerService: roles.checkMLMessages.attach(Downgraded).get(),
                        cpr: roles.checkAnaliseCompras.attach(Downgraded).get(),
                        trackingPurchase: roles.checkAcompCompras.attach(Downgraded).get(),
                        catalog: roles.checkCatalog.attach(Downgraded).get(),
                        managementMlbs: roles.checkManagementMLBs.attach(Downgraded).get(),
                        makeSellPrice: roles.checkMakeSellPrice.attach(Downgraded).get(),
                        shoppingList: roles.checkShoppingList.attach(Downgraded).get(),
                        productionOrders: roles.checkProdOrders.attach(Downgraded).get(),
                    }
                }
                axs.post<IUsers[]>('/auth/operator/', processedData).then((r: any) => {
                    if(r.status === 200){
                        toast.success("Usuário Adicionado!");
                        data.set(fetchResource())
                        closeFunction();
                    }else{
                        toast.error("Ocorreu um erro ao fazer a solicitação.")
                        closeFunction();
                    }
                }).catch((err: any)=>{
                    handleValidateStatus(err.response)
                    closeFunction();
                })
            }
            else{
                try{
                    console.log("aqui")
                    const processedData:IUsers = {
                        name: updateUserData.name.value,
                        email: updateUserData.email.value,
                        roles: {
                            dashboard: roles.checkDashboard.attach(Downgraded).get(),
                            lcre: roles.checkLCRE.attach(Downgraded).get(),
                            kpi: roles.checkKPI.attach(Downgraded).get(),
                            settings_accounts: roles.checkConfigUsers.attach(Downgraded).get(),
                            settings: roles.checkConfig.attach(Downgraded).get(),
                            settings_tax: roles.checkConfigFiscais.attach(Downgraded).get(),
                            settings_integrations: roles.checkConfigIntegracoes.attach(Downgraded).get(),
                            settings_others: roles.checkConfigOutras.attach(Downgraded).get(),
                            relationPCsMlb: roles.checkRelacaoPC.attach(Downgraded).get(),
                            fulfillmentShipments: roles.checkEnvios.attach(Downgraded).get(),
                            managementPCs: roles.checkGestPC.attach(Downgraded).get(),
                            precificationAuto: roles.checkCadastroPrecificacao.attach(Downgraded).get(),
                            precification: roles.checkPrecificacao.attach(Downgraded).get(),
                            groupSkus: roles.checkAgrupSKU.attach(Downgraded).get(),
                            groupComponentsProduction: roles.checkCategComponentes.attach(Downgraded).get(),
                            groupPCsMlbs: roles.checkAgrupMLB.attach(Downgraded).get(),
                            anunciosConcorrentes: roles.checkMLBConc.attach(Downgraded).get(),
                            origin: roles.checkCadastroOrigem.attach(Downgraded).get(),
                            productionHistory: roles.checkHistoricoProd.attach(Downgraded).get(),
                            production: roles.checkProducao.attach(Downgraded).get(),
                            supplierCredit: roles.checkCreditoFornec.attach(Downgraded).get(),
                            questions: roles.checkMLQuestions.attach(Downgraded).get(),
                            customerService: roles.checkMLMessages.attach(Downgraded).get(),
                            cpr: roles.checkAnaliseCompras.attach(Downgraded).get(),
                            trackingPurchase: roles.checkAcompCompras.attach(Downgraded).get(),
                            catalog: roles.checkCatalog.attach(Downgraded).get(),
                            managementMlbs: roles.checkManagementMLBs.attach(Downgraded).get(),
                            makeSellPrice: roles.checkMakeSellPrice.attach(Downgraded).get(),
                            shoppingList: roles.checkShoppingList.attach(Downgraded).get(),
                            productionOrders: roles.checkProdOrders.attach(Downgraded).get(),
                        }
                    }
                    axs.put<IUsers[]>('/auth/operator/'+updateUserData.id.value, processedData).then((r: any) => {
                        if(r.status === 200){
                            toast.success("Usuário Atualizado!");
                            data.set(fetchResource())
                            closeFunction();
                        }else{
                            toast.error("Ocorreu um erro ao fazer a solicitação.")
                            closeFunction();
                        }
                    }).catch((err: any)=>{
                        handleValidateStatus(err.response)
                        closeFunction();
                    })
                }
                catch(err){
                    if(process.env.REACT_APP_AMBIENT === 'dev'){
                        console.log(err)
                    }
                }                
            }

        }
        catch(err){
            if(process.env.REACT_APP_AMBIENT === 'dev'){
                console.log(err)
            }
        }
    }


    const closeFunction = ()=>{
        addUserModalVisible.set(false)
        updateModal.set(false);
        userData.set({
            userName: '',
            userEmail: '',
            password: '',
            repeatedPassword: ''
        })
        Object.keys(roles.get()).forEach((item: any)=>{
            roles[item as keyof State<UsersPermissionsRoles>].merge(defaultPermissions)
        })
    }


    const confirmDelete = async(value: boolean, id: string | number)=>{
        if(value){
            axs.delete<IUsers[]>('/auth/operator/'+id).then((r: any) => {
                if(r.status === 200){
                    const aux_data = data.attach(Downgraded).get()
                    const filteredData = aux_data.filter((item: any)=> item._id !== id)
                    toast.success("Usuário excluído!")
                    data.set(filteredData);
                    showHideModal({show: false, title: "", execute: (confirm: boolean)=> false})
                }else{
                    toast.error("Ocorreu um erro ao fazer a solicitação.")
                }
            }).catch((err: any)=>{
                handleValidateStatus(err.response)
            })
        }else{
            toast.info("Você escolheu não deletar!");
        }
    }

    const handleUpdate = async(value: IUsers)=>{
        axs.get<IUsers[]>('/auth/operator/'+value._id).then((r: any) => {
            if(r.status === 200){
                const data_roles = r.data.roles;
                updateUserData.set({'name': r.data.name, 'email': r.data.email, id: r.data._id})
                roles.set({
                    checkDashboard: data_roles.dashboard,
                    checkLCRE: data_roles.lcre,
                    checkKPI: data_roles.kpi,
                    checkConfigUsers: data_roles.settings_accounts,
                    checkConfig: data_roles.settings,
                    checkConfigFiscais: data_roles.settings_tax,
                    checkConfigIntegracoes: data_roles.settings_integrations,
                    checkConfigOutras: data_roles.settings_others,
                    checkRelacaoPC: data_roles.relationPCsMlb,
                    checkEnvios: data_roles.fulfillmentShipments,
                    checkGestPC: data_roles.managementPCs,
                    checkCadastroPrecificacao: data_roles.precificationAuto,
                    checkPrecificacao: data_roles.precification,
                    checkAgrupSKU: data_roles.groupSkus,
                    checkCategComponentes: data_roles.groupComponentsProduction,
                    checkAgrupMLB: data_roles.groupPCsMlbs,
                    checkMLBConc: data_roles.anunciosConcorrentes,
                    checkCadastroOrigem: data_roles.origin,
                    checkHistoricoProd: data_roles.productionHistory,
                    checkProducao: data_roles.production,
                    checkCreditoFornec: data_roles.supplierCredit,
                    checkMLQuestions: data_roles.questions,
                    checkMLMessages: data_roles.customerService,
                    checkAnaliseCompras: data_roles.cpr,
                    checkAcompCompras: data_roles.trackingPurchase,
                    checkCatalog: data_roles.catalog,
                    checkManagementMLBs: data_roles.managementMlbs,
                    checkMakeSellPrice: data_roles.makeSellPrice,
                    checkShoppingList: data_roles.shoppingList,
                    checkProdOrders: data_roles.productionOrders,
                })
                updateModal.set(true);
                addUserModalVisible.set(true)
            }else{
                toast.error("Ocorreu um erro ao fazer a solicitação.")
            }
        }).catch((err: any)=>{
            handleValidateStatus(err.response)
        })
    }

    const handleUpdatePassword = async()=>{
        if(userData.password.get() !== userData.repeatedPassword.get()){
            toast.error("As senhas inseridas não são iguais!");
            return
        }
        if(userData.password.get().length < 8){
            toast.error("A senha é muito pequena, por favor forneça uma maior!");
            return
        }
        axs.put<IUsers[]>('/auth/changepassword/'+updateUserData.id.value, {'password': userData.password.get()}).then((r: any) => {
            if(r.status === 200){
                toast.success('A senha do usuário foi atualizada!');
                data.set(fetchResource())
                closeFunction()
            }else{
                toast.error("Ocorreu um erro ao fazer a solicitação.")
                closeFunction()
            }
        }).catch((err: any)=>{
            handleValidateStatus(err.response)
            closeFunction()
        })
    }

    const renderCheckBoxes = (tab: string)=>{
        const roleNames = Object.keys(rolesTabs[tab]);
        return(
            <div className="checkboxWrapper">
                <div className="checkboxInlineContainer">
                    <CheckBoxes
                        label="Todos" 
                        id="all" 
                        name="all"
                        value="all"
                        required={false}
                        styleLabel={{'fontSize': '1.8rem'}}
                        onChange={(evt: any)=>{
                            if(evt.target.checked){
                                for(let name of roleNames){
                                    roles[name as keyof State<UsersPermissionsRoles>].set({'read': true, 'write': true, 'delete': true, 'update': true})
                                }
                            }else{
                                for(let name of roleNames){
                                    roles[name as keyof State<UsersPermissionsRoles>].set({'read': false, 'write': false, 'delete': false, 'update': false})
                                }
                            }
                        }}
                        checked={
                            roleNames.every((name: any)=> 
                            roles[name as keyof State<UsersPermissionsRoles>].read.value &&  
                            roles[name as keyof State<UsersPermissionsRoles>].write.value &&  
                            roles[name as keyof State<UsersPermissionsRoles>].update.value &&  
                            roles[name as keyof State<UsersPermissionsRoles>].delete.value)
                        }
                    />
                </div>
                {
                    //USAR O .SOME OU .EVERY PARA VERIFICAR O CHECKBOX TODOS
                    roleNames.map((item: any)=>{
                        return(
                            <>
                                <div className="checkboxInlineContainer" style={{marginLeft: '5rem'}}>
                                    <CheckBoxes
                                        label={rolesLabels[item]} 
                                        id={`${item}-main`}
                                        name="ml_questionsl"
                                        required={false}
                                        styleLabel={{'fontSize': '1.4rem'}}
                                        checked={ roles[item as keyof State<UsersPermissionsRoles>].read.value &&  roles[item as keyof State<UsersPermissionsRoles>].write.value &&  roles[item as keyof State<UsersPermissionsRoles>].update.value &&  roles[item as keyof State<UsersPermissionsRoles>].delete.value}
                                        onChange={(evt: React.BaseSyntheticEvent)=> {
                                            if(evt.target.checked){
                                                roles[item as keyof State<UsersPermissionsRoles>].set({'read': true, 'write': true, 'delete': true, 'update': true})
                                            }else{
                                                roles[item as keyof State<UsersPermissionsRoles>].set({'read': false, 'write': false, 'delete': false, 'update': false})
                                            }
                                        }}
                                    />
                                </div>
                                <div className="checkboxInlineContainer" style={{marginLeft: '10rem'}}>
                                    <CheckBoxes
                                        label="Visualizar" 
                                        id={`${item}-read`} 
                                        name="read"
                                        required={false}
                                        checked={roles[item as keyof State<UsersPermissionsRoles>].read.value}
                                        onChange={()=> roles[item as keyof State<UsersPermissionsRoles>].merge({'read': !roles[item as keyof State<UsersPermissionsRoles>].read.value})}
                                    />
                                    <CheckBoxes
                                        label="Criar" 
                                        id={`${item}-write`}
                                        name="write"
                                        required={false}
                                        checked={roles[item as keyof State<UsersPermissionsRoles>].write.value}
                                        onChange={()=> roles[item as keyof State<UsersPermissionsRoles>].merge({'write': !roles[item as keyof State<UsersPermissionsRoles>].write.value})}
                                    />
                                    <CheckBoxes
                                        label="Alterar" 
                                        id={`${item}-update`}
                                        name="update"
                                        required={false}
                                        checked={roles[item as keyof State<UsersPermissionsRoles>].update.value}
                                        onChange={()=> roles[item as keyof State<UsersPermissionsRoles>].merge({'update': !roles[item as keyof State<UsersPermissionsRoles>].update.value})}
                                    />
                                    <CheckBoxes
                                        label="Deletar" 
                                        id={`${item}-delete`}
                                        name="delete"
                                        required={false}
                                        checked={roles[item as keyof State<UsersPermissionsRoles>].delete.value}
                                        onChange={()=>roles[item as keyof State<UsersPermissionsRoles>].merge({'delete': !roles[item as keyof State<UsersPermissionsRoles>].delete.value})}
                                    />
                                </div>
                            </>

                        )
                    })
                }
            </div>
        )
    }

    if(data.promised){ //Enquanto for promisse ou estiver carregando
        return (
            <>
                <div className={`wrapper`}> 
                    <div className="content-wrapper">
                        <main className="main-content container-fluid">
                            <div className="loading">
                                <AiOutlineLoading3Quarters className="loading-icon" />
                            </div>
                        </main>
                    </div>
                </div>
            </>
        )
    }

    if (data.error) { // Quando o promise dar reject
        return (<ErrorScreen />)
    }

    return(
        <>
            {
                addUserModalVisible.get() === true
                ? <BasicModal closeFunction={closeFunction}>
                    <form className="add-user-form" onSubmit={handleSubmit}>
                        <div className="input-container">
                            <input type="text" name="username" id="username" value={updateModal.get() ? updateUserData.name.value : userData.userName.get()} required={true}
                                onChange={(evt: React.BaseSyntheticEvent)=> updateModal.get() ? updateUserData.merge({'name': evt.target.value}) : userData.userName.set(evt.target.value)}
                            />
                            <label htmlFor="username">Nome do Usuário</label>
                        </div>
                        <div className="input-container">
                            <input type="email" name="email" id="email" value={updateModal.get() ? updateUserData.email.value : userData.userEmail.get()} required={true}
                                onChange={(evt: React.BaseSyntheticEvent)=> updateModal.get() ? updateUserData.merge({"email": evt.target.value}) : userData.userEmail.set(evt.target.value)}
                            />
                            <label htmlFor="email">E-mail do Usuário</label>
                        </div>
                        <div className="input-container">
                            <input type={passwordVisible.get() ? 'text':'password'} name="password" id="password" value={userData.password.get()} required={updateModal.get() ? false:true}
                                onChange={(evt: React.BaseSyntheticEvent)=> userData.password.set(evt.target.value)}
                            />
                            <label htmlFor="password">Senha do Usuário</label>
                            {
                                passwordVisible.get() ? <FiEyeOff className="showHidePass" onClick={()=>{passwordVisible.set(!passwordVisible.get())}} /> : <FiEye className="showHidePass" onClick={()=>{passwordVisible.set(!passwordVisible.get())}}/>
                            }
                        </div>
                        <div className="input-container">
                            <input type={confirmPasswordVisible.get() ? 'text':'password'} name="repeat-password" id="repeat-password" value={userData.repeatedPassword.get()} required={updateModal.get() ? false:true}
                                onChange={(evt: React.BaseSyntheticEvent)=> userData.repeatedPassword.set(evt.target.value)}
                            />
                            <label htmlFor="repeat-password">Confirme a Senha</label>
                            {
                                confirmPasswordVisible.get() ? <FiEyeOff className="showHidePass" onClick={()=>{confirmPasswordVisible.set(!confirmPasswordVisible.get())}} /> : <FiEye className="showHidePass" onClick={()=>{confirmPasswordVisible.set(!confirmPasswordVisible.get())}}/>
                            }
                        </div>

                        {
                            updateModal.get() ?
                            <div className="input-container">
                                <button type="button" onClick={handleUpdatePassword}>Atualizar Apenas a Senha</button>
                            </div>
                            :
                            null
                        }

                        <div className="tabsForm">
                            <h3>Permissões</h3>
                            <Tabs>
                                <TabList>
                                    <Tab>Atendimento</Tab>
                                    <Tab>Cadastros</Tab>
                                    <Tab>Compras</Tab>
                                    <Tab>Configurações</Tab>
                                    <Tab>Dashboard</Tab>
                                    <Tab>Financeiro</Tab>
                                    <Tab>Fulfillment</Tab>
                                    <Tab>Produção</Tab>
                                    <Tab>Vendas</Tab>
                                </TabList>

                                <TabPanel>
                                    {
                                        renderCheckBoxes('Atendimento')
                                    }
                                </TabPanel>
                                
                                <TabPanel>
                                    {
                                        renderCheckBoxes('Cadastro')
                                    }
                                </TabPanel>
                                
                                <TabPanel>
                                    {
                                        renderCheckBoxes('Compras')
                                    }
                                </TabPanel>

                                <TabPanel>
                                    {
                                        renderCheckBoxes('Configurações')
                                    }
                                </TabPanel>

                                <TabPanel>
                                    {
                                        renderCheckBoxes('Dashboard')
                                    }
                                </TabPanel>
                            
                                <TabPanel>
                                    {
                                        renderCheckBoxes('Financeiro')
                                    }
                                </TabPanel>
                            
                                <TabPanel>
                                    {
                                        renderCheckBoxes('Fulfillment')
                                    }
                                </TabPanel>
                            
                                <TabPanel>
                                    {
                                        renderCheckBoxes('Produção')
                                    }
                                </TabPanel>
                            
                                <TabPanel>
                                    {
                                        renderCheckBoxes('Vendas')
                                    }
                                </TabPanel>
                            </Tabs>
                        </div>

                        <div className="input-container">
                            <button type="submit">{updateModal.get() ? "Modificar Usuário":"Cadastrar Usuário"}</button>
                        </div>
                    </form>
                </BasicModal>

                : null
            }
            <ModalConfirm />
            <section className="users-section">
                <div className="input-container__add-user">
                    <button onClick={()=> addUserModalVisible.set(true)}>Adicionar Usuário</button>
                </div>
                <main className='container-fluid' style={{padding: '0'}}>
                    {
                        data.length > 0 ?
                        <VirtualizedGrid 
                            columns={columns} 
                            rows={data.attach(Downgraded).get()} 
                            options={options}
                            title="Usuários" 
                            actions={[
                                {icon: 'edit', title: 'Editar Item', method: (evt: any, row:any)=> handleUpdate(row)},
                                {icon: 'delete', title: 'Deletar Item', method: (evt: any, row:any)=> showHideModal({show: true, title: "Quer mesmo deletar esse item?", execute: (confirm: boolean)=> confirmDelete(confirm, row._id)})}, 
                            ]}
                            tableHeight="50vh"
                            defaultSort={{field: 'name', direction: 'asc'}}
                        />
                        : null
                    }
                </main>
            </section>
        </>
    )
}