import { columns, options } from '../../../../components/table/files/SellingPriceFormulationData';
import { ISellingPriceFormulaton, CardResultsSPF } from "../../../../types/Types";
import VirtualizedGrid from '../../../../components/table/VirtualizedGrid';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { toast } from 'react-toastify';
import { ErrorScreen } from '../../../../components/ErrorScreen/ErrorScreen';
import { useHandleErrors } from '../../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import { Loading } from '../../../../components/Loading/Loading';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { useDarkMode } from '../../../../hooks/useDarkMode';
import CalculateIcon from '@mui/icons-material/Calculate';

const INPUT_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.6rem)';

export const Manual = ()=>{
    const handleValidateStatus = useHandleErrors();
    const {DarkMode, } = useDarkMode();
    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const fetchResource = (query:any={'type': 'MANUAL'}) => axs.get<ISellingPriceFormulaton[]>('/others/precify/history', {params: query}).then((r: any) => {
        handleValidateStatus(r)
        return r.data;
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    });

    const data: State<ISellingPriceFormulaton[]> = useState<ISellingPriceFormulaton[]>([]);

    const costProduct: State<number> = useState<number>(0);
    const shippingCost: State<number> = useState<number>(0);
    const marketPlaceFee: State<number> = useState<number>(0);
    const tax: State<number> = useState<number>(0);
    const product: State<string> = useState<string>('');
    const variableInput: State<number> = useState<number>(0);
    const selectInputType: State<string> = useState<string>('');
    const cardResults: State<CardResultsSPF> = useState<CardResultsSPF>({
        'margin': 0, 
        'profit': 0, 
        'sellPrice': 0,
        'product': ''
    });


    const inputTypes:object = {
        'selling_price': 'Valor da Venda (R$)',
        'margin': 'Margem (%)',
        'profit':'Lucro (R$)'
    }
    const defaultTheme  = createTheme({
        palette:{
          mode: DarkMode ? 'dark' : 'light',
        },
    });

    useEffect(() => {
        data.set(fetchResource())
    }, [])

    const handlePost = async(evt: React.BaseSyntheticEvent)=>{
        evt.preventDefault();
        
        let margin: number | null = null;
        let profit: number | null = null; 
        let sellingPrice: number | null = null;

        const inputValue: string = variableInput.attach(Downgraded).get().toString();

        if(selectInputType.get() === "selling_price"){
            sellingPrice = parseFloat(inputValue)
        }else if(selectInputType.get() === "margin"){
            margin = parseFloat(inputValue)/100
        }else if(selectInputType.get() === "profit"){
            profit = parseFloat(inputValue)
        }else{
            return false
        }

        const processedData: object = {
            'cost_product': parseFloat(String(costProduct.get())),
            'cost_shippment': parseFloat(String(shippingCost.get())),
            'fee_marketplace': parseFloat((marketPlaceFee.get()/100).toFixed(2)),
            'tax': parseFloat((tax.get()/100).toFixed(2)),
            'margin': margin,
            'profit': profit,
            'sell_price': sellingPrice,
            "product": (product.get() !== undefined && product.get() !== null && product.get() !== "") ? product.get() : "N/A"
        }
        axs.post<ISellingPriceFormulaton[]>('/others/precify/', processedData).then((r: any) => {
            if(r.status === 200){
                cardResults.set({margin: r.data.margin, profit: r.data.profit, sellPrice: r.data.sellPrice, product: (product.get() !== undefined && product.get() !== null && product.get() !== "") ? product.get() : undefined})
                data.set(fetchResource())
            }else{
                toast.error("Ocorreu um erro ao fazer a solicitação.")
            }
        }).catch((err: any)=>{
            handleValidateStatus(err.response)
        })
    }

    if(data.promised){ //Enquanto for promisse ou estiver carregando
        return (
            <main className="main-content container-fluid">
                <Loading />
            </main>
        )
    }

    if (data.error) { // Quando o promise dar reject
        return (<ErrorScreen />)
    }

    return(
        <section className="manual-section">
            <ThemeProvider theme={defaultTheme}>
                <Box component="form" onSubmit={(evt: React.BaseSyntheticEvent)=> handlePost(evt)}
                style={{width: "100%"}}>
                    <Paper elevation={3}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '3rem',
                            marginBottom: '2rem',
                            padding: '2rem'
                        }}
                    >
                        <Paper elevation={3} sx={{display: 'flex', flexDirection: 'column', width: '60%', gap: '2rem', padding: '2rem'}}>
                            <Paper elevation={1} sx={{padding: '1rem', display: 'flex', gap: '1rem'}}>
                                <TextField type='number' inputProps={{step: "0.01"}} required id="product_cost" name="product_cost" label="Custo do Produto (R$)" variant="outlined" onChange={(evt: any)=> costProduct.set(evt.target.value)} 
                                    sx={{minWidth: '20rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                />

                                <TextField type='number' inputProps={{step: "0.01"}} required id="shipping_cost" name="shipping_cost" label="Custo do Frete (R$)" variant="outlined" onChange={(evt: any)=> shippingCost.set(evt.target.value)} 
                                    sx={{minWidth: '20rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                />
                            </Paper>

                            <Paper elevation={1} sx={{padding: '1rem', display: 'flex', gap: '1rem'}}>
                                <TextField type='number' inputProps={{step: "0.01"}} required id="marketplace_fee" name="marketplace_fee" label="Taxa do Market Place (%)" variant="outlined" onChange={(evt: any)=> marketPlaceFee.set(evt.target.value)} 
                                    sx={{minWidth: '20rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                />

                                <TextField type='number' inputProps={{step: "0.01"}} required id="tax" name="tax" label="Imposto (%)" variant="outlined" onChange={(evt: any)=> tax.set(evt.target.value)} 
                                    sx={{minWidth: '20rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                />
                            </Paper>
                            <hr />
                            <Paper elevation={1} sx={{padding: '1rem', display: 'flex', gap: '1rem'}}>
                                <FormControl variant='outlined' sx={{minWidth: '20rem', width: '100%'}}>
                                    <InputLabel id="field_type-label" style={{fontSize: INPUT_FONT_SIZE}}>Tipo do Campo</InputLabel>
                                    <Select
                                        required
                                        labelId="field_type-label"
                                        id="field_type"
                                        label="Tipo do Campo"
                                        onChange={(evt: SelectChangeEvent)=>{
                                            selectInputType.set(evt.target.value)
                                        }}
                                        style={{fontSize: INPUT_FONT_SIZE}}
                                    >
                                    <MenuItem value={'selling_price'}>Valor da Venda (R$)</MenuItem>
                                    <MenuItem value={'margin'}>Margem (%)</MenuItem>
                                    <MenuItem value={'profit'}>Lucro (R$)</MenuItem>
                                    </Select>
                                </FormControl>

                                <TextField type='number' inputProps={{step: "0.01"}} required id={selectInputType.get()} name={selectInputType.get()} label={inputTypes[selectInputType.get() as keyof object] ?? "Valor do Input"} variant="outlined" onChange={(evt: any)=> variableInput.set(evt.target.value)} 
                                    sx={{minWidth: '20rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                />
                            </Paper>
                            <hr />
                            <Paper elevation={1} sx={{padding: '1rem', display: 'flex', gap: '1rem'}}>
                                <TextField id="product" name="product" label="Produto" variant="outlined" onChange={(evt: any)=> product.set(evt.target.value)} 
                                    sx={{minWidth: '20rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                />
                            </Paper>
                            <Button startIcon={<CalculateIcon />} variant="contained" color='success' sx={{fontSize: 'clamp(1.2rem, 1.2vw, 1.6rem)', fontWeight: 'bold', padding: '1rem 0', width: '100%', display: 'flex', alignItems: 'center', '& .MuiButton-startIcon':{margin: '0'}}} type='submit'>
                                Calcular
                            </Button>
                        </Paper>
                        {
                            cardResults.product.value ?
                            <Paper elevation={3} sx={{width: '60%', padding: '2rem'}}>
                                <h3 title={cardResults.product.value} style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{cardResults.product.value}</h3>
                            </Paper>
                            :
                            null
                        }
                        {
                            cardResults.margin.value !== 0 && cardResults.profit.value !== 0 && cardResults.sellPrice.value !== 0 ?
                            <Box className="totalValuesContainer">
                                <div>
                                    <h3>Margem:&nbsp;
                                        <strong>{(cardResults.margin.value*100).toFixed(2)}%</strong>
                                    </h3>
                                </div>
                                <div>
                                    <h3>Lucro:&nbsp;
                                        <strong>{cardResults.profit.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong>
                                    </h3>
                                </div>
                                <div>
                                    <h3>Preço de Venda:&nbsp;
                                        <strong>{cardResults.sellPrice.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong>
                                    </h3>
                                </div>
                            </Box>
                            :
                            null
                        }
                    </Paper>
                </Box>
            </ ThemeProvider>
            {
                data.length > 0 ?
                <VirtualizedGrid 
                    rows={data.attach(Downgraded).get()}
                    columns={columns}
                    options={options}
                    title="Histórico de Preços"
                    defaultSort={{field: 'date', direction: 'desc'}}
                />
                : null
            }
        </section>
    )
}