import './style.scss';
import {FiArrowUp, FiArrowDown} from 'react-icons/fi';
import CountUp from 'react-countup';
import { useDarkMode } from '../../hooks/useDarkMode';


export const Card = (props: any) =>{
    const {DarkMode, } = useDarkMode();
    let margin: string | number;
    let contentValue: any;
    if(props.percent){
        contentValue = props.content*100
    }
    else if(props.integer){
        contentValue =  Math.round(props.content)
    }
    else if(props.text){
        contentValue =  props.content
    }

    if(props.lastMonth > 0){
        margin = ((props.content - props.lastMonth) / props.lastMonth) * 100;
        if(+margin > 1000){
            margin = `${margin.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}%`;
            margin = margin.replace('.', ',');
            margin = margin.replace(',', '.');
        }else{
            margin = `${margin.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}%`;
            margin = margin.replace('.', ',');
        }
    }
    else{
        margin = '-';
    }
    return (
        <div className={`card-component ${DarkMode ? 'dark-mode-card' : ''}`} style={props.style}>
            <header><span>{props.title}</span></header>
            <main>
                {
                    props.increment ?
                    <span style={props.styleContent}>
                        <CountUp
                        start={contentValue}
                        end={contentValue}
                        duration={3}
                        separator="."
                        decimals={props.decimals ? props.decimals : 0}
                        decimal=","
                        prefix={props.prefix}
                        suffix={props.percent ? "%" : ""}
                        >
                            {({ countUpRef }) => (
                                <span ref={countUpRef} />
                            )}
                        </CountUp>
                    </span>
                    :
                    <span style={props.styleContent}>{contentValue}</span>
                }
            </main>
            {
                !props.simple ?
                <>
                    <hr />
                    {
                        !DarkMode ?
                        <footer style={{color: (props.content > props.lastMonth ?  "#2ECC71" : "#C0392B"), display: (props.lastMonth === null ? "none":"flex")}}>
                            {
                                props.content > props.lastMonth 
                                ? <FiArrowUp className='icon-up' /> : <FiArrowDown className='icon-down' />
                            }
                            <span title="Referente ao mês anterior">{margin}</span>
                        </footer>
                        :
                        <footer style={{color: (props.content > props.lastMonth ?  "#2ff07f" : "#e74330"), display: (props.lastMonth === null ? "none":"flex")}}>
                            {
                                props.content > props.lastMonth 
                                ? <FiArrowUp className='icon-up' /> : <FiArrowDown className='icon-down' />
                            }
                            <span title="Referente ao mês anterior">{margin}</span>
                        </footer>
                    }
                </>
                :
                null

            }
        </div>
    )
}