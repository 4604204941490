import './style.scss';

import { useModal } from '../../hooks/useModal';


export const ModalConfirm = (props: any) =>{
    const {ModalData, showHideModal} = useModal();

    const handleClickOutside = (evt: React.BaseSyntheticEvent) =>{
        const elmClass = evt.target.getAttribute("class")
        const classesThatClose = ["modal-wrapper"]
        if(classesThatClose.includes(elmClass)){
            ModalData?.execute(false); showHideModal({show: false, title: ModalData?.title, execute: ModalData?.execute})
        }else{
            return
        }
    }

    return (
        <div className="modal-wrapper" style={{display: ModalData?.show ? 'flex':'none'}} onClick={handleClickOutside}>
            <div className="confirm-modal">
                <h3 className="confirm-modal-title">{ModalData?.title}</h3>
                <div className="btns-container">
                    <button className="yes-btn" onClick={()=> {ModalData?.execute(true); showHideModal({show: false, title: ModalData?.title, execute: ModalData?.execute})}}>Sim</button>
                    <button className="no-btn" onClick={()=> {ModalData?.execute(false); showHideModal({show: false, title: ModalData?.title, execute: ModalData?.execute})}}>Não</button>
                </div>
            </div>
        </div>
    )
}