import {BsAwardFill, BsFillTagFill, BsLightningFill } from 'react-icons/bs';
import { FaMoneyBill } from 'react-icons/fa';

export const CaptionsLcre = {
    title: 'Legendas LCRE',
    data: {
        colors: {
            'Margem Negativa (Margem menor que 0)': "#ffa89a",
            'Margem Ruim (Margem maior que 0 e menor que 5)': "#ffdbaf",
            'Margem Media (Margem maior que 5 e menor que 10)': "#faffb1",
            'Margem Boa (Margem maior que 10 e menor que 20)': "#d4ffb2",
            'Margem Alta (Margem maior que 20)': "#b2ffbb",
            'Venda Cancelada': "#c8d6e5 ",
            'Venda com cobrança de frete indevida': '#222f3e',
            'Sem Custo / Margem Desproporcional': "#7ed6df"
        },
        tags:{
            "Rebate Campanha": ["#f0932b", (<BsAwardFill />)],
            "Promoção": ["#e056fd", (<BsFillTagFill  />)],
            "Fulfillment": ["#20bf6b", (<BsLightningFill  />)],
            "Cobrança Devolução": ["#eb3b5a", (<FaMoneyBill  />)],
        }
    }
}