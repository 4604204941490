import { toast } from 'react-toastify';
import {FaPlus} from 'react-icons/fa'
import noData from '../../../assets/images/nodata.svg';
import { Filters } from '../../../components/Filters/Filters';
import { InputMainContainer } from '../../../components/Form/BasicForm';
import { Accordion } from '../../../components/accordion/Accordion';
import {CaptionsCpr} from '../../../components/accordion/files/CaptionsCPR';
import {FaMinus} from 'react-icons/fa';
import { FaShoppingCart } from 'react-icons/fa';
import { ModalConfirm } from '../../../components/modal/ModalConfirm';
import { useModal } from '../../../hooks/useModal';
import { BasicModal } from "../../../components/modal/BasicModal";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Popper from '@material-ui/core/Popper';
import { useDarkMode } from '../../../hooks/useDarkMode';
import { CPRDataType, IProductsData, ICPRData, FilterTimeAdsMetrics, MinMaxDaysAdsMetrics } from "../../../types/Types"
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import './style.scss';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import {FiStar, FiTrendingDown, FiExternalLink} from 'react-icons/fi';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import placeholder from '../../../assets/images/placeholder.jpg';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { GridColumnHeaderParams, GridFilterItem } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import DatePicker, {Locale, Day} from 'react-modern-calendar-datepicker';
import moment from 'moment';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { Loading } from '../../../components/Loading/Loading';
import { BugReportModal } from '../../../components/modal/BugReportModal';

const INPUT_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.6rem)';


const StyledPopper = styled(Popper)({
    "& .MuiAutocomplete-listbox": {
      '& li':{
        fontSize: 'clamp(1rem, 1vw, 1.1rem)',
        maxWidth: '40rem',
      }
    }
});

const defaultTheme  = createTheme();


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function CheckboxesTags(props: any) {
  const { item, applyValue, options:tag_options } = props;
  // const selected_values = item.value ? item.value.map((elm: any)=> elm.title) : [];

  const handleFilterChange = (event: any, value: any) => {
    applyValue({ ...item, value: value });
  };

  return (
    <Autocomplete
      multiple
      options={tag_options}
      disableCloseOnSelect
      getOptionLabel={(option: any) => option.title}
      defaultValue={item.value}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
            style={{ marginRight: 8 }}
            // checked={selected_values.includes(option.title)}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label="Tags" variant="standard" sx={{'& .MuiChip-root':{fontSize: 'clamp(1rem, 1.1vw, 1.2rem)'}}} />
      )}
      PopperComponent={StyledPopper}
      onChange={handleFilterChange}
    />
  );
}


const tooltipHeaderValues = {
  "Vendas-Período": "Total de Vendas durante o período selecionado (histórico de dias)",
  "Venda-Estimada": "Cálculo Realizado com base nas Vendas do Período e calculando projeção com base nos dias ativos de cada anúncio. Influenciado pela tração",
  "Lucro-Estimado": "Cálculo Realizado à partir da projeção de vendas e do lucro médio.",
  "Origem": "Determinado pela regra do SKU, utilizado para separação e indicação da Origem (Comprado, parceria Fornecedor).",
  "SKU": "Código interno do produto",
  "Tags": <div>Ícones para Rápida identificação de situações do produto. Você pode usar o campo de pesqisa para obter dados mais específicos, 
  basta utilizar alguma das seguintes palavras-chave: <strong style={{textDecoration: "underline"}}>novo sku ou Sem histórico.</strong></div>,
  "Fornecedor": "Último Fornecedor Comprado",
  "Traction": "Tração Ajusta a Projeção de Vendas de acordo com seu valor (tração * vendas estimadas). Utilizado para produtos novos ou que se estime uma nova projeção de vendas.",
  "Compra-Mínima": "Quantidade a comprar com base na projeção de vendas para suprir a quantidade necessário de 30dias de estoque.",
  "Duração-Estoque": "A duração do estoque baseada na projeção de vendas diárias",
  "Dias-estoque-ideal": "Quantidade de Dias a definir para uma compra ideal. Define a métrica para o campo Compra Ideal",
  "Compra-Ideal": "Quantidade a comprar com base na projeção de vendas para suprir a quantidade definida no campo Dias Estoque Ideal",
  "Estoque": "Estoque Total em todos os Depósitos(PR, SP, SC, ML FULL, ETP)"

}

const tagColors: any = {
    "SKU Novo": ["SKU Novo", "#f7b731", (<FiStar />)],
    "Sem Histórico": ["Sem Histórico vendas", "#227093", (<FiTrendingDown />)],
};

export const CPRTable = (props:any) =>{
  document.title = "Análise de Compras CPR - MeLiBeat";
  const handleValidateStatus = useHandleErrors();
  const apiRef = useGridApiRef ()


  //Componentes e estados referentes ao filtro de datas
  const Footer = ()=> {
      return(
          <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem', padding: "1rem 0" }}>
              <button
                  type="button"
                  onClick={() => {
                    const to = {
                      year: moment().year(),
                      month: moment().month() + 1,
                      day: moment().date(),
                    }
                    const from = {
                        year:  moment().subtract(90, 'days').year(),
                        month:  moment().subtract(90, 'days').month() + 1,
                        day: moment().subtract(90, 'days').date(),
                    }

                    startingFilterValues.set({"from": from, "to": to})
                    if(disableFilterBtn.get() === true){
                      disableFilterBtn.set(false)
                    }
                  }}
                  style={{
                      border: '#0fbcf9',
                      color: '#fff',
                      borderRadius: '0.5rem',
                      padding: '1rem 1rem',
                      backgroundColor: "#3498DB"
                  }}
              >
                  90 dias
              </button>
                  
              <button
                  type="button"
                  onClick={() => {
                    const to = {
                      year: moment().year(),
                      month: moment().month() + 1,
                      day: moment().date(),
                    }
                    const from = {
                        year:  moment().subtract(60, 'days').year(),
                        month:  moment().subtract(60, 'days').month() + 1,
                        day: moment().subtract(60, 'days').date(),
                    }

                    startingFilterValues.set({"from": from, "to": to})
                      if(disableFilterBtn.get() === true){
                        disableFilterBtn.set(false)
                      }
                      // data.set(fetchResource({"dateFrom": from, "dateTo": to}))

                  }}
                  style={{
                      border: '#0fbcf9',
                      color: '#fff',
                      borderRadius: '0.5rem',
                      padding: '1rem 1rem',
                      backgroundColor: "#3498DB"
                  }}
              >
                  60 dias
              </button>
          
              <button
                  type="button"
                  onClick={() => {
                    const to = {
                      year: moment().year(),
                      month: moment().month() + 1,
                      day: moment().date(),
                    }
                    const from = {
                        year:  moment().subtract(30, 'days').year(),
                        month:  moment().subtract(30, 'days').month() + 1,
                        day: moment().subtract(30, 'days').date(),
                    }

                    startingFilterValues.set({"from": from, "to": to})
                    if(disableFilterBtn.get() === true){
                      disableFilterBtn.set(false)
                    }

                  }}
                  style={{
                      border: '#0fbcf9',
                      color: '#fff',
                      borderRadius: '0.5rem',
                      padding: '1rem 1rem',
                      backgroundColor: "#3498DB"
                  }}
              >
                  30 dias
              </button>
          </div>
      )
  } 
  const startingFilterValues: State<FilterTimeAdsMetrics> = useState<FilterTimeAdsMetrics>({
    "to":{
        year: moment().year(),
        month: moment().month() + 1,
        day: moment().date(),
    },
    "from":{
        year: moment().subtract(30, 'days').year(),
        month: moment().subtract(30, 'days').month() + 1,
        day: moment().subtract(30, 'days').date(),
    }
  })

  const blackFridayFilter: any = useState<any>(undefined)

  const myCustomLocale: Locale = {
      // months list by order
      months: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
      ],
    
      // week days by order
      weekDays: [
        {
          name: 'Domingo', // used for accessibility 
          short: 'D', // displayed at the top of days' rows
          isWeekend: true, // is it a formal weekend or not?
        },
        {
          name: 'Segunda-Feira',
          short: 'S',
        },
        {
          name: 'Terça-Feira',
          short: 'T',
        },
        {
          name: 'Quarta-Feira',
          short: 'Q',
        },
        {
          name: 'Quinta-Feira',
          short: 'Q',
        },
        {
          name: 'Sexta-Feira',
          short: 'S',
        },
        {
          name: 'Sábado',
          short: 'S',
          isWeekend: true,
        },
      ],
    
      // just play around with this number between 0 and 6
      weekStartingIndex: 0,
    
      // return a { year: number, month: number, day: number } object
      getToday(gregorainTodayObject: any) {
        return gregorainTodayObject;
      },
    
      // return a native JavaScript date here
      toNativeDate(date: any) {
        return new Date(date.year, date.month - 1, date.day);
      },
    
      // return a number for date's month length
      getMonthLength(date:any) {
        return new Date(date.year, date.month, 0).getDate();
      },
    
      // return a transformed digit to your locale
      transformDigit(digit:any) {
        return digit;
      },
    
      // texts in the date picker
      nextMonth: 'Próximo Mês',
      previousMonth: 'Mês Anterior',
      openMonthSelector: 'Abrir Seletor de Meses',
      openYearSelector: 'Abrir Seletor de Anos',
      closeMonthSelector: 'Fechar Seletor de Meses',
      closeYearSelector: 'Fechar Seletor deAnos',
      defaultPlaceholder: 'Selecionar Período...',
    
      // for input range value
      from: '',
      to: '---',
    
    
      // used for input value when multi dates are selected
      digitSeparator: ',',
    
      // if your provide -2 for example, year will be 2 digited
      yearLetterSkip: 0,
    
      // is your language rtl or ltr?
      isRtl: false,
  }

  const minMaxDays: State<MinMaxDaysAdsMetrics> = useState<MinMaxDaysAdsMetrics>({
      maximumDate: {
          year: moment().year(),
          month: moment().month() + 1,
          day: moment().date(),
      },
      minimumDate: {
          year: moment().year() - 1,
          month: moment().month() + 1,
          day: moment().date(),
      }
  })

  //

  const checkVisibility = (column: string)=>{
      const storageColumnsData = localStorage.getItem('Análise de Compras - CPR') ? localStorage.getItem('Análise de Compras - CPR') : null;
      if(storageColumnsData !== null){
        const localData = JSON.parse(storageColumnsData);
        const shouldHide = localData.find((item: any)=> item.field === column);
        return shouldHide !== undefined ? !shouldHide.visibility : false
      }
      return false
  }

  let inputsFill: Map<string,any>;

  const processData = (data_value: CPRDataType)=>{
      inputsFill = new Map();
      const unprocessedArray = data_value.filter((item: ICPRData)=> item !== null);
      const processedArray:CPRDataType = unprocessedArray.map((item: any)=>{
          inputsFill.set(item._id, {
              traction: item.traction,
              stock_days_ideal: item.stock_days_ideal,
          })
          if(item.group_skus === true){
              item['group_id'] = item._id;
              item._id = item.skus.toString().replaceAll(',', ', ');
          }
          if(item.supplier.length > 1){
              item.supplier = item.supplier.toString().replaceAll(',', ', ');
          }
          
          let stockAllDuration = item.stockAll > 0 && item.project_sells > 0 ? item.stockAll / (item.project_sells * item.traction) : 0
          stockAllDuration = Math.round(stockAllDuration)
          let project_daily_sells = item.project_sells > 0 ? item.project_sells * item.traction : 0;
          // project_daily_sells = Math.round(project_daily_sells);

          let project_monthly_sells = item.project_sells > 0 ? (item.project_sells * item.traction) * 30 : 0
          // project_monthly_sells =  Math.round(project_monthly_sells);

          let minimun_buy = item.project_sells > 0 ? ((item.project_sells * item.traction) * 30) - item.stockAll : 0
          // minimun_buy = Math.round(minimun_buy)

          let ideal_buy = item.project_sells > 0 ? ((item.project_sells * item.traction) * item.stock_days_ideal) - item.stockAll : 0
          // ideal_buy = Math.round(ideal_buy)

          item["sku"] = item._id;
          let tags = [] 
          if(item['tags'].includes('new')) tags.push('SKU Novo');
          if(item['tags'].includes('without_history')) tags.push('Sem Histórico');
          item['tags'] = tags
          return {...item, stockAllDuration, project_daily_sells, project_monthly_sells, minimun_buy, ideal_buy}
      })
      inputs.set(Object.fromEntries(inputsFill))
      return processedArray
  }

  const token: string | undefined = localStorage.getItem('token') || undefined;
  const axs = axios.create({
      baseURL: `${process.env.REACT_APP_API}`,
      headers: { 'Authorization': `Bearer ${token}` }
  })

  const fetchResource = (query:any=undefined) => axs.get<CPRDataType[]>('/cpr/', {params: query}).then((r: any) => {
      handleValidateStatus(r)
      return processData(r.data)
  }).catch((err: any)=>{
      handleValidateStatus(err.response)
      return []
  })

  const data: State<CPRDataType> = useState<CPRDataType>([]);
  const showCartBtn: State<boolean> = useState<boolean>(false);
  const productsData: State<IProductsData> = useState<IProductsData>({products: [
      {
          "display_id": "",
          "id":'', 
          "title": '', 
          "supplier": '', 
          "ideal_buy": undefined, 
          "id_bling": ''
      }
  ],
  description: ''});
  const {ModalData, showHideModal} = useModal();
  const showDescModal: State<boolean> = useState<boolean>(false);
  const newProduct: State<string | undefined> = useState<string | undefined>('');
  const newQty: State<number> = useState<number>(1);
  const disableBtnGoToList: State<boolean> = useState<boolean>(false);
  const {DarkMode, } = useDarkMode();
  const disableFilterBtn: State<boolean> = useState<boolean>(false);
  const basedProjectionFilter: State<string | undefined> = useState<string | undefined>('activeDays')

  const inputs: any = useState(undefined);

  useEffect(() => {
    // console.log(startingFilterValues.get())
    const dates = convertObjectToString(startingFilterValues.get())
    // console.log(dates)
    data.set(fetchResource({"dateFrom": dates.stringDateFrom, "dateTo": dates.stringDateTo, 'blackFridayFilter': false, 'projectType': 'activeDays'}))
  }, []);


  const columns = [
      { 
        headerName: "SKU",
        renderHeader: (params: GridColumnHeaderParams) => (
          <Tippy content={<div>{tooltipHeaderValues.SKU}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">SKU</div></Tippy>
        ),
        field: "_id", 
        type: 'string',
        hide: checkVisibility('_id'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 70,
        renderCell: (rowData: any) => {
          const id = rowData.row.group_skus ? rowData.row.group_id : rowData.row._id;
          return (
            <ThemeProvider theme={defaultTheme}>
              <div title={rowData.row._id} style={{width: '100%'}}>
                <Link 
                  href={`/vendas/visualizar-sku/${rowData.row.group_skus}/${id}`}
                  target="_blank" rel="noreferrer" 
                  color={'inherit'} 
                  sx={{'&:hover':{color: defaultTheme.palette.primary.dark}}}
                  style={{fontSize: 'clamp(1.2rem, 1vw, 1.3rem)', display: 'flex', alignItems: 'center', gap: '5px', textDecoration: 'none', width: 'inherit'}} 
                >
                    <Tippy content={<div>{rowData.row._id}</div>} maxWidth={'fit-content'}>
                      <div style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", width: 'inherit'}}>
                        {rowData.row._id}
                      </div>
                    </Tippy>
                    <FiExternalLink />
                </Link>
              </div>
            </ThemeProvider>
          )
        }
      },
        { 
          headerName: "Origem",
          renderHeader: (params: GridColumnHeaderParams) => (
            <Tippy content={<div>{tooltipHeaderValues.Origem}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">Origem</div></Tippy>
          ),
          field: "origin", 
          type: 'singleSelect',
          hide: checkVisibility('origin'),
          headerAlign: 'center',
          flex: 0.5,
          minWidth: 50,
          valueOptions:["RM", "TC"]
        },
        {
            headerName: "Imagem",
            field: "photo",
            hide: checkVisibility('photo'),
            headerAlign: 'center',
            flex: 0.5,
            minWidth: 80,
            renderCell: (rowData: any) =>{
              return(
                <div className="img" style={{width: 30, height: 30, borderRadius: '50%', display: 'flex'}}>
                    <img src={rowData.row.photo !== "" ? rowData.row.photo : placeholder} alt="Imagem do produto" style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 'inherit'}}/>
                </div>
              )
            }
        },
      {
        headerName: "Produto",
        field: "title",
        type: "string",
        hide: checkVisibility('title'),
        headerAlign: 'center',
        flex: 2,
        minWidth: 200,
        renderCell: (rowData: any) => {
          const linkArray = rowData.row.cods.map((item: any, index: any)=>{
            return (
                  <Link 
                    key={index}
                    href={rowData.row.links[index]}
                    target="_blank" rel="noreferrer" 
                    color={'inherit'} 
                    sx={{'&:hover':{color: defaultTheme.palette.primary.dark}}}
                    style={{fontSize: 'clamp(1.2rem, 1vw, 1.3rem)', display: 'flex', alignItems: 'center', gap: '5px', textDecoration: 'none'}} 
                  >
                    <div>{item}</div><FiExternalLink />
                  </Link>
            )
          })
          return (
            <Tippy content={
              <div style={{display: 'flex', gap: '1rem', flexWrap: 'wrap', justifyContent: 'center'}}>
                <ThemeProvider theme={defaultTheme}>
                  {linkArray}
                </ThemeProvider>
              </div>
            } interactive={true} maxWidth={'30rem'}>
                <main style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
                  <span title={rowData.row.title}>{rowData.row.title}</span>
                </main>
              </Tippy>
          )
        }
      },
      {
        headerName: "Tags",
        renderHeader: (params: GridColumnHeaderParams) => (
          <Tippy content={<div>{tooltipHeaderValues.Tags}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">Tags</div></Tippy>
        ),
        field: "tags",
        type: "selection",
        hide: checkVisibility('tags'),
        headerAlign: 'center',
        flex: 0.5,
        minWidth: 50,
        sortable:false,
        filterOperators: [{
          value: 'contains',
          getApplyFilterFn: (filterItem: GridFilterItem) => {
            const selected_values = filterItem.value ? filterItem.value.map((item: any)=> item.title) : [];
            if (filterItem.value == null || selected_values === []) {
              return null;
            }
      
            return ({ value } : {value:any}): boolean => {
              if (typeof value === 'object') {
                return selected_values.every((item: any)=> value.includes(item))
              }
              return selected_values.every((item: any)=> value.includes(item))
            };
          },
          // InputComponent: GridFilterInputValue,
          InputComponent: CheckboxesTags,
          InputComponentProps: { type: 'singleSelect', options: [{title: 'Sem Histórico'}, {title: 'SKU Novo'}]},
        }],
        valueOptions: ["Sem Histórico", "SKU Novo"],
        renderCell: (rowData: any) => {
            return (
                <div className="tags-container" style={{marginTop: 0, flexWrap: 'nowrap', display: 'flex', 'gap': '5px'}}>
                {
                    rowData.row.tags.map((item: any, index: any)=>{
                      return (
                        <Tippy key={`${item[0]}-${index}`} content={tagColors[item][0]} maxWidth={'25rem'}>
                          <span key={index} className="tag-medium" style={{color: tagColors[item][1]}}>{tagColors[item][2]}</span>     
                        </Tippy>
                      )
                  })
                }
              </div>
            )
        }
      },
      {
        headerName: "Marca",
        field: "brand", 
        type: 'string',
        hide: checkVisibility('brand'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (rowData: any) => {
          const brands = rowData.row.brand.length > 0 ? rowData.row.brand.toString().replace(',', ', ') : '';
          return (
            <Tippy content={brands} interactive={true}>
              <main style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
                <span title={brands}>{brands}</span>
              </main>
            </Tippy>
          )
        }
      },
      { 
        headerName: "Fornecedor",
        renderHeader: (params: GridColumnHeaderParams) => (
          <Tippy content={<div>{tooltipHeaderValues.Fornecedor}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">Fornecedor</div></Tippy>
        ),
        field: "supplier", 
        type: 'string',
        hide: checkVisibility('supplier'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (rowData: any) => {
            const suppliers_list = rowData.row.suppliers_details.map((item: any, index: any)=>{
              return(
                  <table key={index} style={{width: '100%'}}>
                    {
                      index < 1 ?
                      <thead style={{borderBottom: '1px solid rgba(255,255,255, 0.6)'}}>
                        <tr style={{width: '100%', display: 'flex', justifyContent: 'space-between', gap: '1rem'}}>
                          <th style={{padding: '0.5rem'}}><strong>Código</strong></th>
                          <th style={{padding: '0.5rem'}}><strong>Fornecedor</strong></th>
                          <th style={{padding: '0.5rem'}}><strong>Custo</strong></th>
                        </tr>
                      </thead>
                      :
                      null
                    }
                    <tbody>
                      <tr style={{width: '100%', display: 'flex', justifyContent: 'space-between', gap: '1rem', borderBottom: '1px solid rgba(255,255,255, 0.6)'}}>
                        <td style={{padding: '0.5rem'}}>{item.code}</td>
                        <td style={{padding: '0.5rem'}}>{item.supplier}</td>
                        <td style={{padding: '0.5rem'}}>{parseFloat(item.cost).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}</td>
                      </tr>
                    </tbody>
                  </table>
              )
            })
          return (
            <Tippy content={suppliers_list} maxWidth={"max-content"}>
                <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{rowData.row.supplier}</div>
            </Tippy>
          )
        }
      },
      { 
        headerName: "Estoque",
        renderHeader: (params: GridColumnHeaderParams) => (
          <Tippy content={<div>{tooltipHeaderValues.Estoque}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">Estoque</div></Tippy>
        ),
        field: "stockAll", 
        type: 'number',
        hide: checkVisibility('stockAll'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 50,
        renderCell: (rowData: any) => {
            return (
              <Tippy 
                content={Object.entries(rowData.row.stockAllDetail).map((item: any, index: any)=>{
                  return(
                    <div key={index}>{`${item[0]}: ${item[1]}`}</div>
                  )
                })}
              >
                  <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{rowData.row.stockAll}</div>
              </Tippy>
            )
        }
      },
      {
        headerName: "Vendas Período",
        renderHeader: (params: GridColumnHeaderParams) => (
          <Tippy content={<div>{tooltipHeaderValues['Vendas-Período']}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">Vendas Período</div></Tippy> 
        ),
        field: "total_orders", 
        type: 'number', 
        hide: checkVisibility('total_orders'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 70,
      },
      {
        headerName: "Tração",
        renderHeader: (params: GridColumnHeaderParams) => (
          <Tippy content={<div>{tooltipHeaderValues.Traction}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">Tração</div></Tippy>
        ),
        field: "traction", 
        type: 'number', 
        hide: checkVisibility('traction'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (rowData: any) => {
          const id = rowData.row.group_skus ? rowData.row.group_id : rowData.row._id;
          return (
              <InputMainContainer>
                  <div className="input-container">
                      <input
                          tabIndex={1}
                          type="number"
                          className="inputTable"
                          name="traction"
                          id={`traction-${rowData.row._id}`}
                          value={inputs[id].get() ? inputs[id].traction.get() : 0}
                          onFocus={(evt: React.BaseSyntheticEvent) => evt.target.select()}
                          onChange={(evt: any)=> inputs[id].traction.set(evt.target.value)}
                          onKeyPress={(evt: any)=> {
                              evt.persist();
                              if(evt.key === 'Enter'){
                                  const processedData = {
                                      "ref": rowData.row.group_skus ? rowData.row.group_id : rowData.row._id,
                                      "field": 'traction',
                                      "value": evt.target.value
                                  }
                                  axs.post<CPRDataType[]>('/cpr/fields', processedData).then((r: any) => {
                                      if(r.status === 200){
                                          let stockAllDuration = rowData.row.stockAll > 0 && rowData.row.project_sells > 0 ? rowData.row.stockAll / (rowData.row.project_sells * evt.target.value) : 0
                                          stockAllDuration = stockAllDuration === Infinity ? 0 : stockAllDuration
                  
                                          let project_daily_sells = rowData.row.project_sells > 0 ? rowData.row.project_sells * evt.target.value : 0;
                  
                                          let project_monthly_sells = rowData.row.project_sells > 0 ? (rowData.row.project_sells * evt.target.value) * 30 : 0
                  
                                          let minimun_buy = rowData.row.project_sells > 0 ? ((rowData.row.project_sells * evt.target.value) * 30) - rowData.row.stockAll : 0
                  
                                          let ideal_buy = rowData.row.project_sells > 0 ? ((rowData.row.project_sells * evt.target.value) * rowData.row.stock_days_ideal) - rowData.row.stockAll : 0

                                          rowData.row.stockAllDuration = stockAllDuration;
                                          rowData.row.project_daily_sells = project_daily_sells;
                                          rowData.row.project_monthly_sells = project_monthly_sells;
                                          rowData.row.minimun_buy = minimun_buy;
                                          rowData.row.ideal_buy = ideal_buy;
                                          rowData.traction = evt.target.value;
                                          apiRef.current.setRows(data.attach(Downgraded).get())
                                      }else{
                                          toast.info("Ocorreu um erro ao alterar este item.");
                                      }
                                  }).catch((err: any)=>{
                                      handleValidateStatus(err.response)
                                  })
                                  evt.target.blur()
                              }
                          }}
                      />
                  </div>
              </InputMainContainer>
          )
        }
      },
      {
        headerName: "Vendas Estimadas Diárias",
        renderHeader: (params: GridColumnHeaderParams) => (
          <Tippy content={<div>{tooltipHeaderValues['Venda-Estimada']}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">Vendas Est. Diárias</div></Tippy>
        ),
        field: "project_daily_sells", 
        type: 'number',
        hide: checkVisibility('project_daily_sells'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (rowData: any) => {
          return (
            <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{Math.round(rowData.row.project_daily_sells)}</div>
          )
        }
      },
      {
        headerName: "Vendas Estimadas Mensais",
        renderHeader: (params: GridColumnHeaderParams) => (
          <Tippy content={<div>{tooltipHeaderValues['Venda-Estimada']}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">Vendas Est. Mensais</div></Tippy>
        ),
        field: "project_monthly_sells", 
        type: 'number',
        hide: checkVisibility('project_monthly_sells'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (rowData: any) => {
          return (
            <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{Math.round(rowData.row.project_monthly_sells)}</div>
          )
        }
      },
      {
        headerName: "Duração Estoque",
        renderHeader: (params: GridColumnHeaderParams) => (
          <Tippy content={<div>{tooltipHeaderValues['Duração-Estoque']}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">Duração Estoque</div></Tippy>
        ),
        field: "stockAllDuration", 
        type: 'number',
        hide: checkVisibility('stockAllDuration'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (rowData: any) => {
          return (
            <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{Math.round(rowData.row.stockAllDuration)}</div>
          )
        }
      },
      {
        headerName: "Compra Mínima",
        renderHeader: (params: GridColumnHeaderParams) => (
          <Tippy content={<div>{tooltipHeaderValues['Compra-Mínima']}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">Compra Mínima</div></Tippy>
        ),
        field: "minimun_buy", 
        type: 'number',
        hide: checkVisibility('minimun_buy'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (rowData: any) => {
          return (
            <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{Math.round(rowData.row.minimun_buy)}</div>
          )
        }
      },
      {
        headerName: "Dias Estoque Ideal",
        renderHeader: (params: GridColumnHeaderParams) => (
          <Tippy content={<div>{tooltipHeaderValues['Dias-estoque-ideal']}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">Dias Estoque Ideal</div></Tippy>
        ),
        field: "stock_days_ideal", 
        type: 'number',
        hide: checkVisibility('stock_days_ideal'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (rowData: any) => {
          const id = rowData.row.group_skus ? rowData.row.group_id : rowData.row._id;
          return (
              <InputMainContainer>
                  <div className="input-container">
                      <input
                          tabIndex={1}
                          type="number"
                          className="inputTable"
                          name="stock_days_ideal"
                          id={`stock_days_ideal-${rowData.row._id}`}
                          value={inputs[id].get() ? inputs[id].stock_days_ideal.get() : 0}
                          onFocus={(evt: React.BaseSyntheticEvent) => evt.target.select()}
                          onChange={(evt: any)=> inputs[id].stock_days_ideal.set(evt.target.value)}
                          onKeyPress={(evt: any)=> {
                              if(evt.key === 'Enter'){
                                  const processedData = {
                                      "ref": rowData.row.group_skus ? rowData.row.group_id : rowData.row._id,
                                      "field": 'stock_days_ideal',
                                      "value": evt.target.value
                                  }
                                  axs.post<CPRDataType[]>('/cpr/fields', processedData).then((r: any) => {
                                      if(r.status === 200){
                                          const ideal_buy = (rowData.row.project_daily_sells !== undefined ? rowData.row.project_daily_sells:0) > 0 ? ((rowData.row.project_daily_sells !== undefined ? rowData.row.project_daily_sells:0) * evt.target.value) - rowData.row.stockAll : 0
                                          rowData.row.ideal_buy = ideal_buy;
                                          rowData.row.stock_days_ideal = evt.target.value;
                                          apiRef.current.setRows(data.attach(Downgraded).get())
                                      }else{
                                          toast.info("Ocorreu um erro ao alterar este item.");
                                      }
                                  }).catch((err: any)=>{
                                      handleValidateStatus(err.response)
                                  })
                                  evt.target.blur()
                              }
                          }}
                      />
                  </div>
              </InputMainContainer>
          )
        }
      },
      {
        headerName: "Compra Ideal",
        renderHeader: (params: GridColumnHeaderParams) => (
          <Tippy content={<div>{tooltipHeaderValues['Compra-Ideal']}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">Compra Ideal</div></Tippy>
        ),
        field: "ideal_buy", 
        type: 'number',
        hide: checkVisibility('ideal_buy'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (rowData: any) => {
          return (
            <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{Math.round(rowData.row.ideal_buy)}</div>
          )
        }
      },
  ]

  const options = {
      palette:{
        tableHeader: {
          main: '#F7CE3E',
          dark: '#ac902b',
          light: '#f8d764',
          contrastText: '#000'
        },
      },
      selection: true,
      rowStyle: (rowData: any) => {
        const stockAll = rowData.stockAllDuration;
        let row_class = '';
        if(stockAll <= 7) row_class = "lcreColors-negative-margin"
        if(stockAll >= 7 && stockAll < 15) row_class = "lcreColors-bad-margin";
        if(stockAll >= 15 && stockAll < 20) row_class = "lcreColors-margin-average";
        if(stockAll >= 20 && stockAll < 30) row_class = "lcreColors-good-margin";
        if(stockAll >= 30) row_class = "lcreColors-high-margin";
        return row_class
      },
  }


  const handleSelection = (rows: ICPRData[])=>{
      if(rows.length > 0){
          if(!showCartBtn.get()){
              showCartBtn.set(true);
          }
          const processedData = rows.map((item: ICPRData)=>{
              if(item.group_skus){
                  return {"display_id": item._id, "id": item.group_id, "title": item.title, "supplier": item.supplier[0], "ideal_buy": parseInt(String(item.ideal_buy)), "id_bling": ''}
              }
              else{
                  return {"display_id": item._id, "id": item._id, "title": item.title, "supplier": item.supplier[0], "ideal_buy": parseInt(String(item.ideal_buy)), "id_bling": item.id_bling}
              }
          })
          productsData.products.set(processedData);
      }else{
          showCartBtn.set(false);
      }
          
  }

  const handleCart = (value: boolean) =>{
      if(value){
          showHideModal({show: false, title: ModalData?.title, execute: ModalData?.execute})
          showCartBtn.set(false);
          showDescModal.set(true);
      }else{
          toast.info("Você escolheu não adicionar!");
          // setProductsData({products: {}, description: ''});
          showHideModal({show: false, title: ModalData?.title, execute: ModalData?.execute})
      }
  }

  const handleRemoveItemModal = (id:string) =>{
      const raw_products_data = productsData.attach(Downgraded).get();
      const new_array = Object.values(raw_products_data.products).filter((item: any)=> {
          return item.id !== id
      })
      productsData.products.set(new_array)
      toast.info("Item removido!");
  }

  const handleEditItemModal = (id: string, value: number)=>{
      const raw_products_data = productsData.attach(Downgraded).get();
      const new_array = Object.values(raw_products_data.products).map((item: any)=> {
          if(item.id === id){
              item.ideal_buy = value;
          }
          return item
      })
      productsData.products.set(new_array);
  }
  
  const closeFunction = ()=>{
      showCartBtn.set(true)
      showDescModal.set(false);
  }

  const handleGoToShoppingList = async(evt: React.BaseSyntheticEvent)=>{
      evt.preventDefault();
      disableBtnGoToList.set(true);
      toast.info("Carregando Lista de Compras!");
      const processedData = {
          'description': productsData.description.value,
          'products': productsData.products.value
      }
      showCartBtn.set(false)
      showDescModal.set(false);
      await axs.post<CPRDataType[]>('/shoppinglist/', processedData).then((r: any) => {
          if(r.status === 200){
              disableBtnGoToList.set(false);
              window.open(`/compras/lista-de-compras/${r.data._id}`, '_blank');
          }
      }).catch((err: any)=>{
          handleValidateStatus(err.response)
      })
  }

  const handleAddNewProduct = ()=>{
      if(newProduct.get() === undefined) return
      const raw_data = data.attach(Downgraded).get();
      const raw_products = productsData.attach(Downgraded).get()
      const productData: ICPRData[] = raw_data.filter((item: ICPRData)=> item.sku === newProduct.get());
      const newData: any = {"display_id": productData[0]._id, "id": productData[0]._id, "title": productData[0].title, "supplier": productData[0].supplier[0], "ideal_buy": newQty.get(), "id_bling": productData[0].id_bling}
      const productsAll = raw_products.products;
      productsAll.unshift(newData);
      productsData.products.set(productsAll)
      newProduct.set(undefined)
      newQty.set(1)
      toast.info("Produto Adicionado!");
  }

  const convertObjectToString = (value: any)=>{
    const stringDateFrom: string = moment(`${value.from.year}-${value.from.month}-${value.from.day}`).format("YYYY-MM-DD");
    const stringDateTo: string = moment(`${value.to.year}-${value.to.month}-${value.to.day}`).format("YYYY-MM-DD");
    return {stringDateFrom, stringDateTo}
  }

  const handleFullReset = ()=>{
    toast.info("Resetando os dados sem o cache!");

    startingFilterValues.set({
      "to":{
        year: moment().year(),
        month: moment().month()+ 1,
        day: moment().date(),
      },
      "from":{
          year: moment().subtract(30, 'days').year(),
          month: moment().subtract(30, 'days').month()+ 1,
          day: moment().subtract(30, 'days').date(),
      }
    })

    blackFridayFilter.set(undefined)
    basedProjectionFilter.set('activeDays')
    const dates = convertObjectToString(startingFilterValues.get())

    data.set(fetchResource({"dateFrom": dates.stringDateFrom, "dateTo": dates.stringDateTo, 'blackFridayFilter': false, 'withoutCache': true, 'projectType': 'activeDays'}))
  }

  const handleFilter = (filters: any)=>{
    toast.info('Filtrando...')

    const dateFrom: Day = filters.filterDate.trim().split('---')[0];
    const dateTo: Day = filters.filterDate.trim().split('---')[1];

    const stringDateFrom: string = moment(dateFrom).format("YYYY-MM-DD");
    const stringDateTo: string = moment(dateTo).format("YYYY-MM-DD");

    data.set(fetchResource({"dateFrom": stringDateFrom, "dateTo": stringDateTo, 'blackFridayFilter': filters.filterBlackFriday ? filters.filterBlackFriday === "1" ? true : false : false, 'projectType': filters.filterBasedProjection}))
  }

  const handleReset = ()=> {
    startingFilterValues.set({
      "to":{
        year: moment().year(),
        month: moment().month()+ 1,
        day: moment().date(),
      },
      "from":{
          year: moment().subtract(30, 'days').year(),
          month: moment().subtract(30, 'days').month()+ 1,
          day: moment().subtract(30, 'days').date(),
      }
    })
    blackFridayFilter.set(undefined)
    basedProjectionFilter.set('activeDays')
    const dates = convertObjectToString(startingFilterValues.get())

    data.set(fetchResource({"dateFrom": dates.stringDateFrom, "dateTo": dates.stringDateTo, 'blackFridayFilter': false, 'projectType': 'activeDays'}))
  }



  return(
      <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
          {
              showDescModal.get() === true ?
              <BasicModal closeFunction={closeFunction}>
                  <form className="add-description-form-cpr" onSubmit={handleGoToShoppingList}>
                      <h3>{productsData.products.length} Produtos Selecionados:</h3>
                      <div className="input-container">
                          <div style={{padding: '1rem', overflowY:"scroll", maxHeight: '30rem'}}>
                              <table style={{width: '100%'}}>
                                  <thead>
                                  <tr>
                                      <th><strong>SKU:</strong></th>
                                      <th><strong>Produto: </strong></th>
                                      <th><strong>Quantidade: </strong></th>
                                      <th><strong>Remover: </strong></th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                      {
                                          Object.values(productsData.products.get()).map((item: any, index: any)=>{
                                              return (
                                                  <tr key={index}>
                                                      <td style={{padding: "1rem 0"}}><strong>{item.display_id}</strong></td>
                                                      <td style={{padding: "1rem 0"}}><strong title={item.title}>{item.title}</strong></td>
                                                      <td style={{padding: "1rem 0"}}>
                                                          <input 
                                                              type="number" 
                                                              min={1} 
                                                              value={Math.round(item.ideal_buy)} 
                                                              className="edit-input" 
                                                              onChange={(evt: React.BaseSyntheticEvent)=> handleEditItemModal(item.id, evt.target.value)}
                                                              style={{border: item.ideal_buy < 1 ? "1px solid #C0392B" : "1px solid transparent", boxShadow: item.ideal_buy < 1 ? "0px 0px 3px #d65446":"none"}}
                                                          />
                                                      </td>
                                                      <td><button type="button" className="remove-btn" onClick={()=>handleRemoveItemModal(item.id)}><FaMinus /></button></td>
                                                  </tr>
                                              )
                                          })
                                      }
                                  </tbody>
                              </table>
                          </div>
                      </div>
                      <div className="input-container">
                          <Autocomplete
                              className="skus-select"
                              options={data.attach(Downgraded).get()}
                              getOptionLabel={(option: any) => option.sku}
                              disableClearable
                              renderInput={(params) => <TextField variant="standard" name="filterSelect" {...params} placeholder="SKUs do Grupo" />}
                              PopperComponent={(props: any)=> <StyledPopper {...props} style={{ width: "fit-content", padding: "1rem", display: "flex", flexDirection: "column", gap: '1rem' }} placement='bottom-start' />}
                              renderOption={(props, option) => (
                                  <li {...props}>
                                      {option.sku} - {option.title}
                                  </li>
                              )}
                              onChange={(evt: React.BaseSyntheticEvent, value: any)=> {
                                  const sku_value = value.sku;
                                  newProduct.set(sku_value)
                              }}
                          />
                          <div className="labelMovement">
                              <input 
                                  type="number" 
                                  min={1} 
                                  name="qty" 
                                  id="qty" 
                                  required={false}
                                  onBlur={(evt: React.BaseSyntheticEvent)=> newQty.set(evt.target.value)}
                                  style={{border: newQty.get() < 1 ? "1px solid #C0392B":'', boxShadow: newQty.get() < 1 ? "0px 0px 3px #d65446":"none"}}
                              />
                              <label htmlFor="qty">Quantidade</label>
                          </div>
                          <button className="add-new-product" type="button" onClick={handleAddNewProduct} title="Adicionar Produto"><FaPlus />Produto</button>
                      </div>

                      <div className="input-container">
                          <div className="labelMovement">
                              <input type="text" name="description" id="description" required={true}
                                  onBlur={(evt: React.BaseSyntheticEvent)=>productsData.description.set(evt.target.value)}
                              />
                              <label htmlFor="description">Descrição da Lista</label>
                          </div>
                      </div>
                      {/* botão criado apenas para desabilitar o envio do form com a tecla enter */}
                      <button type="submit" disabled style={{display: 'none'}} aria-hidden="true"></button>
  
                      <div className="input-container">
                          <button disabled={disableBtnGoToList.get()}>Ir para a Lista de Compras</button>
                      </div>
                  </form>
              </BasicModal>
              :
              null
          }
          <ModalConfirm />

          {
              // SelectedData && SelectedData.length > 0 ?
              showCartBtn.get() ?
              <button 
                  className="floating_cart" 
                  onClick={(event: any) => showHideModal({show: true, title: "Gostaria de adicionar esses itens à lista de compras?", execute: (confirm: boolean)=> handleCart(confirm)})} 
              >
                  <FaShoppingCart className="icon" />
              </button>
              :
              null
          }
          <BugReportModal />
          <div className="content-wrapper">
              <main className="main-content container-fluid">
                <Filters submitFunction={handleFilter} disableFilterBtn={disableFilterBtn.get()} handleReset={handleReset} handleFullReset={handleFullReset}>
                  <DatePicker 
                    value={startingFilterValues.attach(Downgraded).get()} 
                    inputPlaceholder="Selecionar Período: de.../até..." 
                    shouldHighlightWeekends
                    locale={myCustomLocale}
                    calendarClassName={`date-picker-filter`}
                    inputClassName="datePickerFilterInput"
                    colorPrimary="#15171c"
                    colorPrimaryLight="#F7CE3E"
                    minimumDate={minMaxDays.minimumDate.value}
                    maximumDate={minMaxDays.maximumDate.value}
                    renderFooter = {Footer}
                    inputName="filterDate"
                    onChange={(range: any)=> {
                      startingFilterValues.set({"from": range.from, "to": range.to})
                      if(range.from !== null && range.to !== null){
                          if(moment(range.to).diff(moment(range.from), 'days') < 30){
                              toast.info('O período deve ser de no mínimo 30 dias.');
                              disableFilterBtn.set(true)
                              return
                          }else{
                              disableFilterBtn.set(false)
                          }
                      }
                    }} 
                  />

                  <FormControl size='small' variant='outlined' sx={{minWidth: '28rem'}} key={blackFridayFilter.get()}>
                    <InputLabel id="black-friday-label-filter" style={{fontSize: INPUT_FONT_SIZE}}>Remover Vendas Black Friday</InputLabel>
                    <Select
                        labelId="black-friday-label-filter"
                        id="black-friday-filter"
                        name='filterBlackFriday'
                        value={blackFridayFilter.attach(Downgraded).get()}
                        label="Remover Vendas Black Friday"
                        style={{fontSize: INPUT_FONT_SIZE}}
                        onChange={(evt: SelectChangeEvent)=> blackFridayFilter.set(evt.target.value)}
                    >
                    <MenuItem value={''}></MenuItem>
                    <MenuItem value={'1'}>Sim</MenuItem>
                    <MenuItem value={'0'}>Não</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl size='small' variant='outlined' sx={{minWidth: '25rem'}} key={basedProjectionFilter.get()}>
                      <InputLabel id="projecao-baseada-label-filter" style={{fontSize: INPUT_FONT_SIZE}}>Projeção Baseada</InputLabel>
                      <Select
                          labelId="projecao-baseada-label-filter"
                          id="projecao-baseada-filter"
                          name='filterBasedProjection'
                          value={basedProjectionFilter.attach(Downgraded).get()}
                          label="Projeção Baseada"
                          style={{fontSize: INPUT_FONT_SIZE}}
                          onChange={(evt: SelectChangeEvent)=> basedProjectionFilter.set(evt.target.value)}
                      >
                      <MenuItem value={'activeDays'}>Dias Ativos</MenuItem>
                      <MenuItem value={'historySells'}>Histórico de Vendas</MenuItem>
                      </Select>
                  </FormControl>
                </ Filters>
                <Accordion title={CaptionsCpr.title} data={CaptionsCpr.data} />
                {
                    data.promised ? 
                    <Loading />
                    : data.error ?
                    <ErrorScreen /> : 
                    data.length > 0 && inputs.get() !== undefined ?
                    <VirtualizedGrid 
                        rows={data.attach(Downgraded).get()}
                        columns={columns}
                        options={options}
                        title="Análise de Compras - CPR"
                        onSelection={(rows: any)=>  handleSelection(rows)}
                        defaultSort={{field: 'ideal_buy', direction: 'desc'}}
                        apiRef={apiRef}
                    />
                    :
                    <div className="img-container">
                        <img src={noData} alt="Não foi possível renderizar o gráfico por falta de dados." />
                        <h4>Não foi possível renderizar os gráficos por falta de dados.</h4>
                    </div>
                }
              </main>

          </div>
      </div>
  )
}