import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import { Loading } from '../../../../components/Loading/Loading';
import TextField from '@material-ui/core/TextField';
import Button from '@mui/material/Button';

import { useDarkMode } from '../../../../hooks/useDarkMode';
import VirtualizedGrid from '../../../../components/table/VirtualizedGrid';
import '../../../../components/table/globalInputs.scss';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useHandleErrors } from '../../../../hooks/useHandleErrors';
import { ErrorScreen } from '../../../../components/ErrorScreen/ErrorScreen';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ObjetoVazio,IProductionOrders, IProductionOrdersItems } from "../../../../types/Types"
import { BasicModal } from "../../../../components/modal/BasicModal";
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import download from 'js-file-download';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';


const BUTTON_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.4rem)';
const INPUT_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.6rem)';

export const Separation = (props: any) =>{
    const { itemId } = props;

    const handleValidateStatus = useHandleErrors();
    
    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    
    const rowsSelected  = useState<string[]>([])
    const data: State<IProductionOrders> = useState<IProductionOrders>({
        components: [],
        date: '',
        finished: false,
        items: [],
        number: '',
        observation: '',
        status: '',
        __v: 0,
        _id: '',
    });

    const showModal: State<boolean> = useState<boolean>(false)
    const modalData: State<IProductionOrdersItems> = useState<IProductionOrdersItems>({
        batch: '',
        cost: 0,
        description: '',
        quantity: 0,
        quantityFinished: 0,
        serialNumbers: '',
        serialNumberComponents: '',
        serialNumber: '',
        sku: '',
        serialNumberRelation: [],
        structureCost: [],
        _id: '',
    })
    const serialNumberRelationModal: State<ObjetoVazio[]> = useState<ObjetoVazio[]>([])
    const printSeparationData: State<any[]> = useState<any[]>([])
    const printIdentificationData: State<any[]> = useState<any[]>([])
    const fetchProductionOrders = (query:any=undefined) => axs.get<IProductionOrders>('/productionorders/'+itemId, {params: query}).then((r: any) => {
        return r.data
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    })

    useEffect(() => {
        if(itemId === 'undefined'){
            return 
        }
        data.set(fetchProductionOrders())
    }, [])

    useEffect(() => {
        if(itemId === 'undefined'){
            return 
        }
        data.set(fetchProductionOrders())
    }, [itemId])

    const checkVisibility = (column: string)=>{
        const storageColumnsData = localStorage.getItem('Separation') ? localStorage.getItem('Separation') : null;
        if(storageColumnsData !== null){
          const localData = JSON.parse(storageColumnsData);
          const shouldHide = localData.find((item: any)=> item.field === column);
          return shouldHide !== undefined ? !shouldHide.visibility : false
        }
        return false
    }

    const {DarkMode, } = useDarkMode();

    const defaultTheme  = createTheme({
        palette:{
          mode: DarkMode ? 'dark' : 'light',
        },
    });

    const handleSelection = (rows: string[])=>{
        rowsSelected.set(rows);
        const filteredRows = data.items.attach(Downgraded).get().filter((item:any)=> rows.some((row: any)=> row === item._id))
        printIdentificationData.set(filteredRows)
        printSeparationData.set(filteredRows)
    }

    const componentRefOPPrint = useRef(null);
    const componentRefSeparation = useRef(null);
    const componentRefIdentification = useRef(null);

    const printOP: any = useReactToPrint({  
        content: () => componentRefOPPrint.current,
        documentTitle: "Ordem de Produção",
    });
    const printSeparation: any = useReactToPrint({  
        content: () => componentRefSeparation.current,
        documentTitle: "Separação",
    });
    const printIdentification: any = useReactToPrint({  
        content: () => componentRefIdentification.current,
        documentTitle: "Identificação",
    });

    const handlePrintChangeStatus = () =>{
        axs.post<any>('/productionorders/status/', {idOp: itemId, status: 'Em separação'}).then((r: any) => {
            if(r.status === 200){
                toast.success('Status alterado para: Em Separação');
                data.set(fetchProductionOrders());
            }
        }).catch((err: any)=>{
            handleValidateStatus(err.response)
        })
    }

    const handleDownloadTags = (evt: React.BaseSyntheticEvent)=>{
        evt.preventDefault();
        const filteredRows = data.items.attach(Downgraded).get().filter((item:any)=> rowsSelected.attach(Downgraded).get().some((row: any)=> row === item._id))
        const processedData = filteredRows.map((item: any)=>{
            return {serialNumber: item.serialNumber, sku: item.sku, description: item.description, quantity: item.quantity, batch: item.batch}
        })

        axs.post<any>(`/productionorders/labelfile`, {'items': processedData}).then((r: any) => {
            if(r.status === 200){
                download(r.data, `${itemId}-etiquetasImpressao.txt`);
            }
        }).catch((err: any)=>{
            handleValidateStatus(err.response)
        })
    }



    const options = {
        palette:{
          tableHeader: {
            main: '#F7CE3E',
            dark: '#ac902b',
            light: '#f8d764',
            contrastText: '#000'
          },
        },
        alternate: true,
        selection: true,
        rowStyle: (rowData: any) => {
            let row_class = '';
            if(rowData.finished) {
                row_class = "AllShipments-alternate-color-finished"
            }        
          return row_class
        },
    }

    const columns = [
    {
        headerName: "SKU",
        field: "sku",
        type: "string",
        hide: checkVisibility('sku'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (rowData: any) =>{
            return(
                rowData.row.sku
            )
        }
    },
    {
        headerName: "Descrição",
        field: "description",
        type: "string",
        hide: checkVisibility('description'),
        headerAlign: 'center',
        flex: 2,
        minWidth: 200,
        renderCell: (rowData: any) =>{
            return(
                <Tippy content={rowData.row.description}>
                    <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                        <span title={rowData.row.description}>{rowData.row.description}</span>
                    </main>
                </Tippy>
            )
        }
    },
    {
        headerName: "Qtd.",
        field: "quantity",
        type: "string",
        hide: checkVisibility('qtd'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (rowData: any) =>{
            return(
                rowData.row.qtd
            )
        }
    },
    ] 
    const columnsComponentsSeparation = [
        {
            headerName: "SKU",
            field: "sku",
            type: "string",
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    rowData.row.sku
                )
            }
        },
        {
            headerName: "Descrição",
            field: "description",
            type: "string",
            headerAlign: 'center',
            flex: 2,
            minWidth: 200,
            renderCell: (rowData: any) =>{
                return(
                    <Tippy content={rowData.row.description}>
                        <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            <span title={rowData.row.description}>{rowData.row.description}</span>
                        </main>
                    </Tippy>
                )
            }
        },
        {
            headerName: "Estoque",
            field: "stock",
            type: "number",
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    <Tippy content={
                        rowData.row.deposits.map((item: any)=>{
                            return(
                                <div style={{display: 'flex', flexDirection: 'row', padding: '1rem', gap: '1rem'}}>
                                    <strong>{item.name}: {item.quantity}</strong>
                                </div>
                            )
                        })
                    }>
                        <strong>{rowData.row.stock}</strong>
                    </Tippy>
                )
            }
        },
        {
            headerName: "Utilizar",
            field: "quantity",
            type: "number",
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    rowData.row.quantity
                )
            }
        },
    ]

    const handleSaveSerialNumber = (evt: any)=>{
        evt.preventDefault()
        const processedData: any = {
            idOp: itemId,
            idItem: modalData._id.get(),
            serialNumberRelation: []
        }
        if(modalData.serialNumberRelation.length > 0){
            processedData["serialNumberRelation"] = modalData.serialNumberRelation.get()

        }else{
            processedData["serialNumberRelation"] = serialNumberRelationModal.get()
        }
        axs.post<any>('/productionorders/serialnumbers/', processedData).then((r: any) => {
            if(r.status === 200){
                toast.success('Números de Série adicionados!');
                data.set(fetchProductionOrders());
            }
        }).catch((err: any)=>{
            handleValidateStatus(err.response)
        })
        showModal.set(false)
    }
    const modaInputsComponent = (props: any)=>{
        const container = [];
        for(let i=0; i< props.quantity; i++){
            const incrementValue = (i+1).toString().padStart(4, '0')
            const serialNumber = props.item.serialNumberFinal + incrementValue;
            serialNumberRelationModal[i].merge({serialNumberFinal: serialNumber, serialNumberComponents: ''})
            container.push(
                <Box key={serialNumber} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', gap: '2rem', width: '100%'}}>
                    <TextField disabled size="small" type='text' label="Serial Number Produto Acabado" variant="standard" defaultValue={serialNumber} 
                        sx={{minWidth: '21rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiInputBase-input': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                    />
                    <TextField size="small" type='text' placeholder="Serial Number Componentes" variant="outlined"
                        multiline minRows={10} maxRows={10}
                        sx={{minWidth: '21rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                        onChange={(evt: any)=>{
                            serialNumberRelationModal[i].merge({serialNumberFinal: serialNumber, serialNumberComponents: evt.target.value})
                        }}
                    />
                </Box>
            )

        }
        return(container)
    }

    if(data.promised){ //Enquanto for promisse ou estiver carregando
        return (
            <>
                <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}> 
                    <div className="content-wrapper">
                        <main className="main-content container-fluid">
                            <Loading />
                        </main>
                    </div>
                </div>
            </>
        )
    }

    if (data.error) { // Quando o promise dar reject
        return (<ErrorScreen />)
    }


    return(
        <div className={`planningSection ${DarkMode ? 'dark-mode-allShipmentsSection' : ''}`}>
            <main className='container-fluid' style={{padding: '0'}}>
                {
                    showModal.get() ?
                    <BasicModal closeFunction={()=> showModal.set(false)}>
                        <ThemeProvider theme={defaultTheme}>
                            <Paper elevation={3} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', gap: '2rem', width: '80rem', margin: '1rem 0', padding: '2rem'}}>
                                {
                                    modalData.serialNumberRelation.length > 0 ?
                                    modalData.serialNumberRelation.attach(Downgraded).get().map((item: any, index: number)=>{
                                        return(
                                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', gap: '2rem', width: '100%'}}>
                                                <TextField disabled size="small" type='text' label="Serial Number Produto Acabado" variant="standard" defaultValue={item.serialNumberFinal} 
                                                    sx={{minWidth: '21rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiInputBase-input': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                                />
                                                <TextField size="small" type='text' placeholder="Serial Number Componentes" variant="outlined" defaultValue={item.serialNumberComponents}
                                                    multiline minRows={10} maxRows={10}
                                                    sx={{minWidth: '21rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                                    onChange={(evt: any)=>{
                                                        modalData.serialNumberRelation[index].merge({serialNumberFinal: item.serialNumberFinal, serialNumberComponents: evt.target.value})
                                                    }}
                                                />
                                            </Box>
                                        )
                                    })
                                    :
                                    modaInputsComponent({'quantity': modalData.quantity.get(),'item': {serialNumberFinal: modalData.serialNumber.get(), serialNumberComponents: ''}})
                                }
                                <Box sx={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'flex-end', gap: '2rem', padding: '2rem'}}>
                                    <Button size='small' variant="outlined" color='primary' sx={{minWidth: 'fit-content', fontSize: BUTTON_FONT_SIZE, '& .MuiButton-startIcon':{margin: '0'}}} onClick={(evt: any)=>{
                                        showModal.set(false);
                                    }}>
                                        Cancelar
                                    </Button>
                                    <Button size='small' variant="contained" color='primary' sx={{minWidth: 'fit-content', fontSize: BUTTON_FONT_SIZE, '& .MuiButton-startIcon':{margin: '0'}}} onClick={handleSaveSerialNumber}>
                                        Salvar
                                    </Button>
                                </Box>
                            </Paper>
                        </ThemeProvider>
                    </BasicModal>
                    :
                    null
                }
                {
                    data ?
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '2rem'}}>
                            <ThemeProvider theme={defaultTheme}>
                                <Paper elevation={3} sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '1rem', width: '100%', margin: '1rem 0', padding: '2rem'}}>
                                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', width: '100%'}}>
                                        <h3>{`Ordem de Produção n°: ${data.number.get()}`}</h3>
                                    </Box>
                                    <Button size='small' variant="contained" color='primary' sx={{minWidth: 'fit-content', fontSize: BUTTON_FONT_SIZE, '& .MuiButton-startIcon':{margin: '0'}}} onClick={handleDownloadTags}>
                                        Impressão Etiquetas
                                    </Button>
                                    <Button size='small' variant="contained" color='primary' sx={{minWidth: 'fit-content', fontSize: BUTTON_FONT_SIZE, '& .MuiButton-startIcon':{margin: '0'}}} onClick={(evt: any)=> {
                                        if(printIdentificationData.length <= 0){
                                            toast.error('Selecione pelo menos um item para ser impresso.');
                                            return
                                        }
                                        printIdentification()
                                    }}>
                                        Impressão Identificação
                                    </Button>
                                    <Button size='small' variant="contained" color='primary' sx={{minWidth: 'fit-content', fontSize: BUTTON_FONT_SIZE, '& .MuiButton-startIcon':{margin: '0'}}} onClick={(evt: any)=> printOP()}>
                                        Impressão OP
                                    </Button>
                                    <Button size='small' variant="contained" color='primary' sx={{minWidth: 'fit-content', fontSize: BUTTON_FONT_SIZE, '& .MuiButton-startIcon':{margin: '0'}}} onClick={(evt: any)=> {
                                        if(printSeparationData.length <= 0){
                                            toast.error('Selecione pelo menos um item para ser impresso.');
                                            return
                                        }
                                        printSeparation()
                                        if(data.status.get() === "Não iniciado"){
                                            handlePrintChangeStatus()
                                        }
                                    }}>
                                        Impressão Separação
                                    </Button>
                                </Paper>
                            </ThemeProvider>
                            <VirtualizedGrid 
                                rows={data.items.attach(Downgraded).get()}
                                columns={columns}
                                options={options}
                                tableHeight={'auto'}
                                title="Separação"
                                onSelectionDefault={(rows: any)=>  handleSelection(rows)}
                                defaultSort={{field: 'qtd', direction: 'asc'}}
                                actions={[
                                    {icon: 'visibility', title: 'Números de Série', method: (evt: any, row:any)=> {
                                        serialNumberRelationModal.set([])
                                        console.log(row.serialNumberRelation)
                                        modalData.set(row)
                                        showModal.set(true)
                                    }}
                                ]} 
                            />
                            <hr />
                            <VirtualizedGrid 
                                rows={data.components.attach(Downgraded).get()}
                                columns={columnsComponentsSeparation}
                                options={{...options, selection: false}}
                                tableHeight={'auto'}
                                title="Componentes Separação"
                                onSelectionDefault={(rows: any)=>  handleSelection(rows)}
                                defaultSort={{field: 'quantity', direction: 'asc'}}
                            />
                        </Box>
                    : null
                }
                <Box sx={{display: 'none'}}>
                    <Paper ref={componentRefOPPrint} elevation={3} sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '1rem', width: '100%', height: '100%', margin: '1rem 0', padding: '2rem'}}>
                        <Paper elevation={3} sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%', padding: '2rem'}}>
                            <h2 style={{marginBottom: '1rem'}}>Ordem de Produção n°: {data.number.get()}</h2>
                            <h3>Data de Impressão: {moment().format("DD-MM-YYYY")}</h3>
                            <h3>Data Criação: {moment(data.date.get()).format("DD-MM-YYYY")}</h3>
                            <h3>Observação: {data.observation.get()}</h3>
                        </Paper>
                        <Paper elevation={3} sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%', padding: '2rem'}}>
                            <h3>Itens para Produção</h3>
                            <table style={{width: '100%', pageBreakAfter: 'auto'}}>
                                <thead style={{borderBottom: '1px solid rgba(255,255,255, 0.6)', backgroundColor: "#F7CE3E", padding: '1rem', pageBreakAfter: 'auto'}}>
                                    <tr style={{width: '100%', display: 'flex', pageBreakInside: 'avoid', pageBreakAfter: 'auto'}}>
                                        <th style={{padding: '1rem', width: '60%'}}><strong style={{fontSize: '1.6rem'}}>Descrição</strong></th>
                                        <th style={{padding: '1rem', width: '20rem'}}><strong style={{fontSize: '1.6rem'}}>SKU</strong></th>
                                        <th style={{padding: '1rem', width: '20rem'}}><strong style={{fontSize: '1.6rem'}}>QTD. a Produzir</strong></th>
                                    </tr>
                                </thead>
                                <tbody style={{pageBreakAfter: 'auto'}}>
                                    {
                                        data.items.attach(Downgraded).get().length > 0 ? 
                                        data.items.attach(Downgraded).get().map((item: any, index: any)=>{
                                            return(
                                                    <tr key={`${item._id}-print`}  style={{width: '100%', display: 'flex', alignItems: 'center', backgroundColor: index % 2 === 0 ? '#ffffff' : '#eeeeee', pageBreakInside: 'avoid', pageBreakAfter: 'auto', borderLeft: '1px solid black', borderRight: '1px solid black', borderTop: '1px solid black', borderBottom: index === data.items.attach(Downgraded).get().length - 1 ? '1px solid black' : 'none'}}>
                                                        <td style={{padding: '1rem', width: '60%'}}>
                                                            {
                                                                item.description && item.description.length > 100 ?
                                                                <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                                                    <strong style={{fontSize: "8px", textAlign: 'left', width: '100%', lineHeight: '1.1'}}>
                                                                        {item.description.slice(0, 70)}
                                                                    </strong>
                                                                    <strong style={{fontSize: "8px", textAlign: 'left', width: '100%', lineHeight: '1.1'}}>
                                                                        {item.description.slice(70, item.description.length)}
                                                                    </strong>
                                                                </div>
                                                                :
                                                                <strong style={{fontSize: "8px", textAlign: 'left', width: '100%'}}>
                                                                    {item.description}
                                                                </strong>
                                                            }
                                                        </td>
                                                        <td style={{padding: '1rem', width: '20rem'}}>
                                                            <strong style={{fontSize: "8px", textAlign: 'left', width: '100%'}}>{item.sku}</strong>
                                                        </td>
                                                        <td style={{padding: '1rem', width: '20rem'}}>
                                                            <strong style={{fontSize: "8px", textAlign: 'left', width: '100%', lineHeight: '1.1'}}>{item.quantity}</strong>
                                                        </td>
                                                    </tr>
                                            )
                                        })
                                        :
                                        null
                                    }
                                </tbody>
                            </table>
                        </Paper>

                        <Paper elevation={3} sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%', padding: '2rem'}}>
                            <h3>Insumos (matéria prima)</h3>
                            <table style={{width: '100%', pageBreakAfter: 'auto'}}>
                                <thead style={{borderBottom: '1px solid rgba(255,255,255, 0.6)', backgroundColor: "#F7CE3E", padding: '1rem', pageBreakAfter: 'auto'}}>
                                    <tr style={{width: '100%', display: 'flex', pageBreakInside: 'avoid', pageBreakAfter: 'auto'}}>
                                        <th style={{padding: '1rem', width: '60%'}}><strong style={{fontSize: '1.6rem'}}>Descrição</strong></th>
                                        <th style={{padding: '1rem', width: '20rem'}}><strong style={{fontSize: '1.6rem'}}>SKU</strong></th>
                                        <th style={{padding: '1rem', width: '20rem'}}><strong style={{fontSize: '1.6rem'}}>QTD. Total</strong></th>
                                    </tr>
                                </thead>
                                <tbody style={{pageBreakAfter: 'auto'}}>
                                    {
                                        data.components.attach(Downgraded).get().length > 0 ? 
                                        data.components.attach(Downgraded).get().map((item: any, index: any)=>{
                                            return(
                                                    <tr key={`${item._id}-print`}  style={{width: '100%', display: 'flex', alignItems: 'center', backgroundColor: index % 2 === 0 ? '#ffffff' : '#eeeeee', pageBreakInside: 'avoid', pageBreakAfter: 'auto', borderLeft: '1px solid black', borderRight: '1px solid black', borderTop: '1px solid black', borderBottom: index === data.components.attach(Downgraded).get().length - 1 ? '1px solid black' : 'none'}}>
                                                        <td style={{padding: '1rem', width: '60%'}}>
                                                            {
                                                                item.description && item.description.length > 100 ?
                                                                <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                                                    <strong style={{fontSize: "8px", textAlign: 'left', width: '100%', lineHeight: '1.1'}}>
                                                                        {item.description.slice(0, 70)}
                                                                    </strong>
                                                                    <strong style={{fontSize: "8px", textAlign: 'left', width: '100%', lineHeight: '1.1'}}>
                                                                        {item.description.slice(70, item.description.length)}
                                                                    </strong>
                                                                </div>
                                                                :
                                                                <strong style={{fontSize: "8px", textAlign: 'left', width: '100%'}}>
                                                                    {item.description}
                                                                </strong>
                                                            }
                                                        </td>
                                                        <td style={{padding: '1rem', width: '20rem'}}>
                                                            <strong style={{fontSize: "8px", textAlign: 'left', width: '100%'}}>{item.sku}</strong>
                                                        </td>
                                                        <td style={{padding: '1rem', width: '20rem'}}>
                                                            <strong style={{fontSize: "8px", textAlign: 'left', width: '100%', lineHeight: '1.1'}}>{item.quantity}</strong>
                                                        </td>
                                                    </tr>
                                            )
                                        })
                                        :
                                        null
                                    }
                                </tbody>
                            </table>
                        </Paper>
                    </ Paper>
                </Box>

                <Box sx={{display: 'none'}}>
                    <Box ref={componentRefSeparation}>
                        {
                            printSeparationData.get().map((item: any)=>{
                                return(
                                    <Paper key={item._id} elevation={3} sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '1rem', width: '100%', height: '100%', padding: '2.5rem'}} style={{pageBreakAfter: 'always'}}>
                                        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%'}}>
                                            <h2 style={{marginBottom: '1rem'}}>Ordem de Produção n°: {data.number.get()}</h2>
                                            <h4><strong>Data de Impressão:</strong> {moment().format("DD-MM-YYYY")}</h4>
                                            <h4><strong>Data Criação:</strong> {moment(data.date.get()).format("DD-MM-YYYY")}</h4>
                                            <h4><strong>Observação:</strong> {data.observation.get()}</h4>
                                        </Box>
                                        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%'}}>
                                            <h4><strong>SKU:</strong> {item.sku} - {item.description}</h4>
                                            <h4><strong>Quantidade a Produzir:</strong> {item.quantity}</h4>
                                            <h4><strong>Quantidade Produzida:</strong> {item.quantityFinished}</h4>
                                            <h4><strong>Prefix Serial Number:</strong> {item.serialNumber}</h4>
                                        </Box>
                                        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%'}}>
                                            <table style={{width: '100%', pageBreakAfter: 'auto', borderTop: '1px solid rgba(0,0,0,0.2)', borderLeft: '1px solid rgba(0,0,0,0.2)', borderRight: '1px solid rgba(0,0,0,0.2)'}}>
                                                <thead style={{borderBottom: '1px solid rgba(255,255,255, 0.6)', backgroundColor: "#F7CE3E", padding: '1rem', pageBreakAfter: 'auto'}}>
                                                    <tr style={{width: '100%', display: 'flex', pageBreakInside: 'avoid', pageBreakAfter: 'auto'}}>
                                                        <th style={{padding: '1rem', width: '60%'}}><strong style={{fontSize: '1.6rem'}}>Descrição</strong></th>
                                                        <th style={{padding: '1rem', width: '20rem'}}><strong style={{fontSize: '1.6rem'}}>SKU</strong></th>
                                                        <th style={{padding: '1rem', width: '20rem'}}><strong style={{fontSize: '1.6rem'}}>QTD. Un.</strong></th>
                                                        <th style={{padding: '1rem', width: '20rem'}}><strong style={{fontSize: '1.6rem'}}>QTD. Total</strong></th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{pageBreakAfter: 'auto'}}>
                                                    {
                                                        item.structureCost.length > 0 ? 
                                                        item.structureCost.map((itemSC: any, index: any)=>{
                                                            return(
                                                                    <tr key={`${item._id}-print`}  style={{width: '100%', display: 'flex', alignItems: 'center', backgroundColor: index % 2 === 0 ? '#ffffff' : '#eeeeee', pageBreakInside: 'avoid', pageBreakAfter: 'auto', borderTop: '1px solid rgba(0,0,0,0.2)', borderBottom: index === item.structureCost.length - 1 ? '1px solid rgba(0,0,0,0.2)' : 'none'}}>
                                                                        <td style={{padding: '1rem', width: '60%'}}>
                                                                            {
                                                                                itemSC.description && itemSC.description.length > 100 ?
                                                                                <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                                                                    <strong style={{fontSize: "8px", textAlign: 'left', width: '100%', lineHeight: '1.1'}}>
                                                                                        {itemSC.description.slice(0, 70)}
                                                                                    </strong>
                                                                                    <strong style={{fontSize: "8px", textAlign: 'left', width: '100%', lineHeight: '1.1'}}>
                                                                                        {itemSC.description.slice(70, itemSC.description.length)}
                                                                                    </strong>
                                                                                </div>
                                                                                :
                                                                                <strong style={{fontSize: "8px", textAlign: 'left', width: '100%'}}>
                                                                                    {itemSC.description}
                                                                                </strong>
                                                                            }
                                                                        </td>
                                                                        <td style={{padding: '1rem', width: '20rem'}}>
                                                                            <strong style={{fontSize: "8px", textAlign: 'left', width: '100%'}}>{itemSC.sku}</strong>
                                                                        </td>
                                                                        <td style={{padding: '1rem', width: '20rem'}}>
                                                                                <strong style={{fontSize: "8px", textAlign: 'left', width: '100%', lineHeight: '1.1'}}>{itemSC.quantity}</strong>
                                                                        </td>
                                                                        <td style={{padding: '1rem', width: '20rem'}}>
                                                                                <strong style={{fontSize: "8px", textAlign: 'left', width: '100%', lineHeight: '1.1'}}>{itemSC.quantityTotal}</strong>
                                                                        </td>
                                                                    </tr>
                                                            )
                                                        })
                                                        :
                                                        null
                                                    }
                                                </tbody>
                                            </table>
                                        </Box>
                                    </ Paper>
                                )
                            })
                        }
                    </Box>
                </Box>

                <Box sx={{display: 'none'}}>
                    <Box ref={componentRefIdentification}>
                        {
                            printIdentificationData.get().map((item: any, index: number)=>{
                                const pages = []
                                for (let i = 1; i <= item.quantity; i++) {
                                    const incrementValue = i.toString().padStart(4, '0')
                                    const serialNumber = item.serialNumber + incrementValue
                                    pages.push(<>
                                        <Paper key={item._id} elevation={3} sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '1rem', width: '100%', height: '100%', padding: '2.5rem'}} style={{pageBreakAfter: 'always'}}>
                                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%'}}>
                                                <h2 style={{marginBottom: '1rem'}}>Ordem de Produção n°: {data.number.get()}</h2>
                                                <h4><strong>Data de Impressão:</strong>{moment().format("DD-MM-YYYY")}</h4>
                                                <h4><strong>Data Criação:</strong>{moment(data.date.get()).format("DD-MM-YYYY")}</h4>
                                                <h4><strong>Observação:</strong>{data.observation.get()}</h4>
                                            </Box>
                                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%'}}>
                                                <h4 style={{margin: '1rem 0'}}><strong>SKU:</strong>{item.sku} - {item.description}</h4>
                                                <h4><strong>Quantidade a Produzir:</strong>{item.quantity}</h4>
                                                <h4><strong>Serial Number:</strong>{serialNumber}</h4>
                                            </Box>
                                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%', height: 'auto'}}>
                                                <table style={{width: '100%', pageBreakAfter: 'auto', borderTop: '1px solid rgba(0,0,0,0.2)', borderLeft: '1px solid rgba(0,0,0,0.2)', borderRight: '1px solid rgba(0,0,0,0.2)'}}>
                                                    <thead style={{borderBottom: '1px solid rgba(255,255,255, 0.6)', backgroundColor: "#F7CE3E", padding: '1rem', pageBreakAfter: 'auto'}}>
                                                        <tr style={{width: '100%', display: 'flex', pageBreakInside: 'avoid', pageBreakAfter: 'auto'}}>
                                                            <th style={{padding: '1rem', width: '60%'}}><strong style={{fontSize: '1.6rem'}}>Descrição</strong></th>
                                                            <th style={{padding: '1rem', width: '20rem'}}><strong style={{fontSize: '1.6rem'}}>SKU</strong></th>
                                                            <th style={{padding: '1rem', width: '20rem'}}><strong style={{fontSize: '1.6rem'}}>Qtd.</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{pageBreakAfter: 'auto'}}>
                                                        {
                                                            item.structureCost.length > 0 ? 
                                                            item.structureCost.map((itemSC: any, index: any)=>{
                                                                return(
                                                                        <tr key={`${item._id}-print`}  style={{width: '100%', display: 'flex', alignItems: 'center', backgroundColor: index % 2 === 0 ? '#ffffff' : '#eeeeee', pageBreakInside: 'avoid', pageBreakAfter: 'auto',borderTop: '1px solid rgba(0,0,0,0.2)', borderBottom: index === item.structureCost.length - 1 ? '1px solid rgba(0,0,0,0.2)' : 'none'}}>
                                                                            <td style={{padding: '1rem', width: '60%'}}>
                                                                                {
                                                                                    itemSC.description && itemSC.description.length > 100 ?
                                                                                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                                                                        <strong style={{fontSize: "8px", textAlign: 'left', width: '100%', lineHeight: '1.1'}}>
                                                                                            {itemSC.description.slice(0, 70)}
                                                                                        </strong>
                                                                                        <strong style={{fontSize: "8px", textAlign: 'left', width: '100%', lineHeight: '1.1'}}>
                                                                                            {itemSC.description.slice(70, itemSC.description.length)}
                                                                                        </strong>
                                                                                    </div>
                                                                                    :
                                                                                    <strong style={{fontSize: "8px", textAlign: 'left', width: '100%'}}>
                                                                                        {itemSC.description}
                                                                                    </strong>
                                                                                }
                                                                            </td>
                                                                            <td style={{padding: '1rem', width: '20rem'}}>
                                                                                <strong style={{fontSize: "8px", textAlign: 'left', width: '100%'}}>{itemSC.sku}</strong>
                                                                            </td>
                                                                            <td style={{padding: '1rem', width: '20rem'}}>
                                                                                    <strong style={{fontSize: "8px", textAlign: 'left', width: '100%', lineHeight: '1.1'}}>{itemSC.quantity}</strong>
                                                                            </td>
                                                                        </tr>
                                                                )
                                                            })
                                                            :
                                                            null
                                                        }
                                                    </tbody>
                                                </table>
                                            </Box>

                                        </ Paper>
                                        <Paper elevation={3} sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%', height: 'auto', padding: '2rem'}} style={{pageBreakAfter: 'always'}}>
                                            <Box>
                                                <h2>Checklist de Montagem</h2>
                                            </Box>
                                            <Box sx={{padding: '2rem 0', display: 'flex', flexDirection: 'column', gap: '2rem'}}>
                                                <h3 style={{fontWeight: 'bold'}}>1. Montagem</h3>
                                                <Box sx={{display: 'flex', flexDirection: 'column', gap: '2rem', fontSize: 'clamp(1.6rem, 1.6vw, 2rem)'}}>
                                                    <span>Data:{String('').padEnd(10, '_')}/{String('').padEnd(10, '_')}/{String('').padEnd(10, '_')}</span>
                                                    <Box sx={{display: 'flex', flexDirection: 'row', gap: '2rem'}}>
                                                        <span><strong>Início: </strong>Horário: {String('').padEnd(20, '_')}</span>
                                                        <span><strong>Fim: </strong>Horário: {String('').padEnd(20, '_')}</span>
                                                    </Box>
                                                    <span>Responsável: {String('').padEnd(60, '_')}</span>
                                                </Box>
                                            </Box>
                                            <Box sx={{padding: '2rem 0', display: 'flex', flexDirection: 'column', gap: '2rem'}}>
                                                <h3 style={{fontWeight: 'bold'}}>2. Testes</h3>
                                                <Box sx={{display: 'flex', flexDirection: 'row', gap: '2rem', fontSize: 'clamp(1.6rem, 1.6vw, 2rem)'}}>
                                                    <span><strong>Início: </strong>Horário:{String('').padEnd(20, '_')}</span>
                                                    <span><strong>Fim: </strong>Horário:{String('').padEnd(20, '_')}</span>
                                                </Box>
                                                <Box sx={{display: 'flex', flexDirection: 'row', gap: '2rem', fontSize: 'clamp(1.6rem, 1.6vw, 2rem)'}}>
                                                    <span style={{display: 'flex', alignItems: 'center'}}><CheckBoxOutlineBlankIcon sx={{width: '3rem', height: '3rem'}} />MemTest</span>
                                                    <span style={{display: 'flex', alignItems: 'center'}}><CheckBoxOutlineBlankIcon sx={{width: '3rem', height: '3rem'}} />Passagem de ISO</span>
                                                    <span style={{display: 'flex', alignItems: 'center'}}><CheckBoxOutlineBlankIcon sx={{width: '3rem', height: '3rem'}} />Testes de Entradas P2 e USB</span>
                                                    <span style={{display: 'flex', alignItems: 'center'}}><CheckBoxOutlineBlankIcon sx={{width: '3rem', height: '3rem'}} />Testes de Stress AIDA</span>
                                                </Box>                            
                                            </Box>
                                            <Box sx={{padding: '2rem 0', display: 'flex', flexDirection: 'column', gap: '2rem'}}>
                                                <h3 style={{fontWeight: 'bold'}}>3. Pontuação de Benchmarks</h3>
                                                <Box sx={{display: 'flex', flexDirection: 'row', gap: '2rem', fontSize: 'clamp(1.6rem, 1.6vw, 2rem)'}}>
                                                    <span>Cinebench:{String('').padEnd(20, '_')}</span>
                                                    <span>3D MARK - Firestrike:{String('').padEnd(20, '_')}</span>
                                                </Box>                            
                                            </Box>
                                            <Box sx={{padding: '2rem 0', display: 'flex', flexDirection: 'column', gap: '2rem'}}>
                                                <h3 style={{fontWeight: 'bold'}}>4. Necessidade de RMA</h3>
                                                <Box sx={{display: 'flex', flexDirection: 'column', gap: '2rem', fontSize: 'clamp(1.6rem, 1.6vw, 2rem)'}}>
                                                    <Box sx={{display: 'flex', flexDirection: 'row', gap: '2rem', fontSize: 'clamp(1.6rem, 1.6vw, 2rem)'}}>
                                                        <span>Data de início: {String('').padEnd(10, '_')}/{String('').padEnd(10, '_')}/{String('').padEnd(10, '_')}</span>
                                                        <span>Data de finalização: {String('').padEnd(10, '_')}/{String('').padEnd(10, '_')}/{String('').padEnd(10, '_')}</span>
                                                    </Box>
                                                    <span style={{overflow: 'hidden', wordBreak: 'break-all'}}>
                                                        Descrição do problema: {String('').padEnd(100, '_')}
                                                    </span>
                                                    <span style={{overflow: 'hidden', wordBreak: 'break-all'}}>
                                                        Solução: {String('').padEnd(100, '_')}
                                                    </span>
                                                    <span style={{overflow: 'hidden', wordBreak: 'break-all'}}>
                                                        Insumos utilizados: {String('').padEnd(100, '_')}
                                                    </span>
                                                    <span>Responsável: {String('').padEnd(60, '_')}</span>
                                                </Box>
                                            </Box>
                                            <Box sx={{padding: '2rem 0', display: 'flex', flexDirection: 'column', gap: '2rem'}}>
                                                <h3 style={{fontWeight: 'bold'}}>5. Conclusão da Máquina</h3>
                                                <Box sx={{display: 'flex', flexDirection: 'row', gap: '2rem', fontSize: 'clamp(1.6rem, 1.6vw, 2rem)'}}>
                                                    <span>Data: {String('').padEnd(10, '_')}/{String('').padEnd(10, '_')}/{String('').padEnd(10, '_')}</span>
                                                    <span>Horário: {String('').padEnd(20, '_')}</span>
                                                    <span>Responsável: {String('').padEnd(60, '_')}</span>
                                                </Box>
                                            </Box>
                                        </Paper>
                                    </>)
                                }
                                return(pages)
                            })
                        }
                    </Box>
                </Box>
                
            </main>
        </div>
    )
    
}
