// import { Geral } from './components/Geral';
import { Users2 } from './components/Users2';
// import { Integrations } from './components/Integrations';
import { Fiscais } from './components/Fiscais';
// import { Others } from './components/Others';

import './style.scss';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import { useDarkMode } from '../../hooks/useDarkMode';
import { BugReportModal } from '../../components/modal/BugReportModal';


export const Settings = () =>{
    document.title = "Configurações - MeLiBeat";

    const {DarkMode, } = useDarkMode();


    return(
        <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
            <BugReportModal />
            <div className="content-wrapper">
                <main className={`main-content container-fluid`}>
                    <h3>Configurações</h3>
                    <Tabs>
                        <TabList>
                            {/* <Tab>Gerais</Tab> */}
                            <Tab>Usuários</Tab>
                            {/* <Tab>Integrações</Tab> */}
                            <Tab>Fiscais</Tab>
                            {/* <Tab>Outros</Tab> */}
                        </TabList>

                        {/* <TabPanel>
                            <Geral />
                        </TabPanel> */}
                        <TabPanel>
                            <Users2 />
                        </TabPanel>
                        {/* <TabPanel>
                            <Integrations />
                        </TabPanel> */}
                        <TabPanel>
                            <Fiscais />
                        </TabPanel>
                        {/* <TabPanel>
                            <Others />
                        </TabPanel> */}
                    </Tabs>
                </main>
            </div>
        </div>
    )
}