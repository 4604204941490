
import moment from 'moment';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

export const columns = [
  { 
    headerName: "MLBs", 
    field: "mlb_ids", 
    type: 'string',
    hide: true,
    renderCell: (rowData: any) => {
        const mlb_ids = rowData.row.mlb_ids.toString().replaceAll(',', ', ');
        return (
          <strong>{mlb_ids}</strong>
        )
    }
  },
  { 
    headerName: "SKUs", 
    field: "skus", 
    type: 'string',
    editable: 'never',
    hide: true,
    renderCell: (rowData: any) => {
        const skus = rowData.row.skus.toString().replaceAll(',', ', ');
        return (
          <span>{skus}</span>
        )
    }
  },
  { 
      headerName: "Descrição", 
      field: "description", 
      type: 'string',
      headerAlign: 'center',
      flex: 2,
      renderCell: (rowData: any) => {
          return (
            <Tippy content={rowData.row.description}>
              <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                <span title={rowData.row.description}>{rowData.row.description}</span>
              </main>
            </Tippy>
          )
      }
  },
  { 
      headerName: "Data", 
      field: "scheduling", 
      type: 'date',
      headerAlign: 'center',
      flex: 1,
      renderCell: (rowData: any) => {
          return (
              <span>{moment(rowData.row.scheduling).utc().format("DD/MM/YYYY")}</span>
          )
      }
  },
  { 
      headerName: "Status", 
      field: "status", 
      type: 'string',
      headerAlign: 'center',
      flex: 1,
      renderCell: (rowData: any) => {
          return (
            <span title={rowData.row.status}>{rowData.row.status}</span>
          )
      }
  },
]


export const options = {
  palette:{
    tableHeader: {
      main: '#F7CE3E',
      dark: '#ac902b',
      light: '#f8d764',
      contrastText: '#000'
    },
  },
  rowStyle: (rowData: any) => {
    let row_class = '';
    const {relation, precification, alteration} = rowData.completed;
    const finished = rowData.finished;
  
    if(!relation && !precification && !alteration && !finished){
      row_class = '';
    }else if(relation && !precification && !alteration && !finished){
      row_class = 'shippingRelation-relation'
    }else if(relation && precification && !alteration && !finished){
      row_class = 'shippingRelation-relation-precification'
    }else if(relation && precification && alteration && !finished){
      row_class = 'shippingRelation-relation-precification-alteration'
    }else if(finished){
      row_class = 'shippingRelation-finished'
    }
    else{
      row_class = ''
    }
    return row_class
  },
}
