import { Line } from 'react-chartjs-2';
import { useDarkMode } from '../../hooks/useDarkMode';

import './style.scss';

export const LineChart = (props: any)=>{
  const {DarkMode, } = useDarkMode();
    const labels: string[] | number[] = props.labels;
    const dataset: object[] = props.dataset;
    const data:object = {
      labels: labels[0] ,
      datasets: dataset,
    };
      
    const options = {
      scales: {
        y: [
          {
            type: 'linear',
            ticks: {
              beginAtZero: true,
            },
          },
        ],
        x:[
          {
            min: 0,
            max: 100,
            suggestedMax: 100,
            suggestedMin: 100
          }
      ]
      },
    };
  return(
    <div className={`chart-container ${DarkMode ? 'dark-mode-chart' : ''}`}>
      <Line data={data} options={options} height={props.height ? props.height:50} />
    </div>
  )
}
