import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

export const columns = [
  { 
    headerName: "SKU",
    field: "sku", 
    headerAlign:"center",
    type: 'string',
    flex: 1,
    minWidth: 100,
  },
  {
    headerName: "Produto",
    field: "title",
    headerAlign:"center",
    type: 'string',
    flex: 2,
    minWidth: 200,
    renderCell: (rowData: any) => {
      return (
        <Tippy content={rowData.row.title}>
          <main style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
            <span>{rowData.row.title}</span>
          </main>
        </Tippy>
      )
    }
  },
  { 
    headerName: "Quantidade",
    field: "qty", 
    headerAlign:"center",
    editable: true,
    type: 'number',
    flex: 1,
    minWidth: 100,
    renderCell: (rowData: any) => {
      return (
          rowData.row.qty
      )
    }
  },
  { 
    headerName: "Status",
    field: "status", 
    headerAlign:"center",
    type: 'string',
    flex: 1,
    minWidth: 100,
    renderCell: (rowData: any) => {
      let status = '';
      if(rowData.row.status === "to_buy") status = "A Comprar"
      if(rowData.row.status === "buyed") status = "Comprado"
      if(rowData.row.status === "rejected") status = "Rejeitado"
      return (
        <>
          {status}
        </>
      )
    }
  }
]


export const options = {
  palette:{
    tableHeader: {
      main: '#F7CE3E',
      dark: '#ac902b',
      light: '#f8d764',
      contrastText: '#000'
    },
  },
  alternate: true,
  rowStyle: (row:any)=> {
    if(row.status === 'to_buy'){
      return {
        backgroundColor: ''
      }
    }
    if(row.status === 'buyed'){
      return {
        backgroundColor: '#b2ffbb'
      }
    }
    if(row.status === 'rejected'){
      return {
        backgroundColor: '#c8d6e5'
      }
    }
  }
}