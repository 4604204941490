import { BasicModal } from "../../../components/modal/BasicModal";
import { BasicForm, InputMainContainer, InputContainer, SelectContainer, CheckboxContainer } from '../../../components/Form/BasicForm';
import {FaMinus} from 'react-icons/fa';
import { useModal } from '../../../hooks/useModal';
import { ModalConfirm } from '../../../components/modal/ModalConfirm';
import { toast } from 'react-toastify';
import { Loading } from '../../../components/Loading/Loading';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Popper from '@material-ui/core/Popper';
import { useDarkMode } from '../../../hooks/useDarkMode';
import { GroupSkusType, IOverWriteInpuData, BlingProductsType, IRawBlingProducts, IGroupSkus } from "../../../types/Types";
import {FaCheckSquare, FaSquare} from 'react-icons/fa';
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { BugReportModal } from '../../../components/modal/BugReportModal';


const INPUT_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.6rem)';

const checkVisibility = (column: string)=>{
    const storageColumnsData = localStorage.getItem('Cadastro Agrupamentos SKUs') ? localStorage.getItem('Cadastro Agrupamentos SKUs') : null;
    if(storageColumnsData !== null){
      const localData = JSON.parse(storageColumnsData);
      const shouldHide = localData.find((item: any)=> item.field === column);
      return shouldHide !== undefined ? !shouldHide.visibility : false
    }
    return false
  }

const options = {
    palette:{
        tableHeader: {
            main: '#F7CE3E',
            dark: '#ac902b',
            light: '#f8d764',
            contrastText: '#000'
        },
    },
    alternate: true,
}

const columns = [
    {
        headerName: 'Tipo',
        field: 'type',
        type: "string",
        hide: checkVisibility('type'),
        headerAlign: 'center',
        flex: 0.5,
        minWidth: 50,
    },
    {
        headerName: 'KPI',
        field: 'kpi',
        type: "string",
        hide: checkVisibility('kpi'),
        headerAlign: 'center',
        flex: 0.5,
        minWidth: 50,
        renderCell: (rowData: any)=>{
            return(
                <>
                    {
                        rowData.row.modules.kpi ?
                        <FaCheckSquare style={{color: '#20bf6b'}} className="icon" />
                        :
                        <FaSquare style={{color: '#a5b1c2', opacity: '.6'}} className="icon" />

                    }
                </>
            )
        }
    },
    {
        headerName: 'CPR',
        field: 'cpr',
        type: "string",
        hide: checkVisibility('cpr'),
        headerAlign: 'center',
        flex: 0.5,
        minWidth: 50,
        renderCell: (rowData: any)=>{
            return(
                <>
                    {
                        rowData.row.modules.cpr ?
                        <FaCheckSquare style={{color: '#20bf6b'}} className="icon" />
                        :
                        <FaSquare style={{color: '#a5b1c2', opacity: '.6'}} className="icon" />

                    }
                </>
            )
        }
    },
    {
        headerName: 'Origem',
        field: 'origin',
        type: "string",
        hide: checkVisibility('origin'),
        headerAlign: 'center',
        flex: 0.5,
        minWidth: 50,
    },
    {
        headerName: 'Produto',
        field: 'title',
        type: "string",
        hide: checkVisibility('title'),
        headerAlign: 'center',
        flex: 2,
        minWidth: 200,
        renderCell: (rowData: any) => (
            <Tippy content={<div>{rowData.row.title}</div>} maxWidth={'40rem'}>
                <div style={{width: '100%', textAlign: 'center', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{rowData.row.title}</div>
            </Tippy>
        ),
    },
    {
        headerName: 'SKUs',
        field: 'skus',
        type: "string",
        hide: checkVisibility('skus'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 150,
        renderCell: (rowData: any) => (
            <Tippy content={<div>{rowData.row.skus}</div>} maxWidth={'40rem'}>
                <div style={{width: '100%', textAlign: 'center', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{rowData.row.skus}</div>
            </Tippy>
        ),
    },
    {
        headerName: 'Descrição',
        field: 'description',
        type: "string",
        hide: checkVisibility('description'),
        headerAlign: 'center',
        flex: 2,
        minWidth: 200,
        renderCell: (rowData: any) => (
            <Tooltip arrow placement="bottom" title={
                <div style={{display: 'flex', flexDirection: 'column', maxHeight: '50rem', overflowY: rowData.row.description ? "scroll" : 'hidden', fontSize: 'clamp(1.4rem, 1.4vw, 1.6rem)'}}>
                    <span>{rowData.row.description ? rowData.row.description : "N/A"}</span>
                </div>
            } sx={{maxWidth: '60rem'}}>
                <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                    <div style={{width: '100%', textAlign: 'center', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}></div>
                    <span>{rowData.row.description ? rowData.row.description : "N/A"}</span>
                </main>
            </Tooltip>
        ),
    },
]

const StyledPopper = styled(Popper)({
    "& .MuiAutocomplete-listbox": {
      '& li':{
        fontSize: 'clamp(1rem, 1vw, 1.1rem)',
      }
    }
});

interface GroupSkuList{
    "_id": string,
    "sku": string,
    "qtd": string
}
interface modalDescriptionData{
    "_id": string,
    "description": string,
}
export const CadastroAgrupamentos = ()=>{
    document.title = "Cadastros de Agrupamentos de Componentes para Montagem";
    const handleValidateStatus = useHandleErrors();

    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const fetchResource = (query:any=undefined) => axs.get<GroupSkusType[]>('/groupSkus/', {params: query}).then((r: any) => {
        handleValidateStatus(r)
        return processData(r.data)
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    });

    const fetchBling = () => axs.get<IRawBlingProducts[]>('/bling/products/').then((r: any) => {
        const processedProducts = Object.entries(r.data).map((item: any)=>{
            let obj:IRawBlingProducts = item[1]
            return {label: `${obj.sku.trim()} - ${obj.title}`, sku: obj.sku.trim(), cost: obj.cost}
        })
        return processedProducts
    }).catch((err: any)=>{
        return []
    });
    const data: State<GroupSkusType[]> = useState<GroupSkusType[]>([]);
    const blingProducts: State<BlingProductsType> = useState<BlingProductsType>(fetchBling);

    const modalVisible: State<boolean> = useState<boolean>(false);
    const modalDescVisible: State<boolean> = useState<boolean>(false);
    const modalDescriptionData: State<modalDescriptionData> = useState<modalDescriptionData>({
        "_id": '',
        "description": '',
    });
    const updateModal: State<boolean> = useState<boolean>(false);
    const radioActive: State<boolean> = useState<boolean>(false);
    const counter: State<number> = useState<number>(1);
    const groupSkuList: State<GroupSkuList[]> = useState<GroupSkuList[]>([]);
    const groupType: State<string | undefined> = useState<string|undefined>(undefined);
    const overWriteInpuData: State<IOverWriteInpuData> = useState<IOverWriteInpuData>({group_name: '', group_type: '', group_skus: [], config_qtd: [], origin: '', kpi: false, cpr: false, id: ''});
    const {DarkMode, } = useDarkMode();
    const {showHideModal} = useModal();

    useEffect(() => {
        data.set(fetchResource())
    }, [])

    const onAddBtnClick = (event: React.MouseEvent<HTMLElement>) => {
        counter.set(p => p + 1);
        const id:string = 'line-'+counter.get().toString();
        groupSkuList.merge([{"_id": id, "sku": '', "qtd": ''}]);
    };

    const onRemoveBtnClick = (position: string) => {
        if (groupSkuList.length > 0) {
            const list: any = groupSkuList.attach(Downgraded).get().filter((item: any)=> item._id !== position)
            groupSkuList.set(list);
        }

    };


    const handleSubmit = (evt: React.BaseSyntheticEvent)=>{
        evt.preventDefault();
        let skus: string[] = [];
        let origin: string = '';
        let group_name: string = '';
        let group_type: string = '';
        let config_qtd: any = [];
        let kpi: boolean = false;
        let cpr: boolean = false;
        let id: string | number | null = null;
        let skuQtd: any = {};
        for(let item of evt.target){
            if(item.id.includes("skus")){
                skus.push(item.value)
            }
            if(item.name === 'group_name'){
                group_name = item.value;
            }
            if(item.name === 'group_type'){
                group_type = item.value;
            }
            if(item.name === 'kit_qtd'){
                if(isNaN(item.value)){
                    toast.error("O valor do campo Quantidade dos Kits precisa ser um número!");
                    return
                }
                config_qtd.push(item.value);
            }
            if(item.name === 'origin' && item.checked){
                origin = item.value;
            }
            if(item.name === 'KPI'){
                kpi = item.checked;
            }
            if(item.name === 'CPR'){
                cpr = item.checked;
            }
            if(item.name === 'id'){
                id = item.value;
            } 
        }

        if(skus.length <= 0){
            toast.error("Adicione pelo menos um SKU!");
        }else{
            for(let sku of skus){
                let qtdSku = skus.filter(item => item === sku)
                if(qtdSku.length > 1){
                    toast.error("Não Adicione SKUs semelhantes!");
                    return false
                }
            }
            if(group_type === 'KIT'){
                if(config_qtd.length <= 0){
                    toast.error("Adicione também as Quantidades!");
                }else{
                    skuQtd = skus.map((item: string | null | undefined, index: number)=>{
                        return {
                            sku: item,
                            qtd: config_qtd[index]
                        }
                    });
                    if(updateModal.get()){
                        handleUpdateData({
                            skuQtd, group_name, group_type, config_qtd, origin, kpi, cpr, skus, id
                        })
                    }else{
                        handleCreateData({
                            skuQtd, group_name, group_type, config_qtd, origin, kpi, cpr, skus
                        })
                    }
                }
            }else{
                if(updateModal.get()){
                    handleUpdateData({
                        skuQtd, group_name, group_type, config_qtd, origin, kpi, cpr, skus, id
                    })
                }else{
                    handleCreateData({
                        group_name, group_type, config_qtd, origin, kpi, cpr, skus, skuQtd
                    });
                }
            }
        }

    }

    const processData = (data_value: object)=>{
        const processedArray = Object.values(data_value)?.map((item: any, index: number)=>{
            let obj: IGroupSkus = item;
            item["kpi"] = obj.modules.kpi ? 'Sim' : 'Não';
            item["cpr"] = obj.modules.cpr ? 'Sim' : 'Não';
            item["skus"] = obj.skus.toString().replaceAll(",", ", ")
            return item
        })
        return processedArray
    }
    
    const confirmDelete = async(value: boolean, id: string|number)=>{
        if(value){
            axs.delete<GroupSkusType[]>('/groupSkus/'+id).then((r: any) => {
                if(r.status === 200){
                    toast.success('Produto Removido com Sucesso!');
                    data.set(fetchResource());
                    return
                }else{
                    toast.error(r.message);
                }
            }).catch((err: any)=>{
                handleValidateStatus(err.response)
            });
        }else{
            toast.info("Você escolheu não deletar!");
        }
    }

    const handleCreateGroup = ()=>{
        if(blingProducts.length > 0){
            updateModal.set(false);
            modalVisible.set(true);
            groupSkuList.set([]);
        }
        else{
            toast.info("Aguarde o carregamento dos dados do Bling!");
        }
    }

    const handleCreateData = async(data_value: {group_name: string, skus: string | any[], group_type: string, config_qtd: any, origin: string, kpi: boolean, cpr: boolean, skuQtd: number})=>{
        const processedData = {
            title: data_value.group_name,
            skus: data_value.skus,
            type: data_value.group_type,
            origin: data_value.origin,
            modules: {'kpi': data_value.kpi, 'cpr': data_value.cpr},
            options_kits: (data_value.skuQtd ? data_value.skuQtd : [])
        }
        axs.post<GroupSkusType[]>('/groupSkus/', processedData).then((r: any) => {
            if(r.status === 200){
                toast.success('Produto Adicionado com Sucesso!');
                data.set(fetchResource());
            }
        }).catch((err: any)=>{
            handleValidateStatus(err.response)
        })
        updateModal.set(false);
        modalVisible.set(false);
    }

    const handleUpdateData = async(data_value: {group_name: string, skus: string | any[], group_type: string, config_qtd: any, origin: string, kpi: boolean, cpr: boolean, skuQtd: number, id: string | number | null})=>{
        const processedData = {
            title: data_value.group_name,
            skus: data_value.skus,
            type: data_value.group_type,
            origin: data_value.origin,
            modules: {'kpi': data_value.kpi, 'cpr': data_value.cpr},
            options_kits: (data_value.skuQtd ? data_value.skuQtd : [])
        }
        const id = data_value.id ? data_value.id:null;

        axs.put<GroupSkusType[]>('/groupSkus/'+id, processedData).then((r: any) => {
            if(r.status === 200){
                toast.success('As Informações do Produto foram Atualizadas!');
                data.set(fetchResource());
            }else{
                toast.error(r.message);
            }
         }).catch((err: any)=>{
            handleValidateStatus(err.response)
        })
    
        updateModal.set(false);
        modalVisible.set(false);
    }

    const handleUpdate = (value: {title: string, type: string, skus: any, origin: string, kpi: string, cpr:string, _id: number | any, options_kits: []}) =>{
        if(blingProducts.length > 0){
            overWriteInpuData.set({group_name: value.title, group_type: value.type, group_skus: value.skus, config_qtd: [], origin: value.origin, kpi: value.kpi === 'Sim', cpr: value.cpr === 'Sim', id: value._id});
            if(value.options_kits.length > 0 && value.type === "KIT"){
                groupSkuList.set(value.options_kits);
            }
            else{
                const skus = value.skus.split(',');
                const products = skus.map((item: any)=>{
                    const sku = item.trim()
                    return {"_id": sku, "sku": sku, "qtd": ''}
                })
                groupSkuList.set(products);
            }
            updateModal.set(true);
            modalVisible.set(true);
        }
        else{
            toast.info("Aguarde o carregamento dos dados do Bling!");
        }
    }

    const handleUpdateDescription = (value: {_id: string | any, description: string}) =>{
        modalDescriptionData.set({"_id": value._id, "description": value.description})
        modalDescVisible.set(true);
    
    }

    const handleSaveNewDescription = async(evt: any)=>{
        evt.preventDefault();

        axs.put<GroupSkusType[]>('/groupSkus/description/'+modalDescriptionData._id.get(), {"description": modalDescriptionData.description.get()}).then((r: any) => {
            if(r.status === 200){
                toast.success('A Descrição dos Anúncios foi Atualizada!');
                data.set(fetchResource());
            }else{
                toast.error(r.message);
            }
         }).catch((err: any)=>{
            handleValidateStatus(err.response)
        })
        modalDescriptionData.set({"_id": '', "description": ''});
        modalDescVisible.set(false);
    }

    if(data.promised){ //Enquanto for promisse ou estiver carregando
        return (
            <>
            <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}> 
                <div className="content-wrapper">
                    <main className="main-content container-fluid">
                        <Loading />
                    </main>
                </div>
            </div>
        </>
        )
    }
    
    if (data.error) { // Quando o promise dar reject
        return (<ErrorScreen />)
    }


    return(
        <>
            {
                modalVisible.get()
                ? <BasicModal closeFunction={()=> modalVisible.set(false)}>
                    <BasicForm SubmitFunction={handleSubmit}>
                        <InputMainContainer>
                            <InputContainer 
                                label="Nome do Grupo" 
                                id="group_name" 
                                name="group_name" 
                                type="text" 
                                required={true} 
                                value={updateModal.get() ? overWriteInpuData.group_name.value:undefined}
                            />
                        </InputMainContainer>

                        <InputMainContainer>
                            <SelectContainer 
                                label="Tipo do Grupo" 
                                id="group_type" 
                                name="group_type" 
                                required={true}
                                options={
                                    {'data': [{'value': '', 'text': ''}, {'value': 'PC', 'text': 'PC'}, {'value': 'KIT', 'text': 'Kits'}, {'value': 'AVULSO', 'text': 'Avulso'}]}
                                }
                                value={updateModal.get() ? overWriteInpuData.group_type.value:undefined}
                                disabled={updateModal.get() ? true:false}
                                onChange={(value: any)=> groupType.set(value["group_type"])}
                            />
                        </InputMainContainer>
                        {
                            groupSkuList.attach(Downgraded).get().map((item: any, index:number)=>{
                                return(
                                    <div key={index} style={{width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem', boxShadow: '-1px 2px 2.5px 0px rgba(0,0,0,0.25)', borderRadius: '.52rem', padding: '1rem'}}>
                                        <InputMainContainer key={`sku-${index}`}>
                                            <div className="input-container">
                                                <Autocomplete
                                                    id={`skus-${index}`}
                                                    className="skus-select"
                                                    // value={{label: item.product ? item.product : "", sku: item.sku ? item.sku : "", cost: undefined}}
                                                    defaultValue={item}
                                                    options={blingProducts.attach(Downgraded).get()}
                                                    getOptionLabel={(option: any) => option.sku}
                                                    disableClearable
                                                    renderOption={(props, option) => (
                                                        <li {...props}>
                                                          {option.label}
                                                        </li>
                                                      )}
                                                    renderInput={(params) => <TextField variant="standard" {...params} placeholder="SKU" />}
                                                    PopperComponent={(props: any)=> <StyledPopper {...props} style={{ width: "fit-content", padding: "1rem", display: "flex", flexDirection: "column", gap: '1rem' }} placement='bottom-start' />}
                                                    onChange={(evt: React.BaseSyntheticEvent, value: any)=> {
                                                        const sku_value = value.sku;
                                                        const newList = groupSkuList.attach(Downgraded).get().map((elm: any)=>{
                                                            if(elm._id === item._id){
                                                                return {"_id": elm._id, "sku": sku_value, "qtd": elm.qtd}
                                                            }else{
                                                                return elm
                                                            }
                                                        })
                                                        groupSkuList.set(newList)
                                                    }}
                                                />
                                            </div>
                                            <button type="button" className="btn-icon-red" onClick={()=>onRemoveBtnClick(item._id)}><FaMinus /></button>
                                        </InputMainContainer>

                                        {
                                            updateModal.get() ?
                                            overWriteInpuData.group_type.value === 'KIT' ?
                                            <InputMainContainer id={item._id} key={`config-${index}`}>
                                                <div className="input-container">
                                                    <input 
                                                        type="text"
                                                        name='kit_qtd'
                                                        id={'kit_qtd-'+index}  
                                                        required={true}
                                                        step={0.01}
                                                        value={item.qtd}
                                                        onChange={(evt: React.BaseSyntheticEvent)=> {
                                                            const value = evt.target.value;
                                                            const newList = groupSkuList.attach(Downgraded).get().map((elm: any, i: any)=>{
                                                                if(elm._id === item._id){
                                                                    return {"_id": elm._id, "sku": elm.sku, "qtd": value}
                                                                }else{
                                                                    return elm
                                                                }
                                                            })
                                                            groupSkuList.set(newList)
                                                        }}
                                                    />
                                                    <label htmlFor={'kit_qtd-'+index}>Quantidade dos Kits</label>
                                                </div>

                                            </InputMainContainer>
                                            :
                                            null
                                            :
                                            groupType.get() === 'KIT' ?
                                            <InputMainContainer id={item._id} key={`config-${index}`}>
                                                <div className="input-container">
                                                    <input 
                                                        type="text"
                                                        name='kit_qtd'
                                                        id={'kit_qtd-'+index}  
                                                        required={true}
                                                        step={0.01}
                                                        value={item.qtd}
                                                        onChange={(evt: React.BaseSyntheticEvent)=> {
                                                            const value = evt.target.value;
                                                            const newList = groupSkuList.attach(Downgraded).get().map((elm: any, i: any)=>{
                                                                if(elm._id === item._id){
                                                                    return {"_id": elm._id, "sku": elm.sku, "qtd": value}
                                                                }else{
                                                                    return elm
                                                                }
                                                            })
                                                            groupSkuList.set(newList)
                                                        }}
                                                    />
                                                    <label htmlFor={'kit_qtd-'+index}>Quantidade dos Kits</label>
                                                </div>
                                            </InputMainContainer>
                                            :
                                            null
                                        }

                                    </div>
                                )
                            })
                        }

                        <InputMainContainer>
                            <Button className='noStyle' variant="contained" startIcon={<AddIcon />} color='primary' sx={{fontSize: 'clamp(1.2rem, 1.2vw, 1.6rem)', width: '100%', display: 'flex', alignItems: 'center', '& .MuiButton-startIcon':{margin: '0'}}} onClick={onAddBtnClick}>
                                Adicionar SKUS
                            </Button>
                        </InputMainContainer>

                        <InputMainContainer>
                            <CheckboxContainer 
                                label="KPI" 
                                id="kpi" 
                                name="KPI"
                                value="KPI"
                                required={false}
                                checked={updateModal.get() ? overWriteInpuData.kpi.value:undefined}
                            />
                            <CheckboxContainer 
                                label="CPR" 
                                id="CPR" 
                                name="CPR"
                                value="CPR"
                                required={false}
                                checked={updateModal.get() ? overWriteInpuData.cpr.value:undefined}
                            />
                            <div className="radio-container">
                                <input 
                                    type='radio' 
                                    name="origin" 
                                    id="TC" 
                                    required={true} 
                                    value="TC" 
                                    checked={updateModal.get() && overWriteInpuData.origin.value === 'TC'? true:undefined}
                                    onChange={(evt:React.BaseSyntheticEvent)=> radioActive.set(evt.target.value)}
                                    />
                                <label htmlFor="TC">TC</label>

                                <input 
                                    type='radio' 
                                    name="origin" 
                                    id="RM" 
                                    required={true} 
                                    value="RM"
                                    checked={updateModal.get() && overWriteInpuData.origin.value === 'RM'? true:undefined}
                                    onChange={(evt:React.BaseSyntheticEvent)=> radioActive.set(evt.target.value)}
                                    />
                                <label htmlFor="RM">RM</label>
                            </div>
                            
                        </InputMainContainer>
                        
                        {updateModal.get() ?
                            <InputContainer name="id" type="hidden" value={overWriteInpuData.id.value} />
                            : null
                        } 

                        <InputMainContainer>
                            <Button variant="contained" sx={{fontSize: 'clamp(1.2rem, 1.2vw, 1.6rem) !important', width: '100%', display: 'flex', alignItems: 'center', '& .MuiButton-startIcon':{margin: '0'}}} type='submit'>
                                Salvar Grupo
                            </Button>
                        </InputMainContainer>
                    </BasicForm>
                </BasicModal>

                : null
            }

{
                modalDescVisible.get()
                ? <BasicModal closeFunction={()=> modalDescVisible.set(false)}>
                    <Box component="form" onSubmit={handleSaveNewDescription}>
                        <Paper elevation={3} sx={{width: '60vw', padding: '2rem'}}>
                            <Box sx={{display: 'flex',flexDirection: 'column', gap: '2rem', width: '100%'}}>
                                <h3>Alterar Descrição dos Anúncios</h3>
                                <TextField size="small" type='text' placeholder="Descrição" variant="outlined"
                                    multiline minRows={30} maxRows={30}
                                    value={modalDescriptionData.description.get()}
                                    sx={{minWidth: '21rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                    onChange={(evt: any)=> modalDescriptionData.description.set(evt.target.value)}
                                />
                                <Button size='small' type="submit" variant="contained" color='primary' sx={{minWidth: 'fit-content', fontSize: 'clamp(1.2rem, 1.2vw, 1.6rem)', '& .MuiButton-startIcon':{margin: '0'}}}>
                                    Salvar Descrição
                                </Button>
                            </Box>
                        </Paper>
                    </Box>
                </BasicModal>

                : null
            }

            <ModalConfirm />
            <BugReportModal />
        
            <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}> 
                <div className="content-wrapper">
                    <main className="main-content container-fluid">
                        <div className="input-container">
                            <Button variant="contained" color='primary' startIcon={<AddIcon />} sx={{fontSize: 'clamp(1.2rem, 1.2vw, 1.6rem)', width: 'fit-content', display: 'flex', alignItems: 'center', '& .MuiButton-startIcon':{margin: '0'}}} onClick={handleCreateGroup}>
                                Novo Grupo
                            </Button>
                        </div>
                        {

                            data.get() ?
                            <VirtualizedGrid 
                                columns={columns} 
                                rows={data.attach(Downgraded).get()} 
                                options={options}
                                title="Cadastro Agrupamentos SKUs" 
                                actions={[
                                    {icon: 'delete', title: 'Deletar cadastro', method: (evt: any, row:any)=> showHideModal({show: true, title: "Quer mesmo deletar esse item?", execute: (confirm: boolean)=> confirmDelete(confirm, row._id)})}, 
                                    {icon: 'edit', title: 'Editar SKUs', method: (evt: any, row:any)=> handleUpdate(row)},
                                    {icon: 'drive_file_rename_outline', title: 'Editar Descrição', method: (evt: any, row:any)=> handleUpdateDescription(row)}
                                ]} 
                                defaultSort={{field: 'type', direction: 'desc'}}
                            />
                            :
                            null
                        }
                    </main>
                </div>
            </div>
        </>
    )
}