import { BrowserRouter as Route, useParams } from 'react-router-dom';
import { Loading } from '../../../components/Loading/Loading';
import { useDarkMode } from '../../../hooks/useDarkMode';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import moment from 'moment';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import { IShippingRelation, ItensShippingRelation } from "../../../types/Types"
import { BugReportModal } from '../../../components/modal/BugReportModal';

export const ShippmentsAnalysis = () =>{
    document.title = "Análise do Envio - MeLiBeat";
    const handleValidateStatus = useHandleErrors();

    let { id } = useParams<any>();
    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const fetchResource = (query:any=undefined) => axs.get<IShippingRelation>(`fulfillment/${id}/validate`, {params: query}).then((r: any) => {
        handleValidateStatus(r)
        const itens = r.data[0].itens.map((item: any)=>{
            return {...item, "_id": Math.random()*10}
        })
        return itens
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    });

    const data: State<ItensShippingRelation[]> = useState<ItensShippingRelation[]>([]);
    const {DarkMode, } = useDarkMode();

    const options = {
        palette:{
          tableHeader: {
            main: '#F7CE3E',
            dark: '#ac902b',
            light: '#f8d764',
            contrastText: '#000'
          },
        },
        alternate: true,
    }

    const columns = [
        {
            headerName: "Scheduling",
            field: "scheduling",
            type: "string",
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            hide: true,
        },
        {
            headerName: "Produto",
            field: "product",
            type: "string",
            headerAlign: 'center',
            flex: 2,
            minWidth: 200,
            renderCell: (rowData: any) =>{
                return(
                    <Tippy content={rowData.row.product}>
                        <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            <span title={rowData.row.product}>{rowData.row.product}</span>
                        </main>
                    </Tippy>
                )
            }
        },
        {
            headerName: "SKU",
            field: "sku",
            type: "string",
            headerAlign: 'center',
            flex: 2,
            minWidth: 200,
            renderCell: (rowData: any) =>{
                return(
                    <span title={rowData.row.sku}>{rowData.row.sku}</span>
                )
            }
        },
        {
            headerName: "MLB ID",
            field: "mlb_id",
            type: "string",
            headerAlign: 'center',
            flex: 2,
            minWidth: 200,
            renderCell: (rowData: any) =>{
                return(
                    <span title={rowData.row.mlb_id}>{rowData.row.mlb_id}</span>
                )
            }
        },
        {
            headerName: "Variação",
            field: "variation",
            type: "string",
            headerAlign: 'center',
            flex: 2,
            minWidth: 200,
            renderCell: (rowData: any) =>{
                return(
                    <Tippy content={rowData.row.variation}>
                        <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            <span title={rowData.row.variation}>{rowData.row.variation}</span>
                        </main>
                    </Tippy>
                )
            }
        },
        {
            headerName: "Inventory ID",
            field: "inventory_id",
            type: "string",
            headerAlign: 'center',
            flex: 2,
            minWidth: 200,
            renderCell: (rowData: any) =>{
                return(
                    <span title={rowData.row.inventory_id}>{rowData.row.inventory_id}</span>
                )
            }
        },
        {
            headerName: "Quantidade Recomendada",
            field: "quantity_recomended",
            type: "number",
            headerAlign: 'center',
            flex: 2,
            minWidth: 200,
            renderCell: (rowData: any) =>{
                return(
                    rowData.row.date_created ?
                    <Tippy content={
                        <span>Quantidade gerada em: {moment(rowData.row.quantity_recomended).format("DD--MM--YYYY")}</span>
                    }>
                        <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            <span title={rowData.row.quantity_recomended}>{`${rowData.row.quantity_recomended} (${rowData.row.recomended_diff*100})`}</span>
                        </main>
                    </Tippy>
                    :
                    <span title={rowData.row.quantity_recomended}>{`${rowData.row.quantity_recomended} (${rowData.row.recomended_diff*100})`}</span>
                )
            }
        },
        {
            headerName: "Quantidade Enviada",
            field: "quantity_shipped",
            type: "number",
            headerAlign: 'center',
            flex: 2,
            minWidth: 200,
            renderCell: (rowData: any) =>{
                return(
                    <span title={rowData.row.quantity_shipped}>{`${rowData.row.quantity_shipped} (${rowData.row.shipped_diff*100})`}</span>
                )
            }
        },
        {
            headerName: "Vendas Período (25d)",
            field: "total_sold",
            type: "number",
            headerAlign: 'center',
            flex: 2,
            minWidth: 200,
            renderCell: (rowData: any) =>{
                return(
                    <span title={rowData.row.total_sold}>{rowData.row.total_sold}</span>
                )
            }
        },
        
    ]

    useEffect(() => {
        data.set(fetchResource())
    }, [])

    if(data.promised){ //Enquanto for promisse ou estiver carregando
        return (
            <>
                <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}> 
                    <div className="content-wrapper">
                        <main className="main-content container-fluid">
                            <Loading />
                        </main>
                    </div>
                </div>
            </>
        )
    }

    if (data.error) { // Quando o promise dar reject
        return (<ErrorScreen />)
    }

    return(
        <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
            <BugReportModal />
            <div className="content-wrapper">
                <main className="main-content container-fluid">
                    <VirtualizedGrid 
                        rows={data.attach(Downgraded).get()}
                        columns={columns}
                        options={options}
                        title="Análise dos Envios"
                        defaultSort={{field: 'scheduling', direction: 'desc'}}
                    />
                </main>
            </div>
        </div>
    )
}