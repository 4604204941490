import * as React from 'react';
import { 
  DataGridPro, 
  ptBR, 
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridCsvExportOptions,
  GridToolbarDensitySelector, 
  GridSortModel,
  GridSortDirection,
  GridToolbarContainerProps,
  useGridApiContext,
  GridCsvGetRowsToExportParams,
  gridFilteredSortedRowIdsSelector,
} from '@mui/x-data-grid-pro';
import { createSvgIcon } from '@mui/material/utils';
import Button,{ ButtonProps } from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Icon from '@mui/material/Icon';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import {DownloadXLS} from '../../utils/downloadFormats';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { ptBR as corePtBR } from '@mui/material/locale';
import { useDarkMode } from '../../hooks/useDarkMode';
import './globalFilterPanel.scss';
import './globalShowHidePanel.scss';
import './globalMenuPanel.scss';
import './globalTablePagination.scss';
import { useState , State, Downgraded } from '@hookstate/core';
import { Untracked } from '@hookstate/untracked';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import Tooltip from '@mui/material/Tooltip';

LicenseInfo.setLicenseKey(
  `9c08ea3d2cc4a4253af00a8b085ad7b2T1JERVI6Mzg5ODcsRVhQSVJZPTE2Nzc5NDMwNDQwMDAsS0VZVkVSU0lPTj0x`,
);


export default function VirtualizedGrid(props: any) {
  const {DarkMode, } = useDarkMode();
  const [pageSize, setPageSize] = React.useState<number>(props.pageSize ? props.pageSize : 25);
  const columns: State<any[]> = useState<any[]>(props.columns);
  const rows: State<any[]> = useState<any[]>(props.rows);
  rows.attach(Untracked);
  const tempRows: State<any[]> = useState<any[]>(props.rows);
  const searchInputValue: State<string> = useState<string>('');
  const searchInputAutoFocus: State<boolean> = useState<boolean>(false);
  // const tableHeight = props.tableHeight ? props.tableHeight : '70vh';
  const firstRow = props.rows[0] ? props.rows[0]._id : null;
  const selectedRow: State<string | null> = useState<string | null>(props.SelectFirstRow ? firstRow:undefined);
  const sortModel: State<GridSortModel> = useState<GridSortModel>(props.multipleSort ? props.multipleSort :
    [
      {
        field: props.defaultSort.field,
        sort: props.defaultSort.direction as GridSortDirection,
      },
    ]
  );
  const titleXls = props.title !== undefined ?  props.title : "Arquivo"
  const hideSearchAndTitle = props.hideSearchAndTitle !== undefined ? props.hideSearchAndTitle : false
  

  React.useEffect(()=>{
    if(props.actions){
      const actions = props.actions;
      if(Object.keys(columns[0]).includes('width')){
        columns.merge([ 
          {
            field: 'actions',
            headerName: "Ações",
            hide: false,
            sortable:false,
            disableColumnMenu: true,
            headerAlign: 'center',
            width: 100,
            renderCell: (rowData: any) => (
              actions.map((action: any, index: number)=>{
                if(action.disableBy){
                  if(rowData.row[action.disableBy.field] === action.disableBy.value){
                    return null
                  }
                }
                return (
                  <Tooltip key={`${action.title}-${Math.random()}`} placement="top" title={<span style={{fontSize: 'clamp(1.4rem, 1.4vw, 1.6rem)'}}>{action.title}</span>}>
                    <IconButton key={index} color='primary' onClick={(evt: React.BaseSyntheticEvent)=> action.method(evt, rowData.row)}><Icon fontSize="large">{action.icon}</Icon></IconButton>
                  </Tooltip>
                )
              })
            ),
          }
        ])  
      }else{
        columns.merge([
          {
            field: 'actions',
            headerName: "Ações",
            hide: false,
            sortable:false,
            disableColumnMenu: true,
            headerAlign: 'center',
            flex: 1,
            renderCell: (rowData: any) => (
              actions.map((action: any, index: number)=>{
                if(action.disableBy){
                  if(rowData.row[action.disableBy.field] === action.disableBy.value){
                    return null
                  }
                }
                return(
                  <Tooltip key={`${action.title}-${Math.random()}`} placement="top" title={<span style={{fontSize: 'clamp(1.4rem, 1.4vw, 1.6rem)'}}>{action.title}</span>}>
                    <IconButton key={index} color='primary' onClick={(evt: React.BaseSyntheticEvent)=> action.method(evt, rowData.row)}><Icon fontSize="large">{action.icon}</Icon></IconButton>
                  </Tooltip>
                )
              })
            ),
          }
        ])
      }
    }
  }, [])

  React.useEffect(()=>{
    if(props.rows){
      Untracked(rows).set(props.rows);
      tempRows.set(props.rows);
    }
  }, [props.rows])

  React.useEffect(()=>{
    if(props.columns){
      if(props.actions){
        const actions = props.actions;
        if(Object.keys(columns[0]).includes('width')){
          columns.merge([ 
            {
              field: 'actions',
              headerName: "Ações",
              hide: false,
              sortable:false,
              disableColumnMenu: true,
              headerAlign: 'center',
              width: 100,
              renderCell: (rowData: any) => (
                actions.map((action: any, index: number)=>{
                  if(action.disableBy){
                    if(rowData.row[action.disableBy.field] === action.disableBy.value){
                      return null
                    }
                  }
                  return(
                    <Tooltip key={`${action.title}-${Math.random()}`} placement="top" title={<span style={{fontSize: 'clamp(1.4rem, 1.4vw, 1.6rem)'}}>{action.title}</span>}>
                      <IconButton key={index} color='primary' onClick={(evt: React.BaseSyntheticEvent)=> action.method(evt, rowData.row)}><Icon fontSize="large">{action.icon}</Icon></IconButton>
                    </Tooltip>
                  )
                })
              ),
            }
          ])  
        }else{
          columns.merge([
            {
              field: 'actions',
              headerName: "Ações",
              hide: false,
              sortable:false,
              disableColumnMenu: true,
              headerAlign: 'center',
              flex: 1,
              renderCell: (rowData: any) => (
                actions.map((action: any, index: number)=>{
                  if(action.disableBy){
                    if(rowData.row[action.disableBy.field] === action.disableBy.value){
                      return null
                    }
                  }
                  return(
                    <Tooltip key={`${action.title}-${Math.random()}`} placement="top" title={<span style={{fontSize: 'clamp(1.4rem, 1.4vw, 1.6rem)'}}>{action.title}</span>}>
                      <IconButton key={index} color='primary' onClick={(evt: React.BaseSyntheticEvent)=> action.method(evt, rowData.row)}><Icon fontSize="large">{action.icon}</Icon></IconButton>
                    </Tooltip>
                  )
                })
              ),
            }
          ])
        }
      }else{
        columns.set(props.columns);
      }
    }
  }, [props.columns])

  const defaultTheme  = createTheme({
    palette:{
      mode: DarkMode ? 'dark' : 'light',
      ...props.options.palette
    },
  },corePtBR, ptBR);

  const useStyles = makeStyles(
    (theme) => {
      return {
        // root: {
        //   '& .super-app-theme--Open': {
        //     backgroundColor: getBackgroundColor(theme.palette.info.main),
        //     '&:hover': {
        //       backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
        //     },
        //   },
        // },
        root: {
          fontSize: 'clamp(1.2rem, 1.1vw, 1.4rem)',
          overflow: props.printTable ? 'hidden !important' : 'auto',
          // '& .MuiDataGrid-main':{
          //   '& div:nth-child(2)':{
          //     height: props.tableHeight ? 'fit-content !important' : 'initial',
          //     '& .MuiDataGrid-virtualScroller':{
          //       height: props.tableHeight ? 'fit-content !important' : 'initial',
          //     }
          //   }
          // },          
          '& .MuiDataGrid-detailPanels .MuiDataGrid-detailPanel':{
            overflowY: 'scroll',
          },
          '& .MuiDataGrid-footerContainer':{
            display: props.hidePagination ? 'none !important':'initial',
            '& .MuiDataGrid-rowCount':{
              fontSize: 'clamp(1.2rem, 1vw, 1.4rem) !important'
            }
          },
          '& .MuiDataGrid-columnHeaders, .MuiDataGrid-pinnedColumnHeaders':{
            lineHeight: 'normal !important',
            backgroundColor: theme.palette.tableHeader ? theme.palette.tableHeader.main : theme.palette.primary.dark,
            '&:hover':{
              backgroundColor: theme.palette.tableHeader ? theme.palette.tableHeader.light : theme.palette.primary.main,
            },
            '& .MuiDataGrid-iconSeparator':{
              color: theme.palette.tableHeader ? theme.palette.tableHeader.contrastText : theme.palette.primary.contrastText,
            },

            '& .MuiBadge-anchorOriginTopRightRectangular':{
              top: '5px !important',
              fontSize: 'clamp(1rem, 1vw, 1.2rem) !important'
            },
            '& .MuiDataGrid-columnHeaderTitleContainer':{
              '& .MuiDataGrid-columnHeaderTitle':{
                color: theme.palette.tableHeader ? theme.palette.tableHeader.contrastText : theme.palette.primary.contrastText,
                fontSize: 'clamp(1.2rem, 1.1vw, 1.4rem)',
                textTransform: 'capitalize',
                fontWeight: 'bold',
              },
              '& .MuiDataGrid-iconButtonContainer':{
                '& .MuiButtonBase-root':{
                  '& .MuiSvgIcon-root':{
                    fontSize: 'clamp(1.2rem, 1.1vw, 1.4rem)',
                    color: theme.palette.tableHeader ? theme.palette.tableHeader.contrastText : theme.palette.primary.contrastText,
                  }
                }
              },
            },
            '& .MuiDataGrid-menuIcon':{
              '& .MuiButtonBase-root':{
                '& .MuiSvgIcon-root':{
                  fontSize: 'clamp(1.2rem, 1.1vw, 1.4rem)',
                  color: theme.palette.tableHeader ? theme.palette.tableHeader.contrastText : theme.palette.primary.contrastText,
                }
              },
            },
          },
          '& .MuiDataGrid-row':{
            fontSize: 'clamp(1.1rem, 1vw, 1.3rem)',
            '&:nth-child(even)':{
              backgroundColor: props.options && props.options.alternate ? DarkMode ? theme.palette.grey[700] : theme.palette.grey[200] : '',
            },

            '&:hover:not(.MuiDataGrid-columnHeaders)': {
              backgroundColor: theme.palette.action.hover,
            },
            '& .MuiDataGrid-detailPanelToggleCell .MuiSvgIcon-root':{
              width: '1.5rem',
              height: '1.5rem'
            },
            '& .MuiDataGrid-cell':{
              textAlign: 'center',
              justifyContent: 'center !important',
            },
            '& .MuiDataGrid-cell:focus-within':{
              outline: 'none !important'
            },
            '& .MuiInputBase-root':{
              '& .MuiInputBase-input':{
                fontSize: 'clamp(1.1rem, 1vw, 1.2rem)',
              }
            },
            //Classes com os estilos das linhas
            //LCRE
            '&.lcreColors-negative-margin':{
              background: !DarkMode ? "#ffa89a" : '#c64539',
            },
            '&.lcreColors-bad-margin':{
              background: !DarkMode ? "#ffdbaf" : "#b36200",
            },
            '&.lcreColors-margin-average':{
              background: !DarkMode ? "#faffb1" : "#a7b300",
            },
            '&.lcreColors-good-margin':{
              background: !DarkMode ? "#d4ffb2" : "#388000",
            },
            '&.lcreColors-high-margin':{
              background: !DarkMode ? "#b2ffbb" : "#00b315",
            },
            '&.lcreColors-cancelled-sale':{
              background: !DarkMode ? "#c8d6e5" : "#395879",
            },
            '&.lcreColors-wrong-shipping-tax':{
              background: "#222f3e",
            },
            '&.lcreColors-cost-margin':{
              background: !DarkMode ? "#7ed6df" : "#195f66",
            },
            //PurchaseTracking
            '&.purchaseTracking-NotFinishedNotDelivered':{
              background: !DarkMode ? "#48dbfb39" : "#0490af",
            },
            '&.purchaseTracking-delivered':{
              background: !DarkMode ? "#2e86de39" : "#18599a",
            },
            '&.purchaseTracking-finished':{
              background: !DarkMode ? "#1dd1a139" : "#169d79",
            },
            '&.purchaseTracking-merged':{
              background: !DarkMode ? "#bbc5f5" : "#8854d0",
            },
            //SerialNumber
            '&.serialNumber-finishedEntrySerial':{
              background: !DarkMode ? "#b6d0ff" : "#0040b3",
            },
            '&.serialNumber-filteredRows':{
              background: !DarkMode ? "#81ecec" : "#179b9b",
            },
            //Catalog
            '&.catalog-winning':{
              background: !DarkMode ? "#F7CE3E" : '#ab8707',
            },
            //Accordion row
            '&.row-is-selected':{
              backgroundColor: '#a1d2fa',
              '&:hover':{
                backgroundColor: '#a1d2fa',
              }
            },
            //Reposição Full accordion
            '&.hide-accordion-row':{
              display: 'none',
            },
            '&.show-accordion-row':{
              display: 'flex',
            },
            '&.Full-accordion-actual':{
              backgroundColor: !DarkMode ? '#cacaca65' : '#0d0d0d',
            },
            //ShippingRelation
            '&.shippingRelation-relation':{
              backgroundColor: !DarkMode ? '#74b9ff' : '#0059b3',
            },
            '&.shippingRelation-relation-precification':{
              backgroundColor: !DarkMode ? '#fab1a0' : '#aa2709',
            },
            '&.shippingRelation-relation-precification-alteration':{
              backgroundColor: !DarkMode ? '#55efc4' : '#0b7557',
            },
            '&.shippingRelation-finished':{
              backgroundColor: '#b2bec3',
            },
            //AllShipments
            '&.AllShipments-alternate-color-finished':{
              '&:nth-child(even)':{
                backgroundColor: !DarkMode ? '#fff5c3' : '#b39500',
              },
              '&:nth-child(odd)':{
                backgroundColor: !DarkMode ? '#ffe4b1' : '#e69600',
              },
            },
          },
        
        },
      };
    },
    { defaultTheme }
  );

  const SearchInput = styled(TextField)({
    '& .MuiInput-input':{
      fontSize: 'clamp(1.05rem, 1.1vw, 1.4rem)',
    },
    '& label':{
      fontSize: 'clamp(1.05rem, 1.1vw, 1.4rem)',

    }
  });

  const classes = useStyles();

  const filterRows = (event: React.ChangeEvent<HTMLInputElement>)=>{
    const value = event.target.value;
    searchInputValue.set(value)
    let aux_data = JSON.parse(JSON.stringify(tempRows.attach(Downgraded).get()))
    if(value !== ''){
      const filteredRows = aux_data.filter((item: any)=> {
        return Object.values(item).toString().toLowerCase().includes(value.toLowerCase())
      });
      Untracked(rows).set(filteredRows);
    }else{
      Untracked(rows).set(tempRows.attach(Downgraded).get());
    }
  }
  
  const ExportIcon = createSvgIcon(
    <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
    'SaveAlt',
  );

  const getRowsWithGroups = ({ apiRef }: GridCsvGetRowsToExportParams) =>
  gridFilteredSortedRowIdsSelector(apiRef);

  const CustomToolbar = (props: GridToolbarContainerProps) => {
    const apiRef = useGridApiContext();

    const handleExport = (options: GridCsvExportOptions) =>
      apiRef.current.exportDataAsCsv(options);
  
    const buttonBaseProps: ButtonProps = {
      color: 'primary',
      startIcon: <ExportIcon />,
    };

    return (
      <GridToolbarContainer style={{padding: '0'}}>
        <ThemeProvider theme={defaultTheme}>
          <Paper style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '2rem', borderRadius: '0'}}>
          {
            hideSearchAndTitle ?
            null
            :
            <Grid container  sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <Grid item lg style={{marginLeft: "2rem"}}>
                <Typography
                    sx={{ flex: '1 1 100%', fontWeight: 'bold'}}
                    variant="h4"
                    id="tableTitle"
                    component="div"
                >
                  {titleXls}
                  {/* Usei a constate de título do xls para não criar outra variável */}
                </Typography>
              </Grid>
              <GridToolbarColumnsButton style={{fontSize:"x-small"}}/>
              <GridToolbarFilterButton style={{fontSize:"x-small"}}/>
              <GridToolbarDensitySelector style={{fontSize:"x-small"}}/>
              <Button style={{fontSize:"x-small"}} {...buttonBaseProps}  onClick={() => handleExport({ getRowsToExport: getRowsWithGroups })} title="Exportar para CSV com Filtros de Tabela Ativos">CSV</Button>
              <Button style={{fontSize:"x-small"}} {...buttonBaseProps} onClick={() => DownloadXLS(titleXls, rows.attach(Downgraded).get())} title="Exportar para XLSX somente com Filtro de pesquisa rápido">XLSX</Button>
              <Grid item style={{display: 'flex', flexDirection: 'row', marginBottom: "1.5rem", marginRight: "2rem", alignItems: 'baseline', justifyContent: 'flex-end', gap: '5px'}}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5, width: '2rem', height: '2rem' }} />
                  <SearchInput 
                    label="Pesquisar" 
                    variant="standard" 
                    value={searchInputValue.attach(Downgraded).get()} 
                    onChange={filterRows}
                    onClick={(event: any)=> searchInputAutoFocus.set(true)}
                    onBlur={(event: any)=> searchInputAutoFocus.set(false)}
                    autoFocus={searchInputAutoFocus.attach(Downgraded).get()}
                  />
                </Box>
              </Grid>
            </Grid>
            }
          </ Paper>
        </ThemeProvider>
    </GridToolbarContainer>
    )
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Paper style={{ width: '100%', padding: "2rem", display: 'flex', flexDirection: 'column', gap: '2rem'}}>
        <div style={{ width: '100%' }}>
          <DataGridPro
            apiRef={props.apiRef}
            ref={props.componentRef}
            rows={rows.attach(Downgraded).get()}
            columns={columns.attach(Downgraded).get()}
            pagination
            autoHeight
            rowsPerPageOptions={[props.pageSize ? props.pageSize : 25, 50, 100, 200, props.rows.length]}
            pageSize={pageSize}
            onPageSizeChange={(value: number)=> setPageSize(value)}
            components={{ Toolbar: CustomToolbar }}
            density="compact"
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={(params: any, event: React.BaseSyntheticEvent)=> {
              if(props.hasOwnProperty('onRowClick') && props.onRowClick !== null){
                if(params.id === selectedRow.get()){
                  props.onRowClick({sameRow: true, row: params.row})
                  selectedRow.set(null)
                }else{
                  props.onRowClick({sameRow: false, row: params.row})
                  selectedRow.set(params.id);
                }
              }
            }}
            checkboxSelection={props.options.selection ? props.options.selection:false}
            onSelectionModelChange={(newSelectionModel: any) => {
              if(props.hasOwnProperty('onSelection')){
                const selectedRows = rows.attach(Downgraded).get().filter((row: any)=> newSelectionModel.includes(row._id));
                props.onSelection(selectedRows)
              }else if(props.hasOwnProperty('onSelectionDefault')){
                props.onSelectionDefault(newSelectionModel)
              }
            }}
            disableSelectionOnClick
            className={classes.root}
            onColumnVisibilityChange={(params: any)=> {
              const columnData = [];
              const localData = localStorage.getItem(props.title) ? localStorage.getItem(props.title) : null;
              if(localData !== null){
                const parsedData = JSON.parse(localData);
                const filteredData = parsedData.filter((item: any)=> item.field !== params.field)
                columnData.push(...filteredData);
              }
              columnData.push({field: params.field, visibility: params.isVisible})
              localStorage.setItem(props.title, JSON.stringify(columnData));
            }}
            columnBuffer={10}
            columnThreshold={10}
            onCellEditCommit={props.onCellEdit ? props.onCellEdit : null}
            getRowId={(row: any)=>{return row._id}}
            getRowClassName={(rowData: any)=> {
              if(rowData.id === selectedRow.get() && props.hasOwnProperty('onRowClick')){
                return 'row-is-selected'
              }else{
                if(props.options.hasOwnProperty('rowStyle')){
                  const row_class = props.options.rowStyle(rowData.row);
                  return row_class;
                }else{
                  return ''
                }
              }
            }}
            sortModel={sortModel.attach(Downgraded).get()}
            onSortModelChange={(model: any) => sortModel.set(model)}
            hideFooterPagination={props.hidePagination}  
            disableVirtualization={props.disableVirtualization}   
            initialState={props.initialState}     
            getDetailPanelContent={props.detailPanel}
            getDetailPanelHeight={({ row }) => 300}
          />
        </div>
      </ Paper>
    </ThemeProvider>
  );
}