import { InputMainContainer, InputContainer } from '../../../components/Form/BasicForm';
import { BaseSyntheticEvent } from "react";
import { toast } from 'react-toastify';
import axios from 'axios';
import { useState , State } from '@hookstate/core';
import { AiOutlineLoading3Quarters} from 'react-icons/ai';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';

export const Fiscais =  ()=>{
    const token: string | undefined = localStorage.getItem('token') || undefined;
    const handleValidateStatus = useHandleErrors();
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const fetchResource = (query:any=undefined) => axs.get<string | number>('/settings/settingsTax', {params: query}).then((r: any) => {
        handleValidateStatus(r)
        const tax:number = r.data.fiscal.tax_aliquot * 100;
        return tax
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return 0
    })
    const taxAliquot: State<string | number> = useState<string | number>(0);

    useEffect(() => {
        taxAliquot.set(fetchResource())
    }, [])

    const handleSetTax = (value: {"taxAliquot":string}) =>{
        taxAliquot.set(value['taxAliquot']);
    }

    const handlePostTax = async(evt: BaseSyntheticEvent) =>{
        const processedData: object = {
            "tax_aliquot": taxAliquot.get()
        }
        axs.put<string | number>('/settings/tax', processedData).then((r: any) => {
            if(r.status === 200){
                toast.success('As Informações fiscais foram Atualizadas!');
            }else{
                toast.success('Ocorreu um erro ao atualizar as informações!');
            }
            
        }).catch((err: any)=>{
            handleValidateStatus(err.response)
        })
    }

    if(taxAliquot.promised){ //Enquanto for promisse ou estiver carregando
        return (
            <>
                <div className={`wrapper`}> 
                    <div className="content-wrapper">
                        <main className="main-content container-fluid">
                            <div className="loading">
                                <AiOutlineLoading3Quarters className="loading-icon" />
                            </div>
                        </main>
                    </div>
                </div>
            </>
        )
    }

    if (taxAliquot.error) { // Quando o promise dar reject
        return (<ErrorScreen />)
    }

    return(
        <section className="fiscal-section">
            {
                taxAliquot.get() !== undefined ?
                <InputMainContainer style={{'width': '100%', 'display': 'flex', 'gap': '1rem'}}>
                    <InputContainer 
                        label="Aliquota de Imposto"
                        id="taxAliquot"
                        class="taxAliquot"
                        name="taxAliquot" 
                        type="number" 
                        required={true}
                        value={taxAliquot.get()}
                        onChange={(value: {"taxAliquot":string})=> handleSetTax(value)}
                    />
                    <button onClick={handlePostTax}>Salvar</button>
                </InputMainContainer>
                :
                null

            }
        </section>
    )
}