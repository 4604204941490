import { useEffect, useRef } from 'react';
import { Card } from '../../../../components/cards/Card';
import { BasicForm, InputMainContainer, InputFilterContainer, DateFilterContainer } from '../../../../components/Form/BasicForm';
import { toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import Popper from '@material-ui/core/Popper';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'react-tippy/dist/tippy.css';
import { ItensShippingRelationPrecify, InfShippingRelationPrecify } from "../../../../types/Types"
import VirtualizedGrid from '../../../../components/table/VirtualizedGrid';
import { styled } from '@mui/material/styles';
import '../../../../components/table/globalInputs.scss';
import { useState , State, Downgraded } from '@hookstate/core';
import socket from '../../../../utils/socketConnection';
import Button from '@mui/material/Button';

const StyledPopper = styled(Popper)({
    "& .MuiAutocomplete-listbox": {
      '& li':{
        fontSize: 'clamp(1.1rem, 1vw, 1.3rem)',
      }
    }
  });

interface ITotalizer {
    cost: number,
    sellsValue: number,
    profit: number,
    margin: number
}

interface IDataShipping {
    scheduling: string | undefined ,
    description: string| undefined ,
    schedulingQty: number| undefined ,
    volumesQty: number | undefined ,
    completed: boolean,
    LOADED: boolean
}


export const Precification = (props: any) =>{
    const data: State<ItensShippingRelationPrecify[]> = useState<ItensShippingRelationPrecify[]>([]);
    const showFinishBtn: State<boolean> = useState<boolean>(false)
    
    const itemsFinished: any = useRef(null)
    const totalizer = useState<ITotalizer>({cost: 0, sellsValue: 0, profit: 0, margin: 0})
    const inputs: any = useState(undefined)
    const dataShipping = useState<IDataShipping>({scheduling: undefined, description: undefined, schedulingQty: undefined, volumesQty: undefined, completed: false, LOADED: false});

    let resData: any;
    let idx: number;
    let filterTimeout: any = useRef(null);

    useEffect(() => {
        toast.info('Pressione a tecla "Enter" para confirmar as alterações dentro da tabela.', {autoClose: 2000})
        socket.emit("fulfillment:getStepTwo", {'id': props.itemId});
        socket.on("responseData", (result: any) => {
            switch(result.operation){
                case 'fulfillment:getStepTwo':
                    data.set(result.data.itens);

                    dataShipping.batch(
                        (shipping)  =>  {
                            shipping.scheduling.set(result.data.scheduling)
                            shipping.description.set(result.data.description)
                            shipping.schedulingQty.set(Math.round(result.data.quantity))
                            shipping.volumesQty.set(Math.round(result.data.volume))
                            shipping.completed.set(result.data.completed.precification)
                            shipping.LOADED.set(true)
                        })

                    //Inicializador e Populizer dos Inputs
                    const inputsFill: Map<string,any> = new Map()
                    const finishedFill: Map<string,any> = new Map()
                    for(let item of result.data.itens){
                        inputsFill.set(item._id, {
                            margin: Math.round((item.margin !== undefined ? item.margin : 0)*100),
                            sellPrice: item.sell_price.toFixed(2),
                            sellPrice12x: item.sell_price_12x.toFixed(2),
                            costShippingFee: item.cost_shipping_fee,
                            tax: item.tax * 100,
                            precificationAll: item.precification_all,
                            finishedPrecify: item.finished_precify,
                        })
                        if(item.finished){
                            finishedFill.set(item._id,item.finished_precify)
                        }
                    }
                    
                    inputs.set(Object.fromEntries(inputsFill))
                    itemsFinished.current = Object.fromEntries(finishedFill)
                    handleChangeData()
                    break;
                case 'fulfillment:putStatus':
                    toast.success("Seção de Precificação Finalizada!");
                    props.resetPage()
                    break;
                case 'fulfillment:putItems':
                    switch(result.ref){
                        case 'cost_shipping_fee':
                            resData = result.data;
                            idx = data.attach(Downgraded).get().findIndex((item) => item._id  === resData._id)
                            if(idx > -1){
                                data[idx].batch(
                                    (item)  =>  {
                                        item.cost_marketplace.set(0)
                                        item.cost_tax.set(0)
                                        item.sell_price.set(0)
                                        item.sell_price_12x.set(0)
                                        item.margin.set(0)
                                        item.profit.set(0)
                                        item.result_profit.set(0)
                                        item.cost_shipping_fee.set(resData.cost_shipping_fee)
                                    })
                                
                                    inputs[resData._id].batch(
                                        (inp: any) => {
                                            inp.sellPrice.set(0)
                                            inp.sellPrice12x.set(0)
                                            inp.margin.set(0)
                                            inp.costShippingFee.set(resData.cost_shipping_fee)
                                        }
                                    )

                                handleChangeData()
                            }
                            break;
                        case 'tax':
                            resData = result.data;
                            idx = data.attach(Downgraded).get().findIndex((item) => item._id  === resData._id)
                            if(idx > -1){
                                data[idx].batch(
                                    (item)  =>  {
                                        item.cost_marketplace.set(0)
                                        item.cost_tax.set(0)
                                        item.sell_price.set(0)
                                        item.sell_price_12x.set(0)
                                        item.margin.set(0)
                                        item.profit.set(0)
                                        item.result_profit.set(0)
                                        item.tax.set(resData.tax * 100)
                                    })
                                inputs[resData._id].batch(
                                    (inp: any) => {
                                        inp.sellPrice.set(0)
                                        inp.sellPrice12x.set(0)
                                        inp.margin.set(0)
                                        inp.tax.set(resData.tax * 100)
                                    }
                                )
                                handleChangeData()
                            }
                            break;
                        case 'type_mlb':
                            resData = result.data;
                            idx = data.attach(Downgraded).get().findIndex((item) => item._id  === resData._id)
                            if(idx > -1){
                                data[idx].batch(
                                    (item)  =>  {
                                        item.type_mlb.set(resData.type_mlb)
                                        item.cost_marketplace.set(0)
                                        item.cost_tax.set(0)
                                        item.sell_price.set(0)
                                        item.sell_price_12x.set(0)
                                        item.margin.set(0)
                                        item.profit.set(0)
                                        item.result_profit.set(0)
                                    })
                                inputs[resData._id].batch(
                                    (inp: any) => {
                                        inp.sellPrice.set(0)
                                        inp.sellPrice12x.set(0)
                                        inp.margin.set(0)
                                    }
                                )
                                handleChangeData()
                            }
                            break;
                        case 'precification_all':
                            resData = result.data;
                            idx = data.attach(Downgraded).get().findIndex((item) => item._id  === resData._id)
                            if(idx > -1){
                                data[idx].batch(
                                    (item)  =>  {
                                        item.precification_all.set(resData.precification_all)
                                    }
                                )

                                inputs[resData._id].batch(
                                    (inp: any) => {
                                        inp.precificationAll.set(resData.precification_all)
                                    }
                                )
                            }
                            break;
                        case 'finished_precify':
                            resData = result.data;
                            idx = data.attach(Downgraded).get().findIndex((item) => item._id  === resData._id)
                            if(idx > -1){
                                data[idx].batch(
                                    (item)  =>  {
                                        item.finished_precify.set(resData.finished_precify)
                                    }
                                )
                                inputs[resData._id].batch(
                                    (inp: any) => {
                                        inp.finishedPrecify.set(resData.finished_precify)
                                    }
                                )
                                itemsFinished.current[resData._id] = resData.finished_precify
                                handleFinishedButton()
                                //handleChangeData()
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                case 'fulfillment:putPrecify':
                    resData = result.data;
                    idx = data.attach(Downgraded).get().findIndex((item) => item._id  === resData._id)
                    if(idx > -1){
                        data[idx].batch(
                            (item)  =>  {
                                item.type_mlb.set(resData.type_mlb)
                                item.cost_marketplace.set(resData.cost_marketplace.toFixed(2))
                                item.cost_tax.set(parseFloat(resData.cost_tax.toFixed(2)))
                                item.sell_price.set(parseFloat(resData.sell_price.toFixed(2)))
                                item.sell_price_12x.set(parseFloat(resData.sell_price_12x.toFixed(2)))
                                item.margin.set(parseFloat(resData.margin.toFixed(2)))
                                item.profit.set(parseFloat(resData.profit))
                                item.result_profit.set(parseFloat(resData.result_profit))
                            }
                        )

                        inputs[resData._id].batch(
                            (inp: any) => {
                                inp.sellPrice.set(resData.sell_price.toFixed(2))
                                inp.sellPrice12x.set(resData.sell_price_12x.toFixed(2))
                                inp.margin.set((resData.margin * 100).toFixed(2))
                            }
                        )

                        handleChangeData()
                    }
                    break;
                default:
                    return
            }
        })
        socket.on("responseError", (result) => {
            toast.error(result.message);
        })
    }, [])

    const handleFinishedButton = () => {
        const values = Object.values(itemsFinished.current)
        const totalToFinish = data.length
        const finished = values.filter(it => it === true).length
        if(finished === totalToFinish && showFinishBtn.get() === false){
            showFinishBtn.set(true)
        }else if(finished !== totalToFinish && showFinishBtn.get() === true){
            showFinishBtn.set(false)
        }
    }

    const handleChangeData = async()=>{
        let finish:boolean = true;
        let sumCostProduct: number = 0;
        let sumProfitByAd: number = 0;
        let sumProfit: number = 0;
        let sumMarginOverSells: number = 0;
        for(let item of data.attach(Downgraded).get()){
            if(item.finished_precify === false) finish = false;
            if(item.cost_product) {
                sumCostProduct += item.cost_product
            };
            if(item.type_mlb === "gold_special") {
                sumProfitByAd += Math.round(item.quantity_shipped * (item.sell_price !== undefined ?item.sell_price : 0))
            };
            if(item.type_mlb === "gold_pro") {
                sumProfitByAd += Math.round(item.quantity_shipped * (item.sell_price_12x !== undefined ? item.sell_price_12x : 0))
            };
            if(item.profit) {
                sumProfit += item.result_profit !== undefined ? item.result_profit : 0
            };
        }
       
        sumMarginOverSells = sumProfit / sumProfitByAd

        sumMarginOverSells = sumMarginOverSells === undefined || sumMarginOverSells === Infinity || isNaN(sumMarginOverSells) ? 0 : sumMarginOverSells
        sumCostProduct = sumCostProduct === undefined || sumCostProduct === Infinity || isNaN(sumCostProduct) ? 0 : sumCostProduct
        sumProfit = sumProfit === undefined || sumProfit === Infinity || isNaN(sumProfit) ? 0 : sumProfit
        sumProfitByAd = sumProfitByAd === undefined || sumProfitByAd === Infinity || isNaN(sumProfitByAd) ? 0 : sumProfitByAd

        totalizer.batch(
            (total) => {
                total.cost.set(sumCostProduct)
                total.sellsValue.set(sumProfitByAd)
                total.profit.set(sumProfit)
                total.margin.set(sumMarginOverSells)
            }
        )

        if(finish === true && showFinishBtn.get() === false){
            showFinishBtn.set(true)
        }else if(finish === false && showFinishBtn.get() === true){
            showFinishBtn.set(false)
        }
    }


    const checkVisibility = (column: string)=>{
        const storageColumnsData = localStorage.getItem('Precificação') ? localStorage.getItem('Precificação') : null;
        if(storageColumnsData !== null){
          const localData = JSON.parse(storageColumnsData);
          const shouldHide = localData.find((item: any)=> item.field === column);
          return shouldHide !== undefined ? !shouldHide.visibility : false
        }
        return false
    }

    const columns = [
        {
          headerName: "MLB",
          field: "mlb_id",
          type: "string",
          hide: checkVisibility('mlb_id'),
          headerAlign: 'center',
          flex: 1,
          minWidth: 100,
          renderCell: (rowData: any) =>{
            const suppliers_list = rowData.row.inf.map((item: InfShippingRelationPrecify, index: number)=>{
                return(
                    <table key={index} style={{width: 'fit-content', fontSize: "clamp(1.2rem, 1.4vw, 1.5rem)"}}>
                        {
                        index < 1 ?
                        <thead style={{borderBottom: '1px solid rgba(255,255,255, 0.6)'}}>
                            <tr style={{width: '100%', display: 'flex', justifyContent: 'space-between', gap: '0rem'}}>
                            <th style={{padding: '0.5rem', width: "10rem", textAlign:"left"}}><strong>SKU</strong></th>
                            <th style={{padding: '0.5rem', width: "10rem", textAlign:"left"}}><strong>Custo</strong></th>
                            <th style={{padding: '0.5rem', width: "15rem", textAlign:"left"}}><strong>Variação</strong></th>
                            </tr>
                        </thead>
                        :
                        null
                        }
                        <tbody>
                        <tr style={{width: '100%', display: 'flex', justifyContent: 'space-evenly', gap: '1rem', borderBottom: '1px solid rgba(255,255,255, 0.6)'}}>
                            <td style={{padding: '0.5rem', width: "10rem", textAlign:"left"}}>{item.sku}</td>
                            <td style={{padding: '0.5rem', width: "10rem", textAlign:"left"}}>{(item.cost ? item.cost : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                            <td 
                            style={{padding: '0.5rem', width: "15rem", textAlign:"left", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} 
                            title={item.variation ? item.variation : "N/A"}
                            >
                                {item.variation ? item.variation : "N/A"}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                )
            })
            return (
                // <Tooltip
                // position="top"
                // interactive={true}
                // html={suppliers_list}
                // >
                //     <span>{rowData.row.mlb_id}</span>
                // </Tooltip>

                <Tippy content={suppliers_list}>
                    <span>{rowData.row.mlb_id}</span>
                </Tippy>
            )
          }
        },
        {
            headerName: "Produto",
            field: "product",
            type: "string",
            hide: checkVisibility('product'),
            headerAlign: 'center',
            flex: 3,
            minWidth: 300,
            renderCell: (rowData: any) =>{
                return(
                    <Tippy content={rowData.row.product}>
                        <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            <span title={rowData.row.product}>{rowData.row.product}</span>
                        </main>
                    </Tippy>
                )
            }
        },
        {
            headerName: "Quantidade",
            field: "quantity_shipped",
            type: "number",
            hide: checkVisibility('quantity_shipped'),
            headerAlign: 'center',
            flex: 0.7,
            minWidth: 70,
            renderCell: (rowData: any) =>{
                return(
                  <span>{rowData.row.quantity_shipped}</span>
                )
            }
        },
        {
            headerName: "Custo Produto",
            field: "cost_product",
            type: "number",
            hide: checkVisibility('cost_product'),
            headerAlign: 'center',
            flex: 1.1,
            minWidth: 110,
            renderCell: (rowData: any) =>{
                return(
                  <span>{(rowData.row.cost_product ? rowData.row.cost_product : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                )
            }
        },
        {
            headerName: "Custo Frete",
            field: "cost_shipping_fee",
            type: "number",
            hide: checkVisibility('cost_shipping_fee'),
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    <InputMainContainer>
                        <div className="input-container">
                            <input
                                tabIndex={1}
                                type="number"
                                className="inputTable money"
                                name="cost_shipping_fee"
                                id={`cost_shipping_fee-${rowData.row._id}`}
                                required={true}
                                value={(inputs[rowData.row._id].costShippingFee.get())}
                                disabled={dataShipping.completed.get() || rowData.row.finished_precify}
                                onFocus={(evt: React.BaseSyntheticEvent) => evt.target.select()}
                                onChange={async(evt: any)=>{
                                    evt.persist();
                                    clearTimeout(filterTimeout.current)

                                    filterTimeout.current = setTimeout(() => {
                                        const processedData = [
                                            {
                                                "field": "cost_shipping_fee",
                                                "value": evt.target.value,
                                            }
                                        ]
                                        processedData.push(
                                            {
                                                "field": "cost_marketplace",
                                                "value": 0,
                                            },
                                            {
                                                "field": "cost_tax",
                                                "value": 0,
                                            },
                                            {
                                                "field": "sell_price",
                                                "value": 0,
                                            },
                                            {
                                                "field": "sell_price_12x",
                                                "value": 0,
                                            },
                                            {
                                                "field": "margin",
                                                "value": 0,
                                            },
                                            {
                                                "field": "profit",
                                                "value": 0,
                                            },
                                            {
                                                "field": "result_profit",
                                                "value": 0,
                                            },
                                        )
                                        socket.emit("fulfillment:putItems", {'id': props.itemId, "fields": processedData, "idItems": rowData.row.ids, ref: "cost_shipping_fee"});
                                    }, 1500)
                                    inputs[rowData.row._id].costShippingFee.set(evt.target.value)

                                }}
                            />
                        <label style={{top: '7.5px'}} htmlFor={`cost_shipping_fee-${rowData.row._id}`}>R$</label>
                        </div>
                    </InputMainContainer>
                )
            }
        },
        {
            headerName: "Custo Marketplace",
            field: "cost_marketplace",
            type: "number",
            hide: checkVisibility('cost_marketplace'),
            headerAlign: 'center',
            flex: 1.1,
            minWidth: 110,
            renderCell: (rowData: any) =>{
                return(
                  <strong>{(rowData.row.cost_marketplace ? rowData.row.cost_marketplace : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong>
                )
            }
        },
        {
            headerName: "Custo Fiscal",
            field: "cost_tax",
            type: "number",
            hide: checkVisibility('cost_tax'),
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                  <strong>{(rowData.row.cost_tax ? rowData.row.cost_tax : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong>
                )
            }
        },
        {
            headerName: "Imposto",
            field: "tax",
            type: "number",
            hide: checkVisibility('tax'),
            headerAlign: 'center',
            flex: 0.9,
            minWidth: 90,
            renderCell: (rowData: any) =>{
                return(
                    <InputMainContainer>
                        <div className="input-container">
                            <input
                                tabIndex={2}
                                type="number"
                                className="inputTable percent"
                                name="tax"
                                id={`tax-${rowData.row._id}`}
                                required={true}
                                value={inputs[rowData.row._id].tax.get()}
                                disabled={dataShipping.completed.get() || rowData.row.finished_precify}
                                onFocus={(evt: React.BaseSyntheticEvent) => evt.target.select()}
                                onChange={async(evt: any)=>{
                                    evt.persist();
                                    clearTimeout(filterTimeout.current)

                                    filterTimeout.current = setTimeout(() => {
                                        const processedData = [
                                            {
                                                "field": "tax",
                                                "value": parseFloat(evt.target.value) / 100,
                                            }
                                        ]
                                        processedData.push(
                                            {
                                                "field": "cost_marketplace",
                                                "value": 0,
                                            },
                                            {
                                                "field": "cost_tax",
                                                "value": 0,
                                            },
                                            {
                                                "field": "sell_price",
                                                "value": 0,
                                            },
                                            {
                                                "field": "sell_price_12x",
                                                "value": 0,
                                            },
                                            {
                                                "field": "margin",
                                                "value": 0,
                                            },
                                            {
                                                "field": "profit",
                                                "value": 0,
                                            },
                                            {
                                                "field": "result_profit",
                                                "value": 0,
                                            },
                                        )
                                        socket.emit("fulfillment:putItems", {'id': props.itemId, "fields": processedData, "idItems": rowData.row.ids, ref: "tax"});
                                    }, 1500)
                                    inputs[rowData.row._id].tax.set(parseFloat(evt.target.value))
                                }}
                            />
                            <label style={{top: '7.5px'}} htmlFor={`tax-${rowData.row._id}`}>%</label>
                        </div>
                    </InputMainContainer>
                )
            }
        },
        {
            headerName: "Tipo Anúncio",
            field: "type_mlb",
            type: "string",
            hide: checkVisibility('tax'),
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                const types = [{name: "Premium", type: "gold_pro"}, {name: "Clássico", type: "gold_special"}]
                return(
                    <InputMainContainer>
                        <div className="input-container">
                            <Autocomplete
                                tabIndex={3}
                                id={`ad_type-${rowData.row._id}`}
                                options={types}
                                value={{type: rowData.row.type_mlb ? rowData.row.type_mlb: '', name: rowData.row.type_mlb ? rowData.row.type_mlb === "gold_special" ? "Clássico" : "Premium" : ''}}
                                getOptionLabel={(option: any) => option.name}
                                isOptionEqualToValue={(option, value) => option.type === value.type}
                                // getOptionSelected={(option: any, value:any)=> value.type === option.type}
                                disabled={dataShipping.completed.get() || rowData.row.finished_precify}
                                disableClearable
                                renderInput={(params) => <TextField variant="standard" {...params} placeholder="Tipo do Anúncio" />}
                                renderOption={(props, option) => (
                                    <li {...props}>
                                      {option.name}
                                    </li>
                                )}
                                PopperComponent={(props)=> <StyledPopper {...props} style={{ width: "fit-content", padding: "1rem",display: "flex", flexDirection: "column", gap: '1rem' }} placement='bottom-start' />}
                                onChange={async(evt: React.BaseSyntheticEvent, value: any)=> {
                                    evt.persist();
                                    const values = [
                                        {
                                            "field": "type_mlb",
                                            "value": value.type
                                        },
                                        {
                                            "field": "cost_marketplace",
                                            "value": 0,
                                        },
                                        {
                                            "field": "cost_tax",
                                            "value": 0,
                                        },
                                        {
                                            "field": "sell_price",
                                            "value": 0,
                                        },
                                        {
                                            "field": "sell_price_12x",
                                            "value": 0,
                                        },
                                        {
                                            "field": "margin",
                                            "value": 0,
                                        },
                                        {
                                            "field": "profit",
                                            "value": 0,
                                        },
                                        {
                                            "field": "result_profit",
                                            "value": 0,
                                        },
                                    ]
                                    socket.emit("fulfillment:putItems", {'id': props.itemId, "fields": values, "idItems": rowData.row.ids, ref: "type_mlb"});
                                    
                                }}
                            />
                        </div>
                </InputMainContainer>
                )
            }
        },
        {
            headerName: "Valor Venda",
            field: "sell_price",
            type: "number",
            hide: checkVisibility('sell_price'),
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    <InputMainContainer>
                        <div className="input-container">
                            <input
                                tabIndex={4}
                                type="number"
                                className="inputTable money"
                                name="sell_price"
                                id={`sell_price-${rowData.row._id}`}
                                required={true}
                                value={inputs[rowData.row._id].sellPrice.get()}
                                disabled={dataShipping.completed.get() || rowData.row.finished_precify}
                                onFocus={(evt: React.BaseSyntheticEvent) => evt.target.select()}
                                onChange={async(evt: any)=>{
                                    evt.persist();
                                    clearTimeout(filterTimeout.current)
                                    if(evt.target.value <= 0){
                                        toast.info("O valor do campo Preço de venda 12x não pode ser menor ou igual a 0")
                                        return;
                                    }
                                    filterTimeout.current = setTimeout(() => {
                                        const value = {
                                            "cost_product": parseFloat(rowData.row.cost_product),
                                            "cost_shippment": rowData.row.cost_shipping_fee !== null ? parseFloat(rowData.row.cost_shipping_fee) : 0,
                                            "marketplace_fee": parseFloat(rowData.row.marketplace_fee),
                                            "tax": rowData.row.tax > 1 ? parseFloat((rowData.row.tax/100).toFixed(2)) : parseFloat((rowData.row.tax).toFixed(2)),
                                            "sell_price": parseFloat(evt.target.value),
                                            "sell_price_12x": null,
                                            "margin":  null,
                                            "qty": parseFloat(rowData.row.quantity_shipped),
                                            "listing_type": rowData.row.type_mlb,
                                            "ids": rowData.row.ids,
                                            "id_fulfillment": props.itemId
                                        }
                                        socket.emit("fulfillment:putPrecify", value);
                                    }, 1500)
                                    inputs[rowData.row._id].sellPrice.set(evt.target.value)
                                }}
                            />
                        <label style={{top: '7.5px'}} htmlFor={`sell_price-${rowData.row._id}`}>R$</label>
                        </div>
                    </InputMainContainer>
                )
            }
        },
        {
            headerName: "Preço 12x",
            field: "sell_price_12x",
            type: "number",
            hide: checkVisibility('sell_price_12x'),
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    <InputMainContainer>
                        <div className="input-container">
                            <input
                                tabIndex={5}
                                key={rowData.row}
                                type="number"
                                className="inputTable money"
                                name="sell_price_12x"
                                id={`sell_price_12x-${rowData.row._id}`}
                                required={true}
                                value={(inputs[rowData.row._id].get().sellPrice12x)}
                                disabled={dataShipping.completed.get() || rowData.row.finished_precify}
                                onFocus={(evt: React.BaseSyntheticEvent) => evt.target.select()}
                                onChange={async(evt: any)=>{
                                    evt.persist();
                                    clearTimeout(filterTimeout.current)
                                    if(evt.target.value <= 0){
                                        toast.info("O valor do campo Preço de venda 12x não pode ser menor ou igual a 0")
                                        return;
                                    }
                                    filterTimeout.current = setTimeout(() => {
                                        const value = {
                                            "cost_product": parseFloat(rowData.row.cost_product),
                                            "cost_shippment": rowData.row.cost_shipping_fee !== null ? parseFloat(rowData.row.cost_shipping_fee) : 0,
                                            "marketplace_fee": parseFloat(rowData.row.marketplace_fee),
                                            "tax": rowData.row.tax > 1 ? parseFloat((rowData.row.tax/100).toFixed(2)) : parseFloat((rowData.row.tax).toFixed(2)),
                                            "sell_price": null,
                                            "sell_price_12x": parseFloat(evt.target.value),
                                            "margin":  null,
                                            "qty": parseFloat(rowData.row.quantity_shipped),
                                            "listing_type": rowData.row.type_mlb,
                                            "ids": rowData.row.ids,
                                            "id_fulfillment": props.itemId
                                        }
                                        socket.emit("fulfillment:putPrecify", value);
                                    }, 1500)
                                    inputs[rowData.row._id].sellPrice12x.set(evt.target.value)
                                }}
                            />
                            <label style={{top: '7.5px'}} htmlFor={`sell_price_12x-${rowData.row._id}`}>R$</label>
                        </div>
                    </InputMainContainer>
                )
            }
        },
        {
            headerName: "Margem",
            field: "margin",
            type: "number",
            hide: checkVisibility('margin'),
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    <InputMainContainer>
                        <div className="input-container">
                            <input
                                tabIndex={6}
                                type="number"
                                key={rowData.row.margin}
                                className="inputTable percent"
                                name="margin"
                                id={`margin-${rowData.row._id}`}
                                required={true}
                                max={99999}
                                value={inputs[rowData.row._id].get().margin}
                                disabled={dataShipping.completed.get() || rowData.row.finished_precify}
                                onFocus={(evt: React.BaseSyntheticEvent) => evt.target.select()}
                                onChange={async(evt: any)=>{
                                    evt.persist();
                                    clearTimeout(filterTimeout.current)
                                    if(evt.target.value <= 0){
                                        toast.info("O valor do campo Margin não pode ser menor ou igual a 0")
                                        return;
                                    }
                                    if(evt.target.value > 99999){
                                        evt.target.value = parseFloat(evt.target.value.toString().substring(0,5))
                                    }

                                    filterTimeout.current = setTimeout(() => {
                                        const value = {
                                            "cost_product": parseFloat(rowData.row.cost_product),
                                            "cost_shippment": rowData.row.cost_shipping_fee !== null ? parseFloat(rowData.row.cost_shipping_fee) : 0,
                                            "marketplace_fee": parseFloat(rowData.row.marketplace_fee),
                                            "tax": rowData.row.tax > 1 ? parseFloat((rowData.row.tax/100).toFixed(2)) : parseFloat((rowData.row.tax).toFixed(2)),
                                            "sell_price": null,
                                            "sell_price_12x": null,
                                            "margin":  parseFloat((evt.target.value/100).toFixed(2)),
                                            "qty": parseFloat(rowData.row.quantity_shipped),
                                            "listing_type": rowData.row.type_mlb,
                                            "ids": rowData.row.ids,
                                            "id_fulfillment": props.itemId
                                        }
                                        socket.emit("fulfillment:putPrecify", value);
                                    }, 1500)
                                    inputs[rowData.row._id].margin.set(evt.target.value)
                                }}
                            />
                            <label style={{top: '7.5px'}} htmlFor={`margin-${rowData.row._id}`}>%</label>
                        </div>
                    </InputMainContainer>
                )
            }
        },
        {
            headerName: "Lucro",
            field: "profit",
            type: "number",
            hide: checkVisibility('profit'),
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                  <span>{(rowData.row.profit ? rowData.row.profit : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                )
              }
        },
        {
            headerName: "Resultado",
            field: "result_profit",
            type: "number",
            editable: 'never',
            hide: checkVisibility('result_profit'),
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                  <span>{(rowData.row.result_profit ? rowData.row.result_profit : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                )
            }
        },
        {
            headerName: "Precificação Geral",
            field: "precification_all ",
            type: "boolean",
            editable: 'never',
            hide: checkVisibility('precification_all'),
            headerAlign: 'center',
            flex: 0.7,
            minWidth: 70,
            renderCell: (rowData: any) =>{
                return(
                    <div className="switch-container">
                        <label className="switch">
                            <input 
                                type="checkbox" 
                                checked={inputs[rowData.row._id].precificationAll.get()}
                                disabled={dataShipping.completed.get() || rowData.row.finished_precify}
                                onChange={async(evt: React.BaseSyntheticEvent)=> {
                                    evt.persist();
                                    const processedData = [
                                        {
                                            "field": "precification_all",
                                            "value": evt.target.checked,
                                        }
                                    ]
                                    inputs[rowData.row._id].precificationAll.set(evt.target.checked)
                                    socket.emit("fulfillment:putItems", {'id': props.itemId, "fields": processedData, "idItems": rowData.row.ids, ref: "precification_all"});
                                }}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                )
            }
        },
        {
            headerName: "Finalizado",
            field: "finished_precify",
            type: "boolean",
            editable: 'never',
            flex: 0.7,
            minWidth: 70,
            renderCell: (rowData: any) =>{
                return(
                    <div className="switch-container">
                        <label className="switch">
                            <input 
                                type="checkbox" 
                                checked={inputs[rowData.row._id].finishedPrecify.get()}
                                disabled={dataShipping.completed.get()}
                                onChange={async(evt: React.BaseSyntheticEvent)=> {
                                    evt.persist();
                                    if(rowData.row.sku === undefined || rowData.row.sku === "" || 
                                    rowData.row.product === undefined || rowData.row.product === ""){
                                        toast.info("Os campos SKU e Produto devem estar preenchidos.");
                                        evt.target.checked = !evt.target.checked;
                                        return
                                    }
                                    if(rowData.row.sell_price <= 0 || rowData.row.sell_price_12x <= 0){
                                        toast.info("Os campos Preço de Venda e Preço 12x não podem ser menores ou iguais a 0.");
                                        evt.target.checked = !evt.target.checked;
                                        return
                                    }

                                    const processedData = [
                                        {
                                            "field": "finished_precify",
                                            "value": evt.target.checked,
                                        }
                                    ]
                                    inputs[rowData.row._id].finishedPrecify.set(evt.target.checked)
                                    socket.emit("fulfillment:putItems", {'id': props.itemId, "fields": processedData, "idItems": rowData.row.ids, ref: "finished_precify"});
                                    
                                }}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                )
            }
        },
        
    ]

    const options = {
        palette:{
            tableHeader: {
            main: '#F7CE3E',
            dark: '#ac902b',
            light: '#f8d764',
            contrastText: '#000'
            },
        },
        alternate: true,
        rowStyle: (rowData: any) => {
            let row_class = '';
            if(rowData.finished_precify) {
                row_class = "AllShipments-alternate-color-finished"
            }        
            return row_class
        },
    }


    const handleSubmit = (evt:React.BaseSyntheticEvent)=>{
        let canFinish: boolean = true;
        let showToastFinished: boolean = true;
        let showToastPrices: boolean = true;
        evt.preventDefault();
        if(data.length <= 0){
            toast.info("Não é possível finalizar um envio sem itens.")
            return
        }
        data.attach(Downgraded).get().forEach((item: ItensShippingRelationPrecify)=>{
            if(item.sell_price <= 0 || item.sell_price_12x <= 0){
                if(showToastPrices){
                    toast.info("Os campos Preço de Venda e Preço de Venda 12x devem ser maiores que 0.");
                    showToastPrices = false;
                }
            }
            if(!item.finished_precify){
                if(showToastFinished){
                    toast.info("Todos os itens devem estar finalizados.");
                    showToastFinished = false;
                }
                canFinish = false
            }
        })

        if(canFinish){
            socket.emit("fulfillment:putStatus", {'id': props.itemId, "field": "precification", "value": true});
        }
    }

    return(
        <div className="precificationSection">
            <main className='container-fluid' style={{padding: '0'}}>
            {
            dataShipping.LOADED.get() ?
            <BasicForm style={{width: '100%'}} SubmitFunction={handleSubmit}>
                <InputMainContainer style={{justifyContent: "flex-end"}}>
                        <InputFilterContainer 
                            label="Descrição do Envio"
                            id="description"
                            class="description"
                            name="description"
                            value={dataShipping.description.get()} 
                            type="text" 
                            disabled={true}
                        />

                        <DateFilterContainer
                            label="Agendamento de Entrega"
                            class="date"
                            id="date" 
                            name="date"
                            value={dataShipping.scheduling.get()}
                            type="date"
                            disabled={true}
                        />

                        <InputFilterContainer 
                            label="Qtd. Volumes do Agendamento"
                            id="volumesItens"
                            class="volumesItens"
                            name="volumesItens"
                            value={dataShipping.volumesQty.get()}
                            type="text" 
                            onEnter={true}
                            disabled={true}
                        />

                        <InputFilterContainer 
                            label="Qtd. Itens do Agendamento"
                            id="schedulingItens"
                            class="schedulingItens"
                            name="schedulingItens"
                            value={dataShipping.schedulingQty.get()}
                            type="text" 
                            disabled={true}
                        />
                    <InputMainContainer style={{width: '60%'}}>
                        <Button disabled={!showFinishBtn.get() ? true:false || dataShipping.completed.get()} variant="contained" color='error' sx={{fontSize: 'clamp(1.2rem, 1.2vw, 1.6rem)', fontFamily: "'Lato', sans-serif", width: '100%', display: 'flex', alignItems: 'center', '& .MuiButton-startIcon':{margin: '0'}}} type='submit'>
                            Finalizar Etapa
                        </Button>
                    </InputMainContainer>
                </InputMainContainer>
            </BasicForm>
            : null
            }
            <div className="card-container">
                <Card 
                    title="Custo Total Produtos"
                    content={(totalizer.cost.get() ? totalizer.cost.get() : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                    lastMonth={0} percent={false} 
                    integer={false} style={{width: '25rem'}} simple={true} text={true}
                />
                <Card 
                    title="Valor Total Faturado"
                    content={(totalizer.sellsValue.get() ? totalizer.sellsValue.get() : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} 
                    lastMonth={0} percent={false} 
                    integer={false} style={{width: '25rem'}} simple={true} text={true}
                />
                <Card 
                    title="Lucro Total" 
                    content={(totalizer.profit.get() ? totalizer.profit.get() : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} 
                    lastMonth={0} percent={false} 
                    integer={false} style={{width: '25rem'}} simple={true} text={true}
                    styleContent={{color: ((totalizer.profit.get() !== undefined ? totalizer.profit.get() : 0) < 0) ? "#EA2027" :""}}
                />
                <Card 
                    title="Margem Sobre Vendas" content={totalizer.margin.get()} lastMonth={0} percent={true} 
                    integer={false} text={false} style={{width: '25rem'}} simple={true} prefix="" decimals={2} increment={true}
                />
            </div>

            {
                data.length > 0 && inputs.get() !== undefined ?
                <>
                    <VirtualizedGrid 
                        rows={data.attach(Downgraded).get()}
                        columns={columns}
                        options={options}
                        tableHeight={'auto'}
                        title="Precificação"
                        defaultSort={{field: 'finished_precify', direction: 'asc'}}
                    />
                    {/* <TableComponent 
                        actions={{exportExcel: true}} 
                        // onEditCell={(value: any, rowData:any, columnName: any)=> handleCellEdit(value, rowData, columnName)}
                        alternateColor="alternate"
                        tableTitle="Precificação" 
                        options={options} 
                        columns={columns} 
                        data={data}
                    /> */}
                </>
                : null
            }
        </main>
        </div>
    )
}