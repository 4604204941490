import { FaAngleUp, FaAngleDown } from 'react-icons/fa';
import { useDarkMode } from '../../hooks/useDarkMode';
import { useState , State } from '@hookstate/core';

import './style.scss'

export const Accordion = (props: any) => {
  const expanded: State<boolean> = useState<boolean>(false)
  const {DarkMode, } = useDarkMode();

  return (
    <article className={`accordion container-fluid ${DarkMode ? 'dark-mode-accordion' : ''}`}>
      <div>
        <h4 onClick={() => expanded.set(!expanded.get())} className='accordion-title'>
          {props.title}
        </h4>
        <button className='btn' onClick={() => expanded.set(!expanded.get())}>
          {expanded ? <FaAngleUp className="icon" /> : <FaAngleDown className="icon" />}
        </button>
      </div>
      {expanded.get() && props.data ?
        <>
          <hr />
          <ul>
            {
              Object.entries(props.data.colors).map((item: any, index: number)=>{
                return <li key={index}><div style={{width: '2rem', height: '2rem', backgroundColor: item[1], boxShadow: '1px 1px 2px rgba(0,0,0,0.5)'}}></div><span>{item[0]}</span></li>
              })
            }
          </ul>
          <ul>
            {
              Object.entries(props.data.tags).map((item: any, index: number)=>{
                return <li key={index}>
                        <div style={{padding: '.3rem', color: '#fff', backgroundColor: item[1][0], boxShadow: '1px 1px 2px rgba(0,0,0,0.5)'}}>
                          {item[1][1]}
                        </div>
                        <span>{item[0]}</span>
                      </li>
              })
            }
          </ul>

        </>
        
        :
        null  
    }
    </article>
  )
}
