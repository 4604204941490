// import { TableComponent, CatalogSubTable } from '../../../components/table/Table';
import { columns, options } from '../../../components/table/files/CatalogData';
import { toast } from 'react-toastify';
import noData from '../../../assets/images/nodata.svg';
import './style.scss';
import {FiAlertTriangle} from 'react-icons/fi';
import {FaTrophy, FaTruck, FaCheckCircle, FaTimesCircle, FaPauseCircle} from 'react-icons/fa';
import {BiRevision} from 'react-icons/bi'
import {BsFillTagFill} from 'react-icons/bs';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import { useDarkMode } from '../../../hooks/useDarkMode';
import { GridModal } from '../../../components/modal/GridModal';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import { Loading } from '../../../components/Loading/Loading';
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import Box from '@mui/material/Box';
import { 
    ICatalog, 
    CompetitorCatalog,

} from "../../../types/Types";
import { BugReportModal } from '../../../components/modal/BugReportModal';

export const Catalog = (props:any) =>{
    document.title = "Catálogo - MeLiBeat";
    const handleValidateStatus = useHandleErrors();

    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const fetchResource = (query:any=undefined) => axs.get<ICatalog[]>('/mlbs/catalog/', {params: query}).then((r: any) => {
        handleValidateStatus(r)
        const statusTranslations:any = {"winning": 'Ganhando', "loosing": 'Perdendo', 'not_listed': 'Não Listado'};
        const adTranslation:any = {"gold_pro": "Premium", "gold_special": "Clássico"}
        for(let item of r.data){
            item.status = statusTranslations[item.status]
            item.listingTypeWinner = adTranslation[item.listingTypeWinner]
        }
        return r.data;
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    });

    const data: State<ICatalog[]> = useState<ICatalog[]>([]);
    const showModal: State<boolean> = useState<boolean>(false);
    const modalData: State<CompetitorCatalog[]> = useState<CompetitorCatalog[]>([]);
    const modalTableTitle: State<string> = useState<string>('');
    const {DarkMode, } = useDarkMode();



    const modalOptions = {
        palette:{
          tableHeader: {
            main: '#F7CE3E',
            dark: '#ac902b',
            light: '#f8d764',
            contrastText: '#000'
          },
        },
        rowStyle: (rowData: any) => {
            let row_class = '';
            const winning: boolean = rowData.winning;
            if(winning) row_class = "catalog-winning";

            return row_class
          },
        alternate: true,
    }
    
    const modalColumns = [
        { 
            headerName: "Vendedor",
            field: "seller", 
            type: 'string',
            headerAlign: 'center',
            flex: 1,
            renderCell: (rowData: any) => {
                return (
                    <span>{rowData.row.seller}</span>
                )
            }
        },
        { 
            headerName: "Tipo Anúncio",
            field: "listing_type_id", 
            type: 'string',
            headerAlign: 'center',
            flex: 1,
            renderCell: (rowData: any) => {
                const adTranslation:any = {"gold_pro": "Premium", "gold_special": "Clássico"}
              return (
                <span>
                  {adTranslation[rowData.row.listing_type_id]}
                </span>
              )
            }
        },
        { 
            headerName: "Preço Venda",
            field: "price", 
            type: 'number',
            headerAlign: 'center',
            flex: 1,
            renderCell: (rowData: any) => {
                return(
                    <span>
                        {(rowData.row.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                    </span>
              )
            }
        },{ 
            headerName: "Qtd. Disponível",
            field: "available_quantity", 
            type: 'number',
            headerAlign: 'center',
            flex: 1,
            renderCell: (rowData: any) => {
              return (
                <span>
                  {rowData.row.available_quantity}
                </span>
              )
            }
        },
    ]


    let accordionColumns = [
        { 
            headerName: "ID",
            field: "id", 
            type: 'string',
            headerAlign: 'center',
            flex: 1,
            renderCell: (rowData: any) => {
                return (
                    <span>{rowData.row.id}</span>
                )
            }
        },
        { 
            headerName: "Relação",
            field: "catalog_relation", 
            type: 'string',
            headerAlign: 'center',
            flex: 1,
            renderCell: (rowData: any) => {
                return (
                    <span>{rowData.row.hasOwnProperty('catalog_relation') ? rowData.row.catalog_relation.mlb_id : null}</span>
                )
            }
        },
        {
            headerName: "Tags",
            field: "tags",
            type: "string",
            headerAlign: 'center',
            flex: 1,
            renderCell: (rowData: any) => {
              const tagStatus:any = {"active": <FaCheckCircle className="icon"/>, "inactive": <FaTimesCircle className="icon"/>, 'paused': <FaPauseCircle className="icon"/>, "under_review": <BiRevision className="icon" />, "closed": <FaTimesCircle className="icon"/>}
              const tagStatusTranslations:any = {"active": 'Ativo', "inactive": 'Inativo', 'paused': 'Pausado', "under_review": "Em Revisão", "closed": "Fechado"}
              const statusColor:any = {"active": "#2477ad", "inactive": "#a5b1c2", 'paused': "#d65446", "under_review": "#000", "closed": "#c0392b"}
              const tagColors: any = {
                'tags': 
                [
                    {'title': 'Envio Grátis', 'value': <FaTruck className="icon" />, 'color': (rowData.row.free_shipping ? '#4b7bec':'#a5b1c2')},
                    {'title': `Status: ${tagStatusTranslations[rowData.row.status]}`, 'value': tagStatus[rowData.row.status], 'color':  (rowData.row.status ? statusColor[rowData.row.status]:'#a5b1c2')},
                    {'title': `Promoção: ${rowData.row.original_price !== null ? (((rowData.row.sell_price - rowData.row.original_price)/rowData.row.original_price)*100):0}%`, 'value': <BsFillTagFill className="icon"/>, 'color': (rowData.row.deal ? '#e056fd':'#a5b1c2')},
                ]
              };
              return (
                <div className="tags-container" style={{marginTop: 0, flexWrap: 'nowrap', display: 'flex', gap: '5px'}}>
                  {
                    Object.values(tagColors.tags).map((item: any, index: any)=>{
                        return(
                            <Tippy content={item.title} maxWidth={'25rem'}>
                                <span key={index} className="tag-small" style={{color: item.color}}>{item.value}</span>
                            </Tippy>
                        )
                    })
                  }
        
                </div>
              )
            }
          },
        { 
            headerName: "Status",
            field: "statusCatalog", 
            type: 'string',
            headerAlign: 'center',
            flex: 1,
            renderCell: (rowData: any) => {
                const tagStatus:any = {"winning": <FaTrophy className="icon"/>, "loosing": <FiAlertTriangle className="icon"/>, 'not_listed': <FaTimesCircle className="icon"/>}
                const statusColor:any = {"winning": "#2477ad", 'loosing': "#f39c12", "not_listed": "#e74c3c"}
                const statusTranslations:any = {"winning": 'Ganhando', "loosing": 'Perdendo', 'not_listed': 'Não Listado'};
                return (
                  <span style={{display: 'flex', alignItems: "center", justifyContent: "flex-start", gap: '5px'}}>
                      {statusTranslations[rowData.row.statusCatalog]}
                      <span style={{color: statusColor[rowData.row.statusCatalog], fontSize: "1rem", marginLeft: '.5rem'}}>
                          {tagStatus[rowData.statusCatalog]}
                      </span>
                  </span>
                )
            }
        },
        { 
            headerName: "Tipo Anúncio",
            field: "listing_type", 
            type: 'string',
            headerAlign: 'center',
            flex: 1,
            renderCell: (rowData: any) => {
                const adTranslation:any = {"gold_pro": "Premium", "gold_special": "Clássico"}
              return (
                <span>
                  {adTranslation[rowData.row.listing_type]}
                </span>
              )
            }
        },
        { 
            headerName: "Preço de Venda",
            field: "sell_price", 
            type: 'number',
            headerAlign: 'center',
            flex: 1,
            renderCell: (rowData: any) => {
                return (
                    <span>{(rowData.row.sell_price ? rowData.row.sell_price:0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                )
            }
        },
        { 
            headerName: "Margem  ",
            field: "margin", 
            type: 'number',
            headerAlign: 'center',
            flex: 1,
            renderCell: (rowData: any) => {
                return (
                    <span>{(rowData.row.margin*100).toFixed(2)}%</span>
                )
            }
        },
        { 
            headerName: "Quantidade",
            field: "available_qty", 
            type: 'number',
            headerAlign: 'center',
            flex: 1,
            renderCell: (rowData: any) => {
                return (
                    <span>{rowData.row.available_qty}</span>
                )
            }
        }
    ]

    useEffect(() => {
        data.set(fetchResource())
    }, [])

    const handleView = async(options: ICatalog) =>{
        const productTitle = options.titleCatalog.substring(0, 35)+'...';
        modalTableTitle.set(productTitle)
        
        toast.info("Carregando Concorrentes.");
        axs.get<ICatalog[]>('/mlbs/catalog/'+options.catalog_id, {params:  {'itemWinner': options.itemWinner}}).then((r: any) => {
            if(r.status === 200){
                showModal.set(true)
                modalData.set(r.data);
            }else{
                toast.error("Ocorreu um erro ao carregar a Tabela!");
            }
        }).catch((err: any)=>{
            handleValidateStatus(err.response)
            return []
        })
    }

    if(data.promised){ //Enquanto for promisse ou estiver carregando
        return (
            <>
                <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}> 
                    <div className="content-wrapper">
                        <main className="main-content container-fluid">
                            <Loading />
                        </main>
                    </div>
                </div>
            </>
        )
    }

    if (data.error) { // Quando o promise dar reject
        return (<ErrorScreen />)
    }

    return(
        <>
            <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
                {
                    showModal.get() === true
                    ?
                    <GridModal 
                        closeFunction={()=> {
                            showModal.set(false);
                        }} 
                        tableTitle={`Concorrentes: ${modalTableTitle.get()}`} 
                        options={modalOptions} 
                        columns={modalColumns} 
                        data={modalData.attach(Downgraded).get()} 
                        style={{width: '80%'}}
                        defaultSort={{field: 'price', direction: 'desc'}}
                    />
                    : null
                }
                <BugReportModal />
                <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
                    <main className="main-content container-fluid">
                        {
                            data.length > 0 ?
                            <VirtualizedGrid 
                                rows={data.attach(Downgraded).get()}
                                columns={columns}
                                options={options}
                                title="Catálogo"
                                defaultSort={{field: 'sell_price', direction: 'asc'}}
                                actions={[
                                    {icon: 'visibility', title: 'Visualizar Item', method: (event: any, row:any)=> handleView(row)}, 
                                ]}
                                detailPanel={({ row }: {row:any}) => 
                                    <Box sx={{ margin: 1 }}>
                                        <VirtualizedGrid 
                                            rows={row['mlbs']}
                                            columns={accordionColumns}
                                            options={options}
                                            title={""}
                                            tableHeight= 'auto'
                                            defaultSort={{field: 'actual', direction: 'desc'}}
                                            hidePagination={true}
                                            hideSearchAndTitle={false}
                                        />
                                    </Box>
                                }
                            />
                            // <CollapsibleTable 
                            //     rows={data.attach(Downgraded).get()} 
                            //     columns={columns} 
                            //     options={options}
                            //     tableHeight="700px"
                            //     title="Catálogo"
                            //     accordionField="mlbs"
                            //     accordionTitle=""
                            //     accordionColumns={accordionColumns}
                            //     actions={[
                            //         {icon: 'visibility', method: (row:any)=> handleView(row)}, 
                            //     ]}
                            //     defaultSort={{field: 'sell_price', direction: 'asc'}}
                            //     RowsPerPage={25}
                            //     hidePaginationAccordion={true}
                            // />
                            :
                            <div className="img-container">
                                <img src={noData} alt="Não foi possível renderizar o gráfico por falta de dados." />
                                <h4>Não foi possível renderizar os gráficos por falta de dados.</h4>
                            </div>
                        }
                    </main>
                </div>
            </div>
        </>
    )
}