import { Filters } from '../../../components/Filters/Filters';
import { useDarkMode } from '../../../hooks/useDarkMode';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { toast } from 'react-toastify';
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import moment from 'moment';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import { IAverageCost, ObjetoVazio } from '../../../types/Types';
import Paper from '@mui/material/Paper';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { Loading } from '../../../components/Loading/Loading';
import { BugReportModal } from '../../../components/modal/BugReportModal';

const INPUT_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.6rem)';


export const AverageCostManagement = () =>{
    document.title = "Gestão de Custo Médio - MeLiBeat";
    const handleValidateStatus = useHandleErrors();

    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const fetchResource = (query:any={undefined}) => axs.get<IAverageCost[]>(`/avaregecosts/`, {params: query}).then((r: any) => {
        handleValidateStatus(r)
        return r.data
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    })
    const data: State<IAverageCost[]> = useState<IAverageCost[]>([]);
    const {DarkMode, } = useDarkMode();
    const filterValues: ObjetoVazio = useState<ObjetoVazio>({"finished": undefined})

    const options = {
        palette:{
          tableHeader: {
            main: '#F7CE3E',
            dark: '#ac902b',
            light: '#f8d764',
            contrastText: '#000'
          },
        },
        alternate: true,
    }

    const columns = [
        {
            headerName: "Data",
            field: "dateCreated",
            type: "string",
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    <span title={moment(rowData.row.dateCreated).format("DD-MM-YYYY")}>{moment(rowData.row.dateCreated).format("DD-MM-YYYY")}</span>
                )
            }
        },
        {
            headerName: "SKU",
            field: "sku",
            type: "string",
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    <span title={rowData.row.sku}>{rowData.row.sku}</span>
                )
            }
        },
        {
            headerName: "Produto",
            field: "product",
            type: "string",
            headerAlign: 'center',
            flex: 3,
            minWidth: 300,
            renderCell: (rowData: any) =>{
                return(
                    <Tippy content={rowData.row.product}>
                        <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            <span title={rowData.row.product}>{rowData.row.product}</span>
                        </main>
                    </Tippy>
                )
            }
        },
        {
            headerName: "Ref",
            field: "ref",
            type: "string",
            headerAlign: 'center',
            flex: 2,
            minWidth: 200,
            renderCell: (rowData: any) =>{
                return(
                    <Tippy content={rowData.row.ref}>
                        <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            <span title={rowData.row.ref}>{rowData.row.ref}</span>
                        </main>
                    </Tippy>
                )
            }
        },
        {
            headerName: "Custo Médio",
            field: "averageCost",
            type: "number",
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    <Tippy content={
                        <ul style={{listStyle: 'none', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '2rem', padding: '2rem'}}>
                            <li>
                                Custo Bling: {rowData.row.historyCalc && rowData.row.historyCalc.oldCost !== undefined ? rowData.row.historyCalc.oldCost.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}) : "N/A"}
                            </li>
                            <li>
                                Qtd. Bling: {rowData.row.historyCalc && rowData.row.historyCalc.oldQty !== undefined ? rowData.row.historyCalc.oldQty : "N/A"}
                            </li>
                            <li>
                                Preço Nota: {rowData.row.historyCalc && rowData.row.historyCalc.newCost !== undefined ? rowData.row.historyCalc.newCost.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}) : "N/A"}
                            </li>
                            <li>
                                Qtd. Nota: {rowData.row.historyCalc && rowData.row.historyCalc.newQty !== undefined ? rowData.row.historyCalc.newQty : "N/A"}
                            </li>
                        </ul>
                    }>
                        <span title={rowData.row.avaregeCost.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}>{rowData.row.avaregeCost.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}</span>
                    </Tippy>
                )
            }
        },
    ]
    
    useEffect(() => {
        data.set(fetchResource())
    }, [])

    const handleFilter = (filters: any)=>{
        toast.info("Filtrando dados!");
        data.set(fetchResource({
            "finished": filters.filterFinished === '1' ? true : false,
        }))
    }

    const handleReset = ()=> {
        filterValues.finished.set(undefined)
        data.set(fetchResource({"finished": false}))
    }

    const handleFinish = (row: any) =>{
        axs.put<IAverageCost[]>(`/avaregecosts/${row._id}`).then((r: any) => {
            if(r.status === 200){
                toast.success("Item Finalizado!");
                data.set(fetchResource({"finished": false}))
            }else{
                toast.error("Ocorreu um erro!");
            }
        }).catch((err: any)=>{
            handleValidateStatus(err.response)
        })
    }

    return(
        <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
            <BugReportModal />
            <div className="content-wrapper">
                <main className="main-content container-fluid">
                    <Filters submitFunction={handleFilter} hideFullResetBtn handleReset={handleReset}>
                        <FormControl size='small' variant='outlined' sx={{minWidth: '20rem'}} key={filterValues.finished.get()}>
                            <InputLabel id="finished-label-filter" style={{fontSize: INPUT_FONT_SIZE}}>Finalizado</InputLabel>
                            <Select
                                labelId="finished-label-filter"
                                id="finished-filter"
                                name='filterFinished'
                                value={filterValues.finished.get()}
                                label="Origem"
                                style={{fontSize: INPUT_FONT_SIZE}}
                                required
                                onChange={(evt: SelectChangeEvent)=> filterValues.finished.set(evt.target.value)}
                            >
                                <MenuItem value={''}></MenuItem>
                                <MenuItem value={'1'}>Sim</MenuItem>
                                <MenuItem value={'0'}>Não</MenuItem>
                            </Select>
                        </FormControl>
                    </Filters>
                    {
                        data.promised ? 
                        <Loading />
                        : data.error ?
                        <ErrorScreen /> : 
                        data.length > 0 ?
                        <VirtualizedGrid 
                            rows={data.attach(Downgraded).get()}
                            columns={columns}
                            options={options}
                            tableHeight={'auto'}
                            title="Gestão de Custo Médio" 
                            defaultSort={{field: 'dateCreated', direction: 'asc'}}
                            actions={[
                                {icon: 'done', title: 'Finalizar Item', method: (evt: any, row:any)=> handleFinish(row), disableBy: {field: 'finished', value: true}}
                            ]} 
                        />
                        :
                        <Paper style={{ width: '100%', height: '100%', padding: "2rem", display: 'flex', alignItems: 'center', justifyContent:'center'}}>
                            <h3>
                                Altere o método de filtragem para que os valores sejam mostrados na tabela.
                            </h3>
                        </Paper>
                    }
                </main>
            </div>
        </div>
    )
}