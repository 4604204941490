
import hero2 from '../../assets/images/hero-landing-2.svg';
import './style.scss'; 
import { useDarkMode } from '../../hooks/useDarkMode';
import { useHandleErrors } from '../../hooks/useHandleErrors';
import { useEffect } from 'react';
import axios from 'axios';
import { IUsers } from "../../types/Types"
import { useState , State } from '@hookstate/core';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import moment from 'moment';
import { BugReportModal } from '../../components/modal/BugReportModal';

export const Home = () =>{
    document.title = "Home - MeliBeat";
    const {DarkMode, } = useDarkMode();
    const hour = parseInt(moment().format('HH'));
    const GREETING = hour >= 6 && hour < 12 ? "Bom Dia" : hour >= 12 && hour < 18 ? "Boa Tarde" : hour > 18 && hour <= 24 ? "Boa Noite" : "Boa Madrugada";

    const handleValidateStatus = useHandleErrors();
    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const fetchResource = (query:any=undefined) => axs.get<IUsers[]>('/auth/operators', {params: query}).then((r: any) => {
        const userLoggedEmail = localStorage.getItem('userLogged');
        const idx = r.data.findIndex((item: any)=> item.email === userLoggedEmail)
        return idx !== -1 ? r.data[idx].name : '';
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return ''
    })

    const userName: State<string> = useState<string>('');


    useEffect(() => {
        userName.set(fetchResource())
    }, [])

    if(userName.promised){ //Enquanto for promisse ou estiver carregando
        return (
            <>
                <div className={`wrapper`}> 
                    <div className="content-wrapper">
                        <main className="main-content container-fluid">
                            <div className="loading">
                                <AiOutlineLoading3Quarters className="loading-icon" />
                            </div>
                        </main>
                    </div>
                </div>
            </>
        )
    }

    if (userName.error) { // Quando o promise dar reject
        userName.set('');
    }

    return(
        <div className="wrapper-landing-page">
            <BugReportModal />
            <div className="content-wrapper-landing-page">
                <main className={`main-content-landing-page container-fluid ${DarkMode ? 'dark-mode-home' : ''}`}>
                    <section className="main-section-landing">
                        <div className="section-landing-text">
                            <h1>MeliBeat</h1>
                            {
                                userName.get() !== '' && userName.get() !== undefined ?
                                <h3>
                                    <span style={{textTransform: 'capitalize'}}>{`${GREETING}, ${userName.get()}.`}</span>
                                </h3>
                                :
                                null
                            }
                            <p>Gerencie seu marketplace de maneira rápida e fácil.</p>
                        </div>

                        <div className="section-landing-image">
                            <div className="img-container">
                                <img src={hero2} alt="MeLiBeat hero" />
                            </div>
                        </div>
                    </section>
                </main>
                
            </div>
        </div>
    )
}