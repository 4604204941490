import { createContext, useState, ReactNode } from 'react';

interface IReportType{
    "page": string,
    "module": string | null,
    "date": string,
    "userEmail": string | null,
    "path": string
}
interface BugReport{
    reportData: IReportType,
    active: boolean,
}

type BugReportContextType = {
    BugReport: BugReport;
    activateBugReport: (BugReport: BugReport) => void;

}

type BugReportContextProps = {
    children: ReactNode
}

export const BugReportContext = createContext({} as BugReportContextType);

export function BugReportContextProvider(props: BugReportContextProps) {
    const [BugReport, setBugReport] = useState<BugReport>(
        {
            reportData: {
                "page": '',
                "module": '',
                "date": '',
                "userEmail": '',
                "path": ''
            },
            active: false,
        }
    );

    function activateBugReport(bugReport: BugReport){
        setBugReport(bugReport);
    }

    return (
        <BugReportContext.Provider value={{ BugReport, activateBugReport }}>
            {props.children}
        </BugReportContext.Provider>
    )
}