import moment from 'moment';
import {BsAwardFill, BsFillTagFill, BsLightningFill } from 'react-icons/bs';
import {FiExternalLink} from 'react-icons/fi';
import { FaMoneyBill } from 'react-icons/fa';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { 
  GridColDef, GridFilterItem,
} from '@mui/x-data-grid';
import Link from '@mui/material/Link';

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const defaultTheme  = createTheme();



export const options = {
  palette:{
    tableHeader: {
      main: '#F7CE3E',
      dark: '#ac902b',
      light: '#f8d764',
      contrastText: '#000'
    },
  },
  rowStyle: (rowData: any) => {
    const margin = rowData.profit_margin*100;
    const status = rowData.status;
  
    let cost_product = rowData.cost_product;
    cost_product = cost_product.toFixed(2).replace('.', ',');
    let selled_price = rowData.selled_price;
    selled_price = selled_price.toFixed(2).replace('.', ',');
    let shipping_fee = rowData.shipping_fee;
    shipping_fee = shipping_fee.toFixed(2).replace('.', ',');
    const pack_id = rowData.pack_id;  
    let row_class = '';
    if(margin < 0) row_class = "lcreColors-negative-margin";
    if(margin >= 0 && margin < 5) row_class = "lcreColors-bad-margin";
    if(margin >= 5 && margin < 10) row_class = "lcreColors-margin-average";
    if(margin > 10 && margin < 20) row_class = "lcreColors-good-margin";
    if(margin >= 20) row_class = "lcreColors-high-margin";
    if(status === "cancelled") row_class = "lcreColors-cancelled-sale";
  
    if(selled_price < 79 && shipping_fee > 0 && pack_id == null) row_class = "lcreColors-wrong-shipping-tax";
    if(cost_product === 0 || margin > 100) row_class = "lcreColors-cost-margin";
  
    return row_class
  },
}

const StyledPopper = styled(Popper)({
  "& .MuiAutocomplete-listbox": {
    '& li':{
      fontSize: 'clamp(1.1rem, 1vw, 1.3rem)',
    }
  }
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function CheckboxesTags(props: any) {
  const { item, applyValue, options:tag_options } = props;
  // const selected_values = item.value ? item.value.map((elm: any)=> elm.title) : [];

  const handleFilterChange = (event: any, value: any) => {
    applyValue({ ...item, value: value });
  };

  return (
    <Autocomplete
      multiple
      options={tag_options}
      disableCloseOnSelect
      getOptionLabel={(option: any) => option.title}
      defaultValue={item.value}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
            style={{ marginRight: 8 }}
            // checked={selected_values.includes(option.title)}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label="Tags" variant="standard" sx={{'& .MuiChip-root':{fontSize: 'clamp(1rem, 1.1vw, 1.2rem)'}}} />
      )}
      //PopperComponent={StyledPopper}
      onChange={handleFilterChange}
    />
  );
}

const checkVisibility = (column: string)=>{
  const storageColumnsData = localStorage.getItem('Relatório LCRE') ? localStorage.getItem('Relatório LCRE') : null;
  if(storageColumnsData !== null){
    const localData = JSON.parse(storageColumnsData);
    const shouldHide = localData.find((item: any)=> item.field === column);
    return shouldHide !== undefined ? !shouldHide.visibility : false
  }
  return false
}

const tagColors: any = {
    "rebate": ["#f0932b", (<BsAwardFill />)],
    "deal": ["#e056fd", (<BsFillTagFill  />)],
    "logistic": ["#20bf6b", (<BsLightningFill  />)],
    "charged": ["#eb3b5a", (<FaMoneyBill  />)],
  };


  export const columns: GridColDef[] = [
  {
    headerName: "Número Venda",
    field: "id",
    type: "string",
    hide: checkVisibility('id'),
    headerAlign: 'center',
    flex: 1.3,
    minWidth: 130,
    renderCell: (rowData: any) => {
      const id = rowData.row.pack_id !== null ? rowData.row.pack_id: rowData.row.id;
      return (
        <ThemeProvider theme={defaultTheme}>
          <div title={rowData.row.id}>
            <Link 
              href={rowData.row.link} 
              target="_blank" rel="noreferrer" 
              color={'inherit'} 
              sx={{'&:hover':{color: defaultTheme.palette.primary.dark}}}
              style={{fontSize: 'clamp(1.2rem, 1vw, 1.3rem)', display: 'flex', alignItems: 'center', gap: '5px', textDecoration: 'none'}} 
            >
                <div>{id}</div><FiExternalLink />
            </Link>
          </div>
        </ThemeProvider>
      )
    }
  },
  {
    headerName: "Produto",
    field: "title",
    type: "string",
    hide: checkVisibility('title'),
    headerAlign: 'center',
    flex: 2,
    minWidth: 200,
    renderCell:(rowData: any)=>{
      return(
        <Tippy content={rowData.row.title}>
          <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            <span>{rowData.row.title}</span>
          </main>
        </Tippy>
      )
    }
  },
  {
    headerName: "Tags",
    field: "tags",
    type: 'string',
    hide: checkVisibility('tags'),
    headerAlign: 'center',
    flex: 1,
    minWidth: 50,
    sortable:false,
    filterOperators: [{
      value: 'contains',
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        const selected_values = filterItem.value ? filterItem.value.map((item: any)=> item.title) : [];
        if (filterItem.value == null || selected_values === []) {
          return null;
        }
  
        return ({ value }): boolean => {
          if (typeof value === 'object') {
            return selected_values.every((item: any)=> value.includes(item))
          }
          return selected_values.every((item: any)=> value.includes(item))
        };
      },
      // InputComponent: GridFilterInputValue,
      InputComponent: CheckboxesTags,
      InputComponentProps: { type: 'singleSelect', options: [{title: 'Promoção'}, {title: 'Rebate Campanha'}, {title: 'Fulfillment'}, {title: 'Cobrança Devolução'}]},
    }],
    valueOptions: ["Promoção", "Rebate Campanha", "Fulfillment", "Cobrança Devolução"],
    renderCell: (rowData: any) => {
      return (
          <div className="tags-container" style={{marginTop: "0", flexWrap: 'nowrap'}}>
              {
                rowData.row.tags.includes('Promoção')
                ? 
                  <Tippy content="Promoção" maxWidth={'25rem'}>
                    <span className="tag-small" style={{color: tagColors["deal"][0]}}>{tagColors["deal"][1]}</span>
                  </Tippy>
                : null
              }
              {
                rowData.row.tags.includes('Rebate Campanha')
                ? 
                  <Tippy content={`Rebate Campanha: ${rowData.row.rebate_campaign.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`} maxWidth={'25rem'}>
                    <span className="tag-small" style={{color: tagColors["rebate"][0]}}>{tagColors["rebate"][1]}</span>
                  </Tippy>
                : null
              }
              {
                rowData.row.tags.includes('Fulfillment')
                ? 
                  <Tippy content="Fulfillment" maxWidth={'25rem'}>
                    <span className="tag-small" style={{color: tagColors["logistic"][0]}}>{tagColors["logistic"][1]}</span>
                  </Tippy>
                : null
              }
              {
                rowData.row.tags.includes('Cobrança Devolução')
                ? 
                  <Tippy content="Cobrança Devolução" maxWidth={'25rem'}>
                    <span className="tag-small" style={{color: tagColors["charged"][0]}}>{tagColors["charged"][1]}</span>
                  </Tippy>
                : null
              }
          </div>
      )
    }
  },
  {
    headerName: "Origem",
    field: "origin",
    type: "string",
    hide: checkVisibility('origin'),
    headerAlign: 'center',
    flex: 1,
    minWidth: 50,
  },
  {
    headerName: "Status",
    field: "status",
    type: 'string',
    hide: checkVisibility('status'),
    headerAlign: 'center',
    flex: 1,
    minWidth: 50,
    renderCell: (rowData: any) => {
      let status = rowData.row.status;
      const statusTranslations:any = {"paid": "Pago", "cancelled": "Cancelado"}
      // status = status.slice(0,4);
      return (
        <>
          {statusTranslations[status]}
        </>
      )
    }
  },
  { 
    headerName: "SKU", 
    field: "sku", 
    type: 'string',
    hide: checkVisibility('sku'),
    headerAlign: 'center',
    flex: 1,
    minWidth: 70,
  },
  {
    headerName: "Data Venda",
    field: "date_approved",
    type: 'string',
    hide: checkVisibility('date_approved'),
    headerAlign: 'center',
    flex: 1,
    minWidth: 100,
    renderCell: (rowData: any) => {
      if(rowData.row.status === "cancelled"){
        return(
          <p title={`Cancelada: ${moment(rowData.row.date_cancelled).format("DD/MM/YYYY")}`} style={{textDecoration: "line-through", fontSize:"inherit", marginBottom: 0}}>
            {moment(rowData.row.date_approved).format("DD/MM/yyyy")}
          </p>
        )
      }
      else{
        return(
          <p style={{fontSize: "inherit", marginBottom: 0}}>{moment(rowData.row.date_approved).format("DD/MM/yyyy")}</p>
        )
      }
    }
  },
  {
    headerName:"Custo Produto",
    field: "cost_product",
    type: 'number',
    hide: checkVisibility('cost_product'),
    headerAlign: 'center',
    flex: 1,
    minWidth: 100,
    editable: true,
    renderCell: (rowData: any) => {
      return (
        <span style={{textDecoration: 'underline dotted'}}>
          {rowData.row.cost_product.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
        </span>
      )
    }
  },
  {
    headerName:"Custo Frete", 
    field: "shipping_fee",
    type: 'number',
    hide: checkVisibility('shipping_fee'),
    headerAlign: 'center',
    flex: 1,
    minWidth: 100,
    renderCell: (rowData: any) => {
      return (
        <>
          {rowData.row.shipping_fee.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
        </>
      )
    }
  },
  {
    headerName:"Custo ML",
    field: "sale_fee",
    type: 'number',
    hide: checkVisibility('sale_fee'),
    headerAlign: 'center',
    flex: 1,
    minWidth: 100,
    renderCell: (rowData: any) => {
      return (
        <>
          {rowData.row.sale_fee.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
        </>
      )
    }
  },
  {
    headerName:"Valor Impostos",
    field: "cost_tax",
    type: 'number',
    hide: checkVisibility('cost_tax'),
    headerAlign: 'center',
    flex: 1,
    minWidth: 100,
    renderCell: (rowData: any) => {
      return (
        <>
          {rowData.row.cost_tax.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
        </>
      )
    }
  },
  {
    headerName:"Valor Venda",
    field: "selled_price",
    type: 'number',
    hide: checkVisibility('selled_price'),
    headerAlign: 'center',
    flex: 1,
    minWidth: 100,
    renderCell: (rowData: any) => {
      return (
        <>
          {rowData.row.selled_price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
        </>
      )
    }
  },
  
  {
    headerName:"Lucro Bruto",
    field: "gross_profit",
    type: 'number',
    hide: checkVisibility('gross_profit'),
    headerAlign: 'center',
    flex: 1,
    minWidth: 100,
    renderCell: (rowData: any) => {
      return (
        <>
          {rowData.row.gross_profit.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
        </>
        )
    }
  },
  {
    headerName:"Lucro Liquido",
    field: "profit",
    type: 'number',
    hide: checkVisibility('profit'),
    headerAlign: 'center',
    flex: 1,
    minWidth: 100,
    renderCell: (rowData: any) => {
      return (
        <>
          {rowData.row.profit.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
        </>
      )
    }
  },
  {
    headerName:"Margem",
    field: "profit_margin",
    type: 'number',
    hide: checkVisibility('profit_margin'),
    headerAlign: 'center',
    flex: 1,
    minWidth: 100,
    renderCell: (rowData: any) => {
      return (
        <>
          {(rowData.row.profit_margin*100).toFixed(2)}%
        </>
      )
    }
  }
  
]

