import XLSX from 'xlsx';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable';

export const DownloadXLS = (title: string, data: any)=>{
    if(title === undefined){
        title = "Arquivo sem título"
    }
    const workSheet=XLSX.utils.json_to_sheet(data)
    const workBook=XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workBook,workSheet, title)
    //Buffer
    // let buf=XLSX.write(workBook,{bookType:"xlsx",type:"buffer"})
    //Binary string
    XLSX.write(workBook,{bookType:"xlsx",type:"binary"})
    //Download
    XLSX.writeFile(workBook, `${title}.xlsx`)
}

export const DownloadPDF = (title: string, data: any, columns: any) => {
    // alert("Essa função ainda não está funcionando.")
    const doc = new jsPDF();
    autoTable(doc, {
       head: [columns],
       body: data,
       theme: "grid",
       headStyles: {fillColor: "#15171c", textColor: "#F7CE3E", fontSize: 10},
       bodyStyles: {fontSize: 8}
    });
  
    doc.save(`${title}.pdf`);
  }