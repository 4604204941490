import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import { Loading } from '../../../components/Loading/Loading';
import { LineChart } from '../../../components/Charts/LineChart';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { toast } from 'react-toastify';
import './style.scss';
import moment from 'moment';
import { ContentAccordion } from '../../../components/accordion/ContentAccordion';
import gold_medal from '../../../assets/images/gold_medal.png';
import silver_medal from '../../../assets/images/silver_medal.png';
import platinum_medal from '../../../assets/images/platinum_medal.png';
import {FaArrowUp, FaArrowDown, FaRegQuestionCircle, FaMedal} from 'react-icons/fa';
import {MdChatBubble, MdRemoveShoppingCart, MdTimer, MdArrowForward, MdArrowBack, MdGrade, MdAttachMoney, MdShoppingCart, MdQuestionAnswer} from 'react-icons/md'
import DatePicker, {Locale, Day} from 'react-modern-calendar-datepicker';
import placeholder from '../../../assets/images/placeholder.jpg';
import { useDarkMode } from '../../../hooks/useDarkMode';
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { Untracked } from '@hookstate/untracked';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import { Filters } from '../../../components/Filters/Filters';

import { 
    IAdsMetrics, 
    Medals, 
    CategoriesFilterAdsMetrics, 
    ReputationData, 
    ShippingTime,
    FilterTimeAdsMetrics, 
    MinMaxDaysAdsMetrics, 
    ChartDates,
    VisitsChartData,
    AverageTicketData,
    ChartVisitsAdsMetrics,
    ChartAdsMetrics,
} from "../../../types/Types";
import { BugReportModal } from '../../../components/modal/BugReportModal';

export const AdsMetrics = () =>{
    document.title = "Métricas Anúncios - MeLiBeat";
    const handleValidateStatus = useHandleErrors();

    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const fetchResource = (query:any=undefined) => axs.get<IAdsMetrics[]>('/orders/metrics', {params: query}).then((r: any) => {
        handleValidateStatus(r)
        if(r.data){
            if(r.data.chartVisits){
                handleChartVisitsGeneration(r.data.chartVisits, r.data.previousChartVisits)
            }
            else{
                toast.info('Infelizmente não foi possível obter os dados para a aba de Visitas.');
            }
            handleChartGeneration(r.data.chart, r.data.previousChart)

            categoriesFilter.set(r.data.categoriesFilter)
            categoriesFilterOptions.set(Object.values(r.data.categoriesFilter));

            const previousDateFrom = moment(r.data.dates.previousDateFrom).format("DD-MM-YYYY");
            const previousDateTo = moment(r.data.dates.previousDateTo).format("DD-MM-YYYY");
            chartDates.set({previousDateFrom, previousDateTo});

            reputationData.set({...r.data.questionsTime, ...r.data.rank, ...r.data.reputation, "shippingTime": r.data.shippingTime})
        }
        return r.data.products
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    })

    const data: State<IAdsMetrics[]> = useState<IAdsMetrics[]>([]);

    const medals:Medals = {
        "silver": ["Mercado Líder", silver_medal],
        "gold": ["Mercado Líder Gold", gold_medal],
        "platinum": ["Mercado Líder Platinum", platinum_medal],
    }

    const weekdays:object = {
        "sunday": "Domingo",
        "monday": "Segunda-Feira",
        "tuesday": "Terça-Feira",
        "wednesday": "Quarta-Feira",
        "thursday": "Quinta-Feira",
        "friday": "Sexta-Feira",
        "saturday": "Sábado"
    }

    const Footer = ()=> {
        return(
            <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem', padding: "1rem 0" }}>
                <button
                    type="button"
                    onClick={() => {
                        const to = {
                            year: moment().year(),
                            month: moment().month() + 1,
                            day: moment().date() - 1,
                        }
                        const from = {
                            year:  moment().subtract(7, 'days').year(),
                            month:  moment().subtract(7, 'days').month() + 1,
                            day: moment().subtract(7, 'days').date() - 1,
                        }

                        startingFilterValues.set({"from": from, "to": to})
                        if(disableFilterBtn.get() === true){
                            disableFilterBtn.set(false)
                        }

                        // data.set(fetchResource({"dateFrom": from, "dateTo": to}))

                    }}
                    style={{
                        border: '#0fbcf9',
                        color: '#fff',
                        borderRadius: '0.5rem',
                        padding: '1rem 1rem',
                        backgroundColor: "#3498DB"
                    }}
                >
                    7 dias
                </button>
                    
                <button
                    type="button"
                    onClick={() => {
                        const to = {
                            year: moment().year(),
                            month: moment().month() + 1,
                            day: moment().date() - 1,
                        }
                        const from = {
                            year:  moment().subtract(15, 'days').year(),
                            month:  moment().subtract(15, 'days').month() + 1,
                            day: moment().subtract(15, 'days').date() - 1,
                        }

                        startingFilterValues.set({"from": from, "to": to})
                        if(disableFilterBtn.get() === true){
                            disableFilterBtn.set(false)
                        }
                        // data.set(fetchResource({"dateFrom": from, "dateTo": to}))

                    }}
                    style={{
                        border: '#0fbcf9',
                        color: '#fff',
                        borderRadius: '0.5rem',
                        padding: '1rem 1rem',
                        backgroundColor: "#3498DB"
                    }}
                >
                    15 dias
                </button>
            
                <button
                    type="button"
                    onClick={() => {
                        const to = {
                            year: moment().year(),
                            month: moment().month() + 1,
                            day: moment().date() - 1,
                        }
                        const from = {
                            year:  moment().subtract(30, 'days').year(),
                            month:  moment().subtract(30, 'days').month() + 1,
                            day: moment().subtract(30, 'days').date() - 1,
                        }

                        startingFilterValues.set({"from": from, "to": to})
                        if(disableFilterBtn.get() === true){
                            disableFilterBtn.set(false)
                        }
                        // data.set(fetchResource({"dateFrom": from, "dateTo": to}))

                    }}
                    style={{
                        border: '#0fbcf9',
                        color: '#fff',
                        borderRadius: '0.5rem',
                        padding: '1rem 1rem',
                        backgroundColor: "#3498DB"
                    }}
                >
                    30 dias
                </button>
            </div>
        )
    } 


    const categoriesFilter:State<CategoriesFilterAdsMetrics | undefined> = useState<CategoriesFilterAdsMetrics | undefined>(undefined);
    categoriesFilter.attach(Untracked)
    const categoriesFilterOptions: State<any> = useState<any>(undefined);
    categoriesFilterOptions.attach(Untracked)
    
    const disableFilterBtn: State<boolean> = useState<boolean>(true);
    const reputationData: State<ReputationData> = useState<ReputationData>({
        "level_id": '',
        "lvl_up":{
            "gross_sales": null,
            "medal": null,
            "registerTime": null,
            "reputation": null,
            "sells": null
        },
        "medal": null,
        "metrics": {
            "sales": {
                "period": '',
                "completed": 0
            },
            "claims": {
                "period": '',
                "rate": 0,
                "value": 0,
                "impact":0,
            },
            "delayed_handling_time": {
                "period": '',
                "rate": 0,
                "value": 0,
                "impact": 0,
            },
            "cancellations": {
                "period": '',
                "rate": 0,
                "value": 0,
                "impact": 0,
            }
        },
        "power_seller_status": null,
        "shippingTime": {
            "monday": {
                "work": false,
                "detail": [{
                    "from": '',
                    "to": '',
                    "cutoff": '',
                    "carrier": {
                        "id": '',
                        "name": ''
                    },
                    "vehicle": {
                        "id": '',
                        "license_plate": '',
                        "vehicle_type": '',
                        "only_for_today": false,
                        "new_driver": false
                    },
                    "driver": {
                        "id": '',
                        "name": ''
                    },
                    "sla": ''
                }]
            },
            "tuesday": {
                "work": false,
                "detail": [{
                    "from": '',
                    "to": '',
                    "cutoff": '',
                    "carrier": {
                        "id": '',
                        "name": ''
                    },
                    "vehicle": {
                        "id": '',
                        "license_plate": '',
                        "vehicle_type": '',
                        "only_for_today": false,
                        "new_driver": false
                    },
                    "driver": {
                        "id": '',
                        "name": ''
                    },
                    "sla": ''
                }]
            },
            "wednesday": {
                "work": false,
                "detail": [{
                    "from": '',
                    "to": '',
                    "cutoff": '',
                    "carrier": {
                        "id": '',
                        "name": ''
                    },
                    "vehicle": {
                        "id": '',
                        "license_plate": '',
                        "vehicle_type": '',
                        "only_for_today": false,
                        "new_driver": false
                    },
                    "driver": {
                        "id": '',
                        "name": ''
                    },
                    "sla": ''
                }]
            },
            "thursday": {
                "work": false,
                "detail": [{
                    "from": '',
                    "to": '',
                    "cutoff": '',
                    "carrier": {
                        "id": '',
                        "name": ''
                    },
                    "vehicle": {
                        "id": '',
                        "license_plate": '',
                        "vehicle_type": '',
                        "only_for_today": false,
                        "new_driver": false
                    },
                    "driver": {
                        "id": '',
                        "name": ''
                    },
                    "sla": ''
                }]
            },
            "friday": {
                "work": false,
                "detail": [{
                    "from": '',
                    "to": '',
                    "cutoff": '',
                    "carrier": {
                        "id": '',
                        "name": ''
                    },
                    "vehicle": {
                        "id": '',
                        "license_plate": '',
                        "vehicle_type": '',
                        "only_for_today": false,
                        "new_driver": false
                    },
                    "driver": {
                        "id": '',
                        "name": ''
                    },
                    "sla": ''
                }]
            },
            "saturday": {
                "work": false,
                "detail": [{
                    "from": '',
                    "to": '',
                    "cutoff": '',
                    "carrier": {
                        "id": '',
                        "name": ''
                    },
                    "vehicle": {
                        "id": '',
                        "license_plate": '',
                        "vehicle_type": '',
                        "only_for_today": false,
                        "new_driver": false
                    },
                    "driver": {
                        "id": '',
                        "name": ''
                    },
                    "sla": ''
                }]
            },
            "sunday": {
                "work": false,
                "detail": [{
                    "from": '',
                    "to": '',
                    "cutoff": '',
                    "carrier": {
                        "id": '',
                        "name": ''
                    },
                    "vehicle": {
                        "id": '',
                        "license_plate": '',
                        "vehicle_type": '',
                        "only_for_today": false,
                        "new_driver": false
                    },
                    "driver": {
                        "id": '',
                        "name": ''
                    },
                    "sla": ''
                }]
            },
        },
        "total": {
            "response_time": 0
        },
        "transactions": {
            "canceled": 0,
            "completed": 0,
            "period": '',
            "ratings": {
                "negative": 0,
                "neutral": 0,
                "positive": 0
            },
            "total": 0
        },
        "user_id": '',
        "weekdays_extra_hours": {
            "response_time": 0,
            "sales_percent_increase": 0,
        },
        "weekdays_working_hours": {
            "response_time": 0,
            "sales_percent_increase": 0,
        },
        "weekend": {
            "response_time": 0,
            "sales_percent_increase": 0,
        },
    })
    const startingFilterValues: State<FilterTimeAdsMetrics> = useState<FilterTimeAdsMetrics>({
        "to":{
            year: moment().year(),
            month: moment().month(),
            day: moment().date() - 1,
        },
        "from":{
            year: moment().subtract(30, 'days').year(),
            month: moment().subtract(30, 'days').month(),
            day: moment().subtract(30, 'days').date() - 1,
        }
    })
    const myCustomLocale: Locale = {
        // months list by order
        months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
        ],
      
        // week days by order
        weekDays: [
          {
            name: 'Domingo', // used for accessibility 
            short: 'D', // displayed at the top of days' rows
            isWeekend: true, // is it a formal weekend or not?
          },
          {
            name: 'Segunda-Feira',
            short: 'S',
          },
          {
            name: 'Terça-Feira',
            short: 'T',
          },
          {
            name: 'Quarta-Feira',
            short: 'Q',
          },
          {
            name: 'Quinta-Feira',
            short: 'Q',
          },
          {
            name: 'Sexta-Feira',
            short: 'S',
          },
          {
            name: 'Sábado',
            short: 'S',
            isWeekend: true,
          },
        ],
      
        // just play around with this number between 0 and 6
        weekStartingIndex: 0,
      
        // return a { year: number, month: number, day: number } object
        getToday(gregorainTodayObject: any) {
          return gregorainTodayObject;
        },
      
        // return a native JavaScript date here
        toNativeDate(date: any) {
          return new Date(date.year, date.month - 1, date.day);
        },
      
        // return a number for date's month length
        getMonthLength(date:any) {
          return new Date(date.year, date.month, 0).getDate();
        },
      
        // return a transformed digit to your locale
        transformDigit(digit:any) {
          return digit;
        },
      
        // texts in the date picker
        nextMonth: 'Próximo Mês',
        previousMonth: 'Mês Anterior',
        openMonthSelector: 'Abrir Seletor de Meses',
        openYearSelector: 'Abrir Seletor de Anos',
        closeMonthSelector: 'Fechar Seletor de Meses',
        closeYearSelector: 'Fechar Seletor deAnos',
        defaultPlaceholder: 'Selecionar Período...',
      
        // for input range value
        from: '',
        to: '---',
      
      
        // used for input value when multi dates are selected
        digitSeparator: ',',
      
        // if your provide -2 for example, year will be 2 digited
        yearLetterSkip: 0,
      
        // is your language rtl or ltr?
        isRtl: false,
      }

    const minMaxDays: State<MinMaxDaysAdsMetrics> = useState<MinMaxDaysAdsMetrics>({
        maximumDate: {
            year: moment().year(),
            month: moment().month() + 1,
            day: moment().date() - 1,
        },
        minimumDate: {
            year: moment().year() - 1,
            month: moment().month() + 1,
            day: moment().date() - 1,
        }
    })

    //CHART DATA
    const chartDates: State<ChartDates> = useState<ChartDates>({
        "previousDateFrom": '',
        "previousDateTo": '',
    })
    chartDates.attach(Untracked)
    const visitsChartData: State<VisitsChartData> = useState<VisitsChartData>({dataset: {}, label: [], tabValue: 0, tabTag: 0, isReady: false, isHigh: false, previousValue: 0});
    const chartLabel: State<string[][]> = useState<string[][]>([])
    const chartGrossSalesData: State<VisitsChartData> = useState<VisitsChartData>({dataset: {}, tabValue: 0, tabTag: 0, isReady: false, isHigh: false, previousValue: 0});
    const chartSellItemsData: State<VisitsChartData> = useState<VisitsChartData>({dataset: {}, tabValue: 0, tabTag: 0, isReady: false, isHigh: false, previousValue: 0});
    const chartSellsData: State<VisitsChartData> = useState<VisitsChartData>({dataset: {}, tabValue: 0, tabTag: 0, isReady: false, isHigh: false, previousValue: 0});
    const averageTicketData: State<AverageTicketData> = useState<AverageTicketData>({tabValue:0, tabTag: 0, isReady: false, isHigh: false, previousValue: 0});
    const chartSize: State<number> = useState<number>(70)
    const tension: number = 0.5;
    let ChartColors: string[] = ["#0abde3", "#b71540", "#1dd1a1", "#1e3799", "#f368e0", "#0c2461", "#e55039", "#222f3e"];
    let ChartColorsAlpha: string[] = ["#0abde30f", "#b715400f", "#1dd1a10f", "#1e37990f", "#f368e00f", "#0c24610f", "#e550390f", "#222f3e0f"];
    const {DarkMode, } = useDarkMode();

    const columns = [
        { 
            headerName: "SKU", 
            field: "sku", 
            type: 'string',
            headerAlign: 'center',
            flex: 0.5,
            renderCell: (rowData: any) => {
                return (
                    <strong title={rowData.row.sku}>{rowData.row.sku}</strong>
                )
            }
        },
        { 
            headerName: "Categoria", 
            field: "category_name", 
            type: 'singleSelect',
            headerAlign: 'center',
            flex: 0.5,
            valueOptions: categoriesFilterOptions.get(),
            renderCell: (rowData: any) => {
                const category_name = rowData.row.category_name ? rowData.row.category_name : "N/A";
                return (
                    <Tippy content={category_name}>
                        <main style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
                            <strong title={category_name}>{category_name}</strong>
                        </main>
                    </Tippy>
                )
            }
        },
        { 
            headerName: "MLB", 
            field: "mlb_id", 
            type: 'string',
            headerAlign: 'center',
            flex: 1,
            renderCell: (rowData: any) => {
                return (
                    <strong title={rowData.row.mlb_id}>{rowData.row.mlb_id}</strong>
                )
            }
        },
        {
            headerName: "Imagem",
            type: 'string',
            field: "photo",
            headerAlign: 'center',
            flex: 0.5,
            renderCell: (rowData: any) =>{
              return(
                <div className="img" style={{width: 30, height: 30, borderRadius: '50%', display: 'flex'}}>
                    <img src={rowData.row.photo !== "" ? rowData.row.photo : placeholder} alt="Imagem do produto" style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 'inherit'}}/>
                </div>
              )
            }
        },
        {
          headerName: "Produto",
          field: "title",
          type: "string",
          headerAlign: 'center',
          flex: 2,
          renderCell: (rowData: any) => {
            return (
                <Tippy content={rowData.row.title}>
                    <main style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
                        <strong title={rowData.row.title}>{rowData.row.title}</strong>
                    </main>
                </Tippy>
            )
          }
        },
        { 
            headerName: "Variação", 
            field: "variation_title", 
            type: 'string',
            headerAlign: 'center',
            flex: 0.5,
            renderCell: (rowData: any) => {
                return (
                    <Tippy content={rowData.row.variation_title}>
                        <main style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
                            <strong title={rowData.row.variation_title}>{rowData.row.variation_title ? rowData.row.variation_title : "N/A"}</strong>
                        </main>
                    </Tippy>
                )
            }
        },
        { 
            headerName: "Faturamento Bruto (% de participação)",
            field: "gross_sales", 
            type: 'number',
            headerAlign: 'center',
            flex: 1,
            renderCell: (rowData: any) => {
                const gross_sales = (rowData.row.gross_sales ? rowData.row.gross_sales : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
                const gross_sales_percentage = rowData.row.gross_sales ? (rowData.row.gross_sales * 100)/chartGrossSalesData.tabValue.value : 0;
                const final_value = `${gross_sales} (${gross_sales_percentage.toFixed(2)}%)`;
                const previousGrossSales = (rowData.row.previousGrossSales ? rowData.row.previousGrossSales : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
                const growthRateGrossSales = rowData.row.growthRateGrossSales;

                return (
                    <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center"}}>
                        <Tippy 
                            content={
                                <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", padding: ".5rem 0"}}>
                                    <p style={{opacity: ".9", fontSize: "clamp(1.1rem, 1vw, 1.2rem)", color: "#fff", padding: 0, margin: 0}}>Comparado com: {chartDates.previousDateFrom.value} a  {chartDates.previousDateTo.value}</p>

                                    <strong style={{fontSize: 'clamp(1.2rem, 1.4vw, 1.5rem)'}}>{previousGrossSales}</strong>
                                </div>

                            } 
                            maxWidth={'30rem'}
                        >   
                            <main style={{display: "flex", alignItems: "flex-start", justifyContent: "center", gap: '0.5rem'}}>
                                <strong style={{fontSize: "clamp(1.2rem, 1.4vw, 1.5rem)"}} title={final_value}>{final_value}</strong>
                                <span className="tab-tag up" style={{display: "flex", alignItems: "center", justifyContent: "center", gap: ".5rem", fontSize: "clamp(1.1rem, 1vw, 1.2rem)", fontWeight: 'bold'}}>
                                    {
                                        growthRateGrossSales === 0 ?
                                        <>=</>
                                        :
                                        growthRateGrossSales > 0 ?
                                        <FaArrowUp style={{width: "1rem", height: "1rem", color: "#219953"}} />
                                        :
                                        <FaArrowDown style={{width: "1rem", height: "1rem", color: "#C0392B"}} />

                                    }
                                    {(growthRateGrossSales * 100).toFixed(2)}%
                                </span>
                            </main>
                        </Tippy>
                    </div>
                )
            }
        },
        { 
            headerName: "Vendas (% de participação)", 
            field: "sell_items", 
            type: 'number',
            headerAlign: 'center',
            flex: 1,
            renderCell: (rowData: any) => {
                const sell_items = rowData.row.sell_items ? rowData.row.sell_items : 0;
                const sell_items_percentage = rowData.row.sell_items ? (rowData.row.sell_items * 100)/chartSellItemsData.tabValue.value : 0;
                const final_value = `${sell_items} (${sell_items_percentage.toFixed(2)}%)`;
                const previousSellItems = rowData.row.previousSellItems;
                const growthRateSellItems = rowData.row.growthRateSellItems;

                return (
                    <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center"}}>
                        <Tippy 
                            content={
                                <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", padding: ".5rem 0"}}>
                                    <p style={{opacity: ".9", fontSize: "clamp(1.1rem, 1vw, 1.2rem)", color: "#fff", padding: 0, margin: 0}}>Comparado com: {chartDates.previousDateFrom.value} a  {chartDates.previousDateTo.value}</p>

                                    <strong style={{fontSize: 'clamp(1.2rem, 1.4vw, 1.5rem)'}}>{previousSellItems}</strong>
                                </div>

                            } 
                            maxWidth={'30rem'}
                        >   
                            <main style={{display: "flex", alignItems: "flex-start", justifyContent: "center", gap: '0.5rem'}}>
                                <strong style={{fontSize: "clamp(1.2rem, 1.4vw, 1.5rem)"}} title={final_value}>{final_value}</strong>
                                <span className="tab-tag up" style={{display: "flex", alignItems: "center", justifyContent: "center", gap: ".5rem", fontSize: "clamp(1.1rem, 1vw, 1.2rem)", fontWeight: 'bold'}}>
                                    {
                                        growthRateSellItems === 0 ?
                                        <>=</>
                                        :
                                        growthRateSellItems > 0 ?
                                        <FaArrowUp style={{width: "1rem", height: "1rem", color: "#219953"}} />
                                        :
                                        <FaArrowDown style={{width: "1rem", height: "1rem", color: "#C0392B"}} />

                                    }
                                    {` ${(growthRateSellItems * 100).toFixed(2)}%`}
                                </span>
                            </main>
                        </Tippy>
                </div>
                )
            }
        },
    ]


    const options = {
        palette:{
          tableHeader: {
            main: '#F7CE3E',
            dark: '#ac902b',
            light: '#f8d764',
            contrastText: '#000'
          },
        },
    }

    useEffect(() => {
        data.set(fetchResource())
    }, [])

    const handleChartGeneration = (chart:ChartAdsMetrics, previousChart: ChartAdsMetrics)=>{
        const processedChartGrossSales:object[] = []
        const processedChartSells:object[] = []
        const processedChartSellItems:object[] = []

        const grossSalesValue: number = chart.result.gross_sales ? chart.result.gross_sales : 0;
        const grossSalesValuePrevious: number = previousChart.result.gross_sales ? previousChart.result.gross_sales : 0;

        const sellItemsValue: number = chart.result.sell_items ? chart.result.sell_items : 0;
        const sellItemsValuePrevious: number = previousChart.result.sell_items ? previousChart.result.sell_items : 0;

        const sellsValue: number = chart.result.sells ? chart.result.sells : 0;
        const sellsValuePrevious: number = previousChart.result.sells ? previousChart.result.sells : 0;

        const averageTicketValue: number = chart.result.average_ticket ? chart.result.average_ticket : 0;
        const averageTicketValuePrevious: number = previousChart.result.average_ticket ? previousChart.result.average_ticket : 0;

        let grossSalesTag: number = ((grossSalesValue - grossSalesValuePrevious) / grossSalesValuePrevious) * 100;
        
        let sellItemsTag: number = ((sellItemsValue - sellItemsValuePrevious) / sellItemsValuePrevious) * 100;
        let sellsTag: number = ((sellsValue - sellsValuePrevious) / sellsValuePrevious) * 100;
        let averageTicketTag: number = ((averageTicketValue - averageTicketValuePrevious) / averageTicketValuePrevious) * 100;

        if(grossSalesTag === null || grossSalesTag === undefined || isNaN(grossSalesTag)){
            grossSalesTag = 0;
        }
        if(sellItemsTag === null || sellItemsTag === undefined || isNaN(sellItemsTag)){
            sellItemsTag = 0;
        }
        if(sellsTag === null || sellsTag === undefined || isNaN(sellsTag)){
            sellsTag = 0;
        }
        if(averageTicketTag === null || averageTicketTag === undefined || isNaN(averageTicketTag)){
            averageTicketTag = 0;
        }

        processedChartGrossSales.push(
            {
                label: ["Faturamento Bruto"], 
                data: chart.gross_sales, 
                borderColor: ChartColors[0], 
                fill: {
                    target: 'origin',
                    above: ChartColorsAlpha[0],   // Area will be red above the origin
                    below: 'transparent'    // And blue below the origin
                },
                backgroundColor: ChartColors[0], hidden: false, tension: tension
            },
            {
                label: ["Faturamento Bruto Anterior"], 
                data: previousChart.gross_sales, 
                borderColor: ChartColors[1], 
                fill: {
                    target: 'origin',
                    above: ChartColorsAlpha[1],   // Area will be red above the origin
                    below: 'transparent'    // And blue below the origin
                },
                backgroundColor: ChartColors[1], hidden: false, tension: tension
            },
        )

        processedChartSellItems.push(
            {
                label: ["Unidades Vendidas"], 
                data: chart.sell_items, 
                borderColor: ChartColors[2], 
                fill: {
                    target: 'origin',
                    above: ChartColorsAlpha[2],   // Area will be red above the origin
                    below: 'transparent'    // And blue below the origin
                },
                backgroundColor: ChartColors[2], hidden: false, tension: tension
            },
            {
                label: ["Unidades Vendidas Anteriores"], 
                data: previousChart.sell_items, 
                borderColor: ChartColors[3], 
                fill: {
                    target: 'origin',
                    above: ChartColorsAlpha[3],   // Area will be red above the origin
                    below: 'transparent'    // And blue below the origin
                },
                backgroundColor: ChartColors[3], hidden: false, tension: tension
            },
        )

        processedChartSells.push(
            {
                label: ["Quantidade de Vendas"], 
                data: chart.sells, 
                borderColor: ChartColors[4], 
                fill: {
                    target: 'origin',
                    above: ChartColorsAlpha[4],   // Area will be red above the origin
                    below: 'transparent'    // And blue below the origin
                },
                backgroundColor: ChartColors[4], hidden: false, tension: tension
            },
            {
                label: ["Quantidade de Vendas Anterior"], 
                data: previousChart.sells, 
                borderColor: ChartColors[5], 
                fill: {
                    target: 'origin',
                    above: ChartColorsAlpha[5],   // Area will be red above the origin
                    below: 'transparent'    // And blue below the origin
                },
                backgroundColor: ChartColors[5], hidden: false, tension: tension
            },
        )

        const formatedDate: string[] = chart.label.map((date: any)=> moment(date).format("DD-MM-YYYY"))
        chartLabel.set([[...formatedDate]]);

        chartGrossSalesData.set({
            dataset: processedChartGrossSales, 
            tabValue: grossSalesValue, 
            tabTag: grossSalesTag, 
            isHigh: grossSalesValue > grossSalesValuePrevious,
            previousValue: grossSalesValuePrevious,
            isReady: true, 
        })

        chartSellItemsData.set({
            dataset: processedChartSellItems, 
            tabValue: sellItemsValue, 
            tabTag: sellItemsTag, 
            isHigh: sellItemsValue > sellItemsValuePrevious,
            previousValue: sellItemsValuePrevious,
            isReady: true, 
        })

        chartSellsData.set({
            dataset: processedChartSells, 
            tabValue: sellsValue, 
            tabTag: sellsTag, 
            isHigh: sellsValue > sellsValuePrevious,
            previousValue: sellsValuePrevious,
            isReady: true, 
        })

        averageTicketData.set({
            tabValue: averageTicketValue, 
            tabTag: averageTicketTag, 
            isHigh: averageTicketValue > averageTicketValuePrevious,
            previousValue: averageTicketValuePrevious,
            isReady: true, 
        });
        
    }
    const handleChartVisitsGeneration = (chart:ChartVisitsAdsMetrics, previousChart: ChartVisitsAdsMetrics)=>{
        const processedChartVisits:object[] = []

        const visitsValue: number = chart.result.total ? chart.result.total : 0;
        const visitsValuePrevious: number = previousChart.result.total ? previousChart.result.total : 0;

        let visitsTag: number = ((visitsValue - visitsValuePrevious) / visitsValuePrevious) * 100;

        if(visitsTag === null || visitsTag === undefined || isNaN(visitsTag)){
            visitsTag = 0;
        }

        processedChartVisits.push(
            {
                label: ["Visitas"], 
                data: chart.values, 
                borderColor: ChartColors[6], 
                fill: {
                    target: 'origin',
                    above: ChartColorsAlpha[6],   // Area will be red above the origin
                    below: 'transparent'    // And blue below the origin
                },
                backgroundColor: ChartColors[6], hidden: false, tension: tension
            },
            {
                label: ["Visitas Anteriores"], 
                data: previousChart.values, 
                borderColor: ChartColors[7], 
                color: '#fff',
                fill: {
                    target: 'origin',
                    above: ChartColorsAlpha[7],   // Area will be red above the origin
                    below: 'transparent'    // And blue below the origin
                },
                backgroundColor: ChartColors[7], hidden: false, tension: tension
            }
        )

        const formatedDate = chart.label.map((date: string)=> moment(date).format("DD-MM-YYYY"))

        visitsChartData.set({
            dataset: processedChartVisits, 
            label: [[...formatedDate]],
            tabValue: visitsValue, 
            tabTag: visitsTag, 
            isHigh: visitsValue > visitsValuePrevious,
            previousValue: visitsValuePrevious,
            isReady: true, 
        })

    }

    const handleTypeSellerColor = (rate: string) =>{
        let new_rate: number = parseInt(rate[0]);

        let color = null;

        if(new_rate === 5){
            color = "green";
        }
        else if(new_rate === 4){
            color = "light_green";
        }
        else if(new_rate === 3){
            color = "yellow";
        }
        else if(new_rate === 2){
            color = "orange";
        }
        else if(new_rate === 1){
            color = "red";
        }
        else{
            color = "";
        }

        return color
    }

    const handleFilter = (filters: any)=>{
        const dateFrom: Day = filters.filterDate.trim().split('---')[0];
        const dateTo: Day = filters.filterDate.trim().split('---')[1];

        const stringDateFrom: string = moment(dateFrom).format("YYYY-MM-DD");
        const stringDateTo: string = moment(dateTo).format("YYYY-MM-DD");

        toast.info('Filtrando Dados!');
        data.set(fetchResource({"dateFrom": stringDateFrom, "dateTo": stringDateTo}))
    }

    const handleReset = ()=> {
        startingFilterValues.set({
            "to":{
                year: moment().year(),
                month: moment().month(),
                day: moment().date() - 1,
            },
            "from":{
                year: moment().subtract(30, 'days').year(),
                month: moment().subtract(30, 'days').month(),
                day: moment().subtract(30, 'days').date() - 1,
            }
        })
        data.set(fetchResource())
    }


    return(
        <div className={`wrapper`}>
            <BugReportModal />
            <div className="content-wrapper">
                <main className={`main-content-metrics-tab container-fluid ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
                    <Filters submitFunction={handleFilter} hideFullResetBtn disableFilterBtn={disableFilterBtn.get()} handleReset={handleReset}>
                        <DatePicker 
                            value={startingFilterValues.attach(Downgraded).get()} 
                            inputPlaceholder="Selecionar Período: de.../até..." 
                            shouldHighlightWeekends
                            locale={myCustomLocale}
                            calendarClassName={`date-picker-filter`}
                            inputClassName="datePickerFilterInput"
                            colorPrimary="#15171c"
                            colorPrimaryLight="#F7CE3E"
                            minimumDate={minMaxDays.minimumDate.value}
                            maximumDate={minMaxDays.maximumDate.value}
                            renderFooter = {Footer}
                            inputName="filterDate"
                            onChange={(range: any)=> {
                                startingFilterValues.set({"from": range.from, "to": range.to})
                                if(range.from !== null && range.to !== null){
                                    if(moment(range.to).diff(moment(range.from), 'days') > 30){
                                        toast.info('O período deve se manter no limite de 30 dias.');
                                        disableFilterBtn.set(true)
                                        return
                                    }else{
                                        disableFilterBtn.set(false)
                                    }
                                }
                            }} 
                        />
                    </ Filters>

                    {
                        data.promised ? 
                        <Loading />
                        : data.error ?
                        <ErrorScreen /> : 
                        <>
                            <h3><strong>Métricas dos Anúncios</strong></h3>
                            {
                                reputationData.user_id.get() !== '' ?
                                <ContentAccordion title={
                                    <section className="reputation-section container-fluid">
                                        <header className="reputation-section-header">
                                            <div className="reputation-section-header-typeSeller">
                                                <strong 
                                                className={`typeSeller ${handleTypeSellerColor(reputationData.level_id.value)}`}>
                                                    <div className="image-container" style={{width: "2rem"}}>
                                                        <img src={medals[reputationData.medal.value as keyof Medals][1]} alt="Medalha MLB" />
                                                    </div>
                                                    
                                                    {medals[reputationData.medal.value as keyof Medals][0]}
                                                </strong>
        
                                                <div className="colorsList">
                                                    <span className={`red ${parseInt(reputationData.level_id.value.toString()[0]) === 1 ? 'active':''}`}></span>
                                                    <span className={`orange ${parseInt(reputationData.level_id.value.toString()[0]) === 2 ? 'active':''}`}></span>
                                                    <span className={`yellow ${parseInt(reputationData.level_id.value.toString()[0]) === 3 ? 'active':''}`}></span>
                                                    <span className={`light_green ${parseInt(reputationData.level_id.value.toString()[0]) === 4 ? 'active':''}`}></span>
                                                    <span className={`green ${parseInt(reputationData.level_id.value.toString()[0]) === 5 ? 'active':''}`}></span>
                                                </div>
                                            </div>
        
                                            <div className="reputation-section-header-sellerReputationData">
                                                <strong className="trackingPeriod">
                                                    Acompanhamos suas vendas nos últimos 60 dias
                                                    <p>(de {moment().subtract(60, 'days').format("DD-MM-YYYY")} até hoje)</p>
                                                </strong>
        
                                                <div className="sellerReputationData-item-container">
                                                    <div className={reputationData.metrics.claims.rate.value > reputationData.metrics.claims.impact.value ? "sellerReputationData-item bad" : "sellerReputationData-item good"}>
                                                        <span>Reclamações</span>
                                                        <span className="card-icon-data">
                                                            <MdChatBubble className="icon" />{(reputationData.metrics.claims.rate.value * 100).toFixed(2)}%
                                                        </span>
                                                    </div>
        
                                                    {/* <div className="sellerReputationData-item">
                                                        <MdFlag className="icon" />
                                                        <span className="sellerReputationData-item-title">{reputationData.metrics.claims.rate * 100}% - Mediações</span>
                                                    </div> */}
        
                                                    <div className={reputationData.metrics.cancellations.rate.value > reputationData.metrics.cancellations.impact.value ? "sellerReputationData-item bad" : "sellerReputationData-item good"}> 
                                                        <span>Cancelamentos</span>
                                                        <span className="card-icon-data">
                                                            <MdRemoveShoppingCart className="icon" />{(reputationData.metrics.cancellations.rate.value * 100).toFixed(2)}%
                                                        </span>
                                                    </div>
        
                                                    <div className={reputationData.metrics.delayed_handling_time.rate.value > reputationData.metrics.delayed_handling_time.impact.value ? "sellerReputationData-item bad" : "sellerReputationData-item good"}> 
                                                        <span>Despachados com atraso</span>
                                                        <span className="card-icon-data">
                                                            <MdTimer className="icon" />{(reputationData.metrics.delayed_handling_time.rate.value * 100).toFixed(2)}%
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
        
                                        </header>
                                    </section>
                                }>
        
                                    <section className="selling-data-section container-fluid">
                                        <h3>Variáveis sobre suas vendas</h3>
        
                                        <div className="selling-data-section-cards-container">
                                            <div className="selling-data-section-card">
                                                <div className="selling-data-section-card-title">
                                                    <h4>Reclamações</h4>
                                                </div>
        
                                                <hr />
        
                                                <div className="selling-data-section-card-data">
                                                    <span className="card-icon-data">
                                                        <MdChatBubble className="icon" />
                                                    </span>
        
                                                    <span className="card-icon-data">
                                                        {(reputationData.metrics.claims.rate.value * 100).toFixed(2)}%
                                                    </span>
        
                                                    <p className="card-text-data">
                                                        São {reputationData.metrics.claims.value.value} das suas vendas
                                                    </p>
        
                                                    {
                                                        reputationData.metrics.claims.rate.value <= reputationData.metrics.claims.impact.value ?
                                                        <span className="card-rule-data lowerValue">
                                                            Abaixo de {Math.round(reputationData.metrics.claims.impact.value * 100)}% permitido
                                                        </span>
                                                        :
                                                        <span className="card-rule-data higherValue">
                                                            Acima de {Math.round(reputationData.metrics.claims.impact.value * 100)}% permitido
                                                        </span>
                                                    }
                                                    
                                                </div>
                                            </div>
        
                                            <div className="selling-data-section-card">
                                                <div className="selling-data-section-card-title">
                                                    <h4>Cancelado por você</h4>
                                                </div>
        
                                                <hr />
        
                                                <div className="selling-data-section-card-data">
                                                    <span className="card-icon-data">
                                                        <MdRemoveShoppingCart className="icon" />
                                                    </span>
        
                                                    <span className="card-icon-data">
                                                        {(reputationData.metrics.cancellations.rate.value * 100).toFixed(2)}%
                                                    </span>
        
                                                    <p className="card-text-data">
                                                        São {reputationData.metrics.cancellations.value.value} das suas vendas
                                                    </p>
        
                                                    {
                                                        reputationData.metrics.cancellations.rate.value <= reputationData.metrics.cancellations.impact.value ?
                                                        <span className="card-rule-data lowerValue">
                                                            Abaixo de {Math.round(reputationData.metrics.cancellations.impact.value * 100)}% permitido
                                                        </span>
                                                        :
                                                        <span className="card-rule-data higherValue">
                                                            Acima de {Math.round(reputationData.metrics.cancellations.impact.value * 100)}% permitido
                                                        </span>
                                                    }
                                                    
                                                </div>
                                            </div>
        
                                            <div className="selling-data-section-card">
                                                <div className="selling-data-section-card-title">
                                                    <h4>Você despachou com atraso</h4>
                                                </div>
        
                                                <hr />
        
                                                <div className="selling-data-section-card-data">
                                                    <span className="card-icon-data">
                                                        <MdTimer className="icon" />
                                                    </span>
        
                                                    <span className="card-icon-data">
                                                        {(reputationData.metrics.delayed_handling_time.rate.value * 100).toFixed(2)}%
                                                    </span>
        
                                                    <p className="card-text-data">
                                                        São {reputationData.metrics.delayed_handling_time.value.value} das suas vendas
                                                    </p>
        
                                                    {
                                                        reputationData.metrics.delayed_handling_time.rate.value <= reputationData.metrics.delayed_handling_time.impact.value ?
                                                        <span className="card-rule-data lowerValue">
                                                            Abaixo de {Math.round(reputationData.metrics.delayed_handling_time.impact.value * 100)}% permitido
                                                        </span>
                                                        :
                                                        <span className="card-rule-data higherValue">
                                                            Acima de {Math.round(reputationData.metrics.delayed_handling_time.impact.value * 100)}% permitido
                                                        </span>
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </section>
        
                                    <section className="shipping-questions-time-section container-fluid">
                                        <h3>Tempo de Resposta Médio às perguntas</h3>
                                        <div className="questions-time-data">
                                            <div className="sellerReputationData-item dark"> 
                                                <span>Tempo de Resposta Médio</span>
                                                <span className="card-icon-data">
                                                    <MdQuestionAnswer className="icon" />{(reputationData.total.response_time.value/60).toFixed(1)} horas
                                                </span>
                                            </div>
        
                                            <div className="sellerReputationData-item dark"> 
                                                <span>Tempo de Resposta Médio dias de trabalho (horário comercial)</span>
                                                <span className="card-icon-data">
                                                    <MdQuestionAnswer className="icon" />{(reputationData.weekdays_working_hours.response_time.value/60).toFixed(1)} horas
                                                </span>
                                            </div>
        
                                            <div className="sellerReputationData-item dark"> 
                                                <span>Tempo de Resposta Médio dias de trabalho (horário não comercial)</span>
                                                <span className="card-icon-data">
                                                    <MdQuestionAnswer className="icon dark" />{(reputationData.weekdays_extra_hours.response_time.value/60).toFixed(1)} horas
                                                </span>
                                            </div>
        
                                            <div className="sellerReputationData-item dark"> 
                                                <span>Tempo de Resposta Médio finais de semana</span>
                                                <span className="card-icon-data">
                                                    <MdQuestionAnswer className="icon" />{(reputationData.weekend.response_time.value/60).toFixed(1)} horas
                                                </span>
                                            </div>
                                        </div>
        
                                        <h3>Tempo das Entregas</h3>
        
                                        <div className="shipping-time-data">
                                            {
                                                Object.keys(reputationData.shippingTime.value).map((item: any, index: number)=>{
                                                    return(
                                                        reputationData.shippingTime[item as keyof ShippingTime].work.value ?
                                                            <div key={index} className="sellerReputationData-item light"> 
                                                                <span>- {weekdays[item as keyof object]}</span>
                                                                <span className="card-icon-data">
                                                                    <MdArrowBack className="icon" />
                                                                    {`Vendas feitas até: ${reputationData.shippingTime[item as keyof ShippingTime].detail[0].cutoff.value} horas`}
                                                                </span>
                                                                <span className="card-icon-data">
                                                                    <MdArrowForward className="icon" />
                                                                    {`Devem ser despachadas até: ${reputationData.shippingTime[item as keyof ShippingTime].detail[0].from.value} horas`}
                                                                </span>
                                                            </div>
                                                        :
                                                        null
                                                    )
                                                })
                                            }
                                        </div>
        
                                    </section>
        
                                    <section className="rank-requisites-section container-fluid">
                                        <h3>Requisitos para avançar</h3>
                                        <div className="rank-data">
                                            {
                                                reputationData.medal.value !== "platinum" ?
                                                    <>
                                                        <div className="sellerReputationData-item"> 
                                                            <span className="card-icon-data"><FaMedal className="icon" />Medalha</span>
                                                            <span className="card-icon-data">
                                                                {`- ${medals[reputationData.lvl_up.medal.value as keyof Medals][0]}`}
                                                            </span>
                                                        </div>
        
                                                        <div className="sellerReputationData-item"> 
                                                            <span className="card-icon-data"><MdGrade className="icon" />Reputação</span>
                                                            <span className="card-icon-data">
                                                                {`- ${reputationData.lvl_up.reputation.value}`}
                                                            </span>
                                                        </div>
        
                                                        <div className="sellerReputationData-item"> 
                                                            <span className="card-icon-data"><MdTimer className="icon" />Tempo Registrado</span>
                                                            <span className="card-icon-data">
                                                                {`- ${reputationData.lvl_up.registerTime.value} dias`}
                                                            </span>
                                                        </div>
        
                                                        <div className="sellerReputationData-item"> 
                                                            <span className="card-icon-data"><MdAttachMoney className="icon" />Lucro Bruto</span>
                                                            <span className="card-icon-data">
                                                                {`- ${reputationData?.lvl_up?.gross_sales.value?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} `}
                                                            </span>
                                                        </div>
        
                                                        <div className="sellerReputationData-item"> 
                                                            <span className="card-icon-data"><MdShoppingCart className="icon" />Vendas</span>
                                                            <span className="card-icon-data">
                                                                {`- ${reputationData.lvl_up.sells.value} `}
                                                            </span>
                                                        </div>
                                                    </>
                                                    
                                                :
                                                <div className="sellerReputationData-item"> 
                                                    <span>Você já alcançou o ranking máximo. Parabéns!</span>
                                                </div>
                                            }
                                        </div>
        
                                    </section>
        
                                </ContentAccordion>
                                :
                                null
                            }
        
                            <Tabs>
                                <TabList>
                                    <Tab>
                                        <h4 className="tab-title">
                                            Faturamento Bruto
                                            <Tippy content={`No Período Anterior: ${chartGrossSalesData.previousValue.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`} maxWidth={'30rem'}>
                                                <span>
                                                    <FaRegQuestionCircle className="icon-small" />
                                                </span>
                                            </Tippy>
                                        </h4>
                                        {
                                            chartGrossSalesData.isReady.value ?
                                            <>
                                                <h3 className="tab-value"><strong>{chartGrossSalesData.tabValue.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></h3>
                                                {
                                                    chartGrossSalesData.isHigh.value ?
                                                    <span className="tab-tag up">
                                                        <FaArrowUp className="icon-small" />
                                                        {chartGrossSalesData.tabTag.value.toFixed(2)}%
                                                    </span>
                                                    :
                                                    <span className="tab-tag down">
                                                        <FaArrowDown className="icon-small" />
                                                        {chartGrossSalesData.tabTag.value.toFixed(2)}%
                                                    </span>
                                                }
                                            </>
                                            :
                                            <h3 className="tab-value">...</h3>
                                        }
                                    </Tab>
        
                                    <Tab>
                                        <h4 className="tab-title">
                                            Quantidade de Vendas
                                            <Tippy content={`No Período Anterior: ${chartSellsData.previousValue.value.toLocaleString('pt-br',{style: 'decimal'})}`} maxWidth={'30rem'}>
                                                <span>
                                                    <FaRegQuestionCircle className="icon-small" />
                                                </span>
                                            </Tippy>
                                        </h4>
                                        {
                                            chartSellsData.isReady.value ?
                                            <>
                                                <h3 className="tab-value"><strong>{chartSellsData.tabValue.value.toLocaleString('pt-br',{style: 'decimal'})}</strong></h3>
                                                {
                                                    chartSellsData.isHigh.value ?
                                                    <span className="tab-tag up">
                                                        <FaArrowUp className="icon-small" />
                                                        {chartSellsData.tabTag.value.toFixed(2)}%
                                                    </span>
                                                    :
                                                    <span className="tab-tag down">
                                                        <FaArrowDown className="icon-small" />
                                                        {chartSellsData.tabTag.value.toFixed(2)}%
                                                    </span>
                                                }
                                            </>
                                            :
                                            <h3 className="tab-value">...</h3>
                                        }
                                    </Tab>
        
                                    <Tab>
                                        <h4 className="tab-title">
                                            Unidades Vendidas
                                            <Tippy content={`No Período Anterior: ${chartSellItemsData.previousValue.value.toLocaleString('pt-br',{style: 'decimal'})}`} maxWidth={'30rem'}>
                                                <span>
                                                    <FaRegQuestionCircle className="icon-small" />
                                                </span>
                                            </Tippy>
                                        </h4>
                                        {
                                            chartSellItemsData.isReady.value ?
                                            <>
                                                <h3 className="tab-value"><strong>{chartSellItemsData.tabValue.value.toLocaleString('pt-br',{style: 'decimal'})}</strong></h3>
                                                {
                                                    chartSellItemsData.isHigh.value ?
                                                    <span className="tab-tag up">
                                                        <FaArrowUp className="icon-small" />
                                                        {chartSellItemsData.tabTag.value.toFixed(2)}%
                                                    </span>
                                                    :
                                                    <span className="tab-tag down">
                                                        <FaArrowDown className="icon-small" />
                                                        {chartSellItemsData.tabTag.value.toFixed(2)}%
                                                    </span>
                                                }
                                            </>
                                            :
                                            <h3 className="tab-value">...</h3>
                                        }
                                    </Tab>
        
                                    <Tab>
                                        <h4 className="tab-title">
                                            Visitas
                                            <Tippy content={`No Período Anterior: ${visitsChartData.previousValue.value.toLocaleString('pt-br',{style: 'decimal'})}`} maxWidth={'30rem'}>
                                                <span>
                                                    <FaRegQuestionCircle className="icon-small" />
                                                </span>
                                            </Tippy>
                                        </h4>
                                        {
                                            visitsChartData.isReady.value ?
                                            <>
                                                <h3 className="tab-value"><strong>{visitsChartData.tabValue.value.toLocaleString('pt-br',{style: 'decimal'})}</strong></h3>
                                                {
                                                    visitsChartData.isHigh.value ?
                                                    <span className="tab-tag up">
                                                        <FaArrowUp className="icon-small" />
                                                        {visitsChartData.tabTag.value.toFixed(2)}%
                                                    </span>
                                                    :
                                                    <span className="tab-tag down">
                                                        <FaArrowDown className="icon-small" />
                                                        {visitsChartData.tabTag.value.toFixed(2)}%
                                                    </span>
                                                }
                                            </>
                                            :
                                            <h3 className="tab-value">...</h3>
                                        }
                                    </Tab>
        
                                    <Tab disabled={true}>
                                        <h4 className="tab-title">
                                            Ticket Médio
                                            <Tippy content={`No Período Anterior: ${averageTicketData.previousValue.value.toLocaleString('pt-br',{style: 'decimal'})}`} maxWidth={'30rem'}>
                                                <span>
                                                    <FaRegQuestionCircle className="icon-small" />
                                                </span>
                                            </Tippy>
                                        </h4>
                                        {
                                            averageTicketData.isReady.value ?
                                            <>
                                                <h3 className="tab-value"><strong>{averageTicketData.tabValue.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></h3>
                                                {
                                                    averageTicketData.isHigh.value ?
                                                    <span className="tab-tag up">
                                                        <FaArrowUp className="icon-small" />
                                                        {averageTicketData.tabTag.value.toFixed(2)}%
                                                    </span>
                                                    :
                                                    <span className="tab-tag down">
                                                        <FaArrowDown className="icon-small" />
                                                        {averageTicketData.tabTag.value.toFixed(2)}%
                                                    </span>
                                                }
                                            </>
                                            :
                                            <h3 className="tab-value">...</h3>
                                        }
                                    </Tab>
                                </TabList>
        
                                <TabPanel>
                                    {
                                        chartGrossSalesData.isReady.value ?
                                        <div className="container-fluid">
                                            <LineChart labels={chartLabel.attach(Downgraded).get()} dataset={chartGrossSalesData.dataset.attach(Downgraded).get()} height={chartSize.get()} />
                                        </div>
                                        :
                                        null
                                    }
                                </TabPanel>
        
                                <TabPanel>
                                    {
                                        chartSellsData.isReady.value ?
                                        <div className="container-fluid">
                                            <LineChart labels={chartLabel.attach(Downgraded).get()} dataset={chartSellsData.dataset.attach(Downgraded).get()} height={chartSize.get()} />
                                        </div>
                                        :
                                        null
                                    }
                                </TabPanel>
        
                                <TabPanel>
                                    {
                                        chartSellItemsData.isReady.value ?
                                        <div className="container-fluid">
                                            <LineChart labels={chartLabel.attach(Downgraded).get()} dataset={chartSellItemsData.dataset.attach(Downgraded).get()} height={chartSize.get()} />
                                        </div>
                                        :
                                        null
                                    }
                                </TabPanel>
        
                                <TabPanel>
                                    {
                                        visitsChartData.isReady.value ?
                                        <div className="container-fluid">
                                            <LineChart labels={visitsChartData.label.attach(Downgraded).get()} dataset={visitsChartData.dataset.attach(Downgraded).get()} height={chartSize.get()} />
                                        </div>
                                        :
                                        null
                                    }
                                </TabPanel>
        
                                <TabPanel>
                                    {
                                        null
                                    }
                                </TabPanel>
        
                            </Tabs>
        
                            {
                                data.length > 0 ?
                                <VirtualizedGrid 
                                    rows={data.attach(Downgraded).get()}
                                    columns={columns}
                                    options={options}
                                    title="Métricas dos Anúncios"
                                    defaultSort={{field: 'gross_sales', direction: 'desc'}}
                                />
                                :
                                null
                            }
                        </>
                    }
                </main>
            </div>
        </div>
    )
}