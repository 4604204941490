import { toast } from 'react-toastify';
import noData from '../../../assets/images/nodata.svg';
import './style.scss';
import {BsFillTagFill } from 'react-icons/bs';
import {FaTruck, FaAward, FaCheckCircle, FaTimesCircle, FaPauseCircle, FaHeart} from 'react-icons/fa';
import {GoZap} from 'react-icons/go';
import {BiRevision} from 'react-icons/bi'
import {FiExternalLink} from 'react-icons/fi';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Popper from '@material-ui/core/Popper';
import placeholder from '../../../assets/images/placeholder.jpg';
import { useDarkMode } from '../../../hooks/useDarkMode';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import Switch from '@mui/material/Switch';
import { 
     GridFilterItem,
  } from '@mui/x-data-grid';
import { 
    IQualityControl, 
    ProductsDataQualityControl,

} from "../../../types/Types";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import { Loading } from '../../../components/Loading/Loading';
import { BugReportModal } from '../../../components/modal/BugReportModal';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';

const defaultTheme  = createTheme();


export const AdsQualityControl = (props:any) =>{
    document.title = "Controle de Qualidade dos Anúncios - MeLiBeat";
    const handleValidateStatus = useHandleErrors();

    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const fetchResource = (query:any=undefined) => axs.get<IQualityControl[]>('/mlbs/qos/', {params: query}).then((r: any) => {
        handleValidateStatus(r)
        return processData(r.data);
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    });

    const data: State<IQualityControl[]> = useState<IQualityControl[]>([]);
    const showButtons: State<boolean> = useState<boolean>(false)
    const productsData: State<ProductsDataQualityControl[]> = useState<ProductsDataQualityControl[]>([]);
    const {DarkMode, } = useDarkMode();


    const StyledPopper = styled(Popper)({
        "& .MuiAutocomplete-listbox": {
          '& li':{
            fontSize: 'clamp(1.1rem, 1vw, 1.3rem)',
          }
        }
    });

    const StyledDefaultIcon = styled(ThumbUpAltOutlinedIcon)({
        width: '2rem',
        height: '2rem',
    });
    const StyledBadIcon = styled(ThumbDownAltOutlinedIcon)({
        width: '2rem',
        height: '2rem',
    });

    const StyledDefaultIconChecked = styled(ThumbUpAltOutlinedIcon)({
        width: '2rem',
        height: '2rem',
        color: "#20bf6b"
    });
    const StyledBadIconChecked = styled(ThumbDownAltOutlinedIcon)({
        width: '2rem',
        height: '2rem',
        color: "#ff3f34"
    });
      
    useEffect(() => {
        data.set(fetchResource())
    }, [])
    
      const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
      const checkedIcon = <CheckBoxIcon fontSize="small" />;
      

      function CheckboxesTags(props: any) {
        const { item, applyValue, options:tag_options } = props;
        const handleFilterChange = (event: any, value: any) => {
          applyValue({ ...item, value: value });
        };
      
        return (
          <Autocomplete
            multiple
            options={tag_options}
            disableCloseOnSelect
            getOptionLabel={(option: any) => option.title}
            defaultValue={item.value}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                  style={{ marginRight: 8 }}
                  // checked={selected_values.includes(option.title)}
                  checked={selected}
                />
                {option.title}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Tags" variant="standard" sx={{'& .MuiChip-root':{fontSize: 'clamp(1rem, 1.1vw, 1.2rem)'}}} />
            )}
            PopperComponent={StyledPopper}
            onChange={handleFilterChange}
          />
        );
      }
      
      const checkVisibility = (column: string)=>{
        const storageColumnsData = localStorage.getItem('Controle de Qualidade dos Anúncios') ? localStorage.getItem('Controle de Qualidade dos Anúncios') : null;
        if(storageColumnsData !== null){
          const localData = JSON.parse(storageColumnsData);
          const shouldHide = localData.find((item: any)=> item.field === column);
          return shouldHide !== undefined ? !shouldHide.visibility : false
        }
        return false
      }

    const columns = [
        { 
            headerName:  "SKU", 
            field: "sku", 
            type: 'string',
            headerAlign: 'center',
            hide: checkVisibility('sku'),
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) => {
                return (
                    <span style={{fontSize: 'clamp(1.1rem,1vw,1.2rem)'}} title={rowData.row.sku}>{rowData.row.sku}</span>
                )
            }
        },
        { 
            headerName:  "MLB", 
            field: "id", 
            type: 'string',
            headerAlign: 'center',
            hide: checkVisibility('id'),
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) => {
                return (
                    <ThemeProvider theme={defaultTheme}>
                        <div title={rowData.row.id}>
                            <Link 
                                href={rowData.row.link} 
                                target="_blank" rel="noreferrer" 
                                color={'inherit'} 
                                sx={{'&:hover':{color: defaultTheme.palette.primary.dark}}}
                                style={{fontSize: 'clamp(1.1rem,1vw,1.2rem)', display: 'flex', alignItems: 'center', gap: '5px', textDecoration: 'none'}} 
                            >
                                <div>{rowData.row.id}</div><FiExternalLink />
                            </Link>
                        </div>
                  </ThemeProvider>
                )
              }
        },
        {
            headerName: "Imagem",
            field: "photo",
            headerAlign: 'center',
            hide: checkVisibility('photo'),
            flex: 0.5,
            minWidth: 50,
            renderCell: (rowData: any) =>{
              return(
                <div className="img" style={{width: 30, height: 30, borderRadius: '50%', display: 'flex'}}>
                    <img alt="Imagem do produto" src={rowData.row.photo !== "" ? rowData.row.photo : placeholder} style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 'inherit'}}/>
                </div>
              )
            }
        },
        {
          headerName: "Produto",
          field: "title",
          type: "string",
          headerAlign: 'center',
          hide: checkVisibility('title'),
          flex: 2,
          minWidth: 150,
          renderCell: (rowData: any) => {
              const product_name = rowData.row.title ? rowData.row.title : '';
              const variation_name = rowData.row.variation_name ? rowData.row.variation_name : '';
              const full_name = `${product_name} - ${variation_name}`
            return (
                <Tippy content={<div>{full_name}</div>} maxWidth={'40rem'}>
                    <div style={{width: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: 'clamp(1.1rem,1vw,1.2rem)'}}>
                        {full_name}
                    </div>
                </Tippy>
            )
          }
        },
        {
            headerName:  "Tags",
            field: "new_tags",
            type: "string",
            headerAlign: 'center',
            hide: checkVisibility('new_tags'),
            flex: 1,
            minWidth: 100,
            sortable:false,
            filterOperators: [{
              value: 'contains',
              getApplyFilterFn: (filterItem: GridFilterItem) => {
                const selected_values = filterItem.value ? filterItem.value.map((item: any)=> item.title) : [];
                if (filterItem.value == null || selected_values.length === 0) {
                  return null;
                }
          
                return ({ value } : {value: any}): boolean => {
                  if (typeof value === 'object') {
                    return selected_values.every((item: any)=> value.includes(item))
                  }
                  return selected_values.every((item: any)=> value.includes(item))
                };
              },
              // InputComponent: GridFilterInputValue,
              InputComponent: CheckboxesTags,
              InputComponentProps: { type: 'singleSelect', options: [
                  {title: 'Envio Grátis'}, 
                  {title: 'Fulfillment'}, 
                  {title: 'Status: Ativo'}, 
                  {title: 'Status: Inativo'},
                  {title: 'Status: Pausado'},
                  {title: 'Status: Em Revisão'},
                  {title: 'Status: Fechado'},
                  {title: 'Promoção'},
                  {title: 'Anúncio Clássico'},
                  {title: 'Anúncio 12x'},
                  {title: 'Anúncio Saudável'},
                  {title: 'Anúncio Não Saudável'},
                ]},
            }],
            valueOptions: [
                "Envio Grátis", 
                "Fulfillment", 
                "Status: Ativo", 
                "Status: Inativo", 
                "Status: Pausado", 
                "Status: Em Revisão", 
                "Status: Fechado", 
                "Promoção",
                "Anúncio Clássico",
                "Anúncio 12x",
                "Anúncio Saudável",
                "Anúncio Não Saudável"
            ],
            renderCell: (rowData: any) => {
              const tagStatus:any = {"active": <FaCheckCircle className="icon-small"/>, "inactive": <FaTimesCircle className="icon-small"/>, 'paused': <FaPauseCircle className="icon-small"/>, "under_review": <BiRevision className="icon-small" />, "closed": <FaTimesCircle className="icon-small"/>}
              const tagStatusTranslations:any = {"active": 'Ativo', "inactive": 'Inativo', 'paused': 'Pausado', "under_review": "Em Revisão", "closed": "Fechado"}
              const statusColor:any = {"active": "#2477ad", "inactive": "#a5b1c2", 'paused': "#d65446", "under_review": "#000", "closed": "#c0392b"}
              const tagColors: any = {
                'tags': 
                [
                    {'title': 'Envio Grátis', 'value': <FaTruck className="icon-small" />, 'color': (rowData.row.free_shipping ? '#4b7bec':'#a5b1c2')},
                    {'title': 'Fulfillment', 'value': <GoZap className="icon-small"/>, 'color': (rowData.row.fulfillment ? '#20bf6b':'#a5b1c2')},
                    {'title': `Status: ${tagStatusTranslations[rowData.row.status]}`, 'value': tagStatus[rowData.row.status], 'color':  (rowData.row.status ? statusColor[rowData.row.status]:'#a5b1c2')},
                    {'title': `Promoção: ${rowData.row.original_price !== null ? (((rowData.row.sell_price - rowData.row.original_price)/rowData.row.original_price)*100).toFixed(2):0}%`, 'value': <BsFillTagFill className="icon-small"/>, 'color': (rowData.row.deal ? '#e056fd':'#a5b1c2')},
                    {'title': `Tipo de Listagem: ${rowData.row.listing_type === 'gold_special' ? 'Clássico':'12x'}`, 'value': <FaAward className="icon-small"/>, 'color': (rowData.row.listing_type === 'gold_pro' ? '#e1b12c' : '#a5b1c2')},
                    {'title': `Saúde do Anúncio ${(rowData.row.health*100)}%`, 'value': <FaHeart className="icon-small"/>, 'color': (rowData.row.health ? ((rowData.row.health*100) >= 70 ? '#e84118' : '#9b59b6') :'#a5b1c2')},
                ]
              };
              return (
                <div className="tags-container" style={{marginTop: 0, flexWrap: 'nowrap', display: 'flex', gap: '5px'}}>
                  {
                    Object.values(tagColors.tags).map((item: any, index: number)=>{
                      if(item.title === "Relação do Catálogo"){
                        if(rowData.row.catalog_relation){
                            return(
                                <Tippy key={index} content={rowData.row.catalog_relation.mlb_id} maxWidth={'25rem'}>
                                    <span style={{color: item.color}}>{item.value}</span>
                                </Tippy>
                            )
                        }else{
                            return(
                                <Tippy key={index} content={item.title} maxWidth={'25rem'}>
                                    <span style={{color: item.color}}>{item.value}</span>
                                </Tippy>
                            )
                        }
                      }else{
                        return (
                            <Tippy key={index} content={item.title} maxWidth={'25rem'}>
                                <span style={{color: item.color}}>{item.value}</span>
                            </Tippy>
                        )
                      }
                    })
                  }
        
                </div>
              )
            }
        },
        {
            headerName: "Descrição",
            field: "verifyDescription",
            type: "boolean",
            headerAlign: 'center',
            hide: checkVisibility('verifyDescription'),
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    <Rating
                    name="description-buttons"
                    value={rowData.row.verifyDescription}
                    onChange={(event, value) => {
                        const processedData = {
                                    "sku": rowData.row.sku,
                                    "mlb_id": null,
                                    "variation_id": null,
                                    "field": "description",
                                    "value": value
                                }
                                axs.put<IQualityControl>('/mlbs/qos', processedData).then((r: any) => {
                                    if(r.status === 200){
                                        const tableData = data.attach(Downgraded).get();
                                        const newData = tableData.map((item:IQualityControl)=>{
                                            if(r.data.sku !== null && item.sku === r.data.sku){
                                                item.verifyDescription = r.data.description
                                            }
                                            return item
                                        })
                                        data.set(newData)
                                    }else{
                                        toast.error("Ocorreu um erro ao fazer a solicitação.")
                                    }
                                }).catch((err: any)=>{
                                    handleValidateStatus(err.response)
                                })
                    }}
                    />
                )
            }
        },

        {
            headerName: "Ficha Cadastral",
            field: "verifySheet",
            type: "boolean",
            headerAlign: 'center',
            hide: checkVisibility('verifySheet'),
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    <Rating
                    name="sheet-buttons"
                    value={rowData.row.verifySheet}
                    onChange={(event, value) => {
                        const processedData = {
                                    "sku": rowData.row.sku,
                                    "mlb_id": null,
                                    "variation_id": null,
                                    "field": "product_sheet",
                                    "value": value
                                }
                                axs.put<IQualityControl>('/mlbs/qos', processedData).then((r: any) => {
                                    if(r.status === 200){
                                        const tableData = data.attach(Downgraded).get();
                                        const newData = tableData.map((item:IQualityControl)=>{
                                            if(r.data.sku !== null && item.sku === r.data.sku){
                                                item.verifySheet = r.data.product_sheet
                                            }
                                            return item
                                        })
                                        data.set(newData)
                                    }else{
                                        toast.error("Ocorreu um erro ao fazer a solicitação.")
                                    }
                                }).catch((err: any)=>{
                                    handleValidateStatus(err.response)
                                })
                    }}
                    />
                )
            }
        },

        {
            headerName: "Fotos",
            field: "verifyPhotos",
            type: "boolean",
            headerAlign: 'center',
            hide: checkVisibility('verifyPhotos'),
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    <Rating
                    name="photo-buttons"
                    value={rowData.row.verifyPhotos}
                    onChange={(event, value) => {
                        const processedData = {
                                    "sku": rowData.row.sku,
                                    "mlb_id": null,
                                    "variation_id": null,
                                    "field": "photos",
                                    "value": value
                                }
                                axs.put<IQualityControl>('/mlbs/qos', processedData).then((r: any) => {
                                    if(r.status === 200){
                                        const tableData = data.attach(Downgraded).get();
                                        const newData = tableData.map((item:IQualityControl)=>{
                                            if(r.data.sku !== null && item.sku === r.data.sku){
                                                item.verifyPhotos = r.data.photos
                                            }
                                            return item
                                        })
                                        data.set(newData)
                                    }else{
                                        toast.error("Ocorreu um erro ao fazer a solicitação.")
                                    }
                                }).catch((err: any)=>{
                                    handleValidateStatus(err.response)
                                })
                    }}
                    />
                )
            }
        },

        {
            headerName: "Vídeo",
            field: "verifyVideo",
            type: "boolean",
            headerAlign: 'center',
            hide: checkVisibility('verifyVideo'),
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    <Rating
                    name="photo-buttons"
                    value={rowData.row.verifyVideo}
                    onChange={(event, value) => {
                        const processedData = {
                                    "sku": rowData.row.sku,
                                    "mlb_id": null,
                                    "variation_id": null,
                                    "field": "video",
                                    "value": value
                                }
                                axs.put<IQualityControl>('/mlbs/qos', processedData).then((r: any) => {
                                    if(r.status === 200){
                                        const tableData = data.attach(Downgraded).get();
                                        const newData = tableData.map((item:IQualityControl)=>{
                                            if(r.data.sku !== null && item.sku === r.data.sku){
                                                item.verifyVideo = r.data.video
                                            }
                                            return item
                                        })
                                        data.set(newData)
                                    }else{
                                        toast.error("Ocorreu um erro ao fazer a solicitação.")
                                    }
                                }).catch((err: any)=>{
                                    handleValidateStatus(err.response)
                                })
                    }}
                    />
                )
            }
        },
        {
            headerName: "Título",
            field: "verifyTitle",
            type: "boolean",
            headerAlign: 'center',
            hide: checkVisibility('verifyTitle'),
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    <Rating
                    name="title-buttons"
                    value={rowData.row.verifyTitle}
                    onChange={(event, value) => {
                        const processedData = {
                            "sku": null,
                            "mlb_id": rowData.row.id,
                            "variation_id": rowData.row.variation_id,
                            "field": "title",
                            "value": value
                                }
                                axs.put<IQualityControl>('/mlbs/qos', processedData).then((r: any) => {
                                    if(r.status === 200){
                                        const tableData = data.attach(Downgraded).get();
                                        const newData = tableData.map((item:IQualityControl)=>{
                                            if(r.data.sku !== null && item.sku === r.data.sku){
                                                item.verifyTitle = r.data.title
                                            }
                                            return item
                                        })
                                        data.set(newData)
                                    }else{
                                        toast.error("Ocorreu um erro ao fazer a solicitação.")
                                    }
                                }).catch((err: any)=>{
                                    handleValidateStatus(err.response)
                                })
                    }}
                    />
                )
            }
        },
        {
            headerName: "Avaliação",
            field: "verifyRating",
            type: "boolean",
            headerAlign: 'center',
            hide: checkVisibility('verifyRating'),
            // flex: 1,
            width: 100,
            renderCell: (rowData: any) =>{
                return(
                    <Rating
                    name="rating-buttons"
                    value={rowData.row.verifyRating}
                    onChange={(event, value) => {
                        const processedData = {
                            "sku": null,
                            "mlb_id": rowData.row.id,
                            "variation_id": rowData.row.variation_id,
                            "field": "rating",
                            "value": value
                                }
                                axs.put<IQualityControl>('/mlbs/qos', processedData).then((r: any) => {
                                    if(r.status === 200){
                                        const tableData = data.attach(Downgraded).get();
                                        const newData = tableData.map((item:IQualityControl)=>{
                                            if(r.data.sku !== null && item.sku === r.data.sku){
                                                item.verifyRating = r.data.rating
                                            }
                                            return item
                                        })
                                        data.set(newData)
                                    }else{
                                        toast.error("Ocorreu um erro ao fazer a solicitação.")
                                    }
                                }).catch((err: any)=>{
                                    handleValidateStatus(err.response)
                                })
                    }}
                    />
                )
            }
        },        {
            headerName: "Envio Full",
            field: "verifyShipFull",
            type: "boolean",
            headerAlign: 'center',
            hide: checkVisibility('verifyShipFull'),
            // flex: 1,
            width: 100,
            renderCell: (rowData: any) =>{
                return(
                    <RadioGroup style={{gap: '5px'}} defaultValue={rowData.row.verifyShipFull} row name="ship-buttons" onChange={(evt: any, value: any)=> {
                        const processedData = {
                            "sku": null,
                            "mlb_id": rowData.row.id,
                            "variation_id": rowData.row.variation_id,
                            "field": "ship_full",
                            "value": value === 'true' ? true : false
                        }
                        axs.put<IQualityControl>('/mlbs/qos', processedData).then((r: any) => {
                            if(r.status === 200){
                                const tableData = data.attach(Downgraded).get();
                                const newData = tableData.map((item:IQualityControl)=>{
                                    if(r.data.sku === null && item.id === r.data.mlb_id){
                                        item.verifyShipFull = r.data.ship_full
                                    }
                                    return item
                                })
                                data.set(newData)
                            }else{
                                toast.error("Ocorreu um erro ao fazer a solicitação.")
                            }
                        }).catch((err: any)=>{
                            handleValidateStatus(err.response)
                        })
                    }}>
                        <Tippy content={<div>Não</div>} maxWidth={'25rem'}>
                            <Radio style={{padding: '0.5rem', boxShadow: '1px 1px 2px rgba(0,0,0,0.3)', borderRadius: '4px'}} value={false} icon={<StyledBadIcon />} checkedIcon={<StyledBadIconChecked />} />
                        </Tippy>
                        <Tippy content={<div>Sim</div>} maxWidth={'25rem'}>
                            <Radio style={{padding: '0.5rem', boxShadow: '1px 1px 2px rgba(0,0,0,0.3)', borderRadius: '4px'}} value={true} icon={<StyledDefaultIcon />} checkedIcon={<StyledDefaultIconChecked />} />
                        </Tippy>
                    </RadioGroup>
                )
            }
        },
    ]
    
    
    
    const options = {
        palette:{
          tableHeader: {
            main: '#F7CE3E',
            dark: '#ac902b',
            light: '#f8d764',
            contrastText: '#000'
          },
        },
        alternate: true,
        selection: true,
      }

    const processData = (values: IQualityControl[])=>{
        const processedArray = values.map((item: IQualityControl)=>{
            let tags: string[] = [];
            if(item.free_shipping) tags.push('Envio Grátis');
            if(item.fulfillment) tags.push('Fulfillment');
            if(item.deal) tags.push('Promoção');
            if(parseFloat(item.health)*100 >= 70) tags.push('Anúncio Saudável');
            if(parseFloat(item.health)*100 < 70) tags.push('Anúncio Não Saudável');
            if(item.status === "active") tags.push('Status: Ativo');
            if(item.status === "inactive") tags.push('Status: Inativo');
            if(item.status === "paused") tags.push('Status: Pausado');
            if(item.status === "under_review") tags.push('Status: Em Revisão');
            if(item.status === "closed") tags.push('Status: Fechado');
            if(item.listing_type === 'gold_special') tags.push('Anúncio Clássico');
            if(item.listing_type === 'gold_pro') tags.push('Anúncio 12x');

            item['new_tags'] = tags;
            
            return item
        })
        return processedArray
    }


    const handleSelection = (rows: IQualityControl[])=>{
        if(rows.length > 0){
            if(!showButtons.get()){
                showButtons.set(true);
            }
            const processedData = rows.map((item: IQualityControl)=>{
                return {
                    "sku": item.sku, 
                    "mlb_id": item.id, 
                    "idItem": item._id
                }
            })
            productsData.set(processedData);
        }else{
            showButtons.set(false);
        }  
    }

    if(data.promised){ //Enquanto for promisse ou estiver carregando
        return (
            <>
                <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}> 
                    <div className="content-wrapper">
                        <main className="main-content container-fluid">
                            <Loading />
                        </main>
                    </div>
                </div>
            </>
        )
    }

    if (data.error) { // Quando o promise dar reject
        return (<ErrorScreen />)
    }

    return(
        <>
            <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
                <BugReportModal />
                <div className="content-wrapper">
                    <main className="main-content container-fluid">
                        {
                            data.length > 0 ?
                            <>
                                <VirtualizedGrid 
                                    rows={data.attach(Downgraded).get()}
                                    columns={columns}
                                    options={options}
                                    title="Controle de Qualidade dos Anúncios"
                                    onSelection={(rows: any)=>  handleSelection(rows)}
                                    defaultSort={{field: 'title', direction: 'asc'}}
                                />
                            </>
                            :
                            <div className="img-container">
                                <img src={noData} alt="Não foi possível renderizar o gráfico por falta de dados." />
                                <h4>Não foi possível renderizar os gráficos por falta de dados.</h4>
                            </div>
                        }
                    </main>

                </div>
            </div>
        </>
    )
}