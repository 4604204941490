import { useHistory } from "react-router-dom";
import { Loading } from '../../../components/Loading/Loading';
import { useDarkMode } from '../../../hooks/useDarkMode';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { toast } from 'react-toastify';
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import { useModal } from '../../../hooks/useModal';
import { ModalConfirm } from '../../../components/modal/ModalConfirm';
import moment from 'moment';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import { BugReportModal } from '../../../components/modal/BugReportModal';

const FONT_SIZE = "clamp(1.2rem, 1.2vw, 1.6rem)";


export const GroupSuppliersManagement = () =>{
    document.title = "Gestão Grupos de Fornecedores - MeLiBeat";
    const handleValidateStatus = useHandleErrors();

    let history = useHistory();
    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const fetchResource = (query:any=undefined) => axs.get<any>(`/groupsuppliers/`, {params: query}).then((r: any) => {
        handleValidateStatus(r)
        return r.data
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    })
    const data: State<any> = useState<any>([]);
    const {DarkMode, } = useDarkMode();
    const {showHideModal} = useModal();

    const options = {
        palette:{
          tableHeader: {
            main: '#F7CE3E',
            dark: '#ac902b',
            light: '#f8d764',
            contrastText: '#000'
          },
        },
        alternate: true,
    }

    const columns = [
        {
            headerName: "Nome",
            field: "name",
            type: "string",
            headerAlign: 'center',
            flex: 2,
            minWidth: 200,
            renderCell: (rowData: any) =>{
                return(
                    <span title={rowData.row.name}>{rowData.row.name}</span>
                )
            }
        },
        {
            headerName: "Limite de Crédito",
            field: "creditLimit",
            type: "number",
            headerAlign: 'center',
            flex: 2,
            minWidth: 200,
            renderCell: (rowData: any) =>{
                return(
                    <span title={rowData.row.creditLimit}>{rowData.row.creditLimit}</span>
                )
            }
        },
        {
            headerName: "Última Alteração",
            field: "lastUpdated",
            type: "string",
            headerAlign: 'center',
            flex: 2,
            minWidth: 200,
            renderCell: (rowData: any) =>{
                return(
                    <span title={moment(rowData.row.lastUpdated).format("DD-MM-YYYY")}>{moment(rowData.row.lastUpdated).format("DD-MM-YYYY")}</span>
                )
            }
        },
    ]

    useEffect(() => {
        data.set(fetchResource())
    }, [])

    const confirmDelete = async(value: boolean, id: string|number)=>{
        if(value){
            axs.delete<any[]>('/groupsuppliers/'+id).then((r: any) => {
                if(r.status === 200){
                    toast.success('Produto Removido com Sucesso!');
                    data.set(fetchResource());
                    return
                }else{
                    toast.error(r.message);
                }
            }).catch((err: any)=>{
                handleValidateStatus(err.response)
            });
        }else{
            toast.info("Você escolheu não deletar!");
        }
    }

    const handleUpdate = (row: any)=>{
        history.push(`/cadastros/grupo-fornecedores/${row._id}`);
    }

    if(data.promised){ //Enquanto for promisse ou estiver carregando
        return (
            <>
                <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}> 
                    <div className="content-wrapper">
                        <main className="main-content container-fluid">
                            <Loading />
                        </main>
                    </div>
                </div>
            </>
        )
    }

    if (data.error) { // Quando o promise dar reject
        return (<ErrorScreen />)
    }

    return(
        <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
            <BugReportModal />
            <div className="content-wrapper">
                <main className="main-content container-fluid">
                    <ModalConfirm />
                    <Box style={{ width: '300px', padding: "2rem 0"}}>
                        <Button variant="contained" style={{fontSize: FONT_SIZE}} color='primary' onClick={(evt: any)=> {
                            history.push(`/cadastros/grupo-fornecedores/`)
                        }}>
                            Cadastrar Novo
                        </Button>
                    </Box>
                    <VirtualizedGrid 
                        rows={data.attach(Downgraded).get()}
                        columns={columns}
                        options={options}
                        tableHeight={'auto'}
                        title="Gestão Grupo Forncedores"
                        actions={[
                            {icon: 'delete', title: 'Deletar Item', method: (evt: any, row:any)=> showHideModal({show: true, title: "Quer mesmo deletar esse item?", execute: (confirm: boolean)=> confirmDelete(confirm, row._id)})}, 
                            {icon: 'edit', title: 'Editar Item', method: (evt: any, row:any)=> handleUpdate(row)}
                        ]} 
                        defaultSort={{field: 'name', direction: 'asc'}}
                    />
                </main>
            </div>
        </div>
    )
}