import './style.scss';
import {FiExternalLink} from 'react-icons/fi';
import {FaHistory} from 'react-icons/fa';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import { useDarkMode } from '../../hooks/useDarkMode';

export const ImageCard = (props: any) =>{
    const {DarkMode, } = useDarkMode();

    return (
        <div className={`image-card-component ${DarkMode ? 'dark-mode-image-card' : ''}`} style={props.style}>
            <div className="image-container">
                <img src={props.image} alt="imagem referente ao MLB" />
                <h3 className="img-card-title">
                    {
                        props.title.isLink
                        ? <a href={props.title.link}>{props.title.value}<FiExternalLink /></a>
                        : <strong>{props.title.value}</strong>

                    }
                    <span>{props.title.inventory_id ? `  ${props.title.inventory_id}`:' Intentory: N/A'}</span>
                </h3>
            </div>

            {
                props.content.map((item: any, index: any)=>{
                    if(index === 0){
                        return (
                            <div className="multiple-data-inner-card-component first" key={index}>
                                <div>
                                    <header><span>{item[0].title}</span></header>
                                    <Tippy content={item[0].value}>
                                        <main className="product-title">
                                            <span>{item[0].value}</span>
                                        </main>
                                    </Tippy>
                                    <hr />
                                    {
                                        item[1].value ?
                                        <>
                                            <header><span>{item[1].title}</span></header>
                                            <main>
                                                <span>{item[1].value}</span>
                                            </main>
                                        </>
                                        :
                                        <>
                                            <header><span>{item[1].title}</span></header>
                                            <main>
                                                <span>...</span>
                                            </main>
                                        </>
                                    }
                                    <hr />
                                    <header><span>Tags</span></header>
                                    <main style={{display: 'flex', gap: '1rem'}}>
                                        {
                                            item[2]['tags'].map((item: any, index: any)=>{
                                                if(props.actual){
                                                    return <span key={index} title={item.title} style={{color: item.color, display: item.display}}>{item.value}</span>
                                                }else{
                                                    return <span key={index} title={item.title} style={{color: "#a5b1c2", display: item.display}}>{item.value}</span>
                                                }
                                                
                                            })
                                        }
                                    </main>
                                </div>
                            </div>
                        )
                    }else{
                        return (
                            <div className="image-card-secondary-container" key={index}>
                                <div className="separator"></div>
                                <div className="multiple-data-inner-card-component" key={index}>
                                    <div>
                                        <header><span>{item[0].title}</span></header>
                                        <main>
                                            <span style={{color: item[0].color ? item[0].color:'inherit'}}>{item[0].value}</span>
                                        </main>
                                        <hr />
                                        <header><span>{item[1].title}</span></header>
                                        <main>
                                            <span style={{color: item[1].color ? item[1].color:'inherit'}}>{item[1].value}</span>
                                        </main>
                                        <hr />
                                        <header><span>{item[2].title}</span></header>
                                        <main>
                                            <span style={{color: item[2].color ? item[2].color:'inherit'}}>{item[2].value}</span>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })
            }
            <FaHistory style={{width: '1.5rem', height: "1.5rem", position: "absolute", top: "-5px", right: "-2px", display: props.actual ? "none":"block"}} />
        </div>
    )
}