import { useEffect } from 'react';
import { useState , State } from '@hookstate/core';
import { Link } from 'react-router-dom';

export const SubMenu = (props: any) =>{
    const {item, menuOpen} = props;
    const subnav: State<boolean> = useState<boolean>(false);
    const showSubNav = () => subnav.set(!subnav.get());

    useEffect(() => {
        if(!menuOpen) subnav.set(false)
    }, [menuOpen])
    return(
        <>
            <span className="sidebarLink" onClick={item.subNav && showSubNav} style={props.style ? props.style:undefined}>
                <div>
                    <span className="icon">{item.icon}</span>
                    <Link to={item.path ? item.path : '#'}>{item.title}</Link>
                    {/* <a href={item.path}>{item.title}</a> */}
                </div>
                <div>
                    {item.subNav && subnav.get() 
                        ? item.iconOpened 
                        : item.subNav 
                        ? item.iconClosed 
                        : null}
                </div>
            </span>
            {menuOpen && subnav.get() && item.subNav.map((item:any, index:number) => {
                return (
                    <div key={index} className="sidebarLink dropDownLink">
                        <div>
                            <span className="icon">{item.icon}</span>
                            <Link to={item.path ? item.path : '#'}>{item.title}</Link>
                            {/* <a href={item.path}>{item.title}</a> */}
                        </div>
                    </div>
                )
            })}
        </>
    )
}