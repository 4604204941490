import { Accordion } from '../../../components/accordion/Accordion';
import { CaptionsPrecificationAuto } from '../../../components/accordion/files/CaptionsPrecificationAuto';
import { SkuTab } from './Components/SkuTab';
import { GroupTab } from './Components/GroupTab';
import './style.scss';
import { PopOverMenuModal } from '../../../components/modal/PopOverMenuModal';
import {FaEdit, FaTrash} from 'react-icons/fa'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ModalConfirm } from '../../../components/modal/ModalConfirm';
import { useModal } from '../../../hooks/useModal';
import { toast } from 'react-toastify';
import { useDarkMode } from '../../../hooks/useDarkMode';
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';

import { IPrecificationAutoData, BlingProductsType, BlingBrandsType, BlingCategoriesType, IEditingData, IRawBlingProducts } from "../../../types/Types";
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import { Loading } from '../../../components/Loading/Loading';
import { BugReportModal } from '../../../components/modal/BugReportModal';

export const PrecificationAuto = () =>{
    document.title = "Cadastro Precificador - MeLiBeat";
    const handleValidateStatus = useHandleErrors();

    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const fetchResource = (query:any=undefined) => axs.get<IPrecificationAutoData[]>('/precification/config/manual', {params: query}).then((r: any) => {
        handleValidateStatus(r)
        return r.data
    }).catch((err: any)=>{
        return []
    });
    const fetchBling = () => axs.get<IRawBlingProducts[]>('/bling/products/').then((r: any) => {
        const processedProducts = Object.entries(r.data).map((item: any)=>{
            let obj:IRawBlingProducts = item[1]
            return {label: `${obj.sku} - ${obj.title}`, sku: obj.sku, cost: obj.cost}
        })
        return processedProducts
    })

    const fetchBlingSKUS = () => axs.get<IRawBlingProducts[]>('/bling/products/').then((r: any) => {
        return r.data
    }).catch((err: any)=>{
        return []
    });

    const fetchBlingBrands = () => axs.get<IRawBlingProducts[]>('/bling/brands').then((r: any) => {
        const brandsArr = r.data;
        const processedarray = brandsArr.map((item: string)=>{
            return {"value": item, "text": item}
        })
        processedarray.unshift( {"value": "", "text": ""})
        return processedarray
    }).catch((err: any)=>{
        return []
    });

    const fetchBlingCategories = () => axs.get<IRawBlingProducts[]>('/bling/categories').then((r: any) => {
        const categoriesArr = r.data;
        const processedarray = categoriesArr.map((item: {id: string | null, description: string | null})=>{
            return {"value": item.id, "text": item.description}
        })
        processedarray.unshift( {"value": "", "text": ""})
        return processedarray
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    });

    const data: State<IPrecificationAutoData[]> = useState<IPrecificationAutoData[]>([]);
    const blingProducts: State<BlingProductsType> = useState<BlingProductsType>([]);
    const skus: State<object[]> = useState<object[]>([]);
    const brands: State<BlingBrandsType> = useState<BlingBrandsType>([]);
    const categories: State<BlingCategoriesType> = useState<BlingCategoriesType>([]);

    const origins: State<object[]> = useState<object[]>([{'value': '', 'text': ''}, {'value': 'TC', 'text': 'TC'}, {'value': 'RM', 'text': 'RM'}]);
    const showPopOverMenuModal: State<boolean> = useState<boolean>(false);
    const menuPosition: State<object> = useState<object>({'x': Number, 'y': Number});
    const editingData: State<IEditingData> = useState<IEditingData>({
        "_id": '',
        "type": '',
        "group": false,
        "origin": '',
        "category": '',
        "brand": '',
        "sku": '',
        "margin": 0,
        "roundTo": 0,
        "__v": 0,
        "priority": 0,
        "tableData": {
            "id": 0,
        }
    });
    const editing: State<boolean> = useState<boolean>(false);
    const typeTab: State<number> = useState<number>(0)
    const {ModalData, showHideModal} = useModal();
    const {DarkMode, } = useDarkMode();


    const columns = [
        {
            headerName: "Tipo",
            field: "type",
            type: "string",
            headerAlign: 'center',
            disableColumnMenu: true,
            flex: 1,
            renderCell: (rowData: any) =>{
                return(
                  <span title={rowData.row.type}>{rowData.row.type.toUpperCase()}</span>
                )
              }
        },
        {
            headerName: "SKU",
            field: "sku",
            type: "string",
            headerAlign: 'center',
            disableColumnMenu: true,
            flex: 1,
            renderCell: (rowData: any) =>{
                const sku = rowData.row.sku !== null ? rowData.row.sku : "N/A"
                return(
                  <span title={sku}>{sku}</span>
                )
              }
        },
        {
            headerName: "Origem",
            field: "origin",
            type: "string",
            headerAlign: 'center',
            disableColumnMenu: true,
            flex: 1,
            renderCell: (rowData: any) =>{
                const origin = rowData.row.origin !== null ? rowData.row.origin : "N/A"
                return(
                    <span title={origin}>{origin}</span>
                )
            }
        },
        {
            headerName: "Categoria",
            field: "category",
            type: "string",
            headerAlign: 'center',
            disableColumnMenu: true,
            flex: 1,
            renderCell: (rowData: any) =>{
                let category_temp: string | undefined = "N/A";
                if(rowData.row.category !== null){
                    const categoryArray = categories.attach(Downgraded).get().filter((item: any)=> item.value === rowData.row.category)
                    if(categoryArray.length > 0){
                        category_temp = categoryArray[0].text
                    }
                }
                return(
                  <span title={category_temp}>{category_temp}</span>
                )
              }
        },
        {
            headerName: "Marca",
            field: "brand",
            type: "string",
            headerAlign: 'center',
            disableColumnMenu: true,
            flex: 1,
            renderCell: (rowData: any) =>{
                const brand = rowData.row.brand !== null ? rowData.row.brand : "N/A"
                return(
                  <span title={brand}>{brand}</span>
                )
              }
        },
        {
            headerName: "Margem",
            field: "margin",
            type: "number",
            headerAlign: 'center',
            disableColumnMenu: true,
            flex: 1,
            renderCell: (rowData: any) =>{
                const margin = rowData.row.margin ? Math.round(rowData.row.margin * 100) : "N/A"
                return(
                  <span title={`Margem: ${margin}`}>{margin}</span>
                )
              }
        },
        {
            headerName: "Arredondamento",
            field: "roundTo",
            type: "number",
            headerAlign: 'center',
            disableColumnMenu: true,
            flex: 1,
            renderCell: (rowData: any) =>{
                const roundTo = rowData.row.roundTo !== null ? rowData.row.roundTo : "N/A"
                return(
                  <span title={roundTo}>{roundTo}</span>
                )
              }
        },

    ]
    
    const options = {
        palette:{
            tableHeader: {
                main: '#F7CE3E',
                dark: '#ac902b',
                light: '#f8d764',
                contrastText: '#000'
            },
        },
        alternate: true,
    }

    useEffect(() => {
        data.set(fetchResource());
        blingProducts.set(fetchBling());
        skus.set(fetchBlingSKUS());
        brands.set(fetchBlingBrands());
        categories.set(fetchBlingCategories());
    }, [])

    const handlePopOverMenuModal = ()=>{
        showPopOverMenuModal.set(false);
        editing.set(true)
        let tab: number | null = null;
        if(editingData.group.value){
            tab = 0;
        }
        else{
            tab = 1;
        }
        typeTab.set(tab)

    }

    
    const handleDelete = (value: boolean)=>{
        if(value){
            showHideModal({show: false, title: ModalData?.title, execute: ModalData?.execute})
            axs.delete<IPrecificationAutoData[]>(`/precification/config/${editingData._id.value}`).then((r: any) => {
                if(r.status === 200){
                    toast.success("Item removido!");
                    data.set(fetchResource())
                }else{
                    toast.error(r.message);
                }
            }).catch((err: any)=>{
                handleValidateStatus(err.response)
            });
        }else{
            toast.info("Você escolheu não remover!");
            showHideModal({show: false, title: ModalData?.title, execute: ModalData?.execute})
        }
    }


    const handleModal = (event: React.MouseEvent<HTMLElement>, value: IEditingData)=>{
        editing.set(false)
        let categoryArr: any = []
        menuPosition.set({x: (event.pageX-180), y: event.pageY})
        showPopOverMenuModal.set(true);
        if(value.category !== null){
            categoryArr = categories.filter((item: any)=> value.category === item.value)
            value.category = categoryArr[0] ? categoryArr[0].value : value.category
        }
        editingData.set(value)
    }

    const reloadTableData = ()=>{
        editingData.set({
            "_id": '',
            "type": '',
            "group": false,
            "origin": '',
            "category": '',
            "brand": '',
            "sku": '',
            "margin": 0,
            "roundTo": 0,
            "__v": 0,
            "priority": 0,
            "tableData": {
                "id": 0,
            }
        });
        editing.set(false);
        data.set(fetchResource())
    }

    if(data.promised || skus.promised || origins.promised || brands.promised || categories.promised){ //Enquanto for promisse ou estiver carregando
        return (
            <>
            <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}> 
                <div className="content-wrapper">
                    <main className="main-content container-fluid">
                        <Loading />
                    </main>
                </div>
            </div>
        </>
        )
    }
    
    if (data.error || skus.error || origins.error || brands.error || categories.error) { // Quando o promise dar reject
        return (<ErrorScreen />)
    }

    return(
        <div className={`wrapper ${DarkMode ? 'dark-mode-precification-auto' : ''}`}>
            <BugReportModal />
            <div className={`content-wrapper ${DarkMode ? 'dark-mode-precification-auto' : ''}`}>
                <ModalConfirm />
                {
                    showPopOverMenuModal.get() ?
                        <PopOverMenuModal position={menuPosition.attach(Downgraded).get()} closeFunction={()=> showPopOverMenuModal.set(false)}>
                            <button onClick={handlePopOverMenuModal}><FaEdit className="icon"/> Editar Regra</button>
                            <button 
                                onClick={(event: any) =>{
                                    showPopOverMenuModal.set(false);
                                    showHideModal({show: true, title: "Gostaria de remover esta regra?", execute: (confirm: boolean)=> handleDelete(confirm)})
                                }} 
                            >
                                <FaTrash className="icon"/>Deletar Regra
                            </button>
                        </PopOverMenuModal>
                    : null
                }
                <main className="main-content container-fluid">
                    {
                        skus.length > 0 && origins.length > 0 && brands.length > 0 && categories.length > 0 ?
                        <>
                            <Accordion title={CaptionsPrecificationAuto.title} data={CaptionsPrecificationAuto.data} />
                            <Tabs>
                                <TabList>
                                    <Tab>Manual</Tab>
                                    <Tab>Automático</Tab>
                                </TabList>

                                <TabPanel>
                                    <Tabs selectedIndex={typeTab.get()} onSelect={(index: any)=> {
                                        editing.set(false);
                                        typeTab.set(index)
                                    }}>
                                        <TabList>
                                            <Tab>Grupos</Tab>
                                            <Tab>SKU</Tab>
                                        </TabList>

                                        <TabPanel>
                                            <GroupTab origins={origins.attach(Downgraded).get()} brands={brands.attach(Downgraded).get()} 
                                                categories={categories.attach(Downgraded).get()} editing={editing.attach(Downgraded).get()} 
                                                editingData={editingData.attach(Downgraded).get()} reloadTable={reloadTableData} 
                                            />
                                        </TabPanel>

                                        <TabPanel>
                                            <SkuTab origins={origins.attach(Downgraded).get()} 
                                                brands={brands.attach(Downgraded).get()} 
                                                categories={categories.attach(Downgraded).get()} 
                                                skus={skus.attach(Downgraded).get()} 
                                                blingProducts={blingProducts.attach(Downgraded).get()}
                                                editing={editing.attach(Downgraded).get()} editingData={editingData.attach(Downgraded).get()} reloadTable={reloadTableData} 
                                            />
                                        </TabPanel>
                                    </Tabs>
                                </TabPanel>

                                <TabPanel>
                                    <h3>Em desenvolvimento...</h3>
                                </TabPanel>
                            </Tabs>
                            {
                                data ?
                                <VirtualizedGrid 
                                    columns={columns} 
                                    rows={data.attach(Downgraded).get()} 
                                    options={options}
                                    title="Regras - Cadastro Precificador" 
                                    actions={[
                                        {icon: 'settings', title: 'Mais Ações', method: (event: any, row:any)=> handleModal(event, row)}, 
                                    ]}
                                    defaultSort={{field: 'type', direction: 'desc'}}
                                />
                                :
                                null
                            }
                        </>
                        :
                        <main className="main-content container-fluid">
                            <Loading />
                        </main>
                    }
                </main>
            </div>
        </div>
    )
}