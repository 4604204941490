import {isFirefox} from 'react-device-detect';

import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker, { DayRange } from 'react-modern-calendar-datepicker'
import './basic-form.scss';
import { useDarkMode } from '../../hooks/useDarkMode';
import moment from 'moment';
import { useState , State } from '@hookstate/core';

export const BasicForm =(props: any) =>{
    const {DarkMode, } = useDarkMode();
    return(
        <form className={`basic-form ${DarkMode ? 'dark-mode-form' : ''}`} onSubmit={props.SubmitFunction} style={props.style ? props.style:null}>
            {/* botão criado apenas para desabilitar o envio do form com a tecla enter */}
            <button type="submit" disabled style={{display: 'none'}} aria-hidden="true"></button>
            {props.children}
        </form>
    )
}

export const InputMainContainer = (props: any) =>{

    return(
        <div className="input-main-container" style={props.style ? props.style:null}>
            {props.children}
        </div>
    )
}

export const InputContainer = (props: any)=>{
    const handleChange = (evt: React.BaseSyntheticEvent, eventType: string)=>{
        const name: string = evt.target.name;
        const value: string | number = evt.target.value;
        if(eventType === "onChange"){
            props.onChange({[name]: value})
        }else if(eventType === "onBlur"){
            props.onBlur({[name]: value})
        }else{
            return
        }
    }
    return (
        <div className={`input-container ${props.containerClass ? props.containerClass : ''}`}>
            <input 
                type={props.type ? props.type : 'text'} 
                className={props.class ? props.class:null}
                name={props.name ? props.name:null} 
                placeholder={props.placeholder ? props.placeholder:''} 
                id={props.id ? props.id:null} 
                required={props.required ? props.required : false}
                onChange={props.onChange ? (evt: React.BaseSyntheticEvent)=> handleChange(evt, 'onChange') : undefined}
                onBlur={props.onBlur ? (evt: React.BaseSyntheticEvent)=> handleChange(evt, 'onBlur') : undefined}
                disabled={props.disabled ? props.disabled : false}
                step={props.type === 'number' ? 0.01:undefined}
                defaultValue={props.value ? props.value : undefined}
            />
            <label htmlFor={props.id} style={{display: props.id ? 'initial' : 'none'}}>{props.label}</label>
        </div>
    )
}

export const DateContainer = (props: any)=>{
    const handleChange = (evt: React.BaseSyntheticEvent)=>{
        const name: string = evt.target.name;
        const value: string | number = evt.target.value;
        props.onChange({[name]: value})
    }
    return(
        <div className={`${props.containerClass ? props.containerClass : ''} input-container`}>
            <label htmlFor={props.id} style={{display: props.id ? 'initial' : 'none'}}>{props.label}</label>
            {
                isFirefox ?
                <input
                    type="date"
                    className={props.class ? props.class:null}
                    name={props.name ? props.name:null} 
                    id={props.id ? props.id:null} 
                    required={props.required ? props.required : false}
                    onChange={props.onChange ? handleChange : undefined}
                    disabled={props.disabled ? props.disabled : false}
                />
                :
                <input
                    type="month"
                    className={props.class ? props.class:null}
                    name={props.name ? props.name:null} 
                    id={props.id ? props.id:null} 
                    required={props.required ? props.required : false}
                    onChange={props.onChange ? handleChange : undefined}
                    disabled={props.disabled ? props.disabled : false}
                />
            }
        </div>
    )
}

export const SelectContainer = (props: any)=>{
    const handleChange = (evt: React.BaseSyntheticEvent)=>{
        const name: string = evt.target.name;
        const value: string = evt.target.value;
        props.onChange({[name]: value})
    }
    return (
        <div className={`${props.containerClass ? props.containerClass : ''} input-container`}>
            <select
                className={props.class ? props.class:null}
                name={props.name ? props.name:null} 
                id={props.id ? props.id:null}
                required={props.required ? props.required : false}
                onChange={props.onChange ? handleChange : undefined}
                disabled={props.disabled ? props.disabled : false}
                defaultValue={props.value ? props.value : undefined}
            >
                {
                    props.options.data.map((item: any,index: number)=>{
                        return <option key={index}value={item.value}>{item.text}</option>
                    })
                }
            </select>
            <label htmlFor={props.id} style={{display: props.id ? 'initial' : 'none'}}>{props.label}</label>
        </div>
    )
}

export const CheckboxContainer = (props: any)=>{
    const checkbox: State<boolean> = useState<boolean>(props.checked ? props.checked:'');
    return (
        <div className="checkbox-container" style={props.styleContainer ? props.styleContainer:null} onChange={props.onChange}>
            <input 
                type='checkbox' 
                className={props.class ? props.class:null}
                name={props.name ? props.name:null} 
                id={props.id ? props.id:null} 
                required={props.required ? props.required : false}
                checked={checkbox.get()}
                value={props.value}
                onChange={e => checkbox.set(!checkbox.get())}
            />
            <label htmlFor={props.id} style={props.styleLabel ? props.styleLabel:null}>{props.label}</label>
        </div>
    )   
}

export const DataListContainer = (props: any)=>{
    const handleChange = (evt: React.BaseSyntheticEvent, eventType: string)=>{
        const name: string = evt.target.name;
        const value: string | number = evt.target.value;
        if(eventType === "onChange"){
            props.onChange({[name]: value})
        }else if(eventType === "onBlur"){
            props.onBlur({[name]: value})
        }else{
            return
        }
    }
    return (
        <div className={`${props.containerClass ? props.containerClass : ''} input-container`}>
            <input 
                list={props.listName}
                className={props.class ? props.class:null}
                name={props.listName ? props.listName:null} 
                placeholder={props.placeholder ? props.placeholder:''} 
                id={props.id ? props.id:props.listName} 
                required={props.required ? props.required : false}
                onChange={props.onChange ? (evt: React.BaseSyntheticEvent)=> handleChange(evt, 'onChange') : undefined}
                onBlur={props.onBlur ? (evt: React.BaseSyntheticEvent)=> handleChange(evt, 'onBlur') : undefined}
                defaultValue={props.value ? props.value : ''}
            />
            <datalist id={props.listName}>
                {
                    props.list.map((item: any, index: number)=>{
                        return <option value={item.sku} key={index} >{item.title}</ option>
                    })
                }
            </datalist> 
            <label htmlFor={props.id} style={{display: props.listName ? 'initial' : 'none'}}>{props.label}</label>
        </div>
    )
}

// ----------- FILTERS ----------

export const InputFilterContainer = (props: any)=>{
    const handleChange = (evt: React.BaseSyntheticEvent, eventType: string)=>{
        const name: string = evt.target.name;
        const value: string | number = evt.target.value;
        if(eventType === "onChange"){
            props.onChange({[name]: value})
        }else if(eventType === "onBlur"){
            props.onBlur({[name]: value})
        }else{
            return
        }
    }

    const handleKeyPress = (evt: any)=>{
        if(evt.key === "Enter"){
            evt.target.blur()
            return
        }
    }
    return (
        <div className={`input-container ${props.containerClass ? props.containerClass : ''}`}>
            {props.controlled ?
            <input 
                type={props.type ? props.type : 'text'} 
                className={props.class ? props.class:null}
                name={props.name ? props.name:null} 
                placeholder={props.placeholder ? props.placeholder:''} 
                id={props.id ? props.id:null} 
                required={props.required ? props.required : false}
                onChange={props.onChange ? (evt: React.BaseSyntheticEvent)=> handleChange(evt, 'onChange') : undefined}
                onBlur={props.onBlur ? (evt: React.BaseSyntheticEvent)=> handleChange(evt, 'onBlur') : undefined}
                onKeyPress={props.onEnter ? handleKeyPress : ()=>{return}}
                disabled={props.disabled ? props.disabled : false}
                step={props.type === 'number' ? props.step ? props.step : 0.01  :undefined}
                min={props.min}
                max={props.max}
                value={props.value}
            />
            :
            <input 
                type={props.type ? props.type : 'text'} 
                className={props.class ? props.class:null}
                name={props.name ? props.name:null} 
                placeholder={props.placeholder ? props.placeholder:''} 
                id={props.id ? props.id:null} 
                required={props.required ? props.required : false}
                onChange={props.onChange ? (evt: React.BaseSyntheticEvent)=> handleChange(evt, 'onChange') : undefined}
                onBlur={props.onBlur ? (evt: React.BaseSyntheticEvent)=> handleChange(evt, 'onBlur') : undefined}
                onKeyPress={props.onEnter ? handleKeyPress : ()=>{return}}
                disabled={props.disabled ? props.disabled : false}
                step={props.type === 'number' ? props.step ? props.step : 0.01  :undefined}
                min={props.min}
                max={props.max}
                defaultValue={props.value}
            />
            }
            <label htmlFor={props.id} style={{display: props.id ? 'initial' : 'none'}}>{props.label}</label>
        </div>
    )
}

export const DateFilterContainer = (props: any)=>{
    const handleChange = (evt: React.BaseSyntheticEvent)=>{
        const name: string = evt.target.name;
        const value: string | number = evt.target.value;
        props.onChange({[name]: value})
    }
    return(
        <div className={`${props.containerClass ? props.containerClass : ''} input-container`}>
            {
                props.controlled ?
                <input
                    type={isFirefox ? 'date' : props.type ? props.type : "month"}
                    className={props.class ? props.class:null}
                    name={props.name ? props.name:null} 
                    id={props.id ? props.id:null} 
                    required={props.required ? props.required : false}
                    onChange={props.onChange ? handleChange : undefined}
                    disabled={props.disabled ? props.disabled : false}
                    min={props.min}
                    max={props.max}
                    value={moment.utc(props.value).format("yyyy-MM-DD")}
                />
                :
                <input
                    type={isFirefox ? 'date' : props.type ? props.type : "month"}
                    className={props.class ? props.class:null}
                    name={props.name ? props.name:null} 
                    id={props.id ? props.id:null} 
                    required={props.required ? props.required : false}
                    onChange={props.onChange ? handleChange : undefined}
                    disabled={props.disabled ? props.disabled : false}
                    min={props.min}
                    max={props.max}
                    defaultValue={moment.utc(props.value).format("yyyy-MM-DD")}
                />
            }
            <label htmlFor={props.id} style={{display: props.id ? 'initial' : 'none'}}>{props.label}</label>
        </div>
    )
}

export const SelectFilterContainer = (props: any)=>{
    const handleChange = (evt: React.BaseSyntheticEvent)=>{
        const name: string = evt.target.name;
        const value: string | number = evt.target.value;
        props.onChange({[name]: value})
    }
    return (
        <div className={`${props.containerClass ? props.containerClass : ''} input-container`}>
            <select
                className={props.class ? props.class:null}
                name={props.name ? props.name:null} 
                id={props.id ? props.id:null}
                required={props.required ? props.required : false}
                onChange={props.onChange ? handleChange : undefined}
                disabled={props.disabled ? props.disabled : false}
                defaultValue={props.value}
            >
                {
                    props.options.data.map((item: any,index: number)=>{
                        return <option key={index}value={item.value}>{item.text}</option>
                    })
                }
            </select>
            <label htmlFor={props.id} style={{display: props.id ? 'initial' : 'none'}}>{props.label}</label>
        </div>
    )
}

export const DatePickerFilterContainer = (props: any)=>{
    const myCustomLocale = {
        // months list by order
        months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
        ],
      
        // week days by order
        weekDays: [
          {
            name: 'Domingo', // used for accessibility 
            short: 'D', // displayed at the top of days' rows
            isWeekend: true, // is it a formal weekend or not?
          },
          {
            name: 'Segunda-Feira',
            short: 'S',
          },
          {
            name: 'Terça-Feira',
            short: 'T',
          },
          {
            name: 'Quarta-Feira',
            short: 'Q',
          },
          {
            name: 'Quinta-Feira',
            short: 'Q',
          },
          {
            name: 'Sexta-Feira',
            short: 'S',
          },
          {
            name: 'Sábado',
            short: 'S',
            isWeekend: true,
          },
        ],
      
        // just play around with this number between 0 and 6
        weekStartingIndex: 0,
      
        // return a { year: number, month: number, day: number } object
        getToday(gregorainTodayObject: any) {
          return gregorainTodayObject;
        },
      
        // return a native JavaScript date here
        toNativeDate(date: any) {
          return new Date(date.year, date.month - 1, date.day);
        },
      
        // return a number for date's month length
        getMonthLength(date:any) {
          return new Date(date.year, date.month, 0).getDate();
        },
      
        // return a transformed digit to your locale
        transformDigit(digit:any) {
          return digit;
        },
      
        // texts in the date picker
        nextMonth: 'Próximo Mês',
        previousMonth: 'Mês Anterior',
        openMonthSelector: 'Abrir Seletor de Meses',
        openYearSelector: 'Abrir Seletor de Anos',
        closeMonthSelector: 'Fechar Seletor de Meses',
        closeYearSelector: 'Fechar Seletor deAnos',
        defaultPlaceholder: 'Selecionar Período...',
      
        // for input range value
        from: '',
        to: '---',
      
      
        // used for input value when multi dates are selected
        digitSeparator: ',',
      
        // if your provide -2 for example, year will be 2 digited
        yearLetterSkip: 0,
      
        // is your language rtl or ltr?
        isRtl: false,
      }
    const dayRange: State<DayRange> = useState<DayRange>({
      from: props.from,
      to: props.to
    });
    const handleChange = (range: DayRange)=>{
        dayRange.set(range)
        props.onChange(range)
    }
    return(
        <div className={`${props.containerClass ? props.containerClass : ''} input-container`}>
            <DatePicker 
                value={dayRange.get()} 
                // onChange={setDayRange} 
                onChange={(range: DayRange)=> handleChange(range)} 
                inputPlaceholder="Selecionar Período: de.../até..." 
                shouldHighlightWeekends
                locale={myCustomLocale}
                calendarClassName={props.class ? `date-picker-filter ${ props.class}` : `date-picker-filter`}
                inputName={props.name ? props.name:null} 
                inputClassName="datePickerFilterInput"
                colorPrimary="#15171c" // added this
                colorPrimaryLight="#F7CE3E"
                minimumDate={props.minDate}
                maximumDate={props.maxDate}
                disabledDays={props.disabledDays}
                renderFooter = {props.renderFooter}
            />
        </div>
    )
}