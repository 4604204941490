import React from 'react';
import { BrowserRouter as Route, useParams } from 'react-router-dom';
import { useDarkMode } from '../../../hooks/useDarkMode';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { Untracked } from '@hookstate/untracked';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { toast } from 'react-toastify';
import {AiOutlineLoading3Quarters} from 'react-icons/ai';
import './style.scss';
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ICostCenterItem, ICostCenter } from "../../../types/Types"
import { useModal } from '../../../hooks/useModal';
import { ModalConfirm } from '../../../components/modal/ModalConfirm';
import { useHistory } from "react-router-dom";
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import { BugReportModal } from '../../../components/modal/BugReportModal';

const FONT_SIZE = "clamp(1.2rem, 1.2vw, 1.6rem)";

const BaseInput = styled(TextField)({
    '& .MuiInput-input':{
      fontSize: FONT_SIZE,
      minWidth: '300px',
    },
    '& label':{
      fontSize: FONT_SIZE,

    }
  });

  interface headerInputs{
      name: string | undefined,
      qty_digits: number | undefined,
      separator: string | undefined,
      order: number | undefined,
  }
  interface itemInputs{
    code: string,
    name: string,
}

interface inputError{
    type_name: boolean,
    digits: boolean,
    separator: boolean,
    order: boolean,
    code: boolean,
    sub_name: boolean,
    show_only: boolean,
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};


export const CostCenters = () =>{
    document.title = "Centros de Custo - MeLiBeat";
    const handleValidateStatus = useHandleErrors();

    let { id } = useParams<any>();
    let history = useHistory();
    const {showHideModal} = useModal();
    const childrenItems: State<itemInputs[]> = useState<itemInputs[]>([]);
    childrenItems.attach(Untracked);
    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const fetchResource = (query:any=undefined) => axs.get<any>(`/costcenter/update/${id}`, {params: query}).then((r: any) => {
        handleValidateStatus(r)
        Untracked(childrenItems).set(r.data.childrenItems)
        return r.data.doc
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    })
    const data: State<ICostCenter> = useState<ICostCenter>({
        typeName: '',
        digits: 0,
        separator: '',
        order: 0,
        items: [],
        dateCreated: '',
        lastUpdated: ''
    })
    const headerInputs: State<headerInputs> = useState<headerInputs>({
        name: undefined,
        qty_digits: undefined,
        separator: undefined,
        order: undefined,
    });

    const itemInputs: State<itemInputs> = useState<itemInputs>({
        code: '',
        name: '',
    });

    const [only_show, setOnlyShow] = React.useState<string[]>([]);

    const inputsError: State<inputError> = useState<inputError>({
        type_name: false,
        digits: false,
        separator: false,
        order: false,
        code: false,
        sub_name: false,
        show_only: false,
    })

    useEffect(() => {
        data.set(fetchResource())
    }, [])
  
    const handleChange = (event: SelectChangeEvent<typeof only_show>) => {
      const {
        target: { value },
      } = event;
      setOnlyShow(
        // On autofill we get a the stringified value.
        typeof value === 'string' ? value.split('') : value,
      );
    };
    const {DarkMode, } = useDarkMode();
    const options = {
        palette:{
          tableHeader: {
            main: '#F7CE3E',
            dark: '#ac902b',
            light: '#f8d764',
            contrastText: '#000'
          },
        },
        alternate: true,
    }

    const columns = [
        {
            headerName: "Código",
            field: "code",
            type: "string",
            headerAlign: 'center',
            flex: 2,
            minWidth: 200,
            renderCell: (rowData: any) =>{
                return(
                    <Tippy content={rowData.row.code}>
                        <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            <span title={rowData.row.code}>{rowData.row.code}</span>
                        </main>
                    </Tippy>
                )
            }
        },
        {
            headerName: "Nome",
            field: "name",
            type: "string",
            headerAlign: 'center',
            flex: 2,
            minWidth: 200,
            renderCell: (rowData: any) =>{
                return(
                    <Tippy content={rowData.row.name}>
                        <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            <span title={rowData.row.name}>{rowData.row.name}</span>
                        </main>
                    </Tippy>
                )
            }
        },
        {
            headerName: "Relacionado com",
            field: "children",
            type: "string",
            headerAlign: 'center',
            flex: 2,
            minWidth: 200,
            renderCell: (rowData: any) =>{
                return(
                    rowData.row.children.length > 0 ?
                    <Tippy content={rowData.row.children.toString().replace(',', ', ')}>
                        <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            <span title={rowData.row.children.toString().replace(',', ', ')}>{rowData.row.children.toString().replace(',', ', ')}</span>
                        </main>
                    </Tippy>
                    :
                    <span>N/A</span>
                )
            }
        },
    ]

    const defaultTheme  = createTheme({
        palette:{
          mode: DarkMode ? 'dark' : 'light',
          ...options.palette,
        }
    });

    const handleAddItem = (evt: React.BaseSyntheticEvent) =>{
        if(itemInputs.name.value === '' && itemInputs.code.value === '' && only_show.length === 0){
            toast.info("Todos os campos precisam estar preenchidos.");
            inputsError.merge({
                sub_name: true, code: true, show_only: true
            })
        }else if(itemInputs.name.value === ''){
            toast.info('O campo "Nome" precisa estar preenchido.');
            inputsError.sub_name.set(true)
        }
        else if(itemInputs.code.value === ''){
            toast.info('O campo "Código" precisa estar preenchido.');
            inputsError.code.set(true)
        }
        else if(only_show.length === 0){
            toast.info('O campo "Mostrar Somente" precisa estar preenchido.');
            inputsError.show_only.set(true)
        }else{
            for(let item of data.items.attach(Downgraded).get()){
                if(item.code ===  itemInputs.code.attach(Downgraded).get()){
                    toast.info('Não é possível adicionar itens com códigos semelhantes.');
                    return
                }
                if(item.name ===  itemInputs.name.attach(Downgraded).get()){
                    toast.info('Não é possível adicionar itens com nomes semelhantes.');
                    return
                }
            }
            const values: ICostCenterItem = {
                "_id": `${itemInputs.name.attach(Downgraded).get()}-${itemInputs.code.attach(Downgraded).get()}`,
                "name": itemInputs.name.attach(Downgraded).get(),
                "code": itemInputs.code.attach(Downgraded).get(),
                "children": only_show,
            }
            data.items.merge([values]);
            itemInputs.name.set('');
            itemInputs.code.set('');
            setOnlyShow([])
            inputsError.merge({
                code: false,
                sub_name: false,
                show_only: false,
            })
        }
    }

    const handleCancel = (evt: React.BaseSyntheticEvent) =>{
        history.goBack()
    }

    const handleSave = (evt: React.BaseSyntheticEvent) =>{
        if(headerInputs.name.value === undefined && headerInputs.qty_digits.value === undefined && headerInputs.separator.value === undefined && headerInputs.order.value === undefined){
            toast.info("Todos os campos precisam estar preenchidos.");
            inputsError.merge({
                type_name: true,
                digits: true,
                separator: true,
                order: true
            })
        }else if(headerInputs.name.value === undefined){
            toast.info('O campo "Nome do Tipo" precisa estar preenchido');
            inputsError.type_name.set(true)
        }
        else if(headerInputs.qty_digits.value === undefined){
            toast.info('O campo "Quantidade de dígitos" precisa estar preenchido');
            inputsError.digits.set(true)
        }
        else if(headerInputs.separator.value === undefined){
            toast.info('O campo "Separador" precisa estar preenchido');
            inputsError.separator.set(true)
        }
        else if(headerInputs.order.value === undefined){
            toast.info('O campo "Ordem" precisa estar preenchido');
            inputsError.order.set(true)
        }else{
            const filteredItems = data.items.attach(Downgraded).get().map((item: any)=>{
                return {
                    code: item.code,
                    name: item.name,
                    children: item.children,
                }
            })
            const processedData = {
                typeName: headerInputs.name.value,
                digits: headerInputs.qty_digits.value,
                separator: headerInputs.separator.value,
                order: headerInputs.order.value,
                items: filteredItems
            }
            axs.post<any>(`/costcenter/`, processedData).then((r: any) => {
                console.log(r.data)
                toast.info("Dados Salvos!");
                return r.data
            }).catch((err: any)=>{
                handleValidateStatus(err.response)
            })
            inputsError.merge({
                type_name: false,
                digits: false,
                separator: false,
                order: false
            })
        }
    }

    const confirmDelete = async(value: boolean, id: string|number)=>{
        if(value){
            toast.success('Produto Removido com Sucesso!');
            const filteredData = data.items.attach(Downgraded).get().filter((item: any)=> item._id !== id);
            data.items.set(filteredData)
        }else{
            toast.info("Você escolheu não deletar!");
        }
    }


    if(data.promised){ //Enquanto for promisse ou estiver carregando
        return (
            <>
                <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}> 
                    <div className="content-wrapper">
                        <main className="main-content container-fluid">
                            <div className="loading">
                                <AiOutlineLoading3Quarters className="loading-icon" />
                            </div>
                        </main>
                    </div>
                </div>
            </>
        )
    }

    if (data.error) { // Quando o promise dar reject
        return (<ErrorScreen />)
    }

    return(
        <ThemeProvider theme={defaultTheme}>
            <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
                <BugReportModal />
                <div className="content-wrapper">
                    <main className="main-content container-fluid">
                        <ModalConfirm />
                        <Paper style={{ width: '100%', padding: "2rem", display: 'flex', gap: '2rem', alignItems: 'center', justifyContent: 'space-between'}}>
                            <BaseInput 
                                error={inputsError.type_name.get() === true ? true:false}
                                label="Nome do tipo" 
                                variant="standard" 
                                type='text'
                                value={headerInputs.name.value}
                                onChange={(evt: React.BaseSyntheticEvent)=>{
                                    headerInputs.name.set(evt.target.value);
                                }}
                            />
                            <BaseInput 
                                error={inputsError.digits.get() === true ? true:false}
                                label="Qtd. Dígitos" 
                                variant="standard" 
                                type='number'
                                value={headerInputs.qty_digits.value}
                                onChange={(evt: React.BaseSyntheticEvent)=>{
                                    headerInputs.qty_digits.set(evt.target.value);
                                }}
                            />
                            <BaseInput 
                                error={inputsError.separator.get() === true ? true:false}
                                label="Separador" 
                                variant="standard" 
                                type='text'
                                value={headerInputs.separator.value}
                                onChange={(evt: React.BaseSyntheticEvent)=>{
                                    headerInputs.separator.set(evt.target.value);
                                }}
                            />
                            <BaseInput 
                                error={inputsError.separator.get() === true ? true:false}
                                label="Ordem" 
                                variant="standard" 
                                type='number'
                                value={headerInputs.order.value}
                                onChange={(evt: React.BaseSyntheticEvent)=>{
                                    headerInputs.order.set(evt.target.value);
                                }}
                            />
                        </Paper>
                            <Paper style={{ width: '100%', height: '100%', padding: "2rem", display: 'flex', flexDirection: 'column', gap: '2rem'}}>
                                <Box style={{ width: '100%', padding: "2rem", display: 'flex', gap: '2rem', justifyContent: 'space-between' }}>
                                    <BaseInput 
                                        error={inputsError.code.get() === true ? true:false}
                                        label="Código" 
                                        variant="standard" 
                                        type='text'
                                        value={itemInputs.code.value}
                                        onChange={(evt: React.BaseSyntheticEvent)=>{
                                            itemInputs.code.set(evt.target.value);
                                        }}
                                    />
                                    <BaseInput 
                                        error={inputsError.sub_name.get() === true ? true:false}
                                        label="Nome" 
                                        variant="standard" 
                                        type='text'
                                        value={itemInputs.name.value}
                                        onChange={(evt: React.BaseSyntheticEvent)=>{
                                            itemInputs.name.set(evt.target.value);
                                        }}
                                    />
                                    <FormControl sx={{ width: 300 }}>
                                        <InputLabel id="multiple-name-label" style={{fontSize: FONT_SIZE}}>Mostrar Somente</InputLabel>
                                        <Select
                                            error={inputsError.show_only.get() === true ? true:false}
                                            labelId="multiple-name-label"
                                            id="multiple-name"
                                            multiple
                                            value={only_show}
                                            onChange={handleChange}
                                            variant='standard'
                                            MenuProps={MenuProps}
                                            style={{fontSize: FONT_SIZE}}
                                            >
                                            {
                                                childrenItems.attach(Downgraded).get().map((item) => (
                                                    <MenuItem
                                                    key={item.code}
                                                    value={item.code}
                                                    >
                                                    {`${item.code} - ${item.name}`}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                    <Button variant="contained" startIcon={<AddIcon />} style={{fontSize: FONT_SIZE}} onClick={handleAddItem}>
                                        Adicionar Item
                                    </Button>
                                </Box>
                                {
                                    data.items.length > 0 ?
                                    <VirtualizedGrid 
                                        rows={data.items.attach(Downgraded).get()}
                                        columns={columns}
                                        options={options}
                                        tableHeight={'auto'}
                                        title="Centros de Custo"
                                        actions={[
                                            {icon: 'delete', title: 'Deletar Item', method: (evt: any, row:any)=> showHideModal({show: true, title: "Quer mesmo deletar esse item?", execute: (confirm: boolean)=> confirmDelete(confirm, row._id)})}, 
                                        ]} 
                                        defaultSort={{field: 'code', direction: 'asc'}}
                                    />
                                    :
                                    <Paper style={{ width: '100%', height: '100%', padding: "2rem", display: 'flex', alignItems: 'center', justifyContent:'center'}}>
                                        <h3>
                                            Adicione itens para que sejam mostrados na tabela.
                                        </h3>
                                    </Paper>
                                }
                                <Box style={{ width: '100%', padding: "2rem", display: 'flex', gap: '2rem', justifyContent: 'flex-end' }}>
                                    <Button variant="outlined" style={{fontSize: FONT_SIZE}} color='error' onClick={handleCancel}>
                                        Cancelar
                                    </Button>
                                    <Button variant="contained" style={{fontSize: FONT_SIZE}} color='success' onClick={handleSave}>
                                        Salvar
                                    </Button>
                                </ Box>
                            </Paper>
                    </main>
                </div>
            </div>
        </ThemeProvider>
    )
}