import { BrowserRouter as Route, useParams } from 'react-router-dom';
import { useDarkMode } from '../../../hooks/useDarkMode';
import { useState , State } from '@hookstate/core';
import { Untracked } from '@hookstate/untracked';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Planning } from './components/Planning';
import { Separation } from './components/Separation';
import { Finalization } from './components/Finalization';
import { BugReportModal } from '../../../components/modal/BugReportModal';

export const ProductionUpdate = () =>{
    document.title = "Alteração dos Dados da Produção - MeLiBeat";
    let { view_mode, id } = useParams<any>();
    const OpId: State<string | undefined> = useState<string | undefined>(id)

    const {DarkMode, } = useDarkMode();

    const currentTabIndex = useState<number | undefined>(0);
    currentTabIndex.attach(Untracked);

    const handleChangeTabIndex = (index: number, newId: any) =>{
        currentTabIndex.set(index);
        OpId.set(newId)
    }


    return(
        <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
            <BugReportModal />
            <div className="content-wrapper">
                <main className="main-content container-fluid">
                    <Tabs selectedIndex={currentTabIndex.get()} onSelect={(index: any)=> handleChangeTabIndex(index, OpId.get())}>
                        <TabList>
                            <Tab>Planejamento</Tab>
                            <Tab>Separação</Tab>
                            <Tab>Finalização</Tab>
                        </TabList>
                        <TabPanel>
                            <Planning 
                                itemId={OpId.get()}
                                viewMode={view_mode}
                                handleChangeTabIndex={handleChangeTabIndex}
                            />
                        </TabPanel>

                        <TabPanel>
                            <Separation 
                                itemId={OpId.get()} 
                            />
                        </TabPanel>
                        <TabPanel>
                            <Finalization 
                                itemId={OpId.get()} 
                            />
                        </TabPanel>
                    </Tabs>
                </main>
            </div>
        </div>
    )
}