import Logo from '../../assets/images/logo-min.png';
import LogoTransp from '../../assets/images/logo-2-min.png';
import { useEffect, FormEvent } from 'react';
import { useAuth, } from '../../hooks/useAuth';
import { Redirect, Route } from 'react-router-dom';
import {FiEye, FiEyeOff} from 'react-icons/fi';
import { toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LoginIcon from '@mui/icons-material/Login';
import './style.scss';
import { UserData } from "../../types/Types"
import { useState , State} from '@hookstate/core';

export const Login = () =>{
    document.title = "Login - MeLiBeat";
    const userData: State<UserData> = useState<UserData>({username: '', password: ''});
    const passwordVisible: State<boolean> = useState<boolean>(false);
    const {user,signIn} = useAuth();

    const defaultTheme  = createTheme();

    useEffect(()=>{
        if(user?.token){
            localStorage.setItem('token', user.token)
            localStorage.setItem('userLogged', userData.username.value)
        }
    }, [user])

    const handleAuth = async(e:FormEvent) =>{
        e.preventDefault()
        try{
            if(!user){
                await signIn(userData.username.value, userData.password.value);
            }else{
                if(process.env.REACT_APP_AMBIENT === 'dev'){
                    console.log(user)
                }
            }
        }
        catch (err){
            toast.error("Usuário ou senha inválidos!");
        }
    }
    const changePasswordVisibility = ()=>{
        passwordVisible.set(!passwordVisible.get());
    }


    return(
        <Route exact path="/login">
            {user?.token ? <Redirect from="/login" to={{pathname: '/'}} /> : null}
            <main id="login-container">
                <aside className="left">
                    <div className="image-container">
                        <img src={Logo} alt="MeLiBeat logo" />
                    </div>
                    <h2 id="logo">MeLiBeat</h2>

                    <p>Gerencie seu marketplace de maneira rápida e intuitiva.</p>
                </aside>

                <aside className="right">
                    <header>
                        <div className="image-container">
                            <img src={LogoTransp} alt="MeLiBeat logo" />
                        </div>
                        <h4>MeLiBeat</h4>
                    </header>

                    <section className="login-section">
                        <div className="login-text-container">
                            <h2>LogIn</h2>
                            <p>Realize o LogIn para entrar e acessar nossos serviços.</p>
                        </div>

                        <ThemeProvider theme={defaultTheme}>
                        <Box component="form" onSubmit={handleAuth}>
                            <Box className="input-container">
                                <TextField type='text' label="E-mail" name="username" id="username" variant="standard"
                                    sx={{minWidth: '20rem', width: '100%', fontSize: 'clamp(1.4rem, 1.4vw, 1.8rem)', '& .MuiInputBase-input': {fontSize: 'clamp(1.4rem, 1.4vw, 1.8rem)'}, '& label':{fontSize: 'clamp(1.4rem, 1.4vw, 1.8rem)'}}}
                                    onChange={e => userData.merge({username: e.target.value})}
                                />
                                {/* <label htmlFor="user-email">Seu E-mail</label> */}
                                {/* <input  onChange={e => userData.merge({username: e.target.value})} type="text" name="username" id="username" placeholder="Seu nome de usuário" required={true} /> */}
                            </Box>
                            <Box className="input-container">
                                <TextField type={passwordVisible.get() ? 'text' : 'password'} name="userPassword" id="user-password" label="Senha" variant="standard" 
                                    sx={{minWidth: '20rem', width: '100%', fontSize: 'clamp(1.4rem, 1.4vw, 1.8rem)', '& .MuiInputBase-input': {fontSize: 'clamp(1.4rem, 1.4vw, 1.8rem)'}, '& label':{fontSize: 'clamp(1.4rem, 1.4vw, 1.8rem)'}}}
                                    onChange={e => userData.merge({password: e.target.value})}
                                />
                                {/* <label htmlFor="user-password">Sua Senha</label>
                                <input onChange={e => userData.merge({password: e.target.value})} type={passwordVisible.get() ? 'text' : 'password'} name="userPassword" id="user-password" placeholder="Pelo menos 8 caracteres" required={true} /> */}
                                {
                                    passwordVisible.get() ? <FiEyeOff className="showHidePass" onClick={changePasswordVisibility} /> : <FiEye className="showHidePass" onClick={changePasswordVisibility}/>
                                }
                            </Box>

                            <Box className="input-container">
                                <Button variant="contained" color='primary' startIcon={<LoginIcon />} sx={{fontSize: 'clamp(1.4rem, 1.4vw, 1.8rem)', width: '100%', '& .MuiButton-startIcon':{margin: '0'}}} type='submit'>
                                    Login
                                </Button>
                            </Box>
                        </Box>
                        </ThemeProvider>

                        <div className="form-footer">
                            <p>Não tem uma conta? Converse com seu administrador para que novos usuários sejam criados.</p>
                        </div>
                    </section>
                </aside>
            </main>
        </Route>
    )
}