import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import moment from 'moment';


export const columns = [
    { 
      headerName: "Data",
      field: "date", 
      type: 'date',
      headerAlign: 'center',
      flex: 0.5,
      disableColumnMenu: true,
      renderCell: (rowData: any) => {
        return (
          <span>{moment(rowData.row.date).format("DD/MM/YYYY")}</span>
        )
      }
    },
  {
    headerName: "Descrição",
    field: "description",
    headerAlign: 'center',
    disableColumnMenu: true,
    flex: 1,
    renderCell: (rowData: any) => {
      return (
        <Tippy content={rowData.row.description} maxWidth={'30rem'}>
          <main style={{textOverflow: "ellipsis", overflow: 'hidden', whiteSpace:"nowrap"}}>
          <span>{rowData.row.description}</span>
          </main>
        </Tippy>
      )
    }
  },
  {
    headerName: "Produtos",
    field: "products",
    headerAlign: 'center',
    disableColumnMenu: true,
    sortable: false, 
    flex: 2,
    renderCell: (rowData: any) => {
        const products = rowData.row.products.map((item: any, index: any)=>{
            return <li key={index}>{item.title}</li>
        })
        const productsList = rowData.row.products.map((item: any, index: any)=>{
            return item.title
        })
        return (
          <Tippy content={<ul style={{display: 'flex', flexDirection: "column", gap: '1rem'}}>{products}</ul>} maxWidth={'none'}>
            <main style={{textOverflow: "ellipsis", overflow: 'hidden', whiteSpace:"nowrap"}}>
              <span title={productsList.toString().replaceAll(",", ", ")}>{productsList.toString().replaceAll(",", ", ")}</span>
            </main>
          </Tippy>
        )
    }
    
  }
]

export const options = {
  palette:{
    tableHeader: {
      main: '#F7CE3E',
      dark: '#ac902b',
      light: '#f8d764',
      contrastText: '#000'
    },
  },
  alternate: true,
}