import './style.scss';

export const YoutubePlayer = (props: any) =>{

    return(
        <div className="youtube-player">
            <iframe 
                width="100%" 
                height="100%" 
                src={`https://www.youtube.com/embed/${props.embedId}`} 
                title="YouTube video player" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen
            >
            </iframe>
      </div>
    )
}