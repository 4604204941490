//Componentes
import { Login } from './pages/Login/Login';
import { Lcre } from './pages/Financeiro/Lcre/Lcre';
import { ManagementLcre } from './pages/Financeiro/managementLcre/ManagementLcre';
import { DashBoard } from './pages/dashboard/DashBoard';
import { Home } from './pages/Home/Home';
import { Settings } from './pages/settings/Settings';
import { KPITable } from './pages/Vendas/KPI-de-Vendas/KPITable';
import { CPRTable } from './pages/Compras/CPR/CPRTable';
import { SKUViewer } from './pages/Vendas/KpiBySku/SKUViewer';
import { CadastroAgrupamentos } from './pages/Cadastros/CadastroAgrupamentos/CadastroAgrupamentos';
import { PrecificationAuto } from './pages/Cadastros/CadastroPrecificador/PrecificationAuto';
import { ShoppingList } from './pages/Compras/ShoppingList/ShoppingList';
import { ShoppingListAll } from './pages/Compras/ShoppingListAll/ShoppingListAll';
import { AdsManagement } from './pages/MercadoLivre/AdsManagement/AdsManagement';
import { SellingPriceFormulation } from './pages/Vendas/SellingPriceFormulation/SellingPriceFormulation';
import { CreditSuppliers } from './pages/Financeiro/CreditSuppliers/CreditSuppliers';
import { PurchaseTracking } from './pages/Compras/AcompanhamentoCompras/PurchaseTracking';
import { Catalog } from './pages/MercadoLivre/Catalog/Catalog';
import { ShippingRelation } from './pages/Fulfillment/ShippingRelation/ShippingRelation';
import { ShippingUpdate } from './pages/Fulfillment/ShippingUpdate/ShippingUpdate';
import { FullReplacement } from './pages/Fulfillment/FullReplacement/FullReplacement';
import { AdsMetrics } from './pages/Vendas/AdsMetrics/AdsMetrics';
import { ABCCurve } from './pages/Vendas/ABCCurve/ABCCurve';
import { AdsQualityControl } from './pages/MercadoLivre/AdsQualityControl/AdsQualityControl';
import { TutorialPage } from './pages/TutorialPage/TutorialPage';
import { SerialNumber } from './pages/Compras/SerialNumber/SerialNumber';
import { SerialNumberCompras } from './pages/Compras/SerialNumberCompras/SerialNumberCompras';

import { ErrorBoundary } from './ErrorBoundary';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { AuthContextProvider } from './contexts/AuthContext';
import { ModalContextProvider } from './contexts/ModalContext';
import { DarkModeContextProvider } from './contexts/DarkModeContext';
import { BugReportContextProvider } from './contexts/BugReportContext';
import { ShippmentsAnalysis } from './pages/Fulfillment/ShippmentsAnalysis/ShippmentAnalysis';
import { CostCenters } from './pages/Cadastros/CostCenters/CostCenters';
import { CostCenterManagement } from './pages/Cadastros/CostCenterManagement/CostCenterManagement';
import { GroupSuppliers } from './pages/Cadastros/CadastroGrupoFornecedores/GroupSuppliers';
import { GroupSuppliersManagement } from './pages/Cadastros/GroupSuppliersManagement/GroupSuppliersManagement';
import { AverageCostManagement } from './pages/Compras/AverageCostManagement/AverageCostManagement';
import { FullReplacementPC } from './pages/Fulfillment/FullReplacementPC/FullReplacementPC';
import { DebtsToPay } from './pages/Financeiro/DebtsToPay/DebtsToPay';
import { AdsPrecification } from './pages/MercadoLivre/AdsPrecification/AdsPrecification';
import { KPIDay } from './pages/Vendas/KPI-Day/KPIDay';
import { KPIDayTP } from './pages/Vendas/KPI-Day-Tp/KPIDay';
import { ProductionOrders } from './pages/Producao/ProductionOrders/ProductionOrders';
import { ProductionUpdate } from './pages/Producao/ProductionUpdate/ProductionUpdate';
import { ActiveDaysHistory } from './pages/MercadoLivre/ActiveDaysHistory/ActiveDaysHistory';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Header } from './components/Header/Header';
import { SideMenu } from './components/sideMenu/SideMenu';

function App() {
  const token: string | boolean = localStorage.getItem('token') || false;
  return (
    <>    
    <BrowserRouter>
      <BugReportContextProvider>
        <DarkModeContextProvider>
          <Header />
          <SideMenu />
          <ToastContainer style={{fontSize: "1.5rem"}}
            position= "bottom-right"
            autoClose={4000}
            hideProgressBar={true}
            closeOnClick={true}
            pauseOnHover={true}
            draggable={true}
          />
          <AuthContextProvider>
          {
            token ?
            null
            :
            <Redirect from="/" to={{pathname: '/login'}} />
          }
            <Switch>
              <ErrorBoundary>
                <Route path="/login" exact component={Login} />
                  <Route path="/tutoriais" exact component={TutorialPage} />
                  <Route path="/vendas/visualizar-sku/:group_sku?/:id?" exact component={SKUViewer} />
                  <ModalContextProvider>
                    <Route path="/" exact component={Home} />
                    <Route path="/dashboard/" exact component={DashBoard} />

                    <Route path="/compras/lista-de-compras/:id?" exact component={ShoppingList} />
                    <Route path="/compras/numeros-de-serie-compras/:id?" exact component={SerialNumberCompras} />
                    <Route path="/compras/numeros-de-serie" exact component={SerialNumber} />
                    <Route path="/vendas/metricas-anuncios" exact component={AdsMetrics} />
                    <Route path="/vendas/curva-abc" exact component={ABCCurve} />
                    <Route path="/mercado-livre/controle-de-qualidade-dos-anuncios" exact component={AdsQualityControl} />
                    <Route path="/mercado-livre/precificacao-dos-anuncios" exact component={AdsPrecification} />

                    <Route path="/compras/gestao-custo-medio" exact component={AverageCostManagement} />
                    <Route path="/cadastros/grupo-fornecedores/:id?" exact component={GroupSuppliers} />
                    <Route path="/cadastros/gestao-grupo-fornecedores" exact component={GroupSuppliersManagement} />
                    <Route path="/cadastros/gestão-de-centros-de-custo" exact component={CostCenterManagement} />
                    <Route path="/cadastros/centros-de-custo/:id?" exact component={CostCenters} />
                    <Route path="/cadastro/cadastro-agrupamentos-skus" exact component={CadastroAgrupamentos} />
                    <Route path="/cadastro/cadastro-precificador" exact component={PrecificationAuto} />

                    <Route path="/fulfillment/analise-envios/:id" exact component={ShippmentsAnalysis} />
                    <Route path="/fulfillment/reposicao-full-pcs" exact component={FullReplacementPC} />
                    <Route path="/fulfillment/reposicao-full" exact component={FullReplacement} />
                    <Route path="/fulfillment/alteracao-envio/:id?" exact component={ShippingUpdate} />
                    <Route path="/fulfillment/relacao-envios" exact component={ShippingRelation} />

                    <Route path="/compras/acompanhamento-de-compras" exact component={PurchaseTracking} />
                    <Route path="/compras/todas-as-listas-de-compras/" exact component={ShoppingListAll} />
                    <Route path="/compras/analise-de-compras" exact component={CPRTable} />

                    <Route path="/settings" exact component={Settings} />

                    <Route path="/mercado-livre/catalogo/" exact component={Catalog} />
                    <Route path="/mercado-livre/gestao-de-anuncios" exact component={AdsManagement} />
                    <Route path="/vendas/kpi-de-vendas" exact component={KPITable} />
                    <Route path="/vendas/kpi-de-vendas-por-dia" exact component={KPIDay} />
                    <Route path="/vendas/kpi-de-vendas-por-dia-tp" exact component={KPIDayTP} />
                    <Route path="/vendas/formulacao-preco-venda/" exact component={SellingPriceFormulation} />

                    <Route path="/financeiro/credito-fornecedores/" exact component={CreditSuppliers} />
                    <Route path="/financeiro/gestao-lcre" exact component={ManagementLcre} />
                    <Route path="/financeiro/tabela-lcre" exact component={Lcre} />
                    <Route path="/financeiro/contas-a-pagar" exact component={DebtsToPay} />

                    <Route path="/producao/ordens-de-producao" exact component={ProductionOrders} />
                    <Route path="/producao/alteracao-de-producao/:view_mode?/:id?" exact component={ProductionUpdate} />

                    <Route path="/mercado-livre/historico-dias-ativos" exact component={ActiveDaysHistory} />

                  </ModalContextProvider>
              </ErrorBoundary>
            </Switch>
          </AuthContextProvider>
        </ DarkModeContextProvider>
      </BugReportContextProvider>
    </BrowserRouter>
    </>
  );
}

export default App;
