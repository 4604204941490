import { Filters } from '../../../components/Filters/Filters';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import React from 'react';
import moment from 'moment';
import { GridModal } from '../../../components/modal/GridModal';
import { PopOverMenuModal } from '../../../components/modal/PopOverMenuModal';
import { useHistory } from 'react-router';
import { useDarkMode } from '../../../hooks/useDarkMode';
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { 
    ISerialNumbers,
    ObjetoVazio,
    ProductsSerialNumbers,
} from "../../../types/Types";
import TextField from '@mui/material/TextField';

import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import { Loading } from '../../../components/Loading/Loading';
import noData from '../../../assets/images/nodata.svg';
import { BugReportModal } from '../../../components/modal/BugReportModal';

const INPUT_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.6rem)';



export const SerialNumber = () =>{
    document.title = "Números de série - MeLiBeat";
    const handleValidateStatus = useHandleErrors();
    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const fetchResource = (query:any=undefined) => axs.get<ISerialNumbers[]>('/purchasetracking/serialnumber', {params: query}).then((r: any) => {
        handleValidateStatus(r)
        const processedData = r.data.map((item: any)=> {
            if(item.finishedEntrySerial) item["status"] = "Finalizado"
            else item["status"] = "Pendente"
            return item
        })
        if(processedData.length <= 0){
            toast.error('Ocorreu um erro ao realizar a solicitação. Nenhum dado obtido.')
        }
        return processedData;
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    })
    const data: State<ISerialNumbers[]> = useState<ISerialNumbers[]>([]);
    const showModal: State<boolean> = useState<boolean>(false);
    const showPopOverMenuModal: State<boolean> = useState<boolean>(false);
    const menuPosition: State<object> = useState<object>({'x': Number, 'y': Number});
    const modalData: State<ProductsSerialNumbers[]> = useState<ProductsSerialNumbers[]>([]);
    const lastIdClicked: State<string | undefined> = useState<string | undefined>('');
    const idIsFinished: State<boolean | undefined> = useState<boolean | undefined>(false);
    const history = useHistory();
    const screenFilter: State<string | undefined> = useState<string | undefined>(undefined);
    const {DarkMode, } = useDarkMode();
    const filterValues: ObjetoVazio = useState<ObjetoVazio>({'filter': undefined})


    const columns = [
        { 
            headerName: "Data Emissão", 
            field: "date", 
            type: 'date',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) => {
                return (
                    <span>{moment(rowData.row.date).format("DD/MM/yyyy")}</span>
                )
            }
        },
        { 
            headerName: "Data Entrega", 
            field: "finishedDate", 
            type: 'date',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) => {
                return (
                    <span>{moment(rowData.finishedDate).format("DD/MM/yyyy")}</span>
                )
            }
        },
        { 
            headerName: "Status",
            field: "status", 
            type: 'singleSelect',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            valueOptions: ["Finalizado", "Pendente"],
        },
        { 
            headerName: "Nota Fiscal", 
            field: "number", 
            type: 'string',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) => {
                return (
                    <span>{rowData.row.number}</span>
                )
            }
        },
        { 
            headerName: "Fornecedor",
            field: "sender", 
            type: 'string',
            headerAlign: 'center',
            flex: 2,
            minWidth: 200,
            renderCell: (rowData: any) => {
                return (
                    <Tippy content={rowData.row.sender} interactive={true} maxWidth={'fit-content'}>
                        <main style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
                            <span title={rowData.row.sender}>{rowData.row.sender}</span>
                        </main>
                    </Tippy>
                )
            }
        },
        { 
            headerName: "CNPJ", 
            field: "cnpjSender", 
            type: "string",
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) => {
                return (
                    <span>{rowData.row.cnpjSender}</span>
                )
            }
        },
        { 
            headerName: "Volumes",
            field: "volume", 
            type: 'number',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) => {
                return (
                    <span>{rowData.row.volume}</span>
                )
            }
        },
        { 
            headerName: "Data Finalizado",
            field: "finishedEntrySerialDate", 
            type: 'date',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) => {
                return (
                    <span>{
                        rowData.row.finishedEntrySerial ?
                            moment(rowData.row.finishedEntrySerialDate).format("DD/MM/yyyy")
                        :
                            null
                    }</span>
                )
            }
        },
    ]

    const options = {
        palette:{
          tableHeader: {
            main: '#F7CE3E',
            dark: '#ac902b',
            light: '#f8d764',
            contrastText: '#000'
          },
        },
        rowStyle: (rowData: any) => {
            let row_class = '';
            if(rowData.finishedEntrySerial) row_class = "serialNumber-finishedEntrySerial";
            return row_class
        },
      }

      const optionsModal = {
        palette:{
          tableHeader: {
            main: '#F7CE3E',
            dark: '#ac902b',
            light: '#f8d764',
            contrastText: '#000'
          },
        },
        rowStyle: (rowData: any) => {
            let row_class = '';
            if(rowData.product.includes(screenFilter.get()) || (rowData.serialNumber && rowData.serialNumber.toUpperCase().includes(screenFilter.get()))) row_class = "serialNumber-filteredRows";
            return row_class
        },
      }
    
    const modalColumns = [
        { 
            headerName: "Produto",
            field: "product", 
            type: 'string',
            headerAlign: 'center',
            flex: 5,
            renderCell: (rowData: any) => {
                return (
                    <Tippy content={rowData.row.product}>
                        <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            <span>{rowData.row.product}</span>
                        </main>
                    </Tippy>
                )
            }
        },
        { 
            headerName: "Número de Série",
            field: "serialNumber", 
            type: 'string',
            headerAlign: 'center',
            flex: 2,
            renderCell: (rowData: any) => {
                // const serialNumberArray = rowData.row.serialNumber ? rowData.row.serialNumber.split(' ').map((item: any, index: any)=>{
                //     return <li>{item}</li>
                //   })
                //   :
                //   rowData.row.serialNumber
                return (
                    <main style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
                        <span title={rowData.row.serialNumber ? rowData.row.serialNumber.replaceAll(' ', ', ') : ''}>{rowData.row.serialNumber ? rowData.row.serialNumber.replaceAll(' ', ', ') : rowData.row.serialNumber}</span>
                    </main>
                )
            }
        },
        { 
            headerName: "NCM",
            field: "ncm", 
            type: 'string',
            headerAlign: 'center',
            flex: 1,
            renderCell: (rowData: any) => {
                return (
                    <span>{
                        rowData.row.ncm ?
                        rowData.row.ncm
                        :
                        "N/A"
                    }</span>
                )
            }
        },
        { 
            headerName: "Origem",
            field: "origin", 
            type: 'string',
            headerAlign: 'center',
            flex: 1,
            renderCell: (rowData: any) => {
                return (
                    <span>{
                        rowData.row.origin ?
                        rowData.row.origin
                        :
                        "N/A"
                    }</span>
                )
            }
        },
        { 
            headerName: "Qtd",
            field: "qty", 
            type: 'number',
            headerAlign: 'center',
            flex: 1,
            renderCell: (rowData: any) => {
                return (
                    <span>{rowData.row.qty}</span>
                )
            }
        },
        { 
            headerName: "Valor",
            field: "cost", 
            type: 'number',
            headerAlign: 'center',
            flex: 1,
            renderCell: (rowData: any) => {
                return (
                    <span>{rowData.row.cost.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                )
            }
        },
        { 
            headerName: "Valor com IPI",
            field: "costWithIpi", 
            type: 'number',
            headerAlign: 'center',
            flex: 1,
            renderCell: (rowData: any) => {
                return (
                    <span>{rowData.row.costWithIpi.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} ({rowData.row.ipi}%)</span>
                )
            }
        },
        { 
            headerName: "Valor Total",
            field: "total", 
            type: 'number',
            headerAlign: 'center',
            flex: 1,
            renderCell: (rowData: any) => {
                return (
                    <span>{(rowData.row.costWithIpi * rowData.row.qty).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                )
            }
        },
    ]

    useEffect(() => {
        data.set(fetchResource())
    }, [])

    const handleModal = (event: MouseEvent, value: ISerialNumbers)=>{
        lastIdClicked.set(value._id);
        idIsFinished.set(value.finishedEntrySerial);
        menuPosition.set({x: (event.pageX-180), y: event.pageY})
        modalData.set(value.products);
        showPopOverMenuModal.set(true);
    }

    const handleFilter = (filters: any)=>{
        toast.info('Filtrando...')
        showPopOverMenuModal.set(false);
        screenFilter.set(filters.SerialNumber);
        data.set(fetchResource({'filter': filters.SerialNumber}))
    }

    const handleReset = () => {
        screenFilter.set(undefined);
        filterValues.merge({'SerialNumber': undefined})
        data.set(fetchResource());
    }


    return(
        <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
            {
                showModal.get() === true
                ?<GridModal 
                    closeFunction={()=> {
                        showModal.set(false);
                    }} 
                    tableTitle={`Produtos`} 
                    alternateColor="alternate-2"
                    options={optionsModal} 
                    columns={modalColumns} 
                    data={modalData.attach(Downgraded).get()} 
                    style={{width: '80%'}}
                    defaultSort={{field: 'total', direction: 'desc'}}
                    modalWrapperStyle={{alignItems: 'unset'}}
                />
                : null
            }

            {
                showPopOverMenuModal.get() ?
                <PopOverMenuModal position={menuPosition.attach(Downgraded).get()} closeFunction={(evt: React.BaseSyntheticEvent)=> showPopOverMenuModal.set(false)}>
                    <button onClick={(evt: React.BaseSyntheticEvent)=>{
                        showPopOverMenuModal.set(false);
                        showModal.set(true)
                    }}
                    >Visualizar</button>

                    <button onClick={(evt: React.BaseSyntheticEvent)=>{
                        history.push(`/compras/numeros-de-serie-compras/${lastIdClicked.get()}`)
                    }}
                    disabled={idIsFinished.get()}
                    >Editar</button>
                </PopOverMenuModal>
                : null
            }
            <BugReportModal />
            <div className="content-wrapper">
                <main className="main-content container-fluid">
                    <Filters submitFunction={handleFilter} hideFullResetBtn handleReset={handleReset}>
                        <TextField size="small" required name="SerialNumber" label="Número de série / Produto" variant="outlined" value={filterValues.SerialNumber.get()}  onChange={(evt: any)=> filterValues.SerialNumber.set(evt.target.value)} 
                            sx={{minWidth: '25rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                        />
                    </ Filters> 
                    {
                        data.promised ? 
                        <Loading />
                        : data.error ?
                        <ErrorScreen /> : 
                        data.length > 0 ?
                        <VirtualizedGrid 
                            rows={data.attach(Downgraded).get()}
                            columns={columns}
                            options={options}
                            title="Números de Série"
                            actions={[
                                {icon: 'settings', title: 'Mais Ações', method: (event: any, row:any)=> handleModal(event, row)}, 
                            ]}
                            defaultSort={{field: 'date', direction: 'desc'}}

                        />
                        :
                        <div className="img-container">
                            <img src={noData} alt="Não foi possível renderizar o gráfico por falta de dados." />
                            <h4>Não foi possível renderizar os gráficos por falta de dados.</h4>
                        </div>
                    }
                    
                </main>
            </div>
        </div>
    )
}