import {FiZapOff, FiTrendingDown, FiPauseCircle, FiAlertCircle, FiExternalLink} from 'react-icons/fi';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import placeholder from '../../../assets/images/placeholder.jpg';
import 'react-tippy/dist/tippy.css';
import {Tooltip} from 'react-tippy';
import { 
  GridFilterItem,
} from '@mui/x-data-grid';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Link from '@mui/material/Link';

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const defaultTheme  = createTheme();

const tooltipHeaderValues = {
  "Vendas-Período": "Total de Vendas durante o período selecionado (histórico de dias)",
  "Venda-Estimada": "Cálculo Realizado com base nas Vendas do Período e calculando projeção com base nos dias ativos de cada anúncio",
  "Lucro-Estimado": "Cálculo Realizado à partir da projeção de vendas e do lucro médio.",
  "Origem": "Determinado pela regra do SKU, utilizado para separação e indicação da Origem (Comprado, parceria Fornecedor).",
  "SKU": "Código interno do produto",
  "Tags": `Ícones para Rápida identificação de situações do produto.`,
  "Geral": "Estoque Geral (Quantitativo)",
  "FULL": "Estoque Fulfillment (Quantitativo)",
  "ETP": "Estoque Em Transporte (Quantitativo)",
  "Margem-Lucro-Médio": " Calculo baseado no histórico de vendas.",
  "Duração-Estoque": "Tempo de Duração do Estoque em Dias",
  "Duração-Estoque-Full": "Tempo de Duração do Estoque do Fulfillment em Dias."
}

const StyledPopper = styled(Popper)({
  "& .MuiAutocomplete-listbox": {
    '& li':{
      fontSize: 'clamp(1.1rem, 1vw, 1.3rem)',
    }
  }
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function CheckboxesTags(props: any) {
  const { item, applyValue, options:tag_options } = props;
  // const selected_values = item.value ? item.value.map((elm: any)=> elm.title) : [];

  const handleFilterChange = (event: any, value: any) => {
    applyValue({ ...item, value: value });
  };

  return (
    <Autocomplete
      multiple
      options={tag_options}
      disableCloseOnSelect
      getOptionLabel={(option: any) => option.title}
      defaultValue={item.value}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
            style={{ marginRight: 8 }}
            // checked={selected_values.includes(option.title)}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label="Tags" variant="standard" sx={{'& .MuiChip-root':{fontSize: 'clamp(1rem, 1.1vw, 1.2rem)'}}} />
      )}
      //PopperComponent={StyledPopper}
      onChange={handleFilterChange}
    />
  );
}

const checkVisibility = (column: string)=>{
  const storageColumnsData = localStorage.getItem('Análise de Vendas') ? localStorage.getItem('Análise de Vendas') : null;
  if(storageColumnsData !== null){
    const localData = JSON.parse(storageColumnsData);
    const shouldHide = localData.find((item: any)=> item.field === column);
    return shouldHide !== undefined ? !shouldHide.visibility : false
  }
  return false
}


export const columns = [
    { 
      headerName: "SKU", 
      renderHeader: (params: any) => (
        <Tippy content={<div>{tooltipHeaderValues.SKU}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">SKU</div></Tippy>
      ),
      field: "_id", 
      type: 'string',
      headerAlign: 'center',
      flex: 1,
      minWidth: 60,
      hide: checkVisibility('_id'),
      renderCell: (rowData: any) => {
        const idUrl = rowData.row.group_skus ? rowData.row.group_id : rowData.row._id;
        return (
          <ThemeProvider theme={defaultTheme}>
            <div title={rowData.row._id}>
              <Link 
                href={`/vendas/visualizar-sku/${rowData.row.group_skus}/${idUrl}`} 
                target="_blank" rel="noreferrer" 
                color={'inherit'} 
                sx={{'&:hover':{color: defaultTheme.palette.primary.dark}}} 
              >
                <Tippy content={<div>{rowData.row._id}</div>} interactive={true} maxWidth={'none'}>
                  <div style={{fontSize: 'clamp(1.2rem, 1vw, 1.3rem)', display: 'flex', alignItems: 'center', gap: '5px', textDecoration: 'none'}}>
                    <div style={{width: '6rem', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>{rowData.row._id}</div><FiExternalLink />
                  </div>
                </Tippy>
              </Link>
            </div>
          </ThemeProvider>
        )
      }
    },
    { 
      headerName: "Origem", 
      renderHeader: (params: any) => (
        <Tippy content={<div>{tooltipHeaderValues.Origem}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">Origem</div></Tippy>
      ),
      field: "origin", 
      type: 'singleSelect',
      headerAlign: 'center',
      flex: 0.5,
      minWidth: 50,
      valueOptions:["RM", "TC"],
      hide: checkVisibility('origin'),
    },
    {
      headerName: "Imagem",
      field: "photo",
      headerAlign: 'center',
      flex: 0.5,
      minWidth: 50,
      hide: checkVisibility('photo'),
      renderCell: (rowData: any) =>{
        return(
          <div className="img" style={{width: 30, height: 30, borderRadius: '50%', display: 'flex'}}>
              <img src={rowData.row.photo !== "" ? rowData.row.photo : placeholder} alt="Imagem do produto" style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 'inherit'}}/>
          </div>
        )
      }
    },
  {
    headerName: "Produto",
    field: "title",
    type: "string",
    headerAlign: 'center',
    flex: 2,
    minWidth: 150,
    hide: checkVisibility('title'),
    renderCell: (rowData: any) => {
      const linkArray = rowData.row.cods.map((item: any, index: any)=>{
        return <a className="defaultLink" key={index} href={rowData.row.links[index]} target="_blank" rel="noreferrer" style={{fontSize: 'clamp(1.1rem, 1vw, 1.2rem)'}}>{item}</a>
      })
      return (
        <>
         <Tippy content={<div style={{display: 'flex', gap: '1rem'}}>{linkArray}</div>} interactive={true} maxWidth={'none'}>
           <main style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
              <span title={rowData.row.title}>{rowData.row.title}</span>
            </main>
          </Tippy>
        </>
      )
    }
  },
  {
    headerName: "Tags",
    renderHeader: (params: any) => (
      <Tippy content={<div>{tooltipHeaderValues.Tags}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">Tags</div></Tippy>
    ),
    field: "tags_filter",
    type: 'string',
    headerAlign: 'center',
    flex: 1,
    minWidth: 50,
    hide: checkVisibility('tags'),
    sortable:false,
    filterOperators: [{
      value: 'contains',
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        const selected_values = filterItem.value ? filterItem.value.map((item: any)=> item.title) : [];
        if (filterItem.value == null || selected_values === []) {
          return null;
        }
  
        return ({ value }:{ value: any }): boolean => {
          if (typeof value === 'object') {
            return selected_values.every((item: any)=> value.includes(item))
          }
          return selected_values.every((item: any)=> value.includes(item))
        };
      },
      // InputComponent: GridFilterInputValue,
      InputComponent: CheckboxesTags,
      InputComponentProps: { type: 'singleSelect', options: [
        {title: 'Com Bling'}, 
        {title: 'Sem Bling'}, 
        {title: 'Fulfillment (Sim)'}, 
        {title: 'Fulfillment (Não)'},
        {title: 'Com Histórico'},
        {title: 'Sem Histórico'},
        {title: 'MLBs Não Pausados'},
        {title: 'MLBs Pausados'},
      ]},
    }],
    valueOptions:[
      "Com Bling", 'Sem Bling', 
      "Fulfillment (Sim)", "Fulfillment (Não)", 
      "Com Histórico", "Sem Histórico", 
      "MLBs Não Pausados", "MLBs Pausados"
    ],
    renderCell: (rowData: any) => {
      const tagColors: any = {
        'tags': 
        [
            {'title': rowData.row.tags_obj.only_fulfilment ? 'Fulfillment (Sim)':'Fulfillment (Não)', 'value': <FiZapOff className="icon" />, 'color': (rowData.row.tags_obj.only_fulfilment ? '#706fd3':'#a5b1c2')},
            {'title': rowData.row.tags_obj.without_history ? 'Sem Histórico':'Com Histórico', 'value': <FiTrendingDown className="icon" />, 'color': (rowData.row.tags_obj.without_history ? '#227093':'#a5b1c2')},
            {'title': rowData.row.tags_obj.all_paused ? 'MLBs Pausados':'MLBs Não Pausados', 'value': <FiPauseCircle className="icon" />, 'color': (rowData.row.tags_obj.all_paused ? '#b33939':'#a5b1c2')},
            {'title': rowData.row.tags_obj.without_bling ? 'Sem Bling':'Com Bling', 'value': <FiAlertCircle className="icon" />, 'color': (rowData.row.tags_obj.without_bling ? '#218c74':'#a5b1c2')},
        ]
      };
      return (
        <div className="tags-container" style={{marginTop: 0, display: 'flex', flexWrap: 'nowrap', gap: '5px'}}>
          {
            Object.values(tagColors.tags).map((item: any, index: any)=>{
              return(
                <Tippy key={index} content={item.title} maxWidth={'25rem'}>
                  <span className="tag-small" style={{color: item.color}}>{item.value}</span>
                </Tippy>
              )
            })
          }
        </div>
      )
    }
  },
  {
    headerName: "Marca",
    field: "brand", 
    type: 'string',
    headerAlign: 'center',
    flex: 0.5,
    minWidth: 50,
    hide: checkVisibility('brand'),
    renderCell: (rowData: any) => {
      const brands = rowData.row.brand.length > 0 ? rowData.row.brand.toString().replace(',', ', ') : '';
      return (
        <Tippy content={<div style={{display: 'flex', gap: '1rem'}}>{brands}</div>} interactive={true} maxWidth={'none'}>
           <main style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
              <span>{brands}</span>
            </main>
          </Tippy>
      )
    } 
  },
  {
    headerName: "Geral",
    renderHeader: (params: any) => (
      <Tippy content={<div>{tooltipHeaderValues.Geral}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">Geral</div></Tippy>
    ),
    field: "stockAll", 
    type: 'number',
    headerAlign: 'center',
    flex: 0.5,
    minWidth: 50,
    hide: checkVisibility('stockAll'),
    renderCell: (rowData: any) => {
      return (
          rowData.row.group_skus ?
          rowData.row.stockAllDetail.length > 0 ?
            <Tooltip
                position="top"
                trigger="click"
                interactive={true}
                html={
                    rowData.row.stockAllDetail.map((item: any, index: any)=>{
                        return(
                        <div key={index} style={{width: "fit-content", fontSize: "clamp(1.2rem, 1.4vw, 1.5rem)"}}>
                            <strong>SKU:</strong>
                            {` `}
                            {item.sku}
                            {` - `}
                            <strong>Estoque:</strong>
                            {` `}
                            {item.stock}
                        </div>
                        )
                    })}
                >
                <div style={{width: 'fit-content'}}>{rowData.row.stockAll}</div>
            </Tooltip>
            :
            <Tooltip
                position="top"
                trigger="click"
                interactive={true}
                html={
                    <div style={{width: "fit-content", fontSize: "clamp(1.2rem, 1.4vw, 1.5rem)"}}>
                        <strong>SKU: N/A</strong>
                        {` - `}
                        <strong>Estoque: N/A</strong>
                    </div>
                }
                >
                <div style={{width: 'fit-content'}}>{rowData.row.stockAll ? rowData.row.stockAll : 0}</div>
            </Tooltip>
        :
        <Tippy 
        content={Object.entries(rowData.row.stockAllDetail).map((item: any, index: any)=>{
            return(
            <div key={index}>{`${item[0]}: ${item[1]}`}</div>
            )
        })}
        >
            <div style={{width: 'fit-content'}}>{rowData.row.stockAll ? rowData.row.stockAll : 0}</div>
        </Tippy>
      )
    }
  },
  {
    headerName: "Full",
    renderHeader: (params: any) => (
      <Tippy content={<div>{tooltipHeaderValues.FULL}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">Full</div></Tippy>
    ),
    field: "stockFull", 
    hide: checkVisibility('stockFull'),
    type: 'number', 
    headerAlign: 'center',
    flex: 0.5,
    minWidth: 50,
    renderCell: (rowData: any) => {
      return (
        rowData.row.group_skus ?
        rowData.row.stockFullDetail.length > 0 ?
          <Tooltip
            position="top"
            trigger="click"
            interactive={true}
            html={
                rowData.row.stockFullDetail.map((item: any, index: any)=>{
                    return(
                    <div key={index} style={{width: "fit-content", fontSize: "clamp(1.2rem, 1.4vw, 1.5rem)"}}>
                        <strong>SKU:</strong>
                        {` `}
                        {item.sku}
                        {` - `}
                        <strong>Estoque:</strong>
                        {` `}
                        {item.stock}
                    </div>
                    )
                })}
            >
            <div style={{width: 'fit-content'}}>{rowData.row.stockFull}</div>
          </Tooltip>
          :
          <Tooltip
              position="top"
              trigger="click"
              interactive={true}
              html={
                      <div style={{width: "fit-content", fontSize: "clamp(1.2rem, 1.4vw, 1.5rem)"}}>
                          <strong>SKU: N/A</strong>
                          {` - `}
                          <strong>Estoque: N/A</strong>
                      </div>
                  }
              >
              <div style={{width: 'fit-content'}}>{rowData.row.stockFull ? rowData.row.stockFull : 0}</div>
          </Tooltip>
        :
        <Tippy 
        content={Object.entries(rowData.row.stockFullDetail).map((item: any, index: any)=>{
          return(
          <div key={index}>{`${item[0]}: ${item[1]}`}</div>
          )
        })}
        >
          <div style={{width: 'fit-content'}}>{rowData.row.stockFull ? rowData.row.stockFull : 0}</div>
        </Tippy>
      )
    }
  },
  {
    headerName: "ETP",
    renderHeader: (params: any) => (
      <Tippy content={<div>{tooltipHeaderValues.ETP}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">ETP</div></Tippy>
    ),
    field: "stockETP", 
    hide: checkVisibility('stockETP'),
    type: 'number', 
    headerAlign: 'center',
    flex: 0.5,
    minWidth: 50,
    renderCell: (rowData: any) => {
        return (
            rowData.row.group_skus ?
                rowData.row.stockETPDetail.length > 0 ?
                <Tooltip
                    position="top"
                    trigger="click"
                    interactive={true}
                    html={
                        rowData.row.stockETPDetail.map((item: any, index: any)=>{
                            return(
                            <div key={index} style={{width: "fit-content", fontSize: "clamp(1.2rem, 1.4vw, 1.5rem)"}}>
                                <strong>SKU:</strong>
                                {` `}
                                {item.sku}
                                {` - `}
                                <strong>Estoque:</strong>
                                {` `}
                                {item.stock}
                            </div>
                            )
                        })}
                    >
                    <div style={{width: 'fit-content'}}>{rowData.row.stockETP ? rowData.row.stockETP : 0}</div>
                </Tooltip>
                :
                <Tooltip
                    position="top"
                    trigger="click"
                    interactive={true}
                    html={
                        <div style={{width: "fit-content", fontSize: "clamp(1.2rem, 1.4vw, 1.5rem)"}}>
                            <strong>SKU: N/A</strong>
                            {` - `}
                            <strong>Estoque: N/A</strong>
                        </div>
                    }
                    >
                    <div style={{width: 'fit-content'}}>{rowData.row.stockETP}</div>
                </Tooltip>
            :
            <Tippy 
        content={Object.entries(rowData.row.stockETPDetail).map((item: any, index: any)=>{
            return(
            <div key={index}>{`${item[0]}: ${item[1]}`}</div>
            )
        })}
        >
            <div style={{width: 'fit-content'}}>{rowData.row.stockETP ? rowData.row.stockETP : 0}</div>
        </Tippy>
        )
    }
  },
  {
    headerName: "Margem Média",
    renderHeader: (params: any) => (
      <Tippy content={<div>{tooltipHeaderValues['Margem-Lucro-Médio']}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">Margem Média</div></Tippy>
    ), 
    field: "margin_avg", 
    type: 'number',
    headerAlign: 'center',
    hide: checkVisibility('margin_avg'),
    flex: 0.5,
    minWidth: 80,
    // defaultSort: 'asc',
    renderCell: (rowData: any) => {
      let margin = (rowData.row.margin_avg * 100).toFixed(2);
      return (
        <span>
          {`${margin}%`}
        </span>
      )
  }},
  {
    headerName: "Lucro Médio",
    renderHeader: (params: any) => (
      <Tippy content={<div>{tooltipHeaderValues['Margem-Lucro-Médio']}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">Lucro Médio</div></Tippy>
    ), 
    field: "profit_avg", 
    type: 'number',
    hide: checkVisibility('profit_avg'),
    headerAlign: 'center',
    flex: 0.5,
    minWidth: 80,
    renderCell: (rowData: any) => {
      return (
        <span>{rowData.row.profit_avg.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
      )
    }
  },
  {
    headerName: "Lucro Est. Mensal",
    renderHeader: (params: any) => (
      <Tippy content={<div>{tooltipHeaderValues['Lucro-Estimado']}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">Lucro Est. Mensal</div></Tippy>
    ), 
    field: "project_profit", 
    hide: checkVisibility('project_profit'),
    type: 'number',
    headerAlign: 'center',
    flex: 1,
    minWidth: 80,
    renderCell: (rowData: any) => {
      return (
        <Tippy content={`Diário: ${`${rowData.row.project_profit.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`}`}>
            <div style={{width: 'fit-content'}}>{(rowData.row.project_profit*30).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</div>
        </Tippy>
      )
    }
  },
  {
    headerName: "Vendas Período",
    renderHeader: (params: any) => (
      <Tippy content={<div>{tooltipHeaderValues['Vendas-Período']}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">Vendas Período</div></Tippy>
    ), 
    field: "total_orders", 
    hide: checkVisibility('total_orders'),
    type: 'number',
    headerAlign: 'center',
    flex: 1, 
    minWidth: 80,
  },
  {
    headerName: "Venda Est. Mensal",
    renderHeader: (params: any) => (
      <Tippy content={<div>{tooltipHeaderValues['Venda-Estimada']}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">Venda Est. Mensal</div></Tippy>
    ), 
    field: "project_sells", 
    type: 'number',
    hide: checkVisibility('project_sells'),
    headerAlign: 'center',
    flex: 1,
    minWidth: 80,
    renderCell: (rowData: any) => {
      return (
        <Tippy content={`Diária: ${Math.floor(rowData.row.project_sells)}`}>
            <div style={{width: 'fit-content'}}>{Math.floor(rowData.row.project_sells*30)}</div>
        </Tippy>
      )
    }
  },
  {
    headerName: "Dur. Estoque",
    renderHeader: (params: any) => (
      <Tippy content={<div>{tooltipHeaderValues['Duração-Estoque']}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">Dur. Estoque</div></Tippy>
    ),
    field: "stockAllDuration", 
    type: 'number',
    headerAlign: 'center',
    flex: 1,
    minWidth: 70,
    hide: checkVisibility('stockAllDuration'),
    renderCell: (rowData: any) => {
      const roundedStock = rowData.row.stockAllDuration.toFixed(2);
      return (
        <span>
          {`${Math.floor(parseFloat(roundedStock))}`}
        </span>
      )
    }
  },
  {
    headerName: "Dur. Estoque Full",
    renderHeader: (params: any) => (
      <Tippy content={<div>{tooltipHeaderValues['Duração-Estoque-Full']}</div>} maxWidth={'25rem'}><div className="MuiDataGrid-columnHeaderTitle">Dur. Estoque Full</div></Tippy>
    ),
    field: "stockFullDuration", 
    headerAlign: 'center',
    hide: checkVisibility('stockFullDuration'),
    type: 'number',
    flex: 1,
    minWidth: 70,
    renderCell: (rowData: any) => {
      const roundedStock = rowData.row.stockFullDuration.toFixed(2);
      return (
        <span>
          {`${Math.floor(parseFloat(roundedStock))}`}
        </span>
      )
    }
  },
  ]
