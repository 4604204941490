
import { columns, options } from '../../../components/table/files/LCREData';
import { Accordion } from '../../../components/accordion/Accordion';
import { Card } from '../../../components/cards/Card';
import { Filters } from '../../../components/Filters/Filters';
import { toast } from 'react-toastify';
import'./style.scss';
import {CaptionsLcre} from '../../../components/accordion/files/CaptionsLcre';
import moment from 'moment';
import { ILCREResults, ILCREOrders, ObjetoVazio } from "../../../types/Types"
import { useDarkMode } from '../../../hooks/useDarkMode';
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { Untracked } from '@hookstate/untracked';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { useEffect } from 'react';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {isFirefox} from 'react-device-detect';
import { Loading } from '../../../components/Loading/Loading';
import { BugReportModal } from '../../../components/modal/BugReportModal';

const LCRETranslations: any = {
    'total_shipping_fee': 'Custo Frete', 
    'total_cost_tax': 'Custo Impostos',
    'total_cost_product': 'Custo Produtos', 
    'total_sale_fee': 'Custo Taxas ML',
    'total_sales': 'Total Vendas', 
    'total_sales_cancelled': 'Vendas Canceladas',
    'total_gross_profit': 'Lucro Bruto',
    'total_profit': 'Lucro Liquido',
    'total_profit_margin': 'Margem de Lucro',
    'total_orders': 'Total Pedidos'
}

const INPUT_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.6rem)';

export const Lcre = (props:any) =>{
    document.title = "Relatório LCRE - MeLiBeat";
    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })

    const handleValidateStatus = useHandleErrors();

    const fetchResource = (query:any=undefined) => axs.get<ILCREOrders[]>('/orders/lcre/', {params: query}).then((r: any) => {
        handleValidateStatus(r)
        Untracked(resultCards).set(r.data.result);
        Untracked(previousResult).set(Object.entries(r.data.previous_result))
        return processData(r.data.orders);
    }).catch((err: any)=> {
        handleValidateStatus(err.response)
        return []
    })

    
    const data: State<ILCREOrders[]> = useState<ILCREOrders[]>([]);
    const resultCards: State<ILCREResults | undefined> = useState<ILCREResults | undefined>(undefined);
    resultCards.attach(Untracked)
    const previousResult: State<any> = useState<any>(undefined);
    previousResult.attach(Untracked)
    const {DarkMode, } = useDarkMode();
    const filterValues: ObjetoVazio = useState<ObjetoVazio>({'tax_aliquot': undefined, "origin": undefined, "date_start": undefined})

    useEffect(() => {
        data.set(fetchResource({
            'date_start': moment().startOf('month').format('YYYY-MM-DD'),
            'date_end': moment().endOf('month').format('YYYY-MM-DD'),
            'origin': 'all'
        }))
      }, [])

    const handleCellEdit = async(new_value: any)=>{
        const raw_data = data.attach(Downgraded).get();
        const cellChanged = raw_data?.filter((item: any)=> item._id === new_value.id);

        if(cellChanged !== undefined && cellChanged.length > 0 ){
            const tax = cellChanged[0].cost_tax ? cellChanged[0].cost_tax : 0;
            const selled_price = cellChanged[0].selled_price ? cellChanged[0].selled_price : 0;
            // const res = await executePut('/orders/costproduct/'+new_value.id, {'cost': new_value.value, 'taxAliquot': Math.round((tax / selled_price) * 100) /100 }, 'dataPut');
            await axs.put<ILCREOrders[]>('/orders/costproduct/'+new_value.id, {'cost': new_value.value, 'taxAliquot': Math.round((tax / selled_price) * 100) /100 }).then((r: any) => {
                if(r.status === 200){
                    const resData = r.data;
                    const aux_data = JSON.parse(JSON.stringify(raw_data))
                    aux_data.forEach((item: any)=>{
                        if(item._id === new_value.id){
                            item['cost_product'] = resData.cost_product;
                            item['profit'] = resData.profit;
                            item['profit_margin'] = resData.profit_margin;
                            item['gross_profit'] = resData.gross_profit;
                        }
                    })
                    data.set(aux_data)
                }
            }).catch((err: any)=>{
                handleValidateStatus(err.response)
            })

        }else{
            toast.error('Ocorreu um erro ao tentar alterar o valor.');
        }
    }

    const processData = (values: any)=>{
        const processedArray = values.map((item: any, index: any)=>{
            let tags:string[] = []
            if(item.deal) tags.push('Promoção');
            if(item.rebate_campaign > 0) tags.push('Rebate Campanha');
            if(item.logistic_type === "Fulfillment") tags.push('Fulfillment');
            if(item.mediation_charged) tags.push('Cobrança Devolução');

            item['tags'] = tags
            return item
        })
        return processedArray
    }

    const handleFilter = (filters: any)=>{
        toast.info('Filtrando...')
        const updatedFilters:any = {};
        updatedFilters["date_start"] = moment(filters.filterDate).startOf('month').format('YYYY-MM-DD');
        updatedFilters["date_end"] = moment(filters.filterDate).endOf('month').format('YYYY-MM-DD')
        updatedFilters["tax_aliquot"] = filters.filterTaxAliquot;
        updatedFilters["origin"] = filters.filterOrigin;
        data.set(fetchResource(updatedFilters))
        filterValues.merge({'tax_aliquot': filters.filterTaxAliquot, "origin": filters.filterOrigin, "date_start": filters.filterDate})
    }

    const handleReset = ()=> {
        data.set(fetchResource({
        'date_start': moment().startOf('month').format('YYYY-MM-DD'),
        'date_end': moment().endOf('month').format('YYYY-MM-DD'),
        'origin': 'all'
        }))
        filterValues.merge({'tax_aliquot': undefined, "origin": undefined, "date_start": undefined})

    }


    return(
        <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
            <BugReportModal />
            <div className="content-wrapper">
                <main className="main-content container-fluid">
                    <Filters submitFunction={handleFilter} hideFullResetBtn handleReset={handleReset}>
                        <TextField name="filterTaxAliquot" size="small" type='number' inputProps={{step: "0.01"}} required id="tax-aliquot" label="Aliquota de Imposto" variant="outlined" value={filterValues.tax_aliquot.get()} 
                            onChange={(evt: any)=> filterValues.tax_aliquot.set(evt.target.value)}
                            sx={{minWidth: '21rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                        />
                        <FormControl size='small' variant='outlined' sx={{minWidth: '20rem'}} key={filterValues.origin.get()}>
                            <InputLabel id="origin-label-filter" style={{fontSize: INPUT_FONT_SIZE}}>Origem</InputLabel>
                            <Select
                                labelId="origin-label-filter"
                                id="origin-filter"
                                name='filterOrigin'
                                value={filterValues.origin.get()}
                                label="Origem"
                                style={{fontSize: INPUT_FONT_SIZE}}
                                required
                                onChange={(evt: SelectChangeEvent)=> filterValues.origin.set(evt.target.value)}
                            >
                            <MenuItem value={''}></MenuItem>
                            <MenuItem value={'all'}>Todos</MenuItem>
                            <MenuItem value={'TC'}>TechWheel</MenuItem>
                            <MenuItem value={'RM'}>RiseMode</MenuItem>
                            <MenuItem value={'UG'}>UpGamer</MenuItem>
                            <MenuItem value={'MM'}>MarMelada</MenuItem>
                            <MenuItem value={'JJ'}>JuJuca</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField type={isFirefox ? 'date' : "month"} size="small" required id="filterDate" name="filterDate" InputLabelProps={{ shrink: true }} label="Data" variant="outlined" value={filterValues.date_start.get()}
                            onChange={(evt: any)=> filterValues.date_start.set(evt.target.value)}
                            sx={{minWidth: '20rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                        />
                    </ Filters>
                    <Accordion title={CaptionsLcre.title} data={CaptionsLcre.data} />
                    {
                        data.promised ? 
                        <Loading />
                        : data.error ?
                        <ErrorScreen /> : 
                        data ?
                        <>
                            {
                                resultCards.get() !== undefined && previousResult.get() !== undefined ?
                                <div className="card-container">
                                    {
                                        Object.entries(resultCards).map((item: any, index: any) => {
                                            if(item !== undefined){
                                                const title = LCRETranslations[item[0]];
                                                const percent = item[0] === 'total_profit_margin' ?  true : false;
                                                return (
                                                    <Card 
                                                        title={title} 
                                                        content={item[1].value} 
                                                        lastMonth={previousResult[index as keyof State<any>][1].value} 
                                                        integer={true} 
                                                        percent={percent} 
                                                        prefix={item[0] === "total_orders" ? '' : percent ? "":"R$"}
                                                        key={index}
                                                        decimals={2}
                                                        increment={true} 
                                                    />
                                                )
                                            }else{
                                                return null
                                            }
                                        })
                                    }
                                    
                                </div>
                                :
                                null
                            }
                            <VirtualizedGrid 
                                rows={data.attach(Downgraded).get()}
                                columns={columns}
                                options={options}
                                title="Relatório LCRE"
                                onCellEdit={(value: any)=> handleCellEdit(value)}
                                defaultSort={{field: 'date_approved', direction: 'desc'}}
                            />
                        </>
                        :
                        null
                    }
                </main>
            </div>
        </div>
    )
}