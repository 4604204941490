import {FaTruck, FaAward, FaBookOpen, FaCheckCircle, FaTimesCircle, FaPauseCircle, FaHeart} from 'react-icons/fa';
import {GoZap} from 'react-icons/go';
import {BiLink} from 'react-icons/bi'
import {BsFillTagFill} from 'react-icons/bs';

export const CaptionsCpr = {
    title: 'Legendas Gestão de Anúncios',
    data: {
        colors: {},
        tags: {
            "Envio Grátis": ["#4b7bec", <FaTruck />],
            "Fulfillment": ["#20bf6b", <GoZap />],
            "Catálogo": ["#4b7bec", <FaBookOpen />],
            "Relação do Catálogo": ["#8854d0", <BiLink />],
            "Status (Ativo)": ["#4b7bec", <FaCheckCircle />],
            "Status (Inativo)": ["#4b7bec", <FaTimesCircle />],
            "Status (Pausado)": ["#4b7bec", <FaPauseCircle />],
            "Promoção": ["#e056fd", <BsFillTagFill />],
            "Tipo de Listagem": ["#e1b12c", <FaAward />],
            "Saúde do Anúncio": ["#e84118", <FaHeart />]
        }
    }
}

