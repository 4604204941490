import {FaTruck, FaAward, FaBookOpen, FaCheckCircle, FaTimesCircle, FaPauseCircle, FaHeart} from 'react-icons/fa';
import {FiZapOff, FiTrendingDown, FiPauseCircle, FiAlertCircle} from 'react-icons/fi';
import {RiAlertFill, RiAlertLine} from 'react-icons/ri'


import {GoZap} from 'react-icons/go';
import {BiLink} from 'react-icons/bi'
import {BsFillTagFill} from 'react-icons/bs';

export const CaptionsFullReplacement = {
    title: 'Legendas Reposição Full',
    data: {
        colors: {},
        tags: {
            "Reposição Necessária por MLB": ["#079992", (<RiAlertLine />)],
            "Diferença no Estoque Fulfillment": ["#D980FA", (<RiAlertFill />)],
            "Sem Fulfilment": ["#706fd3", (<FiZapOff />)],
            "Sem Histórico vendas": ["#227093", (<FiTrendingDown />)],
            "Todos MLBs Pausados": ["#FFC312", (<FiPauseCircle />)],
            "Falta de Estoque para Reposição": ["#EA2027", (<FiAlertCircle />)],
            "Envio Grátis": ["#4b7bec", <FaTruck />],
            "Fulfillment": ["#20bf6b", <GoZap />],
            "Catálogo": ["#4b7bec", <FaBookOpen />],
            "Relação do Catálogo": ["#8854d0", <BiLink />],
            "Status (Ativo)": ["#4b7bec", <FaCheckCircle />],
            "Status (Inativo)": ["#4b7bec", <FaTimesCircle />],
            "Status (Pausado)": ["#4b7bec", <FaPauseCircle />],
            "Promoção": ["#e056fd", <BsFillTagFill />],
            "Tipo de Listagem": ["#e1b12c", <FaAward />],
            "Saúde do Anúncio": ["#e84118", <FaHeart />],
        }
    }
}

