import { useHistory  } from "react-router-dom";
import { toast } from 'react-toastify';

export function useHandleErrors() {
    let history = useHistory();
    const handleValidateStatus = (res: any)=>{
      if(res !== undefined){
        if(res.status === 401){
            toast.error("O token de autenticação expirou. Faça o Login novamente", {autoClose: 5000});
            setTimeout(() => {
                window.location.replace('/login');
            }, 4000);
            return false
        }
        if(res.status === 403){
            toast.error("Você não tem as permissões necessárias para acessar essas funcionalidades.", {autoClose: 5000});
            history.push("/");
            return false
        }
        if(res.status === 400){
            let message = 'Ocorreu um Erro...';
            if(res.data.err !== undefined){
                message = res.data.err.message
            }else{
                message = res.data.msg || res.data.message
            }
            toast.error(message);
            return false
        }
        if(res.status === 404){
            const message = res.data.msg || res.data.message || "URL não encontrada!"
            toast.error(message);
            return false
        }
        if(res.status === 500){
            const message = res.data.msg || res.data.message || "Ocorreu um erro ao processar a solicitação!"
            toast.error(message);
            return false
        }
        if(res.status <= 204){
            if(res.config.method === 'delete'){
                toast.success("Item deletado com sucesso!");
            }
            return true
        }
          
      }
    }
    return handleValidateStatus;
}