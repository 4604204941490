import { createContext, useState, ReactNode } from 'react';

type ModalContextType = {
    ModalData: {show: boolean, title: any, execute: any} | undefined;
    showHideModal: (data: {show: boolean, title: any, execute: any}) => void;
}

type ModalContextProps = {
    children: ReactNode
}

export const ModalContext = createContext({} as ModalContextType);

export function ModalContextProvider(props: ModalContextProps) {
    const [ModalData, setData] = useState<{show: boolean, title: any, execute: any}>();

    function showHideModal(ModalData:{show: boolean, title: any, execute: any}){
        setData(ModalData);
    }

    return (
        <ModalContext.Provider value={{ ModalData, showHideModal }}>
            {props.children}
        </ModalContext.Provider>
    )
}