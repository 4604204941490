import { toast } from 'react-toastify';
import moment, {Moment} from 'moment';
import { IAnalysisABC } from "../../../types/Types";
import { useDarkMode } from '../../../hooks/useDarkMode';
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Filters } from '../../../components/Filters/Filters';
import { Loading } from '../../../components/Loading/Loading';
import {isFirefox} from 'react-device-detect';
import TextField from '@mui/material/TextField';
import { BugReportModal } from '../../../components/modal/BugReportModal';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { textAlign } from '@material-ui/system';

const INPUT_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.6rem)';

moment.updateLocale('pt-br', {
  weekdays : [
    'Domingo','Segunda','Terça','Quarta','Quinta','Sexta','Sabado'
  ]
});

export const KPIDayTP = (props:any) =>{
    document.title = "Análise de Vendas por Dia - MeLiBeat";
    const handleValidateStatus = useHandleErrors();

    const from = moment().startOf('month').format("YYYY-MM-DD");
    const to = moment(from).endOf('month').format('YYYY-MM-DD');
    const processedFilter = {
        dateFrom: from,
        dateTo: to,
        withoutCache: false,
        origin: ''
    }
    const dateFilter: State<string> = useState<string>(isFirefox ? moment().format("yyyy-MM-DD") : moment().format("yyyy-MM"));
    const originFilter: State<string> = useState<string>('')

    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })

    const fetchResource = (query:any=processedFilter) => axs.get<IAnalysisABC>('kpi/skuselldaystp', {params: query}).then((r: any) => {
        handleValidateStatus(r)
        return r.data;
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    })

    const data: State<any> = useState<any>([]);
    const month: State<string> = useState<string>(moment().format("MM"));
    const {DarkMode, } = useDarkMode();

    const checkVisibility = (column: string)=>{
        const storageColumnsData = localStorage.getItem('Análise de Vendas por Dia') ? localStorage.getItem('Análise de Vendas por Dia') : null;
        if(storageColumnsData !== null){
          const localData = JSON.parse(storageColumnsData);
          const shouldHide = localData.find((item: any)=> item.field === column);
          return shouldHide !== undefined ? !shouldHide.visibility : false
        }
        return false
      }

    const options = {
        palette:{
          tableHeader: {
            main: '#F7CE3E',
            dark: '#ac902b',
            light: '#f8d764',
            contrastText: '#000'
          },
        },
        alternate: true,
    }

    const columns: any[] = [
        { 
            headerName: "SKU", 
            field: "sku", 
            type: 'string',
            headerAlign: 'center',
            minWidth: 80,
            hide: checkVisibility('sku'),
            renderCell: (rowData: any) => {
              return (
                rowData.row.sku
              )
            }
        },
        { 
          headerName: "SKU TP", 
          field: "skutp", 
          type: 'string',
          headerAlign: 'center',
          minWidth: 80,
          hide: checkVisibility('sku'),
          renderCell: (rowData: any) => {
            return (
              rowData.row.skutp
            )
          }
      },
        { 
            headerName: "Produto", 
            field: "title", 
            type: 'string',
            headerAlign: 'center',
            align: 'left',
            flex: 3,
            minWidth: 300,
            hide: checkVisibility('title'),
            renderCell: (rowData: any) => {
              return (
                <Tippy content={
                    <div>{rowData.row.title}</div>
                } maxWidth={'100%'}>
                    <div style={{width: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}} title={rowData.row.title}>
                        {rowData.row.title}
                    </div>
                </Tippy>
              )
            }
        },
        { 
            headerName: "Est. Total", 
            field: "stockTotal", 
            type: 'number',
            headerAlign: 'center',
            minWidth: 80,
            hide: checkVisibility('stockTotal'),
            renderCell: (rowData: any) => {
              return (
                rowData.row.stockTotal
              )
            }
        },
        { 
            headerName: "Est. CD", 
            field: "stockAll", 
            type: 'number',
            headerAlign: 'center',
            minWidth: 80,
            hide: checkVisibility('stockAll'),
            renderCell: (rowData: any) => {
              return (
                <Tippy 
                content={Object.entries(rowData.row.stockAllDetail).map((item: any, index: any)=>{
                    return(
                    <div key={index}>{`${item[0]}: ${item[1]}`}</div>
                    )
                })}
                >
                    <div style={{width: 'fit-content'}}>{rowData.row.stockAll}</div>
                </Tippy>
              )
            }
        },
        { 
            headerName: "Est. Full", 
            field: "stockFull", 
            type: 'number',
            headerAlign: 'center',
            minWidth: 80,
            hide: checkVisibility('stockFull'),
            renderCell: (rowData: any) => {
              return (
                <Tippy 
                content={Object.entries(rowData.row.stockFullDetail).map((item: any, index: any)=>{
                    return(
                    <div key={index}>{`${item[0]}: ${item[1]}`}</div>
                    )
                })}
                >
                    <div style={{width: 'fit-content'}}>{rowData.row.stockFull}</div>
                </Tippy>
              )
            }
        },
        { 
            headerName: "Est. ETP", 
            field: "stockETP", 
            type: 'number',
            headerAlign: 'center',
            minWidth: 80,
            hide: checkVisibility('stockETP'),
            renderCell: (rowData: any) => {
              return (
                <Tippy 
                content={Object.entries(rowData.row.stockETPDetail).map((item: any, index: any)=>{
                    return(
                    <div key={index}>{`${item[0]}: ${item[1]}`}</div>
                    )
                })}
                >
                    <div style={{width: 'fit-content'}}>{rowData.row.stockETP}</div>
                </Tippy>
              )
            }
        },
        { 
            headerName: "Custo Un", 
            field: "cost", 
            type: 'number',
            headerAlign: 'center',
            minWidth: 50,
            hide: checkVisibility('cost'),
            renderCell: (rowData: any) => {
              return (
                rowData.row.cost.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
              )
            }
        },
        { 
          headerName: "Custo Estoque", 
          field: "stockCost", 
          type: 'number',
          headerAlign: 'center',
          minWidth: 50,
          hide: checkVisibility('cost'),
          renderCell: (rowData: any) => {
            return (
              rowData.row.stockCost.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
            )
          }
      },
        { 
            headerName: "Faturamento", 
            field: "totalSelledPrice", 
            type: 'number',
            headerAlign: 'center',
            minWidth: 50,
            hide: checkVisibility('totalSelledPrice'),
            renderCell: (rowData: any) => {
              return (
                rowData.row.totalSelledPrice.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
              )
            }
        },
        { 
            headerName: "Vendas", 
            field: "sells", 
            type: 'number',
            headerAlign: 'center',
            minWidth: 50,
            hide: checkVisibility('sells'),
            renderCell: (rowData: any) => {
              return (
                <Tippy content={
                    <div>Un de Vendas Canceladas: {rowData.row.sellsCancelled}</div>
                } maxWidth={'100%'}>
                    <div>
                        {rowData.row.sells}
                    </div>
                </Tippy>
              )
            }
        },
        {
            headerName: "Média Diaria", 
            field: "avaregeSells", 
            type: 'number',
            headerAlign: 'center',
            minWidth: 50,
            hide: checkVisibility('avaregeSells'),
            renderCell: (rowData: any) => {
              return (
                rowData.row.avaregeSells.toFixed(2)
              )
            }
        },
    ]

    const arrayDaysCalendar: number[] = []
    const daysOfWeek: string[] = ['Domingo','Segunda','Terça','Quarta','Quinta','Sexta','Sabado']
    const startOfMonth = moment(dateFilter.get()).startOf('month').format('dddd');
    const daysOfMonth = moment(dateFilter.get()).daysInMonth()
    let idxStartDaysCalendar: number = -1
    let idxEndDaysCalendar: number = -1

    for(let i = 1; i<= (7*6); i++){
      if(startOfMonth === daysOfWeek[(i - 1)]){
        idxStartDaysCalendar = i
      }
      if(i=== daysOfMonth){
        idxEndDaysCalendar = i
      }
      arrayDaysCalendar.push(i)
    }

    useEffect(() => {
        //data.set(fetchResource())
    }, [])

    const handleFilter = (filters: any)=>{
        toast.info('Filtrando...')
        const processedFilter = {
          dateFrom: `${filters.filterDate}-01`,
          dateTo: moment(filters.filterDate).endOf('month').format('YYYY-MM-DD'),
          withoutCache: false,
          origin: originFilter.attach(Downgraded).get()
      }
        month.set(moment(filters.filterDate).format('MM'))
        data.set(fetchResource(processedFilter))
    }

    const handleFullReset = ()=>{
        toast.info("Resetando os dados sem o cache!");
        const from = moment().startOf('month').format("YYYY-MM-DD");
        const to = moment(from).endOf('month').format('YYYY-MM-DD');
        const processedFilter = {
            dateFrom: from,
            dateTo: to,
            withoutCache: true,
            origin: originFilter.attach(Downgraded).get()
        }
        month.set(moment().format('MM'))
        dateFilter.set(isFirefox ? moment().format("yyyy-MM-DD") : moment().format("yyyy-MM"))
        data.set(fetchResource(processedFilter));
    }

    const handleReset = ()=> {
        const from = moment().startOf('month').format("YYYY-MM-DD");
        const to = moment(from).endOf('month').format('YYYY-MM-DD');
        const processedFilter = {
          dateFrom: from,
          dateTo: to,
          withoutCache: false,
          origin: originFilter.attach(Downgraded).get()
        }
        month.set(moment().format('MM'))
        dateFilter.set(isFirefox ? moment().format("yyyy-MM-DD") : moment().format("yyyy-MM"))
        data.set(fetchResource(processedFilter))
    }

    const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    }));


    return(
      <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
        <BugReportModal />
        <div className="content-wrapper">
            <main className="main-content container-fluid">
              <Filters submitFunction={handleFilter} handleReset={handleReset} handleFullReset={handleFullReset}>
                <FormControl size='small' variant='outlined' sx={{minWidth: '20rem', width: '100%'}}>
                  <InputLabel id="origin-label-filter-label" style={{fontSize: INPUT_FONT_SIZE}}>Origem</InputLabel>
                  <Select
                                labelId="origin-label-filter"
                                id="origin-filter"
                                name='filterOrigin'
                                value={originFilter.get()}
                                label="Origem"
                                style={{fontSize: INPUT_FONT_SIZE}}
                                required
                                onChange={(evt: SelectChangeEvent)=> originFilter.set(evt.target.value)}
                            >
                            <MenuItem value={''}></MenuItem>
                            <MenuItem value={'RM'}>RiseMode</MenuItem>
                            <MenuItem value={'UG'}>UpGamer</MenuItem>
                            <MenuItem value={'MM'}>MarMelada</MenuItem>
                            <MenuItem value={'JJ'}>JuJuca</MenuItem>
                            </Select>
              </FormControl>

                <TextField size="small" required name="filterDate" type={isFirefox ? 'date' : "month"} id="closing_date" InputLabelProps={{ shrink: true }} label="Data" variant="outlined"
                    sx={{minWidth: '20rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                    value={dateFilter.get()}
                    onChange={(evt: any)=> isFirefox ? dateFilter.set(moment(evt.target.value).format("yyyy-MM-DD")) : dateFilter.set(moment(evt.target.value).format("yyyy-MM"))}
                />
              </ Filters>
              {
                data.promised ? 
                <Loading />
                : data.error ?
                <ErrorScreen /> : 
                data ?
                <VirtualizedGrid 
                    rows={data.attach(Downgraded).get()}
                    columns={columns}
                    options={options}
                    title="Análise de Vendas por Dia"
                    defaultSort={{field: 'sells', direction: 'desc'}}
                    detailPanel={({ row }: {row:any}) =>
                    <Box sx={{ flexGrow: 1,  m: 4, width: '50%', textAlign: 'center' }}>
                      <Grid
                        container
                        spacing={2}
                        columns={2.8}
                        sx={{
                          '--Grid-borderWidth': '1px',
                          borderTop: 'var(--Grid-borderWidth) solid',
                          borderLeft: 'var(--Grid-borderWidth) solid',
                          borderColor: 'divider',
                          '& > div': {
                            borderRight: 'var(--Grid-borderWidth) solid',
                            borderBottom: 'var(--Grid-borderWidth) solid',
                            borderColor: 'divider',
                          },
                        }}
                      >
                          {
                          //Legenda Calendario
                          daysOfWeek.map((x, i) =>
                                                 
                              <Grid key={`${i}-${Math.random()}`} {...{ xs: 0.4, sm: 0.4, md: 0.4, lg: 0.4 }} minHeight={30} >
                                {x}
                              </Grid>
                          )}
                          {
                          //Dias do Calendario
                          arrayDaysCalendar.map((x, i) =>
                            {
                              const idxArrayDaysInMonth = (x - idxStartDaysCalendar) + 1
                              if(x >= idxStartDaysCalendar && idxArrayDaysInMonth <= idxEndDaysCalendar){
                                return (
                                  <Grid key={`${i}-${Math.random()}`} {...{ xs: 0.4, sm: 0.4, md: 0.4, lg: 0.4 }} minHeight={80} >
                                    <h4 style={{textAlign: "left", color: '#737373'}}>{idxArrayDaysInMonth.toString().padStart(2,'0')}/{moment(dateFilter.get()).format('MM')}/{moment(dateFilter.get()).format('YY')}</h4>
                                    <p style={{marginTop: '0.6em', textAlign: "center", fontWeight: 'bold', fontSize: '1.5em', color: `${row.sellsPerDay[idxArrayDaysInMonth] !== 0 ? '#000' : '#BBB'}`}}>{row.sellsPerDay[idxArrayDaysInMonth]} <span style={{color: '#BBB'}}>{`${row.sellsPerDay[idxArrayDaysInMonth] !== "" ? 'un' : ''}`}</span></p>
                                  </Grid>
                                  )
                              }else{
                                return (
                                  <Grid sx={{bgcolor: '#eee'}} key={`${i}-${Math.random()}`} {...{ xs: 0.4, sm: 0.4, md: 0.4, lg: 0.4 }} minHeight={80} ></Grid>
                                )
                              }
                            }
                          )}
                      </Grid>
                      </Box>
                    }
                />
                :
                null
              }
            </main>
        </div>
      </div>
    )
}