// import {DownloadXLS, DownloadPDF} from '../../../utils/downloadFormats';
import {FaTruck, FaAward, FaBookOpen, FaCheckCircle, FaTimesCircle, FaPauseCircle, FaHeart, FaCircle} from 'react-icons/fa';
import {GoZap} from 'react-icons/go';
import {BiLink, BiRevision} from 'react-icons/bi'
import {BsFillTagFill} from 'react-icons/bs';
import {FiExternalLink} from 'react-icons/fi';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import { 
  GridColDef, GridFilterItem,
} from '@mui/x-data-grid';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const defaultTheme  = createTheme();


const tooltipHeaderValues = {
  "Vendas-Período": "Total de Vendas durante o período selecionado (histórico de dias)",
  "Venda-Estimada": "Cálculo Realizado com base nas Vendas do Período e calculando projeção com base nos dias ativos de cada anúncio",
  "Lucro-Estimado": "Cálculo Realizado à partir da projeção de vendas e do lucro médio.",
  "Origem": "Determinado pela regra do SKU, utilizado para separação e indicação da Origem (Comprado, parceria Fornecedor).",
  "SKU": "Código interno do produto",
  "Tags": "Ícones para Rápida identificação de situações do produto.",
  "Geral": "Estoque Geral (Quantitativo)",
  "FULL": "Estoque Fulfillment (Quantitativo)",
  "ETP": "Estoque Em Transporte (Quantitativo)",
  "Margem-Lucro-Médio": " Calculo baseado no histórico de vendas.",
  "Duração-Estoque": "Tempo de Duração do Estoque em Dias",
  "Duração-Estoque-Full": "Tempo de Duração do Estoque do Fulfillment em Dias."
}


export const options = {
  palette:{
    tableHeader: {
      main: '#F7CE3E',
      dark: '#ac902b',
      light: '#f8d764',
      contrastText: '#000'
    },
  },
  alternate: true,
}

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    '& li':{
      fontSize: 'clamp(1.1rem, 1vw, 1.3rem)',
    }
  }
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function CheckboxesTags(props: any) {
  const { item, applyValue, options:tag_options } = props;
  // const selected_values = item.value ? item.value.map((elm: any)=> elm.title) : [];

  const handleFilterChange = (event: any, value: any) => {
    applyValue({ ...item, value: value });
  };

  return (
    <Autocomplete
      multiple
      options={tag_options}
      disableCloseOnSelect
      getOptionLabel={(option: any) => option.title}
      defaultValue={item.value}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
            style={{ marginRight: 8 }}
            // checked={selected_values.includes(option.title)}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label="Tags" variant="standard" sx={{'& .MuiChip-root':{fontSize: 'clamp(1rem, 1.1vw, 1.2rem)'}}} />
      )}
      //PopperComponent={StyledPopper}
      onChange={handleFilterChange}
    />
  );
}

const checkVisibility = (column: string)=>{
  const storageColumnsData = localStorage.getItem('Gestão de Anúncios') ? localStorage.getItem('Gestão de Anúncios') : null;
  if(storageColumnsData !== null){
    const localData = JSON.parse(storageColumnsData);
    const shouldHide = localData.find((item: any)=> item.field === column);
    return shouldHide !== undefined ? !shouldHide.visibility : false
  }
  return false
}

export const columns: GridColDef[] = [
  {
      field: 'id',
      headerName: "MLB",
      hide: checkVisibility('id'),
      renderHeader: (params: any) => (
        <Tippy content={<div>{tooltipHeaderValues.SKU}</div>} maxWidth={'40rem'}><div className="MuiDataGrid-columnHeaderTitle">MLB</div></Tippy>
      ),
      headerAlign: 'center',
      flex: 1,
      minWidth: 100,
      renderCell: (rowData: any) => (
        <ThemeProvider theme={defaultTheme}>
          <div title={rowData.row.id}>
            <Link 
              href={rowData.row.link} 
              target="_blank" rel="noreferrer" 
              color={'inherit'} 
              sx={{'&:hover':{color: defaultTheme.palette.primary.dark}}}
              style={{fontSize: 'clamp(1.2rem, 1vw, 1.3rem)', display: 'flex', alignItems: 'center', gap: '5px', textDecoration: 'none'}} 
            >
                <div>{rowData.row.id}</div><FiExternalLink />
            </Link>
          </div>
        </ThemeProvider>
        ),
  },
  {
      field: 'photo',
      headerName: "Imagem",
      hide: checkVisibility('photo'),
      headerAlign: 'center',
      flex: 0.5,
      minWidth: 50,
      renderCell: (rowData: any) => (
          <div className="img" style={{width: 30, height: 30, borderRadius: '50%'}}>
              <Avatar alt="Produto mostrado na linha" src={rowData.row.photo} sx={{width: '100%', height: '100%'}} />
          </div>
      ),
  },
  {
    field: 'sku',
    headerName: "SKU", 
    hide: checkVisibility('sku'),
    renderHeader: (params: any) => (
      <Tippy content={<div>{tooltipHeaderValues.SKU}</div>} maxWidth={'40rem'}><div className="MuiDataGrid-columnHeaderTitle">SKU</div></Tippy>
    ),
    headerAlign: 'center',
    flex: 1,
    minWidth: 100,
    renderCell: (rowData: any) => (
      <ThemeProvider theme={defaultTheme}>
        <div title={rowData.row.id}  style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
          <Link 
            href={`/vendas/visualizar-sku/false/${rowData.row.sku}`} 
            target="_blank" rel="noreferrer" 
            color={'inherit'} 
            sx={{'&:hover':{color: defaultTheme.palette.primary.dark}}}
            style={{fontSize: 'clamp(1.1rem, 1vw, 1.2rem)', display: 'flex', alignItems: 'center', gap: '5px'}} 
          >
              <div style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{rowData.row.sku}</div><FiExternalLink />
          </Link>
        </div>
     </ThemeProvider>
    ),
  },
  {
    field: 'inventory_id',
    headerName: 'Inventory ID',
    hide: checkVisibility('inventory_id'),
    renderHeader: (params: any) => (
      <Tippy content={<div>{tooltipHeaderValues.Origem}</div>} maxWidth={'40rem'}><div className="MuiDataGrid-columnHeaderTitle">Inventory ID</div></Tippy>
    ),
    headerAlign: 'center',
    flex: 1,
    minWidth: 100,
    renderCell: (rowData: any) => (
      rowData.row.inventory_id ? rowData.row.inventory_id : "N/A"
    ),
  },
  {
    field: 'title',
    headerName: "Produto",
    hide: checkVisibility('title'),
    headerAlign: 'center',
    flex: 2,
    minWidth: 200,
    renderCell: (rowData: any) => (
      <Tippy content={<div>{rowData.row.title}</div>} maxWidth={'25rem'}><div style={{width: '100%', textAlign: 'start', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{rowData.row.title}</div></Tippy>
    ),
  },
  {
    field: 'variation_name',
    headerName: "Variação",
    hide: checkVisibility('variation_name'),
    headerAlign: 'center',
    flex: 1,
    minWidth: 100,
    renderCell: (rowData: any) => (
      <Tippy 
        content={
          <div>
            {rowData.row.variation_name ? rowData.row.variation_name : "N/A"}
          </div>} 
          maxWidth={'25rem'}
        >
          <div style={{width: '100%', overflow: 'hidden', textAlign: 'start', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
            {rowData.row.variation_name ? rowData.row.variation_name : "N/A"}
          </div>
        </Tippy>
    ),
  },
  {
    field: 'translated_status',
    headerName: "Status",
    hide: checkVisibility('translated_status'),
    headerAlign: 'center',
    type: 'singleSelect',
    flex: 1,
    minWidth: 100,
    valueOptions: ['Ativo', 'Inativo', 'Pausado', "Em Revisão", "Fechado"],
    renderCell: (rowData: any) => {
      const statusColor:any = {"Ativo": "#2477ad", "Inativo": "#a5b1c2", 'Pausado': "#d65446", "Em Revisão": "#000", "Fechado": "#c0392b"}
      return (
        <strong style={{display: 'flex', alignItems: "center", justifyContent: "flex-start"}}>
            {rowData.row.translated_status}
              <span style={{color: statusColor[rowData.row.translated_status], fontSize: "1rem", marginLeft: '.5rem'}}>
                {
                  rowData.row.translated_status !== "Em Revisão" ?
                  <FaCircle />
                  :
                  <BiRevision />
                }
              </span>
          </strong>
      )
    }
  },
  {
    field: 'new_tags',
    headerName: "Tags",
    hide: checkVisibility('new_tags'),
    renderHeader: (params: any) => (
      <Tippy content={<div>{tooltipHeaderValues.Tags}</div>} maxWidth={'40rem'}><div className="MuiDataGrid-columnHeaderTitle">Tags</div></Tippy>
    ),
    type: 'singleSelect',
    headerAlign: 'center',
    flex: 2,
    minWidth: 200,
    filterOperators: [{
      value: 'contains',
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        const selected_values = filterItem.value ? filterItem.value.map((item: any)=> item.title) : [];
        console.log(selected_values)
        if (filterItem.value == null || selected_values === []) {
          return null;
        }
  
        return ({ value }): boolean => {
          if (typeof value === 'object') {
            return selected_values.every((item: any)=> value.includes(item))
          }
          return selected_values.every((item: any)=> value.includes(item))
        };
      },
      // InputComponent: GridFilterInputValue,
      InputComponent: CheckboxesTags,
      InputComponentProps: { type: 'singleSelect', options: [{title: 'Envio Grátis'}, {title: 'Fulfillment (Sim)'}, {title: 'Fulfillment (Não)'}, {title: 'Catálogo'}, {title: 'Relação do Catálogo'}, {title: 'Promoção'}, {title: 'Anúncio saudável'}, {title: 'Anúncio não saudável'}, {title: 'Status: Ativo'}, {title: 'Status: Inativo'}, {title: 'Status: Pausado'}, {title: 'Status: Em revisão'}, {title: 'Status: Fechado'}, {title: 'Anúncio Clássico'}, {title: 'Anúncio Premium'}]},
    }],
    valueOptions: ['Envio Grátis', 'Fulfillment (Sim)', 'Fulfillment (Não)', 'Catálogo', 'Relação do Catálogo', 'Promoção', 'Anúncio saudável', 'Anúncio não saudável', 'Anúncio Clássico', 'Anúncio Premium'],
    renderCell: (rowData: any) => {
      const tagStatus:any = {"Ativo": <FaCheckCircle className="icon"/>, "Inativo": <FaTimesCircle className="icon"/>, 'Pausado': <FaPauseCircle className="icon"/>, "Em Revisão": <BiRevision className="icon" />, "Fechado": <FaTimesCircle className="icon"/>}
      const statusColor:any = {"Ativo": "#2477ad", "Inativo": "#a5b1c2", 'Pausado': "#d65446", "Em Revisão": "#000", "Fechado": "#c0392b"}
      const tagColors: any = {
        'tags': 
        [
            {'title': 'Envio Grátis', 'value': <FaTruck className="icon" />, 'color': (rowData.row.free_shipping ? '#4b7bec':'#a5b1c2')},
            {'title': rowData.row.fulfillment ? 'Fulfillment (Sim)' : 'Fulfillment (Não)', 'value': <GoZap className="icon"/>, 'color': (rowData.row.fulfillment ? '#20bf6b':'#a5b1c2')},
            {'title': rowData.row.catalog_product ? rowData.row.catalog_product:'Catálogo', 'value': <FaBookOpen className="icon"/>, 'color': (rowData.row.catalog ? '#f39c12':'#a5b1c2')},
            {'title': 'Relação do Catálogo', 'value': <BiLink className="icon"/>, 'color': (rowData.row.catalog_relation ? '#f39c12':'#a5b1c2')},
            {'title': `Status: ${rowData.row.translated_status}`, 'value': tagStatus[rowData.row.translated_status], 'color':  (rowData.row.translated_status ? statusColor[rowData.row.translated_status]:'#a5b1c2')},
            {'title': `Promoção: ${rowData.row.original_price !== null ? (((rowData.row.sell_price - rowData.row.original_price)/rowData.row.original_price)*100).toFixed(2):0}%`, 'value': <BsFillTagFill className="icon"/>, 'color': (rowData.row.deal ? '#e056fd':'#a5b1c2')},
            {'title': `Tipo de Listagem: ${rowData.row.listing_type === 'gold_special' ? 'Clássico':'12x'}`, 'value': <FaAward className="icon"/>, 'color': (rowData.row.listing_type === 'gold_pro' ? '#e1b12c' : '#a5b1c2')},
            {'title': `Saúde do Anúncio ${(rowData.row.health*100)}%`, 'value': <FaHeart className="icon"/>, 'color': (rowData.row.health ? ((rowData.row.health*100) >= 70 ? '#e84118' : '#9b59b6') :'#a5b1c2')},
        ]
      };
      return (
        <div className="tags-container" style={{marginTop: 0, flexWrap: 'nowrap', display: 'flex', gap: '5px'}}>
          {
            Object.values(tagColors.tags).map((item: any, index: any)=>{
              if(item.title === "Relação do Catálogo"){
                if(rowData.row.catalog_relation){
                  return (
                    <Tippy key={index} content={rowData.row.catalog_relation.mlb_id} maxWidth={'25rem'}>
                      <span className="tag-small" title={rowData.row.catalog_relation.mlb_id} style={{color: item.color}}>{item.value}</span>
                    </Tippy>
                  )
                }else{
                  return (
                    <Tippy key={index} content={item.title} maxWidth={'25rem'}>
                      <span className="tag-small" style={{color: item.color}}>{item.value}</span>
                    </Tippy>
                  )
                }
              }else{
                return (
                  <Tippy key={index} content={item.title} maxWidth={'25rem'}>
                    <span className="tag-small" style={{color: item.color}}>{item.value}</span>
                  </Tippy>
                )
              }
            })
          }

        </div>
      )
    }
  },
  {
    headerName: "Custo Fiscal",
    field: 'cost_product',
    hide: checkVisibility('cost_product'),
    headerAlign: 'center',
    type: "number",
    flex: 1,
    minWidth: 100,
    renderCell: (rowData: any) => (
      <span>
        {rowData.row.cost_product.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
      </span>
    ),
  },
  {
    headerName: "Valor Venda",
    field: 'sell_price',
    hide: checkVisibility('sell_price'),
    headerAlign: 'center',
    type: "number",
    flex: 1,
    minWidth: 100,
    renderCell: (rowData: any) => (
      <span>
        {rowData.row.sell_price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
      </span>
    ),
  },
  {
    field: 'total_sold',
    headerName: "Total Vendas",
    hide: checkVisibility('total_sold'),
    headerAlign: 'center',
    type: "number",
    flex: 1,
    minWidth: 100,
    renderCell: (rowData: any) => (
      <span>
        {rowData.row.total_sold}
      </span>
    ),
  },
]

