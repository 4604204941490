import moment from 'moment';

import { toast } from 'react-toastify';

import { AiOutlineLoading3Quarters} from 'react-icons/ai';
import { GridModal } from '../../../components/modal/GridModal';
import { ICreditSuppliers, IGroupSupplierCreditBills } from "../../../types/Types"
import { useDarkMode } from '../../../hooks/useDarkMode';

import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';

import { useEffect } from 'react';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { BugReportModal } from '../../../components/modal/BugReportModal';

const options = {
    palette:{
        tableHeader: {
            main: '#F7CE3E',
            dark: '#ac902b',
            light: '#f8d764',
            contrastText: '#000'
        },
    },
    alternate: true,
}

const columns = [
    {
        headerName: 'Fornecedor',
        field: 'name',
        type: 'string',
        headerAlign: 'center',
        disableColumnMenu: true,
        flex: 3,
        renderCell: (rowData: any)=>{
            return(
                <Tippy content={rowData.row.name}>
                        <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            <span>{rowData.row.name}</span>
                        </main>
                    </Tippy>
            )
        }
    },
    {
        headerName: 'Limite de Crédito',
        field: 'creditLimit',
        type: 'number',
        headerAlign: 'center',
        disableColumnMenu: true,
        flex: 2,
        renderCell: (rowData: any)=>{
            return(
                rowData.hasOwnProperty('creditHighest')
                ?<Tippy 
                    content={                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', gap: '1rem'}}>
                            <div>Data: {moment(rowData.row.creditHighest.date).format("DD/MM/yyyy")}</div>
                            <div>Valor: {rowData.row.creditHighest.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</div>
                        </div>
                    }
                >
                    <span>{rowData.row.creditLimit.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                </Tippy>

                : <span>{rowData.row.creditLimit.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
            )
        }
    },
    {
        headerName: 'Crédito Tomado',
        field: 'creditTaken',
        type: 'number',
        headerAlign: 'center',
        disableColumnMenu: true,
        flex: 2,
        renderCell: (rowData: any)=>{
            return(
                <span>{rowData.row.creditTaken.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
            )
        }
    },
    {
        headerName: 'Saldo',
        field: 'creditBalance',
        type: 'number',
        headerAlign: 'center',
        disableColumnMenu: true,
        flex: 2,
        renderCell: (rowData: any)=>{
            return(
                rowData.row.additionalCredits.length > 0 ?
                <Tippy 
                content={                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
                    <ul style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', gap: '1rem', listStyle: 'none'}}>
                        {
                            rowData.row.additionalCredits.map((item: any)=>{
                                return <li>{`${item.name}: ${item.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`}</li>
                            })
                        }
                    </ul>
                }
                >
                    <span>{rowData.row.creditLimit.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                </Tippy>
                :
                <span>{rowData.row.creditBalance.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>

            )
        }
    },
]


const modalColumns = [
    {
        headerName: 'Data',
        field: 'vencimento',
        flex: 1,
        headerAlign: 'center',
        renderCell: (rowData: any)=>{
            return(
                <strong>{moment(rowData.row.vencimento).format("DD/MM/yyyy")}</strong>
            )
        }
    },
    {
        headerName: 'Valor',
        field: 'valor',
        flex: 1,
        headerAlign: 'center',
        renderCell: (rowData: any)=>{
            return(
                <strong>{rowData.row.valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong>
            )
        }
    },
    { 
        headerName: "Histórico",
        field: "historico", 
        flex: 4,
        headerAlign: 'center',
        renderCell: (rowData: any) => {
            return (
                <strong>{rowData.row.historico}</strong>
            )
        }
    },
]

export const CreditSuppliers = () =>{
    document.title = "Crédito Fornecedores - MeLiBeat";
    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })

    const handleValidateStatus = useHandleErrors();
    const fetchResource = async (query:any=undefined) => {
        try{
            const r = await axs.get<ICreditSuppliers[]>('/groupsuppliers/credit/', {params: query})
            handleValidateStatus(r)
            return r.data
        }
        catch(err: any){
            handleValidateStatus(err.response)
            return []
        }
    }
    
    const data: State<ICreditSuppliers[]> = useState<ICreditSuppliers[]>([]);
        
    const showModal: State<boolean> = useState<boolean>(false);
    const modalData: State<IGroupSupplierCreditBills[]> = useState<IGroupSupplierCreditBills[]>([]);
    const modalTableTitle: State<string> = useState<string>('');
    const {DarkMode, } = useDarkMode();


    useEffect(() => {
      data.set(fetchResource())
    }, [])
    

    const handleView = async(options: ICreditSuppliers) =>{
        if(options.bills.length > 0){
            try{
                showModal.set(true);
                modalData.set(options.bills);
                modalTableTitle.set("Contas a Pagar");
            }
            catch(err){
                toast.error("Ocorreu um erro ao carregar a Tabela!");
            }
        }else{
            toast.info("Não existem informações sobre contas a pagar nesta linha!");
        }

    }

    if(data.promised){ //Enquanto for promisse ou estiver carregando
        return (
            <>
                <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}> 
                    <div className="content-wrapper">
                        <main className="main-content container-fluid">
                            <div className="loading">
                                <AiOutlineLoading3Quarters className="loading-icon" />
                            </div>
                        </main>
                    </div>
                </div>
            </>
        )
    }

    if (data.error) { // Quando o promise dar reject
        return (<ErrorScreen />)
    }


    return(
        <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
            {
                showModal.get() === true
                ?<GridModal 
                    closeFunction={()=> showModal.set(false)} tableTitle={modalTableTitle.get()} 
                    columns={modalColumns} 
                    data={modalData.attach(Downgraded).get()} 
                    options={options}
                    style={{width: '80%'}}
                    defaultSort={{field: 'vencimento', direction: 'desc'}}
                />
                : null
            }
            <BugReportModal />
            <div className="content-wrapper">
                <main className="main-content container-fluid">
                    {
                        data.length > 0 ?
                        <VirtualizedGrid 
                            columns={columns} 
                            rows={data.attach(Downgraded).get()} 
                            options={options}
                            tableHeight='auto'
                            title="Crédito Fornecedores" 
                            actions={[
                                {icon: 'visibility', title: 'Visualizar Item', method: (evt: any, row:any)=> handleView(row)}, 
                            ]} 
                            defaultSort={{field: 'creditBalance', direction: 'desc'}}

                        />
                        : null
                    }
                </main>
            </div>
        </div>
    )
}