// import { useState } from 'react'
import { useContext } from 'react'
import { DarkModeContext } from '../contexts/DarkModeContext'

export function useDarkMode() {
    // const darkmodeLS = localStorage.getItem('darkmode') ? localStorage.getItem('darkmode') : '0';
    // const [darkMode, setDarkMode] = useState<boolean>(darkmodeLS === '1' ? true:false);

    // const toggleDarkMode = () => {
    //     localStorage.setItem('darkmode', !darkMode ? '1' : '0');
    //     setDarkMode(!darkMode)
    //     return false
    // }

    // return {darkMode, toggleDarkMode};
    // }
    const value = useContext(DarkModeContext)

    return value;
}

