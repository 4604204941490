import React from 'react';
import { BrowserRouter as Route, useParams } from 'react-router-dom';
import { Loading } from '../../../components/Loading/Loading';
import { useDarkMode } from '../../../hooks/useDarkMode';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { Untracked } from '@hookstate/untracked';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useModal } from '../../../hooks/useModal';
import { ModalConfirm } from '../../../components/modal/ModalConfirm';
import { useHistory } from "react-router-dom";
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import { IGroupSuppliers, additionalCredits } from "../../../types/Types"
import { BasicModal } from "../../../components/modal/BasicModal";
import { useEffect } from 'react';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { BugReportModal } from '../../../components/modal/BugReportModal';

const FONT_SIZE = "clamp(1.2rem, 1.2vw, 1.6rem)";

const BaseInput = styled(TextField)({
    '& .MuiInput-input':{
      fontSize: FONT_SIZE,
      minWidth: '300px',
      flex: 1,
    },
    '& label':{
      fontSize: FONT_SIZE,
      paddingLeft: '14px',

    }
});


const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
        },
    },
};

interface suppliersData{
    name: string | undefined,
    creditLimit: number | undefined,

}

interface supplierGroup{
    _id: string,
    id: string,
    nome: string,
    cnpj: string
}
interface inputError{
    main_name: boolean,
    suppliers: boolean,
    credit_limit: boolean,
    sub_name: boolean,
    sub_value: boolean,
    sub_name_edit: boolean,
    sub_value_edit: boolean,
}

export const GroupSuppliers = () =>{
    document.title = "Grupo de Fornecedores - MeLiBeat";
    const handleValidateStatus = useHandleErrors();

    let { id } = useParams<any>();
    let history = useHistory();
    const {showHideModal} = useModal();
    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })


    const fetchSuppliers = (query:any=undefined) => axs.get<supplierGroup>(`/bling/contacts/relation/`, {params: query}).then((r: any) => {
        handleValidateStatus(r);
        return r.data
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    })
    const [selectedSupplier, setSelectedSupplier] = React.useState<string[]>([]);

    const suppliersData: State<suppliersData> = useState<suppliersData>({
        name: undefined,
        creditLimit: undefined
    })
    suppliersData.attach(Untracked)
    const fetchResource = (query:any=undefined) => axs.get<IGroupSuppliers>(`/groupsuppliers/${id}`, {params: query}).then((r: any) => {
        handleValidateStatus(r);
        const suppliers = Object.values(r.data.suppliersRelation).map((item: any)=> item.id)
        setSelectedSupplier(suppliers)
        Untracked(suppliersData).set({name: r.data.name, creditLimit: r.data.creditLimit})
        return r.data
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    })
    
    const data: State<IGroupSuppliers> = useState<IGroupSuppliers>({
        name: '',
        suppliersRelation: [],
        creditLimit: 0,
        creditHighest: {
          date: '',
          value: 0,
        },
        additionalCredits: [],
        date_created: '',
        last_updated: '',
    })

    const childrenItems: State<supplierGroup[]> = useState<supplierGroup[]>([]);
    childrenItems.attach(Untracked);

    const additionalCredits: State<additionalCredits> = useState<additionalCredits>({
        name: '',
        new_value: '',
        _id: '',
    })
    const editAdditionalCredits: State<additionalCredits> = useState<additionalCredits>({
        name: '',
        new_value: '',
        _id: '',
    })

    const showModal: State<boolean> = useState<boolean>(false)
    const inputsError: State<inputError> = useState<inputError>({
        main_name: false,
        suppliers: false,
        credit_limit: false,
        sub_name: false,
        sub_value: false,
        sub_name_edit: false,
        sub_value_edit: false,
    })
  
    const handleChange = (event: SelectChangeEvent<typeof selectedSupplier>) => {
      const {
        target: { value },
      } = event;
      setSelectedSupplier(
        typeof value === 'string' ? value.split('') : value,
      );
    };
  
    const {DarkMode, } = useDarkMode();

    useEffect(() => {
        if(id){
            data.set(fetchResource())
        }
        childrenItems.set(fetchSuppliers());
    }, [])

    const options = {
        palette:{
          tableHeader: {
            main: '#F7CE3E',
            dark: '#ac902b',
            light: '#f8d764',
            contrastText: '#000'
          },
        },
        alternate: true,
    }

    const columns = [
        {
            headerName: "Nome",
            field: "name",
            type: "string",
            headerAlign: 'center',
            flex: 2,
            minWidth: 200,
            renderCell: (rowData: any) =>{
                return(
                    <Tippy content={rowData.row.name}>
                        <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            <span title={rowData.row.name}>{rowData.row.name}</span>
                        </main>
                    </Tippy>
                )
            }
        },
        {
            headerName: "Valor",
            field: "value",
            type: "string",
            headerAlign: 'center',
            flex: 2,
            minWidth: 200,
            renderCell: (rowData: any) =>{
                return(
                    <span>{rowData.row.value}</span>
                )
            }
        },
    ]

    const defaultTheme  = createTheme({
        palette:{
          mode: DarkMode ? 'dark' : 'light',
          ...options.palette,
        }
    });

    const handleAddItem = (evt: React.BaseSyntheticEvent)=>{
        if(additionalCredits.name.value === '' && additionalCredits.new_value.value === ''){
            toast.info("Todos os campos precisam estar preenchidos.");
            inputsError.merge({
                sub_name: true, sub_value: true
            });
        }else if(additionalCredits.name.value === ''){
            toast.info('O campo "Nome" precisa estar preenchido.');
            inputsError.sub_name.set(true);
        }
        else if(additionalCredits.new_value.value === ''){
            toast.info('O campo "Valor" precisa estar preenchido.');
            inputsError.sub_value.set(true);
        }
        else{
            for(let item of data.additionalCredits.attach(Downgraded).get()){
                if(item.name ===  additionalCredits.name.attach(Downgraded).get()){
                    toast.info('Não é possível adicionar itens com nomes semelhantes.');
                    return
                }
            }
            const values: any = {
                "_id": `${Math.random()*10}-${additionalCredits.name.value}`,
                "name": additionalCredits.name.attach(Downgraded).get(),
                "value": additionalCredits.new_value.attach(Downgraded).get(),
            }
            additionalCredits.name.set('');
            additionalCredits.new_value.set('');
            data.additionalCredits.merge([values]);
            inputsError.merge({
                main_name: false,
                suppliers: false,
                credit_limit: false,
                sub_name: false,
                sub_value: false,
                sub_name_edit: false,
                sub_value_edit: false,
            })
        }
    }

    const handleUpdate = (row: any) =>{
        editAdditionalCredits._id.set(row._id);
        editAdditionalCredits.name.set(row.name);
        editAdditionalCredits.new_value.set(row.value);
        showModal.set(true);
    }

    const handleEditItem = ()=>{
        if(editAdditionalCredits.name.value === undefined && editAdditionalCredits.new_value.value === undefined){
            toast.info("Todos os campos precisam estar preenchidos.");
            inputsError.merge({
                sub_name_edit: true, sub_value_edit: true
            });
        }else if(editAdditionalCredits.name.value === undefined){
            toast.info('O campo "Nome" precisa estar preenchido.');
            inputsError.sub_name_edit.set(true);
        }
        else if(editAdditionalCredits.new_value.value === undefined){
            toast.info('O campo "Valor" precisa estar preenchido.');
            inputsError.sub_value_edit.set(true);
        }
        else{
            for(let item of data.additionalCredits.attach(Downgraded).get()){
                if(item.name ===  editAdditionalCredits.name.attach(Downgraded).get()){
                    toast.info('Não é possível adicionar itens com nomes semelhantes.');
                    return
                }
            }
            const values: any = {
                "_id": editAdditionalCredits._id.attach(Downgraded).get(),
                "name": editAdditionalCredits.name.attach(Downgraded).get(),
                "value": editAdditionalCredits.new_value.attach(Downgraded).get(),
            }
            editAdditionalCredits._id.set('');
            editAdditionalCredits.name.set('');
            editAdditionalCredits.new_value.set('');
            showModal.set(false);
            data.additionalCredits.merge([values]);
            inputsError.merge({
                main_name: false,
                suppliers: false,
                credit_limit: false,
                sub_name: false,
                sub_value: false,
                sub_name_edit: false,
                sub_value_edit: false,
            })
        }
    }

    const handleCancel = (evt: React.BaseSyntheticEvent) =>{
        history.goBack()
    }

    const handleSave = (evt: React.BaseSyntheticEvent) =>{
        if(suppliersData.name.value === undefined && suppliersData.creditLimit.value === undefined && selectedSupplier.length <= 0){
            toast.info("Todos os campos precisam estar preenchidos.");
            inputsError.merge({
                main_name: true,
                suppliers: true,
                credit_limit: true,
                sub_name: true,
                sub_value: true,
            })
        }else if(suppliersData.name.value === undefined){
            toast.info('O campo "Nome" precisa estar preenchido');
            inputsError.main_name.set(true);
        }
        else if(selectedSupplier.length <= 0){
            toast.info('O campo "Fornecedores" precisa estar preenchido');
            inputsError.suppliers.set(true);
        }
        else if(suppliersData.creditLimit.value === undefined){
            toast.info('O campo "Limite de Crédito" precisa estar preenchido');
            inputsError.credit_limit.set(true);
        }
        else{
            const suppliersRelation = childrenItems.attach(Downgraded).get().forEach((item: any)=> {
                for(let supplier of selectedSupplier){
                    if(item.id === supplier){
                        return {
                            id: item.id,
                            cnpj: item.cnpj
                        }
                    }
                }
            })
            const processedData = {
                name: suppliersData.name.value,
                suppliersRelation: suppliersRelation,
                creditLimit: suppliersData.creditLimit.value,
                additionalCredits: data.additionalCredits.get()
            }
            axs.post<any>(`/groupsuppliers/`, processedData).then((r: any) => {
                toast.info("Dados Salvos!");
                return r.data
            }).catch((err: any)=>{
                handleValidateStatus(err.response)
            })
            inputsError.merge({
                main_name: false,
                suppliers: false,
                credit_limit: false,
                sub_name: false,
                sub_value: false,
                sub_name_edit: false,
                sub_value_edit: false,
            })
            
        }
    
    }

    
    const confirmDelete = async(value: boolean, id: string|number)=>{
        if(value){
            toast.success('Produto Removido com Sucesso!');
            const filteredData = data.additionalCredits.attach(Downgraded).get().filter((item: any)=> item._id !== id);
            data.merge({additionalCredits: filteredData})
        }else{
            toast.info("Você escolheu não deletar!");
        }
    }


    if(data.promised || childrenItems.promised){ //Enquanto for promisse ou estiver carregando
        return (
            <>
                <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}> 
                    <div className="content-wrapper">
                        <main className="main-content container-fluid">
                            <Loading />
                        </main>
                    </div>
                </div>
            </>
        )
    }

    if (data.error || childrenItems.error) { // Quando o promise dar reject
        return (<ErrorScreen />)
    }

    return(
        <ThemeProvider theme={defaultTheme}>
            <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
                {
                    showModal.get() ?
                    <BasicModal closeFunction={()=> showModal.set(false)}>
                        <Paper style={{ width: '100%', padding: "2rem", display: 'flex', flexDirection: 'column', gap: '2rem'}}>
                            <Box style={{ width: '100%', padding: "2rem", display: 'flex', gap: '2rem', justifyContent: 'flex-start' }}>
                                    <BaseInput 
                                        error={inputsError.sub_name_edit.value}
                                        label="Nome" 
                                        variant="standard" 
                                        type='text'
                                        value={editAdditionalCredits.name.value}
                                        onChange={(evt: React.BaseSyntheticEvent)=>{
                                            editAdditionalCredits.name.set(evt.target.value);
                                        }}
                                    />
                                    <BaseInput 
                                        error={inputsError.sub_value_edit.value}
                                        label="Valor" 
                                        variant="standard" 
                                        type='number'
                                        value={editAdditionalCredits.new_value.value}
                                        onChange={(evt: React.BaseSyntheticEvent)=>{
                                            editAdditionalCredits.new_value.set(evt.target.value);
                                        }}
                                    />
                                    <Button variant="contained" startIcon={<AddIcon />} style={{fontSize: FONT_SIZE}} onClick={handleEditItem}>
                                        Editar Item
                                    </Button>
                                </Box>
                        </Paper>
                    </BasicModal>
                    :
                    null
                }
                <BugReportModal />
                <div className="content-wrapper">
                    <main className="main-content container-fluid">
                        <ModalConfirm />
                        <Paper style={{ width: '100%', height: '100%', padding: "2rem", display: 'flex', flexDirection: 'column', gap: '2rem'}}>
                            <h3>Cadastro Grupo de Fornecedores</h3>
                            <Box style={{ width: '100%', padding: "2rem", display: 'flex', gap: '2rem', justifyContent: 'space-between' }}>
                                <BaseInput 
                                    error={inputsError.main_name.get() === true ? true:false}
                                    label="Nome" 
                                    variant="standard" 
                                    type='text'
                                    value={suppliersData.name.value}
                                    onChange={(evt: React.BaseSyntheticEvent)=>{
                                        suppliersData.name.set(evt.target.value);
                                    }}
                                />
                                <FormControl sx={{ flex: 1 }}>
                                    <InputLabel id="multiple-name-label" style={{fontSize: FONT_SIZE}}>Fornecedores</InputLabel>
                                    <Select
                                        error={inputsError.suppliers.value}
                                        labelId="multiple-name-label"
                                        id="multiple-name"
                                        multiple
                                        value={selectedSupplier}
                                        onChange={handleChange}
                                        variant='standard'
                                        MenuProps={MenuProps}
                                        style={{fontSize: FONT_SIZE}}
                                        >
                                        {
                                            childrenItems.attach(Downgraded).get().map((item) => (
                                                <MenuItem
                                                key={item.id}
                                                value={item.id}
                                                >
                                                    {item.nome}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <BaseInput 
                                    error={inputsError.credit_limit.value}
                                    label="Limite de Crédito" 
                                    variant="standard" 
                                    type='number'
                                    value={suppliersData.creditLimit.value}
                                    onChange={(evt: React.BaseSyntheticEvent)=>{
                                        suppliersData.creditLimit.set(evt.target.value);
                                    }}
                                />
                            </Box>
                            <Paper style={{ width: '100%', height: '100%', padding: "2rem", display: 'flex', flexDirection: 'column', gap: '2rem'}}>
                                <h3>Créditos Adicionais</h3>
                                <Box style={{ width: '100%', padding: "2rem", display: 'flex', gap: '2rem', justifyContent: 'flex-start' }}>
                                    <BaseInput 
                                        error={inputsError.sub_name.value}
                                        label="Nome" 
                                        variant="standard" 
                                        type='text'
                                        value={additionalCredits.name.value}
                                        onChange={(evt: React.BaseSyntheticEvent)=>{
                                            additionalCredits.name.set(evt.target.value);
                                        }}
                                    />
                                    <BaseInput 
                                        error={inputsError.sub_value.value}
                                        label="Valor" 
                                        variant="standard" 
                                        type='number'
                                        value={additionalCredits.new_value.value}
                                        onChange={(evt: React.BaseSyntheticEvent)=>{
                                            additionalCredits.new_value.set(evt.target.value);
                                        }}
                                    />
                                    <Button variant="contained" startIcon={<AddIcon />} style={{fontSize: FONT_SIZE}} onClick={handleAddItem}>
                                        Adicionar Item
                                    </Button>
                                </Box>
                                {
                                    data.additionalCredits.length > 0 ?
                                    <VirtualizedGrid 
                                        rows={data.additionalCredits.attach(Downgraded).get()}
                                        columns={columns}
                                        options={options}
                                        tableHeight={'auto'}
                                        title="Créditos Adicionais"
                                        actions={[
                                            {icon: 'delete', title: 'Deletar Item', method: (evt: any, row:any)=> showHideModal({show: true, title: "Quer mesmo deletar esse item?", execute: (confirm: boolean)=> confirmDelete(confirm, row._id)})}, 
                                            {icon: 'edit', title: 'Editar Item', method: (evt: any, row:any)=> handleUpdate(row)}
                                        ]} 
                                        defaultSort={{field: 'name', direction: 'asc'}}
                                    />
                                    :
                                    <Paper style={{ width: '100%', height: '100%', padding: "2rem", display: 'flex', alignItems: 'center', justifyContent:'center'}}>
                                        <h3>
                                            Adicione itens para que sejam mostrados na tabela.
                                        </h3>
                                    </Paper>
                                }
                                <Box style={{ width: '100%', padding: "2rem", display: 'flex', gap: '2rem', justifyContent: 'flex-end' }}>
                                    <Button variant="outlined" style={{fontSize: FONT_SIZE}} color='error' onClick={handleCancel}>
                                        Cancelar
                                    </Button>
                                    <Button variant="contained" style={{fontSize: FONT_SIZE}} color='success' onClick={handleSave}>
                                        Salvar
                                    </Button>
                                </ Box>
                            </Paper>
                        </Paper>
                    </main>
                </div>
            </div>
        </ThemeProvider>
    )
}