import { SideMenuData } from './SideMenuData';
import { SubMenu } from './SubMenu';
import { useDarkMode } from '../../hooks/useDarkMode';
import { FaMoon, FaBug } from 'react-icons/fa';
import { useLocation} from 'react-router-dom';
import moment from "moment";
import { useBugReport } from '../../hooks/useBugReport';
import './style.scss';

import { useState , State } from '@hookstate/core';
import { FiLogOut } from 'react-icons/fi'

const pages: any = {
    "/": "Home",
   "/vendas/visualizar-sku/": "Kpi por SKU",
   "/compras/lista-de-compras/": "Lista de Compras",
   "/compras/numeros-de-serie-compras/": "Números de Série Compras",
   "/compras/numeros-de-serie": "Números de Série",
   "/vendas/metricas-anuncios": "Métricas dos Anúncios",
   "/vendas/curva-abc/": "Curva ABC",
   "/mercado-livre/controle-de-qualidade-dos-anuncios": "Controle de Qualidade dos Anúncios",
   "/mercado-livre/precificacao-dos-anuncios/": "Precificação dos Anúncios",
   "/compras/gestao-custo-medio/": "Gestão de Custo Médio",
   "/cadastros/grupo-fornecedores/": "Grupo de Fornecedores",
   "/cadastros/gestao-grupo-fornecedores/": "Gestão Grupo de Fornecedores",
   "/cadastros/gestão-de-centros-de-custo": "Gestão Centros de Custo",
   "/cadastros/centros-de-custo/": "Centros de Custo",
   "/cadastro/cadastro-agrupamentos-skus": "Cadastro Agrupamentos SKUs",
   "/cadastro/cadastro-precificador": "Cadastro Precificador",
   "/fulfillment/analise-envios/": "Análise de Envios",
   "/fulfillment/reposicao-full-pcs": "Reposição Full PCs",
   "/fulfillment/reposicao-full":  "Reposição Full",
   "/fulfillment/alteracao-envio/": "Alteração Envio",
   "/fulfillment/relacao-envios/": "Relação dos Envios",
   "/compras/acompanhamento-de-compras": "Acompanhamento de Compras",
   "/compras/todas-as-listas-de-compras/": "Todas as Listas de Compras",
    "/compras/analise-de-compras": "Análise de Compras",
    "/settings": "Configurações",
    "/mercado-livre/catalogo": "Catálogo",
    "/mercado-livre/gestao-de-anuncios/": "Gestão de Anúncios",
    "/vendas/kpi-de-vendas/": "KPI de Vendas",
    "/vendas/kpi-de-vendas-por-dia/": "KPI de Vendas por Dia",
    "/vendas/formulacao-preco-venda/": "Formulação de Preço de Venda",
    "/financeiro/credito-fornecedores/": "Crédito Fornecedores",
    "/financeiro/gestao-lcre": "Gestão LCRE",
    "/financeiro/tabela-lcre": "LCRE",
    "/producao/ordens-de-producao": "Ordens de Produção",
    "/producao/alteracao-de-producao/": "Alteração Ordem de Produção",
    "/mercado-livre/historico-dias-ativos": "Histórico de Dias Ativos",

}

export const SideMenu = (props: any) => {
    const location = useLocation()
    const {BugReport, activateBugReport} = useBugReport();
    
    const {DarkMode, activateDarkMode} = useDarkMode();
    const sideMenuActive: State<boolean> = useState<boolean>(false);

    const handleLogout = (evt: React.BaseSyntheticEvent) =>{
        localStorage.removeItem('token')
        localStorage.removeItem('userLogged')
    }

    const handleBugReport = (evt: React.BaseSyntheticEvent)=>{
        const path = location.pathname;
        path.replaceAll('/', '')
        const pathArr = path.split('/').filter((item: string)=> item !== '')
        const module = pathArr[0] ? pathArr[0] : null
        const dateTime = moment().format("DD/MM/YYYY - HH:mm");
        const userLoggedEmail = localStorage.getItem('userLogged');
        activateBugReport({
            reportData: {
                "page": pages[path],
                "module": module,
                "date": dateTime,
                "userEmail": userLoggedEmail,
                "path": path
            },
            active: true
        })
        // console.log({
        //     "page": pages[path],
        //     "module": module,
        //     "date": dateTime,
        //     "userEmail": userLoggedEmail,
        //     "path": path
        // })
    }

    return (
        location.pathname !== "/login" ?
        <nav className="sidemenu-container" onMouseEnter={(evt: React.BaseSyntheticEvent)=> sideMenuActive.set(true)} onMouseLeave={(evt: React.BaseSyntheticEvent)=> sideMenuActive.set(false)} style={{width: (sideMenuActive.get() ? '30rem' : '4.8rem'), zIndex: (sideMenuActive.get() ? '999' : '99')}}>
            <div className="sidebarWrap">
                {
                    SideMenuData.map((item: any, index:number)=>{
                        return <SubMenu item={item} key={index} menuOpen={sideMenuActive.get()}/>
                    })
                }

                {/* Criação manual dos componentes do menu */}

                <span className="sidebarLink">
                    <div className="darkmode-container" style={{gap: sideMenuActive.get() ? '1rem':'2rem'}}>
                        <span className="icon" style={{display: !sideMenuActive.get() ? 'flex':'none'}}><FaMoon /></span>
                        <label className="switch" style={{display: sideMenuActive.get() ? 'inline-block':'none'}}>
                            <input type="checkbox" checked={DarkMode}  onChange={(evt: React.BaseSyntheticEvent)=> activateDarkMode(!DarkMode)} />
                            <span className="slider round"></span>
                        </label>
                        <span className="darkmodeTitle" onClick={(evt: React.BaseSyntheticEvent)=> activateDarkMode(!DarkMode)}>Modo Noturno</span>
                    </div>
                </span>

                <span className="sidebarLink">
                    <div className="logout-container" onClick={handleBugReport}>
                        <span className="icon"><FaBug /></span>
                        <span className="logout-title">Bug Report</span>
                    </div>
                </span>

                <span className="sidebarLink">
                    <div className="logout-container" onClick={handleLogout}>
                        <span className="icon"><FiLogOut /></span>
                        <a href='/login'>Logout</a>
                        {/* <a href="/login" className="logout-title">Logout</a> */}
                    </div>
                </span>


                <SubMenu item={''} menuOpen={false} style={{pointerEvents: "none"}}/>
                <SubMenu item={''} menuOpen={false} style={{pointerEvents: "none"}}/>
            </div>
        </nav>
        :
        null
    )
}

