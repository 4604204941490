import { columns, options } from '../../../components/table/files/AdsManagementData';
import { toast } from 'react-toastify';
import noData from '../../../assets/images/nodata.svg';
import { BasicForm ,InputMainContainer } from '../../../components/Form/BasicForm';
import { BasicModal } from '../../../components/modal/BasicModal';
import { PopOverMenuModal } from '../../../components/modal/PopOverMenuModal';
import { Accordion } from '../../../components/accordion/Accordion';
import {CaptionsCpr} from '../../../components/accordion/files/CaptionsAdsManagement';
import { BaseSyntheticEvent } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Popper from '@material-ui/core/Popper';
import { MLBS, URLModal, IRawBlingProducts, IBlingProducts, ObjetoVazio } from "../../../types/Types";
import { useDarkMode } from '../../../hooks/useDarkMode';
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Loading } from '../../../components/Loading/Loading';
import { BugReportModal } from '../../../components/modal/BugReportModal';

const StyledPopper = styled(Popper)({
    "& .MuiAutocomplete-listbox": {
      '& li':{
        fontSize: 'clamp(1rem, 1vw, 1.1rem)',
      }
    }
});

export const AdsManagement = (props:any) =>{
    document.title = "Gestão de Anúncios - MeLiBeat";
    const handleValidateStatus = useHandleErrors();

    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const fetchResource = (query:any=undefined) => axs.get<MLBS[]>('/mlbs/', {params: query}).then((r: any) => {
        handleValidateStatus(r)
        return processData(r.data);
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    })

    const fetchBling = () => axs.get<IRawBlingProducts[]>('/bling/products/').then((r: any) => {
        const processedProducts = Object.entries(r.data).map((item: any)=>{
            let obj:IRawBlingProducts = item[1]
            return {label: `${obj.sku} - ${obj.title}`, sku: obj.sku, cost: obj.cost}
        })
        return processedProducts
    }).catch((err: any)=>{
        return []
    })
    const data: State<MLBS[]> = useState<MLBS[]>([]);

    const showModal: State<boolean> = useState<boolean>(false);
    const showPopOverMenuModal: State<boolean> = useState<boolean>(false);
    const blingProducts: State<IBlingProducts[]> = useState<IBlingProducts[]>(fetchBling);

    const mlbId: State<string | undefined> = useState<string | undefined>('');
    const variationId: State<string | undefined> = useState<string | undefined>('');
    const sku: State<string> = useState<string>('');
    const overwriteSkus: State<boolean> = useState<boolean>(false);
    const descriptionInBling: State<boolean> = useState<boolean>(false);
    const menuPosition: State<object> = useState<object>({'x': Number, 'y': Number});
    const urlModal: State<URLModal> = useState<URLModal>({url: '', title: ''});
    const {DarkMode, } = useDarkMode();
    
    useEffect(() => {
        data.set(fetchResource())
    }, [])

    const handlePopOverMenuModal = ()=>{
        showPopOverMenuModal.set(false);
        showModal.set(true)
    }

    const handleModal = (event: MouseEvent, value: MLBS)=>{
        menuPosition.set({x: (event.pageX-270), y: event.pageY})
        showPopOverMenuModal.set(true);
        mlbId.set(value.id);
        variationId.set(value.variation_id);
    }

    const handleSubmitMLB = async(evt: BaseSyntheticEvent)=>{
        evt.preventDefault();
        const processedData:ObjetoVazio = {
            "id_mlb": mlbId.get(),
            "variation_id": variationId.get(),
            "sku": sku.get()
        }
        if(urlModal.url.value === "/mlbs/taxdata/sku"){
            processedData["updateDataTax"] = overwriteSkus.get()
        }else{
            processedData["descriptionInBling"] = descriptionInBling.get()
        }

        axs.post<MLBS[]>(urlModal.url.value, processedData).then((r: any) => {
            if(r.status === 200){
                if(urlModal.url.value === "/mlbs/taxdata/sku"){
                    toast.success(`Dados Fiscais do anúncio: ${mlbId.get()} alterado para o SKU: ${r.data.sku}`);
                }else{
                    toast.success(`Informações do anúncio: ${mlbId.get()} alterado para as informações do SKU: ${r.data.sku}`);
                }
            }
        }).catch((err: any)=>{
            handleValidateStatus(err.response)
        })
        
        if(urlModal.url.value === "/mlbs/taxdata/sku"){
            overwriteSkus.set(false)
        }else{
            descriptionInBling.set(false)
        }
        showModal.set(false);
    }

    const processData = (values: MLBS[])=>{
        const processedArray = values.map((item: MLBS)=>{
            let tags: string[] = [];
            let translatedStatus: string = 'N/A';
            if(item.free_shipping) tags.push('Envio Grátis');
            if(item.fulfillment) tags.push('Fulfillment (Sim)')
            else tags.push('Fulfillment (Não)')

            if(item.catalog) tags.push('Catálogo');
            if(item.catalog_relation) tags.push('Relação do Catálogo');
            if(item.deal) tags.push('Promoção');
            if(parseFloat(item.health)*100 >= 70) tags.push('Anúncio saudável');
            if(parseFloat(item.health)*100 < 70) tags.push('Anúncio não saudável');
            if(item.status === "active") {
                tags.push('Status: Ativo');
                translatedStatus = 'Ativo';
            }
            if(item.status === "inactive") {
                tags.push('Status: Inativo');
                translatedStatus = 'Intivo';
            }
            if(item.status === "paused") {
                tags.push('Status: Pausado');
                translatedStatus = 'Pausado';
            }
            if(item.status === "under_review") {
                tags.push('Status: Em revisão');
                translatedStatus = 'Em Revisão';
            }
            if(item.status === "closed") {
                tags.push('Status: Fechado');
                translatedStatus = 'Fechado';
            }
            if(item.listing_type === 'gold_special') tags.push('Anúncio Clássico');
            if(item.listing_type === 'gold_pro') tags.push('Anúncio Premium');

            item['translated_status'] = translatedStatus;
            item['new_tags'] = tags;

            return item
        })
        return processedArray
    }

    if(data.promised){ //Enquanto for promisse ou estiver carregando
        return (
            <>
                <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}> 
                    <div className="content-wrapper">
                        <main className="main-content container-fluid">
                            <Loading />
                        </main>
                    </div>
                </div>
            </>
        )
    }

    if (data.error) { // Quando o promise dar reject
        return (<ErrorScreen />)
    }

    return(
        <>
            {
                showModal.get() === true ?
                <BasicModal closeFunction={()=> showModal.set(false)}>
                    <BasicForm SubmitFunction={(evt: React.BaseSyntheticEvent)=> handleSubmitMLB(evt)}>
                        <InputMainContainer>
                            <InputMainContainer>
                                <div className="input-container">
                                    
                                    <Autocomplete
                                        id="skus"
                                        className="skus-select"
                                        options={blingProducts.attach(Downgraded).get()}
                                        getOptionLabel={(option: any) => option.label}
                                        disableClearable
                                        renderInput={(params) => <TextField {...params} placeholder="SKU" />}
                                        PopperComponent={(props: any)=> <StyledPopper {...props} style={{ width: "fit-content", padding: "1rem", display: "flex", flexDirection: "column", gap: '1rem' }} placement='bottom-start' />}
                                        onChange={(evt: React.BaseSyntheticEvent, value: any)=> {
                                            sku.set(value.sku)
                                        }}
                                    />
                                </div>
                            </InputMainContainer>
                        </InputMainContainer>

                        {
                            urlModal.url.value === "/mlbs/taxdata/sku" ?
                            <InputMainContainer>
                                <FormControlLabel
                                    label="Sobrescrever SKUs Existentes"
                                    sx={{'& .MuiFormControlLabel-label': {fontSize: 'clamp(1.3rem, 1vw, 1.5rem)'}}}
                                    control={
                                        <Checkbox
                                            sx={{'& .MuiSvgIcon-root': {width: '2.5rem', height: '2.5rem'}}}
                                            defaultChecked={false}
                                            onChange={(evt: any)=>overwriteSkus.set(evt.target.checked)}
                                        />
                                    }
                                />
                            </InputMainContainer>
                            :
                            <InputMainContainer>
                                <FormControlLabel
                                    label="Descrição do Bling"
                                    sx={{'& .MuiFormControlLabel-label': {fontSize: 'clamp(1.3rem, 1vw, 1.5rem)'}}}
                                    control={
                                        <Checkbox
                                            sx={{'& .MuiSvgIcon-root': {width: '2.5rem', height: '2.5rem'}}}
                                            defaultChecked={false}
                                            onChange={(evt: any)=>descriptionInBling.set(evt.target.checked)}
                                        />
                                    }
                                />
                            </InputMainContainer>

                        }

                        <InputMainContainer>
                            <button type="submit">{urlModal.title.value}</button>
                        </InputMainContainer>
                    </BasicForm>
                </BasicModal>
                :
                null
            }

            {
                showPopOverMenuModal.get() ?
                <PopOverMenuModal position={menuPosition.attach(Downgraded).get()} closeFunction={()=> showPopOverMenuModal.set(false)}>
                    <button onClick={(evt: React.BaseSyntheticEvent)=>{
                        urlModal.set({url: '/mlbs/taxdata/sku', title: "Alterar Dados Fiscais"})
                        handlePopOverMenuModal();
                    }}
                    >Alterar Dados Fiscais (Bling)</button>

                    <button onClick={(evt: React.BaseSyntheticEvent)=>{
                        urlModal.set({url: '/mlbs/infdata/sku', title: "Alterar Dados do Anúncio"})
                        handlePopOverMenuModal();
                    }}
                    >Alterar dos Dados do Anúncio (Bling)</button>
                </PopOverMenuModal>
                : null
            }


            <div className="wrapper">
                <BugReportModal />
                <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
                    <main className="main-content container-fluid">
                        <Accordion title={CaptionsCpr.title} data={CaptionsCpr.data} />
                        {
                            data.length > 0 ?
                            <VirtualizedGrid 
                                rows={data.attach(Downgraded).get()}
                                columns={columns}
                                options={options}
                                title="Gestão de Anúncios"
                                actions={[
                                    {icon: 'settings', title: 'Mais Ações', method: (event: any, row:any)=> {
                                        if(blingProducts !== undefined){
                                            handleModal(event, row)
                                        }else{
                                            toast.info("Por favor aguarde o carregamento dos dados do Bling!");
                                        }
                                    }}, 
                                ]}
                                defaultSort={{field: 'total_sold', direction: 'desc'}}
                            />
                            :
                            <div className="img-container">
                                <img src={noData} alt="Não foi possível renderizar o gráfico por falta de dados." />
                                <h4>Não foi possível renderizar os gráficos por falta de dados.</h4>
                            </div>
                        }
                    </main>

                </div>
            </div>
        </>
    )
}