import {FiZapOff, FiTrendingDown, FiPauseCircle, FiAlertCircle, FiExternalLink} from 'react-icons/fi';
import {RiAlertFill, RiAlertLine} from 'react-icons/ri'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'react-tippy/dist/tippy.css';
import {Tooltip} from 'react-tippy';
import placeholder from '../../../assets/images/placeholder.jpg';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';


import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { 
  GridFilterItem,
} from '@mui/x-data-grid';
import Link from '@mui/material/Link';

const defaultTheme  = createTheme();

const StyledPopper = styled(Popper)({
  "& .MuiAutocomplete-listbox": {
    '& li':{
      fontSize: 'clamp(1.1rem, 1vw, 1.3rem)',
    }
  }
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function CheckboxesTags(props: any) {
  const { item, applyValue, options:tag_options } = props;
  // const selected_values = item.value ? item.value.map((elm: any)=> elm.title) : [];

  const handleFilterChange = (event: any, value: any) => {
    applyValue({ ...item, value: value });
  };

  return (
    <Autocomplete
      multiple
      options={tag_options}
      disableCloseOnSelect
      getOptionLabel={(option: any) => option.title}
      defaultValue={item.value}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
            style={{ marginRight: 8 }}
            // checked={selected_values.includes(option.title)}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label="Tags" variant="standard" sx={{'& .MuiChip-root':{fontSize: 'clamp(1rem, 1.1vw, 1.2rem)'}}} />
      )}
      //PopperComponent={StyledPopper}
      onChange={handleFilterChange}
    />
  );
}

const checkVisibility = (column: string)=>{
  const storageColumnsData = localStorage.getItem('Reposição Full') ? localStorage.getItem('Reposição Full Pc') : null;
  if(storageColumnsData !== null){
    const localData = JSON.parse(storageColumnsData);
    const shouldHide = localData.find((item: any)=> item.field === column);
    return shouldHide !== undefined ? !shouldHide.visibility : false
  }
  return false
}


// const tooltipHeaderValues = {
//   "Vendas-Período": "Total de Vendas durante o período selecionado (histórico de dias)",
//   "Venda-Estimada": "Cálculo Realizado com base nas Vendas do Período e calculando projeção com base nos dias ativos de cada anúncio",
//   "Lucro-Estimado": "Cálculo Realizado à partir da projeção de vendas e do lucro médio.",
//   "Origem": "Determinado pela regra do SKU, utilizado para separação e indicação da Origem (Comprado, parceria Fornecedor).",
//   "SKU": "Código interno do produto",
//   "Tags": "Ícones para Rápida identificação de situações do produto.",
//   "Geral": "Estoque Geral (Quantitativo)",
//   "FULL": "Estoque Fulfillment (Quantitativo)",
//   "ETP": "Estoque Em Transporte (Quantitativo)",
//   "Margem-Lucro-Médio": " Calculo baseado no histórico de vendas.",
//   "Duração-Estoque": "Tempo de Duração do Estoque em Dias",
//   "Duração-Estoque-Full": "Tempo de Duração do Estoque do Fulfillment em Dias."
// }

const tagColors: any = {
  "Sem Fulfillment": ["#706fd3", (<FiZapOff />)],
  "Sem Histórico": ["#227093", (<FiTrendingDown />)],
  "MLBs Pausados": [ "#FFC312", (<FiPauseCircle />)],
  "Sem reposição": ["#EA2027", (<FiAlertCircle />)],
  "Diferença no Estoque Fulfillment": ["#D980FA", (<RiAlertFill />)],
  "Reposição Necessária por MLB": ["#079992", (<RiAlertLine />)],
};


export const columns = [
    { 
      headerName: "SKU", 
      field: "_id", 
      type: 'string',
      flex: 1,
      minWidth: 100,
      headerAlign:"center",
      hide: checkVisibility('_id'),
      renderCell: (rowData: any) => {
        const idUrl = rowData.row.group_skus ? rowData.row.group_id : rowData.row._id
        return (
          <Tippy content={
            <div>{rowData.row._id}</div>
          } maxWidth={'100%'}>
            <ThemeProvider theme={defaultTheme}>
            <div title={rowData.row.id} style={{width: 'fit-content', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
              <Link 
                href={`/vendas/visualizar-sku/${rowData.row.group_skus}/${idUrl}`} 
                target="_blank" rel="noreferrer" 
                color={'inherit'} 
                sx={{'&:hover':{color: defaultTheme.palette.primary.dark}}}
                style={{fontSize: 'clamp(1.2rem, 1vw, 1.3rem)', display: 'flex', alignItems: 'center', gap: '5px', textDecoration: 'none'}} 
              >
                  <div>{rowData.row._id}</div><FiExternalLink />
              </Link>
            </div>
          </ThemeProvider>
        </Tippy>
        )
      }
    },
    { 
      headerName: "Origem", 
      field: "origin", 
      flex: 0.5,
      minWidth: 80,
      headerAlign:"center",
      type: 'singleSelect',
      valueOptions:['RM', 'TC'],
      hide: checkVisibility('origin'),
      renderCell: (rowData: any) =>{
        return(
          <div style={{width: '5rem'}}>
              {rowData.row.origin}
          </div>
        )
      }
    },
    { 
        headerName: "Tipo",
        field: "type", 
        type: 'singleSelect',
        flex: 0.5,
        minWidth: 80,
        headerAlign:"center",
        valueOptions:["AV", "KIT", "PC"],
        hide: checkVisibility('type'),
      },
    {
        headerName: "Imagem",
        field: "photo",
        flex: 1,
        minWidth: 80,
        headerAlign:"center",
        hide: checkVisibility('photo'),
        renderCell: (rowData: any) =>{
          return(
            <div className="img" style={{width: 30, height: 30, borderRadius: '50%'}}>
                <img src={rowData.row.photo !== "" ? rowData.row.photo : placeholder} alt="Imagem do produto" style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 'inherit'}}/>
            </div>
          )
        }
    },
  {
    headerName: "Produto",
    field: "title",
    flex: 4,
    minWidth: 300,
    headerAlign:"center",
    type: "string",
    hide: checkVisibility('title'),
    renderCell: (rowData: any) => {
        return (
            <Tippy content={
                <div>{rowData.row.title}</div>
            } maxWidth={'100%'}>
                <div style={{width: '30rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}} title={rowData.row.title}>
                    {rowData.row.title}
                </div>
            </Tippy>
        )
    }
  },
  {
    headerName: "Tags",
    field: "tags",
    type: "selection",
    flex: 1,
    minWidth: 100,
    headerAlign:"center",
    sortable:false,
    hide: checkVisibility('tags'),
    filterOperators: [{
      value: 'contains',
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        const selected_values = filterItem.value ? filterItem.value.map((item: any)=> item.title) : [];
        if (filterItem.value == null || selected_values === []) {
          return null;
        }
  
        return ({ value }:{ value: any }): boolean => {
          if (typeof value === 'object') {
            return selected_values.every((item: any)=> value.includes(item))
          }
          return selected_values.every((item: any)=> value.includes(item))
        };
      },
      // InputComponent: GridFilterInputValue,
      InputComponent: CheckboxesTags,
      InputComponentProps: { type: 'singleSelect', options: [{title: 'Sem reposição'}, {title: 'Sem Fulfillment'}, {title: 'MLBs Pausados'}, {title: 'Sem Histórico'}, {title: 'Diferença no Estoque Fulfillment'}, {title: 'Reposição Necessária por MLB'}]},
    }],
    valueOptions: ['Sem reposição', ' Sem Fulfillment', 'MLBs Pausados', 'Sem Histórico', 'Diferença no Estoque Fulfillment', 'Reposição Necessária por MLB'],

    renderCell: (rowData: any) => {
        return (
            <div className="tags-container" style={{marginTop: 0, flexWrap: 'nowrap', display: 'flex', gap: '5px'}}>
            {
                rowData.row.tags.map((item: any, index: any)=>{
                    if(item === "Diferença no Estoque Fulfillment"){
                        return (
                            <Tippy 
                                content={
                                    <div>
                                        {<strong>{item}</strong>}
                                        <div>
                                            Estoque ML Full: <></>
                                            <strong style={{color: "#F7CE3E"}}>{rowData.row.stock_full_ml}</strong>
                                        </div>
                                    </div>
                                }
                                key={index}
                            >
                                <span className="tag-medium" title={item} style={{color: tagColors[item][0]}}>{tagColors[item][1]}</span>
                            </Tippy>     
                        )
                    }
                    return (
                        <Tippy content={item} maxWidth={'25rem'} key={index}>
                            <span className="tag-medium" style={{color: tagColors[item][0]}}>{tagColors[item][1]}</span>
                        </Tippy>
                    )
              })
            }
          </div>
        )
    }
  },
  {
    headerName: "Geral", 
    field: "stockAll", 
    type: 'number',
    flex: 0.75,
    minWidth: 100,
    headerAlign:"center",
    hide: checkVisibility('stockAll'),
    renderCell: (rowData: any) => {
      return (
          rowData.row.group_skus ?
          rowData.row.stockAllDetail.length > 0 ?
            <Tooltip
                position="top"
                trigger="click"
                interactive={true}
                html={
                    rowData.row.stockAllDetail.map((item: any, index: any)=>{
                        return(
                        <div key={index} style={{width: "5rem", fontSize: "clamp(1.2rem, 1.4vw, 1.5rem)"}}>
                            <strong>SKU:</strong>
                            {` `}
                            {item.sku}
                            {` - `}
                            <strong>Estoque:</strong>
                            {` `}
                            {item.stock}
                        </div>
                        )
                    })}
                >
                <div style={{width: 'fit-content'}}>{rowData.row.stockAll}</div>
            </Tooltip>
            :
            <Tooltip
                position="top"
                trigger="click"
                interactive={true}
                html={
                    <div style={{width: "fit-content", fontSize: "clamp(1.2rem, 1.4vw, 1.5rem)"}}>
                        <strong>SKU: N/A</strong>
                        {` - `}
                        <strong>Estoque: N/A</strong>
                    </div>
                }
                >
                <div style={{width: 'fit-content'}}>{rowData.row.stockAll ? rowData.row.stockAll : 0}</div>
            </Tooltip>
        :
        <Tippy 
        content={Object.entries(rowData.row.stockAllDetail).map((item: any, index: any)=>{
            return(
            <div key={index}>{`${item[0]}: ${item[1]}`}</div>
            )
        })}
        >
            <div style={{width: 'fit-content'}}>{rowData.row.stockAll ? rowData.row.stockAll : 0}</div>
        </Tippy>
      )
    }
  },
  {
    headerName: "Full", 
    field: "stockFull", 
    type: 'number', 
    flex: 0.75,
    minWidth: 100,
    headerAlign:"center",
    hide: checkVisibility('stockFull'),
    renderCell: (rowData: any) => {
        return (
            rowData.row.group_skus ?
            rowData.row.stockFullDetail.length > 0 ?
                <Tooltip
                position="top"
                trigger="click"
                interactive={true}
                html={
                    rowData.row.stockFullDetail.map((item: any, index: any)=>{
                        return(
                        <div key={index} style={{width: "fit-content", fontSize: "clamp(1.2rem, 1.4vw, 1.5rem)"}}>
                            <strong>SKU:</strong>
                            {` `}
                            {item.sku}
                            {` - `}
                            <strong>Estoque:</strong>
                            {` `}
                            {item.stock}
                        </div>
                        )
                    })}
                >
                <div style={{width: 'fit-content'}}>{rowData.row.stockFull}</div>
            </Tooltip>
            :
            <Tooltip
                position="top"
                trigger="click"
                interactive={true}
                html={
                        <div style={{width: "fit-content", fontSize: "clamp(1.2rem, 1.4vw, 1.5rem)"}}>
                            <strong>SKU: N/A</strong>
                            {` - `}
                            <strong>Estoque: N/A</strong>
                        </div>
                    }
                >
                <div style={{width: 'fit-content'}}>{rowData.row.stockFull ? rowData.row.stockFull : 0}</div>
            </Tooltip>
          :
          <Tippy 
        content={Object.entries(rowData.row.stockFullDetail).map((item: any, index: any)=>{
            return(
            <div key={index}>{`${item[0]}: ${item[1]}`}</div>
            )
        })}
        >
            <div style={{width: 'fit-content'}}>{rowData.row.stockFull ? rowData.row.stockFull : 0}</div>
        </Tippy>
        )
    }
  },
  {
    headerName: "ETP", 
    field: "stockETP", 
    type: 'number', 
    flex: 0.75,
    minWidth: 100,
    headerAlign:"center",
    hide: checkVisibility('stockETP'),
    renderCell: (rowData: any) => {
        return (
            rowData.row.group_skus ?
                rowData.row.stockETPDetail.length > 0 ?
                <Tooltip
                    position="top"
                    trigger="click"
                    interactive={true}
                    html={
                        rowData.row.stockETPDetail.map((item: any, index: any)=>{
                            return(
                            <div key={index} style={{width: "fit-content", fontSize: "clamp(1.2rem, 1.4vw, 1.5rem)"}}>
                                <strong>SKU:</strong>
                                {` `}
                                {item.sku}
                                {` - `}
                                <strong>Estoque:</strong>
                                {` `}
                                {item.stock}
                            </div>
                            )
                        })}
                    >
                    <div style={{width: 'fit-content'}}>{rowData.row.stockETP ? rowData.row.stockETP : 0}</div>
                </Tooltip>
                :
                <Tooltip
                    position="top"
                    trigger="click"
                    interactive={true}
                    html={
                        <div style={{width: "fit-content", fontSize: "clamp(1.2rem, 1.4vw, 1.5rem)"}}>
                            <strong>SKU: N/A</strong>
                            {` - `}
                            <strong>Estoque: N/A</strong>
                        </div>
                    }
                    >
                    <div style={{width: 'fit-content'}}>{rowData.row.stockETP}</div>
                </Tooltip>
            :
            <Tippy 
        content={Object.entries(rowData.row.stockETPDetail).map((item: any, index: any)=>{
            return(
            <div key={index}>{`${item[0]}: ${item[1]}`}</div>
            )
        })}
        >
            <div style={{width: 'fit-content'}}>{rowData.row.stockETP ? rowData.row.stockETP : 0}</div>
        </Tippy>
        )
    }
  },
  {
    headerName: "Margem Média", 
    field: "margin_avg", 
    type: 'number',
    flex: 1,
    minWidth: 100,
    headerAlign:"center",
    hide: checkVisibility('margin_avg'),
    renderCell: (rowData: any) => {
      let margin = (rowData.row.margin_avg * 100).toFixed(2);
      return (
        <>
          {`${margin}%`}
        </>
      )
  }
},
  {
    headerName: "Vendas Período",
    field: "total_orders", 
    type: 'number', 
    flex: 0.75,
    minWidth: 100,
    headerAlign:"center",
    hide: checkVisibility('total_orders'),
    renderCell: (rowData: any) => {
        return (
            <div>{rowData.row.total_orders}</div>
        )
    }
  },
  {
    headerName: "Dur. do Estoque",  
    field: "stockAllDuration", 
    type: 'number',
    flex: 0.75,
    minWidth: 100,
    headerAlign:"center",
    hide: checkVisibility('stockAllDuration'),
    renderCell: (rowData: any) => {
      const roundedStock = rowData.row.stockAllDuration.toFixed(2);
      return (
        <>
          {`${Math.floor(parseFloat(roundedStock))}`}
        </>
      )
    }
  },
  {
    headerName: "Dur. Estoque Full", 
    field: "stockFullDuration", 
    type: 'number',
    flex: 0.75,
    minWidth: 100,
    headerAlign:"center",
    hide: checkVisibility('stockFulLDuration'),
    renderCell: (rowData: any) => {
      const roundedStock = rowData.row.stockFullDuration.toFixed(2);
      return (
        <>
          {`${Math.floor(parseFloat(roundedStock))}`}
        </>
      )
    }
  },
  {
    headerName: "Projeção 15d",
    field: "project_sells_25_days", 
    type: 'number',
    flex: 0.75,
    minWidth: 100,
    headerAlign:"center",
    hide: checkVisibility('project_sells_25_days'),
    renderCell: (rowData: any) => {
      return (
        <>
            <Tippy content={<strong>Projeção Diária: {parseInt(rowData.row.project_sells).toFixed(0)}</strong>} maxWidth={'25rem'}>
                <strong>{parseInt(rowData.row.project_sells_25_days).toFixed(0)}</strong>
            </Tippy>
        </>
      )
    }
  },
  {
    headerName: "Reposição Necessária",
    field: "stock_replenishment", 
    type: 'number',
    flex: 0.75,
    minWidth: 100,
    headerAlign:"center",
    hide: checkVisibility('stock_replenishment'),
    renderCell: (rowData: any) => {
      return (
        <>
          {parseInt(rowData.row.stock_replenishment).toFixed(0)}
        </>
      )
    }
  }
]


export const options = {
    palette:{
      tableHeader: {
        main: '#F7CE3E',
        dark: '#ac902b',
        light: '#f8d764',
        contrastText: '#000'
      },
    },
    
    alternate: true,
}