import { BrowserRouter as Route } from 'react-router-dom';

import { SideMenu } from '../../components/sideMenu/SideMenu';
import { Header } from '../../components/Header/Header';
import { ContentAccordion } from '../../components/accordion/ContentAccordion';
import {FaMoneyBillWaveAlt} from 'react-icons/fa';
import { YoutubePlayer } from './YoutubePlayer';
import { useEffect } from 'react';
import { BugReportModal } from '../../components/modal/BugReportModal';
import './style.scss';


export const TutorialPage = () =>{

    useEffect(() => {
        document.title = "Tutorial do sistema - MeLiBeat";
    }, [])

    return(
        <div className="wrapper">
            <BugReportModal />       
            <div className="content-wrapper">
                <Route>
                    <SideMenu />
                </Route>
                <main className="main-content container-fluid">
                    <h2>Tutoriais MeliBeat</h2>
                    <ContentAccordion class_name="accordion_tutorial_card"
                        title={
                            <div className="tutorial-cards">
                                <FaMoneyBillWaveAlt className="icon" /> Módulo Financeiro
                            </div>
                    }>
                        <h3 className="screen-title">Tela: Reposição Full</h3>
                        <YoutubePlayer embedId="yZhyx3B8hFI" />
                        <hr className="line-boundary" />
                        <h3 className="screen-title">Tela: Relação Full</h3>
                        <YoutubePlayer embedId="06UKldDuS_A" />
                    </ContentAccordion>

                    
                </main>
            </div>
        </div>
    )
}