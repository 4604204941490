import { FaAngleUp, FaAngleDown } from 'react-icons/fa';
import { useDarkMode } from '../../hooks/useDarkMode';
import { useState , State } from '@hookstate/core';

import './style.scss'

export const ContentAccordion = (props: any) => {
  const expanded: State<boolean> = useState<boolean>(props.initializeExpanded ? props.initializeExpanded:false)
  const {DarkMode, } = useDarkMode();

  return (
    <article className={`accordion bigAccordion container-fluid ${props.class_name} ${DarkMode ? 'dark-mode-accordion' : ''}`}>
      <div>
        <div onClick={() => expanded.set(!expanded.get())} className='accordion-title' style={{width: "100%"}}>
          {props.title}
        </div>
        <button className='btn' onClick={() => expanded.set(!expanded.get())}>
          {expanded.get() ? <FaAngleUp className="icon-medium" /> : <FaAngleDown className="icon-medium" />}
        </button>
      </div>
      {expanded.get() && props.children ?
        <>
          <hr />
          {
              props.children
          }
        </>
        
        :
        null  
    }
    </article>
  )
}
