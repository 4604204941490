import { useState } from 'react';
import './style.scss';
import { useDarkMode } from '../../hooks/useDarkMode';

export const ProgressIndicator = (props: any) =>{
    const [phases, ] = useState<any>(props.phases);
    const {DarkMode, } = useDarkMode();

    return(
        <>
        {
            phases ? 
                <div className={`progress-indicator-container ${DarkMode ? 'dark-mode-progress-indicator-container' : ''}`}>
                    {phases.map((item: any, index: any)=>{
                        return(
                            <div className="progress-indicator-inner-container" key={index}>
                                {
                                  index === 0 ?
                                  null
                                  :
                                  <div className={item.current || item.finished ? 'progress-line-current' : 'progress-line'}></div>
                                }
                                <div className={item.current ? 'progress-sub-container-current' : item.finished ? 'progress-sub-container-finished' : 'progress-sub-container'}>
                                    <strong className='progress-phase'>{index+1}</strong>
                                    <strong className="progress-phase-name">{item.name}</strong>
                                </div>
                            </div>
                        )
                    })}
                </div>
            :
            null
        }
        </>
    )
}