import { BrowserRouter as Route, useParams } from 'react-router-dom';
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import { useEffect } from 'react';

import { AllShippments } from './Components/AllShipments';
import { Precification } from './Components/Precification';
import { ChangeAds } from './Components/ChangeAds';
import { Loading } from '../../../components/Loading/Loading';
import { useDarkMode } from '../../../hooks/useDarkMode';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { Untracked } from '@hookstate/untracked';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { toast } from 'react-toastify';
import {AiOutlineLoading3Quarters} from 'react-icons/ai';
import './style.scss';
import Paper from '@mui/material/Paper';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import { useHandleErrors } from '../../../hooks/useHandleErrors';

import { BlingProductsType, MLBRelation, IRawBlingProducts, CompletedShippingRelation } from "../../../types/Types"
import socket from '../../../utils/socketConnection';
import IconButton from '@mui/material/IconButton';
import GroupIcon from '@mui/icons-material/Group';
import Badge from '@mui/material/Badge';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BugReportModal } from '../../../components/modal/BugReportModal';

export const ShippingUpdate = () =>{
    document.title = "Alteração dos Dados do Envio - MeLiBeat";
    const handleValidateStatus = useHandleErrors();
    let { id } = useParams<any>();
    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    // const fetchResource = (query:any=undefined) => axs.get<IShippingRelation>('/fulfillment/'+id, {params: query}).then((r: any) => {
    //     return r.data
    // })
    const allSKUS: State<IRawBlingProducts[]> = useState<IRawBlingProducts[]>([]);
    allSKUS.attach(Untracked);
    const fetchBling = () => axs.get<IRawBlingProducts[]>('/bling/products/').then((r: any) => {
        const processedProducts = Object.entries(r.data).map((item: any)=>{
            let obj:IRawBlingProducts = item[1]
            return {label: `${obj.sku} - ${obj.title}`, sku: obj.sku, cost: obj.cost}
            // return <option value={item[1].sku} key={index}>{item[1].title}</ option>
        })
        Untracked(allSKUS).set(r.data)
        return processedProducts
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    });
    const mlbVariations: State<MLBRelation> = useState<MLBRelation>({
        "_id": '',
        "variations": [{
            "variation_id": '',
            "variation_name": '',
            "marketplace_fee": 0,
            "cost_shipping": 0,
            "inventory_id": '',
            "tax": 0
        }],
    });
    mlbVariations.attach(Untracked);
    const fetchMLBS = () => axs.get<string[]>('mlbs/relation').then((r: any) => {
        const processedProducts: string[] = Object.entries(r.data).map((item: any)=>{
            // return <option value={item[1]._id} key={index}></option>
            return item[1]._id
        })
        Untracked(mlbVariations).set(r.data)
        return processedProducts
    }).catch((err: any)=>{
        return []
    });
    // const data: State<IShippingRelation> = useState<IShippingRelation>(fetchResource);
    const blingProducts: State<BlingProductsType> = useState<BlingProductsType>(fetchBling);
    const mlbs: State<string[]> = useState<string[]>(fetchMLBS);

    const maxShippingWeight: State<number> = useState<number>(0);
    maxShippingWeight.attach(Untracked);
    const maxCubicMeters: State<number> = useState<number>(0);
    maxCubicMeters.attach(Untracked);
    const {DarkMode, } = useDarkMode();
    const usersCount: State<number> = useState<number>(0);

    const LOADED = useState<boolean>(false)
    const activeTabIndex = useState<number>(0);
    // activeTabIndex.attach(Untracked);
    const currentTabIndex = useState<number | undefined>(undefined);
    currentTabIndex.attach(Untracked);
    const activeTabs = useState<CompletedShippingRelation>({
        "relation": false,
        "precification": false,
        "alteration": false
    })
    // activeTabs.attach(Untracked);

    const defaultTheme  = createTheme({
        palette:{
          mode: DarkMode ? 'dark' : 'light',
        },
    });

    useEffect(() => {
        socket.emit("fulfillment:createRoom", {'id': id});
        socket.emit("fulfillment:getStep", {'id': id});

        socket.on("usersCount", (result) => {
            usersCount.set(result.count);
        })
        socket.on("responseData", (result: any) => {
            switch(result.operation){
                case 'fulfillment:getStep':
                    activeTabs.set(result.data.completed);
                    handleCompletedTab(result.data.completed)
                    break;
                case 'fulfillment:getStepOne':
                    activeTabs.set(result.data.completed);
                    handleCompletedTab(result.data.completed)
                    break;
                case 'fulfillment:getStepTwo':
                    activeTabs.set(result.data.completed);
                    handleCompletedTab(result.data.completed)
                    break;
                case 'fulfillment:getStepThree':
                    activeTabs.set(result.data.completed);
                    handleCompletedTab(result.data.completed)
                    break
                default:
                    break;
            }
        })
        socket.on("responseError", (result) => {
            toast.error(result.message)
        })
    }, [])
    
    const handleCompletedTab = (completed: any)=>{
        if(!completed.relation && !completed.precification && !completed.alteration){
            activeTabIndex.set(0);
            currentTabIndex.set(0)
        }
        else if(completed.relation && !completed.precification && !completed.alteration){
            activeTabIndex.set(1);
            currentTabIndex.set(1)

        }
        else if(completed.relation && completed.precification && !completed.alteration){
            activeTabIndex.set(2);
            currentTabIndex.set(2)

        }
        else if(completed.relation && completed.precification && completed.alteration){
            activeTabIndex.set(2);
            currentTabIndex.set(2)
        }
        else{
            activeTabIndex.set(0);
            currentTabIndex.set(0)
        }
        if(LOADED.get() === false){
            LOADED.set(true)
        }
    }

    const handleResetPage = ()=>{
        window.location.reload()
    }

    if(allSKUS.promised || blingProducts.promised || mlbs.promised){ //Enquanto for promisse ou estiver carregando
        return (
            <>
                <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}> 
                    <div className="content-wrapper">
                        <main className="main-content container-fluid">
                            <div className="loading">
                                <AiOutlineLoading3Quarters className="loading-icon" />
                            </div>
                        </main>
                    </div>
                </div>
            </>
        )
    }

    if (allSKUS.error || blingProducts.error || mlbs.error) { // Quando o promise dar reject
        return (<ErrorScreen />)
    }

    return(
        <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
            <BugReportModal />
            <div className="content-wrapper">
                <main className="main-content container-fluid">
                    <ThemeProvider theme={defaultTheme}>
                        <Paper sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: 'fit-content', alignSelf: 'end', padding: '1.5rem 2rem', gap: '1rem'}}>
                            <span style={{fontSize: 'clamp(1.4rem, 1.2vw, 1.6rem)', fontWeight: 'bold'}}>Usuários Ativos: {usersCount.get()}</span>
                            <Badge badgeContent={usersCount.get()} color="primary" sx={{ float: 'right', display: 'flex', width: 'fit-content', '& span': {fontSize: 'clamp(1.2rem, 1.2vw, 1.4rem)'}}}>
                                <IconButton sx={{padding: '0'}}>
                                    <GroupIcon sx={{ width: '3rem', height: '3rem'}} />
                                </IconButton>
                            </Badge>
                        </Paper>
                    </ThemeProvider>
                    {
                        activeTabs.get() !== undefined && LOADED ?
                        <>
                            <div className={`currentTab ${DarkMode ? 'dark-mode-currentTab' : ''}`}>
                                <ProgressIndicator phases={[
                                    {
                                        name: "Relação", 
                                        current: !activeTabs.get().relation && !activeTabs.get().precification && !activeTabs.get().alteration ? true : false,
                                        finished: activeTabs.get().relation
                                    }, 
                                    {
                                        name: "Precificação", 
                                        current: activeTabs.get().relation && !activeTabs.get().precification ? true : false, 
                                        finished: activeTabs.get().precification
                                    }, 
                                    {
                                        name: "Alteração", 
                                        current: activeTabs.get().relation && !activeTabs.get().alteration ? true : false, 
                                        finished: activeTabs.get().alteration
                                    },
                                    {
                                        name: "Finalizado", 
                                        current: activeTabs.get().relation && activeTabs.get().precification && activeTabs.get().alteration ? true : false, 
                                        finished: activeTabs.get().relation && activeTabs.get().precification && activeTabs.get().alteration
                                    }
                                ]} />
                            </div>
                            {
                                allSKUS.length > 0 && blingProducts.length > 0 && mlbs.length > 0 && currentTabIndex.get() !== undefined && LOADED ?
                                
                                <Tabs selectedIndex={currentTabIndex.get()} onSelect={(index: any)=> {
                                        currentTabIndex.set(index);
                                        socket.removeAllListeners("responseData");
                                    }
                                }>
                                    <TabList>
                                        <Tab>Relação</Tab>
                                        <Tab disabled={activeTabIndex.get() < 1 ? true:false}>Precificação</Tab>
                                        <Tab disabled={activeTabIndex.get() < 1 ? true:false}>Alterações dos Anúncios</Tab>
                                    </TabList>
                                    <TabPanel>
                                        <AllShippments 
                                        itemId={id}
                                        resetPage={()=> handleResetPage()}
                                        blingProducts={blingProducts.attach(Downgraded).get()}
                                        mlbs={mlbs.attach(Downgraded).get()}
                                        mlbVariations={mlbVariations.attach(Downgraded).get()}
                                        allSKUS={allSKUS.attach(Downgraded).get()}
                                        activeUsers={usersCount.get()}
                                        />
                                    </TabPanel>

                                    <TabPanel>
                                        <Precification
                                            resetPage={()=> handleResetPage()} 
                                            itemId={id} 
                                        />
                                    </TabPanel>
                                    <TabPanel>
                                        <ChangeAds
                                            resetPage={()=> handleResetPage()} 
                                            itemId={id} 
                                            blingProducts={blingProducts.attach(Downgraded).get()}
                                        />
                                    </TabPanel>
                                </Tabs>
                                :
                                <Loading />
                            }
                        </>
                        :
                        null
                    }
                </main>
            </div>
        </div>
    )
}