import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import { Loading } from '../../../../components/Loading/Loading';
import TextField from '@material-ui/core/TextField';
import Button from '@mui/material/Button';

import { useDarkMode } from '../../../../hooks/useDarkMode';
import VirtualizedGrid from '../../../../components/table/VirtualizedGrid';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import Box from '@mui/material/Box';
import { useHandleErrors } from '../../../../hooks/useHandleErrors';
import { ErrorScreen } from '../../../../components/ErrorScreen/ErrorScreen';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { ObjetoVazio,IProductionProducts, IProductionOrders, IProductionUpdate, IComponentsToBuy } from "../../../../types/Types"
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const BUTTON_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.4rem)';
const INPUT_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.6rem)';

export const Planning = (props: any) =>{
    const { viewMode, itemId, handleChangeTabIndex } = props;
    const booleanViewMode = viewMode === "true" ? true : viewMode === false ? false : false;
    const handleValidateStatus = useHandleErrors();
    let debounceTimeout: any = useRef(null);
    const inputs: State<ObjetoVazio> = useState<ObjetoVazio>({})
    const inputsPriority: State<ObjetoVazio> = useState<ObjetoVazio>({})
    const priorityIncrement: State<number> = useState<number>(1)


    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })

    const fetchProducts = (query:any=undefined) => axs.get<IProductionUpdate[]>('/productionorders/products', {params: query}).then((r: any) => {
        r.data.productsProduction.forEach((item: any)=>{
            inputs.merge({[item._id]: 0})
            // inputsPriority.merge({[item._id]: 0})
            item['priority'] = 9999
        })
        return r.data
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    })
    
    const fetchProductionOrders = (query:any=undefined) => axs.get<IProductionOrders>('/productionorders/'+itemId, {params: query}).then((r: any) => {
        return r.data
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    })
    
    const data: State<IProductionProducts> = useState<IProductionProducts>({
        productsProduction: [], componentsDetail: []
    });
    const dataOrders: State<IProductionOrders> = useState<IProductionOrders>({
        components: [],
        date: '',
        finished: false,
        items: [],
        number: '',
        observation: '',
        status: '',
        __v: 0,
        _id: '',
    });
    const componentsToBuy: State<IComponentsToBuy[]> = useState<IComponentsToBuy[]>([]);

    const rowsSelected  = useState<string[]>([])

    useEffect(() => {
        if(booleanViewMode){
            dataOrders.set(fetchProductionOrders())
        }else{
            data.set(fetchProducts())
        }
    }, [])

    const checkVisibility = (column: string)=>{
        const storageColumnsData = localStorage.getItem('Planejamento') ? localStorage.getItem('Planejamento') : null;
        if(storageColumnsData !== null){
          const localData = JSON.parse(storageColumnsData);
          const shouldHide = localData.find((item: any)=> item.field === column);
          return shouldHide !== undefined ? !shouldHide.visibility : false
        }
        return false
    }

    const {DarkMode, } = useDarkMode();

    const defaultTheme  = createTheme({
        palette:{
          mode: DarkMode ? 'dark' : 'light',
        },
    });

    const handleSelection = (rows: string[])=>{
        rowsSelected.set(rows);
    }

    const handleCalculate = ()=>{
        const filteredRows = data.productsProduction.attach(Downgraded).get().filter((item:any)=> rowsSelected.attach(Downgraded).get().some((row: any)=> row === item._id && item.quantity > 0))
        if(filteredRows.length <= 0){
            toast.error('Itens selecionados com quantidades menores ou iguais a 0');
            return
        }
        toast.info('Processando!');
        const processedData = filteredRows.map((item: any)=>{
            return {"sku": item.sku, "quantity": item.quantity}
        })
        axs.post<IComponentsToBuy[]>('productionorders/components', {'items': processedData}).then((r: any) => {
            if(r.status === 200){
                componentsToBuy.set(r.data)
            }
        }).catch((err: any)=>{
            handleValidateStatus(err.response)
        })
    }

    const handleOPGenerate = ()=>{
        if(componentsToBuy.length <= 0){
            toast.error('Por favor realize a Seleção dos itens e o cálculo para gerar a OP.');
            return
        }
        const filteredRows = data.productsProduction.attach(Downgraded).get().filter((item:any)=> rowsSelected.attach(Downgraded).get().some((row: any)=> row === item._id && item.quantity > 0))
        toast.info('Processando!');
        axs.post<IComponentsToBuy[]>('/productionorders/', {'items': filteredRows, 'components': componentsToBuy.get()}).then((r: any) => {
            if(r.status === 200){
                handleChangeTabIndex(1, r.data._id)
            }
        }).catch((err: any)=>{
            handleValidateStatus(err.response)
        })
    }

    const recalculateMaxProduction = (components:any, productionProducts: any) => {
        const newProductsArr = JSON.parse(JSON.stringify(productionProducts)).sort((a: any, b:any) => {
            const valA = inputsPriority[a._id].get() !== undefined ? inputsPriority[a._id].get() : 999999
            const valB = inputsPriority[b._id].get() !== undefined ? inputsPriority[b._id].get() : 999999
            return valA - valB
        });
        let productsStock = JSON.parse(JSON.stringify(components))
        const prod = newProductsArr.map((product: any) => {
            const productId = product._id;
            const quantity = inputs[productId].get()
            const componentsMinValue: number[] = []
            for (const structure of product.structure) {
                if (structure.componente.codigo === '') { continue }
                const productComponentIdx = productsStock.findIndex((t: any) => t.sku === structure.componente.codigo)
                if (productComponentIdx > -1) {
                    productsStock[productComponentIdx].stock -= (inputs[productId].get() * structure.componente.quantidade)
                    componentsMinValue.push(Math.floor((productsStock[productComponentIdx].stock / structure.componente.quantidade)))
                } else {
                    continue // continue somente para fins de desenvolvimento
                }
            }
          // Verificação da possível quantidade a montar
          const quantityMaxProduction: number = Math.min(...componentsMinValue)

          return {
            ...product,
            quantityMaxProduction,
            quantity
          }
        })
        return prod
    }


    const options = {
        palette:{
          tableHeader: {
            main: '#F7CE3E',
            dark: '#ac902b',
            light: '#f8d764',
            contrastText: '#000'
          },
        },
        alternate: true,
        selection: true,
        rowStyle: (rowData: any) => {
            let row_class = '';
            if(rowData.finished) {
                row_class = "AllShipments-alternate-color-finished"
            }        
          return row_class
        },
    }

    const columns = [
    {
        headerName: "Data Criação",
        field: "dateCreate",
        type: "string",
        hide: checkVisibility('dateCreate'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (rowData: any) =>{
            return(
                rowData.row.date_created
            )
        }
    },
    {
        headerName: "SKU",
        field: "sku",
        type: "string",
        hide: checkVisibility('sku'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (rowData: any) =>{
            return(
                rowData.row.sku
            )
        }
    },
    {
        headerName: "Descrição",
        field: "description",
        type: "string",
        hide: checkVisibility('description'),
        headerAlign: 'center',
        flex: 2,
        minWidth: 200,
        renderCell: (rowData: any) =>{
            return(
                <Tooltip arrow placement="bottom" title={
                    <div style={{display: 'flex', flexDirection: 'column', maxHeight: '50rem', overflowY: 'scroll', fontSize: 'clamp(1.4rem, 1.4vw, 1.6rem)'}}>
                        {
                            rowData.row.components.length > 0 ?
                            rowData.row.components.map((item: any)=>{
                                return(
                                    <ul key={item.sku} style={{display: 'flex', flexDirection: 'column', padding: '1rem', gap: '.5rem', margin: '0'}}>
                                        <li style={{display: 'flex', flexDirection: 'row', gap: '0.5rem'}}>
                                            <strong>Estoque:</strong>
                                            <span>{item.stock}</span>
                                        </li>
                                        <li style={{display: 'flex', flexDirection: 'row', gap: '0.5rem'}}>
                                            <strong>Quantidade:</strong>
                                            <span>{item.quantity}</span>
                                        </li>
                                        <li style={{display: 'flex', flexDirection: 'row', gap: '0.5rem'}}>
                                            <strong>SKU:</strong>
                                            <span>{item.sku}</span>
                                        </li>
                                        <li style={{display: 'flex', flexDirection: 'column', gap: '0.1rem'}}>
                                            <strong>Descrição:</strong>
                                            <span>{item.description}</span>
                                        </li>
                                    </ul>
                                )
                            })
                            :
                            "N/A"
                        }                            
                    </div>
                } sx={{maxWidth: '40rem'}}>
                    <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                        <span title={rowData.row.description}>{rowData.row.description}</span>
                    </main>
                </Tooltip>
            )
        }
    },
    {
        headerName: "Estoque",
        field: "stock",
        type: "number",
        hide: checkVisibility('stock'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (rowData: any) =>{
            return(
                <Tippy content={
                    Object.entries(rowData.row.stockDetail).map((item: any)=>{
                        return(
                            <div key={Math.random()} style={{display: 'flex', flexDirection: 'row', padding: '1rem', gap: '1rem'}}>
                                <strong>{item[0]}:</strong>
                                <span>{item[1]}</span>
                            </div>
                        )
                    })
                }>
                    <strong>{rowData.row.stock}</strong>
                </Tippy>
            )
        }
    },
    {
        headerName: "Em Montagem",
        field: "stockInProduction",
        type: "number",
        hide: checkVisibility('stockInProduction'),
        headerAlign: 'center',
        flex: 2,
        minWidth: 200,
        renderCell: (rowData: any) =>{
            return(
                rowData.row.stockInProduction
            )
        }
    },
    {
        headerName: "Prioridade",
        field: "priority",
        type: "number",
        hide: checkVisibility('priority'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (rowData: any) =>{
            return(
                <TextField size="small" type='number' id={`${rowData.row_id}-prt`} placeholder="Prioridade" variant="standard"
                    onChange={(evt: any)=> {
                        rowData.row.priority = parseInt(evt.target.value)
                        const itemPriorityA = inputsPriority[rowData.row._id].get()
                        const idxOfItemB = Object.values(inputsPriority.get()).findIndex((item: any)=> item === parseInt(evt.target.value))
                        if(idxOfItemB !== -1) {
                            const ItemBId = Object.keys(inputsPriority.get())[idxOfItemB]
                            inputsPriority[ItemBId].set(itemPriorityA)
                            inputsPriority[rowData.row._id].set(parseInt(evt.target.value))
                        }else{
                            inputsPriority[rowData.row._id].set(parseInt(evt.target.value))
                        }
                    }} value={inputsPriority[rowData.row._id].get()}
                    sx={{minWidth: '10rem', width: '100%', fontSize: BUTTON_FONT_SIZE, '& label':{fontSize: BUTTON_FONT_SIZE}}}
                />
            )
        }
    },
    {
        headerName: "Qtd.",
        field: "quantity",
        type: "number",
        hide: checkVisibility('quantity'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (rowData: any) =>{
            return(
                <TextField size="small" type='number' id={`${rowData.row_id}-qtd`} placeholder="Quantidade" variant="standard"
                    onChange={(evt: any)=> {
                        evt.persist();
                        clearTimeout(debounceTimeout.current)
                        inputs[rowData.row._id].set(parseInt(evt.target.value))
                        if(inputsPriority[rowData.row._id].get() === undefined){
                            inputsPriority[rowData.row._id].set(priorityIncrement.get())
                            rowData.row.priority = priorityIncrement.get()
                            priorityIncrement.set((p: number) => p + 1)
                        }
                        debounceTimeout.current = setTimeout(() => {
                            const result = recalculateMaxProduction(data.componentsDetail.attach(Downgraded).get(), data.productsProduction.attach(Downgraded).get())
                            data.productsProduction.set(result)
                        }, 1500)
                    }} value={inputs[rowData.row._id].get()}
                    sx={{minWidth: '10rem', width: '100%', fontSize: BUTTON_FONT_SIZE, '& label':{fontSize: BUTTON_FONT_SIZE}}}
                />
            )
        }
    },
    {
        headerName: "Qtd. Possível",
        field: "quantityToProduction",
        type: "number",
        hide: checkVisibility('quantityToProduction'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (rowData: any) =>{
            return(
                <Tooltip arrow placement="bottom" title={
                    <div style={{display: 'flex', flexDirection: 'column', gap: '1rem', fontSize: 'clamp(1.4rem, 1.4vw, 1.6rem)'}}>                           
                        <strong>Quantidades Para Produção:</strong>
                        <span>Antes: 
                            <strong style={{color: rowData.row.quantityToProduction ? rowData.row.quantityToProduction > 0 ? "#1dd1a1" : "#ff6b81" : '#fff', marginLeft: '.5rem'}}>
                                {rowData.row.quantityToProduction ? rowData.row.quantityToProduction : 0}
                            </strong>
                        </span>
                        <span>Agora: 
                            <strong style={{color: rowData.row.quantityMaxProduction ? rowData.row.quantityMaxProduction > 0 ? "#1dd1a1" : "#ff6b81" : '#fff', marginLeft: '.5rem'}}>
                                {rowData.row.quantityMaxProduction && rowData.row.quantityMaxProduction !== Infinity ? rowData.row.quantityMaxProduction : 0}
                            </strong>
                        </span>
                    </div>
                } sx={{maxWidth: '40rem'}}>
                    <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                        <span>
                            {rowData.row.quantityToProduction ? rowData.row.quantityToProduction : 0}
                            <ArrowRightAltIcon />
                            <strong style={{color: rowData.row.quantityMaxProduction ? rowData.row.quantityMaxProduction > 0 ? "#1dd1a1" : "#ff6b81" : '#000', marginLeft: '.5rem'}}>
                                {rowData.row.quantityMaxProduction ? rowData.row.quantityMaxProduction : 0}
                            </strong>
                        </span>
                    </main>
                </Tooltip>
            )
        }
    },
    {
        headerName: "Qtd. Recomendada",
        field: "NA",
        type: "number",
        hide: checkVisibility('n/a'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (rowData: any) =>{
            return(
                'N/A'
            )
        }
    },
    ]

    const columnsOrders = [
        {
            headerName: "SKU",
            field: "sku",
            type: "string",
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    rowData.row.sku
                )
            }
        },
        {
            headerName: "Descrição",
            field: "description",
            type: "string",
            headerAlign: 'center',
            flex: 2,
            minWidth: 200,
            renderCell: (rowData: any) =>{
                return(
                    <Tippy content={rowData.row.description}>
                        <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            <span title={rowData.row.description}>{rowData.row.description}</span>
                        </main>
                    </Tippy>
                )
            }
        },
        {
            headerName: "Custo",
            field: "cost",
            type: "number",
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    <Tooltip arrow placement="bottom" title={
                        <div style={{display: 'flex', flexDirection: 'column', padding: '1rem', gap: '1rem', maxHeight: '40rem', overflowY: 'scroll', fontSize: 'clamp(1.4rem, 1.4vw, 1.6rem)'}}>
                            {
                                rowData.row.structureCost.length > 0 ?
                                rowData.row.structureCost.map((item: any)=>{
                                    return(
                                        <ul key={item.sku} style={{display: 'flex', flexDirection: 'column', padding: '1rem', gap: '.5rem', margin: '0'}}>
                                            <li style={{display: 'flex', flexDirection: 'row', gap: '0.5rem'}}>
                                                <strong>Custo:</strong>
                                                <span>{item.costTotal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                                            </li>
                                            <li style={{display: 'flex', flexDirection: 'row', gap: '0.5rem'}}>
                                                <strong>SKU:</strong>
                                                <span>{item.sku}</span>
                                            </li>
                                            <li style={{display: 'flex', flexDirection: 'column', gap: '0.1rem'}}>
                                                <strong>Descrição:</strong>
                                                <span>{item.description}</span>
                                            </li>
                                        </ul>
                                    )
                                })
                                :
                                "N/A"
                            }                            
                        </div>
                    }>
                         <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            <span>{rowData.row.cost.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                        </main>
                    </Tooltip>
                )
            }
        },
        {
            headerName: "Qtd.",
            field: "quantity",
            type: "number",
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    <TextField disabled={true} size="small" type='number' id={rowData.row_id} label="Quantidade" variant="standard"
                        onChange={(evt: any)=> rowData.row["quantity"] = parseInt(evt.target.value)} defaultValue={rowData.row.quantity}
                        sx={{minWidth: '10rem', width: '100%', fontSize: BUTTON_FONT_SIZE, '& label':{fontSize: BUTTON_FONT_SIZE}}}
                    />
                )
            }
        },
        {
            headerName: "Qtd. Recomendada",
            field: "NA",
            type: "number",
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    'N/A'
                )
            }
        },

    ]

    const columnsComponentsToBuy = [
        {
            headerName: "SKU",
            field: "sku",
            type: "string",
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    rowData.row.sku
                )
            }
        },
        {
            headerName: "Descrição",
            field: "description",
            type: "string",
            headerAlign: 'center',
            flex: 2,
            minWidth: 200,
            renderCell: (rowData: any) =>{
                return(
                    <Tippy content={rowData.row.description}>
                        <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            <span title={rowData.row.description}>{rowData.row.description}</span>
                        </main>
                    </Tippy>
                )
            }
        },
        {
            headerName: "Estoque",
            field: "stock",
            type: "number",
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    <Tippy content={
                        rowData.row.deposits.map((item: any)=>{
                            return(
                                <div key={`${Math.random()}-${item.name}`} style={{display: 'flex', flexDirection: 'row', padding: '1rem', gap: '1rem'}}>
                                    <strong>{item.name}: {item.quantity}</strong>
                                </div>
                            )
                        })
                    }>
                        <strong>{rowData.row.stock}</strong>
                    </Tippy>
                )
            }
        },
        {
            headerName: "Utilizar",
            field: "quantity",
            type: "number",
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    rowData.row.quantity
                )
            }
        },
        {
            headerName: "A Comprar",
            field: "quantityToBuy",
            type: "number",
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    rowData.row.quantityToBuy
                )
            }
        },
        {
            headerName: "Custo Unitário",
            field: "cost",
            type: "number",
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    rowData.row.cost.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                )
            }
        },
        {
            headerName: "Valor a Comprar",
            field: "costToBuy",
            type: "number",
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    rowData.row.costToBuy.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                )
            }
        },
    ]


    if(data.promised || dataOrders.promised){ //Enquanto for promisse ou estiver carregando
        return (
            <>
                <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}> 
                    <div className="content-wrapper">
                        <main className="main-content container-fluid">
                            <Loading />
                        </main>
                    </div>
                </div>
            </>
        )
    }

    if (data.error || dataOrders.error) { // Quando o promise dar reject
        return (<ErrorScreen />)
    }


    return(
        <div className={`planningSection ${DarkMode ? 'dark-mode-allShipmentsSection' : ''}`}>
            <main className='container-fluid' style={{padding: '0'}}>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '2rem'}}>
                    {
                        !booleanViewMode ?
                        <VirtualizedGrid 
                            rows={data.productsProduction.attach(Downgraded).get()}
                            columns={columns}
                            options={options}
                            tableHeight={'auto'}
                            pageSize={10}
                            title="Planejamento"
                            onSelectionDefault={(rows: any)=>  handleSelection(rows)}
                            multipleSort={[{field: 'priority', sort: 'asc'}, {field: 'dateCreate', sort: 'desc'}]}
                        />
                        :
                        <>
                            <Paper elevation={3} sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', width: '100%', height: 'auto', padding: '2rem'}}>
                                <h3>{`Ordem de Produção n°: ${dataOrders.number.get()}`}</h3>
                            </Paper>
                            <VirtualizedGrid 
                                rows={dataOrders.items.attach(Downgraded).get()}
                                columns={columnsOrders}
                                options={{...options, selection: false}}
                                tableHeight={'auto'}
                                title="Planejamento"
                                defaultSort={{field: 'quantity', direction: 'asc'}}
                            />
                        </>
                    }
                    <ThemeProvider theme={defaultTheme}>
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '1rem', width: '100%', margin: '1rem 0'}}>
                            <Button disabled={booleanViewMode || rowsSelected.length <= 0} size='small' variant="outlined" color='primary' sx={{minWidth: 'fit-content', fontSize: BUTTON_FONT_SIZE, '& .MuiButton-startIcon':{margin: '0'}}} onClick={handleCalculate}>
                                Calcular
                            </Button>
                            <Button disabled={booleanViewMode || componentsToBuy.length <= 0} size='small' variant="contained" color='primary' sx={{minWidth: 'fit-content', fontSize: BUTTON_FONT_SIZE, '& .MuiButton-startIcon':{margin: '0'}}} onClick={handleOPGenerate}>
                                Gerar OP
                            </Button>
                        </Box>
                    </ThemeProvider>
                    <hr />
                    <VirtualizedGrid 
                        rows={booleanViewMode ? dataOrders.components.attach(Downgraded).get() : componentsToBuy.attach(Downgraded).get()}
                        columns={columnsComponentsToBuy}
                        options={{...options, selection: false}}
                        tableHeight={'auto'}
                        title="Componentes a Comprar"
                        defaultSort={{field: 'unit_cost', direction: 'asc'}}
                    />
                    {
                        !booleanViewMode ?
                        <Box sx={{display: 'flex',flexDirection: 'column', gap: '1rem', width: '100%'}}>
                            <h3>Observação</h3>
                            <TextField size="small" type='text' placeholder="Observação" variant="outlined"
                                multiline minRows={5} maxRows={5}
                                sx={{minWidth: '21rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                // onChange={(evt: any)=>{
                                //     modalData.serialNumberRelation[index].merge({serialNumberFinal: item.serialNumberFinal, serialNumberComponents: evt.target.value})
                                // }}
                            />
                        </Box>
                        :
                        null
                    }
                </Box>
            </main>
        </div>
    )
    
}
