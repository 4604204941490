
import placeholder from '../../../assets/images/placeholder.jpg';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

export const columns = [
    { 
      headerName:  "SKU", 
      field: "sku", 
      type: 'string',
      headerAlign: 'center',
      flex: 1,
    },
    {
        headerName: "Imagem",
        field: "photo",
        headerAlign: 'center',
        flex: 0.5,
        renderCell: (rowData: any) =>{
          return(
            <div className="img" style={{width: 30, height: 30, borderRadius: '50%', display: 'flex'}}>
                <img alt="Imagem do produto" src={rowData.row.photo !== "" ? rowData.row.photo : placeholder} style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 'inherit'}}/>
            </div>
          )
        }
    },
    {
      headerName: "Produto",
      field: "title",
      type: "string",
      headerAlign: 'center',
      flex: 4,
      renderCell: (rowData: any) => {
        return (
          <Tippy content={<div style={{display: 'flex', gap: '1rem'}}>{`${rowData.row.title} - ${rowData.row.variation_title}`}</div>} interactive={true} maxWidth={'none'}>
           <main style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
              <span title={`${rowData.row.title} - ${rowData.row.variation_title}`}>{`${rowData.row.title} - ${rowData.row.variation_title}`}</span>
            </main>
          </Tippy>
        )
      }
    },
    {
      headerName: "Estoque Disponível",
      field: "stock",
      type: "number",
      headerAlign: 'center',
      flex: 1,
      renderCell: (rowData: any) => {
        return (
          <span title={rowData.row.stock}>{rowData.row.stock}</span>
        )
      }
    },
    {
        headerName: "Faturamento Bruto",
        field: "gross_sales",
        type: "number",
        headerAlign: 'center',
        flex: 1,
        renderCell: (rowData: any) => {
            const faturamento = (rowData.row.gross_sales ? rowData.row.gross_sales : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
          return (
            <span title={faturamento}>{faturamento}</span>
          )
        }
    },
    {
        headerName: "Vendas",
        field: "sell_items",
        type: "number",
        headerAlign: 'center',
        flex: 1,
        renderCell: (rowData: any) => {
          return (
            <span title={rowData.row.sell_items}>{rowData.row.sell_items}</span>
          )
        }
    },
    {
        headerName: "Classificação",
        field: "classification",
        type: "string",
        headerAlign: 'center',
        flex: 1,
        renderCell: (rowData: any) => {
          return (
            <span title={rowData.row.classification}>{rowData.row.classification}</span>
          )
        }
    },
]

export const options = {
  palette:{
    tableHeader: {
      main: '#F7CE3E',
      dark: '#ac902b',
      light: '#f8d764',
      contrastText: '#000'
    },
  },
  alternate: true,
}
