import './style.scss';
import { useDarkMode } from '../../hooks/useDarkMode';

export const MultipleDataCard = (props: any) =>{
    const {DarkMode, } = useDarkMode();
    return (
        <div className={`card-component multiple-data-card-component ${DarkMode ? 'dark-mode-card' : ''}`}>
            {
                props.titles.map((item: any, index: any)=>{
                    return(
                        <div key={index}>
                            {index === 0 ? null : <hr />}
                            <header><span>{item}</span></header>
                            <main>
                                <span style={{color: props.colors ? props.colors[index]:'inherit'}}>{props.content[index]}</span>
                            </main>
                        </div>
                    )
                })
            
            }
        </div>
    )
}