import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Loading } from '../../../../components/Loading/Loading';
import { IEditingData } from "../../../../types/Types";
import { useDarkMode } from '../../../../hooks/useDarkMode';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { useHandleErrors } from '../../../../hooks/useHandleErrors';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const BUTTON_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.4rem)';
const INPUT_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.6rem)';

export const GroupTab = (props: any) =>{
    const token: string | undefined = localStorage.getItem('token') || undefined;
    const handleValidateStatus = useHandleErrors();
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const loading: State<boolean> = useState<boolean>(false);
    const brands: State<string[]> = useState<string[]>([]);
    const categories: State<string[]> = useState<string[]>([]);
    const origins: State<object[]> = useState<object[]>([{'value': '', 'text': ''}, {'value': 'TC', 'text': 'TC'}, {'value': 'RM', 'text': 'RM'}]);
    const checkBrands: State<boolean> = useState<boolean>(false);
    const checkCategories: State<boolean> = useState<boolean>(false);
    const checkOrigin: State<boolean> = useState<boolean>(false);
    const checkRounding: State<boolean> = useState<boolean>(false);
    const selectedRounding: State<number> = useState<number>(0);
    const selectedMargin: State<number> = useState<number>(0);
    const selectedOrigin: State<string | undefined> = useState<string | undefined>('');
    const selectedCategory: State<string | undefined> = useState<string | undefined>('');
    const selectedBrand: State<string | undefined> = useState<string | undefined>('');
    const editingData: State<IEditingData> = useState<IEditingData>({
        "_id": '',
        "type": '',
        "group": false,
        "origin": '',
        "category": '',
        "brand": '',
        "sku": '',
        "margin": 0,
        "roundTo": 0,
        "__v": 0,
        "priority": 0,
        "tableData": {
            "id": 0,
        }
    });
    const editing: State<boolean> = useState<boolean>(false);
    const selectedRoundingEditing: State<number> = useState<number>(0);
    const selectedMarginEditing: State<number> = useState<number>(0);
    const selectedOriginEditing: State<string> = useState<string>('');
    const selectedCategoryEditing: State<string> = useState<string>('');
    const selectedBrandEditing: State<string> = useState<string>('');

    const {DarkMode, } = useDarkMode();

    const defaultTheme  = createTheme({
        palette:{
          mode: DarkMode ? 'dark' : 'light',
        },
    });


    useEffect(() => {
        origins.set(props.origins);
        const temp_categories = props.categories.map((item: any,index: number)=>{
            return <MenuItem key={`${index}-item.value`} value={item.value}>{item.text}</MenuItem>
        })
        const temp_brands = props.brands.map((item: any,index: number)=>{
            return <MenuItem key={`${index}-item.value`} value={item.value}>{item.text}</MenuItem>
        })
        categories.set(temp_categories)
        brands.set(temp_brands);
        editing.set(props.editing);
        editingData.set(props.editingData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        editing.set(props.editing);
        editingData.set(props.editingData);
        // expandAccordion.set(props.editing)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.editingData, props.editing])

    const handleSubmit = (evt: React.BaseSyntheticEvent)=>{
        evt.preventDefault();
        evt.target.reset()
        let temp_origin: string | undefined | null = null,
        temp_category: string | undefined | null = null,
        temp_brand: string | undefined | null = null,
        temp_rounding: number | undefined | null = null,
        temp_margin: number | null = (selectedMargin.get() ? selectedMargin.get() : 0)/100;

        if(checkOrigin){
            temp_origin = selectedOrigin.attach(Downgraded).get()
        }

        if(checkCategories){
            temp_category = selectedCategory.attach(Downgraded).get()
        }

        if(checkBrands){
            temp_brand = selectedBrand.attach(Downgraded).get()
        }
        if(checkRounding){
            temp_rounding = selectedRounding.attach(Downgraded).get()
        }

        if(temp_origin === null && temp_category === null && temp_brand === null){
            toast.info("Pelo menos um dos campos, Origem, Categoria ou Marca devem ser selecionados.");
            return
        }

        selectedRounding.set(0);
        selectedMargin.set(0);
        selectedOrigin.set('');
        selectedCategory.set('');
        selectedBrand.set('');
        checkBrands.set(false);
        checkOrigin.set(false);
        checkCategories.set(false);
        checkRounding.set(false);


        const processedData = {
            "type":"manual",
           "group": true,
           "origin": temp_origin,
           "category": temp_category,
           "brand": temp_brand,
           "sku": null,
           "margin": temp_margin,
           "roundTo": temp_rounding
       }
       loading.set(true)
       axs.post<any>(`/precification/config/manual`, processedData).then((r: any) => {
            if(r.status === 200){
                if(r.data){
                    editing.set(false);
                    editingData.set({
                        "_id": '',
                        "type": '',
                        "group": false,
                        "origin": '',
                        "category": '',
                        "brand": '',
                        "sku": '',
                        "margin": 0,
                        "roundTo": 0,
                        "__v": 0,
                        "priority": 0,
                        "tableData": {
                            "id": 0,
                        }
                    });
                    loading.set(false)
                    props.reloadTable()
                }
            }else{
                loading.set(false)
                toast.error(r.message);
            }
        }).catch((err: any)=>{
            loading.set(false)
            handleValidateStatus(err.response)
        });
    }

    const handleSubmitEdit = (evt: React.BaseSyntheticEvent)=>{
        evt.preventDefault();
        evt.target.reset()
        let temp_origin = selectedOriginEditing.attach(Downgraded).get(),
        temp_category = selectedCategoryEditing.get(),
        temp_brand = selectedBrandEditing.get(),
        temp_rounding = selectedRoundingEditing.get(),
        temp_margin = (selectedMarginEditing.get() ? selectedMarginEditing.get():0)/100,
        id = editingData._id.value;

        if((temp_origin === undefined || temp_origin === null) && 
            (temp_category === undefined || temp_category === null) &&
            (temp_brand === undefined || temp_brand === null) &&
            (temp_rounding === undefined || temp_rounding === null) &&
            (temp_margin === undefined || temp_margin === null || temp_margin === 0)){

            toast.info("Pelo menos um dos campos deve ser preenchido.");
            return
        }

        if(temp_origin === undefined || temp_origin === null){
            temp_origin = editingData.origin.value
        }

        if(temp_category === undefined || temp_category === null){
            temp_category = editingData.category.value
        }

        if(temp_brand === undefined || temp_brand === null){
            temp_brand = editingData.brand.value
        }
        if(temp_rounding === undefined || temp_rounding === null){
            temp_rounding = editingData.roundTo.value
        }
        if(temp_margin === undefined || temp_margin === null || temp_margin === 0){
            temp_margin = editingData.margin.value
        }


        selectedRoundingEditing.set(0);
        selectedMarginEditing.set(0);
        selectedOriginEditing.set('');
        selectedCategoryEditing.set('');
        selectedBrandEditing.set('');

        const processedData = {
        "origin": temp_origin,
        "category": temp_category,
        "brand":temp_brand,
        "sku": null,
        "margin": temp_margin,
        "roundTo": temp_rounding
        }

        editingData.set({
            "_id": '',
            "type": '',
            "group": false,
            "origin": '',
            "category": '',
            "brand": '',
            "sku": '',
            "margin": 0,
            "roundTo": 0,
            "__v": 0,
            "priority": 0,
            "tableData": {
                "id": 0,
            }
        })
        editing.set(false)
        loading.set(true)

       axs.put<any>(`/precification/config/${id}`, processedData).then((r: any) => {
        if(r.status === 200){
            if(r.data){
                editing.set(false);
                editingData.set({
                    "_id": '',
                    "type": '',
                    "group": false,
                    "origin": '',
                    "category": '',
                    "brand": '',
                    "sku": '',
                    "margin": 0,
                    "roundTo": 0,
                    "__v": 0,
                    "priority": 0,
                    "tableData": {
                        "id": 0,
                    }
                });
                props.reloadTable()
            }
        }else{
            toast.error(r.message);
        }
    }).catch((err: any)=>{
        loading.set(false)
        handleValidateStatus(err.response)
    });
}


    return(
        <div className="groupSection">
            {
                loading.get() ?

                <Loading />
                :
                <main className={`container-fluid ${DarkMode ? 'dark-mode-group-tab' : ''}`} style={{padding: '0'}}>
                    <ThemeProvider theme={defaultTheme}>
                        {
                            !editing.get() ?
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="group-section"
                                id="group-section-header"
                                >
                                    <h3>Cadastro Precificador - Grupos</h3>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box component="form" onSubmit={(evt: React.BaseSyntheticEvent)=> handleSubmit(evt)}
                                    style={{width: "100%"}}
                                    >
                                        <Paper elevation={3}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            gap: '1rem',
                                            margin: 0,
                                            padding: '3rem'
                                        }}
                                        >
                                            <Box sx={{display: 'flex', justifyContent: "center", flexDirection: "column", alignItems: "flex-start"}}>
                                                <FormControlLabel control={<Checkbox />} label="Margem" sx={{ '& .MuiSvgIcon-root': { fontSize: 20 }, display: 'none' }} />
                                                <TextField type='number' required id="margin" label="Margem" variant="outlined" onChange={(evt: any)=> selectedMargin.set(evt.target.value)} 
                                                    sx={{minWidth: '20rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                                />
                                            </Box>
                                            
                                            <hr/>

                                            <Box sx={{display: 'flex', justifyContent: "center", flexDirection: "column", alignItems: "flex-start"}}>
                                                <FormControlLabel control={<Checkbox />} label="Origem" sx={{ '& .MuiSvgIcon-root': { fontSize: 20 }, '& .MuiTypography-root': { fontSize: INPUT_FONT_SIZE } }} onChange={(evt: any)=> {
                                                    checkOrigin.set(evt.target.checked)
                                                    selectedOrigin.set(undefined)
                                                }} />
                                                <FormControl variant='outlined' sx={{minWidth: '20rem', width: '100%'}} key={selectedOrigin.get()}>
                                                    <InputLabel id="origin-label" style={{fontSize: INPUT_FONT_SIZE}}>Origem</InputLabel>
                                                    <Select
                                                        required={checkOrigin.get()}
                                                        disabled={!checkOrigin.get()}
                                                        labelId="origin-label"
                                                        id="origin"
                                                        label="Origem"
                                                        value={selectedOrigin.get()}
                                                        onChange={(evt: SelectChangeEvent)=>{
                                                            selectedOrigin.set(evt.target.value)
                                                        }}
                                                        style={{fontSize: INPUT_FONT_SIZE}}
                                                    >
                                                        <MenuItem value={'TC'}>TechWheel</MenuItem>
                                                        <MenuItem value={'RM'}>RiseMode</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>

                                            <hr/>

                                            <Box component="div" sx={{display: 'flex', justifyContent: "center", flexDirection: "column", alignItems: "flex-start"}}>
                                                <FormControlLabel control={<Checkbox />} label="Categoria" sx={{ '& .MuiSvgIcon-root': { fontSize: 20 }, '& .MuiTypography-root': { fontSize: INPUT_FONT_SIZE } }} onChange={(evt: any)=> {
                                                    checkCategories.set(evt.target.checked)
                                                    selectedCategory.set(undefined)
                                                }} />
                                                <FormControl variant='outlined' sx={{minWidth: '20rem', width: '100%'}} key={selectedCategory.get()}>
                                                    <InputLabel id="category-label" style={{fontSize: INPUT_FONT_SIZE}}>Categoria</InputLabel>
                                                    <Select
                                                        required={checkCategories.get()}
                                                        disabled={!checkCategories.get()}
                                                        labelId="category-label"
                                                        id="category"
                                                        label="Categoria"
                                                        value={selectedCategory.get()}
                                                        onChange={(evt: SelectChangeEvent)=>{
                                                            selectedCategory.set(evt.target.value)
                                                        }}
                                                        style={{fontSize: INPUT_FONT_SIZE}}
                                                    >
                                                        {
                                                            categories.attach(Downgraded).get()
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Box>

                                            <hr/>

                                            <Box sx={{display: 'flex', justifyContent: "center", flexDirection: "column", alignItems: "flex-start"}}>
                                                <FormControlLabel control={<Checkbox />} label="Marca" sx={{ '& .MuiSvgIcon-root': { fontSize: 20 }, '& .MuiTypography-root': { fontSize: INPUT_FONT_SIZE } }} onChange={(evt: any)=> {
                                                    checkBrands.set(evt.target.checked)
                                                    selectedBrand.set(undefined)
                                                }} />
                                                <FormControl variant='outlined' sx={{minWidth: '20rem', width: '100%'}} key={selectedBrand.get()}>
                                                    <InputLabel id="brand-label" style={{fontSize: INPUT_FONT_SIZE}}>Marca</InputLabel>
                                                    <Select
                                                        required={checkBrands.get()}
                                                        disabled={!checkBrands.get()}
                                                        labelId="brand-label"
                                                        id="brand"
                                                        label="Marca"
                                                        value={selectedBrand.get()}
                                                        onChange={(evt: SelectChangeEvent)=>{
                                                            selectedBrand.set(evt.target.value)
                                                        }}
                                                        style={{fontSize: INPUT_FONT_SIZE}}
                                                    >
                                                        {
                                                            brands.attach(Downgraded).get()
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Box>

                                            <hr/>

                                            <Box sx={{display: 'flex', justifyContent: "center", flexDirection: "column", "alignItems": "flex-start"}}>
                                                <FormControlLabel control={<Checkbox />} label="Arredondamento" sx={{ '& .MuiSvgIcon-root': { fontSize: 20 }, '& .MuiTypography-root': { fontSize: INPUT_FONT_SIZE } }} onChange={(evt: any)=> checkRounding.set(evt.target.checked)} />
                                                <TextField type='number' required={checkRounding.get()} disabled={!checkRounding.get()} id="rounding" label="Arredondamento" variant="outlined" onChange={(evt: any)=> {
                                                    if(evt.target.value < 0 || evt.target.value > 99){
                                                        toast.info("O campo Arredondamento aceita apenas valores entre 0 e 99.");
                                                        return
                                                    }
                                                    selectedRounding.set(evt.target.value)
                                                }} 
                                                    sx={{minWidth: '20rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                                />
                                            </Box>

                                            <Button variant="contained" color='primary' startIcon={<AddIcon />} sx={{fontSize: BUTTON_FONT_SIZE, marginTop: '1rem', width: '100%', maxWidth: 'fit-content', '& .MuiButton-startIcon':{margin: '0'}}} type='submit'>
                                                Adicionar Nova Regra
                                            </Button>
                                        </Paper>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                            :
                            <Box component="form" onSubmit={(evt: React.BaseSyntheticEvent)=> handleSubmitEdit(evt)}
                            style={{width: "100%"}}
                            >
                                <Paper elevation={3}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    gap: '1rem',
                                    margin: 0,
                                    padding: '3rem'
                                }}
                                >
                                    <h3>Edição Cadastro Precificador - Grupos</h3>
                                    <Box sx={{display: 'flex', justifyContent: "center", flexDirection: "column", alignItems: "flex-start"}}>
                                        <TextField type='number' id="margin-editing" label="Margem" variant="outlined" onChange={(evt: any)=> selectedMarginEditing.set(evt.target.value)} 
                                            sx={{minWidth: '20rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                            defaultValue={editingData.margin.value ? editingData.margin.value*100 : ""}
                                        />
                                    </Box>
                                    
                                    <hr/>

                                    <Box sx={{display: 'flex', justifyContent: "center", flexDirection: "column", alignItems: "flex-start"}}>
                                        <FormControl variant='outlined' sx={{minWidth: '20rem', width: '100%'}}>
                                            <InputLabel id="origin-edit-label" style={{fontSize: INPUT_FONT_SIZE}}>Origem</InputLabel>
                                            <Select
                                                labelId="origin-edit-label"
                                                id="origin-edit"
                                                label="Origem"
                                                defaultValue={editingData.origin.value}
                                                onChange={(evt: SelectChangeEvent)=>{
                                                    selectedOriginEditing.set(evt.target.value)
                                                }}
                                                style={{fontSize: INPUT_FONT_SIZE}}
                                            >
                                                <MenuItem value={'TC'}>TechWheel</MenuItem>
                                                <MenuItem value={'RM'}>RiseMode</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>

                                    <hr/>

                                    <Box component="div" sx={{display: 'flex', justifyContent: "center", flexDirection: "column", alignItems: "flex-start"}}>
                                        <FormControl variant='outlined' sx={{minWidth: '20rem', width: '100%'}}>
                                            <InputLabel id="category-label-edit" style={{fontSize: INPUT_FONT_SIZE}}>Categoria</InputLabel>
                                            <Select
                                                labelId="category-label-edit"
                                                id="category-edit"
                                                label="Categoria"
                                                defaultValue={editingData.category.value}
                                                onChange={(evt: SelectChangeEvent)=>{
                                                    selectedCategoryEditing.set(evt.target.value)
                                                }}
                                                style={{fontSize: INPUT_FONT_SIZE}}
                                            >
                                                {
                                                    categories.attach(Downgraded).get()
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>

                                    <hr/>

                                    <Box sx={{display: 'flex', justifyContent: "center", flexDirection: "column", alignItems: "flex-start"}}>
                                        <FormControl variant='outlined' sx={{minWidth: '20rem', width: '100%'}}>
                                            <InputLabel id="brand-label-edit" style={{fontSize: INPUT_FONT_SIZE}}>Marca</InputLabel>
                                            <Select
                                                labelId="brand-label-edit"
                                                id="brand-edit"
                                                label="Marca"
                                                defaultValue={editingData.brand.value}
                                                onChange={(evt: SelectChangeEvent)=>{
                                                    selectedBrandEditing.set(evt.target.value)
                                                }}
                                                style={{fontSize: INPUT_FONT_SIZE}}
                                            >
                                                {
                                                    brands.attach(Downgraded).get()
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>

                                    <hr/>

                                    <Box sx={{display: 'flex', justifyContent: "center", flexDirection: "column", "alignItems": "flex-start"}}>
                                        <TextField type='number' id="rounding-editing" label="Arredondamento" variant="outlined" onChange={(evt: any)=> {
                                            if(evt.target.value < 0 || evt.target.value > 99){
                                                toast.info("O campo Arredondamento aceita apenas valores entre 0 e 99.");
                                                return
                                            }
                                            selectedRoundingEditing.set(evt.target.value)
                                        }} 
                                            sx={{minWidth: '20rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                            defaultValue={editingData.roundTo.value}
                                        />
                                    </Box>

                                    <Button variant="contained" color='primary' startIcon={<EditIcon />} sx={{fontSize: BUTTON_FONT_SIZE, marginTop: '1rem', width: '100%', maxWidth: 'fit-content', '& .MuiButton-startIcon':{margin: '0'}}} type='submit'>
                                        Editar Regra
                                    </Button>
                                </Paper>
                            </Box>
                        }
                    </ThemeProvider>
                </main>
            }
        </div>
    )
}