import { MdClose } from 'react-icons/md';
import './style.scss';

export const BasicModal = (props: any)=>{
    const handleClickOutside = (evt: React.BaseSyntheticEvent) =>{
        const elmClass = evt.target.getAttribute("class")
        const classesThatClose = ["modal-wrapper", "basic-modal"]
        if(classesThatClose.includes(elmClass) && !props.notCloseBind){
            props.closeFunction()
        }else{
            return
        }
    }
    return(
        <div className="modal-wrapper" onClick={handleClickOutside}>
            <div className="basic-modal">
                <div className="close-container" style={{alignSelf: 'center'}}>
                    <button onClick={props.closeFunction}><MdClose /></button>
                </div>
                {props.children}
            </div>
        </div>
    )
}