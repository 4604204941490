import { columns, options } from '../../../components/table/files/ABCCurveData';
import { toast } from 'react-toastify';
import noData from '../../../assets/images/nodata.svg';
import { Filters } from '../../../components/Filters/Filters';
import { LineChart } from '../../../components/Charts/LineChart';
import moment from 'moment';
import './style.scss';
import { IAnalysisABC, ObjetoVazio } from "../../../types/Types";
import { useDarkMode } from '../../../hooks/useDarkMode';
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { Untracked } from '@hookstate/untracked';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import { Loading } from '../../../components/Loading/Loading';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { BugReportModal } from '../../../components/modal/BugReportModal';

const INPUT_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.6rem)';


export const ABCCurve = (props:any) =>{
    document.title = "Curva ABC - MeLiBeat";
    const handleValidateStatus = useHandleErrors();

    const to = moment().format("YYYY-MM-DD");
    const from = moment(to).subtract(30, 'days').format("YYYY-MM-DD"); //alterar o filterValues para o valor que está aqui
    const processedFilter = {
        dateFrom: from,
        dateTo: to,
        typeClassification: "gross_sales",
    }

    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const filterValues: ObjetoVazio = useState<ObjetoVazio>({"period": 30, "classification": undefined})



    const fetchResource = (query:any=processedFilter) => axs.get<IAnalysisABC>('/orders/analysisabc/', {params: query}).then((r: any) => {
        handleValidateStatus(r)
        return r.data;
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
    })

    const data: State<IAnalysisABC> = useState<IAnalysisABC>({
        "products": [{
            "sku": '',
            "title": '',
            "variation_title": '',
            "category_id": '',
            "type_exposition": '',
            "logistic_type": '',
            "sells": 0,
            "sell_items": 0,
            "gross_sales": 0,
            "net_sales": 0,
            "net_sales_perc":0,
            "gross_sales_perc": 0,
            "gmv": 0,
            "stock": 0,
            "photo": '',
            "grossSalesClassification": '',
            "sellItemsClassification": '',
            "pontuation": 0,
            "pontuationClassification": '',
            "classification": '',
            "_id": '',
        }],
        "classifByQtyAxisX": {
                "grossSales": {
                    "A":'',
                    "B":'',
                    "C":'',
                },
                "sellItems": {
                    "A":'',
                    "B":'',
                    "C":'',
                },
                "pontuation": {
                    "A":'',
                    "B":'',
                    "C":'',
                },
        },
        "classifByValueAxisY": {
                "grossSales": {
                    "A":'',
                    "B":'',
                    "C":'',
                },
                "sellItems": {
                    "A":'',
                    "B":'',
                    "C":'',
                },
                "pontuation": {
                    "A":'',
                    "B":'',
                    "C":'',
                },
        },
        "chartX": [],
        "chartY": [],
        "chartValue": [],
        "chartLabel": [],
    });

    const chartDataset: State<object[]> = useState<object[]>([]);
    chartDataset.attach(Untracked)
    const chartLabel: State<number[][]> = useState<number[][]>([]);
    chartLabel.attach(Untracked)
    const chartSize: State<number> = useState<number>(60);
    const {DarkMode, } = useDarkMode();

    useEffect(() => {
        data.set(fetchResource())
    }, [])

    const handleFilter = (filters: any)=>{
        let period;
        let classification;
        if(!filters.filterPeriod){
            period = 7;
        }else{
            period = filters["filterPeriod"]
        }
        if(!filters.filterClassification){
            classification = "gross_sales";
        }else{
            classification = filters["filterClassification"];
        }

        const to = moment().format("YYYY-MM-DD");
        const from = moment(to).subtract(period, 'days').format("YYYY-MM-DD")
        const processedFilter = {
            dateFrom: from,
            dateTo: to,
            typeClassification: classification,
        }

        toast.info('Filtrando Dados!');
        data.set(fetchResource(processedFilter))
    }

    const handleReset = ()=> {
        filterValues.merge({"period": undefined, "classification": undefined});
        data.set(fetchResource())
    }


    data.batch((data) => {
        if (data.promised) return
        if (data.error) return
        else{
            const values = data.attach(Downgraded).get();
            const tension: number = 0.3;
            let ChartColors:string[] = ['#00a8ff', '#9c88ff', '#fbc531', '#4cd137', '#40739e', '#e84118', '#7f8fa6', '#2f3640', '#10ac84', '#01a3a4', '#f368e0'];
            let ChartColorsAlpha:string[] = ['#00a8ff6f', '#9c88ff6f', '#fbc5316f', '#4cd1376f', '#40739e6f', '#e841186f', '#7f8fa66f', '#2f36406f', '#10ac846f', '#01a3a46f', '#f368e06f'];
            Untracked(chartLabel).set([values.chartLabel])
            Untracked(chartDataset).set([
                {
                    label: ["Curva ABC"], 
                    data: [
                        {x: values.chartX[0], y: values.chartY[0]},
                        {x: values.chartX[1], y: values.chartY[1]},
                        {x: values.chartX[2], y: values.chartY[2]},
                        {x: values.chartX[3], y: values.chartY[3]},
                    ],
                    borderColor: ChartColors[1], 
                    type: 'line',
                    order: 2,
                    spanGaps: true,
                    hidden: false, tension: tension,
                    backgroundColor: ChartColors[1]
                    // backgroundColor: ChartColors[1], hidden: false, tension: tension
                },
                {
                    label: ["A"], 
                    data: [
                        {x: values.chartX[0], y: values.chartY[0]},
                        {x: values.chartX[1], y: values.chartY[1]},
                        {x: values.chartX[1], y:0},
                    ],
                    borderColor: 'transparent', 
                    type: 'line',
                    order: 2,
                    spanGaps: true,
                    fill: {
                        target: 'origin',
                        above: ChartColorsAlpha[2],
                        below: 'transparent'
                    },
                    backgroundColor: 'transparent', hidden: false, tension: tension
                },
                {
                    label: ["B"], 
                    data: [
                        {x: values.chartX[1], y: values.chartY[1]},
                        {x: values.chartX[2], y: values.chartY[2]},
                        {x: values.chartX[2], y: 0},
                    ],
                    borderColor:  'transparent', 
                    type: 'line',
                    order: 2,
                    spanGaps: true,
                    fill: {
                        target: 'origin',
                        above: ChartColorsAlpha[1],
                        below: 'transparent'
                    },
                    backgroundColor:  'transparent', hidden: false, tension: tension
                },
                {
                    label: ["C"], 
                    data: [
                        {x: values.chartX[2], y: values.chartY[2]},
                        {x: values.chartX[3], y: values.chartY[3]},
                        {x: values.chartX[3], y:0},
                    ],
                    borderColor:  'transparent', 
                    type: 'line',
                    order: 2,
                    spanGaps: true,
                    fill: {
                        target: 'origin',
                        above: ChartColorsAlpha[0],
                        below: 'transparent'
                    },
                    backgroundColor:  'transparent', hidden: false, tension: tension
                },
            ])
        }
    })

    return(
        <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
            <BugReportModal />
            <div className="content-wrapper">
                <main className="main-content container-fluid">
                    <Filters submitFunction={handleFilter} hideFullResetBtn handleReset={handleReset}>
                        <FormControl size='small' variant='outlined' sx={{minWidth: '20rem', width: '100%'}}>
                            <InputLabel id="period-label" style={{fontSize: INPUT_FONT_SIZE}}>Período</InputLabel>
                            <Select
                                required
                                labelId="period-label"
                                id="period"
                                value={filterValues.period.get()}
                                label="Período"
                                name='filterPeriod'
                                onChange={(evt: SelectChangeEvent)=>{
                                    filterValues.period.set(evt.target.value)
                                }}
                                style={{fontSize: INPUT_FONT_SIZE}}
                            >
                            <MenuItem value={7}>7 dias</MenuItem>
                            <MenuItem value={30}>30 dias</MenuItem>
                            <MenuItem value={90}>90 dias</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl size='small' variant='outlined' sx={{minWidth: '20rem', width: '100%'}}>
                            <InputLabel id="classification-label" style={{fontSize: INPUT_FONT_SIZE}}>Tipo de Classificação</InputLabel>
                            <Select
                                required
                                labelId="classification-label"
                                id="classification"
                                value={filterValues.classification.get() ? filterValues.classification.get() : "gross_sales"}
                                label="Tipo de Classificação"
                                name='filterClassification'
                                onChange={(evt: SelectChangeEvent)=>{
                                    filterValues.classification.set(evt.target.value)
                                }}
                                style={{fontSize: INPUT_FONT_SIZE}}
                            >
                            <MenuItem value={'gross_sales'}>Faturamento</MenuItem>
                            <MenuItem value={'sell_items'}>Vendas</MenuItem>
                            <MenuItem value={'gross_sales&sell_items'}>Faturamento e Vendas</MenuItem>
                            </Select>
                        </FormControl>
                    </ Filters>
                    {
                        data.promised ? 
                        <Loading />
                        : data.error ?
                        <ErrorScreen /> : 
                        data.products.length > 0  && chartDataset.length > 0 ?
                        <>
                            <h3><strong>Curva ABC</strong></h3>
                            <div>
                                <LineChart labels={chartLabel.attach(Downgraded).get()} dataset={chartDataset.attach(Downgraded).get()} height={chartSize.get()} />
                            </div>
                            <VirtualizedGrid 
                                rows={data.products.attach(Downgraded).get()}
                                columns={columns}
                                options={options}
                                title="Curva ABC"
                                defaultSort={{field: 'gross_sales', direction: 'desc'}}
                            />
                        </>
                        :
                        <div className="img-container">
                            <img src={noData} alt="Não foi possível renderizar o gráfico por falta de dados." />
                            <h4>Não foi possível renderizar os gráficos por falta de dados.</h4>
                        </div>
                    }
                </main>
            </div>
        </div>
    )
}