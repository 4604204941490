import { useEffect } from 'react';
import { useModal } from '../../../../hooks/useModal';
import { ModalConfirm } from '../../../../components/modal/ModalConfirm';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import { Loading } from '../../../../components/Loading/Loading';
import TextField from '@material-ui/core/TextField';
import Button from '@mui/material/Button';

import { useDarkMode } from '../../../../hooks/useDarkMode';
import VirtualizedGrid from '../../../../components/table/VirtualizedGrid';
import '../../../../components/table/globalInputs.scss';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useHandleErrors } from '../../../../hooks/useHandleErrors';
import { ErrorScreen } from '../../../../components/ErrorScreen/ErrorScreen';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ObjetoVazio, IProductionOrders } from "../../../../types/Types"
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { BasicModal } from "../../../../components/modal/BasicModal";

const BUTTON_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.4rem)';
const FONT_SIZE = "clamp(1.2rem, 1.2vw, 1.6rem)";
const INPUT_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.6rem)';



export const Finalization = (props: any) =>{
    const { itemId } = props;

    const handleValidateStatus = useHandleErrors();
    const {showHideModal} = useModal();
    
    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const showModal: State<boolean> = useState<boolean>(false);
    const showMultipleModal: State<boolean> = useState<boolean>(false);
    const entradas: State<ObjetoVazio[]> = useState<ObjetoVazio[]>([]);
    const rowsSelected  = useState<string[]>([])
    const data: State<IProductionOrders> = useState<IProductionOrders>({
        components: [],
        date: '',
        finished: false,
        items: [],
        number: '',
        observation: '',
        status: '',
        __v: 0,
        _id: '',
    });
    const fetchProductionOrders = (query:any=undefined) => axs.get<IProductionOrders>('/productionorders/'+itemId, {params: query}).then((r: any) => {
        return r.data
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    })

    const checkVisibility = (column: string)=>{
        const storageColumnsData = localStorage.getItem('Finalization') ? localStorage.getItem('Finalization') : null;
        if(storageColumnsData !== null){
          const localData = JSON.parse(storageColumnsData);
          const shouldHide = localData.find((item: any)=> item.field === column);
          return shouldHide !== undefined ? !shouldHide.visibility : false
        }
        return false
    }

    useEffect(() => {
        if(itemId === 'undefined'){
            return 
        }
        data.set(fetchProductionOrders())
    }, [])
    useEffect(() => {
        if(itemId === 'undefined'){
            return 
        }
        data.set(fetchProductionOrders())
    }, [itemId])

    const {DarkMode, activateDarkMode} = useDarkMode();

    const defaultTheme  = createTheme({
        palette:{
          mode: DarkMode ? 'dark' : 'light',
        },
    });

    const handleSelection = (rows: string[])=>{
        rowsSelected.set(rows);
        let processedRows: ObjetoVazio[] = [];
        data.items.attach(Downgraded).get().forEach((item:any)=>{
            if(rows.some((row: any)=> row === item._id)){
                processedRows.push({
                    idItem: item._id, entry: 0, sku: item.sku
                })
            }
        })
        entradas.set(processedRows)
    }

    const confirmChangeStatus = (confirm: boolean, id: string, status: string)=>{
        if(confirm){
            axs.post<any>('/productionorders/status/', {idOp: id, status: status}).then((r: any) => {
                if(r.status === 200){
                    toast.success(`Status alterado para: ${status}`);
                    data.set(fetchProductionOrders());
                }
            }).catch((err: any)=>{
                handleValidateStatus(err.response)
            })
        }
        return
    }

    const handleChangeStatus = (evt: SelectChangeEvent)=>{
        const status = evt.target.value;
        if(status === "Cancelado"){
            showHideModal({show: true, title: "Quer mesmo alterar o Status dessa Ordem para CANCELADO", execute: (confirm: boolean)=> confirmChangeStatus(confirm, itemId, status)})
            return
        }
        axs.post<any>('/productionorders/status/', {idOp: itemId, status: status}).then((r: any) => {
            if(r.status === 200){
                toast.success(`Status alterado para: ${status}`);
                data.set(fetchProductionOrders());
            }
        }).catch((err: any)=>{
            handleValidateStatus(err.response)
        })
    }

    const handleFinish = (evt: any)=>{
        axs.post<any>('/productionorders/finished/', {idOp: itemId, finished: true}).then((r: any) => {
            if(r.status === 200){
                toast.success('Item Finalizado!');
                data.set(fetchProductionOrders());
            }
        }).catch((err: any)=>{
            handleValidateStatus(err.response)
        })
    }

    const handleEntries = (evt: any)=>{
        if(entradas.length <= 0){
            toast.error('Adicione ao menos uma entrada!');
            return
        }
        axs.put<any>('/productionorders/entry/', {idOp: itemId, items: entradas.attach(Downgraded).get()}).then((r: any) => {
            if(r.status === 200){
                toast.success('Entradas adicionadas!');
                data.set(r.data);
            }
        }).catch((err: any)=>{
            handleValidateStatus(err.response)
        })
        showModal.set(false)
        showMultipleModal.set(false)
    }

    const handleMultipleEntries = (evt: any)=>{
        if(entradas.length <= 0){
            toast.error('Adicione ao menos uma entrada!');
            return
        }
        showModal.set(true)
        showMultipleModal.set(true)
    }


    const options = {
        palette:{
          tableHeader: {
            main: '#F7CE3E',
            dark: '#ac902b',
            light: '#f8d764',
            contrastText: '#000'
          },
        },
        alternate: true,
        selection: true,
        rowStyle: (rowData: any) => {
            let row_class = '';
            if(rowData.finished) {
                row_class = "AllShipments-alternate-color-finished"
            }        
          return row_class
        },
    }

    const columns = [
    {
        headerName: "SKU",
        field: "sku",
        type: "string",
        hide: checkVisibility('sku'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (rowData: any) =>{
            return(
                rowData.row.sku
            )
        }
    },
    {
        headerName: "Descrição",
        field: "description",
        type: "string",
        hide: checkVisibility('description'),
        headerAlign: 'center',
        flex: 2,
        minWidth: 200,
        renderCell: (rowData: any) =>{
            return(
                <Tippy content={rowData.row.description}>
                    <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                        <span title={rowData.row.description}>{rowData.row.description}</span>
                    </main>
                </Tippy>
            )
        }
    },
    {
        headerName: "Qtd. Produzida",
        field: "quantityFinished",
        type: "number",
        hide: checkVisibility('quantityFinished'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (rowData: any) =>{
            return(
                rowData.row.quantityFinished
            )
        }
    },
    {
        headerName: "Qtd. Produzir",
        field: "quantity",
        type: "number",
        hide: checkVisibility('qtd'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (rowData: any) =>{
            return(
                rowData.row.quantity
            )
        }
    },
]

    if(data.promised){ //Enquanto for promisse ou estiver carregando
        return (
            <>
                <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}> 
                    <div className="content-wrapper">
                        <main className="main-content container-fluid">
                            <Loading />
                        </main>
                    </div>
                </div>
            </>
        )
    }

    if (data.error) { // Quando o promise dar reject
        return (<ErrorScreen />)
    }


    return(
        <div className={`planningSection ${DarkMode ? 'dark-mode-allShipmentsSection' : ''}`}>
            <main className='container-fluid' style={{padding: '0'}}>
                <ModalConfirm />
                {
                    showModal.get() ?
                    <BasicModal closeFunction={()=> showModal.set(false)}>
                        <ThemeProvider theme={defaultTheme}>
                            <Paper elevation={3} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '2rem', width: '80rem', margin: '1rem 0', padding: '2rem'}}>
                                <Box sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%'}}>
                                    <h2>Entradas</h2>
                                </Box>
                                {
                                    showMultipleModal.get() ?
                                        entradas.get().map((item: any, index)=>{
                                            return(
                                                <Paper elevation={3} sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', gap: '2rem', width: '100%', padding: '1rem'}}>
                                                    <TextField disabled size="small" type='text' label="SKU" variant="standard" defaultValue={item.sku} 
                                                        sx={{minWidth: '21rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiInputBase-input': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                                    />
                                                    <TextField size="small" type='number' label="Entradas" variant="standard" defaultValue={item.entry} 
                                                        sx={{minWidth: '21rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiInputBase-input': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                                        onChange={(evt: any)=>{
                                                            const value = parseInt(evt.target.value);
                                                            entradas[index].merge({entry: value})
                                                        }}
                                                    />
                                                </Paper>
                                            )
                                        })
                                    :
                                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', gap: '2rem', width: '100%'}}>
                                        <TextField disabled size="small" type='text' label="SKU" variant="standard" defaultValue={entradas[0].sku.get()} 
                                            sx={{minWidth: '21rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiInputBase-input': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                        />
                                        <TextField size="small" type='number' label="Entradas" variant="standard" defaultValue={entradas[0].entry.get()} 
                                            sx={{minWidth: '21rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiInputBase-input': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                            onChange={(evt: any)=>{
                                                const value = parseInt(evt.target.value);
                                                entradas[0].merge({entry: value})

                                            }}
                                        />
                                    </Box>
                                }

                                <Box sx={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'flex-end', gap: '2rem', padding: '2rem'}}>
                                    <Button size='small' variant="contained" color='primary' sx={{minWidth: 'fit-content', fontSize: BUTTON_FONT_SIZE, '& .MuiButton-startIcon':{margin: '0'}}} onClick={handleEntries}>
                                        Salvar
                                    </Button>
                                </Box>
                            </Paper>
                        </ThemeProvider>
                    </BasicModal>
                    :
                    null
                }
                {
                    data ?
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '2rem'}}>
                            <ThemeProvider theme={defaultTheme}>
                                <Paper elevation={3} sx={{display: 'flex', justifyContent: 'flex-end', gap: '1rem', width: '100%', margin: '1rem 0', padding: '2rem'}}>
                                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', width: '100%'}}>
                                        <h3>{`Ordem de Produção n°: ${data.number.get()}`}</h3>
                                    </Box>
                                    <FormControl size='small' variant='outlined' sx={{minWidth: '20rem'}}>
                                        <InputLabel id="status-labelr" style={{fontSize: FONT_SIZE}}>Status</InputLabel>
                                            <Select
                                                labelId="status-labelr"
                                                id="status"
                                                name='status'
                                                value={data.status.get()}
                                                label="Status"
                                                style={{fontSize: FONT_SIZE}}
                                                required
                                                onChange={handleChangeStatus}
                                            >
                                            <MenuItem disabled value="Não iniciado">Não Iniciado</MenuItem>
                                            <MenuItem value="Em separação">Em Separação</MenuItem>
                                            <MenuItem value="Em produção">Em Produção</MenuItem>
                                            <MenuItem disabled value="Finalizado">Finalizado</MenuItem>
                                            <MenuItem value="Cancelado">Cancelado</MenuItem>
                                            </Select>
                                    </FormControl>
                                    <Button size='small' variant="outlined" color='primary' sx={{minWidth: 'fit-content', fontSize: BUTTON_FONT_SIZE, '& .MuiButton-startIcon':{margin: '0'}}} onClick={handleMultipleEntries}>
                                        Entrada em Lote
                                    </Button>
                                    <Button size='small' variant="outlined" color='primary' sx={{minWidth: 'fit-content', fontSize: BUTTON_FONT_SIZE, '& .MuiButton-startIcon':{margin: '0'}}}>
                                        Troca RMA
                                    </Button>
                                    <Button size='small' variant="contained" color='primary' sx={{minWidth: 'fit-content', fontSize: BUTTON_FONT_SIZE, '& .MuiButton-startIcon':{margin: '0'}}} onClick={handleFinish}>
                                        Finalizar
                                    </Button>
                                </Paper>
                            </ThemeProvider>
                            <VirtualizedGrid 
                                rows={data.items.attach(Downgraded).get()}
                                columns={columns}
                                options={options}
                                tableHeight={'auto'}
                                title="Finalização"
                                onSelectionDefault={(rows: any)=>  handleSelection(rows)}
                                defaultSort={{field: 'qtd', direction: 'asc'}}
                                actions={[
                                    {icon: 'add', title: 'Alterar Entradas', method: (evt: any, row:any)=> {
                                        entradas.set([{idItem: row._id, entry: 0, sku: row.sku}]);
                                        showModal.set(true);
                                    }}
                                ]} 
                            />
                            <Box sx={{display: 'flex',flexDirection: 'column', gap: '1rem', width: '100%'}}>
                                <h3>Observação</h3>
                                <TextField size="small" type='text' placeholder="Observação" variant="outlined"
                                    multiline minRows={5} maxRows={5}
                                    value={data.observation.get()}
                                    sx={{minWidth: '21rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                />
                            </Box>
                        </Box>
                    : null
                }
            </main>
        </div>
    )
    
}
