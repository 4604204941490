import './style.scss';
import Logo from '../../assets/images/logo-min.png'
import { useHistory, useLocation } from 'react-router';
import { useDarkMode } from '../../hooks/useDarkMode';

export const Header = (props: any) =>{
    const history = useHistory();
    const location = useLocation()
    const {DarkMode, } = useDarkMode();
    
    return (
        location.pathname !== '/login' ?
        <nav className={`headerMenu-container ${DarkMode ? "dark-mode-header" : ''}`}>
            <div onClick={()=> history.push("/")} className="clicker-div">
                <div className="image-container">
                    <img src={Logo} alt="MeLiBeat logo" />
                </div>
                <h3>MeLiBeat</h3>
            </div>
        </nav>
        :
        null
    )
}