import { useEffect, useRef } from 'react';
import { Card } from '../../../../components/cards/Card';
import { BasicModal } from '../../../../components/modal/BasicModal';
import { PopOverMenuModal } from '../../../../components/modal/PopOverMenuModal';
import { BasicForm , InputMainContainer, InputFilterContainer, DateFilterContainer } from '../../../../components/Form/BasicForm';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import {BsFillTagFill } from 'react-icons/bs';
import {FaTruck, FaAward, FaEdit, FaMoneyBill} from 'react-icons/fa';
import {FiAlertCircle, FiAlertOctagon, FiAlertTriangle} from 'react-icons/fi';
import {GrAlert} from 'react-icons/gr';
import {IoMdAddCircleOutline} from 'react-icons/io'
import { ModalConfirm } from '../../../../components/modal/ModalConfirm';
import { useModal } from '../../../../hooks/useModal';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import VirtualizedGrid from '../../../../components/table/VirtualizedGrid';
import { styled } from '@mui/material/styles';
import '../../../../components/table/globalInputs.scss';
import Popper from '@material-ui/core/Popper';
import { ObjetoVazio, ItensShippingRelationAlteration, BlingProductsType, URLModal, IBlingProducts } from "../../../../types/Types"
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import socket from '../../../../utils/socketConnection';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import {FiExternalLink} from 'react-icons/fi';
import { createTheme } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useHandleErrors } from '../../../../hooks/useHandleErrors';
import FormControlLabel from '@mui/material/FormControlLabel';

import { 
    GridFilterItem,
} from '@mui/x-data-grid';


const StyledPopper = styled(Popper)({
    "& .MuiAutocomplete-listbox": {
      '& li':{
        fontSize: 'clamp(1.1rem, 1vw, 1.3rem)',
      }
    }
  });

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function CheckboxesTags(props: any) {
  const { item, applyValue, options:tag_options } = props;
  const handleFilterChange = (event: any, value: any) => {
    applyValue({ ...item, value: value });
  };

  return (
    <Autocomplete
      multiple
      options={tag_options}
      disableCloseOnSelect
      getOptionLabel={(option: any) => option.title}
      defaultValue={item.value}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
            style={{ marginRight: 8 }}
            // checked={selected_values.includes(option.title)}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label="Tags" variant="standard" sx={{'& .MuiChip-root':{fontSize: 'clamp(1rem, 1.1vw, 1.2rem)'}}} />
      )}
      PopperComponent={StyledPopper}
      onChange={handleFilterChange}
    />
  );
}

interface ITotalizer {
    adsToChange: number,
    adsToFinish: number,
    changedAds: number,
    percentageFinished: number
}

interface IDataShipping {
    scheduling: string | undefined ,
    description: string| undefined ,
    schedulingQty: number| undefined ,
    volumesQty: number | undefined ,
    completed: boolean,
    LOADED: boolean
}

export const ChangeAds = (props: any) =>{
    const handleValidateStatus = useHandleErrors();
    const data: State<ItensShippingRelationAlteration[]> = useState<ItensShippingRelationAlteration[]>([]);
    const showModal: State<boolean> = useState<boolean>(false);
    const showPopOverMenuModal: State<boolean> = useState<boolean>(false);
    const blingProducts: State<BlingProductsType> = useState<BlingProductsType>(props.blingProducts);
    const mlbId: State<string> = useState<string>('');
    const variationId: State<string> = useState<string>('');
    const sku: State<string | undefined> = useState<string | undefined>('');
    const overwriteSkus: State<boolean> = useState<boolean>(false);
    const menuPosition: State<object> = useState<object>({'x': Number, 'y': Number});
    const showFinishBtn: State<boolean> = useState<boolean>(false);
    const newPrice: State<ObjetoVazio> = useState<ObjetoVazio>({});
    const {ModalData, showHideModal} = useModal();

    // const adsPendingAlteration: State<number> = useState<number>(0);
    // const adsWaitingPromoEnding: State<number> = useState<number>(0);
    // const adsChanged: State<number> = useState<number>(0);
    // const percentageFinished: State<number> = useState<number>(0);

    const urlModal: State<URLModal> = useState<URLModal>({url: '', title: ''});

    const isPrecificationCompleted: State<boolean> = useState<boolean>(false);
    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })

    const itemsFinished: any = useRef(null)
    const totalizer = useState<ITotalizer>({adsToChange: 0, adsToFinish: 0, changedAds: 0, percentageFinished: 0})
    const inputs: any = useState(undefined)
    const dataShipping = useState<IDataShipping>({scheduling: undefined, description: undefined, schedulingQty: undefined, volumesQty: undefined, completed: false, LOADED: false});

    let resData: any
    let idx: number

    useEffect(() => {
        socket.emit("fulfillment:getStepThree", {'id': props.itemId});
        socket.on("responseData", (result: any) => {
            switch(result.operation){
                case 'fulfillment:getStepThree':
                    const itens = result.data.itens.map((item: any)=>{
                        let tags: any = [];
                        let relationTags: any = [];
                        const skuToCompareFiscal = item.sku.substring(0,1) === '9' ? `${item.sku}_` : item.sku;

                        if(item.actualDeal) tags.push("Promoção (Sim)")
                        else tags.push("Promoção (Não)")
                        if(item.actualFree_shipping) tags.push("Envio Grátis")
                        if(item.actualType_mlb === 'gold_special') tags.push("Anúncio Clássico")
                        else tags.push("Anúncio 12x")

                        if(skuToCompareFiscal !== item.actualSkuFiscal) relationTags.push("SKU Fiscal Não Relacionada")
                        else relationTags.push("SKU Fiscal Relacionada")
                        if(item.sku !== item.actualSku) relationTags.push("SKUS Não Relacionadas")
                        else relationTags.push("SKUS Relacionadas")
                        if(item.cost_product.toFixed(2) !== item.actualCostFiscal.toFixed(2)) relationTags.push("Custo Fiscal Relacionado")
                        else relationTags.push("Custo Fiscal Não Relacionado")
                        if(item.type_mlb === "gold_special") relationTags.push("Anúncio Clássico")
                        else relationTags.push("Anúncio 12x")
                        if(item.actualSell_price < 79 && item.actualFree_shipping) relationTags.push("Frete Grátis Ativo Produtos < R$ 79")
                        if(item.precification_all) relationTags.push("Precificação Geral")

                        item.actualTags = tags;
                        item.relationTags = relationTags;

                        return item
                    })
                    data.set(itens);
                    dataShipping.batch(
                        (shipping)  =>  {
                            shipping.scheduling.set(result.data.scheduling)
                            shipping.description.set(result.data.description)
                            shipping.schedulingQty.set(Math.round(result.data.quantity))
                            shipping.volumesQty.set(Math.round(result.data.volume))
                            shipping.completed.set(result.data.completed.alteration)
                            shipping.LOADED.set(true)
                        })
                    isPrecificationCompleted.set(result.data.completed.precification)
                    //Inicializador e Populizer dos Inputs
                    const inputsFill: Map<string,any> = new Map()
                    const finishedFill: Map<string,any> = new Map()
                    for(let item of result.data.itens){
                        inputsFill.set(item._id, {
                            alteration: item.alteration,
                            check_mlb: item.check_mlb,
                            check_tax_data: item.check_tax_data,
                            check_stock: item.check_stock,
                            finished_alteration: item.finished_alteration,
                        })
                        if(item.finished){
                            finishedFill.set(item._id,item.finished_alteration)
                        }
                    }
                    
                    inputs.set(Object.fromEntries(inputsFill))
                    itemsFinished.current = Object.fromEntries(finishedFill)
                    handleChangeData()
                    break;
                case 'fulfillment:putStatus':
                    props.resetPage()
                    toast.success("Seção de Alteração Finalizada!");
                    break;
                case 'fulfillment:putItem':
                    switch(result.ref){
                        case 'check_mlb':
                            resData = result.data;
                            idx = data.attach(Downgraded).get().findIndex((item) => item._id  === resData._id)
                            if(idx > -1){
                                data[idx].batch(
                                    (item)  =>  {
                                        item.check_mlb.set(resData.check_mlb)
                                    }
                                )

                                inputs[resData._id].batch(
                                    (inp: any) => {
                                        inp.check_mlb.set(resData.check_mlb)
                                    }
                                )
                            }
                            handleChangeData()
                            break;
                        case 'check_tax_data':
                            resData = result.data;
                            idx = data.attach(Downgraded).get().findIndex((item) => item._id  === resData._id)
                            if(idx > -1){
                                data[idx].batch(
                                    (item)  =>  {
                                        item.check_tax_data.set(resData.check_tax_data)
                                    }
                                )

                                inputs[resData._id].batch(
                                    (inp: any) => {
                                        inp.check_tax_data.set(resData.check_tax_data)
                                    }
                                )
                            }
                            handleChangeData()
                            break;
                        case 'check_stock':
                            resData = result.data;
                            idx = data.attach(Downgraded).get().findIndex((item) => item._id  === resData._id)
                            if(idx > -1){
                                data[idx].batch(
                                    (item)  =>  {
                                        item.check_stock.set(resData.check_stock)
                                    }
                                )

                                inputs[resData._id].batch(
                                    (inp: any) => {
                                        inp.check_stock.set(resData.check_stock)
                                    }
                                )
                            }
                            handleChangeData()
                            break;
                        case 'finished_alteration':
                            resData = result.data;
                            idx = data.attach(Downgraded).get().findIndex((item) => item._id  === resData._id)
                            if(idx > -1){
                                data[idx].batch(
                                    (item)  =>  {
                                        item.finished_alteration.set(resData.finished_alteration)
                                    }
                                )

                                inputs[resData._id].batch(
                                    (inp: any) => {
                                        inp.finished_alteration.set(resData.finished_alteration)
                                    }
                                )
                            }
                            itemsFinished.current[resData._id] = resData.finished_alteration
                            handleFinishedButton()
                            break;
                        default:
                            break;
                    }
                    break;
                case 'fulfillment:putSellPriceML':
                    resData = result.data;
                    idx = data.attach(Downgraded).get().findIndex((item) => item._id  === resData.id_item)
                    if(idx > -1){
                        data[idx].batch(
                            (item)  =>  {
                                item.actualSell_price.set(resData.sell_price)
                            }
                        )
                        toast.success(`Preço do Anúncio (Mercado Livre): ${resData.mlb_id} alterado para: ${resData.sell_price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`);
                        handleChangeData()
                    }
                    break;
                default:
                    break;
            }
        })
        socket.on("responseError", (result) => {
            toast.error(result.message);
        })
    }, [])

    const handleFinishedButton = () => {
        const values = Object.values(itemsFinished.current);
        const totalToFinish = data.length
        const finished = values.filter(it => it === true).length
        if(finished === totalToFinish && showFinishBtn.get() === false){
            showFinishBtn.set(true)
        }else if(finished !== totalToFinish && showFinishBtn.get() === true){
            showFinishBtn.set(false)
        }
    }


    const handleChangeData = ()=>{
        let finish:boolean = true;
        let sumNotFinished: number = 0,
        sumWaitingPromoEnding: number = 0,
        sumFinished: number = 0,
        totalAds: number = data.length;
        data.attach(Downgraded).get().forEach((item: ItensShippingRelationAlteration)=>{
            if(item.finished_alteration === false) {
                finish = false;
                sumNotFinished++
            }else{
                sumFinished++
            }

            // if(item.deal && (item.sell_price !== item.actualSell_price || item.sell_price_12x !== item.actualSell_price )){
            //     sumWaitingPromoEnding++
            // }
        })

        let percFinished: number = sumFinished / totalAds

        percFinished =  percFinished === undefined || percFinished === Infinity || isNaN(percFinished) ? 0 : percFinished;

        // percentageFinished.set(percFinished)
        // adsChanged.set(sumFinished)
        // adsWaitingPromoEnding.set(sumWaitingPromoEnding)
        // adsPendingAlteration.set(sumNotFinished)

        totalizer.batch(
            (total) => {
                total.adsToChange.set(sumNotFinished)
                total.adsToFinish.set(sumWaitingPromoEnding)
                total.changedAds.set(sumFinished)
                total.percentageFinished.set(percFinished)
            }
        )

        if(finish){
            showFinishBtn.set(true)
        }else{
            showFinishBtn.set(false)
        }
    }

    const checkVisibility = (column: string)=>{
        const storageColumnsData = localStorage.getItem('Alteração Anúncios') ? localStorage.getItem('Alteração Anúncios') : null;
        if(storageColumnsData !== null){
          const localData = JSON.parse(storageColumnsData);
          const shouldHide = localData.find((item: any)=> item.field === column);
          return shouldHide !== undefined ? !shouldHide.visibility : false
        }
        return false
    }

    const defaultTheme  = createTheme();

    const columns = [
        {
          headerName: "Anúncio ID",
          field: "mlb_id",
          type: "string",
          hide: checkVisibility('mlb_id'),
          headerAlign: 'center',
          flex: 1,
          minWidth: 100,
          renderCell: (rowData: any) =>{
            return(
              <>
                <Link 
                href={`https://www.mercadolivre.com.br/publicaciones/${rowData.row.mlb_id}/modificar/`} 
                target="_blank" rel="noreferrer" 
                color={'inherit'} 
                sx={{'&:hover':{color: defaultTheme.palette.primary.dark}}}
                style={{fontSize: 'clamp(1.2rem, 1vw, 1.3rem)', display: 'flex', alignItems: 'center', gap: '5px'}} 
                >
                    <div>{rowData.row.mlb_id}</div><FiExternalLink />
            </Link>
            </>
            )
          }
        },
        {
            headerName: "Produto",
            field: "product",
            type: "string",
            hide: checkVisibility('product'),
            headerAlign: 'center',
            flex: 3,
            minWidth: 300,
            renderCell: (rowData: any) =>{
                return(
                    <Tippy content={rowData.row.product}>
                        <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            <span title={rowData.row.product}>{rowData.row.product}</span>
                        </main>
                    </Tippy>
                )
              }
        },
        {
            headerName: "Observações",
            field: "observation ",
            type: "string",
            hide: checkVisibility('observation'),
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                  <span>{rowData.row.observation ? rowData.row.observation : 'N/A'}</span>
                )
              }
        },
        {
            headerName: "SKU",
            field: "sku",
            type: "string",
            hide: checkVisibility('sku'),
            headerAlign: 'center',
            flex: 0.8,
            minWidth: 80,
            renderCell: (rowData: any) =>{
                return(
                  <span>{rowData.row.sku}</span>
                )
              }
        },
        {
            headerName: "Quantidade",
            field: "quantity_shipped",
            type: "number",
            hide: checkVisibility('quantity_shipped'),
            headerAlign: 'center',
            flex: 0.8,
            minWidth: 80,
            renderCell: (rowData: any) =>{
                return(
                  <span>{rowData.row.quantity_shipped}</span>
                )
            }
        },
        {
            headerName: "Custo",
            field: "cost_product",
            type: "number",
            hide: checkVisibility('cost_product'),
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                  <span>{(rowData.row.cost_product ? rowData.row.cost_product : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                )
              }
        },
        {
            headerName: "Tags Anúncio Atual",
            field: "actualTags",
            type: "selection",
            hide: checkVisibility('actualTags'),
            headerAlign: 'center',
            flex: 1.2,
            minWidth: 120,
            filterOperators: [{
                value: 'contains',
                getApplyFilterFn: (filterItem: GridFilterItem) => {
                  const selected_values = filterItem.value ? filterItem.value.map((item: any)=> item.title) : [];
                  if (filterItem.value == null || selected_values === []) {
                    return null;
                  }
            
                  return ({ value }:{ value: any }): boolean => {
                    if (typeof value === 'object') {
                      return selected_values.every((item: any)=> value.includes(item))
                    }
                    return selected_values.every((item: any)=> value.includes(item))
                  };
                },
                // InputComponent: GridFilterInputValue,
                InputComponent: CheckboxesTags,
                InputComponentProps: { 
                    type: 'singleSelect', 
                    options: [
                        {title: 'Promoção (Sim)'}, 
                        {title: 'Promoção (Não)'}, 
                        {title: 'Envio Grátis'}, 
                        {title: 'Anúncio Clássico'}, 
                        {title: 'Anúncio 12x'}, 
                    ]},
              }],
              valueOptions: [
                'Promoção (Sim)', 'Promoção (Não)', 'Envio Grátis', 'Anúncio Clássico', 'Anúncio 12x'
              ],
            renderCell: (rowData: any) => {
                const tagColors: any = {
                  'tags': 
                  [
                    {'title': `Promoção: ${rowData.row.actualDeal ? 'Sim':'Não'}`, 'value': <BsFillTagFill className="icon" />, 'color': (rowData.row.actualDeal ? '#e056fd':'#a5b1c2')},
                    {'title': 'Envio Grátis', 'value': <FaTruck className="icon" />, 'color': (rowData.row.actualFree_shipping ? '#4b7bec':'#a5b1c2')},
                    {'title': `Tipo de Listagem: ${rowData.row.actualType_mlb === 'gold_special' ? 'Clássico':'12x'}`, 'value': <FaAward className="icon"/>, 'color': (rowData.row.actualType_mlb === 'gold_pro' ? '#e1b12c' : '#a5b1c2')},
                  ]
                };
                return (
                  <div className="tags-container" style={{marginTop: 0, flexWrap: 'nowrap', display: 'flex', gap: '5px'}}>
                    {
                      Object.values(tagColors.tags).map((item: any, index: any)=>{
                        return (
                            <Tippy key={index} content={<div style={{display: 'flex', gap: '1rem'}}>{item.title}</div>} maxWidth={'none'}>
                                <span key={index} className="tag-small" style={{color: item.color}}>{item.value}</span>
                            </Tippy>
                        )
                      })
                    }
          
                  </div>
                )
            }
        },
        {
            headerName: "Preço Venda Atual",
            field: "actualSell_price",
            type: "number",
            hide: checkVisibility('actualSell_price'),
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                const actual_sell_price = (rowData.row.actualSell_price ? rowData.row.actualSell_price : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
                return(
                    <strong>{actual_sell_price}</strong>
                )
            }
        },
        {
            headerName: "Tags Relação",
            field: "relationTags",
            type: "selection",
            sortable: false,
            hide: checkVisibility('relationTags'),
            headerAlign: 'center',
            flex: 1,
            minWidth: 150,
            filterOperators: [{
                value: 'contains',
                getApplyFilterFn: (filterItem: GridFilterItem) => {
                  const selected_values = filterItem.value ? filterItem.value.map((item: any)=> item.title) : [];
                  if (filterItem.value == null || selected_values === []) {
                    return null;
                  }
            
                  return ({ value }:{ value: any }): boolean => {
                    if (typeof value === 'object') {
                      return selected_values.every((item: any)=> value.includes(item))
                    }
                    return selected_values.every((item: any)=> value.includes(item))
                  };
                },
                // InputComponent: GridFilterInputValue,
                InputComponent: CheckboxesTags,
                InputComponentProps: { 
                    type: 'singleSelect', 
                    options: [
                        {title: 'SKU Fiscal Relacionada'}, 
                        {title: 'SKU Fiscal Não Relacionada'}, 
                        {title: 'SKUS Relacionadas'}, 
                        {title: 'SKUS Não Relacionadas'}, 
                        {title: 'Custo Fiscal Relacionado'}, 
                        {title: 'Custo Fiscal Não Relacionado'}, 
                        {title: 'Anúncio Clássico'}, 
                        {title: 'Anúncio 12x'}, 
                        {title: 'Frete Grátis Ativo Produtos < R$ 79'}, 
                        {title: 'Precificação Geral'}, 
                    ]},
              }],
              valueOptions: [
                'SKU Fiscal Relacionada', 'SKU Fiscal Não Relacionada', 'SKUS Relacionadas', 'SKUS Não Relacionadas',
                'Custo Fiscal Relacionado', 'Custo Fiscal Não Relacionado', 'Anúncio Clássico', 'Anúncio 12x',
                'Frete Grátis Ativo Produtos < R$ 79', 'Precificação Geral'
              ],
            renderCell: (rowData: any) => {
                const skuToCompareFiscal = rowData.row.sku.substring(0,1) === '9' ? `${rowData.row.sku}_` : rowData.row.sku;
                const tagColors: any = {
                  'tags': 
                  [
                    {
                        'title': 
                            <div style={{display: 'flex', gap: '.5rem', flexDirection: "column"}}>
                                <strong>SKU: <strong style={{color: (skuToCompareFiscal.replace('_','') !== rowData.row.actualSkuFiscal ? '#d65446':'#2ECC71')}}>{skuToCompareFiscal}</strong></strong>
                                <strong>SKU Fiscal: <strong style={{color: (skuToCompareFiscal.replace('_','') !== rowData.row.actualSkuFiscal ? '#d65446':'#2ECC71')}}>{rowData.row.actualSkuFiscal}</strong></strong>
                            </div>, 
                        'value': <FiAlertCircle className="icon" />, 
                        'color': (skuToCompareFiscal !== rowData.row.actualSkuFiscal ? '#EE5A24':'#a5b1c2')
                    },
                    {
                        'title': 
                            <div style={{display: 'flex', gap: '.5rem', flexDirection: "column"}}>
                                <strong>SKU: <strong style={{color: (rowData.row.sku !== rowData.row.actualSku ? '#d65446':'#2ECC71')}}>{rowData.row.sku}</strong></strong>
                                <strong>SKU Anúncio: <strong style={{color: (rowData.row.sku !== rowData.row.actualSku ? '#d65446':'#2ECC71')}}>{rowData.row.actualSku}</strong></strong>
                            </div>, 
                        'value': <IoMdAddCircleOutline className="icon" />, 
                        'color': (rowData.row.sku !== rowData.row.actualSku ? '#01a3a4':'#a5b1c2')
                    },
                    {
                        'title': 
                        <div style={{display: 'flex', gap: '.5rem', flexDirection: "column"}}>
                            <strong>Custo: <strong style={{color: (rowData.row.cost_product.toFixed(2) !== rowData.row.actualCostFiscal.toFixed(2) ? '#d65446':'#2ECC71')}}>{(rowData.row.cost_product ? rowData.row.cost_product:0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></strong>
                            <strong>Custo Fiscal: <strong style={{color: (rowData.row.cost_product.toFixed(2) !== rowData.row.actualCostFiscal.toFixed(2) ? '#d65446':'#2ECC71')}}>{(rowData.row.actualCostFiscal ? rowData.row.actualCostFiscal:0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></strong>
                        </div>,  
                        'value': <FiAlertOctagon className="icon" />, 
                        'color': (rowData.row.cost_product.toFixed(2) !== rowData.row.actualCostFiscal.toFixed(2) ? '#1B1464':'#a5b1c2')
                    },
                    {
                        'title':
                        <div style={{display: 'flex', gap: '.5rem', flexDirection: "column"}}>
                            {
                                rowData.row.type_mlb === "gold_special" ?
                                <>
                                    <strong>Preço Venda Precificador (0x): <strong style={{color: (rowData.row.sell_price.toFixed(2) !== rowData.row.actualSell_price.toFixed(2) ? '#d65446':'#2ECC71')}}>{(rowData.row.sell_price ? rowData.row.sell_price:0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></strong>
                                    <strong>Preço Venda (ML): <strong style={{color: (rowData.row.sell_price.toFixed(2) !== rowData.row.actualSell_price.toFixed(2)) ? '#d65446':'#2ECC71'}}>{(rowData.row.actualSell_price ? rowData.row.actualSell_price:0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></strong>
                                </>
                                :
                                <>
                                    <strong>Preço Venda Precificador (12x): <strong style={{color: (rowData.row.sell_price_12x.toFixed(2) !== rowData.row.actualSell_price.toFixed(2) ? '#d65446':'#2ECC71')}}>{(rowData.row.sell_price_12x ? rowData.row.sell_price_12x:0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></strong>
                                    <strong>Preço Venda (ML): <strong style={{color: (rowData.row.sell_price_12x.toFixed(2) !== rowData.row.actualSell_price.toFixed(2) ) ? '#d65446':'#2ECC71'}}>{(rowData.row.actualSell_price ? rowData.row.actualSell_price:0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></strong>
                                </>
                            }
                        </div>,
                        'value': <FiAlertTriangle className="icon"/>, 
                        'color': (rowData.row.type_mlb === "gold_special" ? (rowData.row.sell_price.toFixed(2) !== rowData.row.actualSell_price.toFixed(2) ? '#6F1E51' : '#a5b1c2') : (rowData.row.sell_price_12x.toFixed(2) !== rowData.row.actualSell_price.toFixed(2) ? '#6F1E51' : '#a5b1c2'))
                    },
                    {'title': `Frete Grátis Ativo Produtos < R$ 79`, 'value': <GrAlert className="icon"/>, 'color': ((rowData.row.actualSell_price < 79 && rowData.row.actualFree_shipping) ? '#B53471' : '#a5b1c2')},
                    {'title': 'Precificação Geral', 'value': <FaMoneyBill className="icon" />, 'color': (rowData.row.precification_all ? '#44bd32':'#a5b1c2')},
                  ]
                };
                return (
                  <div className="tags-container" style={{marginTop: 0, flexWrap: 'nowrap', display: 'flex', gap: '5px'}}>
                    {
                      Object.values(tagColors.tags).map((item: any, index: any)=>{
                          return(
                            <Tippy key={index} content={<div style={{display: 'flex', gap: '1rem'}}>{item.title}</div>} maxWidth={'none'}>
                                <span className="tag-small" style={{color: item.color}}>{item.value}</span>
                            </Tippy>
                          )
                      })
                    }
          
                  </div>
                )
            }
        },
        {
            headerName: "Valor Venda",
            field: "sell_price",
            type: "number",
            hide: checkVisibility('sell_price'),
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    <Tippy content={
                        <div style={{display: 'flex', gap: '1rem', flexDirection: "column"}}>
                            <strong style={{textDecoration: rowData.row.actualType_mlb === "gold_special" ? "underline":'none'}}>
                                {`Clássico: ${rowData.row.sell_price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`}
                            </strong>
                            <strong style={{textDecoration: rowData.row.actualType_mlb === "gold_pro" ? "underline":'none'}}>
                                {`Premium: ${rowData.row.sell_price_12x.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`}
                            </strong>
                        </div>
                    } maxWidth={'none'}>
                        {
                            rowData.row.type_mlb === "gold_special" ?
                            <strong>{(rowData.row.sell_price ? rowData.row.sell_price : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong>
                            :
                            <strong>{(rowData.row.sell_price_12x ? rowData.row.sell_price_12x : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong>
                        }
                    </Tippy>
                )
            }
        },
        {
            headerName: "Alteração",
            field: "alteration",
            type: "string",
            hide: checkVisibility('alteration'),
            headerAlign: 'center',
            flex: 2,
            minWidth: 200,
            renderCell: (rowData: any) =>{
                return(
                    <div className="addRow">
                        <input 
                            type="number" 
                            name="change-price" 
                            id={`change-price-${rowData.row._id}`} 
                            className="add-row-input-small" 
                            min={0} 
                            step={0.01} 
                            defaultValue={(rowData.row.sell_price !== rowData.row.actualSell_price || rowData.row.sell_price_12x !== rowData.row.actualSell_price) ? rowData.row.type_mlb === "gold_special" ? rowData.row.sell_price : rowData.row.sell_price_12x : ""}
                            disabled={dataShipping.completed.get() || rowData.row.finished_alteration}
                            onBlur={(evt: React.BaseSyntheticEvent)=> {
                                const newPrices:any = {...newPrice};
                                newPrices[rowData.row._id] = evt.target.value;
                                newPrice.set(newPrices)
                            }}
                        />
                        <button className="btn-small round"  disabled={dataShipping.completed.get() || rowData.row.finished_alteration} onClick={(evt: React.BaseSyntheticEvent)=> {
                            if(rowData.row.actualDeal){
                                showHideModal({show: true, title: "Esse anúncio possúi promoções ativas. Gostaria de alterará-lo no Mercado Livre mesmo assim?", execute: (confirm: boolean)=> handleChangePriceML(confirm, rowData.row)})
                            }else{
                                handleChangePriceML(true, rowData.row)
                            }
                        }}><FaEdit className="icon-small"/>Preço ML</button>
                        <label className="inputTableLabelMoney" htmlFor={`change-price-${rowData.row._id}`} >R$</label>
                    </div>
                )
            }
        },
    
        {
            headerName: "Revisão Anúncio",
            field: "check_mlb",
            type: "string",
            hide: checkVisibility('check_mlb'),
            headerAlign: 'center',
            flex: 0.7,
            minWidth: 70,
            renderCell: (rowData: any) =>{
                return(
                    <div className="switch-container">
                        <label className="switch">
                            <input 
                                type="checkbox" 
                                checked={inputs[rowData.row._id].check_mlb.get()}
                                disabled={dataShipping.completed.get() || rowData.row.finished_alteration}
                                onChange={async (evt: React.BaseSyntheticEvent)=> {
                                    evt.persist();
                                    const processedData = [
                                        {
                                            "field": "check_mlb",
                                            "value": evt.target.checked,
                                        }
                                    ]
                                    inputs[rowData.row._id].check_mlb.set(evt.target.checked)
                                    socket.emit("fulfillment:putItem", {'id': props.itemId, "fields": processedData, "idItem": rowData.row._id, ref: "check_mlb"});
                                }}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                )
            }
        },
        {
            headerName: "Dados Fiscais",
            field: "check_tax_data",
            type: "string",
            hide: checkVisibility('check_tax_data'),
            headerAlign: 'center',
            flex: 0.7,
            minWidth: 70,
            renderCell: (rowData: any) =>{
                return(
                    <div className="switch-container">
                        <label className="switch">
                            <input 
                                type="checkbox" 
                                checked={inputs[rowData.row._id].check_tax_data.get()}
                                disabled={dataShipping.completed.get() || rowData.row.finished_alteration}
                                onChange={async(evt: React.BaseSyntheticEvent)=> {
                                    // handleCellEdit(evt.target.checked, {'_id': rowData.row._id}, "check_tax_data")
                                    evt.persist();
                                    const processedData = [
                                        {
                                            "field": "check_tax_data",
                                            "value": evt.target.checked,
                                        }
                                    ]
                                    inputs[rowData.row._id].check_tax_data.set(evt.target.checked)
                                    socket.emit("fulfillment:putItem", {'id': props.itemId, "fields": processedData, "idItem": rowData.row._id, ref: "check_tax_data"});
                                }}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                )
            }
        },
        {
            headerName: "Transf. Estoque",
            field: "check_stock",
            type: "string",
            hide: checkVisibility('check_stock'),
            headerAlign: 'center',
            flex: 0.7,
            minWidth: 70,
            renderCell: (rowData: any) =>{
                return(
                    <div className="switch-container">
                        <label className="switch">
                            <input 
                                type="checkbox" 
                                checked={inputs[rowData.row._id].check_stock.get()}
                                disabled={dataShipping.completed.get() || rowData.row.finished_alteration}
                                onChange={async(evt: any)=> {
                                    // handleCellEdit(evt.target.checked, {'_id': rowData.row._id}, "check_stock")
                                    evt.persist();
                                    const processedData = [
                                        {
                                            "field": "check_stock",
                                            "value": evt.target.checked,
                                        }
                                    ]
                                    inputs[rowData.row._id].check_stock.set(evt.target.checked)
                                    socket.emit("fulfillment:putItem", {'id': props.itemId, "fields": processedData, "idItem": rowData.row._id, ref: "check_stock"});
                                }}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                )
            }
        },
        {
            headerName: "Finalizado",
            field: "finished_alteration",
            type: "boolean",
            headerAlign: 'center',
            flex: 0.7,
            minWidth: 70,
            renderCell: (rowData: any) =>{
                return(
                    <div className="switch-container">
                        <label className="switch">
                            <input 
                                type="checkbox" 
                                checked={inputs[rowData.row._id].finished_alteration.get()}
                                disabled={dataShipping.completed.get()}
                                onChange={async(evt: React.BaseSyntheticEvent)=> {
                                    evt.persist();
                                    if(!rowData.row.check_mlb || !rowData.row.check_stock || !rowData.row.check_tax_data){
                                        toast.info("Os campos Revisão Anúncio, Dados Fiscais e Transferência de Estoque devem estar preenchidos.");
                                        evt.target.checked = !evt.target.checked;
                                        return
                                    }
                                    // handleCellEdit(evt.target.checked, {'_id': rowData.row._id}, "finished_alteration")
                                    const processedData = [
                                        {
                                            "field": "finished_alteration",
                                            "value": evt.target.checked,
                                        }
                                    ]
                                    inputs[rowData.row._id].finished_alteration.set(evt.target.checked)
                                    socket.emit("fulfillment:putItem", {'id': props.itemId, "fields": processedData, "idItem": rowData.row._id, ref: "finished_alteration"});
                                }}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                )
            }
        },
    ]

    const options = {
        palette:{
            tableHeader: {
            main: '#F7CE3E',
            dark: '#ac902b',
            light: '#f8d764',
            contrastText: '#000'
            },
        },
        alternate: true,
        rowStyle: (rowData: any) => {
            let row_class = '';
            if(rowData.finished_alteration) {
                row_class = "AllShipments-alternate-color-finished"
            }        
            return row_class
        },
    }
    


    const handleSubmit = (evt:React.BaseSyntheticEvent)=>{
        let canFinish:boolean = true;
        let showToastFinished:boolean = true;
        evt.preventDefault();
        if(data.length <= 0){
            toast.info("Não é possível finalizar um envio sem itens.")
            return
        }
        data.attach(Downgraded).get().forEach((item: ItensShippingRelationAlteration)=>{
            if(!item.finished_alteration){
                if(showToastFinished){
                    toast.info("Todos os itens devem estar finalizados.");
                    showToastFinished = false;
                }
                canFinish = false
            }
        })

        if(canFinish){
            socket.emit("fulfillment:putStatus", {'id': props.itemId, "field": "alteration", "value": true});
        }
    }



    const handlePopOverMenuModal = ()=>{
        if(blingProducts !== undefined){
            showPopOverMenuModal.set(false);
            showModal.set(true)
            sku.set('')
        }else{
            toast.info("Por favor aguarde o carregamento dos dados do Bling!");
        }
    }

    const handleModal = (event: any, value: ItensShippingRelationAlteration)=>{
        menuPosition.set({x: (event.pageX-270), y: event.pageY})
        showPopOverMenuModal.set(true);
        mlbId.set(value.mlb_id);
        variationId.set(value.variation_id);
    }

    const handleSubmitMLB = async(evt: React.BaseSyntheticEvent)=>{
        evt.preventDefault();
        if(sku === undefined){
            toast.info("Você não adicionou um SKU");
            return
        }
        const processedData: ObjetoVazio = {
            "id_mlb": mlbId,
            "variation_id": variationId,
            "sku": sku
        }

        if(urlModal.url.value === "/mlbs/taxdata/sku"){
            processedData["updateDataTax"] =  overwriteSkus.get()
        }

        axs.post<any>(urlModal.url.value, processedData).then((r: any) => {
            if(r.status === 200){
                if(r.data !== undefined){
                    toast.success(`SKU dos Dados Fiscais do  Anúncio: ${mlbId.value} alterado para: ${r.data.sku}`);
                }
            }else{
                toast.error(r.message);
            }
        }).catch((err: any)=>{
            handleValidateStatus(err.response)
        })

        if(urlModal.url.value === "/mlbs/taxdata/sku"){
            overwriteSkus.set(false)
        }
        showModal.set(false);
    }

    // const handleUpdatePrice = (evt:React.BaseSyntheticEvent, values: any) =>{
    //     evt.preventDefault();
    //     let temp_value:number = parseFloat(newPrice[values._id].value);

    //     if(temp_value === null || temp_value === undefined || isNaN(temp_value)){
    //         temp_value = (values.sell_price !== values.actualSell_price || values.sell_price_12x !== values.actualSell_price) ? values.type_mlb === "gold_special" ? values.sell_price : values.sell_price_12x : null
    //     }
    //     if(values.actualDeal){
    //         newPrice.set({[values._id]: temp_value})
    //         showHideModal({show: true, title: "Existe uma Promoção ativa neste anúncio. Gostaria de alterar o preço mesmo assim?", execute: (confirm: boolean)=> handleConfirm(confirm, values)})
    //     }else{
    //         const processedData = {
    //             "sku": values.sku,
    //             "id": values.idMlbAny,
    //             "price": temp_value
    //         }
    //         axs.post<any>('/anymarket/sellprice', processedData).then((r: any) => {
    //             if(r.status === 200){
    //                 if(r.data){
    //                     toast.success(`Preço no Anymarket alterado para: ${r.data.price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}!`);
    //                 }
    //             }else{
    //                 toast.error(r.message);
    //             }
    //         }).catch((err: any)=>{
    //             handleValidateStatus(err.response)
    //         })
            
    //         showModal.set(false);
    //     }
    // }

    const handleChangePriceML = (confirm: boolean, values: ItensShippingRelationAlteration) =>{
        let temp_value: number | undefined = parseFloat(newPrice[values._id].value);

        if(temp_value === undefined || temp_value === null || isNaN(temp_value)){
            temp_value = values.type_mlb === "gold_special" ? values.sell_price : values.sell_price_12x;
        }
        if(confirm){
            const processedData = {
                "variation_id": values.variation_id,
                "mlb_id": values.mlb_id,
                "sell_price": temp_value,
                "id_item": values._id,
                "id": props.itemId
            }

            // axs.post<any>('/mlbs/sellprice', processedData).then((r: any) => {
            //     if(r.status === 200){
            //         if(r.data){
            //             const resData = r.data;
            //             toast.success(`Preço do Anúncio (Mercado Livre): ${resData.mlb_id} alterado para: ${resData.sell_price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`);
            //         }
            //     }else{
            //         toast.error(r.message);
            //     }
            //  })
            socket.emit("fulfillment:putSellPriceML", processedData);
            showHideModal({show: false, title: ModalData?.title, execute: ModalData?.execute})
        }else{
            showHideModal({show: false, title: ModalData?.title, execute: ModalData?.execute})
        }

    }


    const handleConfirm = (confirm: boolean, values: any) =>{
        let temp_value: number = parseFloat(newPrice[values._id].value);
        if(confirm){
            const processedData = {
                "sku": values.sku,
                "id": values.idMlbAny,
                "price": temp_value
            }
            axs.post<any>('/anymarket/sellprice', processedData).then((r: any) => {
                if(r.status === 200){
                    if(r.data){
                        toast.success(`Preço no Anymarket alterado para: ${r.data.price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}!`);
                    }
                }else{
                    toast.error(r.message);
                }
            }).catch((err: any)=>{
                handleValidateStatus(err.response)
            })
            showModal.set(false);
        }else{
            showHideModal({show: false, title: ModalData?.title, execute: ModalData?.execute})
        }
    }


    return(
        <>
            <ModalConfirm />
            {
                showModal.get() === true ?
                <BasicModal closeFunction={()=> showModal.set(false)}>
                    <BasicForm SubmitFunction={(evt: React.BaseSyntheticEvent)=> handleSubmitMLB(evt)}>
                        <InputMainContainer>
                            <InputMainContainer>
                                <div className="input-container">
                                <Autocomplete
                                        id="skus"
                                        className="skus-select"
                                        options={blingProducts.attach(Downgraded).get()}
                                        getOptionLabel={(option: any) => option.sku}
                                        disableClearable
                                        renderInput={(params) => <TextField variant="standard" {...params} placeholder="SKU" />}
                                        renderOption={(props, option) => (
                                            <li {...props}>
                                              {option.label}
                                            </li>
                                        )}
                                        PopperComponent={(props)=> <StyledPopper {...props} style={{ width: "fit-content", padding: "1rem",display: "flex", flexDirection: "column", gap: '1rem' }} placement='bottom-start' />}
                                        onChange={(evt: React.BaseSyntheticEvent, value: IBlingProducts)=> {
                                            sku.set(value.sku)
                                        }}
                                    />
                                </div>
                            </InputMainContainer>
                        </InputMainContainer>

                        {
                            urlModal.url.value === "/mlbs/taxdata/sku" ?
                            <InputMainContainer>
                                <FormControlLabel
                                    label="Sobrescrever SKUs Existentes"
                                    sx={{'& .MuiFormControlLabel-label': {fontSize: 'clamp(1.3rem, 1vw, 1.5rem)'}}}
                                    control={
                                        <Checkbox
                                            sx={{'& .MuiSvgIcon-root': {width: '2.5rem', height: '2.5rem'}}}
                                            defaultChecked={false}
                                            onChange={(evt: any)=>overwriteSkus.set(evt.target.checked)}
                                        />
                                    }
                                />
                            </InputMainContainer>
                            :
                            null

                        }

                        <InputMainContainer>
                            <button type="submit">{urlModal.title.value}</button>
                        </InputMainContainer>
                    </BasicForm>
                </BasicModal>
                :
                null
            }

            {
                showPopOverMenuModal.get() ?
                    <PopOverMenuModal position={menuPosition.attach(Downgraded).get()} closeFunction={()=> showPopOverMenuModal.set(false)}>
                        <button onClick={(evt: any)=>{
                            urlModal.set({url: '/mlbs/taxdata/sku', title: "Alterar Dados Fiscais"})
                            handlePopOverMenuModal();
                        }}
                        >Alterar Dados Fiscais (Bling)</button>

                        <button onClick={(evt: any)=>{
                            urlModal.set({url: '/mlbs/infdata/sku', title: "Alterar Dados do Anúncio"})
                            handlePopOverMenuModal();
                        }}
                        >Alterar dos Dados do Anúncio (Bling)</button>
                    </PopOverMenuModal>
                : null
            }

            <div className="changeAdsSection">
                    <main className='container-fluid' style={{padding: '0'}}>
                        <BasicForm style={{width: '100%'}} SubmitFunction={handleSubmit}>
                            <InputMainContainer style={{justifyContent: "flex-end"}}>
                                <InputFilterContainer 
                                    label="Descrição do Envio"
                                    id="description"
                                    class="description"
                                    name="description"
                                    value={dataShipping.description.get()}
                                    type="text" 
                                    disabled={true}
                                />

                            <DateFilterContainer
                                label="Agendamento de Entrega"
                                class="date"
                                id="date" 
                                name="date"
                                value={dataShipping.scheduling.get()}
                                type="date"
                                disabled={true}
                            />
                            <InputFilterContainer 
                                label="Qtd. Volumes do Agendamento"
                                id="volumesItens"
                                class="volumesItens"
                                name="volumesItens"
                                value={dataShipping.volumesQty.get()} 
                                type="text" 
                                onEnter={true}
                                disabled={true}
                            />

                            <InputFilterContainer 
                                label="Qtd. Itens do Agendamento"
                                id="schedulingItens"
                                class="schedulingItens"
                                name="schedulingItens"
                                value={dataShipping.schedulingQty.get()} 
                                type="text" 
                                disabled={true}
                            />
                                <InputMainContainer style={{width: '60%'}}>
                                    <Button disabled={showFinishBtn.get() ? false:true || dataShipping.completed.get()  || !isPrecificationCompleted.get()} variant="contained" color='error' sx={{fontSize: 'clamp(1.2rem, 1.2vw, 1.6rem)', fontFamily: "'Lato', sans-serif", width: '100%', display: 'flex', alignItems: 'center', '& .MuiButton-startIcon':{margin: '0'}}} type='submit'>
                                        Finalizar Etapa
                                    </Button>
                                </InputMainContainer>
                            </InputMainContainer>
                        </BasicForm>

                        <div className="card-container">
                            <Card 
                                title="Anúncios Pendentes de Alteração"
                                content={totalizer.adsToChange.attach(Downgraded).get()}
                                lastMonth={0} percent={false} 
                                integer={false} style={{width: '40rem'}} simple={true} text={true}
                                prefix="" increment={true}
                            />
                            <Card 
                                title="Anúncios Aguardando Finalização da Promoção"
                                content={totalizer.adsToFinish.attach(Downgraded).get()} 
                                lastMonth={0} percent={false} 
                                integer={false} style={{width: '40rem'}} simple={true} text={true}
                                prefix="" increment={true}
                            />
                            <Card 
                                title="Produtos Alterados" 
                                content={totalizer.changedAds.attach(Downgraded).get()} 
                                lastMonth={0} percent={false} 
                                integer={false} style={{width: '40rem'}} simple={true} text={true} prefix=""
                                increment={true}
                            />
                            <Card 
                                title="% Concluída" content={totalizer.percentageFinished.attach(Downgraded).get()} lastMonth={0} percent={true} increment={true}
                                integer={false} text={false} style={{width: '40rem'}} simple={true} prefix="" decimals={2}
                            />
                        </div>

                    {
                        data.length > 0 && inputs.get() !== undefined ?
                        <>
                            <VirtualizedGrid 
                                rows={data.attach(Downgraded).get()}
                                columns={columns}
                                options={options}
                                tableHeight={'auto'}
                                title="Alteração Anúncios"
                                actions={[
                                    {icon: 'settings', title: 'Mais Ações', method: (event: any, row:any)=> {
                                        if(!row.finished_alteration){
                                            handleModal(event, row)
                                        }else{
                                            toast.info("Você não pode alterar as informações dessa linha!");
                                        }
                                    }}, 
                                ]}
                                defaultSort={{field: 'finished_alteration', direction: 'asc'}}
                            />
                        </>
                        : null
                    }
                </main>
            </div>
        </>
    )
}