import moment from 'moment';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional


const checkVisibility = (column: string)=>{
  const storageColumnsData = localStorage.getItem('Acompanhamento das Compras') ? localStorage.getItem('Acompanhamento das Compras') : null;
  if(storageColumnsData !== null){
    const localData = JSON.parse(storageColumnsData);
    const shouldHide = localData.find((item: any)=> item.field === column);
    return shouldHide !== undefined ? !shouldHide.visibility : false
  }
  return false
}

export const columns = [
  { 
    headerName: "Data Emissão", 
    field: "date", 
    type: "string",
    hide: checkVisibility('date'),
    headerAlign: 'center',
    flex: 1,
    minWidth: 100,
    valueFormatter: (params: any) => {
      const value_formatted = moment(params.value).format("DD/MM/YYYY");
      return value_formatted
    }
  },
  { 
    headerName: "Status", 
    field: "status", 
    type: "string",
    hide: checkVisibility('status'),
    headerAlign: 'center',
    flex: 1,
    minWidth: 100,
    renderCell: (rowData: any) => {
      let status;
      if(!rowData.row.finished && !rowData.row.delivered){
        status = "A caminho";
      }else if(!rowData.row.finished && rowData.row.delivered){
        status = "Entregue Transportadora";
      }else if(rowData.row.finished){
        status = "Finalizado";
      }else{
        status = "Indefinido";
      }
      return (
        <span>{status}</span>
      )
    }
  },
  { 
      headerName: "Nota Fiscal", 
      field: "number", 
      type: "number",
      hide: checkVisibility('number'),
      headerAlign: 'center',
      flex: 1,
      minWidth: 100,
  },
  { 
    headerName: "Fornecedor", 
    field: "sender", 
    type: "string",
    hide: checkVisibility('sender'),
    headerAlign: 'center',
    flex: 2,
    minWidth: 200,
    renderCell: (rowData: any) => {
      return (
        <Tippy content={rowData.row.sender}>
          <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            <span>{rowData.row.sender}</span>
          </main>
        </Tippy>
      )
    }
  },
  {
    headerName: "CNPJ",
    field: "cnpjSender",
    type: "string",
    hide: checkVisibility('cnpjSender'),
    headerAlign: 'center',
    flex: 1,
    minWidth: 100,
    renderCell: (rowData: any) => {
      return (
        <Tippy content={rowData.row.cnpjSender}>
          <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            <span>{rowData.row.cnpjSender}</span>
          </main>
        </Tippy>
      )
    }
  },
  {
    headerName: "UF",
    field: "senderAddress.uf",
    type: "string",
    hide: checkVisibility('senderAddress.uf'),
    headerAlign: 'center',
    flex: 1,
    minWidth: 50,
    renderCell: (rowData: any) => {
      return (
        <span>{rowData.row.senderAddress.uf}</span>
      )
    }
  },
  {
    headerName: "Cidade",
    field: "senderAddress.city",
    type: "string",
    hide: checkVisibility('senderAddress.city'),
    headerAlign: 'center',
    flex: 1,
    minWidth: 100,
    renderCell: (rowData: any) => {
      return (
        <span>{rowData.row.senderAddress.city}</span>
      )
    }
  },
  {
    headerName: "Valor Total",
    field: "totalValue",
    type: "number",
    hide: checkVisibility('totalValue'),
    headerAlign: 'center',
    flex: 1,
    minWidth: 100,
    renderCell: (rowData: any) => {
      return (
        <span>{rowData.row.totalValue.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
      )
    }
  },
  {
    headerName: "Volumes",
    field: "volume",
    type: "number",
    hide: checkVisibility('volume'),
    headerAlign: 'center',
    flex: 1,
    minWidth: 80,
    renderCell: (rowData: any) => {
      const volume = rowData.row.volume ? parseFloat(rowData.row.volume) : 0;
      const gross_weight = rowData.row.grossWeight ? parseFloat(rowData.row.grossWeight) : 0;
      return (
        <Tippy content={<div style={{display: 'flex', gap: '1rem'}}>{`Peso Bruto: ${gross_weight} Kg`}</div>} interactive={true} maxWidth={'none'}>
            <span>{`${volume}`}</span>
        </Tippy>
      )
    }
  },
  {
    headerName: "Transportadora",
    field: "Carrying",
    type: "string",
    hide: checkVisibility('Carrying'),
    headerAlign: 'center',
    flex: 1,
    minWidth: 150,
    renderCell: (rowData: any) => {
      return (
        <Tippy content={rowData.row.carrying}>
          <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            <span>{rowData.row.carrying}</span>
          </main>
        </Tippy>
      )
    }
  },
  {
    headerName: "Observações",
    field: "observations",
    type: "string",
    hide: checkVisibility('observations'),
    headerAlign: 'center',
    flex: 1,
    minWidth: 100,
    editable: true,
    renderCell: (rowData: any) => {
      return (
        <span style={{textDecoration: 'underline dotted'}}>{rowData.row.observations ? rowData.row.observations : "Observações"}</span>
      )
    }
  },
  {
    headerName: "Prazo Estimado",
    field: "deliveryTime",
    type: "date",
    hide: checkVisibility('deliveryTime'),
    headerAlign: 'center',
    flex: 1,
    minWidth: 100,
    editable: true,
    renderCell: (rowData: any) => {
      return (
        <span style={{textDecoration: 'underline dotted'}}>{rowData.row.deliveryTime ? moment(rowData.row.deliveryTime).format("DD/MM/YYYY") : "DD/MM/AAAA"}</span>
      )
    }
  },
]

export const options = {
  palette:{
    tableHeader: {
      main: '#F7CE3E',
      dark: '#ac902b',
      light: '#f8d764',
      contrastText: '#000'
    },
  },
  rowStyle: (rowData: any)=>{
    let row_class = "";

    if(!rowData.finished && !rowData.delivered && (rowData.products && rowData.products.some((item: any)=> item.skuRef === undefined))){
      row_class = "purchaseTracking-NotFinishedNotDelivered";
    }else if(!rowData.finished && !rowData.delivered && (rowData.products && rowData.products.every((item: any)=> item.skuRef !== undefined))){
      row_class = "purchaseTracking-merged";
    }
    else if(!rowData.finished && rowData.delivered){
      row_class = "purchaseTracking-delivered";
    }else if(rowData.finished){
      row_class = "purchaseTracking-finished";
    }
    else{
      row_class = "";
    }
  
    return row_class
  }
}