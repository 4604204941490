// import { useState } from 'react'
import { useContext } from 'react'
import { BugReportContext } from '../contexts/BugReportContext';

export function useBugReport() {
    const value = useContext(BugReportContext)

    return value;
}

