import { toast } from 'react-toastify';
import noData from '../../../assets/images/nodata.svg';
import { useModal } from '../../../hooks/useModal';
import { ModalConfirm } from '../../../components/modal/ModalConfirm';
import { BaseSyntheticEvent } from 'react';
import './style.scss';
import { MdEdit } from 'react-icons/md';
import {BsFillTagFill } from 'react-icons/bs';
import {FaEdit, FaTruck, FaAward, FaCheckCircle, FaTimesCircle, FaPauseCircle, FaHeart} from 'react-icons/fa';
import {GoZap} from 'react-icons/go';
import {BiRevision} from 'react-icons/bi'
import {FiExternalLink} from 'react-icons/fi';
import {MdSwapVerticalCircle} from 'react-icons/md';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import { BasicForm, InputMainContainer, InputFilterContainer, SelectFilterContainer } from '../../../components/Form/BasicForm';
import { BasicModal } from '../../../components/modal/BasicModal';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Popper from '@material-ui/core/Popper';
import placeholder from '../../../assets/images/placeholder.jpg';
import { useDarkMode } from '../../../hooks/useDarkMode';
import Link from '@mui/material/Link';
import { 
     GridFilterItem,
  } from '@mui/x-data-grid';
import { 
    IQualityControl, 
    PriceCalculate, 
    CalculatedResult, 
    IBlingProducts, 
    ObjetoVazio, 
    ProductsDataQualityControl,
    MultipleAnyData,
    IRawBlingProducts,

} from "../../../types/Types";
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import { Loading } from '../../../components/Loading/Loading';
import { BugReportModal } from '../../../components/modal/BugReportModal';

const defaultTheme  = createTheme();


export const AdsPrecification = (props:any) =>{
    document.title = "Precificação dos Anúncios - MeLiBeat";
    const handleValidateStatus = useHandleErrors();

    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const fetchResource = (query:any=undefined) => axs.get<IQualityControl[]>('/mlbs/qos/', {params: query}).then((r: any) => {
        handleValidateStatus(r)
        return processData(r.data);
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    });

    const fetchBling = () => axs.get<IRawBlingProducts[]>('/bling/products/').then((r: any) => {
        const processedProducts = Object.entries(r.data).map((item: any)=>{
            let obj:IRawBlingProducts = item[1]
            return {label: `${obj.sku} - ${obj.title}`, sku: obj.sku, cost: obj.cost}
        })
        return processedProducts
    })
    const data: State<IQualityControl[]> = useState<IQualityControl[]>([]);
    const blingProducts: State<IBlingProducts[]> = useState<IBlingProducts[]>(fetchBling);
    const showButtons: State<boolean> = useState<boolean>(false)
    const productsData: State<ProductsDataQualityControl[]> = useState<ProductsDataQualityControl[]>([]);
    const changeProductsData: State<IQualityControl> = useState<IQualityControl>({
        "catalog_relation": {},
        "_id": "",
        "id": "",
        "variation_id": "",
        "sku": "",
        "inventory_id": "",
        "seller_id": 0,
        "channels": [],
        "cost_product": 0,
        "cost_sale_fee": 0,
        "cost_shipping": 0,
        "sell_price": 0,
        "original_price": 0,
        "available_qty": 0,
        "pending_qty": 0,
        "total_sold": 0,
        "deal": false,
        "shipping_mode": "",
        "free_shipping": false,
        "listing_type": "",
        "category_id": "",
        "fulfillment": false,
        "catalog": false,
        "catalog_product": "",
        "photo": "",
        "variation_name": "",
        "title": "",
        "description_id": "",
        "video_id": "",
        "warranty": "",
        "link": "",
        "status": "",
        "sub_status": [],
        "warnings": [],
        "condition": "",
        "health": "",
        "tags": [],
        "date_created": "",
        "last_updated": "",
        "actual": false,
        "verifyDescription": 0,
        "verifySheet": 0,
        "verifyPhotos": 0,
        "verifyVideo": 0,
        "verifyTitle": 0,
        "verifyRating": 0,
        "verifyShipFull": false,
        "feeMarketplace": 0,
        "feeMarketplace12x": 0,
        "tax": 0,
        "sellPrice": 0,
        "sellPrice12x": 0,
        "profit": 0,
        "margin": 0,
        "brand": [""],
        "category_bling": "",
        "origin":"",
    });
    const {ModalData, showHideModal} = useModal();
    const showDescModal: State<boolean> = useState<boolean>(false);
    const newPrice: State<ObjetoVazio> = useState<ObjetoVazio>({});
    const priceCalculate: State<PriceCalculate> = useState<PriceCalculate>({
        "field_type": "",
        "field_value": "",
        "tax_input": "",
        "adType": "",
    });
    const calculatedResult: State<CalculatedResult> = useState<CalculatedResult>({
        "sellPrice": 0,
        "profit": 0,
        "margin": 0,
        "markup": 0
    });
    const showModal: State<boolean> = useState<boolean>(false);
    const sku: State<string | undefined> = useState<string | undefined>(undefined);
    const mlbId: State<string | undefined> = useState<string | undefined>(undefined);
    const variationId: State<string | null> = useState<string | null>(null);
    const multipleChangeModal: State<boolean> = useState<boolean>(false);
    const multipleAnyData: State<MultipleAnyData> = useState<MultipleAnyData>({
        "resultProcess": {
            "success": {
                "qty": 0,
                "detail": [
                    {
                        "status": '',
                        "sku":'',
                        "mlb_id": '',
                        "err": ''
                    }
                ],
            },
            "fail": {
                "qty": 0,
                "detail": [
                    {
                        "status": '',
                        "sku":'',
                        "mlb_id": '',
                        "err": ''
                    }
                ],
            }
        }
    });
    const anymarketPrices: State<ObjetoVazio> = useState<ObjetoVazio>({});
    const {DarkMode, } = useDarkMode();


    const StyledPopper = styled(Popper)({
        "& .MuiAutocomplete-listbox": {
          '& li':{
            fontSize: 'clamp(1.1rem, 1vw, 1.3rem)',
          }
        }
    });


      
    useEffect(() => {
        data.set(fetchResource())
    }, [])
    
      const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
      const checkedIcon = <CheckBoxIcon fontSize="small" />;
      

      function CheckboxesTags(props: any) {
        const { item, applyValue, options:tag_options } = props;
        const handleFilterChange = (event: any, value: any) => {
          applyValue({ ...item, value: value });
        };
      
        return (
          <Autocomplete
            multiple
            options={tag_options}
            disableCloseOnSelect
            getOptionLabel={(option: any) => option.title}
            defaultValue={item.value}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                  style={{ marginRight: 8 }}
                  // checked={selected_values.includes(option.title)}
                  checked={selected}
                />
                {option.title}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Tags" variant="standard" sx={{'& .MuiChip-root':{fontSize: 'clamp(1rem, 1.1vw, 1.2rem)'}}} />
            )}
            PopperComponent={StyledPopper}
            onChange={handleFilterChange}
          />
        );
      }
      
      const checkVisibility = (column: string)=>{
        const storageColumnsData = localStorage.getItem('Controle de Qualidade dos Anúncios') ? localStorage.getItem('Controle de Qualidade dos Anúncios') : null;
        if(storageColumnsData !== null){
          const localData = JSON.parse(storageColumnsData);
          const shouldHide = localData.find((item: any)=> item.field === column);
          return shouldHide !== undefined ? !shouldHide.visibility : false
        }
        return false
      }

    const columns = [
        { 
            headerName:  "SKU", 
            field: "sku", 
            type: 'string',
            headerAlign: 'center',
            hide: checkVisibility('sku'),
            flex: 0.5,
            minWidth: 100,
            renderCell: (rowData: any) => {
                return (
                    <span style={{fontSize: 'clamp(1.1rem,1vw,1.2rem)'}} title={rowData.row.sku}>{rowData.row.sku}</span>
                )
            }
        },
        { 
            headerName: "MLB", 
            field: "id", 
            type: 'string',
            headerAlign: 'center',
            hide: checkVisibility('id'),
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) => {
                return (
                    <ThemeProvider theme={defaultTheme}>
                        <div title={rowData.row.id}>
                            <Link 
                                href={rowData.row.link} 
                                target="_blank" rel="noreferrer" 
                                color={'inherit'} 
                                sx={{'&:hover':{color: defaultTheme.palette.primary.dark}}}
                                style={{fontSize: 'clamp(1.1rem,1vw,1.2rem)', display: 'flex', alignItems: 'center', gap: '5px', textDecoration: 'none'}} 
                            >
                                <div>{rowData.row.id}</div><FiExternalLink />
                            </Link>
                        </div>
                  </ThemeProvider>
                )
              }
        },
        {
            headerName: "Imagem",
            field: "photo",
            headerAlign: 'center',
            hide: checkVisibility('photo'),
            flex: 0.5,
            minWidth: 50,
            renderCell: (rowData: any) =>{
              return(
                <div className="img" style={{width: 30, height: 30, borderRadius: '50%', display: 'flex'}}>
                    <img alt="Imagem do produto" src={rowData.row.photo !== "" ? rowData.row.photo : placeholder} style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 'inherit'}}/>
                </div>
              )
            }
        },
        {
          headerName: "Produto",
          field: "title",
          type: "string",
          headerAlign: 'center',
          hide: checkVisibility('title'),
          flex: 2,
          minWidth: 200,
          renderCell: (rowData: any) => {
              const product_name = rowData.row.title ? rowData.row.title : '';
              const variation_name = rowData.row.variation_name ? rowData.row.variation_name : '';
              const full_name = `${product_name} - ${variation_name}`
            return (
                <Tippy content={<div>{full_name}</div>} maxWidth={'40rem'}>
                    <div style={{width: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: 'clamp(1.1rem,1vw,1.2rem)'}}>
                        {full_name}
                    </div>
                </Tippy>
            )
          }
        },
        {
            headerName:  "Tags",
            field: "new_tags",
            type: "string",
            headerAlign: 'center',
            hide: checkVisibility('new_tags'),
            flex: 1,
            minWidth: 100,
            sortable:false,
            filterOperators: [{
              value: 'contains',
              getApplyFilterFn: (filterItem: GridFilterItem) => {
                const selected_values = filterItem.value ? filterItem.value.map((item: any)=> item.title) : [];
                if (filterItem.value == null || selected_values.length === 0) {
                  return null;
                }
          
                return ({ value } : {value: any}): boolean => {
                  if (typeof value === 'object') {
                    return selected_values.every((item: any)=> value.includes(item))
                  }
                  return selected_values.every((item: any)=> value.includes(item))
                };
              },
              // InputComponent: GridFilterInputValue,
              InputComponent: CheckboxesTags,
              InputComponentProps: { type: 'singleSelect', options: [
                  {title: 'Envio Grátis'}, 
                  {title: 'Fulfillment'}, 
                  {title: 'Status: Ativo'}, 
                  {title: 'Status: Inativo'},
                  {title: 'Status: Pausado'},
                  {title: 'Status: Em Revisão'},
                  {title: 'Status: Fechado'},
                  {title: 'Promoção'},
                  {title: 'Anúncio Clássico'},
                  {title: 'Anúncio 12x'},
                  {title: 'Anúncio Saudável'},
                  {title: 'Anúncio Não Saudável'},
                ]},
            }],
            valueOptions: [
                "Envio Grátis", 
                "Fulfillment", 
                "Status: Ativo", 
                "Status: Inativo", 
                "Status: Pausado", 
                "Status: Em Revisão", 
                "Status: Fechado", 
                "Promoção",
                "Anúncio Clássico",
                "Anúncio 12x",
                "Anúncio Saudável",
                "Anúncio Não Saudável"
            ],
            renderCell: (rowData: any) => {
              const tagStatus:any = {"active": <FaCheckCircle className="icon-small"/>, "inactive": <FaTimesCircle className="icon-small"/>, 'paused': <FaPauseCircle className="icon-small"/>, "under_review": <BiRevision className="icon-small" />, "closed": <FaTimesCircle className="icon-small"/>}
              const tagStatusTranslations:any = {"active": 'Ativo', "inactive": 'Inativo', 'paused': 'Pausado', "under_review": "Em Revisão", "closed": "Fechado"}
              const statusColor:any = {"active": "#2477ad", "inactive": "#a5b1c2", 'paused': "#d65446", "under_review": "#000", "closed": "#c0392b"}
              const tagColors: any = {
                'tags': 
                [
                    {'title': 'Envio Grátis', 'value': <FaTruck className="icon-small" />, 'color': (rowData.row.free_shipping ? '#4b7bec':'#a5b1c2')},
                    {'title': 'Fulfillment', 'value': <GoZap className="icon-small"/>, 'color': (rowData.row.fulfillment ? '#20bf6b':'#a5b1c2')},
                    {'title': `Status: ${tagStatusTranslations[rowData.row.status]}`, 'value': tagStatus[rowData.row.status], 'color':  (rowData.row.status ? statusColor[rowData.row.status]:'#a5b1c2')},
                    {'title': `Promoção: ${rowData.row.original_price !== null ? (((rowData.row.sell_price - rowData.row.original_price)/rowData.row.original_price)*100).toFixed(2):0}%`, 'value': <BsFillTagFill className="icon-small"/>, 'color': (rowData.row.deal ? '#e056fd':'#a5b1c2')},
                    {'title': `Tipo de Listagem: ${rowData.row.listing_type === 'gold_special' ? 'Clássico':'12x'}`, 'value': <FaAward className="icon-small"/>, 'color': (rowData.row.listing_type === 'gold_pro' ? '#e1b12c' : '#a5b1c2')},
                    {'title': `Saúde do Anúncio ${(rowData.row.health*100)}%`, 'value': <FaHeart className="icon-small"/>, 'color': (rowData.row.health ? ((rowData.row.health*100) >= 70 ? '#e84118' : '#9b59b6') :'#a5b1c2')},
                ]
              };
              return (
                <div className="tags-container" style={{marginTop: 0, flexWrap: 'nowrap', display: 'flex', gap: '5px'}}>
                  {
                    Object.values(tagColors.tags).map((item: any, index: number)=>{
                      if(item.title === "Relação do Catálogo"){
                        if(rowData.row.catalog_relation){
                            return(
                                <Tippy key={index} content={rowData.row.catalog_relation.mlb_id} maxWidth={'25rem'}>
                                    <span style={{color: item.color}}>{item.value}</span>
                                </Tippy>
                            )
                        }else{
                            return(
                                <Tippy key={index} content={item.title} maxWidth={'25rem'}>
                                    <span style={{color: item.color}}>{item.value}</span>
                                </Tippy>
                            )
                        }
                      }else{
                        return (
                            <Tippy key={index} content={item.title} maxWidth={'25rem'}>
                                <span style={{color: item.color}}>{item.value}</span>
                            </Tippy>
                        )
                      }
                    })
                  }
        
                </div>
              )
            }
        },
        {
            headerName: "Total Vendas",
            field: "total_sold",
            type: "number",
            headerAlign: 'center',
            hide: checkVisibility('total_sold'),
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) => {
              return (
                  <span style={{fontSize: 'clamp(1.1rem,1vw,1.2rem)'}}>{rowData.row.total_sold}</span>
              )
            }
        },
        {
            headerName: "Preço Venda ML",
            field: "sell_price",
            type: "number",
            headerAlign: 'center',
            hide: checkVisibility('sell_price'),
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) => {
                const skuAny = rowData.row.sku;
                const mlbAny = rowData.row.id;
                const sku_mlb = `${skuAny}-${mlbAny}`;
                // let anyPrice = "";
                // if(anymarketPrices){
                //     if(anymarketPrices.attach(Downgraded).get().hasOwnProperty(sku_mlb)){
                //         anyPrice = anymarketPrices[sku_mlb].value
                //     }
                // }

              return (
                <>
                        <span 
                            title={`${rowData.row.sell_price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`}
                            style={{cursor: "pointer", fontSize: 'clamp(1.1rem,1vw,1.2rem)'}}
                            onClick={(evt:BaseSyntheticEvent)=> {
                                axs.get<IQualityControl>('/anymarket/sellprice', {params: {"sku": rowData.row.sku, "mlb_id": rowData.row.id}}).then((r: any) => {
                                    if(r.status === 200){
                                        const skuAny = r.data.sku;
                                        const mlbAny = r.data.mlb_id;
                                        const sku_mlb = `${skuAny}-${mlbAny}`;
                                        const anyPrice = r.data.sellPriceAnymarket ? r.data.sellPriceAnymarket : 0;
                    
                                        const prices = anymarketPrices.attach(Downgraded).get();
                                        prices[sku_mlb] = anyPrice.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) ;
                                        anymarketPrices.set(prices);
                                        // const aux_data = JSON.parse(JSON.stringify(data))
                                        // setData(aux_data)
                                    }else{
                                        toast.error("Ocorreu um erro ao fazer a solicitação.")
                                    }
                                })
                            }}
                        >
                            {`${rowData.row.sell_price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`}
                        </span>
                </>
              )
            }
        },
        {
            headerName: "Precificador",
            field: "sellPrice",
            headerAlign: 'center',
            type: 'number',
            hide: checkVisibility('sellPrice'),
            flex: 2,
            minWidth: 200,
            renderCell: (rowData: any) =>{
                return(
                    <div className="multi-btn">
                        <input 
                            type="number" 
                            name="change-price" 
                            id={`change-price-${rowData.row._id}`} 
                            className="multi-btn-input-small" 
                            defaultValue={(rowData.row.listing_type === "gold_special" ? rowData.row.sellPrice : rowData.row.sellPrice12x).toFixed(2)}
                            min={0} 
                            step={0.01}
                            onBlur={(evt: BaseSyntheticEvent)=> handleSetNewPrice(rowData.row, evt.target.value)}
                        />
                        <button className="btn-small" onClick={(evt: BaseSyntheticEvent)=> {
                            calculatedResult.set({
                                "sellPrice": 0,
                                "profit": 0,
                                "margin": 0,
                                "markup": 0
                            })
                            changeProductsData.set(rowData.row)
                            showDescModal.set(true);
                        }}>
                            <MdSwapVerticalCircle className="icon-small"/>Calcular
                        </button>

                        <button className="btn-small" onClick={(evt: BaseSyntheticEvent)=> {
                            if(rowData.row.deal){
                                showHideModal({show: true, title: "Esse anúncio possúi promoções ativas. Gostaria de alterará-lo no Mercado Livre mesmo assim?", execute: (confirm: boolean)=> handleChangePriceML(confirm, rowData.row)})
                            }else{
                                handleChangePriceML(true, rowData.row)
                            }
                        }}><FaEdit className="icon-small"/>Alterar ML</button>
                        <label className="inputTableLabelMoney" htmlFor={`change-price-${rowData.row._id}`} >R$</label>
                    </div>
                )
              }
        },
        {
            headerName: "Margem",
            field: "margin",
            type: "number",
            headerAlign: 'center',
            hide: checkVisibility('margin'),
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) => {
              return (
                <span style={{fontSize: 'clamp(1.1rem,1vw,1.2rem)'}} title={`${Math.round(rowData.row.margin*100)}%`}>{Math.round(rowData.row.margin*100)}%</span>
              )
            }
        },  
    ]
    
    
    
    const options = {
        palette:{
          tableHeader: {
            main: '#F7CE3E',
            dark: '#ac902b',
            light: '#f8d764',
            contrastText: '#000'
          },
        },
        alternate: true,
        selection: true,
      }


    const handleSetNewPrice = (row: IQualityControl, value: number)=>{
        const newPrices:any = newPrice;
        newPrices[row._id] = value;
        newPrice.set(newPrices)
    }

    const processData = (values: IQualityControl[])=>{
        const newPrices:any = newPrice.attach(Downgraded).get();
        const processedArray = values.map((item: IQualityControl)=>{
            let tags: string[] = [];
            if(item.free_shipping) tags.push('Envio Grátis');
            if(item.fulfillment) tags.push('Fulfillment');
            if(item.deal) tags.push('Promoção');
            if(parseFloat(item.health)*100 >= 70) tags.push('Anúncio Saudável');
            if(parseFloat(item.health)*100 < 70) tags.push('Anúncio Não Saudável');
            if(item.status === "active") tags.push('Status: Ativo');
            if(item.status === "inactive") tags.push('Status: Inativo');
            if(item.status === "paused") tags.push('Status: Pausado');
            if(item.status === "under_review") tags.push('Status: Em Revisão');
            if(item.status === "closed") tags.push('Status: Fechado');
            if(item.listing_type === 'gold_special') tags.push('Anúncio Clássico');
            if(item.listing_type === 'gold_pro') tags.push('Anúncio 12x');

            item['new_tags'] = tags;

            newPrices[item._id] = 0;
            
            return item
        })
        newPrice.set(newPrices)
        return processedArray
    }


    const handleSelection = (rows: IQualityControl[])=>{
        if(rows.length > 0){
            if(!showButtons.get()){
                showButtons.set(true);
            }
            const processedData = rows.map((item: IQualityControl)=>{
                return {
                    "sku": item.sku, 
                    "mlb_id": item.id, 
                    "idItem": item._id
                }
            })
            productsData.set(processedData);
        }else{
            showButtons.set(false);
        }  
    }


    const handleMultipleChange = (value: boolean) =>{
        if(value){
            const processedValues = productsData.attach(Downgraded).get().map((item: ProductsDataQualityControl)=>{
                if(newPrice.attach(Downgraded).get().hasOwnProperty(item.idItem)){
                    return {
                        "sku": item.sku, 
                        "mlb_id": item.mlb_id, 
                        "price": parseFloat(newPrice[item.idItem].value)
                    }
                }
                else {
                    return undefined
                }
            })

            const processedArrayFinal = processedValues.filter((item: any)=> item !== undefined)

            if(processedArrayFinal === undefined || processedArrayFinal.length <= 0){
                toast.info("Por favor, faça ao menos uma alteração no preço de algum produto e tente novamente.")
            }

            axs.post<IQualityControl>('/anymarket/sellprice/batch', {"products": processedArrayFinal}).then((r: any) => {
                if(r.status === 200){
                    multipleAnyData.set(r.data);
                    multipleChangeModal.set(true);
                    data.set(fetchResource())
                }else{
                    toast.error("Ocorreu um erro ao fazer a solicitação.")
                }
            }).catch((err: any)=>{
                handleValidateStatus(err.response)
            })
            showHideModal({show: false, title: ModalData?.title, execute: ModalData?.execute})
            showButtons.set(false);

        }else{
            showHideModal({show: false, title: ModalData?.title, execute: ModalData?.execute})
        }
    }

    const closeFunction = ()=>{
        showButtons.set(false);
        showDescModal.set(false);
    }

    const handleSetCalculatedChanges = () =>{
        const tableData = data.attach(Downgraded).get();
        const newData = tableData.map((item:IQualityControl)=>{
            if(item._id === changeProductsData._id.value){
                if(item.listing_type === "gold_special"){
                    item.sellPrice = calculatedResult?.sellPrice.value
                }else{
                    item.sellPrice12x = calculatedResult?.sellPrice.value
                }
                item.margin = calculatedResult?.margin.value
                // item.listing_type = calculatedResult.listing_type
            }
            return item
        })
        data.set(newData)
        showDescModal.set(false);
    }

    const handleCalculate = (evt: BaseSyntheticEvent) =>{
        evt.preventDefault();
        let sell_price:number | null = null,
        margin:number | null = null, 
        profit:number | null = null,
        listing_type = priceCalculate.listing_type.value;

        if(listing_type === undefined){
            listing_type = changeProductsData?.listing_type.value
        }

        if(priceCalculate.field_type.value === "sell_price"){
            sell_price = parseFloat(priceCalculate.field_value.value)
        }
        else if(priceCalculate.field_type.value === "margin"){
            margin = parseFloat(priceCalculate.field_value.value)/100
        }
        else{
            profit = parseFloat(priceCalculate.field_value.value)
        }

        const processedArray = {            
            "cost_product": changeProductsData?.cost_product.value,
            "cost_shippment": changeProductsData?.cost_shipping.value,
            "fee_marketplace": listing_type === "gold_special" ? changeProductsData?.feeMarketplace.value:changeProductsData?.feeMarketplace12x.value, // de acordo com o tipo do anúncio
            "tax": priceCalculate.tax_input.value ? parseFloat(priceCalculate.tax_input.value)/100 : changeProductsData?.tax.value,
            "sell_price": sell_price,
            "margin": margin,
            "profit": profit
        }
        axs.post<IQualityControl>('/others/precify', processedArray).then((r: any) => {
            calculatedResult.set(r.data)
        }).catch((err:any)=>{
            toast.error("Ocorreu um erro ao fazer a solicitação.")

        })
    }

    const handleChangePriceML = (confirm: boolean, values: IQualityControl) =>{
        let temp_value: number | undefined = parseFloat(newPrice[values._id].value);

        if(temp_value === undefined || temp_value === null || isNaN(temp_value)){
            temp_value = values.listing_type === "gold_special" ? values.sellPrice : values.sellPrice12x
        }

        if(confirm){
            const processedData = {
                "variation_id": values.variation_id,
                "mlb_id": values.id,
                "sell_price": temp_value
            }
            axs.post<IQualityControl>('/mlbs/sellprice/', processedData).then((r: any) => {
                if(r.status === 200){
                    const resData = r.data;
                    const aux_data = data.attach(Downgraded).get();
                    toast.success(`Preço do Anúncio (Mercado Livre): ${resData.mlb_id} alterado para: ${resData.sell_price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`);

                    aux_data.map((item: any)=>{
                        if(item.id === resData.mlb_id){
                            item.sell_price = resData.sell_price;
                        }
                        return false
                    })
                    data.set(aux_data)
                }else{
                    toast.error("Ocorreu um erro ao fazer a solicitação.")
                }
            }).catch((err: any)=>{
                handleValidateStatus(err.response)
            })
            showHideModal({show: false, title: ModalData?.title, execute: ModalData?.execute})
            showButtons.set(false);
            
        }else{
            showHideModal({show: false, title: ModalData?.title, execute: ModalData?.execute})
        }
    }

    const handlePopOverMenuModal = (value: IQualityControl)=>{
        if(blingProducts !== undefined){
            variationId.set(value.variation_id);
            mlbId.set(value.id);
            showModal.set(true)
        }else{
            toast.info("Por favor aguarde o carregamento dos dados do Bling!");
        }
    }

    const handleSubmitMLB = async(evt: BaseSyntheticEvent)=>{
        evt.preventDefault();
        const processedData = {
            "id_mlb": mlbId.get(),
            "variation_id": variationId.get(),
            "sku": sku.get(),
        }
        axs.post<IQualityControl>('/mlbs/infdata/sku/', processedData).then((r: any) => {
            if(r.status === 200){
                toast.success(`SKU dos Dados do  Anúncio: ${mlbId.get()} alterado para: ${r.data.sku}`);
            }else{
                toast.error("Ocorreu um erro ao fazer a solicitação.")
            }
        }).catch((err: any)=>{
            handleValidateStatus(err.response)
        })
        showModal.set(false);
    }

    const handleConfirmAndChange = ()=>{
        const message = changeProductsData.deal.value ? "Esse anúncio possúi promoções ativas. Gostaria de alterará-lo no AnyMarket mesmo assim?" : "Gostaria de alterar o item selecionado no AnyMarket?"
        handleSetCalculatedChanges();
        showHideModal({show: true, title: message, execute: (confirm: boolean)=> handleMultipleChange(confirm)})
    }

    if(data.promised){ //Enquanto for promisse ou estiver carregando
        return (
            <>
                <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}> 
                    <div className="content-wrapper">
                        <main className="main-content container-fluid">
                            <Loading />
                        </main>
                    </div>
                </div>
            </>
        )
    }

    if (data.error) { // Quando o promise dar reject
        return (<ErrorScreen />)
    }

    return(
        <>
            {
                multipleChangeModal.get() === true ?
                <BasicModal closeFunction={()=> multipleChangeModal.set(false)}>
                    <div className="data-container">
                        <section className="section-left">
                            <h3>Sucesso: {multipleAnyData?.resultProcess.success.qty.value}</h3>
                            {
                                multipleAnyData?.resultProcess.success.detail.attach(Downgraded).get().map((item: any, index: number)=>{
                                    return (
                                        <div key={index} className="section-container success">
                                            <div>
                                                <p>SKU: <strong>{item.sku}</strong></p>
                                                <p>Preço: <strong>{item.price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></p>
                                            </div>
                                            <p>{item.err}</p>
                                        </div>
                                    )
                                })
                            }
                        </section>
                        <section className="section-right">
                            <h3>Com Erro: {multipleAnyData?.resultProcess.fail.qty.value}</h3>
                            {
                                multipleAnyData?.resultProcess.fail.detail.attach(Downgraded).get().map((item: any, index: number)=>{
                                    return (
                                        <div key={index} className="section-container error">
                                            <div>
                                                <p>SKU: <strong>{item.sku}</strong></p>
                                                <p>MLB: <strong>{item.mlb_id}</strong></p>
                                            </div>
                                            <p>{item.err}</p>
                                        </div>
                                    )
                                })
                            }
                        </section>
                    </div>
                </BasicModal>
                :
                null
            }

            {
                showModal.get() === true ?
                <BasicModal closeFunction={()=> showModal.set(false)}>
                    <BasicForm SubmitFunction={(evt: BaseSyntheticEvent)=> handleSubmitMLB(evt)}>
                        <InputMainContainer>
                            <InputMainContainer>
                                <div className="input-container">
                                    <Autocomplete
                                        className="skus-select"
                                        options={blingProducts.attach(Downgraded).get()}
                                        disableClearable
                                        getOptionLabel={(option: any) => option.sku}
                                        renderOption={(props, option) => (
                                            <li {...props}>
                                                {option.label}
                                            </li>
                                            )}
                                        renderInput={(params) => <TextField {...params} placeholder="SKU" />}
                                        PopperComponent={(props: any)=> <StyledPopper {...props} style={{ width: "fit-content", padding: "1rem", display: "flex", flexDirection: "column", gap: '1rem' }} placement='bottom-start' />}
                                        onChange={(evt: BaseSyntheticEvent, value: any)=> {
                                            sku.set(value.sku)
                                        }}
                                    />
                                </div>
                            </InputMainContainer>
                        </InputMainContainer>

                        <InputMainContainer>
                            <button type="submit">Alterar dados do Anúncio</button>
                        </InputMainContainer>
                    </BasicForm>
                </BasicModal>
                :
                null
            }

            {
                showDescModal.get() === true ?
                <BasicModal closeFunction={closeFunction}>
                    <form className="add-description-form-adsQuality" onSubmit={(evt: BaseSyntheticEvent)=> handleCalculate(evt)}>
                        <div className="input-container">
                            <div style={{padding: '1rem', overflowY:"scroll", maxHeight: '45rem'}}>
                                <InputMainContainer>
                                    <SelectFilterContainer
                                        label="Tipo do Anúncio"
                                        id="adType"
                                        class="filterSelect"
                                        name="adType"
                                        required={true}
                                        disabled={true}
                                        value={changeProductsData.listing_type.value}
                                        options={
                                            {'data': [{'value': '', 'text': ''}, {'value': 'gold_special', 'text': 'Clássico'}, {'value': 'gold_pro', 'text': '12x'}],}
                                        }
                                        onChange={(value: any)=> priceCalculate.merge({"adType": value["adType"]})}
                                    />
                                </InputMainContainer>

                                <InputMainContainer>
                                    <InputFilterContainer 
                                        type="number"
                                        label="Imposto (%)"
                                        class="filterInput"
                                        id="tax_input"
                                        name="tax_input"
                                        required={true}
                                        value={(changeProductsData.tax.value * 100).toFixed(2)}
                                        onChange={(value: any)=> priceCalculate.merge({"tax_input": value["tax_input"]})}
                                    />
                                </InputMainContainer>

                                <InputMainContainer>
                                    <SelectFilterContainer 
                                        label="Tipo do Campo"
                                        class="filterSelect"
                                        id="field_type"
                                        name="field_type"
                                        required={true}
                                        options={
                                            {'data': [{'value': '', 'text': ''}, {'value': 'sell_price', 'text': 'Preço de Venda'}, {'value': 'margin', 'text': 'Margem'}, {'value': 'profit', 'text': 'Lucro'}],}
                                        }
                                        onChange={(value: any)=> priceCalculate.merge({"field_type": value["field_type"]})}
                                    />
                                </InputMainContainer>

                                <InputMainContainer>
                                    <InputFilterContainer 
                                        type="number"
                                        label="Valor do Campo"
                                        class="filterInput"
                                        id="field_value"
                                        name="field_value"
                                        required={true}
                                        onBlur={(value: any)=> priceCalculate.merge({"field_value": value["field_value"]})}
                                    />
                                </InputMainContainer>
                                {
                                    calculatedResult.attach(Downgraded).get() !== undefined ?
                                    <div className="popup-result">
                                        <h3>Resultado: </h3>
                                        <hr/>
                                        <strong>
                                            Preço de Venda: {calculatedResult?.sellPrice.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                                        </strong>
                                        <strong>
                                            Margem: <strong style={{color: (calculatedResult?.margin.value !== undefined ? calculatedResult.margin.value : 0) * 100 >= 0 ? "#219953" : "#C0392B"}}>{((calculatedResult?.margin.value !== undefined ? calculatedResult.margin.value : 0) * 100).toFixed(2)}%</strong>
                                        </strong>
                                        <strong>
                                            Lucro: <strong style={{color: (calculatedResult?.profit.value !== undefined ? calculatedResult.profit.value : 0) >= 0 ? "#219953" : "#C0392B"}}>{calculatedResult?.profit.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong>
                                        </strong>
                                    </div>
                                    :
                                    null
                                }
                                {/* botão criado apenas para desabilitar o envio do form com a tecla enter */}
                                <button type="submit" disabled style={{display: 'none'}} aria-hidden="true"></button>
                            </div>
                        </div>

                        <div className="input-container" style={{padding: '0 1rem'}}>
                            <button className="btn-cancel" onClick={(evt: BaseSyntheticEvent)=> closeFunction()}>Cancelar</button>
                            <button type="submit" className="btn-calculate">Calcular</button>
                            <button disabled={calculatedResult.attach(Downgraded).get() !== undefined ? false:true} className="btn-confirm" onClick={handleSetCalculatedChanges}>Confirmar</button>
                            <button disabled={calculatedResult.attach(Downgraded).get() !== undefined ? false:true} onClick={handleConfirmAndChange}>Confirmar e Alterar</button>
                        </div>

    
                    </form>
                </BasicModal>
                :
                null
            }


            {
                // SelectedData && SelectedData.length > 0 ?
                showButtons.get() ?
                <button 
                    className="floating_edit" 
                    onClick={(event: any) => showHideModal({show: true, title: "Gostaria de alterar os itens selecionados no Anymarket?", execute: (confirm: boolean)=> handleMultipleChange(confirm)})}
                >
                    <MdEdit className="icon" />
                </button>
                :
                null
            } 

            <ModalConfirm />
            <BugReportModal />
            <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
                <div className="content-wrapper">
                    <main className="main-content container-fluid">
                        {
                            data.length > 0 ?
                            <>
                                <VirtualizedGrid 
                                    rows={data.attach(Downgraded).get()}
                                    columns={columns}
                                    options={options}
                                    title="Precificação dos Anúncios"
                                    onSelection={(rows: any)=>  handleSelection(rows)}
                                    actions={[
                                        {icon: 'settings', title: 'Mais Ações', method: (event: any, row:any)=> handlePopOverMenuModal(row)}, 
                                    ]}
                                    defaultSort={{field: 'total_sold', direction: 'desc'}}
                                />
                            </>
                            :
                            <div className="img-container">
                                <img src={noData} alt="Não foi possível renderizar o gráfico por falta de dados." />
                                <h4>Não foi possível renderizar os gráficos por falta de dados.</h4>
                            </div>
                        }
                    </main>

                </div>
            </div>
        </>
    )
}