import { BrowserRouter as Route, useParams } from 'react-router-dom';
import noData from '../../../assets/images/nodata.svg';

import { LineChart } from '../../../components/Charts/LineChart';
import { MultipleDataCard } from '../../../components/cards/MultipleDataCard';
import { ImageCard } from '../../../components/cards/ImageCard';

import {FaTruck, FaAward, FaBookOpen, FaCheckCircle, FaTimesCircle, FaPauseCircle, FaHeart} from 'react-icons/fa';
import {FiAlertTriangle} from 'react-icons/fi';
import {GoZap} from 'react-icons/go';
import {BiLink, BiRevision} from 'react-icons/bi'
import {BsFillTagFill} from 'react-icons/bs';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import { useDarkMode } from '../../../hooks/useDarkMode';

import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';

import './style.scss';
import { IKPIBySKU, MLBSKpiBySku } from "../../../types/Types";
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import { Loading } from '../../../components/Loading/Loading';
import { BugReportModal } from '../../../components/modal/BugReportModal';

export const SKUViewer = (props:any) =>{
    document.title = "Visualização de Dados SKU - MeLiBeat";
    const handleValidateStatus = useHandleErrors();

    let { group_sku, id } = useParams<any>();

    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const url = group_sku === 'true' ? '/kpi/group/'+id : '/kpi/sku/'+id;
    const fetchResource = (query:any=undefined) => axs.get<IKPIBySKU>(url, {params: query}).then((r: any) => {
        handleValidateStatus(r);
        return  processData(r.data[0])
    }).catch((err: any)=>{
        handleValidateStatus(err.response);
        return {
            "_id": '',
            "mlbs": [],
            "photo": '',
            "links": [],
            "cods": [],
            "project_sells": 0,
            "total_orders": 0,
            "margin_avg": 0,
            "profit_avg": 0,
            "project_profit": 0,
            "title": '',
            "origin": '',
            "cost_sku": '',
            "cost_stock": '',
            "stockAll": '',
            "stockFull": '',
            "stockETP": '',
            "stockAllDetail": {
                "PR": undefined,
                "SC": undefined,
                "SP": undefined
            },
            "stockAllDuration": 0,
            "stockFullDuration": 0,
            "brand": '',
            "tags": {},
            "chart_sells": {
              "chart_label": [],
              "chart_value": [],
              "chart_valuePrevious": []
            },
            "group_skus": false,
            "skus": '',
            "type": '',
        }
    })

    const data: State<IKPIBySKU> = useState<IKPIBySKU>({
        "_id": '',
        "mlbs": [],
        "photo": '',
        "links": [],
        "cods": [],
        "project_sells": 0,
        "total_orders": 0,
        "margin_avg": 0,
        "profit_avg": 0,
        "project_profit": 0,
        "title": '',
        "origin": '',
        "cost_sku": '',
        "cost_stock": '',
        "stockAll": '',
        "stockFull": '',
        "stockETP": '',
        "stockAllDetail": {
            "PR": undefined,
            "SC": undefined,
            "SP": undefined
        },
        "stockAllDuration": 0,
        "stockFullDuration": 0,
        "brand": '',
        "tags": {},
        "chart_sells": {
          "chart_label": [],
          "chart_value": [],
          "chart_valuePrevious": []
        },
        "group_skus": false,
        "skus": '',
        "type": '',
    });
    // const [loading, setLoading] = useState<boolean>(false);
    const chartLabel: State<string[][]> = useState<string[][]>([]);
    const chartDataset: State<object> = useState<object>({});
    const tagStatusTranslations:object = {"active": 'Ativo', "inactive": 'Inativo', 'paused': 'Pausado', "under_review": "Em Revisão", "closed": "Fechado"};
    const tagStatus:any = {"active": <FaCheckCircle className="icon"/>, "inactive": <FaTimesCircle className="icon"/>, 'paused': <FaPauseCircle className="icon"/>, "under_review": <BiRevision className="icon" />}
    let ChartColors: string[] = ["#0abde3", "#b71540", "#1dd1a1", "#1e3799", "#f368e0", "#0c2461", "#e55039", "#222f3e"];
    let ChartColorsAlpha: string[] = ["#0abde30f", "#b715400f", "#1dd1a10f", "#1e37990f", "#f368e00f", "#0c24610f", "#e550390f", "#222f3e0f"];
    const {DarkMode, } = useDarkMode();

    useEffect(() => {
        data.set(fetchResource())
    }, [])

    const stockColors = (filter: string, value: number)=>{
        let backgroundColor: string = "";
        if(filter === 'stockAllDuration'){
            const stockAll = value;
            if(stockAll <= 7) backgroundColor = "#ff8b79";
            if(stockAll > 7 && stockAll <= 15) backgroundColor = "#ffce92";
            if(stockAll > 15 && stockAll <= 20) backgroundColor = "#c7cf52";
            if(stockAll > 20 && stockAll <= 30) backgroundColor = "#93d461";
            if(stockAll > 30) backgroundColor = "#5ec96a";
          
            return backgroundColor
          }
    }


    const processData = (data_to_process: IKPIBySKU)=>{
        const processedMLBS = data_to_process.mlbs.filter((mlb: MLBSKpiBySku)=>{
            if(!mlb.actual && mlb.orders_sold.total_sold <= 0){
                return false
            }else{
                // console.log(`Actual: ${mlb.actual}\nVendas período: ${mlb.orders_sold.total_sold}`)
                return true
            }
        })
        data_to_process.mlbs = processedMLBS
        return data_to_process
    }

    const handleChart = () =>{
        let dataset: object[] = [];
        let labels: string[][] = [[...data.chart_sells.chart_label.attach(Downgraded).get()]];
        dataset.push({
            label: 'Vendas nos últimos 30 Dias',
            data: data.chart_sells.chart_value.attach(Downgraded).get(),
            tension: 0.1,
            borderColor: ChartColors[0],
            backgroundColor: ChartColors[0],
            fill: {
                target: 'origin',
                above: ChartColorsAlpha[0],   // Area will be red above the origin
                below: 'transparent'    // And blue below the origin
            }
        })
        dataset.push({
            label: 'Vendas no Período Anterior',
            data: data.chart_sells.chart_valuePrevious.attach(Downgraded).get(),
            tension: 0.1,
            borderColor: ChartColors[1],
            backgroundColor: ChartColors[1],
            fill: {
                target: 'origin',
                above: ChartColorsAlpha[1],   // Area will be red above the origin
                below: 'transparent'    // And blue below the origin
            }
        })

        chartLabel.set(labels);
        chartDataset.set(dataset);
    }

    if(data.promised){ //Enquanto for promisse ou estiver carregando
        return (
            <>
                <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}> 
                    <div className="content-wrapper">
                        <main className="main-content container-fluid">
                            <Loading />
                        </main>
                    </div>
                </div>
            </>
        )
    }

    if (data.error) { // Quando o promise dar reject
        return (<ErrorScreen />)
    }
    data.batch((data) => {
        if (data.promised) return
        if (data.error) return
        else{
            handleChart()
        }
    })

    return(
        <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
            <BugReportModal />
            <div className="content-wrapper">
                <main className="main-content container-fluid">
                    {
                        data.get() ? 
                        <div className={`screen-header-container ${DarkMode ? 'dark-mode-screen-header-container' : ''}`}>
                            <h4 style={{display:'flex', alignItems:'center', gap: '1rem'}}>
                                <b>{group_sku === 'true' ? 'Grupo:':'SKU:'}</b>
                                {
                                    group_sku === 'true' ? 
                                    <Tippy content={<div style={{display:'flex', alignItems:'center', gap: '1rem'}}>{data.skus.value.toString().replaceAll(',', ', ')}</div>} interactive={true} maxWidth={'20rem'}><div>{`${data._id.value} - `}</div></Tippy>
                                    :
                                    <div>{data._id.value} - </div>
                                }
                            </h4>
                            <h4>{data.title.value} - </h4>
                            <h4><b>Origem:</b> {data.origin.value}</h4>
                            {
                                group_sku === 'true' ?
                                <h4> - <b>Tipo:</b> {data.type.value}</h4>
                                :
                                null
                            }
                        </div>
                        :
                        null
                    }
                    {
                        chartLabel.attach(Downgraded).get() === undefined || chartLabel.length === 0 
                        ? 
                        <div className="img-container">
                            <img src={noData} alt="Não foi possível renderizar o gráfico por falta de dados." />
                            <h4>Não foi possível renderizar os gráficos por falta de dados.</h4>
                        </div>
                        
                        : <LineChart labels={chartLabel.attach(Downgraded).get()} dataset={chartDataset.attach(Downgraded).get()} />
                    }

                    {
                        data.get() ? 
                        <div className="card-container-wrapper">
                            <div className="screen-cards-container">
                                <MultipleDataCard 
                                    titles={['Estoque Geral', 'Estoque Full', 'Estoque ETP']} 
                                    content={[
                                        <Tippy content={Object.entries(data.stockAllDetail.attach(Downgraded).get()).map((item: string[], index: number)=>{
                                            return(
                                                <div key={index}>{`${item[0]}: ${item[1]}`}</div>
                                            )
                                        })}>
                                        <div style={{width: 'fit-content'}}>{data.stockAll.value}</div>
                                        </Tippy>
                                        , data.stockFull.value, data.stockETP.value]}
                                />
                                <MultipleDataCard 
                                    titles={['Duração Estoque', 'Duração Estoque Full', 'Duração Estoque Total']} 
                                    content={[parseInt(String(data.stockAllDuration.value)), parseInt(String(data.stockFullDuration.value)), ( parseInt(String(data.stockFullDuration.value)) + parseInt(String(data.stockAllDuration.value)))]}
                                    colors={[stockColors('stockAllDuration', data.stockAllDuration.value), stockColors('stockAllDuration', data.stockFullDuration.value), stockColors('stockAllDuration', (parseInt(String(data.stockFullDuration.value)) + parseInt(String(data.stockAllDuration.value))))]}
                                />
                                <MultipleDataCard 
                                    titles={['Margem Média', 'Lucro Médio', 'Total de Pedidos']} 
                                    content={[`${(data.margin_avg.value * 100).toFixed(2)}%`, `${(data.profit_avg.value !== null ? data.profit_avg.value : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`, parseInt(String(data.total_orders.value))]}
                                    colors={[data.margin_avg.value * 100 >= 0 ? 'inherit':'#C0392B', data.profit_avg.value >= 0 ? 'inherit':'#C0392B', 'inherit']}
                                />
                                <MultipleDataCard 
                                    titles={['Lucro Estimado', 'Vendas Estimadas', 'Vendas Estimadas Mensais']} 
                                    content={[
                                        <Tippy content={`Lucro Estimado Mensal: ${(data.project_profit.value*30).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`}>
                                            <div style={{width: 'fit-content'}}>{`${(data.project_profit.value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`}</div>
                                        </Tippy>
                                        , parseInt(String(data.project_sells.value)), parseInt(String(data.project_sells.value))*30]}
                                    colors={[data.project_profit.value >= 0 ? 'inherit':'#C0392B', 'inherit', 'inherit']}
                                />
                                <MultipleDataCard 
                                    titles={['Todos Pausados', 'Custo Médio', 'Custo Total Estoque']} 
                                    content={[data.tags.all_paused.value ? 'Sim': 'Não', ((data.cost_sku.value !== null && data.cost_sku.value !== undefined) ? data.cost_sku.value : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}), ((data.cost_stock.value !== null && data.cost_stock.value !== undefined) ? data.cost_stock.value : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})]}
                                    colors={['inherit', 'inherit','inherit']}
                                />
                            </div>
                            <div style={{display: 'flex', alignItems:'center', justifyContent:'center', width:'100%'}}>
                                <hr style={{width: '100%', height: '1'}}/> <h3 style={{margin: '3rem 1rem'}}>Anúncios</h3> <hr style={{width: '100%', height: '1'}}/>
                            </div>
                            <div className="screen-cards-container">
                                {
                                    data.mlbs.length > 0 ?
                                    data.mlbs.attach(Downgraded).get().map((item: MLBSKpiBySku, index: number)=>{
                                        return(
                                            <ImageCard
                                                image={item.photo}
                                                title={{'value': <Tippy interactive content={<div>{`SKU: ${item.sku}`}</div>} maxWidth={'25rem'}><div>{item.id}</div></Tippy>, 'isLink': true, 'link': item.link, 'inventory_id': item.inventory_id}}
                                                key={index}
                                                actual={item.actual}
                                                style={{opacity: item.actual ? "1":"0.6"}}
                                                content={[
                                                    [
                                                        {'title': 'Produto','value': item.title}, 
                                                        {'title': 'Variação', 'value': item.variation_name},
                                                        {'tags': 
                                                            [
                                                                {'title': 'Envio Grátis', 'value': <FaTruck className="icon" />, 'color': (item.free_shipping ? '#4b7bec':'#a5b1c2')},
                                                                {'title': 'Fulfillment', 'value': <GoZap className="icon"/>, 'color': (item.fulfillment ? '#20bf6b':'#a5b1c2')},
                                                                {'title': item.catalog_product ? item.catalog_product:'Catálogo', 'value': <FaBookOpen className="icon"/>, 'color': (item.catalog ? '#4b7bec':'#a5b1c2')},
                                                                {'title': 'Relação do Catálogo', 'value': <BiLink className="icon"/>, 'color': (item.catalog_relation ? '#8854d0':'#a5b1c2')},
                                                                {'title': `Status: ${tagStatusTranslations[item.status as keyof object]}`, 'value': tagStatus[item.status], 'color':  (item.status ? '#4b7bec':'#a5b1c2')},
                                                                {'title': `Promoção: ${item.original_price !== null ? (((item.sell_price - item.original_price)/item.original_price)*100).toFixed(2):0}%`, 'value': <BsFillTagFill className="icon"/>, 'color': (item.deal ? '#e056fd':'#a5b1c2')},
                                                                {'title': `Tipo de Listagem: ${item.listing_type === 'gold_special' ? 'Clássico':'12x'}`, 'value': <FaAward className="icon"/>, 'color': (item.listing_type === 'gold_pro' ? '#e1b12c' : '#a5b1c2')},
                                                                {'title': `Saúde do Anúncio ${(item.health*100)}%`, 'value': <FaHeart className="icon"/>, 'color': (item.health ? (item.health*100) <= 70 ? '#9b59b6' : '#e84118' :'#a5b1c2')},
                                                            ]
                                                        },
                                                    ],
                                                    [
                                                        {'title': 'Quantidade Disponível', 'value': Math.round(item.available_qty)}, 
                                                        {'title': 'Quantidade Pendente', 'value': Math.round(item.pending_qty), 'color': item.pending_qty > 0 ? '#C0392B':null},
                                                        {'title': 'Total Vendido', 'value': Math.round(item.total_sold)},
                                                    ],
                                                    [
                                                        {'title': 'Vendas no Período', 'value': Math.round(item.orders_sold.total_sold)},
                                                        {'title': 'Margem Média', 'value': `${(item.orders_sold.margin_avg*100).toFixed(2)}%`, 'color': item.orders_sold.margin_avg*100 >= 0 ? 'inherit':'#C0392B'},
                                                        {'title': 'Lucro Médio', 'value': `${(item.orders_sold.profit_avg !== null ? item.orders_sold.profit_avg : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`, 'color': item.orders_sold.profit_avg >= 0 ? 'inherit':'#C0392B'},
                                                    ],
                                                    [
                                                        {'title': 'Preço de Venda', 'value': `${(item.sell_price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`},
                                                        {'title': 'Margem Atual', 'value': `${(item.margin*100).toFixed(2)}%`, 'color': item.margin*100 >= 0 ? 'inherit':'#C0392B'},
                                                        {'title': 'Lucro Atual', 'value': `${(item.profit).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`, 'color': item.profit >= 0 ? 'inherit':'#C0392B'},
                                                    ]
                                                ]}
                                            />
                                        )
                                    })
                                    :
                                    <div style={{width: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '1rem', margin: '2rem 0'}}>
                                        <FiAlertTriangle className="icon" style={{color: '#8a271c', width: '3rem', height: '3rem'}} />
                                        <h3>Atualmente sem anúncios vinculados ao SKU</h3>
                                    </div>
                                }
                            </div>
                        </div>
                        :
                        null
                    }
                </main>
            </div>
        </div>
    )
}