import { columns } from '../../../components/table/files/KPIData';
import { toast } from 'react-toastify';

import noData from '../../../assets/images/nodata.svg';

import { Accordion } from '../../../components/accordion/Accordion';

import {FiZapOff, FiTrendingDown, FiPauseCircle, FiAlertCircle} from 'react-icons/fi'
import { useDarkMode } from '../../../hooks/useDarkMode';
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';

import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';

import { IKPI, ColorCaptionKPI, FilterTimeAdsMetrics, MinMaxDaysAdsMetrics } from "../../../types/Types";
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import DatePicker, {Locale, Day} from 'react-modern-calendar-datepicker';
import moment from 'moment';
import { Filters } from '../../../components/Filters/Filters';
import { Loading } from '../../../components/Loading/Loading';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { BugReportModal } from '../../../components/modal/BugReportModal';

const INPUT_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.6rem)';

const captionsColors: ColorCaptionKPI = {
    margin_avg: {
        'Margem Negativa (Margem menor que 0)': "#ffa89a",
        'Margem Ruim (Margem maior que 0 e menor que 5)': "#ffdbaf",
        'Margem Media (Margem maior que 5 e menor que 10)': "#faffb1",
        'Margem Boa (Margem maior que 10 e menor que 20)': "#d4ffb2",
        'Margem Alta (Margem maior que 20)': "#b2ffbb",
    },
    
    stockAllDuration: {
        'Estoque Negativo (Estoque menor ou igual a 7)': "#ffa89a",
        'Estoque Ruim (Estoque entre 7 e 15)': "#ffdbaf",
        'Estoque Medio (Estoque entre 15 e 20)': "#faffb1",
        'Estoque Bom (Estoque entre 20 e 30)': "#d4ffb2",
        'Estoque Alto (Estoque maior que 30)': "#b2ffbb"
    },
    stockFullDuration: {
        'Estoque Negativo (Estoque menor ou igual a 7)': "#ffa89a",
        'Estoque Ruim (Estoque entre 7 e 15)': "#ffdbaf",
        'Estoque Medio (Estoque entre 15 e 20)': "#faffb1",
        'Estoque Bom (Estoque entre 20 e 30)': "#d4ffb2",
        'Estoque Alto (Estoque maior que 30)': "#b2ffbb"
    },
}


// const options = {
//     palette:{
//       tableHeader: {
//         main: '#F7CE3E',
//         dark: '#ac902b',
//         light: '#f8d764',
//         contrastText: '#000'
//       },
//     },
//     rowStyle: (rowData: any) => {
//       const margin = rowData.profit_margin*100;
//       const status = rowData.status;
    
//       let cost_product = rowData.cost_product;
//       cost_product = cost_product.toFixed(2).replace('.', ',');
//       let selled_price = rowData.selled_price;
//       selled_price = selled_price.toFixed(2).replace('.', ',');
//       let shipping_fee = rowData.shipping_fee;
//       shipping_fee = shipping_fee.toFixed(2).replace('.', ',');
//       const pack_id = rowData.pack_id;  
//       let row_class = '';
//       if(margin < 0) row_class = "lcreColors-negative-margin";
//       if(margin >= 0 && margin < 5) row_class = "lcreColors-bad-margin";
//       if(margin >= 5 && margin < 10) row_class = "lcreColors-margin-average";
//       if(margin > 10 && margin < 20) row_class = "lcreColors-good-margin";
//       if(margin >= 20) row_class = "lcreColors-high-margin";
//       if(status === "cancelled") row_class = "lcreColors-cancelled-sale";
    
//       if(selled_price < 79 && shipping_fee > 0 && pack_id == null) row_class = "lcreColors-wrong-shipping-tax";
//       if(cost_product === 0 || margin > 100) row_class = "lcreColors-cost-margin";
    
//       return row_class
//     },
// }

export const KPITable = (props:any) =>{
    document.title = "KPI de Vendas - MeLiBeat";
    const handleValidateStatus = useHandleErrors();

    const processData = (data: IKPI[])=>{
        const processedArray = data.map((item: IKPI)=>{
            item["tags_obj"] = {}
            item["tags_filter"] = [];
            if(item.group_skus === true){
                item['group_id'] = item._id;
                item._id = item?.skus?.toString().replaceAll(',', ', ');
            }

            if(item.tags.includes("without_bling")){
                item["tags_obj"]["without_bling"] = true
                item.tags_filter.push("Sem Bling")

            }else{
                item["tags_obj"]["without_bling"] = false
                item.tags.push("with_bling")
                item.tags_filter.push("Com Bling")

            }

            if(item.tags.includes("only_fulfilment")){
                item["tags_obj"]["only_fulfilment"] = true
                item.tags_filter.push("Fulfillment (Sim)")

            }else{
                item["tags_obj"]["only_fulfilment"] = false
                item.tags.push("fulfillment_no")
                item.tags_filter.push("Fulfillment (Não)")
            }

            if(item.tags.includes("without_history")){
                item["tags_obj"]["without_history"] = true
                item.tags_filter.push("Sem Histórico")
            }else{
                item["tags_obj"]["without_history"] = false
                item.tags.push("with_history")
                item.tags_filter.push("Com Histórico")
            }

            if(item.tags.includes("all_paused")){
                item["tags_obj"]["all_paused"] = true
                item.tags_filter.push("MLBs Pausados")

            }else{
                item["tags_obj"]["all_paused"] = false
                item.tags.push("all_paused_no")
                item.tags_filter.push("MLBs Não Pausados")
            }
            
            if(item.tags.length === 0){
                item["tags_obj"] = {
                    "without_bling": false,
                    "only_fulfilment": false,
                    "without_history": false,
                    "all_paused": false
                }
                item.tags = ["with_bling", "fulfillment_no", "with_history", "all_paused_no"]
                item["tags_filter"] = ["Com Bling", "Fulfillment (Não)", "Com Histórico", "MLBs Não Pausados"];
            }

            return item
        })
        return processedArray
    }

    //Componentes e estados referentes ao filtro de datas
    const Footer = ()=> {
        return(
            <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem', padding: "1rem 0" }}>
                <button
                    type="button"
                    onClick={() => {
                    const to = {
                        year: moment().year(),
                        month: moment().month() + 1,
                        day: moment().date() - 1,
                    }
                    const from = {
                        year:  moment().subtract(90, 'days').year(),
                        month:  moment().subtract(90, 'days').month() + 1,
                        day: moment().subtract(90, 'days').date() - 1,
                    }

                    startingFilterValues.set({"from": from, "to": to})
                    if(disableFilterBtn.get() === true){
                        disableFilterBtn.set(false)
                    }
                    }}
                    style={{
                        border: '#0fbcf9',
                        color: '#fff',
                        borderRadius: '0.5rem',
                        padding: '1rem 1rem',
                        backgroundColor: "#3498DB"
                    }}
                >
                    90 dias
                </button>
                    
                <button
                    type="button"
                    onClick={() => {
                    const to = {
                        year: moment().year(),
                        month: moment().month() + 1,
                        day: moment().date() - 1,
                    }
                    const from = {
                        year:  moment().subtract(60, 'days').year(),
                        month:  moment().subtract(60, 'days').month() + 1,
                        day: moment().subtract(60, 'days').date() - 1,
                    }

                    startingFilterValues.set({"from": from, "to": to})
                        if(disableFilterBtn.get() === true){
                        disableFilterBtn.set(false)
                        }
                        // data.set(fetchResource({"dateFrom": from, "dateTo": to}))

                    }}
                    style={{
                        border: '#0fbcf9',
                        color: '#fff',
                        borderRadius: '0.5rem',
                        padding: '1rem 1rem',
                        backgroundColor: "#3498DB"
                    }}
                >
                    60 dias
                </button>
            
                <button
                    type="button"
                    onClick={() => {
                    const to = {
                        year: moment().year(),
                        month: moment().month() + 1,
                        day: moment().date() - 1,
                    }
                    const from = {
                        year:  moment().subtract(30, 'days').year(),
                        month:  moment().subtract(30, 'days').month() + 1,
                        day: moment().subtract(30, 'days').date() - 1,
                    }

                    startingFilterValues.set({"from": from, "to": to})
                    if(disableFilterBtn.get() === true){
                        disableFilterBtn.set(false)
                    }

                    }}
                    style={{
                        border: '#0fbcf9',
                        color: '#fff',
                        borderRadius: '0.5rem',
                        padding: '1rem 1rem',
                        backgroundColor: "#3498DB"
                    }}
                >
                    30 dias
                </button>
            </div>
        )
    } 
    const startingFilterValues: State<FilterTimeAdsMetrics> = useState<FilterTimeAdsMetrics>({
    "to":{
        year: moment().year(),
        month: moment().month() + 1,
        day: moment().date() - 1,
    },
    "from":{
        year:  moment().subtract(30, 'days').year(),
        month:  moment().subtract(30, 'days').month() + 1,
        day: moment().subtract(30, 'days').date() - 1,
    }
    })
    const blackFridayFilter: any = useState<any>(undefined)
    const basedProjectionFilter: State<string | undefined> = useState<string | undefined>('activeDays')
    const metrics: any = useState<any>(undefined)

    const myCustomLocale: Locale = {
        // months list by order
        months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
        ],
    
        // week days by order
        weekDays: [
        {
            name: 'Domingo', // used for accessibility 
            short: 'D', // displayed at the top of days' rows
            isWeekend: true, // is it a formal weekend or not?
        },
        {
            name: 'Segunda-Feira',
            short: 'S',
        },
        {
            name: 'Terça-Feira',
            short: 'T',
        },
        {
            name: 'Quarta-Feira',
            short: 'Q',
        },
        {
            name: 'Quinta-Feira',
            short: 'Q',
        },
        {
            name: 'Sexta-Feira',
            short: 'S',
        },
        {
            name: 'Sábado',
            short: 'S',
            isWeekend: true,
        },
        ],
    
        // just play around with this number between 0 and 6
        weekStartingIndex: 0,
    
        // return a { year: number, month: number, day: number } object
        getToday(gregorainTodayObject: any) {
        return gregorainTodayObject;
        },
    
        // return a native JavaScript date here
        toNativeDate(date: any) {
        return new Date(date.year, date.month - 1, date.day);
        },
    
        // return a number for date's month length
        getMonthLength(date:any) {
        return new Date(date.year, date.month, 0).getDate();
        },
    
        // return a transformed digit to your locale
        transformDigit(digit:any) {
        return digit;
        },
    
        // texts in the date picker
        nextMonth: 'Próximo Mês',
        previousMonth: 'Mês Anterior',
        openMonthSelector: 'Abrir Seletor de Meses',
        openYearSelector: 'Abrir Seletor de Anos',
        closeMonthSelector: 'Fechar Seletor de Meses',
        closeYearSelector: 'Fechar Seletor deAnos',
        defaultPlaceholder: 'Selecionar Período...',
    
        // for input range value
        from: '',
        to: '---',
    
    
        // used for input value when multi dates are selected
        digitSeparator: ',',
    
        // if your provide -2 for example, year will be 2 digited
        yearLetterSkip: 0,
    
        // is your language rtl or ltr?
        isRtl: false,
    }

    const minMaxDays: State<MinMaxDaysAdsMetrics> = useState<MinMaxDaysAdsMetrics>({
        maximumDate: {
            year: moment().year(),
            month: moment().month() + 1,
            day: moment().date() - 1,
        },
        minimumDate: {
            year: moment().year() - 1,
            month: moment().month() + 1,
            day: moment().date() - 1,
        }
    })

    //

    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const fetchResource = (query:any=undefined) => axs.get<IKPI[]>('/kpi/', {params: query}).then((r: any) => {
        handleValidateStatus(r)
        return processData(r.data)
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    });

    const data: State<IKPI[]> = useState<IKPI[]>([]);
    const colorFilter: State<string> = useState<string>('margin_avg');
    const {DarkMode, } = useDarkMode();
    const captionsData: object = {
        colors: captionsColors ? captionsColors[colorFilter.get() as keyof ColorCaptionKPI] : captionsColors['margin_avg'],
        tags: {
            "Apenas Fulfilment": ["#706fd3", (<FiZapOff />)],
            "Sem Histórico vendas": ["#227093", (<FiTrendingDown />)],
            "Todos MLBs Pausados": ["#b33939", (<FiPauseCircle />)],
            "SKU Sem Cadastro Bling": ["#218c74", (<FiAlertCircle />)]
        }
    }
    const disableFilterBtn: State<boolean> = useState<boolean>(false);



    const options = {
        palette:{
          tableHeader: {
            main: '#F7CE3E',
            dark: '#ac902b',
            light: '#f8d764',
            contrastText: '#000'
          },
        },
        rowStyle: (rowData: any) => {
          let row_class = '';
          if(colorFilter.get() === 'margin_avg'){
            const margin = rowData.margin_avg*100;
            if(margin < 0) row_class = 'lcreColors-negative-margin';
            if(margin >= 0 && margin < 5) row_class = 'lcreColors-bad-margin';
            if(margin >= 5 && margin < 10) row_class = 'lcreColors-margin-average';
            if(margin > 10 && margin < 20) row_class = 'lcreColors-good-margin';
            if(margin >= 20) row_class = 'lcreColors-high-margin';
        }
        else if(colorFilter.get() === 'stockAllDuration'){
            const stockAll = rowData.stockAllDuration;
            if(stockAll <= 7) row_class = 'lcreColors-negative-margin';
            if(stockAll >= 7 && stockAll < 15) row_class = 'lcreColors-bad-margin';
            if(stockAll >= 15 && stockAll < 20) row_class = 'lcreColors-margin-average';
            if(stockAll >= 20 && stockAll < 30) row_class = 'lcreColors-good-margin';
            if(stockAll >= 30) row_class = 'lcreColors-high-margin';

        }
        else if(colorFilter.get() === 'stockFullDuration'){
            const stockFull = rowData.stockFullDuration;
            if(stockFull <= 7) row_class = 'lcreColors-negative-margin';
            if(stockFull >= 7 && stockFull < 15) row_class = 'lcreColors-bad-margin';
            if(stockFull >= 15 && stockFull < 20) row_class = 'lcreColors-margin-average';
            if(stockFull >= 20 && stockFull < 30) row_class = 'lcreColors-good-margin';
            if(stockFull >= 30) row_class = 'lcreColors-high-margin';
        }else{
            row_class = ''
        }
          return row_class
        },
    }

    useEffect(() => {
        const dates = convertObjectToString(startingFilterValues.get())
        data.set(fetchResource({"dateFrom": dates.stringDateFrom, "dateTo": dates.stringDateTo, 'blackFridayFilter': false, 'projectType': 'activeDays'}))
    }, [])

    const convertObjectToString = (value: any)=>{
        const stringDateFrom: string = moment(value.from).format("YYYY-MM-DD");
        const stringDateTo: string = moment(value.to).format("YYYY-MM-DD");
        return {stringDateFrom, stringDateTo}
    }

    const handleFullReset = ()=>{
        toast.info("Resetando os dados sem o cache!");
        colorFilter.set('margin_avg');
        startingFilterValues.set({
            "to":{
                year: moment().year(),
                month: moment().month(),
                day: moment().date() - 1,
            },
            "from":{
                year: moment().subtract(30, 'days').year(),
                month: moment().subtract(30, 'days').month(),
                day: moment().subtract(30, 'days').date() - 1,
            }
        })
    
        blackFridayFilter.set(undefined)
        basedProjectionFilter.set('activeDays')
        metrics.set(undefined)
        const dates = convertObjectToString(startingFilterValues.get())
    
        data.set(fetchResource({"dateFrom": dates.stringDateFrom, "dateTo": dates.stringDateTo, 'blackFridayFilter': false, 'withoutCache': true, 'projectType': 'activeDays'}))
    }

    const handleFilter = (filters: any)=>{
        toast.info("Filtrando dados!");
        colorFilter.set(filters.filterMetric);
        console.log(colorFilter.get())
        const dateFrom: Day = filters.filterDate.trim().split('---')[0];
        const dateTo: Day = filters.filterDate.trim().split('---')[1];
    
        const stringDateFrom: string = moment(dateFrom).format("YYYY-MM-DD");
        const stringDateTo: string = moment(dateTo).format("YYYY-MM-DD");
    
        data.set(fetchResource({"dateFrom": stringDateFrom, "dateTo": stringDateTo, 'blackFridayFilter': filters.filterBlackFriday ? filters.filterBlackFriday === "1" ? true : false : false, 'projectType': filters.filterBasedProjection}))
    }

    const handleReset = ()=> {
        colorFilter.set('margin_avg')
        startingFilterValues.set({
            "to":{
                year: moment().year(),
                month: moment().month(),
                day: moment().date() - 1,
            },
            "from":{
                year: moment().subtract(30, 'days').year(),
                month: moment().subtract(30, 'days').month(),
                day: moment().subtract(30, 'days').date() - 1,
            }
          })
          blackFridayFilter.set(undefined)
          basedProjectionFilter.set('activeDays')
          metrics.set(undefined)
          const dates = convertObjectToString(startingFilterValues.get())
      
          data.set(fetchResource({"dateFrom": dates.stringDateFrom, "dateTo": dates.stringDateTo, 'blackFridayFilter': false, 'projectType': 'activeDays'}))
    }


    return(
        <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
            <BugReportModal />
            <div className="content-wrapper">
                <main className="main-content container-fluid">
                    <Filters submitFunction={handleFilter} disableFilterBtn={disableFilterBtn.get()} handleReset={handleReset} handleFullReset={handleFullReset}>
                        <DatePicker 
                        value={startingFilterValues.attach(Downgraded).get()} 
                        inputPlaceholder="Selecionar Período: de.../até..." 
                        shouldHighlightWeekends
                        locale={myCustomLocale}
                        calendarClassName={`date-picker-filter`}
                        inputClassName="datePickerFilterInput"
                        colorPrimary="#15171c"
                        colorPrimaryLight="#F7CE3E"
                        minimumDate={minMaxDays.minimumDate.value}
                        maximumDate={minMaxDays.maximumDate.value}
                        renderFooter = {Footer}
                        inputName="filterDate"
                        onChange={(range: any)=> {
                            startingFilterValues.set({"from": range.from, "to": range.to})
                            if(range.from !== null && range.to !== null){
                                if(moment(range.to).diff(moment(range.from), 'days') < 30){
                                    toast.info('O período deve ser de no mínimo 30 dias.');
                                    disableFilterBtn.set(true)
                                    return
                                }else{
                                    disableFilterBtn.set(false)
                                }
                            }
                        }} 
                        />
                        <FormControl size='small' variant='outlined' sx={{minWidth: '20rem', width: '100%'}}>
                            <InputLabel id="origin-label" style={{fontSize: INPUT_FONT_SIZE}}>Métrica</InputLabel>
                            <Select
                                required
                                labelId="origin-label"
                                id="origin"
                                value={metrics.get()}
                                label="Métrica"
                                name='filterMetric'
                                onChange={(evt: SelectChangeEvent)=>{
                                    metrics.set(evt.target.value)
                                }}
                                style={{fontSize: INPUT_FONT_SIZE}}
                            >
                            <MenuItem value={'margin_avg'}>Margem</MenuItem>
                            <MenuItem value={'stockAllDuration'}>Duração Estoque</MenuItem>
                            <MenuItem value={'stockFullDuration'}>Duração do Estoque Full</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl size='small' variant='outlined' sx={{minWidth: '28rem'}} key={blackFridayFilter.get()}>
                            <InputLabel id="black-friday-label-filter" style={{fontSize: INPUT_FONT_SIZE}}>Remover Vendas Black Friday</InputLabel>
                            <Select
                                labelId="black-friday-label-filter"
                                id="black-friday-filter"
                                name='filterBlackFriday'
                                value={blackFridayFilter.attach(Downgraded).get()}
                                label="Remover Vendas Black Friday"
                                style={{fontSize: INPUT_FONT_SIZE}}
                                onChange={(evt: SelectChangeEvent)=> blackFridayFilter.set(evt.target.value)}
                            >
                            <MenuItem value={''}></MenuItem>
                            <MenuItem value={'1'}>Sim</MenuItem>
                            <MenuItem value={'0'}>Não</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl size='small' variant='outlined' sx={{minWidth: '25rem'}} key={basedProjectionFilter.get()}>
                            <InputLabel id="projecao-baseada-label-filter" style={{fontSize: INPUT_FONT_SIZE}}>Projeção Baseada</InputLabel>
                            <Select
                                labelId="projecao-baseada-label-filter"
                                id="projecao-baseada-filter"
                                name='filterBasedProjection'
                                value={basedProjectionFilter.attach(Downgraded).get()}
                                label="Projeção Baseada"
                                style={{fontSize: INPUT_FONT_SIZE}}
                                onChange={(evt: SelectChangeEvent)=> basedProjectionFilter.set(evt.target.value)}
                            >
                            <MenuItem value={'activeDays'}>Dias Ativos</MenuItem>
                            <MenuItem value={'historySells'}>Histórico de Vendas</MenuItem>
                            </Select>
                        </FormControl>
                    </ Filters>
                    <Accordion title="Legendas KPI de Vendas" data={captionsData} />
                    {
                        data.promised ? 
                        <Loading />
                        : data.error ?
                        <ErrorScreen /> : 
                        data.length > 0 ?
                        <VirtualizedGrid 
                            rows={data.attach(Downgraded).get()}
                            columns={columns}
                            options={options}
                            title="Análise de Vendas"
                            defaultSort={{field: 'total_orders', direction: 'desc'}}
                        />
                        :
                        <div className="img-container">
                            <img src={noData} alt="Não foi possível renderizar o gráfico por falta de dados." />
                            <h4>Não foi possível renderizar os gráficos por falta de dados.</h4>
                        </div>
                    }
                </main>

            </div>
        </div>
    )
}