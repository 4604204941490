import './style.scss';
import { MdClose } from 'react-icons/md';

export const PopOverMenuModal = (props:any) =>{
    return (
        <div className="popOverMenuModal" style={{top: props.position.y, left: props.position.x}}>
            {
                props.closeFunction ?
                <div className="close-container" style={{alignSelf: 'center'}}>
                    <button onClick={props.closeFunction}><MdClose /></button>
                </div>
                :
                null
            }
            {props.children}
        </div>
    )
}