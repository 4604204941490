import { createContext, useState, ReactNode } from 'react';
import axios from 'axios';

type User = {
    token: string;
}

type AuthContextType = {
    user: User | undefined;
    signIn: (username: string, password: string) => Promise<void>;
}

type AuthContextProps = {
    children: ReactNode
}

export const AuthContext = createContext({} as AuthContextType);

export function AuthContextProvider(props: AuthContextProps) {
    const [user, setUser] = useState<User>();

    //Verificação se token ainda é válido ou algo do genero
    /*useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                const { displayName, photoURL, uid } = user
                if (!displayName || !photoURL) {
                    throw new Error('Missing information from Google Account.')
                }

                setUser({
                    id: uid,
                    name: displayName,
                    avatar: photoURL
                })
            }
        })

        return () => {
            unsubscribe()
        }
    }, [])*/

    async function signIn(username: string,password: string) {
        try{
            const result = await axios.post(process.env.REACT_APP_API+'/auth/login', {
                username: username,
                password: password
            })

            if (result.status === 200) {
                const { token } = result.data
                if (!token) {
                    throw new Error('Missing information from Requisition.')
                }
     
                setUser({
                    token: token
                })     
            }else{
                if(process.env.REACT_APP_AMBIENT === 'dev'){
                    console.log(result)
                }
            }
        }catch(e){
            throw new Error('Error in function signIn')
        }
    }
    return (
        <AuthContext.Provider value={{ user, signIn }}>
            {props.children}
        </AuthContext.Provider>
    )
}