
import { Header } from "../../components/Header/Header";
import { SideMenu } from "../../components/sideMenu/SideMenu";
import { BrowserRouter as Route } from 'react-router-dom';
import { useState } from "react";
// import { useRequest } from "../../hooks/useRequest";
import { Responsive, WidthProvider } from 'react-grid-layout';
import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';
import { MdClose } from 'react-icons/md';
import { FaPlus } from 'react-icons/fa';
import { InputMainContainer, SelectFilterContainer } from '../../components/Form/BasicForm';
import { toast } from 'react-toastify';
import moment from 'moment';
import axios from 'axios';
import { Card } from '../../components/cards/Card';
import { useHandleErrors } from '../../hooks/useHandleErrors';


import './style.scss';

const ResponsiveGridLayout = WidthProvider(Responsive);

const options = [
    {'value': '', 'text': ''}, 
    {'value': 'lcre total_shipping_fee', 'text': 'Card LCRE (Custo Frete)'},
    {'value': 'lcre total_cost_tax', 'text': 'Card LCRE (Custo Impostos)'},
    {'value': 'lcre total_cost_product', 'text': 'Card LCRE (Custo Produtos)'},
    {'value': 'lcre total_sale_fee', 'text': 'Card LCRE (Custo Taxas ML)'},
    {'value': 'lcre total_sales', 'text': 'Card LCRE (Total Vendas)'},
    {'value': 'lcre total_sales_cancelled', 'text': 'Card LCRE (Vendas Canceladas)'},
    {'value': 'lcre total_gross_profit', 'text': 'Card LCRE (Lucro Bruto)'},
    {'value': 'lcre total_profit', 'text': 'Card LCRE (Lucro Liquido)'},
    {'value': 'lcre total_profit_margin', 'text': 'Card LCRE (Margem de Lucro)'},
    {'value': 'lcre total_orders', 'text': 'Card LCRE (Total Pedidos)'},
    
]

export const DashBoard = () =>{
    document.title = "Dashboard - MeliBeat";
    const handleValidateStatus = useHandleErrors();

    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const lsLayout = localStorage.getItem("layouts") ? JSON.parse(localStorage.getItem("layouts") || "{}") : undefined;
    // const lsGridItems = localStorage.getItem("gridItems") ? JSON.parse(localStorage.getItem("gridItems") || "{}") : undefined;
    const [layouts, ] = useState<any>( lsLayout !== undefined ? lsLayout :
        {
            "lg": [
                {i: 'a', x: 0, y: 0, w: 1, h: 2},
                {i: 'b', x: 1, y: 0, w: 3, h: 2},
                {i: 'c', x: 4, y: 0, w: 1, h: 2},
                {i: 'd', x: 5, y: 5, w: 5, h: 2, maxW: 7}
            ],
            "md": [
                {i: 'a', x: 0, y: 0, w: 1, h: 2},
                {i: 'b', x: 1, y: 0, w: 3, h: 2},
                {i: 'c', x: 4, y: 0, w: 1, h: 2},
                {i: 'd', x: 5, y: 5, w: 5, h: 2, maxW: 7}
            ],
            "sm": [
                {i: 'a', x: 0, y: 0, w: 1, h: 2},
                {i: 'b', x: 1, y: 0, w: 3, h: 2},
                {i: 'c', x: 4, y: 0, w: 1, h: 2},
                {i: 'd', x: 5, y: 5, w: 5, h: 2, maxW: 7}
            ],
        }
    )

    
    const [gridItems, setGridItems] = useState<any>([])

    const [lastResult, setLastResult] = useState<any>({'origin': '', 'data': []})
    
    
    const createElement = (el:any)=> {
        const renderItem = el.i;
        const itemKey = el.key;
        return (
            <div className="grid-item-container" style={{width: 'auto'}} key={itemKey}>
                <div className="close-container" style={{alignSelf: 'center'}}>
                    <button onClick={(evt: any ) => removeItem(itemKey)}><MdClose className="btn-icon" /></button>
                </div>
                <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent:'center'}}>
                    {renderItem}
                </div>
            </div>
        );
      }

    const removeItem = (key:any) => {
        const newItems = gridItems.filter((item: any)=> item['key'] !== key)
        setGridItems(newItems)
    }

    const onLayoutChange = (layout:any, newLayouts:any)=> {
        localStorage.setItem("layouts", JSON.stringify(newLayouts));
        localStorage.setItem("gridItems", JSON.stringify(layout));
    }

    const handleLCRECards = async (cardTitle: string)=>{
        const LCRETranslations: any = {
            'total_shipping_fee': 'Custo Frete', 
            'total_cost_tax': 'Custo Impostos',
            'total_cost_product': 'Custo Produtos', 
            'total_sale_fee': 'Custo Taxas ML',
            'total_sales': 'Total Vendas', 
            'total_sales_cancelled': 'Vendas Canceladas',
            'total_gross_profit': 'Lucro Bruto',
            'total_profit': 'Lucro Liquido',
            'total_profit_margin': 'Margem de Lucro',
            'total_orders': 'Total Pedidos'
        }
        const title = LCRETranslations[cardTitle];
        const data = {
            'date_start': moment().startOf('month').subtract(1, 'month').format('YYYY-MM-DD'),
            'date_end': moment().endOf('month').subtract(1, 'month').format('YYYY-MM-DD'),
            'origin': 'all'
        }
        let result: object = {}
        let previousResult: object = {}
        if(lastResult.origin === 'lcre'){
            previousResult = lastResult.data[0].previous_result
            result = lastResult.data[0].result;
        }else{
            await axs.get<any>('/orders/lcre/', {params: data}).then((r: any) => {
               previousResult = r.data.previous_result
               result = r.data.result;
               setLastResult({'origin': 'lcre', 'data': [r.data]})
           }).catch((err: any)=>{
                handleValidateStatus(err.response)
            })
        }
        const percent = cardTitle === 'total_profit_margin' ?  true : false;
        return (
            <Card 
                title={title} 
                content={result[cardTitle as keyof object]} 
                lastMonth={previousResult[cardTitle as keyof object]} 
                integer={true} 
                percent={percent} 
                prefix={percent ? "":"R$"}
                decimals={2}
                increment={true} 
            />
        )
    }

    const handleFilter = async (filters: any)=>{
        const allItems = [...gridItems];
        for(let item of allItems){
            if(item.n === filters.filterSelect){
                toast.error('Não é possível adicionar o mesmo componente duas vezes!');
                return
            }
        }
        toast.info('Gerando item...')
        let value: any = undefined;
        if(filters.filterSelect.includes('lcre')){
            const filterValue = filters.filterSelect.replace('lcre ', '')
            value = await handleLCRECards(filterValue)
        }else{
            value = filters.filterSelect;
        }
        allItems.push({
            key:filters.filterSelect,
            i: value,
            x: Infinity,
            y: Infinity,
            minW: 50,
            w: 50,
            h: 5,

        })
        setGridItems(allItems);
    }
        

    return(
        <div className="wrapper">
            <Header submitFunction={handleFilter}>
                <InputMainContainer>
                    <SelectFilterContainer 
                        label="Componentes"
                        class="filterSelect"
                        id="filterSelect" 
                        name="filterSelect" 
                        required={true}
                        containerClass="input-container-inline bigger-select-input"
                        options={
                            {'data': options,}
                        }
                    />
                </InputMainContainer>
                <button type="submit" className="filter-btn">Adicionar <FaPlus /></button>
            </ Header>

            <div className="content-wrapper">
                <Route>
                    <SideMenu />
                </Route>
                <main className="main-content container-fluid">
                    <ResponsiveGridLayout className="layout" layouts={layouts}
                        breakpoints={{lg: 1200, md: 996, sm: 768}}
                        cols={{lg: 20, md: 12, sm: 4}}
                        draggableHandle=".close-container"
                        onLayoutChange={(layout: any, layouts: any) => onLayoutChange(layout, layouts)}
                        verticalCompact={false}
                        >
                        {
                            gridItems.map((el:any)=> createElement(el))

                        }
                    </ResponsiveGridLayout>
                </main>
            </div>
        </div>
    )
}