import { FiTrendingDown, FiStar } from 'react-icons/fi'

export const CaptionsCpr = {
    title: 'Legendas CPR - Análise de Compras',
    data: {
        colors: {
            'Estoque Negativo (Estoque menor ou igual a 7)': "#ffa89a",
            'Estoque Ruim (Estoque entre 7 e 15)': "#ffdbaf",
            'Estoque Medio (Estoque entre 15 e 20)': "#faffb1",
            'Estoque Bom (Estoque entre 20 e 30)': "#d4ffb2",
            'Estoque Alto (Estoque maior que 30)': "#b2ffbb"
        },
        tags: {
            "SKU Novo": ["#f7b731", (<FiStar />)],
            "Sem Histórico vendas": ["#227093", (<FiTrendingDown />)],
        }
    }
}
