import { toast } from 'react-toastify';


import './style.scss'
import moment from 'moment';
import { useDarkMode } from '../../../hooks/useDarkMode';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';

import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import DatePicker, {Locale, Day} from 'react-modern-calendar-datepicker';
import { BugReportModal } from '../../../components/modal/BugReportModal';

import { 
    FilterTimeAdsMetrics, 
    MinMaxDaysAdsMetrics, 
} from "../../../types/Types";


const FONT_SIZE = "clamp(1.2rem, 1.2vw, 1.6rem)";
const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
        },
    },
};

const BaseInput = styled(TextField)({
    width: '100%',
    '& .MuiInput-input':{
      fontSize: FONT_SIZE,
      minWidth: '300px',
      flex: 1,
    },
    '& label':{
      fontSize: FONT_SIZE,
    }
});

export const DebtsToPay = (props:any) =>{
    document.title = "Contas a Pagar - MeLiBeat";

    const myCustomLocale: Locale = {
        // months list by order
        months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
        ],
      
        // week days by order
        weekDays: [
          {
            name: 'Domingo', // used for accessibility 
            short: 'D', // displayed at the top of days' rows
            isWeekend: true, // is it a formal weekend or not?
          },
          {
            name: 'Segunda-Feira',
            short: 'S',
          },
          {
            name: 'Terça-Feira',
            short: 'T',
          },
          {
            name: 'Quarta-Feira',
            short: 'Q',
          },
          {
            name: 'Quinta-Feira',
            short: 'Q',
          },
          {
            name: 'Sexta-Feira',
            short: 'S',
          },
          {
            name: 'Sábado',
            short: 'S',
            isWeekend: true,
          },
        ],
      
        // just play around with this number between 0 and 6
        weekStartingIndex: 0,
      
        // return a { year: number, month: number, day: number } object
        getToday(gregorainTodayObject: any) {
          return gregorainTodayObject;
        },
      
        // return a native JavaScript date here
        toNativeDate(date: any) {
          return new Date(date.year, date.month - 1, date.day);
        },
      
        // return a number for date's month length
        getMonthLength(date:any) {
          return new Date(date.year, date.month, 0).getDate();
        },
      
        // return a transformed digit to your locale
        transformDigit(digit:any) {
          return digit;
        },
      
        // texts in the date picker
        nextMonth: 'Próximo Mês',
        previousMonth: 'Mês Anterior',
        openMonthSelector: 'Abrir Seletor de Meses',
        openYearSelector: 'Abrir Seletor de Anos',
        closeMonthSelector: 'Fechar Seletor de Meses',
        closeYearSelector: 'Fechar Seletor deAnos',
        defaultPlaceholder: 'Selecionar Período...',
      
        // for input range value
        from: '',
        to: '---',
      
      
        // used for input value when multi dates are selected
        digitSeparator: ',',
      
        // if your provide -2 for example, year will be 2 digited
        yearLetterSkip: 0,
      
        // is your language rtl or ltr?
        isRtl: false,
    }
    const minMaxDays: State<MinMaxDaysAdsMetrics> = useState<MinMaxDaysAdsMetrics>({
        maximumDate: {
            year: moment().year(),
            month: moment().month() + 1,
            day: moment().date() - 1,
        },
        minimumDate: {
            year: moment().year() - 1,
            month: moment().month() + 1,
            day: moment().date() - 1,
        }
    })
    const startingFilterValues: State<FilterTimeAdsMetrics> = useState<FilterTimeAdsMetrics>({
        "to":{
            year: moment().year(),
            month: moment().month(),
            day: moment().date() - 1,
        },
        "from":{
            year: moment().subtract(30, 'days').year(),
            month: moment().subtract(30, 'days').month(),
            day: moment().subtract(30, 'days').date() - 1,
        }
    })
    const token: string | undefined = localStorage.getItem('token') || undefined;
    // const axs = axios.create({
    //     baseURL: `${process.env.REACT_APP_API}`,
    //     headers: { 'Authorization': `Bearer ${token}` }
    // })

    // const fetchResource = (query:any=undefined) => axs.get<any>('/orders/analysisabc/', {params: query}).then((r: any) => {
    //     return r.data;
    // })
    const data: State<any> = useState<any>([]);
    const {DarkMode, } = useDarkMode();


    if(data.promised){
        return (
            <>
                <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}> 
                    <div className="content-wrapper">
                        <main className="main-content container-fluid">
                            <div className="loading">
                                <AiOutlineLoading3Quarters className="loading-icon" />
                            </div>
                        </main>
                    </div>
                </div>
            </>
        )
    }

    if (data.error) { // Quando o promise dar reject
        return (
            <ErrorScreen />
        )
    }


    return(
        <>
            <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
                <BugReportModal />
                <div className="content-wrapper">
                    <main className="main-content container-fluid">
                        <Paper style={{width: '100%', display: 'flex', flexDirection: 'column', gap: '2rem', padding: '2rem'}}>
                            <h3 style={{margin: '0'}}>Filtros</h3>
                            <Box style={{width: '100%', display: 'flex', flexDirection: 'row', gap: '2rem'}}>
                                <Paper style={{width: 'fit-content', padding: '2rem', display: 'flex', flexDirection: 'column', gap: '2rem'}}>
                                    <Box style={{display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
                                        <h4 style={{margin: '0'}}>Data Vencimento</h4>
                                        <DatePicker 
                                            wrapperClassName="DatePicker-1"
                                            value={startingFilterValues.attach(Downgraded).get()} 
                                            inputPlaceholder="Selecionar Período: de.../até..." 
                                            shouldHighlightWeekends
                                            locale={myCustomLocale}
                                            calendarClassName={`date-picker-filter`}
                                            inputClassName="datePickerFilterInputDebtsToPay"
                                            colorPrimary="#15171c"
                                            colorPrimaryLight="#F7CE3E"
                                            minimumDate={minMaxDays.minimumDate.value}
                                            maximumDate={minMaxDays.maximumDate.value}
                                            inputName="filterDate"
                                            onChange={(range: any)=> {
                                                startingFilterValues.set({"from": range.from, "to": range.to})
                                                if(range.from !== null && range.to !== null){
                                                    if(moment(range.to).diff(moment(range.from), 'days') > 30){
                                                        toast.info('O período deve se manter no limite de 30 dias.');
                                                        return
                                                    }else{
                                                    }
                                                }
                                            }} 
                                        />
                                    </Box>
                                    <Box style={{display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
                                        <h4 style={{margin: '0'}}>Data Emissão</h4>
                                        <DatePicker 
                                            wrapperClassName="DatePicker-2"
                                            value={startingFilterValues.attach(Downgraded).get()} 
                                            inputPlaceholder="Selecionar Período: de.../até..." 
                                            shouldHighlightWeekends
                                            locale={myCustomLocale}
                                            inputClassName="datePickerFilterInputDebtsToPay"
                                            colorPrimary="#15171c"
                                            colorPrimaryLight="#F7CE3E"
                                            minimumDate={minMaxDays.minimumDate.value}
                                            maximumDate={minMaxDays.maximumDate.value}
                                            onChange={(range: any)=> {
                                                startingFilterValues.set({"from": range.from, "to": range.to})
                                                if(range.from !== null && range.to !== null){
                                                    if(moment(range.to).diff(moment(range.from), 'days') > 30){
                                                        toast.info('O período deve se manter no limite de 30 dias.');
                                                        return
                                                    }else{
                                                    }
                                                }
                                            }} 
                                        />
                                    </Box>
                                    <Box style={{display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
                                        <h4 style={{margin: '0'}}>Data Pagamento</h4>
                                        <DatePicker 
                                            wrapperClassName="DatePicker-3"
                                            value={startingFilterValues.attach(Downgraded).get()} 
                                            inputPlaceholder="Selecionar Período: de.../até..." 
                                            shouldHighlightWeekends
                                            locale={myCustomLocale}
                                            calendarClassName={`date-picker-filter`}
                                            inputClassName="datePickerFilterInputDebtsToPay"
                                            colorPrimary="#15171c"
                                            colorPrimaryLight="#F7CE3E"
                                            minimumDate={minMaxDays.minimumDate.value}
                                            maximumDate={minMaxDays.maximumDate.value}
                                            inputName="filterDate"
                                            onChange={(range: any)=> {
                                                startingFilterValues.set({"from": range.from, "to": range.to})
                                                if(range.from !== null && range.to !== null){
                                                    if(moment(range.to).diff(moment(range.from), 'days') > 30){
                                                        toast.info('O período deve se manter no limite de 30 dias.');
                                                        return
                                                    }else{
                                                    }
                                                }
                                            }} 
                                        />
                                    </Box>
                                </Paper>

                                <Paper style={{width: 'fit-content', padding: '2rem', display: 'flex', flexDirection: 'column', gap: '2rem'}}>
                                    <FormControl sx={{ flex: 1 }}>
                                        <InputLabel id="payment-status-label" style={{fontSize: FONT_SIZE}}>Status Pagamento</InputLabel>
                                        <Select
                                            // error={inputsError.suppliers.value}
                                            labelId="payment-status-label"
                                            id="payment-status"
                                            // value={selectedSupplier}
                                            // onChange={handleChange}
                                            input={<OutlinedInput label="Status Pagamento" />}
                                            MenuProps={MenuProps}
                                            style={{fontSize: FONT_SIZE, width: '20rem'}}
                                            >
                                            {
                                                [{id: 1, name: 'Pago'}, {id: 2, name: 'Aberto'}, {id: 3, name: 'Atrasado (Não existe)'}].map((item) => (
                                                    <MenuItem
                                                    key={item.id}
                                                    value={item.id}
                                                    >
                                                        {item.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ flex: 1 }}>
                                        <InputLabel id="cost-centers-label" style={{fontSize: FONT_SIZE}}>Centros de Custo</InputLabel>
                                        <Select
                                            // error={inputsError.suppliers.value}
                                            labelId="cost-centers-label"
                                            id="cost-centers"
                                            value={[]}
                                            // onChange={handleChange}
                                            multiple
                                            input={<OutlinedInput label="Centros de Custo" />}
                                            MenuProps={MenuProps}
                                            style={{fontSize: FONT_SIZE, width: '20rem'}}
                                        >
                                            {
                                                [{id: 1, name: 'Centro 1'}, {id: 2, name: 'Centro 2'}, {id: 3, name: 'Centro 3'}].map((item) => (
                                                    <MenuItem
                                                    key={item.id}
                                                    value={item.id}
                                                    >
                                                        {item.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Paper>
                            </Box>
                            <Divider />
                            <Box style={{width: '100%', display: 'flex', flexDirection: 'row', gap: '2rem'}}>
                                <Paper style={{width: '100%', display: 'flex', flex: '1', flexDirection: 'column', gap: '2rem', padding: '2rem'}}>
                                    <h3 style={{margin: '0'}}>Formato de Exibição</h3>
                                    <Box style={{width: '100%', display: 'flex', flexDirection: 'row', gap: '2rem'}}>
                                        <Paper style={{width: 'fit-content', padding: '2rem', display: 'flex',  flex: '1', flexDirection: 'column', gap: '2rem'}}>
                                            <FormControl sx={{ flex: 1 }}>
                                                <InputLabel id="payment-status-label" style={{fontSize: FONT_SIZE}}>Tipo do Gráfico</InputLabel>
                                                <Select
                                                    // error={inputsError.suppliers.value}
                                                    labelId="payment-status-label"
                                                    id="payment-status"
                                                    // value={selectedSupplier}
                                                    // onChange={handleChange}
                                                    input={<OutlinedInput label="Tipo do Gráfico" />}
                                                    MenuProps={MenuProps}
                                                    style={{fontSize: FONT_SIZE, width: '100%'}}
                                                    >
                                                    {
                                                        [{id: 1, name: 'Pizza'}, {id: 2, name: 'Linha'}, {id: 3, name: 'Barra'}].map((item) => (
                                                            <MenuItem
                                                            key={item.id}
                                                            value={item.id}
                                                            >
                                                                {item.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Paper>
                                        <Paper style={{width: 'fit-content', padding: '2rem', display: 'flex',  flex: '1', flexDirection: 'column', gap: '2rem'}}>
                                            <FormControl sx={{ flex: 1 }}>
                                                <InputLabel id="group-by-label" style={{fontSize: FONT_SIZE}}>Agrupamento por:</InputLabel>
                                                <Select
                                                    // error={inputsError.suppliers.value}
                                                    labelId="group-by-label"
                                                    id="group-by"
                                                    // value={selectedSupplier}
                                                    // onChange={handleChange}
                                                    input={<OutlinedInput label="Agrupamento por:" />}
                                                    MenuProps={MenuProps}
                                                    style={{fontSize: FONT_SIZE, width: '100%'}}
                                                    >
                                                    {
                                                        [{id: 1, name: 'Data Vencimento'}, {id: 2, name: 'Data Emissão'}, {id: 3, name: 'Data Entrega'}, {id: 4, name: 'Status'}, {id: 5, name: 'Centro de Custo'}].map((item) => (
                                                            <MenuItem
                                                            key={item.id}
                                                            value={item.id}
                                                            >
                                                                {item.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Paper>
                                        <Paper style={{width: 'fit-content', padding: '2rem', display: 'flex', flex: '1', flexDirection: 'column', gap: '2rem'}}>
                                            <FormControl sx={{ flex: 1 }}>
                                                <InputLabel id="cost-center-label" style={{fontSize: FONT_SIZE}}>Centro de Custo por:</InputLabel>
                                                <Select
                                                    // error={inputsError.suppliers.value}
                                                    labelId="cost-center-label"
                                                    id="cost-center"
                                                    // value={selectedSupplier}
                                                    // onChange={handleChange}
                                                    input={<OutlinedInput label="Centro de Custo por:" />}
                                                    MenuProps={MenuProps}
                                                    style={{fontSize: FONT_SIZE, width: '100%'}}
                                                    >
                                                    {
                                                        [{id: 1, name: 'Nome x - Ordem: 1'}].map((item) => (
                                                            <MenuItem
                                                            key={item.id}
                                                            value={item.id}
                                                            >
                                                                {item.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Paper>
                                    </Box>

                                    <Paper style={{width: 'fit-content', padding: '2rem', display: 'flex', flexDirection: 'row', gap: '2rem'}}>
                                        <Button style={{fontSize: FONT_SIZE}} variant="contained">Filtrar</Button>
                                        <Button style={{fontSize: FONT_SIZE}} color="error" variant="contained">Resetar</Button>
                                    </Paper>
                                </Paper>
                                <Paper style={{width: '100%', display: 'flex', flex: '1', flexDirection: 'column', gap: '2rem', padding: '2rem'}}>
                                    <h3 style={{margin: '0'}}>Relatórios</h3>
                                    <Paper style={{width: '100%', padding: '2rem', display: 'flex', flexDirection: 'row', gap: '2rem'}}>
                                        <FormControl sx={{ flex: 1 }}>
                                            <InputLabel id="reports-list-label" style={{fontSize: FONT_SIZE}}>Lista de Relatórios</InputLabel>
                                            <Select
                                                // error={inputsError.suppliers.value}
                                                labelId="reports-list-label"
                                                id="reports-list"
                                                // value={selectedSupplier}
                                                // onChange={handleChange}
                                                input={<OutlinedInput label="Lista de Relatórios" />}
                                                MenuProps={MenuProps}
                                                style={{fontSize: FONT_SIZE, width: '100%'}}
                                                >
                                                {
                                                    [{id: 1, name: 'Teste 1'}, {id: 2, name: 'Teste 2'}, {id: 3, name: 'Teste 3'}].map((item) => (
                                                        <MenuItem
                                                        key={item.id}
                                                        value={item.id}
                                                        >
                                                            {item.name}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                        <Button style={{fontSize: FONT_SIZE}} variant="contained">Carregar</Button>
                                        <Button style={{fontSize: FONT_SIZE}} color="error" variant="contained">Deletar</Button>
                                    </Paper>

                                    <Paper style={{width: '100%', padding: '2rem', display: 'flex', flexDirection: 'row', gap: '2rem'}}>
                                        <BaseInput label="Nome do Relatório" variant="standard" />
                                        <Button style={{fontSize: FONT_SIZE}} variant="contained">Salvar</Button>
                                    </Paper>
                                </Paper>
                            </Box>
                        </Paper>
                        {/* <div className="img-container">
                            <img src={noData} alt="Não foi possível renderizar o gráfico por falta de dados." />
                            <h4>Não foi possível renderizar os gráficos por falta de dados.</h4>
                        </div> */}
                    </main>
                </div>
            </div>
        </>
    )
}