import {FaTrophy, FaTimesCircle} from 'react-icons/fa';
import {FiExternalLink, FiAlertTriangle} from 'react-icons/fi';
import placeholder from '../../../assets/images/placeholder.jpg';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

export const options = {
  palette:{
    tableHeader: {
      main: '#F7CE3E',
      dark: '#ac902b',
      light: '#f8d764',
      contrastText: '#000'
    },
  },
  alternate: true,
}

export const columns = [
  { 
    headerName: "SKU", 
    field: "_id", 
    type: 'string',
    headerAlign: 'center',
    flex: 1,
    minWidth: 100,
    renderCell: (rowData: any) => {
      return (
        <Tippy content={
          <div>{rowData.row._id}</div>
        } maxWidth={'100%'}>
            <div style={{width: '10rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
              <span title={rowData.row._id}>{rowData.row._id}</span>
            </div>
        </Tippy>
      )
    }
  },
    {
        headerName: "Imagem",
        field: "photo",
        type: 'string',
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (rowData: any) =>{
          return(
            <div className="img" style={{width: 30, height: 30, borderRadius: '50%'}}>
                <img src={rowData.row.photo ? rowData.row.photo : placeholder} alt="Imagem do produto" style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 'inherit'}}/>
            </div>
          )
        }
    },
    {
      headerName: "Produto",
      field: "titleCatalog",
      type: 'string',
      headerAlign: 'center',
      flex: 2,
      minWidth: 200,
      renderCell: (rowData: any) => {
        return (
          <Tippy content={
            <div>{rowData.row.titleCatalog ? rowData.row.titleCatalog : 'N/A'}</div>
          } maxWidth={'100%'}>
            <a title={rowData.row.titleCatalog} href={rowData.row.linkCatalog} target="_blank" rel="noreferrer" style={{fontSize: 'clamp(1.1rem, 1vw, 1.2rem)', color: '#0A1612', display: 'flex', gap: '5px'}}>
                <div style={{width: '40rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{rowData.row.titleCatalog  ? rowData.row.titleCatalog : 'N/A'}</div><FiExternalLink />
            </a>
          </Tippy>
        )
      }
    },
    {
      headerName: "Status",
      field: "status",
      type: 'singleSelect',
      headerAlign: 'center',
      flex: 1,
      valueOptions: ['Ganhando', 'Perdendo', 'Não Listado'],
      minWidth: 100,
      renderCell: (rowData: any) => {
        const tagStatus:any = {"Ganhando": <FaTrophy className="icon"/>, "Perdendo": <FiAlertTriangle className="icon"/>, 'Não Listado': <FaTimesCircle className="icon"/>}
        const statusColor:any = {"Ganhando": "#2477ad", 'Perdendo': "#f39c12", "Não Listado": "#e74c3c"}
        // const statusTranslations:any = {"winning": 'Ganhando', "loosing": 'Perdendo', 'not_listed': 'Não Listado'};
        return (
          <span style={{display: 'flex', alignItems: "center", justifyContent: "flex-start"}}>
              {rowData.row.status}
              <span style={{color: statusColor[rowData.row.status], fontSize: "1rem", marginLeft: '.5rem'}}>
                  {tagStatus[rowData.row.status]}
              </span>
          </span>
        )
      }
    },
  {
    headerName: "Valor Ganhar",
    field: "priceToWin",
    type: 'number',
    headerAlign: 'center',
    flex: 1,
    minWidth: 100,
    renderCell: (rowData: any) => {
      return (
        <span>
          {(rowData.row.priceToWin ? rowData.row.priceToWin : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
        </span>
      )
    }
  },
  {
    headerName: "Valor Ganhador",
    field: "priceWinner",
    type: 'number',
    headerAlign: 'center',
    flex: 1,
    minWidth: 100,
    renderCell: (rowData: any) => {
      return (
        <span>
          {(rowData.row.priceWinner ? rowData.row.priceWinner : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
        </span>
      )
    }
  },
  {
    headerName: "Tipo Anúncio Ganhador",
    field: "listingTypeWinner",
    type: 'singleSelect',
    headerAlign: 'center',
    flex: 1,
    valueOptions: ["Premium", "Clássico"], 
    minWidth: 100,
    renderCell: (rowData: any) => {
      return (
        <span>
          {rowData.row.listingTypeWinner ? rowData.row.listingTypeWinner : "N/A"}
        </span>
      )
    }
  },
  {
    headerName: "Qtd. Disponível Ganhador",
    field: "availableQuantityWinner",
    type: 'number',
    headerAlign: 'center',
    flex: 1,
    minWidth: 100,
    renderCell: (rowData: any) => {
      return (
        <span>
          {rowData.row.availableQuantityWinner ? rowData.row.availableQuantityWinner : "N/A"}
        </span>
      )
    }
  }
]
