import { columns, options } from '../../../components/table/files/ShippingRelationData';
import noData from '../../../assets/images/nodata.svg';
import { PopOverMenuModal } from '../../../components/modal/PopOverMenuModal';
import { useModal } from '../../../hooks/useModal';
import { ModalConfirm } from '../../../components/modal/ModalConfirm';
import { useHistory } from "react-router-dom";
import { Accordion } from '../../../components/accordion/Accordion';
import {CaptionsShippmentRelations} from '../../../components/accordion/files/CaptionsShippingRelations';
import {FaEdit, FaTrash} from 'react-icons/fa';
import { GoGraph } from 'react-icons/go';
import './style.scss';
import { IShippingRelation } from "../../../types/Types"
import { useDarkMode } from '../../../hooks/useDarkMode';
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import { Loading } from '../../../components/Loading/Loading';
import { BugReportModal } from '../../../components/modal/BugReportModal';

export const ShippingRelation = (props:any) =>{
    document.title = "Relação de Envios Full - MeLiBeat";
    const handleValidateStatus = useHandleErrors();

    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const fetchResource = (query:any=undefined) => axs.get<IShippingRelation[]>('/fulfillment/', {params: query}).then((r: any) => {
        handleValidateStatus(r)
        const auxData = r.data;
        const processedData = auxData.map((item: any)=>{
            item["skus"] = []
            item["mlb_ids"] = []
            if(item.itens.length > 0){
                item.itens.map((innerItem: any)=>{
                    item.skus.push(innerItem.sku)
                    item.mlb_ids.push(innerItem.mlb_id)
                    return innerItem
                })
            }
            return item
        })
        return processedData
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    })
    const data: State<IShippingRelation[]> = useState<IShippingRelation[]>([]);

    const showPopOverMenuModal: State<boolean> = useState<boolean>(false);
    const popOverMenuModalData: State<IShippingRelation> = useState<IShippingRelation>({
        "shipping": {
            "max_m3": 0,
            "max_weight": 0,
        },
        "completed": {
            "relation": false,
            "precification": false,
            "alteration": false
        },
        "_id": '',
        "quantity": 0,
        "finished": false,
        "description": '',
        "scheduling": '',
        "status": '',
        "itens": [],
        "volume": 0,
        "__v": 0
    });
    const menuPosition: State<object> = useState<object>({'x': Number, 'y': Number});
    const {ModalData, showHideModal} = useModal();
    let history = useHistory();
    const {DarkMode, } = useDarkMode();

    useEffect(() => {
        data.set(fetchResource())
    }, [])

    const handlePopOverMenuModal = (evt: React.BaseSyntheticEvent, origin: string)=>{
        showPopOverMenuModal.set(false);
        if(origin === "editShippment"){
            return
        }else if(origin === "deleteShippment"){
            showHideModal({show: true, title: "Gostaria de excluir este envio?", execute: (confirm: boolean)=> handleDelete(confirm)})

        }
        else if(origin === "analiseShippment"){
            return
        }else{
            return
        }
    }

    const handleModal = (event: any, value: IShippingRelation)=>{
        menuPosition.set({x: (event.pageX-170), y: event.pageY})
        popOverMenuModalData.set(value)
        showPopOverMenuModal.set(true);
    }

    const handleAddRelation = (evt: React.BaseSyntheticEvent)=>{
        evt.preventDefault();
        axs.post<any>('/fulfillment/').then((r: any) => {
            if(r.status === 200){
                const new_id = r.data._id
                history.push(`/fulfillment/alteracao-envio/${new_id}/`);
            }else{
                toast.error(r.message);
            }
        }).catch((err: any)=>{
            handleValidateStatus(err.response)
        })
    }

    const handleDelete = (value: boolean) =>{
        if(value){
            axs.delete<any>('/fulfillment/'+popOverMenuModalData._id.value).then((r: any) => {
                if(r.status === 200){
                    data.set(fetchResource())
                }else{
                    toast.error(r.message);
                }
            }).catch((err: any)=>{
                handleValidateStatus(err.response)
            })
            showHideModal({show: false, title: ModalData?.title, execute: ModalData?.execute})
        }else{
            // setProductsData({products: {}, description: ''});
            showHideModal({show: false, title: ModalData?.title, execute: ModalData?.execute})
        }
    }

    if(data.promised){ //Enquanto for promisse ou estiver carregando
        return (
            <>
                <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}> 
                    <div className="content-wrapper">
                        <main className="main-content container-fluid">
                            <Loading />
                        </main>
                    </div>
                </div>
            </>
        )
    }

    if (data.error) { // Quando o promise dar reject
        return (<ErrorScreen />)
    }

    return(
        <>
            <ModalConfirm />
            {
                showPopOverMenuModal.get() ?
                    <PopOverMenuModal position={menuPosition.attach(Downgraded).get()} closeFunction={()=> showPopOverMenuModal.set(false)}>
                        {
                            true ?
                            <>
                                <a href={`/fulfillment/alteracao-envio/${popOverMenuModalData._id.value}/`} onClick={(evt: React.BaseSyntheticEvent)=> handlePopOverMenuModal(evt, 'editShippment')} target="_blank" rel="noreferrer">
                                    <FaEdit /><div>Editar Envio</div>
                                </a>
                                <a href={`/fulfillment/analise-envios/${popOverMenuModalData._id.value}/`} onClick={(evt: React.BaseSyntheticEvent)=> handlePopOverMenuModal(evt, 'analiseShippment')} target="_blank" rel="noreferrer">
                                    <GoGraph /><div>Analisar Envio</div>
                                </a>
                                <button onClick={(evt: React.BaseSyntheticEvent)=> handlePopOverMenuModal(evt, 'deleteShippment')}><FaTrash />Deletar Envio</button>
                            </>
                            :
                            null
                        }

                    </PopOverMenuModal>
                : null
            }


            <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
                <BugReportModal />
                <div className="content-wrapper">
                    <main className="main-content container-fluid">
                        <Accordion title={CaptionsShippmentRelations.title} data={CaptionsShippmentRelations.data} />
                        <div className={`addShippment ${DarkMode ? 'dark-mode-addShippment' : ''}`}>
                            <Button variant="contained" color='primary' startIcon={<AddIcon />} sx={{fontSize: 'clamp(1.2rem, 1.2vw, 1.6rem)', width: 'fit-content', display: 'flex', alignItems: 'center', '& .MuiButton-startIcon':{margin: '0'}}} onClick={(evt: React.BaseSyntheticEvent)=> handleAddRelation(evt)}>
                                Novo Envio
                            </Button>
                        </div>
                        {
                            data.length > 0 ?
                            <VirtualizedGrid 
                                rows={data.attach(Downgraded).get()}
                                columns={columns}
                                options={options}
                                title="Relação de Envios Full"
                                actions={[
                                    {icon: 'settings', title: 'Mais Ações', method: (event: any, row:any)=> handleModal(event, row)}, 
                                ]}
                                defaultSort={{field: 'scheduling', direction: 'desc'}}
                            />
                            :
                            <div className="img-container">
                                <img src={noData} alt="Não foi possível renderizar o gráfico por falta de dados." />
                                <h4>Não foi possível renderizar os gráficos por falta de dados.</h4>
                            </div>
                        }
                    </main>

                </div>
            </div>
        </>
    )
}