import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Loading } from '../../../../components/Loading/Loading';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import Autocomplete from '@mui/material/Autocomplete';
import Popper from '@material-ui/core/Popper';
import { BlingProductsType, IEditingData } from "../../../../types/Types";
import { useDarkMode } from '../../../../hooks/useDarkMode';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { useHandleErrors } from '../../../../hooks/useHandleErrors';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddIcon from '@mui/icons-material/Add';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

const BUTTON_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.4rem)';
const INPUT_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.6rem)';

const StyledPopper = styled(Popper)({
    "& .MuiAutocomplete-listbox": {
        '& li':{
        fontSize: INPUT_FONT_SIZE,
    }}
});

export const SkuTab = (props: any) =>{
    const handleValidateStatus = useHandleErrors();
    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const loading: State<boolean> = useState<boolean>(false);
    // const [skus, setSkus] = useState<any>([]);
    const blingProducts: State<BlingProductsType> = useState<BlingProductsType>([]);
    const checkRounding: State<boolean> = useState<boolean>(false);
    const selectedRounding: State<number> = useState<number>(0);
    const selectedMargin: State<number> = useState<number>(0);
    const selectedSku: State<string> = useState<string>('');
    const editingData: State<IEditingData> = useState<IEditingData>({
        "_id": '',
        "type": '',
        "group": false,
        "origin": '',
        "category": '',
        "brand": '',
        "sku": '',
        "margin": 0,
        "roundTo": 0,
        "__v": 0,
        "priority": 0,
        "tableData": {
            "id": 0,
        }
    });
    const editing: State<boolean> = useState<boolean>(false);
    const selectedRoundingEditing: State<number> = useState<number>(0);
    const selectedMarginEditing: State<number> = useState<number>(0);
    const selectedSkuEditing: State<string> = useState<string>('');
    const {DarkMode, } = useDarkMode();

    const defaultTheme  = createTheme({
        palette:{
          mode: DarkMode ? 'dark' : 'light',
        },
    });

    useEffect(() => {
        blingProducts.set(props.blingProducts)
        editing.set(props.editing);
        if(props.editingData.length > 0){
            editingData.set(props.editingData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        editing.set(props.editing);
        editingData.set(props.editingData);
    }, [props.editingData, props.editing])


    const handleSubmit = (evt: React.BaseSyntheticEvent)=>{
        evt.preventDefault();
        evt.target.reset()
        let temp_sku: string = selectedSku.get(),
        temp_rounding: number = 0,
        temp_margin: number | null = (selectedMargin.get() ? selectedMargin.get() : 0)/100;

        if(temp_sku === ''){
            toast.error('O campo SKU deve estar preenchido');
            return
        }

        if(checkRounding.get()){
            temp_rounding = selectedRounding.get()
        }
        

        selectedRounding.set(0);
        selectedMargin.set(0);
        selectedSku.set('')
        checkRounding.set(false);


        const processedData = {
            "type":"manual",
            "group": false,
            "origin": null,
            "category": null,
            "brand": null,
            "sku": temp_sku,
            "margin": temp_margin,
            "roundTo": temp_rounding
       }

       loading.set(true)
    
       axs.post<any>(`/precification/config/manual`, processedData).then((r: any) => {
            if(r.status === 200){
                if(r.data){
                    editing.set(false);
                    editingData.set({
                        "_id": '',
                        "type": '',
                        "group": false,
                        "origin": '',
                        "category": '',
                        "brand": '',
                        "sku": '',
                        "margin": 0,
                        "roundTo": 0,
                        "__v": 0,
                        "priority": 0,
                        "tableData": {
                            "id": 0,
                        }
                    });
                    loading.set(false)
                    props.reloadTable()
                }
            }else{
                loading.set(false)
                toast.error(r.message);
            }
        }).catch((err: any)=>{
            loading.set(false)
            handleValidateStatus(err.response)
        });
    }

    const handleSubmitEdit = (evt: React.BaseSyntheticEvent)=>{
        evt.preventDefault();
        evt.target.reset()
        let temp_sku: string = selectedSkuEditing.get(),
        temp_rounding = selectedRoundingEditing.get(),
        temp_margin: number = (selectedMarginEditing.get() ? selectedMarginEditing.get():0)/100,
        id = editingData._id.value;

        if((temp_sku === undefined || temp_sku === null) &&
            (temp_rounding === undefined || temp_rounding === null) &&
            (temp_margin === undefined || temp_margin === null || temp_margin === 0)){
            toast.info("Pelo menos um dos campos deve ser preenchido.");
            return
        }

        if(temp_sku === undefined || temp_sku === null){
            temp_sku = editingData.sku.value
        }
        if(temp_rounding === undefined || temp_rounding === null){
            temp_rounding = editingData.roundTo.value
        }
        if(temp_margin === undefined || temp_margin === null || temp_margin === 0){
            temp_margin = editingData.margin.value
        }

        selectedSkuEditing.set('')
        selectedRoundingEditing.set(0);
        selectedMarginEditing.set(0);

        const processedData = {
        "origin": null,
        "category": null,
        "brand":null,
        "sku": temp_sku,
        "margin": temp_margin,
        "roundTo": temp_rounding
        }

        editingData.set({
            "_id": '',
            "type": '',
            "group": false,
            "origin": '',
            "category": '',
            "brand": '',
            "sku": '',
            "margin": 0,
            "roundTo": 0,
            "__v": 0,
            "priority": 0,
            "tableData": {
                "id": 0,
            }
        })
        editing.set(false)
        loading.set(true)

       axs.put<any>(`/precification/config/${id}`, processedData).then((r: any) => {
        if(r.status === 200){
            if(r.data){
                editing.set(false);
                editingData.set({
                    "_id": '',
                    "type": '',
                    "group": false,
                    "origin": '',
                    "category": '',
                    "brand": '',
                    "sku": '',
                    "margin": 0,
                    "roundTo": 0,
                    "__v": 0,
                    "priority": 0,
                    "tableData": {
                        "id": 0,
                    }
                });
                loading.set(false)
                props.reloadTable()
            }
        }else{
            loading.set(false)
            toast.error(r.message);
        }
    }).catch((err: any)=>{
        loading.set(false)
        handleValidateStatus(err.response)
    });
}


    return(
        <div className="skuSection">
            {
                loading.get() ?

                <Loading />
                :
                <main className={`container-fluid ${DarkMode ? 'dark-mode-sku-tab' : ''}`} style={{padding: '0'}}>
                    <ThemeProvider theme={defaultTheme}>
                        {
                            !editing.get() ?
                                <Accordion>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="sku-section"
                                    id="sku-section-header"
                                    >
                                        <h3 style={{marginLeft: "1rem"}}>Cadastro Precificador - SKUs</h3>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box component="form" onSubmit={(evt: React.BaseSyntheticEvent)=> handleSubmit(evt)}
                                        style={{width: "100%"}}
                                        >
                                            <Paper elevation={3}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                gap: '1rem',
                                                margin: 0,
                                                padding: '3rem'
                                            }}
                                            >
                                                <Box sx={{display: 'flex', justifyContent: "center", flexDirection: "column", alignItems: "flex-start"}}>
                                                    <Autocomplete
                                                        id={`skus-id`}
                                                        options={blingProducts.attach(Downgraded).get()}
                                                        getOptionLabel={(option: any) => option.sku}
                                                        sx={{minWidth: '20rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                                        renderInput={(params) => 
                                                            <TextField required {...params} label="SKU" />
                                                        }
                                                        renderOption={(props, option) => (
                                                            <li {...props}>
                                                                {`${option.sku} -  ${option.label}`}
                                                            </li>
                                                        )}
                                                        PopperComponent={(props)=> <StyledPopper {...props} style={{ width: "fit-content", padding: "1rem",display: "flex", flexDirection: "column", gap: '1rem' }} placement='bottom-start' />}
                                                        onChange={(evt: React.BaseSyntheticEvent, value: any)=> {
                                                            const sku_value = value !== null ? value.sku : '';
                                                            selectedSku.set(sku_value)
                                                        }}
                                                    />
                                                </Box>
                                                <hr/>
                                                <Box sx={{display: 'flex', justifyContent: "center", flexDirection: "column", alignItems: "flex-start"}}>
                                                    <TextField required type='number' id="margin-editing" label="Margem" variant="outlined" onChange={(evt: any)=> selectedMargin.set(evt.target.value)} 
                                                        sx={{minWidth: '20rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                                    />
                                                </Box>
                                                <hr/>
                                                <Box sx={{display: 'flex', justifyContent: "center", flexDirection: "column", "alignItems": "flex-start"}}>
                                                    <FormControlLabel control={<Checkbox />} label="Arredondamento" sx={{ '& .MuiSvgIcon-root': { fontSize: 20 }, '& .MuiTypography-root': { fontSize: INPUT_FONT_SIZE } }} onChange={(evt: any)=> checkRounding.set(evt.target.checked)} />
                                                    <TextField type='number' required={checkRounding.get()} disabled={!checkRounding.get()} id="rounding" label="Arredondamento" variant="outlined" onChange={(evt: any)=> {
                                                        if(evt.target.value < 0 || evt.target.value > 99){
                                                            toast.info("O campo Arredondamento aceita apenas valores entre 0 e 99.");
                                                            return
                                                        }
                                                        selectedRounding.set(evt.target.value)
                                                    }} 
                                                        sx={{minWidth: '20rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                                    />
                                                </Box>

                                                <Button variant="contained" color='primary' startIcon={<AddIcon />} sx={{fontSize: BUTTON_FONT_SIZE, marginTop: '1rem', width: '100%', maxWidth: 'fit-content', '& .MuiButton-startIcon':{margin: '0'}}} type='submit'>
                                                    Adicionar Nova Regra
                                                </Button>
                                            </Paper>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            :
                                <Box component="form" onSubmit={(evt: React.BaseSyntheticEvent)=> handleSubmitEdit(evt)}
                                style={{width: "100%"}}
                                >
                                    <Paper elevation={3}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        gap: '1rem',
                                        margin: 0,
                                        padding: '3rem'
                                    }}
                                    >
                                        <h3>Edição Cadastro Precificador - SKUs</h3>
                                        <Box sx={{display: 'flex', justifyContent: "center", flexDirection: "column", alignItems: "flex-start"}}>
                                            <Autocomplete
                                                id={`skus-editing-id`}
                                                key={editingData.sku.value}
                                                disableClearable={true}
                                                defaultValue={{label: '', sku: editingData.sku.value, cost: undefined}}
                                                options={blingProducts.attach(Downgraded).get()}
                                                getOptionLabel={(option: any) => option.sku}
                                                sx={{minWidth: '20rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                                renderInput={(params) => 
                                                    <TextField {...params} label="SKU" />
                                                }
                                                renderOption={(props, option) => (
                                                    <li {...props}>
                                                        {`${option.sku} -  ${option.label}`}
                                                    </li>
                                                )}
                                                PopperComponent={(props)=> <StyledPopper {...props} style={{ width: "fit-content", padding: "1rem",display: "flex", flexDirection: "column", gap: '1rem' }} placement='bottom-start' />}
                                                onChange={(evt: React.BaseSyntheticEvent, value: any)=> {
                                                    const sku_value = value.sku;
                                                    editingData.sku.set(value.sku);
                                                    selectedSkuEditing.set(sku_value)
                                                }}
                                            />
                                        </Box>
                                        <hr/>
                                        <Box sx={{display: 'flex', justifyContent: "center", flexDirection: "column", alignItems: "flex-start"}}>
                                            <TextField type='number' id="margin-editing" label="Margem" variant="outlined" 
                                                onChange={(evt: any)=> {
                                                    selectedMarginEditing.set(evt.target.value)
                                                    editingData.margin.set(evt.target.value)
                                                }} 
                                                defaultValue={editingData.margin.value ? editingData.margin.value*100 : ""}
                                                sx={{minWidth: '20rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                            />
                                        </Box>
                                        <hr/>
                                        <Box sx={{display: 'flex', justifyContent: "center", flexDirection: "column", "alignItems": "flex-start"}}>
                                            <TextField type='number' id="rounding" label="Arredondamento" variant="outlined" onChange={(evt: any)=> {
                                                if(evt.target.value < 0 || evt.target.value > 99){
                                                    toast.info("O campo Arredondamento aceita apenas valores entre 0 e 99.");
                                                    return
                                                }
                                                selectedRoundingEditing.set(evt.target.value)
                                                editingData.roundTo.set(evt.target.value)
                                            }} 
                                            defaultValue={editingData.roundTo.value}
                                            sx={{minWidth: '20rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                            />
                                        </Box>

                                        <Button variant="contained" color='primary' startIcon={<AddIcon />} sx={{fontSize: BUTTON_FONT_SIZE, marginTop: '1rem', width: '100%', maxWidth: 'fit-content', '& .MuiButton-startIcon':{margin: '0'}}} type='submit'>
                                            Alterar Regra
                                        </Button>
                                    </Paper>
                                </Box>
                        }
                    </ThemeProvider>
                </main>
            }
        </div>
    )
}