
export const CaptionsShippmentRelations = {
    title: 'Legendas Relação de Envios Full',
    data: {
        colors: {
            'Etapa 1 - Relação do Envio': "#fff",
            'Etapa 2 e 3 - Precificação e Alterações': "#74b9ff",
            'Etapa 3 - Alterações': "#fab1a0",
            'Concluido': "#55efc4",
            'Encerrado': "#b2bec3",
        },
        tags:{}
    }
}