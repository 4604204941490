import './style.scss';
import VirtualizedGrid from '../../components/table/VirtualizedGrid';


import { MdClose } from 'react-icons/md';

export const GridModal = (props: any) =>{
    const {tableTitle, columns, options, data, closeFunction, defaultSort, modalWrapperStyle} = props
    return (
        <div className="modal-wrapper" ref={props.componentRef} style={modalWrapperStyle}>
            <div className="table-modal" style={props.style}>
                <div className="close-container">
                    <button onClick={closeFunction}><MdClose className="icon" /></button>
                </div>
                <main className="main-content container-fluid">
                    <VirtualizedGrid 
                        rows={data}
                        columns={columns}
                        options={options}
                        title={tableTitle}
                        defaultSort={{field: defaultSort.field, direction: defaultSort.direction}}
                    />
                </main>
            </div>
        </div>
    )
}