import { toast } from 'react-toastify';
import moment, {Moment} from 'moment';
import { IAnalysisABC } from "../../../types/Types";
import { useDarkMode } from '../../../hooks/useDarkMode';
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import { Filters } from '../../../components/Filters/Filters';
import { Loading } from '../../../components/Loading/Loading';
import {isFirefox} from 'react-device-detect';
import TextField from '@mui/material/TextField';
import {FaCheckCircle, FaTimesCircle, FaPauseCircle} from 'react-icons/fa';
import {FiExternalLink} from 'react-icons/fi';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {BiRevision} from 'react-icons/bi'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { BugReportModal } from '../../../components/modal/BugReportModal';

const defaultTheme  = createTheme();

const INPUT_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.6rem)';

export const ActiveDaysHistory = (props:any) =>{
    document.title = "Histórico de Dias Ativos - MeLiBeat";
    const handleValidateStatus = useHandleErrors();

    const from = moment().startOf('month').format("YYYY-MM-DD");
    const to = moment(from).endOf('month').format('YYYY-MM-DD');
    const processedFilter = {
        dateFrom: from,
        dateTo: to,
        skuHistory: false,
        withoutCache: false,
    }

    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const fetchResource = (query:any=processedFilter) => axs.get<IAnalysisABC>('/mlbs/history', {params: query}).then((r: any) => {
        handleValidateStatus(r)
        const tagStatusTranslations:any = {"active": 'Ativo', "inactive": 'Inativo', 'paused': 'Pausado', "under_review": "Em Revisão", "closed": "Fechado"}
        const processedArray = r.data.map((item: any)=>{
          const status = item.status;
          item["status"] = tagStatusTranslations[status];
          item["actual"] = item.actual ? 'Não' : 'Sim'
          return item
        })
        return processedArray;
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    })

    const data: State<any> = useState<any>([]);
    const month: State<string> = useState<string>(moment().format("MM"));
    const dateFilter: State<string> = useState<string>(isFirefox ? moment().format("yyyy-MM-DD") : moment().format("yyyy-MM"));
    const SKUHistoryFilter: State<string> = useState<string>('0');
    const {DarkMode, } = useDarkMode();

    const checkVisibility = (column: string)=>{
        const storageColumnsData = localStorage.getItem('Histórico de Dias Ativos') ? localStorage.getItem('Histórico de Dias Ativos') : null;
        if(storageColumnsData !== null){
          const localData = JSON.parse(storageColumnsData);
          const shouldHide = localData.find((item: any)=> item.field === column);
          return shouldHide !== undefined ? !shouldHide.visibility : false
        }
        return false
      }

    const options = {
        palette:{
          tableHeader: {
            main: '#F7CE3E',
            dark: '#ac902b',
            light: '#f8d764',
            contrastText: '#000'
          },
        },
        alternate: true,
    }

    const columns: any[] = [
      { 
          headerName: "ID", 
          field: "id", 
          type: 'string',
          headerAlign: 'center',
          flex: 1,
          minWidth: 150,
          hide: checkVisibility('id'),
          renderCell: (rowData: any) => {
            return (
              <ThemeProvider theme={defaultTheme}>
              <div title={rowData.row.id}>
                <Link 
                  href={rowData.row.link} 
                  target="_blank" rel="noreferrer" 
                  color={'inherit'} 
                  sx={{'&:hover':{color: defaultTheme.palette.primary.dark}}} 
                >
                    <div style={{fontSize: 'clamp(1.2rem, 1vw, 1.3rem)', display: 'flex', alignItems: 'center', gap: '5px', textDecoration: 'none'}}>
                      <div>{rowData.row.id}</div><FiExternalLink />
                    </div>
                </Link>
              </div>
            </ThemeProvider>
            )
          }
      },
      { 
        headerName: "Título", 
        field: "title", 
        type: 'string',
        headerAlign: 'center',
        align: 'left',
        flex: 4,
        minWidth: 400,
        hide: checkVisibility('title'),
        renderCell: (rowData: any) => {
          return (
            <Tippy content={
                <div>{rowData.row.title}</div>
            } maxWidth={'100%'}>
                <div style={{width: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}} title={rowData.row.title}>
                    {rowData.row.title}
                </div>
            </Tippy>
          )
        }
      },
      { 
        headerName: "Variação", 
        field: "variation_name", 
        type: 'string',
        headerAlign: 'center',
        align: 'left',
        flex: 2,
        minWidth: 200,
        hide: checkVisibility('variation_name'),
        renderCell: (rowData: any) => {
          return (
            <Tippy content={
                <div>{rowData.row.variation_name ? rowData.row.variation_name : "N/A"}</div>
            } maxWidth={'100%'}>
                <div style={{width: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}} title={rowData.row.variation_name}>
                    {rowData.row.variation_name ? rowData.row.variation_name : "N/A"}
                </div>
            </Tippy>
          )
        }
      },
      { 
        headerName: "SKU", 
        field: "sku", 
        type: 'string',
        headerAlign: 'center',
        minWidth: 100,
        flex: 1,
        hide: checkVisibility('sku'),
        renderCell: (rowData: any) => {
          return (
            rowData.row.sku
          )
        }
      },
      { 
        headerName: "SKU Histórico", 
        field: "actual", 
        type: 'singleSelect',
        headerAlign: 'center',
        minWidth: 100,
        flex: 1,
        hide: checkVisibility('actual'),
        valueOptions: ["Sim", "Não"],
        renderCell: (rowData: any) => {
          return (
            rowData.row.actual
          )
        }
      },
      { 
        headerName: "Status", 
        field: "status", 
        type: 'singleSelect',
        headerAlign: 'center',
        align: 'left',
        flex: 1,
        minWidth: 100,
        hide: checkVisibility('status'),
        valueOptions: ["Ativo", "Inativo", "Pausado", "Em Revisão", "Fechado"],
        renderCell: (rowData: any) => {
          const tagStatus:any = {"Ativo": <FaCheckCircle className="icon"/>, "Inativo": <FaTimesCircle className="icon"/>, 'Pausado': <FaPauseCircle className="icon"/>, "Em Revisão": <BiRevision className="icon" />, "Fechado": <FaTimesCircle className="icon"/>}
          const statusColor:any = {"Ativo": "#2477ad", "Inativo": "#a5b1c2", 'Pausado': "#d65446", "Em Revisão": "#000", "Fechado": "#c0392b"}
          return (
            <div className="tags-container" style={{marginTop: 0, flexWrap: 'nowrap', display: 'flex', gap: '5px'}}>
              <Tippy content={`Status: ${rowData.row.status}`} maxWidth={'25rem'}>
                  <span className="tag-small" title={`Status: ${rowData.row.status}`} style={{color: (rowData.row.status ? statusColor[rowData.row.status]:'#a5b1c2')}}>{tagStatus[rowData.row.status]}</span>
              </Tippy>
            </div>
          )
        }
      },
      { 
          headerName: "Total Vendido", 
          field: "total_sold", 
          type: 'number',
          headerAlign: 'center',
          minWidth: 50,
          hide: checkVisibility('total_sold'),
          renderCell: (rowData: any) => {
            return (
              rowData.row.total_sold
            )
          }
      },
      { 
          headerName: "Última Atualização", 
          field: "last_updated", 
          type: 'date',
          headerAlign: 'center',
          minWidth: 200,
          hide: checkVisibility('last_updated'),
          renderCell: (rowData: any) => {
            return (
              moment(rowData.row.last_updated).format("DD-MM-YYYY")
              
            )
          }
      },
      { 
        headerName: "Dias Ativos", 
        field: "activeDaysCount", 
        type: 'number',
        headerAlign: 'center',
        minWidth: 50,
        hide: checkVisibility('activeDaysCount'),
        renderCell: (rowData: any) => {
          return (
            rowData.row.activeDaysCount
            
          )
        }
    }
    ]

    for(let i = 1; i<= moment().daysInMonth(); i++){
      columns.push({
        headerName: `${i.toString().padStart(2,'0')}/${ moment().format('MM')}`, 
        field: `activeDays['${i}']`, 
        type: 'boolean',
        headerAlign: 'center',
        minWidth: 50,
        renderCell: (rowData: any) => {
          return (
            typeof rowData.row.activeDays[i] === "boolean" ?
            <div className="tags-container" style={{marginTop: 0, flexWrap: 'nowrap', display: 'flex', gap: '5px'}}>
              <span className="tag-small" style={{color: (rowData.row.activeDays[i] ? '#10ac84' : "#d65446")}}>{rowData.row.activeDays[i] ? <FaCheckCircle className="icon"/> : <FaTimesCircle className="icon"/>}</span>
            </div>
            :
            rowData.row.activeDays[i]
          )
        }
      })
    }

    useEffect(() => {
        data.set(fetchResource())
    }, [])

    const handleFilter = (filters: any)=>{
        toast.info('Filtrando...')
        const updatedFilters:any = {};
        updatedFilters["dateFrom"] = `${filters.filterDate}-01`;
        updatedFilters["dateTo"] = moment(filters.filterDate).endOf('month').format('YYYY-MM-DD')
        updatedFilters["withoutCache"] = false;
        updatedFilters["skuHistory"] = filters.filterSKUHistory === "1" ? true : false;
        month.set(moment(filters.filterDate).format('MM'))
        data.set(fetchResource(updatedFilters))
    }

    const handleFullReset = ()=>{
        toast.info("Resetando os dados sem o cache!");
        const from = moment().startOf('month').format("YYYY-MM-DD");
        const to = moment(from).endOf('month').format('YYYY-MM-DD');
        const processedFilter = {
            dateFrom: from,
            dateTo: to,
            withoutCache: true
        }
        month.set(moment().format('MM'))
        dateFilter.set(isFirefox ? moment().format("yyyy-MM-DD") : moment().format("yyyy-MM"))
        SKUHistoryFilter.set('0')
        data.set(fetchResource(processedFilter));
    }

    const handleReset = ()=> {
        const from = moment().startOf('month').format("YYYY-MM-DD");
        const to = moment(from).endOf('month').format('YYYY-MM-DD');
        const processedFilter = {
          dateFrom: from,
          dateTo: to,
          withoutCache: false
        }
        month.set(moment().format('MM'))
        dateFilter.set(isFirefox ? moment().format("yyyy-MM-DD") : moment().format("yyyy-MM"))
        SKUHistoryFilter.set('0')
        data.set(fetchResource(processedFilter))
    }


    return(
      <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
          <BugReportModal />
          <div className="content-wrapper">
              <main className="main-content container-fluid">
                <Filters submitFunction={handleFilter} handleReset={handleReset} handleFullReset={handleFullReset}>
                  <FormControl size='small' variant='outlined' sx={{minWidth: '20rem', width: '100%'}}>
                      <InputLabel id="SKUHistory-label" style={{fontSize: INPUT_FONT_SIZE}}>Histórico SKU</InputLabel>
                      <Select
                          required
                          labelId="SKUHistory-label"
                          id="SKUHistory"
                          value={SKUHistoryFilter.get()}
                          label="Histórico SKU"
                          name='filterSKUHistory'
                          style={{fontSize: INPUT_FONT_SIZE}}
                          onChange={(evt: SelectChangeEvent)=>SKUHistoryFilter.set(evt.target.value)}
                      >
                        <MenuItem value={'1'}>Sim</MenuItem>
                        <MenuItem value={'0'}>Não</MenuItem>
                      </Select>
                  </FormControl>
                  <TextField size="small" required name="filterDate" type={isFirefox ? 'date' : "month"} id="closing_date" InputLabelProps={{ shrink: true }} label="Data" variant="outlined"
                      sx={{minWidth: '20rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                      value={dateFilter.get()}
                      onChange={(evt: any)=> isFirefox ? dateFilter.set(moment(evt.target.value).format("yyyy-MM-DD")) : dateFilter.set(moment(evt.target.value).format("yyyy-MM"))}
                  />
                </ Filters>
                {
                  data.promised ? 
                  <Loading />
                  : data.error ?
                  <ErrorScreen /> : 
                  data ?
                  <VirtualizedGrid 
                      rows={data.attach(Downgraded).get()}
                      columns={columns}
                      options={options}
                      title="Histórico de Dias Ativos"
                      defaultSort={{field: 'sells', direction: 'desc'}}
                      initialState={{ pinnedColumns: { left: ['id', 'title', 'sku', 'variation_name', 'actual', 'status', 'total_sold', 'last_updated', 'activeDaysCount']} }}
                  />
                  :
                  null
                }
              </main>
          </div>
      </div>
    )
}