import { Manual } from './components/Manual';
import { useDarkMode } from '../../../hooks/useDarkMode';
import { BugReportModal } from '../../../components/modal/BugReportModal';

import './style.scss';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';

export const SellingPriceFormulation = () =>{
    const {DarkMode, } = useDarkMode();
    document.title = "Formulação de Preço de Venda - MeLiBeat";


    return(
        <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
            <BugReportModal />
            <div className="content-wrapper">
                <main className="main-content container-fluid">
                    <h3>Formulação  de Preço de Venda</h3>
                    <Tabs>
                        <TabList>
                            <Tab>Manual</Tab>
                        </TabList>

                        <TabPanel>
                            <Manual />
                        </TabPanel>

                    </Tabs>
                </main>
            </div>
        </div>
    )
}