import { toast } from 'react-toastify';
import moment, {Moment} from 'moment';
import { IAnalysisABC } from "../../../types/Types";
import { useDarkMode } from '../../../hooks/useDarkMode';
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Filters } from '../../../components/Filters/Filters';
import { Loading } from '../../../components/Loading/Loading';
import {isFirefox} from 'react-device-detect';
import TextField from '@mui/material/TextField';
import { BugReportModal } from '../../../components/modal/BugReportModal';

const INPUT_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.6rem)';

export const KPIDay = (props:any) =>{
    document.title = "Análise de Vendas por Dia - MeLiBeat";
    const handleValidateStatus = useHandleErrors();

    const from = moment().startOf('month').format("YYYY-MM-DD");
    const to = moment(from).endOf('month').format('YYYY-MM-DD');
    const processedFilter = {
        dateFrom: from,
        dateTo: to,
        withoutCache: false
    }
    const dateFilter: State<string> = useState<string>(isFirefox ? moment().format("yyyy-MM-DD") : moment().format("yyyy-MM"));

    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const url: State<string> = useState<string>('kpi/skuselldays');
    const fetchResource = (query:any=processedFilter) => axs.get<IAnalysisABC>(url.get(), {params: query}).then((r: any) => {
        handleValidateStatus(r)
        return r.data;
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    })

    const data: State<any> = useState<any>([]);
    const month: State<string> = useState<string>(moment().format("MM"));
    const {DarkMode, } = useDarkMode();

    const checkVisibility = (column: string)=>{
        const storageColumnsData = localStorage.getItem('Análise de Vendas por Dia') ? localStorage.getItem('Análise de Vendas por Dia') : null;
        if(storageColumnsData !== null){
          const localData = JSON.parse(storageColumnsData);
          const shouldHide = localData.find((item: any)=> item.field === column);
          return shouldHide !== undefined ? !shouldHide.visibility : false
        }
        return false
      }

    const options = {
        palette:{
          tableHeader: {
            main: '#F7CE3E',
            dark: '#ac902b',
            light: '#f8d764',
            contrastText: '#000'
          },
        },
        alternate: true,
    }

    const columns: any[] = [
        { 
            headerName: "SKU", 
            field: "sku", 
            type: 'string',
            headerAlign: 'center',
            minWidth: 80,
            hide: checkVisibility('sku'),
            renderCell: (rowData: any) => {
              return (
                rowData.row.sku
              )
            }
        },
        { 
            headerName: "Descrição", 
            field: "title", 
            type: 'string',
            headerAlign: 'center',
            align: 'left',
            flex: 3,
            minWidth: 300,
            hide: checkVisibility('title'),
            renderCell: (rowData: any) => {
              return (
                <Tippy content={
                    <div>{rowData.row.title}</div>
                } maxWidth={'100%'}>
                    <div style={{width: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}} title={rowData.row.title}>
                        {rowData.row.title}
                    </div>
                </Tippy>
              )
            }
        },
        { 
            headerName: "Est. Total", 
            field: "stockTotal", 
            type: 'number',
            headerAlign: 'center',
            minWidth: 80,
            hide: checkVisibility('stockTotal'),
            renderCell: (rowData: any) => {
              return (
                rowData.row.stockTotal
              )
            }
        },
        { 
            headerName: "Est. Matriz", 
            field: "stockAll", 
            type: 'number',
            headerAlign: 'center',
            minWidth: 80,
            hide: checkVisibility('stockAll'),
            renderCell: (rowData: any) => {
              return (
                <Tippy 
                content={Object.entries(rowData.row.stockAllDetail).map((item: any, index: any)=>{
                    return(
                    <div key={index}>{`${item[0]}: ${item[1]}`}</div>
                    )
                })}
                >
                    <div style={{width: 'fit-content'}}>{rowData.row.stockAll}</div>
                </Tippy>
              )
            }
        },
        { 
            headerName: "Est. Full", 
            field: "stockFull", 
            type: 'number',
            headerAlign: 'center',
            minWidth: 80,
            hide: checkVisibility('stockFull'),
            renderCell: (rowData: any) => {
              return (
                <Tippy 
                content={Object.entries(rowData.row.stockFullDetail).map((item: any, index: any)=>{
                    return(
                    <div key={index}>{`${item[0]}: ${item[1]}`}</div>
                    )
                })}
                >
                    <div style={{width: 'fit-content'}}>{rowData.row.stockFull}</div>
                </Tippy>
              )
            }
        },
        { 
            headerName: "Est. ETP", 
            field: "stockETP", 
            type: 'number',
            headerAlign: 'center',
            minWidth: 80,
            hide: checkVisibility('stockETP'),
            renderCell: (rowData: any) => {
              return (
                <Tippy 
                content={Object.entries(rowData.row.stockETPDetail).map((item: any, index: any)=>{
                    return(
                    <div key={index}>{`${item[0]}: ${item[1]}`}</div>
                    )
                })}
                >
                    <div style={{width: 'fit-content'}}>{rowData.row.stockETP}</div>
                </Tippy>
              )
            }
        },
        { 
            headerName: "Custo", 
            field: "cost", 
            type: 'number',
            headerAlign: 'center',
            minWidth: 50,
            hide: checkVisibility('cost'),
            renderCell: (rowData: any) => {
              return (
                rowData.row.cost.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
              )
            }
        },
        { 
            headerName: "Preço Venda", 
            field: "avaregeSelledPrice", 
            type: 'number',
            headerAlign: 'center',
            minWidth: 50,
            hide: checkVisibility('avaregeSelledPrice'),
            renderCell: (rowData: any) => {
              return (
                parseFloat(rowData.row.avaregeSelledPrice).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
              )
            }
        },
        { 
            headerName: "Valor Vendas", 
            field: "totalSelledPrice", 
            type: 'number',
            headerAlign: 'center',
            minWidth: 50,
            hide: checkVisibility('totalSelledPrice'),
            renderCell: (rowData: any) => {
              return (
                rowData.row.totalSelledPrice.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
              )
            }
        },
        { 
            headerName: "Vendas", 
            field: "sells", 
            type: 'number',
            headerAlign: 'center',
            minWidth: 50,
            hide: checkVisibility('sells'),
            renderCell: (rowData: any) => {
              return (
                <Tippy content={
                    <div>Un de Vendas Canceladas: {rowData.row.sellsCancelled}</div>
                } maxWidth={'100%'}>
                    <div>
                        {rowData.row.sells}
                    </div>
                </Tippy>
              )
            }
        },
        {
            headerName: "Média", 
            field: "avaregeSells", 
            type: 'number',
            headerAlign: 'center',
            minWidth: 50,
            hide: checkVisibility('avaregeSells'),
            renderCell: (rowData: any) => {
              return (
                rowData.row.avaregeSells.toFixed(2)
              )
            }
        },
        {
            headerName: "Média Pond.", 
            field: "avaregeSellsFiltered", 
            type: 'number',
            headerAlign: 'center',
            minWidth: 50,
            hide: checkVisibility('avaregeSellsFiltered'),
            renderCell: (rowData: any) => {
              return (
                rowData.row.avaregeSellsFiltered ? rowData.row.avaregeSellsFiltered.toFixed(2) : 'N/A'
              )
            }
        },
        {
            headerName: "Dur. Estoque", 
            field: "stockTotalDuration", 
            type: 'number',
            headerAlign: 'center',
            minWidth: 50,
            hide: checkVisibility('stockTotalDuration'),
            renderCell: (rowData: any) => {
              return (
                <Tippy 
                content={
                    <>
                        <div>{`Duração Estoque Matriz: ${rowData.row.stockAllDuration}`}</div>
                        <div>{`Duração Estoque Full: ${rowData.row.stockFullDuration}`}</div>
                    </>
                }
                >
                    <div style={{width: 'fit-content'}}>{rowData.row.stockTotalDuration}</div>
                </Tippy>
              )
            }
        },
        {
            headerName: "PM", 
            field: "pm", 
            type: 'string',
            headerAlign: 'center',
            minWidth: 100,
            hide: checkVisibility('pm'),
            renderCell: (rowData: any) => {
              return (
                rowData.row.pm
              )
            }
        },
    ]

    for(let i = 1; i<= moment().daysInMonth(); i++){
      columns.push({
        headerName: `${i.toString().padStart(2,'0')}/${moment().format('MM')}`, 
        field: `sellsPerDay['${i}']`, 
        type: 'number',
        headerAlign: 'center',
        minWidth: 50,
        renderCell: (rowData: any) => {
          return (
            rowData.row.sellsPerDay[i]
          )
        }
      })
    }

    useEffect(() => {
        data.set(fetchResource())
    }, [])

    const handleFilter = (filters: any)=>{
        toast.info('Filtrando...')
        const updatedFilters:any = {};
        updatedFilters["dateFrom"] = `${filters.filterDate}-01`;
        updatedFilters["dateTo"] = moment(filters.filterDate).endOf('month').format('YYYY-MM-DD')
        updatedFilters["withoutCache"] = false;
        month.set(moment(filters.filterDate).format('MM'))
        data.set(fetchResource(updatedFilters))
    }

    const handleFullReset = ()=>{
        toast.info("Resetando os dados sem o cache!");
        const from = moment().startOf('month').format("YYYY-MM-DD");
        const to = moment(from).endOf('month').format('YYYY-MM-DD');
        const processedFilter = {
            dateFrom: from,
            dateTo: to,
            withoutCache: true
        }
        month.set(moment().format('MM'))
        dateFilter.set(isFirefox ? moment().format("yyyy-MM-DD") : moment().format("yyyy-MM"))
        data.set(fetchResource(processedFilter));
        url.set('kpi/skuselldays')
    }

    const handleReset = ()=> {
        const from = moment().startOf('month').format("YYYY-MM-DD");
        const to = moment(from).endOf('month').format('YYYY-MM-DD');
        const processedFilter = {
          dateFrom: from,
          dateTo: to,
          withoutCache: false
        }
        month.set(moment().format('MM'))
        dateFilter.set(isFirefox ? moment().format("yyyy-MM-DD") : moment().format("yyyy-MM"))
        data.set(fetchResource(processedFilter))
        url.set('kpi/skuselldays')
    }


    return(
      <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
        <BugReportModal />
        <div className="content-wrapper">
            <main className="main-content container-fluid">
              <Filters submitFunction={handleFilter} handleReset={handleReset} handleFullReset={handleFullReset}>
                <FormControl size='small' variant='outlined' sx={{minWidth: '20rem', width: '100%'}}>
                  <InputLabel id="typeURL-label" style={{fontSize: INPUT_FONT_SIZE}}>Agrupamento</InputLabel>
                  <Select
                      required
                      labelId="typeURL-label"
                      id="typeURL"
                      value={url.get()}
                      label="Agrupamento"
                      name='filterTypeURL'
                      onChange={(evt: SelectChangeEvent)=>{
                          url.set(evt.target.value)
                      }}
                      style={{fontSize: INPUT_FONT_SIZE}}
                  >
                    <MenuItem value={'kpi/skuselldays'}>SKU</MenuItem>
                    <MenuItem value={'kpi/groupsellday'}>Grupos</MenuItem>
                  </Select>
              </FormControl>

                <TextField size="small" required name="filterDate" type={isFirefox ? 'date' : "month"} id="closing_date" InputLabelProps={{ shrink: true }} label="Data" variant="outlined"
                    sx={{minWidth: '20rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                    value={dateFilter.get()}
                    onChange={(evt: any)=> isFirefox ? dateFilter.set(moment(evt.target.value).format("yyyy-MM-DD")) : dateFilter.set(moment(evt.target.value).format("yyyy-MM"))}
                />
              </ Filters>
              {
                data.promised ? 
                <Loading />
                : data.error ?
                <ErrorScreen /> : 
                data ?
                <VirtualizedGrid 
                    rows={data.attach(Downgraded).get()}
                    columns={columns}
                    options={options}
                    title="Análise de Vendas por Dia"
                    defaultSort={{field: 'sells', direction: 'desc'}}
                    initialState={{ pinnedColumns: { left: ['sku', 'title', 'stockTotal', 'stockAll', 'stockFull', 'stockETP', 'cost', 'avaregeSelledPrice', 'totalSelledPrice', 'sells', 'avaregeSells', 'avaregeSellsFiltered', 'stockTotalDuration', 'pm']} }}
                />
                :
                null
              }
            </main>
        </div>
      </div>
    )
}