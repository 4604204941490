import errorImg from '../../assets/images/error_screen.svg'

export const ErrorScreen = (props:any) =>{
    return(
        <div style={{width: '100%', height: '100%', display: 'flex',
            flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
            animation: "slide .3s ease-in-out",
            background: 'linear-gradient(90deg, rgb(255, 242, 55) 0%, rgba(255, 198, 11, 0.856) 100%)'
        }}>
            <div className="img-container" style={{width: '40rem', height: '40rem'}}>
                <img src={errorImg} alt="Imagem de erro." />
            </div>
            <h1>Ocorreu um erro...</h1>
            <button onClick={() => window.location.reload()} style={{fontSize: 'clamp(1.6rem, 1.5vw, 1.8rem)', padding: '1rem', marginTop: '1rem', borderRadius: '4px', color: '#fff', backgroundColor: '#000'}}>Recarregar Tela</button>
        </div>
)
}