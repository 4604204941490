import { useBugReport } from '../../hooks/useBugReport';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@mui/material/Button';
import BugReportImg from '../../assets/images/bug-report.svg'
import './style.scss';

const INPUT_FONT_SIZE = 'clamp(1.4rem, 1.5vw, 1.8rem)';

export const BugReportModal = (props: any) =>{
    const {BugReport, activateBugReport} = useBugReport();


    const handleClickOutside = (evt: React.BaseSyntheticEvent) =>{
        const elmClass = evt.target.getAttribute("class")
        const classesThatClose = ["modal-wrapper"]
        if(classesThatClose.includes(elmClass)){
            activateBugReport({
                reportData: {
                    "page": '',
                    "module": '',
                    "date": '',
                    "userEmail": '',
                    "path": ''
                },
                active: false
            })
        }else{
            return
        }
    }

    const handleCancel = (evt: React.BaseSyntheticEvent)=>{
        evt.preventDefault();
        activateBugReport({
            reportData: {
                "page": '',
                "module": '',
                "date": '',
                "userEmail": '',
                "path": ''
            },
            active: false
        })
    }

    return (
        <form onSubmit={(evt: any)=> console.log("enviou")} className="modal-wrapper" style={{display: BugReport?.active ? 'flex':'none'}} onClick={handleClickOutside}>
            <Paper elevation={3} sx={{width: '80%', height: '80%', padding: '2rem', display: 'flex', flexDirection: 'column', gap: '4rem'}}>
                <Box sx={{width: '100%', height:'15%', padding: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'wrap'}}>
                    <h2>Parece que há algo errado...</h2>
                    <div className="img-container" style={{width: '10rem'}}>
                        <img src={BugReportImg} alt="Imagem de erro." />
                    </div>
                </Box>
                <Box sx={{width: '100%', height:'fit-content', padding: '1rem', display: 'flex', justifyContent: 'flex-start', gap: '2rem', flexWrap: 'wrap', fontSize: 'clamp(1.8rem, 2vw, 2.2rem)'}}>
                    <Paper elevation={2} sx={{padding: '2rem'}}>
                        <span className="confirm-modal-title">Erro na tela: <strong>{BugReport?.reportData.page ? BugReport?.reportData.page : "N/A"}</strong></span>
                    </Paper>
                    <Paper elevation={2} sx={{padding: '2rem'}}>
                        <span className="confirm-modal-title">Módulo: <strong>{BugReport?.reportData.module ? BugReport?.reportData.module : "N/A"}</strong></span>
                    </Paper>
                    <Paper elevation={2} sx={{padding: '2rem'}}>
                        <span className="confirm-modal-title">Data: <strong>{BugReport?.reportData.date ? BugReport?.reportData.date : "N/A"}</strong></span>
                    </Paper>
                    <Paper elevation={2} sx={{padding: '2rem'}}>
                        <span className="confirm-modal-title">Usuário: <strong>{BugReport?.reportData.userEmail ? BugReport?.reportData.userEmail : "N/A"}</strong></span>
                    </Paper>
                </Box>
                <Box sx={{width: '100%', padding: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '2rem'}}>
                    <h2>Fale mais sobre o problema.</h2>
                    <Paper elevation={2} sx={{padding: '2rem', width: '100%',  display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '2rem'}}>
                        <TextField type='text' required placeholder="Descreva seu problema..." variant="outlined"
                            multiline minRows={8} maxRows={8}
                            sx={{width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                        />
                        <TextField type="file" variant="standard" helperText="Adicione um print da tela caso também caso desejar." color="primary"
                            sx={{width: 'fit-content', fontSize: INPUT_FONT_SIZE, '& .MuiInputBase-input ': {fontSize: INPUT_FONT_SIZE}, '& .MuiFormHelperText-root':{fontSize: INPUT_FONT_SIZE}}}
                        />
                    </Paper>
                    <Box sx={{alignSelf: 'flex-start', display: 'flex', gap: '2rem'}}>
                        <Button size='small' type="submit" variant="contained" color='primary' sx={{minWidth: 'fit-content', fontSize: INPUT_FONT_SIZE, '& .MuiButton-startIcon':{margin: '0'}}}>
                            Reportar Bug
                        </Button>
                        <Button size='small' type="reset" variant="outlined" color='error' sx={{minWidth: 'fit-content', fontSize: INPUT_FONT_SIZE, '& .MuiButton-startIcon':{margin: '0'}}} onClick={handleCancel}>
                            Cancelar
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </form>
    )
}