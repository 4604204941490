import { createContext, useState, ReactNode } from 'react';

type DarkModeContextType = {
    DarkMode: boolean;
    activateDarkMode: (darkMode:boolean) => void;

}

type DarkModeContextProps = {
    children: ReactNode
}

export const DarkModeContext = createContext({} as DarkModeContextType);

export function DarkModeContextProvider(props: DarkModeContextProps) {
    const darkmodeLS = localStorage.getItem('darkmode') ? localStorage.getItem('darkmode') : '0';
    const [DarkMode, setDarkMode] = useState<boolean>(darkmodeLS === '1' ? true:false);

    function activateDarkMode(DarkMode: boolean){
        localStorage.setItem('darkmode', DarkMode ? '1':'0');
        setDarkMode(DarkMode);
    }

    return (
        <DarkModeContext.Provider value={{ DarkMode, activateDarkMode }}>
            {props.children}
        </DarkModeContext.Provider>
    )
}