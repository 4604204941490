import { columns, options } from '../../../components/table/files/ManagementLcreData';
import { columns as modalColumns, options as modalOptions } from '../../../components/table/files/LCREData';

import { toast } from 'react-toastify';
import moment from 'moment';
import { ModalConfirm } from '../../../components/modal/ModalConfirm';
import { GridModal } from '../../../components/modal/GridModal';
import { LineChart } from '../../../components/Charts/LineChart';
import { Filters } from '../../../components/Filters/Filters';
import { useDarkMode } from '../../../hooks/useDarkMode';
import { useModal } from '../../../hooks/useModal';

import { ILCREOrders, IManagementLCRE, ObjetoVazio } from "../../../types/Types"
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { Untracked } from '@hookstate/untracked';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import AddIcon from '@mui/icons-material/Add';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';

import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {isFirefox} from 'react-device-detect';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Loading } from '../../../components/Loading/Loading';
import { BugReportModal } from '../../../components/modal/BugReportModal';

interface newData{
    "closing_date": string,
    'tax_aliquot': string | number,
    'origin': string
}
const BUTTON_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.4rem)';
const INPUT_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.6rem)';

export const ManagementLcre = () =>{
    document.title = "Gestão De Fechamentos LCRE - MeLiBeat";
    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })

    const handleValidateStatus = useHandleErrors();

    const fetchResource = (query:any=undefined) => axs.get<IManagementLCRE[]>('/lcre/', {params: query}).then((r: any) => {
        handleValidateStatus(r)
        const completeData = r.data.map((item: IManagementLCRE)=>{
            const processedData = {
                'date_start': item.date_start,
                'origin': item.origin,
                'tax_aliquot':  item.tax_aliquot,
                '_id': item._id,
                ...item.result
            };
            return processedData;
        })
        return completeData.sort((a:any, b:any) => a.date_start.localeCompare(b.date_start))
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    })


    const data: State<IManagementLCRE[]> = useState<IManagementLCRE[]>([]);


    const modalData: State<ILCREOrders[]> = useState<ILCREOrders[]>([]);
    const modalTableTitle: State<string> = useState<string>('');
    const chartLabel: State<string[]> = useState<string[]>([]);
    const chartDataset: State<object[]> = useState<object[]>([]);
    chartLabel.attach(Untracked);
    chartDataset.attach(Untracked);
    const viewModal: State<boolean> = useState<boolean>(false);
    // const [filters, isFiltering, isReseting, executeSetNewFilter, executeFilter, executeStopFilter, executeResetFilter] = useFilter();
    // const [executeGet, executePost, , executeDelete, result, accessDenied] = useRequest();
    const newData: State<newData> = useState<newData>({"tax_aliquot": '', "closing_date": '', "origin": ''})
    const {DarkMode, } = useDarkMode();
    const filterValues: ObjetoVazio = useState<ObjetoVazio>({"origin": undefined})
    const {showHideModal} = useModal();
    let ChartColors = ['#00a8ff', '#9c88ff', '#fbc531', '#4cd137', '#40739e', '#e84118', '#7f8fa6', '#2f3640', '#10ac84', '#01a3a4', '#f368e0'];
    const LCRETranslations: any = {
        'total_shipping_fee': 'Custo Frete', 
        'total_cost_tax': 'Custo Impostos',
        'total_cost_product': 'Custo Produtos', 
        'total_sale_fee': 'Custo Taxas ML',
        'total_sales': 'Total Vendas', 
        'total_gross_profit': 'Lucro Bruto',
        'total_profit': 'Lucro Liquido',
        'total_profit_margin': 'Margem de Lucro',
        'total_orders': 'Total Pedidos',
        'total_sales_cancelled': 'Total Vendas Canceladas'
    }

    const defaultTheme  = createTheme({
        palette:{
          mode: DarkMode ? 'dark' : 'light',
        },
    });

    useEffect(() => {
        data.set(fetchResource({'origin': 'all'}))
      }, [])

    const handleView = async(options: IManagementLCRE) =>{
        try{
            await axs.get<IManagementLCRE[]>(`/lcre/${options._id}`).then((r: any) => {
                if(r.status === 200){
                    const resData = r.data;
                    modalData.set(processData(resData.orders));
                    modalTableTitle.set(`Gestão de Fechamentos LCRE - ${moment(resData.date_start).format('MM-YYYY')}`);
                }
            }).catch((err: any)=>{
                handleValidateStatus(err.response)
            })
        }
        catch(err){
            toast.error("Ocorreu um erro ao carregar a Tabela!");
        }

    }

    
    const processData = (values: any)=>{
        const processedArray = values.map((item: ILCREOrders)=>{
            let tags:string[] = []
            if(item.deal) tags.push('deal');
            if(item.rebate_campaign > 0) tags.push('rebate_campaign');
            if(item.logistic_type === "fulfillment") tags.push('fulfillment');
            if(item.mediation_charged) tags.push('mediation_charged');

            item['tags'] = tags
            return item
        })
        return processedArray
    }

    const handleChart = (values: any) =>{
        let available_months = values.map((month: any)=> moment(month.date_start).format("YYYY-MM-DD"))
        let min_availale_month = available_months.sort((a:any, b:any) => a.localeCompare(b))[0]

        let min_month = parseInt(moment(min_availale_month).format("MM"))
        const previous_months: any = [moment(min_availale_month).subtract(1, 'months').startOf('month').format("YYYY-MM-DD")];
        for(let i=0; i <= min_month; i++){
            const last_created_month = moment(previous_months[previous_months.length-1]).subtract(1, 'months').startOf('month').format("YYYY-MM-DD")
            previous_months.push(last_created_month)
        }

        let all_months = [...previous_months, ...available_months].sort((a:any, b:any) => a.localeCompare(b))
        all_months = all_months.slice(2, all_months.length)
        let datasetUnprocessed: any = {};


        for(let month of all_months){
            if(!available_months.includes(month)){
                datasetUnprocessed[month] = {
                    'date_start': 0,
                    'total_sales': 0,
                    'total_cost_product': 0,
                    'total_cost_tax': 0,
                    'total_gross_profit': 0,
                    'total_orders': 0,
                    'total_profit': 0,
                    'total_profit_margin': 0,
                    'total_sale_fee': 0,
                    'total_sales_cancelled': 0,
                    'total_shipping_fee': 0,
                }
            }else{
                const month_result = values.filter((value: any)=> moment(value.date_start).format("YYYY-MM-DD") === month);
                const result = month_result[0];
                datasetUnprocessed[month] = {
                    'date_start': result.date_start,
                    'total_sales': result.total_sales,
                    'total_cost_product': result.total_cost_product,
                    'total_cost_tax': result.total_cost_tax,
                    'total_gross_profit': result.total_gross_profit,
                    'total_orders': result.total_orders,
                    'total_profit': result.total_profit,
                    'total_profit_margin': result.total_profit_margin * 100,
                    'total_sale_fee': result.total_sale_fee,
                    'total_sales_cancelled': result.total_sales_cancelled,
                    'total_shipping_fee': result.total_shipping_fee,
                }
            }
        }
        

        let dataset: any = [];
        let label: any = [];
        let inner_label = [
            'total_sales',
            'total_cost_product',
            'total_cost_tax',
            'total_gross_profit',
            'total_orders',
            'total_profit',
            'total_profit_margin',
            'total_sale_fee',
            'total_sales_cancelled',
            'total_shipping_fee'
        ]
        Object.keys(datasetUnprocessed).forEach((item: string, index: any)=>{
            label.push(moment(item).startOf('month').format('MM-YYYY'))
        })

        inner_label.forEach((item: any, index: number)=>{
            const dataProcessed = Object.keys(datasetUnprocessed).map((value: any)=>{
                return datasetUnprocessed[value][item];
            });
            dataset.push({
                label: LCRETranslations[item],
                data: dataProcessed,
                tension: 0.1,
                borderColor: ChartColors[index],
                backgroundColor: ChartColors[index],
                hidden: (item === 'total_sales' ? false : true)
            })
        })
        Untracked(chartLabel).set([label])
        Untracked(chartDataset).set(dataset)
    }

    const handleAdd = async(evt: React.BaseSyntheticEvent) =>{
        evt.preventDefault();
        evt.target.reset()
        toast.info("Adicionando...");
        let allowAdd = true;
        const processedData = {
            date_start: moment(newData.closing_date.value).startOf('month').format('YYYY-MM-DD'),
            date_end: moment(newData.closing_date.value).endOf('month').format('YYYY-MM-DD'),
            tax_aliquot: newData.tax_aliquot.value,
            origin: newData.origin.value
        }
        for(let line of data.attach(Downgraded).get()){
            if(processedData.date_start === moment(line.date_start).format('YYYY-MM-DD') && processedData.origin === line.origin){
                allowAdd = false;
            }
        }
        if(allowAdd){
            await axs.post<IManagementLCRE[]>(`/lcre/`, processedData).then((r: any) => {
                if(r.status === 200){
                    toast.success("Fechamento Adicionado!");
                    data.set(fetchResource({'origin': 'all'}))
                }
            }).catch((err: any)=>{
                handleValidateStatus(err.response)
            })
        }
    }


    const confirmDelete = async(value: boolean, id: string)=>{
        if(value){
            await axs.delete<IManagementLCRE[]>(`/lcre/${id}`).then((r: any) => {
                if(r.status === 200){
                    toast.success("Linha exluída!");
                    data.set(fetchResource({'origin': 'all'}))
                }
            }).catch((err: any)=>{
                handleValidateStatus(err.response)
            })
        }else{
            toast.info("Você escolheu não deletar!");
        }
    }

    const handleFilter = (filters: any)=>{
        toast.info('Filtrando...');
        data.set(fetchResource({'origin': filters.filterOrigin}))
        filterValues.merge({'origin': filters.filterOrigin})
    }

    const handleReset = ()=> {
        data.set(fetchResource({'origin': 'all'}))
        filterValues.merge({'origin': undefined})
    };


    data.batch((data) => {
        if (data.promised) return
        if (data.error) return
        else{
            const values = data.attach(Downgraded).get();
            handleChart(values)
        }
    })


    return(
        <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
            {
                viewModal.get() === true
                ?<GridModal 
                    closeFunction={()=> viewModal.set(false)} 
                    tableTitle={modalTableTitle.get()} 
                    options={modalOptions} 
                    columns={modalColumns} 
                    data={modalData.attach(Downgraded).get()}
                    style={{width: '100%'}}
                    defaultSort={{field: 'date_approved', direction: 'desc'}}
                    modalWrapperStyle={{alignItems: 'unset'}}
                />
                : null
            }
            <ModalConfirm />
            <BugReportModal />
            <div className="content-wrapper">
                <main className="main-content container-fluid">
                    <Filters submitFunction={handleFilter} hideFullResetBtn handleReset={handleReset}>
                        <FormControl size='small' variant='outlined' sx={{minWidth: '20rem'}} key={filterValues.origin.get()}>
                            <InputLabel id="origin-label-filter" style={{fontSize: INPUT_FONT_SIZE}}>Origem</InputLabel>
                            <Select
                                labelId="origin-label-filter"
                                id="origin-filter"
                                name='filterOrigin'
                                value={filterValues.origin.get()}
                                label="Origem"
                                style={{fontSize: INPUT_FONT_SIZE}}
                                required
                                onChange={(evt: SelectChangeEvent)=> filterValues.origin.set(evt.target.value)}
                            >
                            <MenuItem value={'all'}>Todos</MenuItem>
                            <MenuItem value={'TC'}>TechWheel</MenuItem>
                            <MenuItem value={'RM'}>RiseMode</MenuItem>
                            <MenuItem value={'UG'}>UpGamer</MenuItem>
                            <MenuItem value={'MM'}>MarMelada</MenuItem>
                            <MenuItem value={'JJ'}>JuJuca</MenuItem>
                            </Select>
                        </FormControl>
                    </ Filters>
                    <ThemeProvider theme={defaultTheme}>
                        <Box component="form" onSubmit={(evt: React.BaseSyntheticEvent)=> handleAdd(evt)}
                        style={{width: "100%"}}
                        >
                            <Paper elevation={3}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    gap: '1rem',
                                    margin: 0,
                                    padding: '3rem'
                                }}
                            >
                                <TextField type={isFirefox ? 'date' : "month"} required id="closing_date" InputLabelProps={{ shrink: true }} label="Data" variant="outlined" onChange={(evt: any)=> newData.closing_date.set(evt.target.value)} 
                                    sx={{minWidth: '20rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                />

                                <TextField type='number' inputProps={{step: "0.1"}} required id="tax-aliquot" label="Aliquota de Imposto" variant="outlined" onChange={(evt: any)=> newData.tax_aliquot.set(evt.target.value)} 
                                    sx={{minWidth: '20rem', width: '100%', fontSize: INPUT_FONT_SIZE, '& .MuiOutlinedInput-root': {fontSize: INPUT_FONT_SIZE}, '& label':{fontSize: INPUT_FONT_SIZE}}}
                                />
                                <FormControl variant='outlined' sx={{minWidth: '20rem', width: '100%'}}>
                                    <InputLabel id="origin-label" style={{fontSize: INPUT_FONT_SIZE}}>Origem</InputLabel>
                                    <Select
                                        required
                                        labelId="origin-label"
                                        id="origin"
                                        value={filterValues.origin.get()}
                                        label="Origem"
                                        onChange={(evt: SelectChangeEvent)=>{
                                            newData.origin.set(evt.target.value)
                                        }}
                                        style={{fontSize: INPUT_FONT_SIZE}}
                                    >
                                    <MenuItem value={'all'}>Todos</MenuItem>
                                    <MenuItem value={'TC'}>TechWheel</MenuItem>
                                    <MenuItem value={'RM'}>RiseMode</MenuItem>
                                    <MenuItem value={'UG'}>UpGamer</MenuItem>
                                    <MenuItem value={'MM'}>MarMelada</MenuItem>
                                    <MenuItem value={'JJ'}>JuJuca</MenuItem>
                                    </Select>
                                </FormControl>
                                <Button variant="contained" color='primary' startIcon={<AddIcon />} sx={{fontSize: BUTTON_FONT_SIZE, width: '100%', maxWidth: 'fit-content', '& .MuiButton-startIcon':{margin: '0'}}} type='submit'>
                                    Adicionar Fechamento
                                </Button>
                            </Paper>
                        </Box>
                    </ ThemeProvider>
                    {
                        data.promised ? 
                        <Loading />
                        : data.error ?
                        <ErrorScreen /> : 
                        chartLabel.get() === undefined || chartLabel.length === 0 || chartDataset.length === 0 || data.length === 0
                        ? 
                        null :
                        <>
                            <LineChart labels={chartLabel.attach(Downgraded).get()} dataset={chartDataset.attach(Downgraded).get()} />
                            <VirtualizedGrid 
                                columns={columns} 
                                rows={data.attach(Downgraded).get()} 
                                options={options}
                                title="Gestão de Fechamentos LCRE" 
                                actions={[
                                    {icon: 'visibility', title: 'Visualizar Item', method: (evt: any, row:any)=> handleView(row).then(_ => {viewModal.set(true)})},
                                    {icon: 'delete', title: 'Deletar Item', method: (evt: any, row:any)=> showHideModal({show: true, title: "Quer mesmo deletar esse item?", execute: (confirm: boolean)=> confirmDelete(confirm, row._id)})}, 
                                ]} 
                                defaultSort={{field: 'date_start', direction: 'desc'}}
                            />
                        </>
                    }
                </main>
            </div>
        </div>
    )
}