import { BrowserRouter as Route, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import { ContentAccordion } from '../../../components/accordion/ContentAccordion';
import { toast } from 'react-toastify';
import { SideMenu } from '../../../components/sideMenu/SideMenu';
import { Header } from '../../../components/Header/Header';
import { ModalConfirm } from '../../../components/modal/ModalConfirm';
import { useModal } from '../../../hooks/useModal';
import { useDarkMode } from '../../../hooks/useDarkMode';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import './style.scss';
import { Untracked } from '@hookstate/untracked';
import { 
    ProductsSerialNumbers,
    ISerialNumbers,
    ObjetoVazio,
} from "../../../types/Types";
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { BugReportModal } from '../../../components/modal/BugReportModal';

export const SerialNumberCompras = () =>{
    document.title = "Número de série Compras - MeLiBeat";
    const handleValidateStatus = useHandleErrors();

    let { id } = useParams<any>();
    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const fetchResource = (query:any=undefined) => axs.get<ISerialNumbers>(`/purchasetracking/serialnumber/${id}`, {params: query}).then((r: any) => {
        handleValidateStatus(r)
        return r.data
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    })
    const data: State<ISerialNumbers> = useState<ISerialNumbers>({
        "carrying": null,
        "chaveAcesso": null,
        "cnpjCarrying": null,
        "cnpjSender": null,
        "danfe": null,
        "date": null,
        "delivered": false,
        "deliveredDate": null,
        "finished": false,
        "finishedDate": null,
        "finishedEntrySerial": false,
        "finishedEntrySerialDate": null,
        "grossWeight": null,
        "netWeight": null,
        "number": null,
        "observations": null,
        "products": [],
        "sender": null,
        "senderAddress": {
            "cep": null,
            "city": null,
            "district": null,
            "number": null,
            "street": null,
            "uf": null
        },
        "serie": null,
        "totalValue": null,
        "trackLinkCarrying": null,
        "volume": null,
        "xml": null,
        "__v": null,
        "_id": ''
    });
    data.attach(Untracked)
    const sender: State<string | null> = useState<string | null>("");
    sender.attach(Untracked)
    const numberNF: State<string | null> = useState<string | null>("");
    numberNF.attach(Untracked)
    const volume: State<number | null> = useState<number | null>(0);
    volume.attach(Untracked)
    const finishedEntrySerial: State<boolean | undefined> = useState<boolean | undefined>(false);
    finishedEntrySerial.attach(Untracked)
    const idsToDisable: State<ObjetoVazio> = useState<ObjetoVazio>({});
    idsToDisable.attach(Untracked)
    const quantityInserted: State<ObjetoVazio> = useState<ObjetoVazio>({});
    quantityInserted.attach(Untracked)
    const serialNumber: State<ObjetoVazio> = useState<ObjetoVazio>({});
    serialNumber.attach(Untracked)
    const disableFinish: State<boolean> = useState<boolean>(true);
    disableFinish.attach(Untracked)
    const saveClicked: State<boolean> = useState<boolean>(true);
    saveClicked.attach(Untracked)
    const renderControlState : State<string> = useState<string>('');
    const history = useHistory();
    const {DarkMode, } = useDarkMode();

    const {ModalData, showHideModal} = useModal();

    useEffect(() => {
        data.set(fetchResource())
    }, [])

    useEffect(() => {
        if (data.promised) return
        if (data.error) return
        else{
            data.batch((data) => {
                const raw_data = data.attach(Downgraded).get();
                if(raw_data.finishedEntrySerial){
                    toast.error("Não é possível editar um item já finalizado.");
                    history.push(`/compras/numeros-de-serie/`)
                }
                sender.set(raw_data.sender);
                numberNF.set(raw_data.number);
                volume.set(raw_data.volume);
                finishedEntrySerial.set(raw_data.finishedEntrySerial)
    
                let qtyInsertedTemp: any = quantityInserted.get() ? quantityInserted.attach(Downgraded).get() : {};;
                let idsDisableTemp: any = idsToDisable.get() ? idsToDisable.attach(Downgraded).get() : {};
                let serialNumberTemp: any = serialNumber.get() ? serialNumber.attach(Downgraded).get() : {};;
                let disableFinishTemp: any = null;
    
                for(let product of raw_data.products){
                    if(product.serialNumber){
                        if(product.serialNumber === "N/A"){
                            qtyInsertedTemp[product._id] = product.qty;
                            idsDisableTemp[product._id] = true
                            
                            serialNumberTemp[product._id] = {'_id': product._id, serialNumber: product.serialNumber.replaceAll('\n', ' ').trimEnd()};
                            if(serialNumberTemp !== {} && raw_data.products.length > 0){
                                const shouldDisable = data.products.length !== Object.keys(serialNumberTemp).length ? true : false;
                                disableFinishTemp = shouldDisable;
                            }
                        }else{
                            const valuesInserted = product.serialNumber.split(" ").length;
                            qtyInsertedTemp[product._id] = valuesInserted;
                            serialNumberTemp[product._id] = {'_id': product._id, serialNumber: product.serialNumber.replaceAll('\n', ' ').trimEnd()};
    
                            if(serialNumberTemp !== {} && raw_data.products.length > 0){
                                const shouldDisable = data.products.length !== Object.keys(serialNumberTemp).length ? true : false;
                                disableFinishTemp = shouldDisable;
                            }
                        }
                    }
                }
                quantityInserted.merge({...qtyInsertedTemp});
                idsToDisable.merge({...idsDisableTemp});
                serialNumber.merge({...serialNumberTemp});
                disableFinish.set(disableFinishTemp)
            })
        }
    }, [data.promised])

    const handleCheckBox = (confirm: boolean, checked: boolean, new_id: string, qty: number | null) =>{
        if(confirm){
            const raw_data = data.attach(Downgraded).get();
            const temp = idsToDisable.attach(Downgraded).get();
            temp[new_id] = checked;
            idsToDisable.merge({...temp});
            saveClicked.set(false)
            if(checked){
                for(let product of raw_data.products){
                    if(new_id === product._id){
                        if(product.serialNumber){
                            product.serialNumber = ''
                        }
                    }
                }

                const temp2 = quantityInserted.attach(Downgraded).get();
                temp2[new_id] = qty;
                quantityInserted.merge({...temp2});
    
                const temp3 = serialNumber.attach(Downgraded).get();
                temp3[new_id] = {'_id': new_id, 'serialNumber': 'N/A'};
                serialNumber.merge({...temp3});
                if(temp3 !== {} && raw_data.products.length > 0){
                    const shouldDisable = data.products.length !== Object.keys(temp3).length ? true : false;
                    disableFinish.set(shouldDisable);
                }
            }else{
                const temp2 = quantityInserted.attach(Downgraded).get();
                temp2[new_id] = 0;
                quantityInserted.merge({...temp2});

                const temp3 = serialNumber.attach(Downgraded).get();
                delete temp3[new_id];
                serialNumber.merge({...temp3});
                if(temp3 !== {} && raw_data.products.length > 0){
                    const shouldDisable = data.products.length !== Object.keys(temp3).length ? true : false;
                    disableFinish.set(shouldDisable);
                }
            }
        }else{
            return
        }
    }

    const handleSave = ()=>{
        if(serialNumber.get() !== undefined && serialNumber.get() !== {}){
            const raw_serial_numbers = serialNumber.attach(Downgraded).get();
            const serial_numbers = Object.values(raw_serial_numbers);
            axs.put<ProductsSerialNumbers[]>(`/purchasetracking/serialnumber/${id}`, {items: serial_numbers}).then((r: any) => {
                if(r.status === 200){
                    toast.success("Dados salvos.");
                    saveClicked.set(true)
                    sender.set(r.data.sender);
                    numberNF.set(r.data.number);
                    volume.set(r.data.volume);
                    finishedEntrySerial.set(r.data.finishedEntrySerial);
                    for(let product of r.data.products){
                        if(product.serialNumber){
                            if(product.serialNumber === "N/A"){
                                const temp = quantityInserted.attach(Downgraded).get();
                                temp[product._id] = product.qty;
                                quantityInserted.set({...temp});
                                const temp2 = idsToDisable.attach(Downgraded).get();
                                temp2[product._id] = true;
                                Untracked(idsToDisable).set({...temp2});
                            }else{
                                const valuesInserted = product.serialNumber.split(" ").length;
                                const temp = quantityInserted.attach(Downgraded).get();
                                temp[product._id] = valuesInserted;
                                Untracked(quantityInserted).set({...temp});
    
                                const temp2 = serialNumber.attach(Downgraded).get();
                                temp2[product._id] = {'_id': product._id, serialNumber: product.serialNumber.replaceAll('\n', ' ').trimEnd()};
                                Untracked(serialNumber).merge({...temp2});
                                if(temp2 !== {} && data.products.length > 0){
                                    const shouldDisable = data.products.length !== Object.keys(temp2).length ? true : false;
                                    Untracked(disableFinish).set(shouldDisable);
                                }
                            }
                        }
                    }
                    Untracked(data).set(r.data)
                }else{
                    toast.success("Ocorreu um erro ao tentar salvar os dados.");
                }
            }).catch((err: any)=>{
                handleValidateStatus(err.response)
            })
        }else{
            toast.info("Adicione informações antes de salvar.");
        }
        renderControlState.set(Math.random().toString())
    }

    const executeFinish = (confirm: boolean, codesWithError: string) =>{
        if(confirm){
            axs.put<ProductsSerialNumbers[]>(`/purchasetracking/serialnumber/finished/${id}`).then((r: any) => {
                if(r.status === 200){
                    toast.success("Finalizado!");
                    history.push(`/compras/numeros-de-serie/`)
                }else{
                    toast.error("Ocorreu um erro ao tentar finalizar.");
                }
            }).catch((err: any)=>{
                handleValidateStatus(err.response)
            })
        }else{
            toast.info(`Os itens com os códigos: ${codesWithError} possuem a quantidade de entrada menor que a quantidade da nota.`, {autoClose: 5000});
            renderControlState.set(Math.random().toString())
        }
    }

    const verifySaveOFinish = (confirm: boolean, isLess: boolean, codesWithError: string) =>{
        if(confirm){
            if(isLess){
                codesWithError = codesWithError.substring(0, codesWithError.length-2)
                showHideModal({show: true, title: "Alguns itens possuem a quantidade de entrada menor que a quantidade da nota. Gostaria de continuar mesmo assim?", execute: (confirm: boolean)=> {
                    executeFinish(confirm, codesWithError)
                }})
            }else{
                axs.put<ProductsSerialNumbers[]>(`/purchasetracking/serialnumber/finished/${id}`).then((r: any) => {
                    if(r.status === 200){
                        toast.success("Finalizado!");
                        history.push(`/compras/numeros-de-serie/`)
                    }else{
                        toast.error("Ocorreu um erro ao tentar finalizar...");
                        renderControlState.set(Math.random().toString())
                    }
                }).catch((err: any)=>{
                    handleValidateStatus(err.response)
                })
            }
        }else{
            return
        }
    }

    const handleFinish = ()=>{
        let isLess = false;
        let codesWithError = '';
        const raw_data = data.attach(Downgraded).get();

        for(let product of raw_data.products){
            if(quantityInserted[product._id].get() < product.qty){
                codesWithError += `${product.code}, `;
                isLess = true;
            }
        }
        if(!saveClicked.get()){
            showHideModal({show: true, title: "Você tem alterações não salvas. Gostaria de continuar mesmo assim?", execute: (confirm: boolean)=> {
                verifySaveOFinish(confirm, isLess, codesWithError)
            }})
        }else{
            if(isLess){
                codesWithError = codesWithError.substring(0, codesWithError.length-2)
                showHideModal({show: true, title: "Alguns itens possuem a quantidade de entrada menor que a quantidade da nota. Gostaria de continuar mesmo assim?", execute: (confirm: boolean)=> {
                    executeFinish(confirm, codesWithError)
                }})
            }else{
                axs.put<ProductsSerialNumbers[]>(`/purchasetracking/serialnumber/finished/${id}`).then((r: any) => {
                    if(r.status === 200){
                        toast.success("Finalizado!");
                        history.push(`/compras/numeros-de-serie/`)
                    }else{
                        toast.error("Ocorreu um erro ao tentar finalizar...");
                        renderControlState.set(Math.random().toString())
                    }
                }).catch((err: any)=>{
                    handleValidateStatus(err.response)
                })
            }
        }
    }
    

    if(data.promised){ //Enquanto for promisse ou estiver carregando
        return (
            <>
                <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}> 
                    <Header></Header>
                    <div className="content-wrapper">
                        <Route>
                            <SideMenu />
                        </Route>
                        <main className="main-content container-fluid">
                            <div className="loading">
                                <AiOutlineLoading3Quarters className="loading-icon" />
                            </div>
                        </main>
                    </div>
                </div>
            </>
        )
    }

    if (data.error) { // Quando o promise dar reject
        return (<ErrorScreen />)
    }

    return(
        <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
            <ModalConfirm />
            <BugReportModal />
            <div className="content-wrapper">
                <Route>
                    <SideMenu />
                </Route>
                <main className="main-content container-fluid">
                    <div className="page-title-container">
                        <h3>Números de série - Compras</h3>
                    </div>
                    {
                        data.products.length > 0 ?
                        <>
                            <div className="sender-carrying-info-container">
                                <div>
                                    <strong>Fornecedor:</strong>
                                    <span>{sender.get()}</span>
                                </div>
                                -
                                <div>
                                    <strong>Número da NF:</strong>
                                    <span>{numberNF.get()}</span>
                                </div>
                                -
                                 <div>
                                    <strong>Volumes:</strong>
                                    <span>{volume.get()}</span>
                                </div>
                            </div>
                            <section className={`accordions-section ${DarkMode ? 'dark-mode-accordions-section' : ''}`}>
                                {
                                data.products.attach(Downgraded).get().map((product: any)=>{
                                        return(
                                            <ContentAccordion class_name={`accordion_productsSerialNumber ${DarkMode ? 'dark-mode-accordion_productsSerialNumber' : ''}`}
                                            initializeExpanded={product.serialNumber ? false:true}
                                                title={
                                                    <ul className="accordionTitle" key={renderControlState.get()}>
                                                        <li>
                                                            <strong 
                                                                className={`${quantityInserted[product._id].get() ? quantityInserted[product._id].get() !== product.qty ? 'diff-quantity' : 'same-quantity' : ''}`}
                                                            >
                                                                Código:
                                                            </strong>
                                                            <span>{product.code}</span>
                                                        </li>
                                                        <li>
                                                            <strong
                                                                className={`${quantityInserted[product._id].get() ? quantityInserted[product._id].get() !== product.qty ? 'diff-quantity' : 'same-quantity' : ''}`}
                                                            >
                                                                Produto:
                                                            </strong>
                                                            <span>{product.product}</span>
                                                        </li>
                                                        <li>
                                                            <strong
                                                                className={`${quantityInserted[product._id].get() ? quantityInserted[product._id].get() !== product.qty ? 'diff-quantity' : 'same-quantity' : ''}`}
                                                            >
                                                                Qtde:
                                                            </strong>
                                                            <span>{product.qty}</span>
                                                        </li>
                                                        <li>
                                                            <strong
                                                                className={`${quantityInserted[product._id].get() ? quantityInserted[product._id].get() !== product.qty ? 'diff-quantity' : 'same-quantity' : ''}`}
                                                            >
                                                                Qtde. Entrada:
                                                            </strong>
                                                            <span>{quantityInserted[product._id].get()}</span>
                                                        </li>
                                                    </ul>
                                                }>
                                                <textarea 
                                                    placeholder="Números de série" 
                                                    className={`textarea-serial-number ${quantityInserted[product._id].get() ? quantityInserted[product._id].get() !== product.qty ? 'diff-quantity' : 'same-quantity' : ''}`} 
                                                    disabled={idsToDisable[product._id].get() || finishedEntrySerial.get()}
                                                    key={idsToDisable[product._id].get()}
                                                    defaultValue={product.serialNumber ? product.serialNumber.replaceAll(' ', '\n') : idsToDisable[product._id].get() === true ? 'N/A' : ''}
                                                    onChange={(evt: React.BaseSyntheticEvent)=>{
                                                        
                                                        const arrayOfValues = evt.target.value.split('\n');
                                                        let valuesInserted = arrayOfValues.length;
                                                        valuesInserted = arrayOfValues[valuesInserted-1] === '\n' || arrayOfValues[valuesInserted-1] === ' ' || arrayOfValues[valuesInserted-1] === ''
                                                        ? valuesInserted-1 : valuesInserted;

                                                        const temp = quantityInserted.attach(Downgraded).get();
                                                        temp[product._id] = valuesInserted;
                                                        quantityInserted.merge({...temp})

                                                        const temp2 = serialNumber.attach(Downgraded).get();
                                                        temp2[product._id] = {'_id': product._id, serialNumber: evt.target.value.replaceAll('\n', ' ').trimEnd()};
                                                        serialNumber.merge({...temp2})
                                                        if(temp2 !== {} && data.products.length > 0){
                                                            const shouldDisable = data.products.length !== Object.keys(temp2).length ? true : false;
                                                            disableFinish.set(shouldDisable);
                                                        }
                                                        Untracked(saveClicked).set(false)
                                                    }}
                                                />
                                                <section className="checkbox-container">
                                                    <input 
                                                        type='checkbox' 
                                                        name="checkNoSerialNumber"
                                                        id={product._id}
                                                        disabled={finishedEntrySerial.get()}
                                                        defaultChecked={idsToDisable[product._id].get()}
                                                        onChange={e => {
                                                            e.persist();
                                                            const checked = e.target.checked;
                                                            if(serialNumber[product._id].get() && checked){
                                                                showHideModal({show: true, title: "Esta ação vai eliminar os dados, gostaria de continuar mesmo assim?", execute: (confirm: boolean)=> {
                                                                    e.target.checked = confirm
                                                                    handleCheckBox(confirm, checked, product._id, product.qty)
                                                                }})
                                                            }else{
                                                                handleCheckBox(true, checked, product._id, product.qty);
                                                            }
                                                        }}
                                                    />
                                                    <label htmlFor={product._id}><strong>Não Possui Número de Série</strong></label>
                                                </section>
                                            </ContentAccordion>
                                        )
                                    })
                                }
                                <div className="serial-number-btns-container">
                                    <button className="serial-number-btn save" onClick={handleSave}>Salvar</button>
                                    <button className="serial-number-btn finish" 
                                    disabled={disableFinish.get()} onClick={handleFinish}>
                                        Finalizar
                                    </button>
                                </div>
                            </section>
                        </>
                        :
                        null
                    }
                </main>
            </div>
        </div>
    )
}