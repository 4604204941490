import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import moment from 'moment';

export const columns = [
    { 
        headerName: "Data", 
        field: "date", 
        type: 'date',
        headerAlign: 'center',
        flex: 1,
        renderCell: (rowData: any) => {
            return (
                <span style={{width: 'fit-content'}}>{moment(rowData.row.date).format("DD/MM/yyyy")}</span>
            )
        }
    },
    { 
        headerName:  "Produto",
        field: "product", 
        type: 'string',
        headerAlign: 'center',
        flex: 4,
        renderCell: (rowData: any) => {
            return (
                <Tippy content={<div>{rowData.row.product}</div>} maxWidth={'25rem'}>
                    <div style={{width: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: 'clamp(1.1rem,1vw,1.2rem)'}}>
                        <span>{rowData.row.product}</span>
                    </div>
                </Tippy>
            )
        }
    },
    { 
        headerName:  "Custo Produto",
        field: "cost_product", 
        type: 'number',
        headerAlign: 'center',
        flex: 1,
        renderCell: (rowData: any) => {
            return (
                <span>{rowData.row.costProduct.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
            )
        }
    },
    { 
        headerName:  "Custo Frete",
        field: "cost_shippment", 
        type: 'number',
        headerAlign: 'center',
        flex: 1,
        renderCell: (rowData: any) => {
            return (
                <span>{rowData.row.costShippment.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
            )
        }
    },
    { 
        headerName:  "Taxa Marketplace",
        field: "feeMarketplace", 
        type: 'number',
        headerAlign: 'center',
        flex: 1,
        renderCell: (rowData: any) => {
            return (
                <span>{(rowData.row.feeMarketplace*100).toFixed(0)}%</span>
            )
        }
    },
    { 
        headerName:  "Imposto",
        field: "tax", 
        type: 'number',
        headerAlign: 'center',
        flex: 1,
        renderCell: (rowData: any) => {
            return (
                <span>{(rowData.row.tax*100).toFixed(0)}%</span>
            )
        }
    },
    { 
        headerName:  "Valor da Venda",
        field: "sellPrice", 
        type: 'number',
        headerAlign: 'center',
        flex: 1,
        renderCell: (rowData: any) => {
            return (
                <span>{rowData.row.sellPrice.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
            )
        }
    },
    { 
        headerName:  "Lucro",
        field: "profit", 
        type: 'number',
        headerAlign: 'center',
        flex: 1,
        renderCell: (rowData: any) => {
            return (
                <span>{rowData.row.profit.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
            )
        }
    },
    { 
        headerName:  "Margem",
        field: "margin", 
        type: 'number',
        headerAlign: 'center',
        flex: 1,
        renderCell: (rowData: any) => {
            return (
                <span>{(rowData.row.margin*100).toFixed(0)}%</span>
            )
        }
    },
]

export const options = {
    palette:{
      tableHeader: {
        main: '#F7CE3E',
        dark: '#ac902b',
        light: '#f8d764',
        contrastText: '#000'
      },
    },
    alternate: true,
  }
