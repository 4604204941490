import React from 'react';
import errorImg from './assets/images/error.svg'

export class ErrorBoundary extends React.Component<{}, {hasError: boolean}> {
    constructor(props: any) {
      super(props);
      this.state = {'hasError': false};
    }
  
    static getDerivedStateFromError(error:any) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error:any, errorInfo:any) {
      // You can also log the error to an error reporting service
      if(process.env.REACT_APP_AMBIENT === 'dev'){
        console.log(error, errorInfo);
      }
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
            <div style={{width: '100%', height: '100%', display: 'flex',
               flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
               animation: "slide .3s ease-in-out",
               background: 'linear-gradient(90deg, rgb(255, 242, 55) 0%, rgba(255, 198, 11, 0.856) 100%)'
            }}>
                <div className="img-container" style={{width: '40rem', height: '40rem'}}>
                    <img src={errorImg} alt="Imagem de erro." />
                </div>
                <h1>Ocorreu um erro...</h1>
                <a href='/' style={{fontSize: 'clamp(1.6rem, 1.5vw, 1.8rem)', padding: '1rem', marginTop: '1rem', borderRadius: '4px', color: '#fff', backgroundColor: '#000'}}>Ir para o ínicio</a>
            </div>
        );
      }
  
      return this.props.children; 
    }
  }
