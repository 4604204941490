import { Loading } from '../../../components/Loading/Loading';
import { useDarkMode } from '../../../hooks/useDarkMode';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { toast } from 'react-toastify';
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import { useModal } from '../../../hooks/useModal';
import { ModalConfirm } from '../../../components/modal/ModalConfirm';
import moment from 'moment';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { BugReportModal } from '../../../components/modal/BugReportModal';

const FONT_SIZE = "clamp(1.2rem, 1.2vw, 1.6rem)";


export const ProductionOrders = () =>{
    document.title = "Ordens de Produção - MeLiBeat";
    const handleValidateStatus = useHandleErrors();

    let history = useHistory();
    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const fetchResource = (query:any=undefined) => axs.get<any>(`/productionorders/`, {params: query}).then((r: any) => {
        handleValidateStatus(r)
        return r.data
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    })
    const data: State<any> = useState<any>([]);
    const {DarkMode, } = useDarkMode();
    const {showHideModal} = useModal();


    const options = {
        palette:{
          tableHeader: {
            main: '#F7CE3E',
            dark: '#ac902b',
            light: '#f8d764',
            contrastText: '#000'
          },
        },
        alternate: true,
    }

    const columns = [
        {
            headerName: "Ordem de Produção",
            field: "number",
            type: "string",
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    <span title={rowData.row.number}>{rowData.row.number}</span>
                )
            }
        },
        {
            headerName: "Status",
            field: "status",
            type: "string",
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    <span title={rowData.row.status}>{rowData.row.status}</span>
                )
            }
        },
        {
            headerName: "Data",
            field: "date",
            type: "string",
            headerAlign: 'center',
            flex: 2,
            minWidth: 200,
            renderCell: (rowData: any) =>{
                return(
                    <span title={moment(rowData.row.date).format("DD-MM-YYYY")}>{moment(rowData.row.date).format("DD-MM-YYYY")}</span>
                )
            }
        },
    ]

    useEffect(() => {
        data.set(fetchResource())
    }, [])

    const confirmDelete = async(value: boolean, id: string|number)=>{
        if(value){
            axs.delete<any[]>('/productionorders/'+id).then((r: any) => {
                if(r.status === 200){
                    toast.success('Ordem de Produção Removida com Sucesso!');
                    data.set(fetchResource());
                    return
                }else{
                    toast.error(r.message);
                }
            }).catch((err: any)=>{
                handleValidateStatus(err.response)
            })
        }else{
            toast.info("Você escolheu não deletar!");
        }
    }

    const handleView = (row: any)=>{
        const row_id = row._id;
        history.push(`/producao/alteracao-de-producao/${true}/${row_id}/`);
    }

    const handleAddNewOrder = (evt: React.BaseSyntheticEvent)=>{
        evt.preventDefault();
        history.push(`/producao/alteracao-de-producao/${false}/${undefined}/`);
    }


    if(data.promised){ //Enquanto for promisse ou estiver carregando
        return (
            <>
                <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}> 
                    <div className="content-wrapper">
                        <main className="main-content container-fluid">
                            <Loading />
                        </main>
                    </div>
                </div>
            </>
        )
    }

    if (data.error) { // Quando o promise dar reject
        return (<ErrorScreen />)
    }

    return(
        <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
            <ModalConfirm />
            <BugReportModal />
            <div className="content-wrapper">
                <main className="main-content container-fluid">
                    <Box style={{ width: '300px', padding: "2rem 0"}}>
                        <Button variant="contained" style={{fontSize: FONT_SIZE}} color='primary' onClick={handleAddNewOrder}>
                            Nova Ordem de Produção
                        </Button>
                    </Box>
                    <VirtualizedGrid 
                        rows={data.attach(Downgraded).get()}
                        columns={columns}
                        options={options}
                        tableHeight={'auto'}
                        title="Ordens de Produção"
                        actions={[
                            {icon: 'delete', title: 'Deletar Item', method: (evt: any, row:any)=> showHideModal({show: true, title: "Quer mesmo deletar esse item?", execute: (confirm: boolean)=> confirmDelete(confirm, row._id)})}, 
                            {icon: 'visibility', title: 'Visualizar Item', method: (evt: any, row:any)=> handleView(row)}
                        ]} 
                        defaultSort={{field: 'date', direction: 'desc'}}
                    />
                </main>
            </div>
        </div>
    )
}