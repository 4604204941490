
import {FaYoutube} from 'react-icons/fa';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import './style.scss';


export const TutorialButton = (props:any) =>{

    return(
        <Tippy content={<div style={{display: 'flex', gap: '1rem'}}>Tutorial da Tela</div>} interactive={true} maxWidth={'none'}>
            <a href={props.url ? props.url : "#"} className="tutorial-button" target="_blank" rel="noreferrer" style={props.style}>
                <FaYoutube className="icon" />
            </a>
        </Tippy>
    )
}
