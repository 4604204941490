import './style.scss';
import { useDarkMode } from '../../hooks/useDarkMode';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export const Filters = (props: any) =>{
    const BUTTON_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.4rem)';
    const {DarkMode, } = useDarkMode();
    const hideFilterBtn = props.hideFilterBtn ? props.hideFilterBtn : false
    const disableFilterBtn = props.disableFilterBtn ? props.disableFilterBtn : false
    const hideResetBtn = props.hideResetBtn ? props.hideResetBtn : false
    const hideFullResetBtn = props.hideFullResetBtn ? props.hideFullResetBtn : false
    const showResetSessionData = props.showResetSessionData ? props.showResetSessionData : false;
    const handleReset = props.handleReset ? props.handleReset : ()=> undefined;
    const handleFullReset = props.handleFullReset ? props.handleFullReset : ()=> undefined;
    const handleResetSessionData = props.handleResetSessionData ? props.handleResetSessionData : ()=> undefined;

    const defaultTheme  = createTheme({
        palette:{
          mode: DarkMode ? 'dark' : 'light',
        },
    });

    const handleSubmit = (evt:any)=>{
        evt.preventDefault();
        const elements: any = {}
        Object.keys(evt.target.elements).forEach((elm: any) => {
            if(evt.target.elements[elm].name && evt.target.elements[elm].value){
                elements[evt.target.elements[elm].name] = evt.target.elements[elm].value
            }
        })
        props.submitFunction(elements);
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Paper elevation={3} className={`filter-container ${DarkMode ? "dark-mode-filter" : ''}`}>
                <Box component="form" onSubmit={(evt: any)=> handleSubmit(evt)}>
                        {/* botão criado apenas para desabilitar o envio do form com a tecla enter */}
                        <button type="submit" disabled style={{display: 'none'}} aria-hidden="true"></button>
                        <Stack direction="row" spacing={2}>
                            {props.children}
                            <Button disabled={disableFilterBtn ? disableFilterBtn:false} size='small' variant="contained" color='primary' startIcon={<FilterAltIcon />} sx={{display: hideFilterBtn ? 'none' : '', minWidth: 'fit-content', fontSize: BUTTON_FONT_SIZE, width: '100%', '& .MuiButton-startIcon':{margin: '0'}}} type='submit'>
                                Filtrar
                            </Button>
                            <Button size='small' variant="contained" color='error' startIcon={<FilterAltOffIcon />} sx={{display: hideResetBtn ? 'none' : '', minWidth: 'fit-content', fontSize: BUTTON_FONT_SIZE, width: '100%', '& .MuiButton-startIcon':{margin: '0'}}} type='reset' onClick={handleReset}>
                                Resetar
                            </Button>
                            <Button size='small' variant="contained" color='error' startIcon={<RestartAltIcon />} sx={{display: hideFullResetBtn ? 'none' : '', minWidth: 'fit-content', fontSize: BUTTON_FONT_SIZE, width: '100%', '& .MuiButton-startIcon':{margin: '0'}}} type='reset' onClick={handleFullReset}>
                                Full Reset
                            </Button>
                            <Button size="small" variant="contained" disabled={sessionStorage.getItem("selectionData") === null && sessionStorage.getItem("selectedRows") === null} color='error' sx={{display: showResetSessionData ? '' : 'none', minWidth: 'fit-content', fontSize: BUTTON_FONT_SIZE, width: '100%', '& .MuiButton-startIcon':{margin: '0'}}} type='reset' onClick={handleResetSessionData}>
                                Resetar Dados Selecionados
                            </Button>
                        </Stack>
                </Box>
            </Paper>
        </ThemeProvider>
    )
}