
export const CheckBoxes = (props: any)=>{
    return (
        <div className="checkbox-container">
            <input 
                type='checkbox' 
                className={props.class ? props.class:null}
                name={props.name ? props.name:null} 
                id={props.id ? props.id:null} 
                required={props.required ? props.required : false}
                checked={props.checked ? props.checked : false}
                value={props.value}
                onChange={props.onChange}
            />
            <label htmlFor={props.id} style={props.styleLabel ? props.styleLabel:null}>{props.label}</label>
        </div>
    )
}
