import moment from 'moment';

const checkVisibility = (column: string)=>{
  const storageColumnsData = localStorage.getItem('Gestão de Fechamentos LCRE') ? localStorage.getItem('Gestão de Fechamentos LCRE') : null;
  if(storageColumnsData !== null){
    const localData = JSON.parse(storageColumnsData);
    const shouldHide = localData.find((item: any)=> item.field === column);
    return shouldHide !== undefined ? !shouldHide.visibility : false
  }
  return false
}

export const columns = [
  {
    headerName: "Origem",
    field: "origin",
    type: "string",
    hide: checkVisibility('origin'),
    headerAlign: 'center',
    flex: 1,
    renderCell: (rowData: any) => {
      return (
        <>
          <span title={rowData.row.origin}>{rowData.row.origin}</span>
        </>
      )
    }
  },
  {
    headerName: "Data", 
    field: "date_start",
    type: "string",
    hide: checkVisibility('date_start'),
    headerAlign: 'center',
    flex: 1,
    renderCell: (rowData: any) => {
      return (
        <>
          {moment(rowData.row.date_start).format("DD/MM/yyyy")}
        </>
      )
    }
  },
  {
    headerName: "Alíquota Imposto", 
    field: "tax_aliquot",
    type: "number",
    hide: checkVisibility('tax_aliquot'),
    headerAlign: 'center',
    flex: 1,
    renderCell: (rowData: any) => {
      return (
        <>
          {`${rowData.row.tax_aliquot * 100}%`}
        </>
      )
    }
  },
  {
    headerName: "Pedidos", 
    field: "total_orders",
    type: "number",
    hide: checkVisibility('total_orders'),
    headerAlign: 'center',
    flex: 1,
    renderCell: (rowData: any) => {
      return (
        <>
          {rowData.row.total_orders}
        </>
      )
    }
  },
  {
    headerName:"Custo Total Produtos",
    field: "total_cost_product",
    type: "number",
    hide: checkVisibility('total_cost_product'),
    headerAlign: 'center',
    flex: 1,
    renderCell: (rowData: any) => {
      
      return (
        <>
          {rowData.row.total_cost_product.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
        </>
      )
    }
  },
  {
    headerName:"Total Taxas Frete",
    field: "total_shipping_fee",
    type: "number",
    hide: checkVisibility('total_shipping_fee'),
    headerAlign: 'center',
    flex: 1,
    renderCell: (rowData: any) => {
      return (
        <>
          {rowData.row.total_shipping_fee.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
        </>
      )
    }
  },
  {
    headerName:"Total Taxas Vendas",
    field: "total_sale_fee",
    type: "number",
    hide: checkVisibility('total_sale_fee'),
    headerAlign: 'center',
    flex: 1,
    renderCell: (rowData: any) => {
      return (
        <>
          {rowData.row.total_sale_fee.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
        </>
      )
    }
  },
  {
    headerName:"Custo Total Impostos",
    field: "total_cost_tax",
    type: "number",
    hide: checkVisibility('total_cost_tax'),
    headerAlign: 'center',
    flex: 1,
    renderCell: (rowData: any) => {
      return (
        <>
          {rowData.row.total_cost_tax.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
        </>
      )
    }
  },
  {
    headerName:"Lucro Bruto Total",
    field: "total_gross_profit",
    type: "number",
    hide: checkVisibility('total_gross_profit'),
    headerAlign: 'center',
    flex: 1,
    renderCell: (rowData: any) => {
      return (
        <>
          {rowData.row.total_gross_profit.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
        </>
      )
    }
  },
  {
    headerName:"Lucro Líquido Total",
    field: "total_profit",
    type: "number",
    hide: checkVisibility('total_profit'),
    headerAlign: 'center',
    flex: 1,
    renderCell: (rowData: any) => {
      return (
        <>
          {rowData.row.total_profit.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
        </>
      )
    }
  },
  {
    headerName:"Margem Lucro Total",
    field: "total_profit_margin",
    type: "number",
    hide: checkVisibility('total_profit_margin'),
    headerAlign: 'center',
    flex: 1,
    renderCell: (rowData: any) => {
      return (
        <>
          {(rowData.row.total_profit_margin * 100).toFixed(2)}%
        </>
      )
    }
  },
  {
    headerName:"Total Vendas",
    field: "total_sales",
    type: "number",
    hide: checkVisibility('total_sales'),
    headerAlign: 'number',
    flex: 1,
    renderCell: (rowData: any) => {
      return (
        <>
          {rowData.row.total_sales.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
        </>
      )
    }
  },
  {
    headerName:"Total Vendas Canceladas",
    field: "total_sales_cancelled",
    type: "number",
    hide: checkVisibility('total_sales_cancelled'),
    headerAlign: 'center',
    flex: 1,
    renderCell: (rowData: any) => {
      return (
        <>
          {rowData.row.total_sales_cancelled.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
        </>
      )
    }
  }
  
]

export const options = {
  palette:{
      tableHeader: {
          main: '#F7CE3E',
          dark: '#ac902b',
          light: '#f8d764',
          contrastText: '#000'
      },
  },
  alternate: true,
}