import { BrowserRouter as Route, useHistory } from 'react-router-dom';
import { columns, options } from '../../../components/table/files/ShoppingListAllData';
import {AiOutlineLoading3Quarters} from 'react-icons/ai'
import { toast } from 'react-toastify';
import { ModalConfirm } from '../../../components/modal/ModalConfirm';
import { IShoppingListData, ObjetoVazio} from "../../../types/Types";
import { useDarkMode } from '../../../hooks/useDarkMode';
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import { useModal } from '../../../hooks/useModal';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import { Filters } from '../../../components/Filters/Filters';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { BugReportModal } from '../../../components/modal/BugReportModal';

const INPUT_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.6rem)';


export const ShoppingListAll = ()=>{
    document.title = "Todas as Listas de Compras - MeliBeat";
    const handleValidateStatus = useHandleErrors();

    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const fetchResource = (query:any=undefined) => axs.get<IShoppingListData[]>('/shoppinglist/', {params: query}).then((r: any) => {
        handleValidateStatus(r)
        return r.data
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    })
    const data: State<IShoppingListData[]> = useState<IShoppingListData[]>([]);
    let history = useHistory();
    const {DarkMode, } = useDarkMode();
    const {showHideModal} = useModal();
    const filterValues: ObjetoVazio = useState<ObjetoVazio>({'finished': undefined})

    useEffect(() => {
        data.set(fetchResource())
    }, [])

    const handleChangePage = (value: any) =>{
        history.push("/compras/lista-de-compras/"+value._id);
    }

    const confirmDelete = async(value: boolean, id: string)=>{
        if(value){
            const returnPost = await axs.delete<IShoppingListData>(`/shoppinglist/${id}`);
            if(returnPost.status === 200){
                const dt = data.get().filter((elm: any)=> elm._id.toString() !== id)
                data.set(dt)
                toast.success("Item deletado!");
            }else{
                toast.error("Não foi possível deletar!");
            }
        }else{
            toast.info("Você escolheu não deletar!");
        }
    }

    const handleFinish = async(confirm: boolean, id: string) =>{
        if(confirm){
            const returnPost = await axs.post<IShoppingListData>(`/shoppinglist/finish/${id}`)
            if(returnPost.status === 200){
                const dt = data.get().filter((elm: any)=> elm._id.toString() !== id)
                data.set(dt)
                toast.success("Item finalizado!");
            }else{
                toast.error("Não foi possível finalizar!");
            }
        }else{
            toast.info("Você escolheu não finalizar a lista!");
        }
    }

    const handleFilter = (filters: any)=>{
        toast.info('Filtrando...')
        data.set(fetchResource({'finished': filters.filterFinished ? filters.filterFinished === "1" ? true : false : false}))
        axs.defaults.params = undefined
    }

    const handleReset = ()=> {
        data.set(fetchResource())
        filterValues.merge({"finished": undefined})
    }

    const renderFilters = (props: any)=>{
        const cancelAnimation = props.cancelAnimation;
        return(
            <Filters submitFunction={handleFilter} cancelAnimation={cancelAnimation} hideFullResetBtn handleReset={handleReset}>
                <FormControl size='small' variant='outlined' sx={{minWidth: '20rem'}}>
                    <InputLabel id="finished-label-filter" style={{fontSize: INPUT_FONT_SIZE}}>Finalizadas</InputLabel>
                    <Select
                        labelId="finished-label-filter"
                        id="finished-filter"
                        name='filterFinished'
                        value={filterValues.finished.get()}
                        label="Finalizadas"
                        style={{fontSize: INPUT_FONT_SIZE}}
                        required
                        onChange={(evt: SelectChangeEvent)=> filterValues.finished.set(evt.target.value)}
                    >
                    <MenuItem value={''}></MenuItem>
                    <MenuItem value={'1'}>Sim</MenuItem>
                    <MenuItem value={'0'}>Não</MenuItem>
                    </Select>
                </FormControl>
            </ Filters>
        )
    }

    if(data.promised){ //Enquanto for promisse ou estiver carregando
        return (
            <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}> 
                <div className="content-wrapper">
                    <main className="main-content container-fluid">
                        {renderFilters({})}
                        <div className="loading">
                            <AiOutlineLoading3Quarters className="loading-icon" />
                        </div>
                    </main>
                </div>
            </div>
        )
    }
    
    if (data.error) { // Quando o promise dar reject
        return (<ErrorScreen />)
    }

    //Quando a promisse for resolvida

    return(
        <>
            <div className={`wrapper ${DarkMode ? 'dark-mode-wrapper' : ''}`}> 
                <ModalConfirm />
                <BugReportModal />
                <div className="content-wrapper">
                    <main className="main-content container-fluid">
                        {
                            renderFilters({cancelAnimation: true})
                        }
                        {

                                data.attach(Downgraded).get().length ?
                                <VirtualizedGrid 
                                    rows={data.attach(Downgraded).get()}
                                    columns={columns}
                                    options={options}
                                    title="Listas de Compra"
                                    actions={[
                                        {icon: 'delete', title: 'Deletar Item', method: (evt: any, row:any)=> showHideModal({show: true, title: "Quer mesmo deletar esse item?", execute: (confirm: boolean)=> confirmDelete(confirm, row._id)})}, 
                                        {icon: 'visibility', title: 'Visualizar Item', method: (evt: any, row:any)=> handleChangePage(row)},
                                        {icon: 'done', title: 'Finalizar Item', method: (evt: any, row:any)=> showHideModal({show: true, title: "Quer mesmo finalizar esse item?", execute: (confirm: boolean)=> handleFinish(confirm, row._id)}), disableBy: {field: 'finished', value: true}},
                                    ]}
                                    defaultSort={{field: 'date', direction: 'desc'}}
                                />
                                :null
                        }
                    </main>
                </div>
            </div>
        </>
    )
}