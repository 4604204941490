import { columns, options } from '../../../components/table/files/FullReplacementPCData';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { BrowserRouter as Route, useHistory } from 'react-router-dom';
import { BasicForm, InputMainContainer } from '../../../components/Form/BasicForm';
import noData from '../../../assets/images/nodata.svg';
import { Filters } from '../../../components/Filters/Filters';
import { Accordion } from '../../../components/accordion/Accordion';
import {CaptionsFullReplacement} from '../../../components/accordion/files/CaptionsFullReplacement';
import moment from 'moment';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import {FaTruck, FaAward, FaCheckCircle, FaTimesCircle, FaPauseCircle, FaHeart} from 'react-icons/fa';
import {GoZap} from 'react-icons/go';
import {MdCreate} from 'react-icons/md';
import {BiRevision, BiText} from 'react-icons/bi'
import {BsFillTagFill, BsFillChatQuoteFill} from 'react-icons/bs';
import {FiExternalLink} from 'react-icons/fi';
import { ModalConfirm } from '../../../components/modal/ModalConfirm';
// import { useModal } from '../../../hooks/useModal';
import { IFullReplacement, IFullReplacementRowData, ObjetoVazio, BlingProductsType, MLBS, Shippments, IRawBlingProducts } from "../../../types/Types"
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import VirtualizedGrid from '../../../components/table/VirtualizedGrid';
import Popper from '@material-ui/core/Popper';
import { TutorialButton } from '../../../components/TutorialButton/TutorialButton';
import { useDarkMode } from '../../../hooks/useDarkMode';
import { BasicModal } from '../../../components/modal/BasicModal';
import Link from '@mui/material/Link';
import { createTheme, styled } from '@mui/material/styles';
import axios from 'axios';
import { useState , State, Downgraded } from '@hookstate/core';
import { ErrorScreen } from '../../../components/ErrorScreen/ErrorScreen';
import { useHandleErrors } from '../../../hooks/useHandleErrors';
import Box from '@mui/material/Box';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { 
    GridFilterItem,
} from '@mui/x-data-grid';
import { Loading } from '../../../components/Loading/Loading';
import { BugReportModal } from '../../../components/modal/BugReportModal';

const defaultTheme  = createTheme();
const INPUT_FONT_SIZE = 'clamp(1.2rem, 1.2vw, 1.6rem)';


const StyledPopper = styled(Popper)({
    "& .MuiAutocomplete-listbox": {
      '& li':{
        fontSize: 'clamp(1rem, 1vw, 1.1rem)',
        maxWidth: '40rem',
      }
    }
});

const FONT_SIZE = "clamp(1.2rem, 1.2vw, 1.6rem)";

const BaseInput = styled(TextField)({
    '& .MuiInput-input':{
      fontSize: FONT_SIZE,
      flex: 1,
      padding: '9px 0'
    },
    '& label':{
      fontSize: FONT_SIZE,
      paddingLeft: '14px',

    }
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function CheckboxesTags(props: any) {
  const { item, applyValue, options:tag_options } = props;
  // const selected_values = item.value ? item.value.map((elm: any)=> elm.title) : [];

  const handleFilterChange = (event: any, value: any) => {
    applyValue({ ...item, value: value });
  };

  return (
    <Autocomplete
      multiple
      options={tag_options}
      disableCloseOnSelect
      getOptionLabel={(option: any) => option.title}
      defaultValue={item.value}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
            style={{ marginRight: 8 }}
            // checked={selected_values.includes(option.title)}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label="Tags" variant="standard" sx={{'& .MuiChip-root':{fontSize: 'clamp(1rem, 1.1vw, 1.2rem)'}}} />
      )}
      PopperComponent={StyledPopper}
      onChange={handleFilterChange}
    />
  );
}

export const FullReplacementPC = (props:any) =>{
    document.title = "Reposição Full Pcs - MeLiBeat";
    const handleValidateStatus = useHandleErrors();

    const showActualFalseMLBS: State<ObjetoVazio> = useState<ObjetoVazio>({});
    const processData = (data: IFullReplacement[])=>{
        const processedArray = data.map((item: IFullReplacement, index: any)=>{
            const temp = showActualFalseMLBS.attach(Downgraded).get();
            temp[item._id as keyof ObjetoVazio] = false;
            if(item.group_skus === true){
                item['group_id'] = item._id;
                item._id = item?.skus?.toString().replaceAll(',', ', ');
            }
            showActualFalseMLBS.merge({...temp})

            const new_tags: string[] = item.tags.map((tag: any)=>{
                if(tag === 'lack_replenishment') return 'Sem reposição'
                else if(tag === 'without_fulfilment') return 'Sem Fulfillment'
                else if(tag === 'without_history') return 'Sem Histórico'
                else if(tag === 'all_paused') return 'MLBs Pausados'
                else if(tag === 'diff_stock_full') return 'Diferença no Estoque Fulfillment'
                else if(tag === 'replenishment_mlb') return 'Reposição Necessária por MLB'
                else return tag
            })
            item.tags = new_tags
            
            item.mlbs.forEach((mlb)=>{
                let mlb_tags = [];
                if(mlb.free_shipping) mlb_tags.push('Envio Grátis')
                if(mlb.variation_id !== null) mlb_tags.push('Variação (Sim)')
                else mlb_tags.push('Variação (Não)')
                if(mlb.fulfillment) mlb_tags.push('Fulfillment (Sim)')
                else mlb_tags.push('Fulfillment (Não)')
                if(mlb.status === "active") mlb_tags.push('Status (Ativo)')
                else if(mlb.status === "iactive") mlb_tags.push('Status (Inativo)')
                if(mlb.deal) mlb_tags.push('Promoção (Sim)')
                else mlb_tags.push('Promoção (Não)')
                if(mlb.listing_type === 'gold_special') mlb_tags.push('Anúncio Clássico')
                else mlb_tags.push('Anúncio 12x')
                if(mlb.quality_control.title === 'minimal') mlb_tags.push('Título Verificado')
                else mlb_tags.push('Título Parão')
                if(mlb.quality_control.rating === 'good') mlb_tags.push('Avaliação Positiva')
                else if(mlb.quality_control.rating === 'bad') mlb_tags.push('Avaliação Negativa')
                if(mlb.kit_qty > 1) mlb_tags.push('Kit Ativo')
                else mlb_tags.push('Kit Inativo')

                mlb.tags = mlb_tags;
            })

            return item
        })
        return processedArray
    }
    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })
    const fetchResource = (query:any={'blackFridayFilter': false, 'projectType':'activeDays'}) => axs.get<any[]>('/kpi/repofullpc/', {params: query}).then((r: any) => {
        handleValidateStatus(r)
        return processData(r.data);
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    })
    const fetchBling = () => axs.get<IRawBlingProducts[]>('/bling/products/').then((r: any) => {
        handleValidateStatus(r)
        const processedProducts = Object.entries(r.data).map((item: any)=>{
            let obj:IRawBlingProducts = item[1]
            return {label: `${obj.sku} - ${obj.title}`, sku: obj.sku, cost: obj.cost}
        })
        return processedProducts
    }).catch((err: any)=>{
        return []
    })
    const fetchShippments = () => axs.get<Shippments[]>('/fulfillment/relation/', {params: {'not_completed': "relation"}}).then((r: any) => {
        handleValidateStatus(r)
        const shippmentsArray: Shippments[] = [{
            "_id": null,
            "description": 'Novo Envio',
            "scheduling": moment()
        }, ...r.data];
        return shippmentsArray
    }).catch((err: any)=>{
        return []
    })
    const data: State<IFullReplacement[]> = useState<IFullReplacement[]>([]);
    const blingProducts: State<BlingProductsType> = useState<BlingProductsType>([]);
    const shippments: State<Shippments[]> = useState<Shippments[]>([]);


    const showGenRelation: State<boolean> = useState<boolean>(false);
    // const {ModalData, showHideModal} = useModal();
    const relationData: State<object[]> = useState<object[]>([]);
    const quantity_analyst: State<ObjetoVazio> = useState<ObjetoVazio>({});
    const skus: State<ObjetoVazio> = useState<ObjetoVazio>({});
    const history = useHistory();
    const selectedRows: State<ObjetoVazio> = useState<ObjetoVazio>({});
    const {DarkMode, } = useDarkMode();

    const disabledSubmitButton: State<boolean> = useState<boolean>(false);
    const modalVisible: State<boolean> = useState<boolean>(false);
    const selectShippment: State<Shippments> = useState<Shippments>({
        "_id": '',
        "description": '',
        "scheduling": ''
    });

    const blackFridayFilter: any = useState<any>(undefined)
    const basedProjectionFilter: State<string | undefined> = useState<string | undefined>('activeDays')


    useEffect(() => {
        data.set(fetchResource());
        blingProducts.set(fetchBling());
        shippments.set(fetchShippments())
    }, [])

    useEffect(() => {
        if (data.promised) return
        if (data.error) return
        else{
            handleGetSession()
        }
    }, [data.promised])



    const accordionColumns = [
        { 
            field: "", 
            type: 'boolean',
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false,
            minWidth: 50,
            renderCell: (rowData: any) => {
                return (
                    <div className="checkbox-container" style={{display: rowData.row.actual ? "" : "none"}}>
                        <input
                            type='checkbox' 
                            className="mlbCheckbox"
                            checked={selectedRows[rowData.row._id].value ? selectedRows[rowData.row._id].value : false}
                            onChange={e => {
                                const temp = selectedRows.attach(Downgraded).get();
                                temp[rowData.row._id] = e.target.checked
                                selectedRows.set({...temp})
                                if(e.target.checked){
                                    handleSelection(rowData.row)
                                }else{
                                    handleRemoveSelection(rowData.row._id)
                                }
                            }}
                        />
                    </div>
                )
            }
        },
        { 
            title: "actual",
            field: "actual", 
            type: 'boolean',
            hide: true,
            headerAlign: 'center',
            flex: 1,
        },
        { 
            headerName: "MLB",
            field: "id", 
            type: 'string',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) => {
                return (
                    <Tippy content={
                        <table style={{width: '100%', fontSize: "clamp(1.2rem, 1.4vw, 1.5rem)"}}>
                            <thead style={{borderBottom: '1px solid rgba(255,255,255, 0.6)'}}>
                                <tr style={{width: '100%', display: 'flex', justifyContent: 'space-between', gap: '0rem'}}>
                                    <th style={{padding: '0.5rem', width: "10rem", textAlign:"left", fontSize: '1.1rem'}}><strong>SKU</strong></th>
                                    <th style={{padding: '0.5rem', width: "15rem", textAlign:"left", fontSize: '1.1rem'}}><strong>Variação</strong></th>
                                    <th style={{padding: '0.5rem', width: "fit-content", textAlign:"left", fontSize: '1.1rem'}}><strong>Inventory ID</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    rowData.row.variationsInMlb.map((item: any)=>{
                                        return (
                                            <tr style={{width: '100%', display: 'flex', gap: '1rem', borderBottom: '1px solid rgba(255,255,255, 0.6)'}}>
                                                <td style={{padding: '0.5rem', width: "10rem", textAlign:"left", fontSize: '1.1rem'}}>{item.sku}</td>
                                                <td 
                                                style={{padding: '0.5rem', width: "18rem", textAlign:"left", fontSize: '1.1rem'}}
                                                title={item.variation_name ? item.variation_name : "N/A"}
                                                >
                                                    {item.variation_name ? item.variation_name : "N/A"}
                                                </td>
                                                <td 
                                                style={{padding: '0.5rem', width: "fit-content", textAlign:"right", fontSize: '1.1rem'}} 
                                                title={item.inventory_id ? item.inventory_id : "N/A"}
                                                >
                                                    {item.inventory_id ? item.inventory_id : "N/A"}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    } maxWidth="fit-content">
                        <div title={rowData.row.id}>
                            <Link 
                                href={rowData.row.link} 
                                target="_blank" rel="noreferrer" 
                                color={'inherit'} 
                                sx={{'&:hover':{color: defaultTheme.palette.primary.dark}}}
                                style={{fontSize: 'clamp(1.2rem, 1vw, 1.3rem)', display: 'flex', alignItems: 'center', gap: '5px', textDecoration: !rowData.row.actual ? "line-through" : "none"}} 
                                >
                                    <div>{rowData.row.id}</div><FiExternalLink />
                            </Link>
                        </div>
                    </Tippy>
                )
            }
        },
        {
            headerName: "Título Anúncio",
            field: "title",
            type: "string",
            headerAlign: 'center',
            flex: 1,
            minWidth: 150,
            renderCell: (rowData: any) => {
                return (
                    <Tippy content={
                        <div>{rowData.row.title}</div>
                    } maxWidth={'100%'}>
                        <div style={{width: '30rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}} title={rowData.row.title}>
                            {rowData.row.title}
                        </div>
                    </Tippy>
                )
            }
        },
        {
            headerName: "Tags",
            field: "tags",
            type: "string",
            headerAlign: 'center',
            flex: 2,
            minWidth: 200,
            filterOperators: [{
                value: 'contains',
                getApplyFilterFn: (filterItem: GridFilterItem) => {
                  const selected_values = filterItem.value ? filterItem.value.map((item: any)=> item.title) : [];
                  if (filterItem.value == null || selected_values === []) {
                    return null;
                  }
            
                  return ({ value }:{ value: any }): boolean => {
                    if (typeof value === 'object') {
                      return selected_values.every((item: any)=> value.includes(item))
                    }
                    return selected_values.every((item: any)=> value.includes(item))
                  };
                },
                // InputComponent: GridFilterInputValue,
                InputComponent: CheckboxesTags,
                InputComponentProps: { 
                    type: 'singleSelect', 
                    options: [
                        {title: 'Avaliação Positiva'}, 
                        {title: 'Avaliação Negativa'}, 
                        {title: 'Título Verificado'}, 
                        {title: 'Título Padrão'}, 
                        {title: 'Status (Ativo)'}, 
                        {title: 'Status (Intivo)'}, 
                        {title: 'Fulfillment (Sim)'}, 
                        {title: 'Fulfillment (Não)'}, 
                        {title: 'Kit Ativo'}, 
                        {title: 'Kit Inativo'}, 
                        {title: 'Promoção (Sim)'}, 
                        {title: 'Promoção (Não)'}, 
                        {title: 'Envio Grátis'}, 
                        {title: 'Variação (Sim)'}, 
                        {title: 'Variação (Não)'}, 
                        {title: 'Anúncio Clássico'}, 
                        {title: 'Anúncio 12x'}, 
                    ]},
              }],
              valueOptions: [
                'Avaliação Positiva', 'Avaliação Negativa', 'Título Verificado', 'Título Padrão',
                'Status (Ativo)', 'Status (Intivo)', 'Fulfillment (Sim)', 'Fulfillment (Não)', 
                'Promoção (Sim)', 'Promoção (Não)', 'Envio Grátis', 'Variação (Sim)', 'Variação (Não)',
                'Anúncio Clássico', 'Anúncio 12x'
              ],
            renderCell: (rowData: any) => {
                let skuQualityColor = "";
                let avaliationsColor = "";
                let titleQualityColor = "";
                if(rowData.row.quality_control.description === null && rowData.row.quality_control.product_sheet === null && 
                rowData.row.quality_control.photos === null && rowData.row.quality_control.video === null){
                    skuQualityColor = "#a5b1c2";
                }
                else if(rowData.row.quality_control.description === "default" && rowData.row.quality_control.product_sheet === "default" && 
                rowData.row.quality_control.photos === "default" && rowData.row.quality_control.video === "default"){
                    skuQualityColor = "#20bf6b"
                }
                else if((rowData.row.quality_control.description === "default" || rowData.row.quality_control.description === "minimal") && 
                (rowData.row.quality_control.product_sheet === "default" || rowData.row.quality_control.product_sheet === "minimal") && 
                (rowData.row.quality_control.photos === "default" || rowData.row.quality_control.photos === "minimal") && 
                (rowData.row.quality_control.video === "default" || rowData.row.quality_control.video === "minimal")){
                    skuQualityColor = "#4b7bec"
                }else{
                    skuQualityColor = "#e84118"
                }

                if(rowData.row.quality_control.title === null){
                titleQualityColor = "#a5b1c2";
                }else if(rowData.row.quality_control.title === "minimal"){
                titleQualityColor = "#20bf6b";
                }else{
                titleQualityColor = "#4b7bec";
                }

                if(rowData.row.quality_control.rating === null){
                avaliationsColor = "#a5b1c2";
                }else if(rowData.row.quality_control.rating === "bad"){
                avaliationsColor = "#e84118";
                }else{
                avaliationsColor = "#20bf6b";
                }

                const tagStatus:any = {"active": <FaCheckCircle className="icon"/>, "inactive": <FaTimesCircle className="icon"/>, 'paused': <FaPauseCircle className="icon"/>, "under_review": <BiRevision className="icon" />, "closed": <FaTimesCircle className="icon"/>}
                const tagStatusTranslations:any = {"active": 'Ativo', "inactive": 'Inativo', 'paused': 'Pausado', "under_review": "Em Revisão", "closed": "Fechado"}
                const statusColor:any = {"active": "#2477ad", "inactive": "#a5b1c2", 'paused': "#d65446", "under_review": "#000", "closed": "#c0392b"}
                const tagColors: any = {
                'tags': 
                [
                    {'title': 'Envio Grátis', 'value': <FaTruck className="icon" />, 'color': (rowData.row.free_shipping ? '#4b7bec':'#a5b1c2')},
                    {'title': 'Fulfillment', 'value': <GoZap className="icon"/>, 'color': (rowData.row.fulfillment ? '#20bf6b':'#a5b1c2')},
                    {'title': `Status: ${tagStatusTranslations[rowData.row.status]}`, 'value': tagStatus[rowData.row.status], 'color':  (rowData.row.status ? statusColor[rowData.row.status]:'#a5b1c2')},
                    {'title': `Promoção: ${rowData.row.original_price !== null ? (((rowData.row.sell_price - rowData.row.original_price)/rowData.row.original_price)*100):0}%`, 'value': <BsFillTagFill className="icon"/>, 'color': (rowData.row.deal ? '#e056fd':'#a5b1c2')},
                    {'title': `Tipo de Listagem: ${rowData.row.listing_type === 'gold_special' ? 'Clássico':'12x'}`, 'value': <FaAward className="icon"/>, 'color': (rowData.row.listing_type === 'gold_pro' ? '#e1b12c' : '#a5b1c2')},
                    {'title': `Saúde do Anúncio ${(rowData.row.health*100)}%`, 'value': <FaHeart className="icon"/>, 'color': (rowData.row.health ? ((rowData.row.health*100) >= 70 ? '#e84118' : '#9b59b6') :'#a5b1c2')},
                    {'title': 'Qualidade Título', 'value': <BiText className="icon" />, 'color': titleQualityColor},
                    {'title': 'Avaliações', 'value': <BsFillChatQuoteFill className="icon" />, 'color': avaliationsColor},
                ]
                };
                const tagNoColors: any = {
                'tags': 
                [
                    {'title': 'Envio Grátis', 'value': <FaTruck className="icon" />, 'color': "#a5b1c2"},
                    {'title': 'Fulfillment', 'value': <GoZap className="icon"/>, 'color': "#a5b1c2"},
                    {'title': `Status: ${tagStatusTranslations[rowData.row.status]}`, 'value': tagStatus[rowData.row.status], 'color': "#a5b1c2"},
                    {'title': `Promoção: ${rowData.row.original_price !== null ? (((rowData.row.sell_price - rowData.row.original_price)/rowData.row.original_price)*100):0}%`, 'value': <BsFillTagFill className="icon"/>, 'color': "#a5b1c2"},
                    {'title': `Tipo de Listagem: ${rowData.row.listing_type === 'gold_special' ? 'Clássico':'12x'}`, 'value': <FaAward className="icon"/>, 'color': '#a5b1c2'},
                    {'title': `Saúde do Anúncio ${(rowData.row.health*100)}%`, 'value': <FaHeart className="icon"/>, 'color': "#a5b1c2"},
                    {'title': 'Qualidade Título', 'value': <BiText className="icon" />, 'color': "#a5b1c2"},
                    {'title': 'Avaliações', 'value': <BsFillChatQuoteFill className="icon" />, 'color': "#a5b1c2"},
                ]
                };
                return (
                <div className="tags-container" style={{marginTop: 0, flexWrap: 'nowrap', display: 'flex', gap: '5px'}}>
                    {
                    rowData.row.actual ?
                        Object.values(tagColors.tags).map((item: any, index: number)=>{
                            if(item.title === "Kit Ativo"){
                                if(rowData.group_skus){
                                    return(
                                        <Tippy key={`${index}-${Math.random()}`} content={item.title} maxWidth={'25rem'}>
                                            <span className="tag-small" title={item.title} style={{color: item.color}}>{item.value}</span>
                                        </Tippy>
                                    )
                                }else{
                                    return null
                                }
                            }
                            else if(item.title === "Relação do Catálogo"){
                                if(!rowData.group_skus){
                                    if(rowData.row.catalog_relation){
                                        return(
                                            <Tippy key={`${index}-${Math.random()}`} content={rowData.row.catalog_relation.mlb_id} maxWidth={'25rem'}>
                                                <span key={`${index}-${Math.random()}`} className="tag-small" style={{color: item.color}}>{item.value}</span>
                                            </Tippy>
                                        )
                                            
                                    }else{
                                        return(
                                            <Tippy key={`${index}-${Math.random()}`} content={item.title} maxWidth={'25rem'}>
                                                <span key={`${index}-${Math.random()}`} className="tag-small" style={{color: item.color}}>{item.value}</span>
                                            </Tippy>
                                        )
                                    }
                                }else{
                                    return null
                                }
                            }
                            else if(item.title === "Qualidade Título"){
                                return (
                                    <Tippy key={`${index}-${Math.random()}`} content={
                                        <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center"}}>
                                            <strong>{`Título: ${rowData.row.quality_control.title !== null ? rowData.row.quality_control.title : "N/A"}`}</strong>
                                        </div>
                                    } maxWidth={'25rem'}>
                                        <span className="tag-small" title={item.title} style={{color: item.color}}>{item.value}</span>
                                    </Tippy>
                                )
                            }
                            else if(item.title === "Avaliações"){
                                return (
                                    <Tippy key={`${index}-${Math.random()}`} content={
                                        <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center"}}>
                                            <strong>{`Avaliação: ${rowData.row.quality_control.rating !== null ? (rowData.row.quality_control.rating === "good" ? "Boa":"Ruim") : "N/A"}`}</strong>
                                        </div>
                                    } maxWidth={'25rem'}>
                                        <span className="tag-small" title={item.title} style={{color: item.color}}>{item.value}</span>
                                    </Tippy>
                                )
                            }
                            else{
                                return(
                                    <Tippy key={`${index}-${Math.random()}`} content={item.title} maxWidth={'25rem'}>
                                        <span key={`${index}-${Math.random()}`} className="tag-small" title={item.title} style={{color: item.color}}>{item.value}</span>
                                    </Tippy>
                                )
                            }
                        })
                    :
                    Object.values(tagNoColors.tags).map((item: any, index: number)=>{
                        if(item.title === "Kit Ativo"){
                            if(rowData.group_skus){
                                return(
                                    <Tippy key={`${index}-${Math.random()}`} content={item.title} maxWidth={'25rem'}>
                                        <span className="tag-small" title={item.title} style={{color: item.color}}>{item.value}</span>
                                    </Tippy>
                                )
                            }else{
                                return null
                            }
                        }
                        else if(item.title === "Relação do Catálogo"){
                            if(!rowData.group_skus){
                                if(rowData.row.catalog_relation){
                                    return(
                                        <Tippy key={`${index}-${Math.random()}`} content={rowData.row.catalog_relation.mlb_id} maxWidth={'25rem'}>
                                            <span key={`${index}-${Math.random()}`} className="tag-small" style={{color: item.color}}>{item.value}</span>
                                        </Tippy>
                                    )
                                        
                                }else{
                                    return(
                                        <Tippy key={`${index}-${Math.random()}`} content={item.title} maxWidth={'25rem'}>
                                            <span key={`${index}-${Math.random()}`} className="tag-small" style={{color: item.color}}>{item.value}</span>
                                        </Tippy>
                                    )
                                }
                            }else{
                                return null
                            }
                        }
                        else if(item.title === "Qualidade Título"){
                            return (
                                <Tippy key={`${index}-${Math.random()}`} content={
                                    <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center"}}>
                                        <strong>{`Título: ${rowData.row.quality_control.title !== null ? rowData.row.quality_control.title : "N/A"}`}</strong>
                                    </div>
                                } maxWidth={'25rem'}>
                                    <span className="tag-small" title={item.title} style={{color: item.color}}>{item.value}</span>
                                </Tippy>
                            )
                        }
                        else if(item.title === "Avaliações"){
                            return (
                                <Tippy key={`${index}-${Math.random()}`} content={
                                    <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center"}}>
                                        <strong>{`Avaliação: ${rowData.row.quality_control.rating !== null ? (rowData.row.quality_control.rating === "good" ? "Boa":"Ruim") : "N/A"}`}</strong>
                                    </div>
                                } maxWidth={'25rem'}>
                                    <span className="tag-small" title={item.title} style={{color: item.color}}>{item.value}</span>
                                </Tippy>
                            )
                        }
                        else{
                            return(
                                <Tippy key={`${index}-${Math.random()}`} content={item.title} maxWidth={'25rem'}>
                                    <span key={`${index}-${Math.random()}`} className="tag-small" title={item.title} style={{color: item.color}}>{item.value}</span>
                                </Tippy>
                            )
                        }
                    })
                    }
        
                </div>
            )}
        },
        { 
            headerName: "Vendas Totais",
            field: "total_sold", 
            type: 'number',
            headerAlign: 'center',
            flex: 0.8,
            minWidth: 80,
            renderCell: (rowData: any) => {
                return(
                    <span style={{textDecoration: !rowData.row.actual ? "line-through" : "none"}}>{parseInt(rowData.row.total_sold).toFixed(0)}</span>
                )
            }
        },
            { 
            headerName: "Qtd. Disp.",
            field: "available_qty", 
            type: 'number',
            headerAlign: 'center',
            flex: 0.75,
            minWidth: 75,
            renderCell: (rowData: any) => {
                if(rowData.row.group_skus){
                    if(rowData.row.kit_qty > 1){
                        return(
                            <Tippy content={<strong>Quantidade Disponível (kit): {parseInt(rowData.row.available_qty_kit).toFixed(0)}</strong>} maxWidth={'25rem'}>
                                <span style={{textDecoration: !rowData.row.actual ? "line-through" : "none"}}>{parseInt(rowData.row.available_qty).toFixed(0)}</span>
                            </Tippy>
                        )
                    }else{
                        return(
                            <span style={{textDecoration: !rowData.row.actual ? "line-through" : "none"}}>{parseInt(rowData.row.available_qty).toFixed(0)}</span>
                        )
                    }
                }else{
                    if(rowData.row.hasOwnProperty("total_qty")){
                        return(
                            <Tippy content={<strong>Quantidade Total (kit): {parseInt(rowData.row.total_qty).toFixed(0)}</strong>} maxWidth={'25rem'}>
                                <span style={{textDecoration: !rowData.row.actual ? "line-through" : "none"}}>{parseInt(rowData.row.available_qty).toFixed(0)}</span>
                            </Tippy>
                        )
                    }else{
                        return(
                            <span style={{textDecoration: !rowData.row.actual ? "line-through" : "none"}}>{parseInt(rowData.row.available_qty).toFixed(0)}</span>
                        )
                    }
                }
            }
        },
        { 
            headerName: "Qtd. Pend.",
            field: "pending_qty", 
            type: 'number',
            headerAlign: 'center',
            flex: 0.75,
            minWidth: 75,
            renderCell: (rowData: any) => {
                if(rowData.row.group_skus){
                    if(rowData.row.kit_qty > 1){
                        return(
                            <Tippy content={<strong>Quantidade Pendente (kit): {parseInt(rowData.row.pending_qty_kit).toFixed(0)}</strong>} maxWidth={'25rem'}>
                                <span style={{textDecoration: !rowData.row.actual ? "line-through" : "none"}}>{parseInt(rowData.row.pending_qty).toFixed(0)}</span>
                            </Tippy>
                        )
                    }else{
                        return(
                            <span style={{textDecoration: !rowData.row.actual ? "line-through" : "none"}}>{rowData.row.pending_qty}</span>
                        )
                    }
                }else{
                    return(
                        <span style={{textDecoration: !rowData.row.actual ? "line-through" : "none"}}>{rowData.row.pending_qty}</span>
                    )
                }
            }
        },
        { 
            headerName: "Qtd. Transp.",
            field: "qty_shipped", 
            type: 'number',
            headerAlign: 'center',
            flex: 0.75,
            minWidth: 75,
            renderCell: (rowData: any) => {
                return(
                    <span style={{textDecoration: !rowData.row.actual ? "line-through" : "none"}}>{rowData.row.qty_shipped}</span>
                )
            }
        },
        { 
            headerName: "Vendas Prd.",
            field: "total_sells", 
            type: 'number',
            headerAlign: 'center',
            flex: 0.8,
            minWidth: 80,
            renderCell: (rowData: any) => {
                if(rowData.row.group_skus){
                    if(rowData.row.kit_qty > 1){
                        return(
                            <Tippy content={
                                <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center"}}>
                                    <strong>Vendas (kit): {parseInt(rowData.row.total_sold_kit).toFixed(0)}</strong>
                                    <strong>Dias Ativos: {parseInt(rowData.row.active_days).toFixed(0)}</strong>
                                </div>
                            } maxWidth={'25rem'}>
                                <span>{parseInt(rowData.row.total_sells).toFixed(0)}</span>
                            </Tippy>
                        )
                    }else{
                        return(
                            <Tippy content={<strong>Dias Ativos: {parseInt(rowData.row.active_days).toFixed(0)}</strong>} maxWidth={'25rem'}>
                                <span>{parseInt(rowData.row.total_sells).toFixed(0)}</span>
                            </Tippy>
                        )
                    }
                }else{
                    return (
                        rowData.row.hasOwnProperty("total_sold_kit") ?
                            <Tippy content={
                                <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center"}}>
                                    <strong>Vendas (kit): {parseInt(rowData.row.total_sold_kit).toFixed(0)}</strong>
                                    <strong>Dias Ativos: {parseInt(rowData.row.active_days).toFixed(0)}</strong>
                                </div>
                            } maxWidth={'25rem'}>
                                <span>{parseInt(rowData.row.total_sells).toFixed(0)}</span>
                            </Tippy>
                        :
                            <Tippy content={<strong>Dias Ativos: {parseInt(rowData.row.active_days).toFixed(0)}</strong>} maxWidth={'25rem'}>
                                <span>{parseInt(rowData.row.total_sells).toFixed(0)}</span>
                            </Tippy>
                    )
                }
            }
        },
        { 
            headerName: "Margem Média",
            field: "margin_avg", 
            type: 'number',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) => {
                return (
                    <span>{(rowData.row.margin_avg * 100).toFixed(2)}%</span>
                )
            }
        },
        { 
            headerName: "Preço Venda",
            field: "sell_price", 
            type: 'number',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) => {
                return (
                    <span style={{textDecoration: !rowData.row.actual ? "line-through" : "none"}}>{rowData.row.sell_price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                )
            }
        },
        { 
            headerName: "Dur. Estoque",
            field: "stock_duration", 
            type: 'number',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) => {
                return (
                    <span style={{textDecoration: !rowData.row.actual ? "line-through" : "none"}}>{parseInt(rowData.row.stock_duration).toFixed(0)}</span>
                )
            }
        },
        { 
            headerName: "Proj. 15d",
            field: "project_sells_25_days", 
            type: 'number',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) => {
                if(rowData.row.kit_qty > 1){
                    return (
                        <Tippy content={<strong>Projeção 25d (Kit): {parseInt(rowData.row.project_sells_kit_25_days).toFixed(0)}</strong>} maxWidth={'25rem'}>
                            <span>{parseInt(rowData.row.project_sells_25_days).toFixed(0)}</span>
                        </Tippy>
                    )
                }else{
                    return(
                        <span>{parseInt(rowData.row.project_sells_25_days).toFixed(0)}</span>
                    )
                }
            }
        },
        { 
            headerName: "Rep. Neces.",
            field: "stock_replenishment", 
            type: 'number',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) => {
                return (
                    <span>{parseInt(rowData.row.stock_replenishment).toFixed(0)}</span>
                )
            }
        },
        {
            headerName: "Qtd. Analista",
            field: "quantity_analyst",
            type: "number",
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                    <InputMainContainer>
                        <div className="input-container">
                        <BaseInput
                                disabled={!rowData.row.actual}
                                tabIndex={1}
                                variant="standard" 
                                placeholder="Qtd. Analista"
                                className="inputTable"
                                name="number_nf"
                                id={`number_nf-${rowData.row._id}`}
                                defaultValue={quantity_analyst[rowData.row._id].value ? quantity_analyst[rowData.row._id].value : 0}
                                style={{borderBottom: 'none'}}
                                onChange={(evt: React.BaseSyntheticEvent)=> {
                                    const temp = quantity_analyst.attach(Downgraded).get();
                                    temp[rowData.row._id] = parseFloat(evt.target.value)
                                    quantity_analyst.set({...temp})
                                    sessionStorage.setItem("quantity_analyst", JSON.stringify(temp));
                                    const mlbsSelected = relationData.attach(Downgraded).get();
                                    mlbsSelected.forEach((item: any)=>{
                                        if(item.identifier === rowData.row._id){
                                            item.quantity_analyst = parseFloat(evt.target.value)
                                        }
                                    })
                                    handleSetSession(mlbsSelected)
                                    relationData.set(mlbsSelected)
                                }}
                            />
                        </div>
                    </InputMainContainer>
                )
            }
        },
        {
            headerName: "SKU Input",
            field: "sku_input",
            type: "string",
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (rowData: any) =>{
                return(
                <InputMainContainer>
                    <div className="input-container">
                        <Autocomplete
                            disabled={!rowData.row.actual}
                            id={`skus-relation-${rowData.row._id}`}
                            options={blingProducts.attach(Downgraded).get()}
                            getOptionLabel={(option: any) => option.sku}
                            disableClearable
                            defaultValue={skus[rowData.row._id].value ? skus[rowData.row._id].value : undefined}
                            renderOption={(props, option) => (
                                <li {...props}>
                                    {option.label}
                                </li>
                                )}
                            renderInput={(params) => <TextField {...params} variant="standard" placeholder="SKU" />}
                            PopperComponent={(props: any)=> <StyledPopper {...props} style={{ width: "fit-content", padding: "1rem", display: "flex", flexDirection: "column", gap: '1rem' }} placement='bottom-start' />}
                            onChange={(evt: React.BaseSyntheticEvent, value: any)=> {
                                const temp = skus.attach(Downgraded).get();
                                temp[rowData.row._id] = {"sku": value.sku, "label": value.label}
                                skus.set({...temp})
                                sessionStorage.setItem("skus", JSON.stringify(skus.attach(Downgraded).get()));
                                const mlbsSelected = relationData.attach(Downgraded).get();
                                mlbsSelected.forEach((item: any)=>{
                                    if(item.identifier === rowData.row._id){
                                        item.sku_analyst = value.sku
                                    }
                                    return null
                                })
                                handleSetSession(mlbsSelected)
                                relationData.set(mlbsSelected);
                            }}
                        />
                    </div>
                </InputMainContainer>
                )
            }
        },
    ]


    const handleResetSession = ()=>{
        sessionStorage.removeItem("selectionData");
        sessionStorage.removeItem("selectedRows");
        sessionStorage.removeItem("quantity_analyst");
        sessionStorage.removeItem("skus");
        relationData.set([]);
        selectedRows.set({})
        skus.set({});
        quantity_analyst.set({})
        showGenRelation.set(false);
        // const aux_data = JSON.parse(JSON.stringify(data));
        // data.(aux_data)
        toast.info("Resetando as informações dos itens selecionados!");
    }

    const handleSetSession = (data: object[])=>{
        sessionStorage.setItem("selectionData", JSON.stringify(data));
        sessionStorage.setItem("selectedRows", JSON.stringify(selectedRows.attach(Downgraded).get()));
        sessionStorage.setItem("skus", JSON.stringify(skus.attach(Downgraded).get()));
        sessionStorage.setItem("quantity_analyst", JSON.stringify(quantity_analyst.attach(Downgraded).get()));
    }

    const handleGetSession = ()=>{
        const aux_data = data.attach(Downgraded).get();
        if(sessionStorage.getItem("selectionData") !== null){
            const selectionDataStringfyied = sessionStorage.getItem("selectionData");
            const selectionData: object[] = JSON.parse(String(selectionDataStringfyied))
            let processedData: object[] = [];
            aux_data.forEach((item:IFullReplacement)=>{
                item.mlbs.forEach((mlb: MLBS)=>{
                    selectionData.forEach((check: ObjetoVazio)=>{
                        if(check.identifier === mlb._id){
                            processedData.push(check)
                        }
                    })
                })
            })
            if(processedData.length > 0) showGenRelation.set(true);
            relationData.set(processedData);
        }
        if(sessionStorage.getItem("selectedRows") !== null){
            const selectedRowsStringfyied = sessionStorage.getItem("selectedRows");
            const rows: ObjetoVazio = JSON.parse(String(selectedRowsStringfyied))
            selectedRows.set(rows);
        }
        if(sessionStorage.getItem("skus") !== null){
            const skusStringfyied = sessionStorage.getItem("skus");
            const rows: ObjetoVazio = JSON.parse(String(skusStringfyied))
            skus.set(rows);
        }
        if(sessionStorage.getItem("quantity_analyst") !== null){
            const quantityAnalystStringfyied = sessionStorage.getItem("quantity_analyst");
            const rows: ObjetoVazio = JSON.parse(String(quantityAnalystStringfyied))
            quantity_analyst.set(rows);
        }
    }



    const handleRemoveSelection = (identifier: object[]) =>{
        let temp_array = relationData.attach(Downgraded).get();
        let idx = temp_array.findIndex((t:any)=> (t.identifier === identifier))

        if(idx > -1){
            temp_array.splice(idx, 1)
            relationData.set(temp_array)
        }
    }

    const handleSelection = (row: IFullReplacementRowData) =>{
        if(!showGenRelation.get()){
            showGenRelation.set(true);
        }
        let skuOfMlb: IFullReplacement[] = data.attach(Downgraded).get().filter((item: IFullReplacement) => item._id === row.sku || item._id?.includes(String(row.sku)))
        let temp_array = relationData.attach(Downgraded).get();
        const temp_skus = skus.attach(Downgraded).get();
        if(skuOfMlb.length > 0){
            let item = skuOfMlb[0]
            let idx = temp_array.findIndex((t:any)=> (t.identifier === row._id))
            if(idx === -1){
                temp_array.push({
                    "identifier": row._id, //ObjectId
                    "id": row.id,//MLB
                    "variation_id": row.variation_id,
                    "variation_name": row.variation_name,
                    "inventory_id": row.inventory_id,
                    "category_id": row.category_id,
                    "tax_aliquot": row.tax_aliquot,
                    "cost_product": row.cost_product,
                    "sell_price": row.sell_price,
                    "cost_sale_fee": row.cost_sale_fee,
                    "cost_shipping": row.cost_shipping,
                    "actual": row.actual,
                    "fulfillment": row.fulfillment,
                    "stock_replenishment": row.stock_replenishment,
                    "stockAll": item.stockAll,
                    "stockFull": item.stockFull,
                    "stockETP": item.stockETP,
                    "catalog_relation": row.hasOwnProperty('catalog_relation') ? row.catalog_relation : null,
                    "brand": item.brand,
                    "origin": item.origin,
                    "photo": item.photo,
                    "group_skus": item.group_skus,
                    "title": item.title,
                    "_id": item._id,//SKU
                    "stock_replenishment_sku": item.stock_replenishment,
                    "category_bling":  item.category_bling,
                    "composition": item.composition,
                    "gross_weight": item.gross_weight,
                    "cubic_meter": item.cubic_meter,
                    "quantity_analyst": quantity_analyst[row._id] ? parseFloat(quantity_analyst[row._id].value) : 0,
                    "sku_analyst": temp_skus[row._id] ? temp_skus[row._id]["sku"] : null,
                    "sku": row.sku
                })
                handleSetSession(temp_array)
                relationData.set(temp_array);
            }
        }
        
    }
    
    const handleGenRelation = (evt: React.BaseSyntheticEvent) =>{
        evt.preventDefault();
        disabledSubmitButton.set(true)
        const result:IFullReplacement[] = [];
        relationData.attach(Downgraded).get().forEach(function(item:any) {
            if(result.indexOf(item) < 0) {
                result.push(item);
            }
        });
        axs.post<any>('/fulfillment/', {
            "products": [...result],
            "fulfillmentShippingId": selectShippment._id.value
        }).then((r: any) => {
            if(r.status === 200){
                const id = r.data._id;
                history.push(`/fulfillment/alteracao-envio/${id}`);
            }
         }).catch((err: any)=>{
            handleValidateStatus(err.response)
        })
        
        showGenRelation.set(false);
    }

    const handleFullReset = ()=>{
        toast.info("Resetando os dados em cache!");
        blackFridayFilter.set(undefined)
        basedProjectionFilter.set('activeDays')
        data.set(fetchResource({'withoutCache': true}));
    }

    const handleFilter = (filters: any)=>{
        toast.info('Filtrando...')
        data.set(fetchResource({
            'blackFridayFilter': filters.filterBlackFriday ? filters.filterBlackFriday === "1" ? true : false : false,
            'projectType': filters.filterBasedProjection
        }));
    }

    return(
        <>
            <div className={`wrapper  ${DarkMode ? 'dark-mode-wrapper' : ''}`}>
                {
                    modalVisible.get() ?
                    <BasicModal closeFunction={()=> modalVisible.set(false)}>
                        <BasicForm style={{width: '100%', boxShadow: "none"}} SubmitFunction={handleGenRelation}>
                            <InputMainContainer 
                                style={{'width': '60rem', "padding": '2rem'}}
                            >
                                <div className="input-container">
                                    <Autocomplete
                                        className="shippments-select"
                                        options={shippments.attach(Downgraded).get()}
                                        getOptionLabel={(option: any) => `${option.description} -  Reserva: ${moment.utc(option.scheduling).format("DD/MM/yyyy")}`}
                                        // getOptionSelected={(option: any, value:any)=> value.sku === option.sku}
                                        disableClearable
                                        renderInput={(params) => <TextField variant="standard" {...params} placeholder="Envio" />}
                                        // renderOption={(props: any)=> <strong style={{margin: "1rem 0", fontSize: "clamp(1.1rem, 1vw, 1.2rem)"}}>{`${props.description} -  Reserva: ${moment(props.scheduling).format("DD/MM/yyyy")}`}</strong>}
                                        renderOption={(props, option) => (
                                            <li {...props}>
                                                {`${option.description} -  Reserva: ${moment.utc(option.scheduling).format("DD/MM/yyyy")}`}
                                            </li>
                                        )}
                                        PopperComponent={(props)=> <StyledPopper {...props} style={{ width: "fit-content", padding: "1rem",display: "flex", flexDirection: "column", gap: '1rem' }} placement='bottom-start' />}
                                        onChange={(evt: React.BaseSyntheticEvent, value: any)=> {
                                            // console.log(value)
                                            selectShippment.set(value)
                                        }}
                                    />
                                </div>
                            </InputMainContainer>

                            <InputMainContainer style={{justifyContent: "flex-start", flexDirection: "row", "alignItems": "flex-start"}}>
                                <button 
                                className="btn-icon-red-big-border"
                                onClick={(evt: React.BaseSyntheticEvent)=> modalVisible.set(false)}
                                >
                                    Cancelar
                                </button>
                                <button type="submit" className="btn-icon-blue-big" disabled={disabledSubmitButton.get()}>Confirmar</button>
                            </InputMainContainer>
                        </BasicForm>
                    </BasicModal>
                    :
                    null
                }
                {
                    showGenRelation.get() ?
                    <button 
                        className="floating_cart" 
                        onClick={(event: any) => modalVisible.set(true)} 
                        // onClick={(event: any) => showHideModal({show: true, title: "Gostaria de adicionar esses itens à uma relação?", execute: (confirm: boolean)=> handleGenRelation(confirm)})} 
                    >
                        <MdCreate className="icon" />
                    </button>
                    :
                    null
                }
                <ModalConfirm />
                <BugReportModal />
                <div className="content-wrapper">
                    <main className="main-content container-fluid">
                        <Filters submitFunction={handleFilter} hideResetBtn handleFullReset={handleFullReset} handleResetSessionData={handleResetSession} showResetSessionData={true}>
                            <FormControl size='small' variant='outlined' sx={{minWidth: '28rem'}} key={blackFridayFilter.get()}>
                                <InputLabel id="black-friday-label-filter" style={{fontSize: INPUT_FONT_SIZE}}>Remover Vendas Black Friday</InputLabel>
                                <Select
                                    labelId="black-friday-label-filter"
                                    id="black-friday-filter"
                                    name='filterBlackFriday'
                                    value={blackFridayFilter.attach(Downgraded).get()}
                                    label="Remover Vendas Black Friday"
                                    style={{fontSize: INPUT_FONT_SIZE}}
                                    onChange={(evt: SelectChangeEvent)=> blackFridayFilter.set(evt.target.value)}
                                >
                                <MenuItem value={''}></MenuItem>
                                <MenuItem value={'1'}>Sim</MenuItem>
                                <MenuItem value={'0'}>Não</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl size='small' variant='outlined' sx={{minWidth: '25rem'}} key={basedProjectionFilter.get()}>
                                <InputLabel id="projecao-baseada-label-filter" style={{fontSize: INPUT_FONT_SIZE}}>Projeção Baseada</InputLabel>
                                <Select
                                    labelId="projecao-baseada-label-filter"
                                    id="projecao-baseada-filter"
                                    name='filterBasedProjection'
                                    value={basedProjectionFilter.attach(Downgraded).get()}
                                    label="Projeção Baseada"
                                    style={{fontSize: INPUT_FONT_SIZE}}
                                    onChange={(evt: SelectChangeEvent)=> basedProjectionFilter.set(evt.target.value)}
                                >
                                <MenuItem value={'activeDays'}>Dias Ativos</MenuItem>
                                <MenuItem value={'historySells'}>Histórico de Vendas</MenuItem>
                                </Select>
                            </FormControl>
                        </Filters>
                        <Accordion title={CaptionsFullReplacement.title} data={CaptionsFullReplacement.data} />
                        <TutorialButton url="https://www.youtube.com/watch?v=yZhyx3B8hFI" />
                        {
                            data.promised ? 
                            <Loading />
                            : data.error ?
                            <ErrorScreen /> :
                            data.length > 0 && blingProducts.length > 0 ?
                            // <CollapsibleTable 
                            //     rows={data.attach(Downgraded).get()} 
                            //     columns={columns} 
                            //     options={options}
                            //     tableHeight="700px"
                            //     title="Reposição Full PCs"
                            //     accordionField="mlbs"
                            //     accordionTitle=""
                            //     accordionColumns={accordionColumns}
                            //     defaultSort={{field: 'actual', direction: 'desc'}}
                            //     RowsPerPage={25}
                            //     hidePaginationAccordion={true}
                            //     downloadExcellMlbs={true}
                            //     renderShowHideMlbsBtn={false}
                            //     hideSearchAndTitle={false}
                            // />
                            <VirtualizedGrid 
                            rows={data.attach(Downgraded).get()}
                            columns={columns}
                            options={options}
                            title="Reposição Full PCs"
                            defaultSort={{field: 'actual', direction: 'desc'}}
                            detailPanel={({ row }: {row:any}) => 
                                <Box sx={{ margin: 1 }}>
                                    <VirtualizedGrid 
                                        rows={row['mlbs']}
                                        columns={accordionColumns}
                                        options={{...options, rowStyle:(rowData: any) => {
                                            let row_class: string[] = [];
                                            if(!rowData.actual) {
                                                row_class.push("Full-accordion-actual")
                                            }
                                            if(!rowData.actual){
                                                if(!showActualFalseMLBS[rowData.parent_identifier].value){
                                                    row_class.push("hide-accordion-row");
                                                }else{
                                                    row_class.push("show-accordion-row");
                                                }
                                            }
                                            return JSON.stringify(row_class.join(' ')).replaceAll('"', '')
                                        }}}
                                        title={""}
                                        tableHeight= 'auto'
                                        defaultSort={{field: 'actual', direction: 'desc'}}
                                        hidePagination={true}
                                        hideSearchAndTitle={false}
                                    />
                                </Box>
                            }
                        />
                            :
                            <div className="img-container">
                                <img src={noData} alt="Não foi possível renderizar o gráfico por falta de dados." />
                                <h4>Não foi possível renderizar os gráficos por falta de dados.</h4>
                            </div>
                        }
                    </main>

                </div>
            </div>
        </>
    )
}